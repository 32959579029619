import React, { useMemo, useCallback } from 'react'
import CustomModal from "../../../components/CustomModal";
import { useIntl } from "react-intl";
import { convertToDateOnly } from "../../../utils/date"
import { useToast } from "../../../hooks/toast";
import { useSelector, useDispatch } from "react-redux";
import { solveChecklistStep } from "../../../redux/modules/checklist/actions";
import FileArrowDown from '../../../newUXAssets/icons/FileArrowDown';

import {
  Container,
  Header,
  Title,
  Subtitle,
  Hr,
  Content,
  Footer,
  Button,
  DownloadContainer
} from './styles'

const ChecklistStepModal = ({ open, handleClose, id, step })=>{
  const intl = useIntl();
  const dispatch = useDispatch();
  const { event } = useSelector((state) => state.initialSettings);
  const { addToast } = useToast();

  const isPortuguese = useMemo(()=> intl.locale === 'pt', [intl])

  const youtubeLink = useMemo(()=>{
    if(isPortuguese){
      if(step.linkPt) return step.linkPt.substring(step.linkPt.indexOf("=") + 1)
      return null
    }

    if(step.linkEn) return step.linkEn.substring(step.linkEn.indexOf("=") + 1)

    return null
    
  },[step, isPortuguese])

  const downloadLink = useMemo(()=>{
    return isPortuguese ? step.mediaFilePtLink : step.mediaFileEnLink
  }, [step, isPortuguese])

  const callBackMessage = useCallback(
    (message) =>
      addToast({
        type: "error",
        title: intl.formatMessage({
          id: "error",
        }),
        description: intl.formatMessage({
          id: "api.error." + message,
        }),
      }),
    [addToast, intl]
  );

  const solveStep = () => {
    dispatch(solveChecklistStep({
      eventChecklistId: step.eventChecklistId,
      userChecklistId: step.userChecklistId,
      solved: (step.status === 'Concluded' ? false : true),
      selectedEvent: event.value,
      callbackError: callBackMessage
    }))
    handleClose()
  }

  return (
    <CustomModal handleClose={handleClose} isModalVisible={open} id={id}>
      <Container>
        <Header>
          <Title>{isPortuguese ? step.descriptionPt : step.descriptionEn}</Title>
          <Subtitle>
            {intl.formatMessage({ id: "checklist.expires" })}: {convertToDateOnly(step.expiresIn)}
          </Subtitle>
        </Header>
        <Hr />
        <Content>
          <div  
            dangerouslySetInnerHTML={{
              __html: isPortuguese ? 
                step.howToDoPt : 
                step.howToDoEn
            }} 
          />
          {youtubeLink && 
            <iframe
              title='ChecklistStepVideo'
              width="100%"
              height="600"
              src={`https://www.youtube.com/embed/${youtubeLink}`}
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope;"
              picture-in-pictureallowfullscreen
            />
          }
          
          {downloadLink &&
            <DownloadContainer>
              <a href={downloadLink} target='_blank' rel='noreferrer'>
                <FileArrowDown color='#11a7d9' /> {intl.formatMessage({ id: "download_file" })}
              </a>
            </DownloadContainer>
          }
          
        </Content>
        <Footer>
          <Button color="#fff" onClick={handleClose}>{intl.formatMessage({ id: "cancel" })}</Button>
          <Button onClick={solveStep}>
            {intl.formatMessage(step.status === 'Concluded' ? { id: "checklist.unmark" } :{ id: "checklist.solve" })}
          </Button>
        </Footer>
      </Container>
    </CustomModal>
  )
}

export default ChecklistStepModal