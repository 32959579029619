import styled from "styled-components";

import { Collapse } from "antd";

const { Panel } = Collapse;

export const Container = styled.div`
  width: 100%;
  height: 94vh;
  background: #fcfcfc;
  padding: 25px 75px 50px 70px;
  overflow-y: auto;

  ::-webkit-scrollbar {
    width: 4px;
    background-color: #fcfcfc;
  }

  ::-webkit-scrollbar-thumb {
    background: #11a7d9;
    -webkit-border-radius: 1ex;
    -webkit-box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.75);
  }

  ::-webkit-scrollbar-corner {
    background: #11a7d9;
  }
`;

export const Title = styled.h1`
  color: #11a7d9;
  font-family: "Inter";
  font-size: 30px;
  font-style: normal;
  font-weight: 700;
  line-height: 100%;
  border-bottom: 1px solid #d4d4d4;
  padding-bottom: 15px;
`;

export const Hr = styled.hr`
  border-radius: 30px;
  background: #11a7d9;
  width: 425px;
  height: 2px;
  margin-bottom: 24px;
  margin-top: -19.5px;
`;

export const Content = styled.div`
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  width: 100%;
`;

export const Accordion = styled(Collapse)`
  width: 100%;
  border: none;

  > div {
    margin-bottom: 20px;
  }
`;

export const AccordionPanel = styled(Panel)`
  width: 100%;
  border-radius: 8px;
  box-shadow: 2px 2px 10px 0px rgba(112, 112, 112, 0.15);
  background: #fff;

  .ant-collapse-header {
    min-height: 64px;
    padding: 16px 24px !important;
    color: #6c6c6c !important;
    font-family: "Open Sans" !important;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 170% !important;
    white-space: pre-line;
    overflow-wrap: break-word;
    overflow: hidden;
  }

  .ant-collapse-header-text {
    white-space: pre-line;
    overflow-wrap: break-word;
    overflow: hidden;
  }

  .faq-inside-text{
    color: #676F82;
    font-family: 'Open Sans';
    font-size: 16px;
    font-style: normal;
    font-weight: 400;

    ol, ul {
      margin-left: 30px;
    }
  }

  .ant-collapse-content-box {
    padding: 24px;
  }
`;

export const VideoWrapper = styled.div`
  position: relative;
  padding-top: 16px;
  > div > iframe {
    top: 0;
    left: 0;
    @media (max-width: 720px) {
      width: 100%;
      height: 100%;
    }
  }
`;

export const HTMLCanvas = styled.div`
  white-space: pre-line;
  overflow-wrap: break-word;

  ul, ol {
    padding-left: 10px;
  }
`