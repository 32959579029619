import { all, takeLatest, call, put } from "redux-saga/effects";

import { setAllEventSuccess } from "./actions";
import { getEventService } from '../../../service/event.service'

function* getEvent() {
  try {
    const response = yield call(getEventService)

    if (response.status === 200 && response.data.output.length) {
      yield put(setAllEventSuccess({
        event: response.data.output
      }
      ))
    }

  } catch (error) {
   
    console.warn('error', error) //Falta configurar msg de erro
  }
}

export default all([takeLatest("GET_ALL_EVENT", getEvent)]);
