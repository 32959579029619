import styled, { css } from "styled-components";

export const Container = styled.div`
  height: 94vh;
  background: #fcfcfc;
  padding: 25px 110px 50px 70px;
`;

export const Title = styled.h1`
  color: #11a7d9;
  font-family: "Inter";
  font-size: 30px;
  font-style: normal;
  font-weight: 700;
  line-height: 100%;
  border-bottom: 1px solid #d4d4d4;
  padding-bottom: 15px;
`;

export const Hr = styled.hr`
  border-radius: 30px;
  background: #11a7d9;
  width: 119px;
  height: 2px;
  margin-bottom: 24px;
  margin-top: -19.5px;
`;

export const Content = styled.div``;

export const Information = styled.div`
  width: 300px;
  height: 87px;
  flex-shrink: 0;
  margin-bottom: 32px;
`;

export const Label = styled.div`
  color: #3a3a3c;
  font-family: 'Open Sans';
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 170%;
  padding-bottom: 8px;
`;

export const Description = styled.div`
  border-radius: 13px;
  background: #fff;
  padding: 27px 17px;
  display: flex;
  align-items: center;
  font-family: "Inter";
  font-size: 18.2px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 0.364px;
  width: 300px;
  height: 52px;
  flex-shrink: 0;

  span {
    padding-left: 20px;
  }

  ${(props) => {
    switch (props.type) {
      case "service":
        return css`
          color: #6c6c6c;
          font-family: "Open Sans";
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 20px;
          width: 398px;
          height: 169px;
          padding: 12px;
          box-shadow: 2px 2px 10px 0px rgba(112, 112, 112, 0.15);
          gap: 8px;
          text-align: justify;

          span {
            padding-left: 8px;
          }
        `;

      case "whatsapp":
        return css`
          color: #444;
        `;

      default:
        return css`
          color: #11a7d9;
        `;
    }
  }}
`;

export const LogoWhatsApp = styled.img`
  width: 20px;
  height: 20px;
  flex-shrink: 0;
`;
