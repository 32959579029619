import styled, { css } from "styled-components";

export const SelectInputContainer = styled.div`
  background: transparent;
  border-radius: 5px;
  border: 1px solid #dadada;
  color: #dadada;
  display: flex;
  align-items: center;
  flex: 1;

  @media (max-width: 700px) {
    flex-direction: column;
    width: 100%;
  }

  & + div {
    margin-top: 24px;
  }

  ${(props) =>
    props.isErrored &&
    css`
      border-color: #c53030;
    `}

  ${(props) =>
    props.isFocused &&
    css`
      color: #5ddb98;
      border-color: #5ddb98;
    `}
  
  ${(props) =>
    props.isFilled &&
    css`
      color: #5ddb98;
    `}
  
  input[type=number] {
    -moz-appearance: textfield;
  }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  ${(props) =>
    props.disabled &&
    css`
      opacity: 0.7;
      background-color: #f2f2f2;
    `}

  ${(props) =>
    props.disabled &&
    css`
      opacity: 0.7;
      background-color: #f2f2f2;
    `}

  input {
    background: transparent;
    font-size: 14px;
    border: 0;
    flex: 1;
    color: #002244;
    padding: 8px;
    width: 100%;
    &::placeholder {
      color: #002244;
    }
    &:focus {
      border: 0;
      outline: none;
    }
  }

  svg {
    margin-top: 4px;
    margin-bottom: 4px;
    margin-right: 16px;
    margin-left: 16px;

    @media (max-width: 700px) {
      margin-left: 8px;
    }
  }

  .select_telephone_input {
    border-right: 1px solid #dadada;
    border-radius: 5px;

    div {
      border: 0;
    }

    @media (max-width: 700px) {
      border-right: 0 solid #dadada;
      border-bottom: 1px solid #dadada;
    }
  }
`;

export const LabelContainer = styled.div`
  margin-right: 8px;

  ${(props) =>
    props.labelWidth &&
    css`
      width: ${props.labelWidth};
      @media (max-width: 500px) {
        width: auto;
      }
    `}

  ${(props) =>
    props.labelOrientation === "vertical" &&
    css`
      margin-bottom: 8px;
    `}

  @media (max-width: 500px) {
    margin-bottom: 8px;
    width: 100%;
  }
`;

export const SelectContainer = styled.div`
  width: 100%;
  max-width: 40%;
  display: flex;

  @media (max-width: 1250px) {
    max-width: 50%;
  }

  @media (max-width: 700px) {
    max-width: none;
  }
`;

export const InputContainer = styled.div`
  width: 100%;
  display: flex;

  @media (max-width: 700px) {
    padding: 8px;
    width: 100%
  }
`;

export const Error = styled.div`
  margin: 5px 0px !important;
  display: flex;
  font-size: 10px;
  align-items: center;
  justify-content: flex-start;
  color: #e00000;
  text-align: left;
  svg {
    margin-right: 4px;
    min-width: 32px;
  }
  span {
    background: #c53030;
    color: #fff;
    &::before {
      border-color: #c53030 transparent;
    }
  }

  ${(props) =>
    props.labelWidth &&
    css`
      margin-left: ${props.labelWidth} !important;
      @media (max-width: 500px) {
        margin-left: 0px !important;
      }
    `}
`;

export const RootContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 16px;
`;

export const Label = styled.span`
  margin-right: 8px;
  font-size: 12px;
  font-weight: 600;

  ${(props) =>
    props.labelWidth &&
    css`
      width: ${props.labelWidth};
    `}

  word-wrap: break-word;
  @media (max-width: 500px) {
    margin-bottom: 8px;
    width: 100%;
  }
`;

export const InputRow = styled.div`
  display: flex;
  align-items: center;

  ${(props) =>
    props.labelOrientation === "horizontal" &&
    css`
      flex-direction: row;
    `}

  ${(props) =>
    props.labelOrientation === "vertical" &&
    css`
      flex-direction: column;
      align-items: unset;
    `}

  @media (max-width: 500px) {
    flex-direction: column;
    align-items: flex-start;
  }
`;

export const AsteriskText = styled.span`
  color: #c53030;
`;
