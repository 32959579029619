import styled from 'styled-components'

export const SolvedLabel = styled.label`
  border-radius: 6px;
  background: #C7FFE5;
  color: #4A9D77;
  text-align: center;
  font-family: 'Open Sans';
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
  width: 96px;
  padding: 5px;  
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
`

export const UnsolvedLabel = styled.label`
  border-radius: 6px;
  background: #FFF4A1;
  color: #E5B800;
  text-align: center;
  font-family: 'Open Sans';
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
  width: 96px;
  padding: 5px;  
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
`

export const DelayedLabel = styled.label`
  border-radius: 6px;
  background: #FF8080; 
  color: #A60000;
  text-align: center;
  font-family: 'Open Sans';
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
  width: 96px;
  padding: 5px;  
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
`