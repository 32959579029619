import React from "react";

const Files = (props) => {
  const { size = "26", color = "#444444" } = props;

  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 26 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_539_13172)">
        <path
          d="M17.0625 22.75H5.6875C5.47201 22.75 5.26535 22.6644 5.11298 22.512C4.9606 22.3597 4.875 22.153 4.875 21.9375V7.3125C4.875 7.09701 4.9606 6.89035 5.11298 6.73798C5.26535 6.5856 5.47201 6.5 5.6875 6.5H13.8125L17.875 10.5625V21.9375C17.875 22.153 17.7894 22.3597 17.637 22.512C17.4847 22.6644 17.278 22.75 17.0625 22.75Z"
          stroke={color}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M8.125 6.5V4.0625C8.125 3.84701 8.2106 3.64035 8.36298 3.48798C8.51535 3.3356 8.72201 3.25 8.9375 3.25H17.0625L21.125 7.3125V18.6875C21.125 18.903 21.0394 19.1097 20.887 19.262C20.7347 19.4144 20.528 19.5 20.3125 19.5H17.875"
          stroke={color}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M8.9375 15.4375H13.8125"
          stroke={color}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M8.9375 18.6875H13.8125"
          stroke={color}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_824_1651">
          <rect width={size} height={size} fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default Files;
