import { all, takeLatest, call, put } from "redux-saga/effects";

import { setEventsSuccess } from "./actions";
import {
  getEventsService,
  getAccountsByEventAndTypeUserService,
  changeAdminStaffSignInService,
  handleReturnToUserService,
} from "../../../service/impersonate.service";

function* getEvents({ payload }) {
  try {
    const response = yield call(getEventsService, payload.adminToken);

    if (response.status === 200) {
      const events = response.data.output.map((event) => ({
        label: event?.eventEditionName,
        value: event?.idNumber,
      }));
      let eventsSort = [...events].sort((a, b) =>
        a.label < b.label ? -1 : a.label > b.label ? 1 : 0
      );
      yield put(setEventsSuccess(eventsSort));
    }
  } catch (error) {
    payload.callbackError(error.response.data.code);
  }
}

function* getAccountsByEventAndTypeUser({ payload }) {
  try {
    const response = yield call(
      getAccountsByEventAndTypeUserService,
      payload.params,
      payload.adminToken
    );

    if (response.status === 200) {
      payload.callbackSuccess(response.data.accountDropdownOutputList);
    }
  } catch (error) {
    payload.callbackError(error.response.data.code);
  }
}

function* changeAdminStaffSignIn({ payload }) {
  try {
    const response = yield call(
      changeAdminStaffSignInService,
      payload.params,
      payload.adminToken
    );

    if (response.status === 200) {
      payload.callbackSuccess(response.data);
    }
  } catch (error) {
    payload.callbackError(error.response.data.code);
  }
}

function* handleReturnToUser({ payload }) {
  try {
    const response = yield call(
      handleReturnToUserService,
      payload.params,
      payload.adminToken
    );

    if (response.status === 200) {
      payload.callbackSuccess(response.data);
    }
  } catch (error) {
    payload.callbackError(error.response.data.code);
  }
}

export default all([
  takeLatest("GET_EVENTS", getEvents),
  takeLatest(
    "GET_ACCOUNTS_BY_EVENT_AND_TYPE_USER",
    getAccountsByEventAndTypeUser
  ),
  takeLatest("CHANGE_ADMIN_STAFF_SIGNIN", changeAdminStaffSignIn),
  takeLatest("HANDLE_RETURN_TO_USER", handleReturnToUser),
]);
