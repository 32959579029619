import { format } from "date-fns";

Date.prototype.addDays = function (days) {
  var dat = new Date(this.valueOf());
  dat.setDate(dat.getDate() + days);
  return dat;
};

const convertToDateOnly = (date) => {
  if (!date) return "";

  const dt = new Date(date);
  const dtDateOnly = new Date(
    dt.valueOf() + dt.getTimezoneOffset() * 60 * 1000
  );

  return format(dtDateOnly, "dd/MM/yyyy");
};

const convertToYearOnly = (date) => {
  const dt = new Date(date);
  const dtDateOnly = new Date(
    dt.valueOf() + dt.getTimezoneOffset() * 60 * 1000
  );

  return format(dtDateOnly, "yyyy");
};

const listDatesBetween = (startDate, stopDate, compareToday) => {
  var dateArray = [];

  if (compareToday) {
    const today = new Date();
    if (startDate.valueOf() < today.valueOf()) startDate = today;
  }

  var currentDate = new Date(
    startDate.valueOf() + startDate.getTimezoneOffset() * 60 * 1000
  );

  var finalDate = new Date(
    stopDate.valueOf() + stopDate.getTimezoneOffset() * 60 * 1000
  );

  while (currentDate <= finalDate) {
    dateArray.push({
      label: format(new Date(currentDate), "dd/MM/yyyy"),
      value: new Date(currentDate),
    });
    currentDate = currentDate.addDays(1);
  }
  return dateArray;
};

const listDatesBetweenNewUX = (startDate, stopDate, compareToday) => {
  var dateArray = [];

  if (compareToday) {
    const today = new Date();
    if (startDate.valueOf() < today.valueOf()) startDate = today;
  }

  var currentDate = new Date(
    startDate.valueOf() + startDate.getTimezoneOffset() * 60 * 1000
  );

  var finalDate = new Date(
    stopDate.valueOf() + stopDate.getTimezoneOffset() * 60 * 1000
  );

  while (currentDate <= finalDate) {
    dateArray.push({
      label: format(new Date(currentDate), "dd/MM/yyyy"),
      value: format(new Date(currentDate), "yyyy-MM-dd"),
    });
    currentDate = currentDate.addDays(1);
  }
  return dateArray;
};

const inputTimeInsideDate = ({ date, time }) => {
  let dateAux = date;
  let timeAux = time.split(":");

  dateAux.setUTCHours(timeAux[0], timeAux[1], 0, 0);

  return dateAux;
};

const inputTimeInsideDateNewUX = ({ date, time }) => {
  
  return date + "T" + time + ":00.000Z"
};

export {
  convertToDateOnly,
  convertToYearOnly,
  listDatesBetween,
  inputTimeInsideDate,
  listDatesBetweenNewUX,
  inputTimeInsideDateNewUX
};
