import produce from "immer";

const INITIAL_STATE = {
  events: [],
};

const impersonate = (state = INITIAL_STATE, action) => {
  return produce(state, (draft) => {
    switch (action.type) {
      case "SET_EVENTS": {
        const { events } = action.payload;

        draft.events = events;

        break;
      }

      default: {
        return draft;
      }
    }
  });
};

export default impersonate;
