import React, { useCallback, useEffect, useMemo } from "react";
import { useSelector, useDispatch } from "react-redux";

import { UpOutlined } from "@ant-design/icons";

import { getInfoFaq } from "../../redux/modules/faq/actions";

import { useToast } from "../../hooks/toast";

import { useIntl } from "react-intl";

import {
  Container,
  Title,
  Content,
  Hr,
  Accordion,
  AccordionPanel,
  VideoWrapper,
  HTMLCanvas
} from "./styles";

const Faq = () => {
  const dispatch = useDispatch();
  const intl = useIntl();
  const { addToast } = useToast();

  const { faqListOutput } = useSelector((state) => state.faq);

  const faqQuestions = useMemo(() => {
    if (!faqListOutput.length) return [];

    if (intl.locale === "pt") {
      return faqListOutput.map((item) => ({
        id: item.id,
        answer: item.answerPT,
        question: item.questionPT,
        tag: item.tag,
        code: !item.url ? null : item.url.substring(item.url.indexOf("=") + 1),
        position: item.faqPosition,
      }));
    }

    return faqListOutput.map((item) => ({
      id: item.id,
      answer: item.answerEN,
      question: item.questionEN,
      tag: item.tag,
      code: !item.url ? null : item.url.substring(item.url.indexOf("=") + 1),
      position: item.faqPosition,
    }));
  }, [faqListOutput, intl]);

  const callBackMessage = useCallback(
    (message) =>
      addToast({
        type: "error",
        title: intl.formatMessage({
          id: "error",
        }),
        description: intl.formatMessage({
          id: "api.error." + message,
        }),
      }),
    [addToast, intl]
  );

  useEffect(() => {
    dispatch(getInfoFaq(callBackMessage));
  }, [dispatch, callBackMessage]);

  return (
    <Container>
      <Title>
        {intl.formatMessage({
          id: "faq.visualization.title",
        })}
      </Title>
      <Hr />
      <Content>
        <Accordion
          accordion
          expandIconPosition="end"
          expandIcon={({ isActive }) =>(
            <UpOutlined
              style={{ fontSize: "16px" }}
              rotate={isActive ? 360 : 180}
            />
          )}
        >
          {faqQuestions.length &&
            faqQuestions.map((item) => (
              <AccordionPanel header={item.question} key={item.id}>
                <HTMLCanvas  
                  dangerouslySetInnerHTML={{
                    __html: item.answer
                  }}
                  className="faq-inside-text" 
                />
                  {item?.tag && item.code && (
                    <VideoWrapper>
                      <iframe
                        title={item.question}
                        width="100%"
                        height="600"
                        src={`https://www.youtube.com/embed/${item.code}`}
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope;"
                        picture-in-pictureallowfullscreen
                      />
                    </VideoWrapper>
                  )}
              </AccordionPanel>
            ))}
        </Accordion>
      </Content>
    </Container>
  );
};

export default Faq;
