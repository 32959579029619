import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  height: 94vh;
  background: #fcfcfc;
  padding: 25px 75px 50px 70px;
  overflow-y: auto;

  ::-webkit-scrollbar {
    width: 4px;
    background-color: #fcfcfc;
  }

  ::-webkit-scrollbar-thumb {
    background: #11a7d9;
    -webkit-border-radius: 1ex;
    -webkit-box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.75);
  }

  ::-webkit-scrollbar-corner {
    background: #11a7d9;
  }
`;

export const Title = styled.h1`
  color: #444;
  font-family: "Inter";
  font-size: 30px;
  font-style: normal;
  font-weight: 700;
  line-height: 100%;
  padding-bottom: 15px;
`;

export const ButtonContainer = styled.div`
  width: 150px;
  margin-bottom: 15px;
`;

export const TableContainer = styled.div`
  width: 100%;
  box-shadow: 2px 2px 10px 0px rgba(193, 193, 193, 0.25);
  border-radius: 10px 10px 0px 0px;
  padding: 1px;
  background: #fff;
`;

export const Actions = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 64px;
  height: 64px;
  gap: 5px;
`;

export const BtnActions = styled.div`
  cursor: pointer;
  display: flex;
  height: 30px;
  width: 30px;
  padding: 8px 12px;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  box-shadow: 0px 0.751px 1.503px 0px rgba(16, 24, 40, 0.05);
  background: ${({ $isDelete, $isEdit }) => {
    if ($isDelete) {
      return "#002244";
    }

    if ($isEdit) {
      return "#11a7d9";
    }

    return "#11a7d9";
  }};
`;
