import React, { useCallback, useMemo } from "react";
import { Form } from "antd";
import { useIntl } from "react-intl";
import { useHistory } from "react-router-dom";

import useNotification from "../../../hooks/newUXNotification";

import { useSelector, useDispatch } from "react-redux";

import { changeProfileExhibitor } from "../../../redux/modules/profile/actions";

import Button from "../../../newUXComponents/Button";
import Input from "../../../newUXComponents/Input";

import { validation, INITIAL_VALUES } from "./validations";

import { ContentForm, ContainerBtn, Container } from "./styles";

import { Title, SubTitle, Hr } from "../styles";

const FormAccountExhibitor = () => {
  const intl = useIntl();
  const history = useHistory();
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const notification = useNotification();

  const { exhibitor } = useSelector((state) => state.profile);

  const callBackError = useCallback(
    (message) => {
      notification.displayError(
        intl.formatMessage({
          id: "api.error." + message,
        }),
        "change-exhibitor-error"
      );
    },
    [notification, intl]
  );

  const callBackSuccess = useCallback(() => {
    notification.displaySuccess(
      intl.formatMessage({
        id: "success.registration_data_updated",
      }),
      "change-exhibitor-success"
    );
  }, [notification, intl]);

  const onFinish = (values) => {
    if (values.companyNameCredential) {
      dispatch(
        changeProfileExhibitor(
          values.companyNameCredential,
          callBackSuccess,
          callBackError
        )
      );
      goBack();
    }
  };

  const goBack = () => {
    history.push("/user/view");
  };

  const initialValues = useMemo(() => {
    if (exhibitor?.credentialName) {
      return {
        companyNameCredential: exhibitor.credentialName,
      };
    }

    return INITIAL_VALUES;
  }, [exhibitor]);

  return (
    <Container>
      <Title>
        {intl.formatMessage({
          id: "my_account",
        })}
      </Title>
      <SubTitle>{intl.formatMessage({ id: "general_information" })}</SubTitle>
      <Hr />
      <ContentForm
        layout="vertical"
        form={form}
        name="my-account"
        onFinish={onFinish}
        initialValues={initialValues}
      >
        <Input
          type="text"
          name="companyNameCredential"
          label={intl.formatMessage({ id: "company_name_credential" })}
          placeholder={intl.formatMessage({ id: "company_name_credential" })}
          rules={validation("companyNameCredential", intl)}
          maxLength={255}
        />
        <ContainerBtn>
          <Button
            label={intl.formatMessage({ id: "cancel" })}
            onClick={() => goBack()}
            type="link"
          />

          <Button
            label={intl.formatMessage({ id: "save" })}
            htmlType="submit"
            type="primary"
          />
        </ContainerBtn>
      </ContentForm>
    </Container>
  );
};

export default FormAccountExhibitor;
