import React, {
  useEffect,
  useState,
  useMemo,
  useRef,
  useCallback
} from "react";
import { useField } from "@unform/core";
import { MdError } from "react-icons/md";
import Select from "../Select";
import {
  getCountries,
  getCountryCallingCode,
  parsePhoneNumber,
} from 'react-phone-number-input/input';
import countryListEn from 'react-phone-number-input/locale/en';
import countryListPt from 'react-phone-number-input/locale/pt';
import Input from 'react-phone-number-input/input';

import { useIntl } from "react-intl";

import {
  SelectInputContainer,
  Error,
  RootContainer,
  Label,
  InputRow,
  AsteriskText,
  SelectContainer,
  InputContainer,
  LabelContainer,
} from "./styles";

let brazilCountryCode = "BR";

export const getCountryFromTelephoneNumber = (number) => {
  let value = "";

  if (number) {
    const phoneNumber = parsePhoneNumber(number);
    value = phoneNumber?.country;
  }

  return { value };
}

export const getFullTelephoneNumber = (number, countryCode) => {
  if (!number) {
    return "";
  }

  if (!countryCode) {
    if (number.charAt(0) === "+") {
      return number;
    }

    //Flow due to problems in our select initial value in forms
    return "+" + getCountryCallingCode(brazilCountryCode) + " " + number;
  }

  return "+" + getCountryCallingCode(countryCode) + " " + number;
}

const TelephoneInputWithLabel = ({
  name = "",
  containerStyle = {},
  icon: Icon,
  validationBlur,
  disabled,
  label,
  labelWidth,
  labelOrientation = "horizontal",
  className,
  required,
  defaultCountryCode = brazilCountryCode,
  ...rest
}) => {
  const inputRef = useRef(null);
  const intl = useIntl();
  const selectName = name + "_selected_country";

  const [isFocused, setIsFocused] = useState(false);
  const [isFilled, setIsFilled] = useState(false);
  const [telephoneValue, setTelephoneValue] = useState();
  const [countryCodeValue, setCountryCodeValue] = useState();

  const { fieldName, defaultValue, error, registerField } = useField(name);

  const [countryList, setCountryList] = useState([]);
  const [countrySelectedValue, setCountrySelectedValue] = useState();

  const isPortuguese = useMemo(()=> intl.locale === 'pt', [intl])

  const handleInputFocus = useCallback(() => {
    setIsFocused(true);
  }, []);

  const handleInputBlur = useCallback(async () => {
    let fieldHasValue = !!inputRef.current?.value;
    setIsFocused(false);

    setIsFilled(fieldHasValue);

    if (validationBlur) {
      validationBlur(fieldName, inputRef.current?.value);
    }
  }, []);

  const handleCountrySelection = (countries, countryCode) => {
    const countryValue = countries.find(
        (country) => country.value === countryCode);
    setCountrySelectedValue(countryValue);
    setCountryCodeValue(countryCode);
  };

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      path: "value",
    });

    const reactCountries = getCountries().map((country) => ({
      label: isPortuguese
              ? countryListPt[country] + " (+" + getCountryCallingCode(country) + ")"
              : countryListEn[country] + " (+" + getCountryCallingCode(country) + ")",
      value: country,
    }));
    setCountryList(reactCountries);

    if (defaultValue) {
      setTelephoneValue(defaultValue);
      const phoneNumberCountry = getCountryFromTelephoneNumber(defaultValue);
      handleCountrySelection(reactCountries, phoneNumberCountry.value);
    } else {
      handleCountrySelection(reactCountries, defaultCountryCode);
    }
  }, [fieldName, registerField]);

  const handleCountryChanges = (countryCode) => {
    setTelephoneValue("");
    handleCountrySelection(countryList, countryCode);
  };

  return (
    <RootContainer className={className}>
      <InputRow labelOrientation={labelOrientation}>
        <LabelContainer labelWidth={labelWidth} labelOrientation={labelOrientation}>
          <Label labelWidth={labelWidth}>
            {label}
            {required && <AsteriskText>*</AsteriskText>}
          </Label>
        </LabelContainer>
        <SelectInputContainer
          style={containerStyle}
          isFocused={isFocused}
          isFilled={isFilled}
          isErrored={!!error}
          disabled={disabled}
        >
          <SelectContainer>
            <Select
              name={selectName}
              className="select_telephone_input"
              placeholder={intl.formatMessage({ id: "country_code" })}
              disabled={disabled}
              options={countryList}
              value={countrySelectedValue}
              onChange={(event) => handleCountryChanges(event?.value || undefined)}
              isClearable={true}
              menuPlacement="auto"
            />
          </SelectContainer>
          <InputContainer>
            {Icon && <Icon size={24} />}
            <Input
              country={countryCodeValue}
              international
              value={telephoneValue}
              onChange={setTelephoneValue}
              onFocus={handleInputFocus}
              onBlur={handleInputBlur}
              ref={inputRef}
              disabled={disabled}
              maxLength="25"
              {...rest}
            />
          </InputContainer>
        </SelectInputContainer>
      </InputRow>
      {error && (
        <Error title={error} labelWidth={labelWidth}>
          <MdError color="#e00000" size={20} />
          {error}
        </Error>
      )}
    </RootContainer>
  );
};

export default TelephoneInputWithLabel;
