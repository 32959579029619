import React, { useMemo, useState, useCallback, useEffect } from "react";
import { Form } from "antd";
import { useIntl } from "react-intl";

import { useSelector, useDispatch } from "react-redux";

import { useHistory } from "react-router-dom";

import { changeProfileServiceProvider } from "../../../redux/modules/profile/actions";
import {
  getListCountries,
  getCep,
} from "../../../redux/modules/system/actions";

import useNotification from "../../../hooks/newUXNotification";
import { useLoading } from "../../../hooks/loading";

import cnpjCpfFormatter from "../../../utils/cnpjCpfFormatter";

import {
  getCountryFromTelephoneNumber,
  getFullTelephoneNumber,
  removeCountryFromTelephoneNumber,
} from "../../../newUXComponents/TelephoneInput";

import provision_types from "../../../constants/provisionTypes";
import taxes from "../../../constants/taxes";
import types from "../../../constants/types";

import Button from "../../../newUXComponents/Button";

import { numberMask } from "../../../utils/numberMask";

import FirstForm from "./firstForm";
import SecondForm from "./secondForm";
import ChangeForm from "./changeForm";

import { INITIAL_VALUES } from "./validations";

import {
  ContentForm,
  ContainerBtn,
  CustomSteps,
  StepsContent,
  StepsAction,
} from "./styles";

import { Container, Title, SubTitle, Hr } from "../styles";

const FormAccountServiceProvider = () => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const history = useHistory();

  const [form] = Form.useForm();
  const values = Form.useWatch([], form);
  const notification = useNotification();
  const { handleLoading } = useLoading();

  const optionsProvision = provision_types().filter(
    provisionType => provisionType.value !== 0
  );

  const { accountCreated, user } = useSelector((state) => state.auth);
  const { countries } = useSelector((state) => state.system);

  const [current, setCurrent] = useState(accountCreated ? 1 : 0);
  const [submittable, setSubmittable] = useState(false);
  const [disabledInputs, setDisabledInputs] = useState(false);
  const [documentLabel, setDocumentLabel] = useState(
    intl.formatMessage({ id: "id_number_cnpj" })
  );
  const [documentMask, setDocumentMask] = useState("99.999.999/9999-99");
  const [taxesByPerson, setTaxesByPerson] = useState(taxes);
  const [defaultCountry, setDefaultCountry] = useState('BR')
  const [defaultCountryMobile, setDefaultCountryMobile] = useState('BR')

  const getCepSuccess = useCallback(
    (address) => {
      setDisabledInputs(false);
      form.setFieldsValue({
        address: address.street,
        district: address.neighborhood,
        city: address.city,
        state: address.state,
      });
    },
    [form]
  );

  const handleGoBack = useCallback(() => {
    if (accountCreated) {
      history.goBack();
      return;
    }
    setCurrent(0);
  }, [accountCreated, history]);

  const callBackMessage = useCallback(
    (message, type) => {
      if (type === "success") {
        notification.displaySuccess(
          intl.formatMessage({
            id: "success.registration_data_updated",
          }),
          "change-exhibitor-success"
        );
        history.push('/user/view');
      } else {
        notification.displayError(
          intl.formatMessage({
            id: "api.error." + message,
          }),
          "change-service-provider-error"
        );
      }
      handleLoading(false);
      setDisabledInputs(false);
    },
    [handleGoBack, intl, notification]
  );

  const handleCep = useCallback(
    (cep) => {
      const numericCep = numberMask(cep);

      if (numericCep.length === 8) {
        setDisabledInputs(true);
        dispatch(getCep(numericCep, getCepSuccess, callBackMessage));
      }
    },
    [callBackMessage, getCepSuccess, dispatch]
  );

  const onFinish = () => {
    const formData = form.getFieldsValue(true);

    handleLoading(true);

    const data = {
      type: formData.type,
      companyName: formData.companyName,
      district: formData.district,
      city: formData.city,
      state: formData.state,
      email: formData.email,
      name: formData.name,
      state_inscription:
        formData.stateInscription &&
        formData.stateInscription.replace(/[^a-z\d\s]+/gi, ""),
      city_inscription:
        formData.cityInscription &&
        formData.cityInscription.replace(/[^a-z\d\s]+/gi, ""),
      zipCode: formData.zipCode,
      avenue: formData.address,
      country: formData.country.value || formData.country,
      taxes: formData.taxes,
      document: formData.document.replace(/[^a-z\d\s]+/gi, ""),
      address_number: formData.addressNumber,
      provisionTypes: formData.provisionTypes,
      mobile: getFullTelephoneNumber(
        formData.mobile,
        formData.mobile_selected_country.value,
        defaultCountry
      ),
      phone: getFullTelephoneNumber(
        formData.phone,
        formData.phone_selected_country.value,
        defaultCountryMobile
      ),
    };

    dispatch(changeProfileServiceProvider(data, callBackMessage));
  };

  const initialValues = useMemo(() => {
    if (user.name) {
      if (user?.telephone){
        setDefaultCountry(getCountryFromTelephoneNumber(user.telephone).value)
      }
      if (user?.mobile){
        setDefaultCountryMobile(getCountryFromTelephoneNumber(user.mobile).value)
      }
      return {
        name: user?.name,
        companyName: user?.companyName,
        type: user?.user_type,
        document: cnpjCpfFormatter(user?.document, user?.user_type),
        stateInscription: user?.state_inscription,
        cityInscription: user?.municipal_inscription,
        country: user?.country,
        address: user?.avenue,
        addressNumber: user?.number,
        zipCode: user?.zipCode,
        district: user?.district,
        city: user?.city,
        state: user?.state,
        phone: removeCountryFromTelephoneNumber(user?.telephone),
        mobile: removeCountryFromTelephoneNumber(user?.mobile),
        phone_selected_country: getCountryFromTelephoneNumber(user?.telephone),
        mobile_selected_country: getCountryFromTelephoneNumber(user?.mobile),
        email: user?.email,
        taxes: user.taxes,
        provisionTypes: user?.provisionTypes,
      };
    }

    return INITIAL_VALUES;
  }, [user]);

  useEffect(() => {
    if (values?.type) {
      switch (values?.type) {
        case "F":
          setDocumentLabel(intl.formatMessage({ id: "document" }));
          setDocumentMask("999.999.999-99");
          setTaxesByPerson(taxes[4]);
          return;
        case "J":
          setDocumentLabel(intl.formatMessage({ id: "id_number_cnpj" }));
          setDocumentMask("99.999.999/9999-99");
          setTaxesByPerson([taxes[0], taxes[1], taxes[2], taxes[3]]);
          return;
        case "I":
          setDocumentLabel(
            intl.formatMessage({
              id: "passport",
            })
          );
          setDocumentMask("***************");
          setTaxesByPerson([taxes[5]]);
          return;
        default:
          return "";
      }
    }
  }, [values, intl]);

  const steps = useMemo(
    () => [
      {
        title: intl.formatMessage({
          id: "informations",
        }),
        content: (
          <FirstForm
            optionsProvision={optionsProvision}
            taxesByPerson={taxesByPerson}
            types={types}
            countries={countries}
            refForm={form}
            documentLabel={documentLabel}
            documentMask={documentMask}
          />
        ),
      },
      {
        title: intl.formatMessage({
          id: "account_data",
        }),
        content: (
          <SecondForm
            handleCep={handleCep}
            refForm={form}
            disabledInputs={disabledInputs}
          />
        ),
      },
    ],
    [
      optionsProvision,
      taxesByPerson,
      countries,
      handleCep,
      form,
      disabledInputs,
      documentMask,
      documentLabel,
      intl,
    ]
  );

  const items = steps.map((item) => ({
    key: item.title,
    title: item.title,
  }));

  useEffect(() => {
    form.validateFields({ validateOnly: true }).then(
      () => {
        setSubmittable(true);
      },
      () => {
        setSubmittable(false);
      }
    );
  }, [form, values]);

  useEffect(() => {
    dispatch(getListCountries());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Container>
      <Title>
        {intl.formatMessage({
          id: "my_account",
        })}
      </Title>
      <SubTitle>{intl.formatMessage({ id: "general_information" })}</SubTitle>
      <Hr />
      <ContentForm
        layout="vertical"
        form={form}
        name="my-account"
        onFinish={onFinish}
        initialValues={initialValues}
      >
        {accountCreated ? (
          <ChangeForm
            handleCep={handleCep}
            refForm={form}
            disabledInputs={disabledInputs}
            optionsProvision={optionsProvision}
            defaultCountry={defaultCountry}
            defaultCountryMobile={defaultCountryMobile}
          />
        ) : (
          <>
            <CustomSteps
              current={current}
              items={items}
              labelPlacement="vertical"
            />
            <StepsContent>{steps[current].content}</StepsContent>
          </>
        )}
        <StepsAction>
          <ContainerBtn>
            <Button
              label={
                current === 1
                  ? intl.formatMessage({ id: "goback" })
                  : intl.formatMessage({ id: "next" })
              }
              onClick={() => (current === 0 ? setCurrent(1) : handleGoBack())}
              type={current === 0 && submittable ? "primary" : "link"}
              disabled={!submittable}
            />

            {current === 1 && (
              <Button
                label={intl.formatMessage({ id: "save" })}
                type="primary"
                htmlType="submit"
              />
            )}
          </ContainerBtn>
        </StepsAction>
      </ContentForm>
    </Container>
  );
};

export default FormAccountServiceProvider;
