import styled, { css } from 'styled-components'
import { Typography } from 'antd';

export const Container = styled.div`
  width: 100%;
  height: 94vh;
  background: #fcfcfc;
  padding: 25px 75px 50px 70px;
  overflow-y: auto;

  ::-webkit-scrollbar {
    width: 4px;
    background-color: #fcfcfc;
  }

  ::-webkit-scrollbar-thumb {
    background: #11a7d9;
    -webkit-border-radius: 1ex;
    -webkit-box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.75);
  }

  ::-webkit-scrollbar-corner {
    background: #11a7d9;
  }
`;

export const Title = styled.h1`
  color: #11a7d9;
  font-family: "Inter";
  font-size: 30px;
  font-style: normal;
  font-weight: 700;
  line-height: 100%;
  border-bottom: 1px solid #d4d4d4;
  padding-bottom: 15px;
`;

export const Hr = styled.hr`
  border-radius: 30px;
  background: #11a7d9;
  width: 142px;
  height: 2px;
  margin-bottom: 24px;
  margin-top: -19.5px;
`;

export const ProgressContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 35px;
  width: 100%;
  margin: 25px 0;

  h3 {
    color: #024;
    font-family: 'Open Sans';
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 170%;
    margin: 0;
  }
`

export const ChecklistArea = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  gap: 3%;
  align-items: center;
  flex-wrap: wrap;
`

export const Card = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 31%;
  background-color: #FFF;
  height: 470px;
  border-radius: 10px;
  margin-bottom: 40px ;

  ${(props) =>
    props.status &&
    css`
      border: ${props.status === 'Delayed' ? '1px solid #FF8080' : props.status === 'Concluded' ? '1px solid #05A660': '1px solid #F2F2F5'};
      box-shadow: ${props.status === 'Delayed' ? '2px 2px 10px 0px rgba(255, 92, 92, 0.55)' : props.status === 'Concluded' ? '2px 2px 10px 0px rgba(73, 215, 140, 0.55)': 'none'};
    `}
`

export const CardTitle = styled(Typography.Paragraph)`
  color: '#000';
  font-family: 'Inter';
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  width: 100%;
  margin: 0;
`
export const CardSubtitle = styled.p`
  color:  #676F82;
  font-family: 'Inter';
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  width: 100%;
  margin: 0;
`

export const CardText = styled(Typography.Paragraph)`
  color:  #676F82;
  font-family: 'Inter';
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  text-align: justify;
  text-justify: inter-word; 
`
export const CardTextContainer = styled.div `
  width: 100%;
  height: 20%;
  padding: 0 16px;
`

export const CardHeader = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  width: 100%;
  height: 25%;
  padding: 16px;
`

export const CardFooter = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  width: 100%;
  height: 7%;
  padding: 16px;
  margin-bottom: 15px;
`

export const DetailButton = styled.button`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border-radius: 6px;
  border: 0.867px solid #11A7D9;
  background: #11A7D9;
  box-shadow: 0px 0.867px 1.735px 0px rgba(16, 24, 40, 0.05);
  cursor: pointer;
  padding: 5px;
  color: #fff;
  font-family: 'Open Sans';
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  width: 96px; 
  height: 30px;
`

export const CardIMG = styled.div`
  height: 40%;
  width: 100%;
  background-color: #63696c;
  display: flex;
  justify-content: center;
  align-items: center;
  border-top-left-radius: 10px; 
  border-top-right-radius: 10px; 

  img {
    color: white;
    border-top-left-radius: 10px; 
    border-top-right-radius: 10px; 
    width: 100%;
    height: 100%;
    object-fit: none;
  }
`