import React, { useState } from "react";

import { useIntl } from "react-intl";
import { Checkbox } from "antd";

import CustomModal from "../../../components/CustomModal";

import Button from "../../../newUXComponents/Button";

import {
  Container,
  ContainerButton,
  Description,
  Divider,
  Header,
  Message,
} from "./styles";

const ConfirmationAssociateCompany = ({
  open,
  handleClose,
  id,
  confirmation,
  title = "",
  isRemovingPermission = false
}) => {
  const intl = useIntl();

  const [approvalCheckbox, setApprovalCheckbox] = useState(false);

  const handleConfirmationEvent = () => {
    handleClose();
    confirmation();
  };

  return (
    <CustomModal id={id} isModalVisible={open} handleClose={() => {}}>
      <Container>
        {title && (
          <>
            <Header>
              <span>{title}</span>
            </Header>
            <Divider />
          </>
        )}
        <Description>
          {intl.formatMessage({
            id: isRemovingPermission ? "disassociate_service_provider_modal_message": "associate_service_provider_modal_message",
          })}
        </Description>
        <Message>
          <Checkbox
            onChange={() => {
              setApprovalCheckbox(!approvalCheckbox);
            }}
          >
            {intl.formatMessage({
              id: "modal_approval_checkbox_message",
            })}
          </Checkbox>
        </Message>
        <ContainerButton>
          <Button
            type="primary"
            onClick={() => handleConfirmationEvent()}
            label={intl.formatMessage({ id: "yes" })}
            disabled={!approvalCheckbox}
          />
          <Button
            type="link"
            onClick={() => handleClose()}
            label={intl.formatMessage({ id: "no" })}
          />
        </ContainerButton>
      </Container>
    </CustomModal>
  );
};

export default ConfirmationAssociateCompany;
