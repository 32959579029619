import React from "react";

const Image = (props) => {
  const { size = "64", color = "white", filled = false } = props;

  if (filled) {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={size}
        height={size}
        viewBox="0 0 17 16"
        fill="none"
      >
        <g clipPath="url(#clip0_295_45216)">
          <path
            d="M14 3H3C2.72386 3 2.5 3.22386 2.5 3.5V12.5C2.5 12.7761 2.72386 13 3 13H14C14.2761 13 14.5 12.7761 14.5 12.5V3.5C14.5 3.22386 14.2761 3 14 3Z"
            stroke="#444444"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M10.25 6.75C10.5261 6.75 10.75 6.52614 10.75 6.25C10.75 5.97386 10.5261 5.75 10.25 5.75C9.97386 5.75 9.75 5.97386 9.75 6.25C9.75 6.52614 9.97386 6.75 10.25 6.75Z"
            fill="#444444"
          />
          <path
            d="M9.70703 10.2501L11.3127 8.64633C11.4064 8.55263 11.5335 8.5 11.6661 8.5C11.7986 8.5 11.9258 8.55263 12.0195 8.64633L14.5002 11.1288"
            stroke="#444444"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M2.5 10.543L5.89625 7.14615C5.94269 7.09967 5.99783 7.06279 6.05853 7.03763C6.11923 7.01246 6.18429 6.99951 6.25 6.99951C6.31571 6.99951 6.38077 7.01246 6.44147 7.03763C6.50217 7.06279 6.55731 7.09967 6.60375 7.14615L12.4569 12.9999"
            stroke="#444444"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </g>
        <defs>
          <clipPath id="clip0_295_45216">
            <rect
              width={size}
              height={size}
              fill="white"
              transform="translate(0.5)"
            />
          </clipPath>
        </defs>
      </svg>
    );
  }

  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 64 64"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_1108_2746)">
        <path
          d="M54 12H20C18.8954 12 18 12.8954 18 14V40C18 41.1046 18.8954 42 20 42H54C55.1046 42 56 41.1046 56 40V14C56 12.8954 55.1046 12 54 12Z"
          stroke={color}
          strokeWidth="3"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M48 42V50C48 50.5304 47.7893 51.0391 47.4142 51.4142C47.0391 51.7893 46.5304 52 46 52H10C9.46957 52 8.96086 51.7893 8.58579 51.4142C8.21071 51.0391 8 50.5304 8 50V22C8 21.4696 8.21071 20.9609 8.58579 20.5858C8.96086 20.2107 9.46957 20 10 20H18"
          stroke={color}
          strokeWidth="3"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M18 32.1725L27.585 22.585C27.7707 22.399 27.9913 22.2515 28.2341 22.1509C28.4769 22.0502 28.7372 21.9984 29 21.9984C29.2628 21.9984 29.5231 22.0502 29.7659 22.1509C30.0087 22.2515 30.2293 22.399 30.415 22.585L41.3275 33.5L47.25 27.585C47.625 27.2102 48.1335 26.9997 48.6637 26.9997C49.194 26.9997 49.7025 27.2102 50.0775 27.585L56 33.515"
          stroke={color}
          strokeWidth="3"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_1108_2746">
          <rect width={size} height={size} fill={color} />
        </clipPath>
      </defs>
    </svg>
  );
};

export default Image;
