import React, { useMemo } from "react";
import { useHistory } from "react-router-dom";
import { useIntl } from "react-intl";
import { Tabs, TabTitle, TabBox } from "./styles";

import { useSelector } from "react-redux";

const MyAccountHeader = ({ isProfile = false, isTermsOfUse = false }) => {
  const history = useHistory();
  const intl = useIntl();

  const { permission } = useSelector((state) => state.auth);

  const hideTab = useMemo(() => {
    if (
      permission.includes("EDIT_EXHIBITOR_PROFILE_INFORMATION") ||
      permission.includes("EDIT_PROFILE_INFORMATION")
    ) {
      return false;
    }

    return true;
  }, [permission]);

  return (
    <Tabs>
      <TabBox
        onClick={() => history.push("/user/view")}
        $removeTab={hideTab}
        className={isProfile && "selectedTab"}
      >
        <TabTitle>{intl.formatMessage({ id: "general_information" })}</TabTitle>
      </TabBox>
      <TabBox
        onClick={() => history.push("/user/terms-of-use")}
        $removeTab={!permission.includes("ACCESS_TERMS_OF_USE")}
        className={isTermsOfUse && "selectedTab"}
      >
        <TabTitle>{intl.formatMessage({ id: "terms_of_use" })}</TabTitle>
      </TabBox>
    </Tabs>
  );
};

export default MyAccountHeader;
