import produce from "immer";

const INITIAL_STATE = {
  associatesList: [],
  typesCredentials: [],
  quota: {},
  credentialScheduleAvailableDates: [],
  credentialScheduleAvailableTimes: [],
};

const credentials = (state = INITIAL_STATE, action) => {
  return produce(state, (draft) => {
    switch (action.type) {
      case "SET_LIST_CREDENTIALS_SUCCESS": {
        const { credentials } = action.payload;

        draft.associatesList = credentials;

        break;
      }

      case "GET_TYPE_CREDENTIAL_SUCCESS": {
        const { types } = action.payload;

        draft.typesCredentials = types;

        localStorage.setItem(
          "InformaMarket:typesCredentials ",
          JSON.stringify(types)
        );

        break;
      }

      case "GET_COLLABORATOR_OF_CONTRACT_SUCCESS": {
        const { collaborator } = action.payload;

        draft.collaborator = collaborator;

        localStorage.setItem(
          "InformaMarket:collaborator ",
          JSON.stringify(collaborator)
        );

        break;
      }

      case "SET_QUOTA_CREDENTIAL_SUCCESS": {
        const { quota } = action.payload;

        draft.quota = quota;

        break;
      }

      case "SET_CREDENTIAL_SCHEDULE_SUCCESS": {
        const { credentialSchedule } = action.payload;

        draft.credentialSchedule = credentialSchedule;

        localStorage.setItem(
          "InformaMarket:credentialSchedule ",
          JSON.stringify(credentialSchedule)
        );

        break;
      }

      case "CLEAN_QUOTA_CREDENTIAL": {
        draft.associatesList = [];
        draft.quota = {};

        break;
      }

      case "SET_CREDENTIAL_SCHEDULE_AVAILABLE_DATES": {
        const { dates } = action.payload;

        draft.credentialScheduleAvailableDates = dates;

        break;
      }

      case "SET_CREDENTIAL_SCHEDULE_AVAILABLE_TIMES": {
        const { times } = action.payload;

        draft.credentialScheduleAvailableTimes = times;

        break;
      }

      case "CLEAR_CREDENTIAL_SCHEDULE_AVAILABLE_DATES": {
        draft.credentialScheduleAvailableDates = [];

        break;
      }

      case "CLEAR_CREDENTIAL_SCHEDULE_AVAILABLE_TIMES": {
        draft.credentialScheduleAvailableTimes = [];

        break;
      }

      default: {
        if (localStorage.getItem("InformaMarket:associatesList")) {
          const associatesList = JSON.parse(
            localStorage.getItem("InformaMarket:associatesList")
          );

          draft.associatesList = associatesList;
        }

        if (localStorage.getItem("InformaMarket:credentialSchedule")) {
          const credentialSchedule = JSON.parse(
            localStorage.getItem("InformaMarket:credentialSchedule")
          );

          draft.credentialSchedule = credentialSchedule;
        }

        return draft;
      }
    }
  });
};

export default credentials;
