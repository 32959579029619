import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 365px;
  max-height: 90vh;
`

export const Header = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 15px;
`

export const Title = styled.h2`
  color:  #11A7D9;
  text-align: center;
  font-family: 'Open Sans';
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 32px;
  letter-spacing: -0.48px;
  padding: 16px 32px 0 32px;
  margin: 0;
`

export const Hr = styled.hr`
  border: 0.5px solid #D4D4D4;
`

export const Content = styled.p`
  color: #676F82;
  font-family: 'Open Sans';
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  padding: 16px 32px;
  text-align: justify;
  text-justify: inter-word;
  white-space: pre-line;

  p {
    color: #444;
    text-align: center;
    font-family: "Open Sans";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
`

export const ButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 5px;
  width: 100%;
`