import produce from "immer";

const INITIAL_STATE = {
  exhibitor: {},
};

const profile = (state = INITIAL_STATE, action) => {
  return produce(state, (draft) => {
    switch (action.type) {
      case "SET_PROFILE_EXHIBITOR": {
        const { profile } = action.payload;

        draft.exhibitor = profile;

        localStorage.setItem("InformaMarket:profile", JSON.stringify(profile));

        break;
      }

      case "CHANGE_PROFILE_EXHIBITOR_SUCCESS": {
        const { newCredentialName } = action.payload;

        draft.exhibitor.credentialName = newCredentialName;

        break;
      }

      case "CLEAR_PROFILE_EXHIBITOR": {
        draft.exhibitor = [];
        localStorage.removeItem("InformaMarket:profile");

        break;
      }

      default: {
        return draft;
      }
    }
  });
};

export default profile;
