import React, { createContext, useReducer, useMemo } from "react";
import { dialogReducer, Types } from "./reducer";

const initialState = {
  dialogs: [],
};

const DialogContext = createContext({
  state: initialState,
  createDialog: () => {},
  removeDialog: () => {},
  resetDialogs: () => {},
});

const mainReducer = ({ dialogs }, action) => ({
  dialogs: dialogReducer(dialogs, action),
});

const DialogProvider = ({ children }) => {
  const [dialogs, dispatch] = useReducer(mainReducer, initialState);

  const createDialog = (dialog) =>
    dispatch({ type: Types.create, payload: dialog });

  const removeDialog = (id) => {
    dispatch({ type: Types.close, payload: { id } });
    setTimeout(() => {
      dispatch({ type: Types.remove, payload: { id } });
    }, 300);
  };

  const resetDialogs = () => dispatch({ type: Types.reset, payload: {} });

  const state = dialogs;

  const renderedDialogs = useMemo(() => {
    return (
      dialogs.dialogs.length &&
      dialogs.dialogs.map(({ id, open, Component, props: dialogProps = {} }) => {
        return (
          <Component
            key={id}
            id={id}
            handleClose={() => removeDialog(id)}
            open={open}
            {...dialogProps}
          />
        );
      })
    );
  }, [dialogs]);

  return (
    <DialogContext.Provider
      value={{ state, createDialog, removeDialog, resetDialogs }}
    >
      {children}
      {renderedDialogs !== 0 ? renderedDialogs : null}
    </DialogContext.Provider>
  );
};

export { DialogProvider, DialogContext };
