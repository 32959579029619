import produce from "immer";

const INITIAL_STATE = {
  associatedCompanies: [],
};

const associateCompany = (state = INITIAL_STATE, action) => {
  return produce(state, (draft) => {
    switch (action.type) {
      case "SET_ALL_ASSOCIATE_COMPANY": {
        const { associatedCompanies } = action.payload;

        draft.associatedCompanies = associatedCompanies;

        localStorage.setItem(
          "InformaMarket:associatedCompanies",
          JSON.stringify(associatedCompanies)
        );

        break;
      }

      default: {
        if (localStorage.getItem("InformaMarket:associatedCompanies")) {
          const associatedCompanies = JSON.parse(
            localStorage.getItem("InformaMarket:associatedCompanies")
          );

          draft.associatedCompanies = associatedCompanies;
        }

        return draft;
      }
    }
  });
};

export default associateCompany;
