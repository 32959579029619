import React from "react";
import { useHistory } from "react-router-dom";
import { useIntl } from "react-intl";
import { Tabs, TabTitle, TabBox } from "./styles";

import { useSelector } from "react-redux";

const CredentialsHeader = ({
  isAssociateSelected = false,
  isRegisterSelected = false,
  isScheduleSelected = false,
  isTransferSelected = false,
}) => {
  const history = useHistory();
  const intl = useIntl();

  const { permission } = useSelector((state) => state.auth);

  return (
    <Tabs>
      <TabBox
        onClick={() => history.push("/credentials/associate")}
        $removeTab={!permission.includes("MANAGE_CREDENTIALS")}
        className={isAssociateSelected && "selectedTab"}
      >
        <TabTitle>
          {intl.formatMessage({ id: "associate_credential" })}
        </TabTitle>
      </TabBox>
      <TabBox
        onClick={() => history.push("/credentials/register")}
        $removeTab={!permission.includes("MANAGE_ASSOCIATE_COLLABORATOR")}
        className={isRegisterSelected && "selectedTab"}
      >
        <TabTitle>
          {intl.formatMessage({ id: "employee_registration" })}
        </TabTitle>
      </TabBox>
      <TabBox
        onClick={() => history.push("/credentials/schedule")}
        $removeTab={!permission.includes("MANAGE_CREDENTIALS")}
        className={isScheduleSelected && "selectedTab"}
      >
        <TabTitle>{intl.formatMessage({ id: "schedule_withdrawal" })}</TabTitle>
      </TabBox>
      <TabBox
        onClick={() => history.push("/credentials/transfer")}
        $removeTab={!permission.includes("TRANSFER_CREDENTIALS")}
        className={isTransferSelected && "selectedTab"}
      >
        <TabTitle>{intl.formatMessage({ id: "credential_transfer" })}</TabTitle>
      </TabBox>
    </Tabs>
  );
};

export default CredentialsHeader;
