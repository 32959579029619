import React from "react";

const Edit = (props) => {
  const { size = "16", color = "#fff" } = props;

  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="PencilSimpleLine" clipPath="url(#clip0_1365_21048)">
        <path
          id="Vector"
          d="M6 13.5H3C2.86739 13.5 2.74021 13.4473 2.64645 13.3536C2.55268 13.2598 2.5 13.1326 2.5 13V10.2069C2.50006 10.0744 2.55266 9.94745 2.64625 9.85375L10.3538 2.14625C10.4475 2.05256 10.5746 1.99992 10.7072 1.99992C10.8397 1.99992 10.9669 2.05256 11.0606 2.14625L13.8538 4.9375C13.9474 5.03126 14.0001 5.15839 14.0001 5.29094C14.0001 5.42349 13.9474 5.55062 13.8538 5.64438L6 13.5Z"
          stroke={color}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          id="Vector_2"
          d="M13.5 13.5H6"
          stroke={color}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          id="Vector_3"
          d="M8.5 4L12 7.5"
          stroke={color}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_1365_21048">
          <rect width={size} height={size} fill={color} />
        </clipPath>
      </defs>
    </svg>
  );
};

export default Edit;
