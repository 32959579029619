import React, { createContext, useContext, useCallback, useState } from "react";

const LoadingContext = createContext({});

const LoadingProvider = ({ children }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [isAccountLoading, setIsAccountLoading] = useState(false);
  const [accountTimer, setAccountTimer] = useState();

  const handleLoading = useCallback((loading) => {
    setIsLoading(loading);
  }, []);

  const handleAccountLoading = useCallback(
    (loading, delay = 100) => {
      if (loading) {
        clearTimeout(accountTimer);
        setAccountTimer();
        setIsAccountLoading(true);
      } else {
        const timerRef = setTimeout(() => {
          setIsAccountLoading(false);
          setAccountTimer();
        }, delay);
        setAccountTimer(timerRef);
      }
    },
    [accountTimer]
  );

  return (
    <LoadingContext.Provider
      value={{
        isLoading,
        handleLoading,
        isAccountLoading,
        handleAccountLoading,
        debouncing: !!accountTimer,
      }}
    >
      {children}
    </LoadingContext.Provider>
  );
};

const useLoading = () => {
  const context = useContext(LoadingContext);

  if (!context) {
    throw new Error("useLoading must be used within a LoadingProvider");
  }

  return context;
};

export { LoadingProvider, useLoading };
