import { useIntl } from "react-intl";

const intl = useIntl

const client_types = () => {
  const client_array = [
    {
      label: intl().formatMessage({ id: "standard" }),
      value: "Standard"
    },
    {
      label: intl().formatMessage({ id: "sponsor" }),
      value: "Sponsor"
    },
    {
      label: intl().formatMessage({ id: "digital" }),
      value: "Digital"
    },
    {
      label: intl().formatMessage({ id: "barter" }),
      value: "Barter"
    },
  ];

  return client_array;
}

export default client_types;

