export function authRequest(authState) {
  return {
    type: "AUTH_REQUEST",
    payload: {
      email: authState.email,
      password: authState.password,
      keepSignedIn: authState.keepSignedIn,
      callbackLoading: authState.callbackLoading,
    },
  };
}

export function authSuccess(authState) {
  return {
    type: "AUTH_SUCCESS",
    payload: {
      user: authState.user,
      token: authState.token,
      customerCenterToken: authState.customerCenterToken,
      serviceProviderLoggingAsAnExhibitor:
        authState.serviceProviderLoggingAsAnExhibitor,
      adminToken: authState.adminToken,
      keepSignedIn: authState.keepSignedIn,
      accountCreated: authState.accountCreated,
    },
  };
}

export function logoutRequest(logoutState) {
  return {
    type: "LOGOUT_REQUEST",
    payload: {
      token: logoutState.token,
      callbackLoading: logoutState.callbackLoading,
      tokenExpired: logoutState.tokenExpired,
      user: logoutState.user,
    },
  };
}

export function logoutSuccess() {
  return {
    type: "LOGOUT_SUCCESS",
  };
}

export function preLogoutRequest() {
  return {
    type: "PRE_LOGOUT_REQUEST",
  };
}

export function loadUser() {
  return {
    type: "LOAD_USER",
  };
}

export function updateUser(authState) {
  return {
    type: "UPDATE_USER",
    payload: {
      user: authState.user,
    },
  };
}
