import React from "react";

const IdentificationCard = (props) => {
  const { size = "26", color = "#444444" } = props;

  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 26 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_539_13182)">
        <path
          d="M15.4375 11.375H19.5"
          stroke={color}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M15.4375 14.625H19.5"
          stroke={color}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M21.9375 4.875H4.0625C3.61377 4.875 3.25 5.23877 3.25 5.6875V20.3125C3.25 20.7612 3.61377 21.125 4.0625 21.125H21.9375C22.3862 21.125 22.75 20.7612 22.75 20.3125V5.6875C22.75 5.23877 22.3862 4.875 21.9375 4.875Z"
          stroke={color}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M9.75 14.625C11.0962 14.625 12.1875 13.5337 12.1875 12.1875C12.1875 10.8413 11.0962 9.75 9.75 9.75C8.40381 9.75 7.3125 10.8413 7.3125 12.1875C7.3125 13.5337 8.40381 14.625 9.75 14.625Z"
          stroke={color}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M6.5 17.0625C6.86055 15.6609 8.2357 14.625 9.75 14.625C11.2643 14.625 12.6405 15.6599 13 17.0625"
          stroke={color}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_824_1637">
          <rect width={size} height={size} fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default IdentificationCard;
