import React, { useEffect, useRef, useState, useMemo } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useSelector } from "react-redux";
import { useHistory } from "react-router";
import AnimatedMainContainer from "../../components/AnimatedMainContainer";
import { useToast } from "../../hooks/toast";
import * as Yup from "yup";
import getValidationErrors from "../../utils/getValidationErrors";
import api from "../../service";
import { useCallback } from "react";
import InputWithLabel from "../../components/InputWithLabel";
import FormButton from "../../components/FormButton";
import { Form } from "@unform/web";
import { validateSingleFieldOnBlur } from "../../utils/formValidations";

import {
  Container,
  FormContainer,
  TitleContainer,
  Title,
  FieldsContainer,
  Horizontal,
  FilterActionsContainer,
  SubmitContainer,
  LinkButton,
  GoBackContainer,
} from "./styles";
import SelectWithLabel from "../../components/SelectWithLabel";
import Table from "../../components/Table";
import Badge from "../../components/Badge";
import { useLoading } from "../../hooks/loading";

const Events = () => {
  const { token } = useSelector((state) => state.auth);
  const [data, setData] = useState([]);
  const formRef = useRef(null);
  const intl = useIntl();
  const history = useHistory();
  const { addToast } = useToast();
  const { handleLoading } = useLoading();
  const [loading, setLoading] = useState(false);
  const [formWithSearchedValues, setFormWithSearchedValues] = useState();
  const [key, setKey] = useState();

  const schema = Yup.object().shape({
    prefix: Yup.string(),
    name: Yup.string(),
    status: Yup.bool().nullable(),
  });

  useEffect(() => {
    handleLoading(true);
    if (
      history.location.state != null &&
      history.location.state.prevPath.pathname.includes("/events/")
    ) {
      if (sessionStorage.getItem("@informacp/eventNavigation") != null) {
        setData(
          JSON.parse(sessionStorage.getItem("@informacp/eventNavigation"))
            .eventSearchResult
        );
        handleLoading(false);
      } else {
        callEventApi();
      }
    } else {
      callEventApi();
    }
  }, [addToast, handleLoading, intl, token, history]);

  const handleSubmit = useCallback(
    async (data) => {
      try {
        setLoading(true);
        clearSessionStorage();
        if (loading) {
          return;
        }
        formRef.current?.setErrors({});

        await schema.validate(data, { abortEarly: false });

        api
          .patch(
            "/admin/events",
            {
              eventEditionCode: data.prefix,
              eventEditionName: data.name,
              active: data.status,
            },
            {
              headers: {
                "Content-Type": "application/json",
                Accept: "*/*",
                Authorization: `Bearer ${token}`,
              },
            }
          )
          .then((result) => {
            setLoading(false);
            setData(result.data.output);
            sessionStorage.setItem(
              "@informacp/eventNavigation",
              JSON.stringify({
                eventCode: data.prefix,
                eventName: data.name,
                eventStatus: data.status,
                eventSearchResult: result.data.output,
              })
            );
          })
          .catch((err) => {
            setLoading(false);
            addToast({
              type: "error",
              title: intl.formatMessage({
                id: "error",
              }),
              description: intl.formatMessage({
                id: "api.error." + err.response.data.code,
              }),
            });
          });
      } catch (err) {
        setLoading(false);
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);

          formRef.current?.setErrors(errors);

          return;
        }
      }
    },
    [addToast, intl, schema, token, loading]
  );

  const pushBack = () => {
    history.push("/dashboard");
  };

  const clearFields = () => {
    formRef.current.reset();
    formRef.current.setFieldValue("event", {
      label: intl.formatMessage({ id: "event_edition" }),
      value: null,
    });
    formRef.current.setFieldValue("status", {
      label: intl.formatMessage({ id: "both" }),
      value: null,
    });
    callEventApi();
  };

  const validationBlur = (name) => {
    let errors = formRef.current.getErrors();

    validateSingleFieldOnBlur(errors, schema, name, formRef);
  };

  const columns = [
    {
      name: intl.formatMessage({ id: "prefix" }),
      selector: (row) => row.eventEditionCode,
      sortable: true,
      maxWidth: "400px",
    },
    {
      name: intl.formatMessage({ id: "name" }),
      selector: (row) => row.eventEditionName,
      sortable: true,
      maxWidth: "400px",
    },
    {
      name: "Status",
      selector: (row) => row.active,
      cell: (row) => {
        if (row.active) {
          return (
            <Badge
              color="#33d27e"
              text={intl.formatMessage({ id: "active" })}
            />
          );
        }
        return (
          <Badge
            color="#C9C9C9"
            text={intl.formatMessage({ id: "inactive" })}
          />
        );
      },
      maxWidth: "200px",
    },
    {
      cell: (row) => (
        <LinkButton to={`/event-checklist/${row.idNumber}`}>
          <Badge
            color="#002244"
            text={intl.formatMessage({ id: "users_checklist" })}
          />
        </LinkButton>
      ),
      right: true,
    },
    {
      cell: (row) => (
        <LinkButton to={`/events/${row.eventEditionCode}`}>
          <Badge
            color="#002244"
            text={intl.formatMessage({ id: "see_details" })}
          />
        </LinkButton>
      ),
      // maxWidth: "calc(100% - 800px)",
      right: true,
    },
  ];

  const clearSessionStorage = () => {
    sessionStorage.removeItem("@informacp/eventNavigation");
  };

  const callEventApi = () => {
    handleLoading(true);
    clearSessionStorage();
    api
      .get("/admin/events", {
        headers: {
          "Content-Type": "application/json",
          Accept: "*/*",
          Authorization: `Bearer ${token}`,
        },
      })
      .then((result) => {
        setData(result.data.output);
      })
      .catch((err) => {
        addToast({
          type: "error",
          title: intl.formatMessage({
            id: "error",
          }),
          description: intl.formatMessage({
            id: "api.error." + err.response.data.code,
          }),
        });
      })
      .finally(() => {
        handleLoading(false);
      });
  };

  const statusOptions = useMemo(
    () => [
      {
        label: intl.formatMessage({ id: "active" }),
        value: true,
      },
      {
        label: intl.formatMessage({ id: "inactive" }),
        value: false,
      },
      {
        label: intl.formatMessage({ id: "both" }),
        value: null,
      },
    ],
    [intl]
  );

  useEffect(() => {
    const eventNavigationFromSessionStorage = sessionStorage.getItem(
      "@informacp/eventNavigation"
    );

    if (eventNavigationFromSessionStorage) {
      const { eventCode, eventName, eventStatus } = JSON.parse(
        eventNavigationFromSessionStorage
      );
      const status = statusOptions.find((item) => item.value === eventStatus);
      setFormWithSearchedValues({
        prefix: eventCode,
        name: eventName,
        status: status,
      });
      setKey("session-filter");
    } else {
      const statusDefault = statusOptions.find((item) => item.value === null);
      setFormWithSearchedValues({
        prefix: "",
        name: "",
        status: statusDefault,
      });
      setKey("session-filter");
    }
  }, [intl, statusOptions]);

  return (
    <AnimatedMainContainer
      breadcrumb={intl.formatMessage({ id: "breadcrumb.events" })}
    >
      <Container>
        <FormContainer>
          <Form
            ref={formRef}
            onSubmit={handleSubmit}
            initialData={formWithSearchedValues}
            key={key}
          >
            <TitleContainer>
              <Title>
                <FormattedMessage id="search_for_event_edition" />
              </Title>
              <GoBackContainer>
                <LinkButton onClick={pushBack}>
                  <Badge
                    color="#002244"
                    text={intl.formatMessage({ id: "goback" })}
                    fontSize="12px"
                  />
                </LinkButton>
              </GoBackContainer>
            </TitleContainer>
            <FieldsContainer>
              <Horizontal>
                <InputWithLabel
                  name="prefix"
                  validationBlur={validationBlur}
                  label={intl.formatMessage({ id: "event_prefix" })}
                />
                <InputWithLabel
                  name="name"
                  validationBlur={validationBlur}
                  label={intl.formatMessage({ id: "name" })}
                />
                <SelectWithLabel
                  name="status"
                  options={statusOptions}
                  validationBlur={validationBlur}
                  label="Status"
                />
              </Horizontal>
              <Horizontal>
                <FilterActionsContainer>
                  <SubmitContainer>
                    <FormButton
                      color="#C9C9C9"
                      type="button"
                      onClick={clearFields}
                    >
                      <FormattedMessage id="clear_fields" />
                    </FormButton>
                  </SubmitContainer>
                  <SubmitContainer>
                    <FormButton type="submit" loading={loading}>
                      <FormattedMessage id="search" />
                    </FormButton>
                  </SubmitContainer>
                </FilterActionsContainer>
              </Horizontal>
            </FieldsContainer>
          </Form>
        </FormContainer>
      </Container>
      <Table
        columns={columns}
        data={data}
        title={intl.formatMessage({ id: "results" })}
      />
    </AnimatedMainContainer>
  );
};

export default Events;
