import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  height: 94vh;
  background: #fcfcfc;
  padding: 25px 75px 50px 70px;
  overflow-y: auto;

  ::-webkit-scrollbar {
    width: 4px;
    background-color: #fcfcfc;
  }

  ::-webkit-scrollbar-thumb {
    background: #11a7d9;
    -webkit-border-radius: 1ex;
    -webkit-box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.75);
  }

  ::-webkit-scrollbar-corner {
    background: #11a7d9;
  }
`;

export const Title = styled.h1`
  color: #444;
  font-family: "Inter";
  font-size: 30px;
  font-style: normal;
  font-weight: 700;
  line-height: 100%;
  padding-bottom: 15px;
`;

export const Cards = styled.div`
  width: 100%;
  height: 98px;
  flex-shrink: 0;
  display: flex;
  justify-content: space-between;
  flex-direction: row;

  @media (max-width: 900px) {
    flex-direction: column;
    gap: 10px;
    justify-content: flex-start;
    height: auto;
  }
`;

export const Card = styled.div`
  display: flex;
  width: 310px;
  height: 97px;
  padding: 20px 16px 21px 17px;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  border-radius: 20px;
  background: #fff;
  box-shadow: 2px 2px 10px 0px rgba(193, 193, 193, 0.25);
`;

export const CardBody = styled.div`
  display: flex;
  width: 277px;
  height: 56px;
  padding: 1px 8px 0px 7px;
  justify-content: center;
  align-items: center;
  gap: 103.002px;
  flex-shrink: 0;
`;

export const CardText = styled.div`
  display: flex;
  flex-direction: column;
  width: 116.998px;
  height: 55px;
  flex-shrink: 0;
  color: #6c6c6c;
  font-family: "Open Sans";
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 150%;

  span:last-child {
    color: #024;
    font-family: "Open Sans";
    font-size: 30px;
    font-style: normal;
    font-weight: 700;
    line-height: 32px;
    letter-spacing: -0.6px;
  }
`;

export const CardIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 42px;
  height: 42px;
  background: #f4f7fe;
  border-radius: 20px;
  flex-shrink: 0;
`;

export const ContainerBtn = styled.div`
  display: inline-flex;
  align-items: flex-start;
  gap: 10px;
  margin: 25px 0 16px;
`;

export const Content = styled.div`
  width: 100%;
  box-shadow: 2px 2px 10px 0px rgba(193, 193, 193, 0.25);
  border-radius: 0px 0px 10px 10px;
  padding: 1px;
  background: #fff;
`;

export const Actions = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 64px;
  height: 64px;
  gap: 5px;
`;

export const BtnActions = styled.div`
  display: flex;
  height: 30px;
  width: 30px;
  padding: 8px 12px;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  cursor: pointer;
  box-shadow: 0px 0.751px 1.503px 0px rgba(16, 24, 40, 0.05);
  background: ${({ $isDelete, $disabled }) => {
    if ($isDelete && !$disabled) {
      return "#002244";
    }

    if (!$isDelete && !$disabled) {
      return "#11a7d9";
    }

    if ($disabled) {
      return "#C7C9D9";
    }
  }};
`;

export const DeletedCredentialBox = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 5px;
  p {
    margin: 0;
  }
`