export function getDocuments(params, callbackSuccess, callbackError) {
  return {
    type: "GET_DOCUMENTS",
    payload: {
      params,
      callbackSuccess,
      callbackError,
    },
  };
}

export function setDocumentsSuccess(documents) {
  return {
    type: "SET_DOCUMENTS_SUCCESS",
    payload: {
      documents,
    },
  };
}

export function getFile(id, callbackSuccess, callbackError) {
  return {
    type: "GET_FILE",
    payload: {
      id,
      callbackSuccess,
      callbackError,
    },
  };
}
