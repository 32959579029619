import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  height: 94vh;
  flex-direction: column;
  background-color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Message = styled.p`
  text-align: center;
  color: #444;
  font-family: "Open Sans";
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 170%;
  margin-bottom: 3px;
`;
