import React, { useEffect, useMemo, useRef, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useSelector } from "react-redux";
import { useHistory } from "react-router";
import AnimatedMainContainer from "../../components/AnimatedMainContainer";
import { useToast } from "../../hooks/toast";
import * as Yup from "yup";
import getValidationErrors from "../../utils/getValidationErrors";
import api from "../../service";
import { useCallback } from "react";
import FormButton from "../../components/FormButton";
import { Form } from "@unform/web";
import { validateSingleFieldOnBlur } from "../../utils/formValidations";

import {
  Container,
  FormContainer,
  TitleContainer,
  Title,
  FieldsContainer,
  Horizontal,
  FilterActionsContainer,
  SubmitContainer,
  LinkButton,
  GoBackContainer,
  ActionContainer,
  BadgeContainer,
  NotificationContainer,
} from "./styles";
import InputWithLabel from "../../components/InputWithLabel";
import SelectWithLabel from "../../components/SelectWithLabel";
import Table from "../../components/Table";
import Badge from "../../components/Badge";
import Loader from "react-loader-spinner";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import { parse, format } from "date-fns";
import { useLoading } from "../../hooks/loading";

const StaffAccess = () => {
  const { token } = useSelector((state) => state.auth);
  const [data, setData] = useState([]);
  const formRef = useRef(null);
  const intl = useIntl();
  const history = useHistory();
  const { addToast } = useToast();
  const { handleLoading } = useLoading();

  const [loading, setLoading] = useState(false);
  const [filtered, setFiltered] = useState(false);
  const [events, setEvents] = useState([]);

  const schema = Yup.object().shape({
    name: Yup.string().nullable(),
    email: Yup.string().nullable(),
    status: Yup.string().nullable(),
    event: Yup.string().nullable(),
  });

  useEffect(() => {
    handleLoading(true);
    api
      .get(`admin/events`, {
        headers: {
          "Content-Type": "application/json",
          Accept: "*/*",
          Authorization: `Bearer ${token}`,
        },
      })
      .then((result) => {
        const events = result.data.output.map((event) => {
          return {
            label: `${event.eventEditionName} - ${event.eventEditionCode}`,
            value: event.idNumber,
          };
        })
        const sortedEvents = [...events].sort((a, b) =>
          a.label < b.label ? -1 : a.label > b.label ? 1 : 0
        );
        setEvents(sortedEvents);
      })
      .catch((err) =>
        addToast({
          type: "error",
          title: intl.formatMessage({
            id: "error",
          }),
          description: intl.formatMessage({
            id: "api.error." + err.response.data.code,
          }),
        })
      )
      .finally(() => handleLoading(false));
  }, [addToast, intl, token, handleLoading]);

  const handleSubmit = useCallback(
    async (data) => {
      try {
        formRef.current?.setErrors({});

        setLoading(true);
        if (loading) return;

        await schema.validate(data, { abortEarly: false });

        setFiltered(true);

        api
          .patch(
            `/staff/access`,
            {
              name: data.name,
              email: data.email,
              status: data.status,
              eventId: data.event,
            },
            {
              headers: {
                "Content-Type": "application/json",
                Accept: "*/*",
                Authorization: `Bearer ${token}`,
              },
            }
          )
          .then((result) => setData(result.data.staffAccessFilterOutputs))
          .catch((err) =>
            addToast({
              type: "error",
              title: intl.formatMessage({
                id: "error",
              }),
              description: intl.formatMessage({
                id: "api.error." + err.response.data.code,
              }),
            })
          )
          .finally(() => setLoading(false));
      } catch (err) {
        setLoading(false);
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);

          formRef.current?.setErrors(errors);

          return;
        }
      }
    },
    [addToast, intl, loading, schema, token]
  );

  const validationBlur = (name) => {
    let errors = formRef.current.getErrors();
    validateSingleFieldOnBlur(errors, schema, name, formRef);
  };

  const toggleRowStatus = useCallback(
    (id, status) => {
      api
        .put(
          `/staff/access/status/${id}`,
          {
            active: !status,
          },
          {
            headers: {
              "Content-Type": "application/json",
              Accept: "*/*",
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((result) => {
          addToast({
            type: "success",
            title: intl.formatMessage({
              id: "success",
            }),
            description: intl.formatMessage({
              id: "success.staff_access_status_change",
            }),
          });
          formRef?.current?.submitForm();
        })
        .catch((err) =>
          addToast({
            type: "error",
            title: intl.formatMessage({
              id: "error",
            }),
            description: intl.formatMessage({
              id: "api.error." + err.response.data.code,
            }),
          })
        )
        .finally(() => setLoading(false));
    },
    [addToast, intl, token]
  );

  const statusOptions = useMemo(
    () => [
      {
        label: intl.formatMessage({ id: "active" }),
        value: true,
      },
      {
        label: intl.formatMessage({ id: "inactive" }),
        value: false,
      },
      {
        label: intl.formatMessage({ id: "both" }),
        value: null,
      },
    ],
    [intl]
  );

  const columns = useMemo(
    () => [
      {
        name: intl.formatMessage({ id: "name" }),
        selector: (row) => row.name,
      },
      {
        name: "E-mail",
        selector: (row) => row.email,
      },
      {
        name: intl.formatMessage({ id: "start_date" }),
        selector: (row) =>
          format(parse(row.startDate, "yyyy-MM-dd", new Date()), "dd/MM/yyyy"),
      },
      {
        name: intl.formatMessage({ id: "final_date" }),
        selector: (row) =>
          format(parse(row.finalDate, "yyyy-MM-dd", new Date()), "dd/MM/yyyy"),
      },
      {
        name: intl.formatMessage({ id: "events" }),
        selector: (row) => row?.events?.join(", "),
      },
      {
        name: intl.formatMessage({ id: "status" }),
        selector: (row) =>
          row.active
            ? intl.formatMessage({ id: "active" })
            : intl.formatMessage({ id: "inactive" }),
      },
      {
        cell: (row) => (
          <LinkButton to={`/staff-access/${row.id}`}>
            <Badge color="#002244" text={intl.formatMessage({ id: "edit" })} />
          </LinkButton>
        ),
        width: "125px",
      },
      {
        cell: (row) => (
          <div
            onClick={() => toggleRowStatus(row.id, row.active, row)}
            style={{ cursor: "pointer", margin: "0 8px" }}
          >
            <Badge
              color="#002244"
              text={intl.formatMessage({ id: "change_status" })}
            />
          </div>
        ),
        width: "160px",
      },
    ],
    [intl, toggleRowStatus]
  );

  const clearFields = () => {
    formRef.current.reset();
    formRef.current.setFieldValue("name");
    formRef.current.setFieldValue("email");
    formRef.current.setFieldValue("event");
    formRef.current.setFieldValue("status");
    setFiltered(false);
    setData([]);
  };

  return (
    <AnimatedMainContainer
      breadcrumb={intl.formatMessage({ id: "breadcrumb.manage_staff_access" })}
    >
      <Container>
        <FormContainer>
          <Form ref={formRef} onSubmit={handleSubmit}>
            <TitleContainer>
              <Title>
                <FormattedMessage id="search_staff_access" />
              </Title>
              <GoBackContainer>
                <ActionContainer>
                  <BadgeContainer onClick={() => history.push("/dashboard")}>
                    <Badge
                      color="#C9C9C9"
                      text={intl.formatMessage({ id: "goback" })}
                      fontSize="12px"
                    />
                  </BadgeContainer>
                  <BadgeContainer
                    onClick={() => history.push("/staff-access/new")}
                  >
                    <Badge
                      color="#002244"
                      text={intl.formatMessage({
                        id: "add_staff_access",
                      })}
                      fontSize="12px"
                    />
                  </BadgeContainer>
                </ActionContainer>
              </GoBackContainer>
            </TitleContainer>
            <FieldsContainer>
              <Horizontal>
                <InputWithLabel
                  name="name"
                  placeholder={intl.formatMessage({ id: "name" })}
                  label={intl.formatMessage({ id: "name" })}
                  validationBlur={validationBlur}
                  labelWidth="150px"
                />
                <InputWithLabel
                  name="email"
                  placeholder="E-mail"
                  label="E-mail"
                  validationBlur={validationBlur}
                  labelWidth="150px"
                />
              </Horizontal>
              <Horizontal>
                <SelectWithLabel
                  name="event"
                  placeholder={intl.formatMessage({ id: "event" })}
                  label={intl.formatMessage({ id: "event" })}
                  options={events}
                  validationBlur={validationBlur}
                />
                <SelectWithLabel
                  name="status"
                  placeholder={intl.formatMessage({ id: "status" })}
                  label={intl.formatMessage({ id: "status" })}
                  options={statusOptions}
                  validationBlur={validationBlur}
                />
              </Horizontal>
              <Horizontal>
                <FilterActionsContainer>
                  <SubmitContainer>
                    <FormButton
                      color="#C9C9C9"
                      type="button"
                      onClick={clearFields}
                    >
                      <FormattedMessage id="clear_fields" />
                    </FormButton>
                  </SubmitContainer>
                  <SubmitContainer>
                    <FormButton type="submit" loading={loading}>
                      <FormattedMessage id="search" />
                    </FormButton>
                  </SubmitContainer>
                </FilterActionsContainer>
              </Horizontal>
            </FieldsContainer>
          </Form>
        </FormContainer>
      </Container>
      {filtered ? (
        <>
          <Table
            columns={columns}
            data={data}
            title={intl.formatMessage({ id: "results" })}
            progressPending={loading}
            progressComponent={
              <Loader type="Oval" color="#00BFFF" height={100} width={100} />
            }
          />
        </>
      ) : (
        <NotificationContainer>
          <FormattedMessage id="need_to_filter_first" />
        </NotificationContainer>
      )}
    </AnimatedMainContainer>
  );
};

export default StaffAccess;
