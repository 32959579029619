import { all, takeLatest, call, put } from "redux-saga/effects";

import { setDocumentsSuccess } from "./actions";
import {
  getDocumentsService,
  getFileService,
} from "../../../service/document.service";

function* getDocuments({ payload }) {
  try {
    const response = yield call(getDocumentsService, payload.params);

    if (response.status === 200) {
      yield put(setDocumentsSuccess(response.data.output));
      payload.callbackSuccess(response.data.paginationInfo);
    }
  } catch (error) {
    payload.callbackError(error.response.data.code);
  }
}

function* getFile({ payload }) {
  try {
    const response = yield call(getFileService, payload.id);

    if (response.status === 200) {
      payload.callbackSuccess(response.data.output);
    }
  } catch (error) {
    payload.callbackError(error.response.data.code);
  }
}

export default all([
  takeLatest("GET_DOCUMENTS", getDocuments),
  takeLatest("GET_FILE", getFile),
]);
