import React from "react";
import ReactExport from "react-data-export";
import { format } from "date-fns";
import { useIntl } from "react-intl";

const File = ReactExport.ExcelFile;
const Sheet = ReactExport.ExcelFile.ExcelSheet;
const Column = ReactExport.ExcelFile.ExcelColumn;

const Report = ({ data, filename, children }) => {
  const intl = useIntl();

  return (
    <File element={children} filename={filename} fileExtension="xlsx">
      <Sheet data={data} name="Relatório">
        <Column label="evento" value="eventName" />
        <Column label="razao_social" value="accountName" />
        <Column label="nome_fantasia" value="accountAlias" />
        <Column label="cnpj_passaporte" value="accountCode" />
        <Column label="nome_credencial" value="credentialName" />
        <Column label="tipo" value="credentialType" />
        <Column
          label="data_emissao"
          value={(col) =>
            format(new Date(col.credentialEmissionDate), "dd/MM/yyyy")
          }
        />
        <Column label="email" value="email" />
        <Column label="documento_credenciado" value="accreditedDoc" />
        <Column label="nome_credenciado" value="accreditedName" />
        <Column label="cargo_credenciado" value="accreditedJob" />
        <Column
          label="impresso?"
          value={(col) =>
            col.printed ? intl.formatMessage({ id: "yes" }) : intl.formatMessage({ id: "no" })
          }
        />
        <Column label="codigo_barras" value="barCode" />
      </Sheet>
    </File>
  );
};

export default Report;
