import api from "./index";

export const getAllContractByEventService = async (params) =>
  await api.get(`/exhibitor/opportunity/event/${params}`);

export const getContractByExhibitorService = async (params) =>
  await api.get(
    `/exhibitor/events/opportunity?event_id=${params.eventId}&opportunity_id=${params.contractId}`
  );

export const getOrderByContractService = async (params) =>
  await api.patch("/ecommerce/chleba/order", params);

export const getContractByServiceProviderService = async (params) =>
  await api.get(
    `/serviceprovider/associated-opportunity?event_id=${params.eventId}&opportunity_id=${params.contractId}`
  );

export const changeHeadbandExhibitorService = async (params) =>
  await api.post("/exhibitor/opportunity/headband", params);
