export function getProfileExhibitor(eventId, opportunityId, callbackError) {
  return {
    type: "GET_PROFILE_EXHIBITOR",
    payload: {
      eventId,
      opportunityId,
      callbackError,
    },
  };
}

export function setProfileExhibitorSuccess(profile) {
  return {
    type: "SET_PROFILE_EXHIBITOR",
    payload: {
      profile,
    },
  };
}

export function clearProfileExhibitor() {
  return {
    type: "CLEAR_PROFILE_EXHIBITOR",
  };
}

export function changeProfileExhibitor(
  companyNameCredential,
  callBackOnSuccess,
  callbackError
) {
  return {
    type: "CHANGE_PROFILE_EXHIBITOR",
    payload: {
      companyNameCredential,
      callBackOnSuccess,
      callbackError,
    },
  };
}

export function changeProfileExhibitorSuccess(newCredentialName) {
  return {
    type: "CHANGE_PROFILE_EXHIBITOR_SUCCESS",
    payload: {
      newCredentialName,
    },
  };
}

export function changeProfileServiceProvider(data, callBackMessage) {
  return {
    type: "CHANGE_PROFILE_SERVICE_PROVIDER",
    payload: {
      data,
      callBackMessage,
    },
  };
}

export function changeProfileServiceProviderSuccess(data) {
  return {
    type: "CHANGE_PROFILE_SERVICE_PROVIDER_SUCCESS",
    payload: {
      data,
    },
  };
}
