import { all, takeLatest, call, put } from "redux-saga/effects";

import {
  setProfileExhibitorSuccess,
  changeProfileExhibitorSuccess,
} from "./actions";
import { updateUser } from "../auth/actions";
import {
  getProfileExhibitorService,
  changeProfileExhibitorService,
  changeProfileServiceProviderService,
} from "../../../service/profile.service";

function* getProfileExhibitor({ payload }) {
  try {
    const response = yield call(getProfileExhibitorService, {
      eventId: payload.eventId,
      opportunityId: payload.opportunityId,
    });

    if (response.status === 200) {
      yield put(setProfileExhibitorSuccess(response.data.output));
    }
  } catch (error) {
    payload.callbackError(error.response.data.code);
  }
}

function* changeProfileExhibitor({ payload }) {
  try {
    const response = yield call(changeProfileExhibitorService, {
      companyNameCredential: payload.companyNameCredential,
    });

    if (response.status === 200) {
      payload.callBackOnSuccess();
      yield put(changeProfileExhibitorSuccess(payload.companyNameCredential));

      yield put(
        updateUser({
          user: response.data.user,
        })
      );
    }
  } catch (error) {
    payload.callbackError(error.response.data.code);
  }
}

function* changeProfileServiceProvider({ payload }) {
  try {
    const response = yield call(
      changeProfileServiceProviderService,
      payload.data
    );

    if (response.status === 200) {
      yield put(updateUser(response.data));
      payload.callBackMessage("", "success");
    }
  } catch (error) {
    payload.callBackMessage(error.response.data.code, "error");
  }
}

export default all([
  takeLatest("GET_PROFILE_EXHIBITOR", getProfileExhibitor),
  takeLatest("CHANGE_PROFILE_EXHIBITOR", changeProfileExhibitor),
  takeLatest("CHANGE_PROFILE_SERVICE_PROVIDER", changeProfileServiceProvider),
]);
