import React, { useEffect, useMemo, useRef, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useSelector } from "react-redux";
import { useHistory } from "react-router";
import AnimatedMainContainer from "../../components/AnimatedMainContainer";
import { useToast } from "../../hooks/toast";
import * as Yup from "yup";
import getValidationErrors from "../../utils/getValidationErrors";
import api from "../../service";
import { useCallback } from "react";
import FormButton from "../../components/FormButton";
import { Form } from "@unform/web";
import { validateSingleFieldOnBlur } from "../../utils/formValidations";

import {
  Container,
  FormContainer,
  TitleContainer,
  Title,
  FieldsContainer,
  Horizontal,
  FilterActionsContainer,
  SubmitContainer,
  LinkButton,
  GoBackContainer,
  ActionContainer,
  BadgeContainer,
  NotificationContainer,
} from "./styles";
import SelectWithLabel from "../../components/SelectWithLabel";
import InputWithLabel from "../../components/InputWithLabel";
import Table from "../../components/Table";
import Badge from "../../components/Badge";
import Report from "./report";
import Loader from "react-loader-spinner";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import { useLoading } from "../../hooks/loading";

const AdditionalProduct = () => {
  const { token } = useSelector((state) => state.auth);
  var language = useSelector((state) => state.language).code;
  const [data, setData] = useState([]);
  const formRef = useRef(null);
  const intl = useIntl();
  const history = useHistory();
  const { addToast } = useToast();

  const [loading, setLoading] = useState(false);
  const [filtered, setFiltered] = useState(false);
  const [showExport, setShowExport] = useState(false);
  const [categories, setCategories] = useState([]);
  const { handleLoading } = useLoading();

  const schema = Yup.object().shape({
    category: Yup.string().nullable(),
    name: Yup.string().nullable(),
  });

  const handleSubmit = useCallback(
    async (data) => {
      try {
        formRef.current?.setErrors({});

        setLoading(true);
        if (loading) return;

        await schema.validate(data, { abortEarly: false });

        setFiltered(true);

        api
          .patch(
            `/admin/ecommerce/chleba/product`,
            {
              categoryId: data.category,
              name: data.name,
            },
            {
              headers: {
                "Content-Type": "application/json",
                Accept: "*/*",
                Authorization: `Bearer ${token}`,
              },
            }
          )
          .then((result) => {
            setData(result.data.ecommerceChlebaProductFilterOutput)
          })
          .catch((err) =>{
            addToast({
              type: "error",
              title: intl.formatMessage({
                id: "error",
              }),
              description: intl.formatMessage({
                id: "api.error." + err.response.data.code,
              }),
            })
          })
          .finally(() => setLoading(false));
      } catch (err) {
        setLoading(false);
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);
          formRef.current?.setErrors(errors);
          return;
        }
      }
    },
    [addToast, intl, loading, schema, token]
  );

  useEffect(() => {
    handleLoading(true);
    api
      .patch(
        `admin/ecommerce/chleba/category`,
        {},
        {
          headers: {
            "Content-Type": "application/json",
            Accept: "*/*",
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((response) => {
        const categories =
          response.data.ecommerceChlebaCategoryFilterOutput.map((category) => ({
            value: category.id,
            label: category.title,
          }));
        setCategories(categories);
      })
      .catch((err) =>
        addToast({
          type: "error",
          title: intl.formatMessage({
            id: "error",
          }),
          description: intl.formatMessage({
            id: "api.error." + err.response.data.code,
          }),
        })
      )
      .finally(() => handleLoading(false));
  }, [addToast, intl, token, handleLoading]);

  useEffect(()=>{
    if(data.length === 0){
      setShowExport(false)
      return
    }
    setShowExport(true)
  },[data])

  const validationBlur = (name) => {
    let errors = formRef.current.getErrors();
    validateSingleFieldOnBlur(errors, schema, name, formRef);
  };

  const columns = useMemo(
    () => [
      {
        name: intl.formatMessage({ id: "category" }),
        selector: (row) => row.category,
      },
      {
        name: intl.formatMessage({ id: "title" }),
        selector: (row) => (language === "pt" ? row.title : row.englishTitle),
      },
      {
        name: intl.formatMessage({ id: "dependent_product" }),
        selector: (row) =>
          language === "pt"
            ? row.dependentProductTitle
            : row.dependentProductEnglishTitle,
      },
      {
        name: intl.formatMessage({ id: "code" }),
        selector: (row) => row.code,
      },
      {
        cell: (row) => (
          <LinkButton to={`/admin/additional-products/${row.id}`}>
            <Badge color="#002244" text={intl.formatMessage({ id: "edit" })} />
          </LinkButton>
        ),
        right: true,
      },
    ],
    [intl, language]
  );

  const clearFields = () => {
    formRef.current.reset();
    formRef.current.setFieldValue("category");
    formRef.current.setFieldValue("name");
    setFiltered(false);
    setData([]);
  };

  return (
    <AnimatedMainContainer
      breadcrumb={intl.formatMessage({
        id: "breadcrumb.additional_products_and_services",
      })}
    >
      <Container>
        <FormContainer>
          <Form ref={formRef} onSubmit={handleSubmit}>
            <TitleContainer>
              <Title>
                <FormattedMessage id="search_additional_products_and_services" />
              </Title>
              <GoBackContainer>
                <ActionContainer>
                  <BadgeContainer onClick={() => history.push("/dashboard")}>
                    <Badge
                      color="#C9C9C9"
                      text={intl.formatMessage({ id: "goback" })}
                      fontSize="12px"
                    />
                  </BadgeContainer>
                  <BadgeContainer
                    onClick={() =>
                      history.push("/admin/additional-products/new")
                    }
                  >
                    <Badge
                      color="#002244"
                      text={intl.formatMessage({
                        id: "add_additional_products",
                      })}
                      fontSize="12px"
                    />
                  </BadgeContainer>
                </ActionContainer>
              </GoBackContainer>
            </TitleContainer>
            <FieldsContainer>
              <Horizontal>
                <SelectWithLabel
                  name="category"
                  validationBlur={validationBlur}
                  label={intl.formatMessage({ id: "category" })}
                  placeholder={intl.formatMessage({ id: "category" })}
                  labelOrientation="vertical"
                  options={categories}
                />

                <InputWithLabel
                  name="name"
                  placeholder={intl.formatMessage({ id: "name" })}
                  label={intl.formatMessage({ id: "name" })}
                  validationBlur={validationBlur}
                  labelWidth="150px"
                />
              </Horizontal>
              <Horizontal>
                <FilterActionsContainer>
                  <SubmitContainer>
                    <FormButton
                      color="#C9C9C9"
                      type="button"
                      onClick={clearFields}
                    >
                      <FormattedMessage id="clear_fields" />
                    </FormButton>
                  </SubmitContainer>
                  <SubmitContainer>
                    <FormButton type="submit" loading={loading}>
                      <FormattedMessage id="search" />
                    </FormButton>
                  </SubmitContainer>
                  {showExport ? (
                    <SubmitContainer>
                      <FormButton type="button" loading={loading}>
                        <Report filename={'ADDITIONAL_PRODUCTS_REPORT'} data={data}>
                          <FormattedMessage id="export" />
                        </Report>
                      </FormButton>
                    </SubmitContainer>
                  ) : null}
                </FilterActionsContainer>
              </Horizontal>
            </FieldsContainer>
          </Form>
        </FormContainer>
      </Container>
      {filtered ? (
        <>
          <Table
            columns={columns}
            data={data}
            title={intl.formatMessage({ id: "results" })}
            progressPending={loading}
            progressComponent={
              <Loader type="Oval" color="#00BFFF" height={100} width={100} />
            }
          />
        </>
      ) : (
        <NotificationContainer>
          <FormattedMessage id="need_to_filter_first" />
        </NotificationContainer>
      )}
    </AnimatedMainContainer>
  );
};

export default AdditionalProduct;
