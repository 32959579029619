import { all, takeLatest, call, put } from "redux-saga/effects";

import { setEcommerceInfoSuccess } from "./actions";
import {
  getEcommerceInfoService,
  getEcommerceLinkService,
} from "../../../service/ecommerce.service";

function* getEcommerceInfo({ payload }) {
  try {
    const response = yield call(getEcommerceInfoService, payload.params);
    if (response.status === 200 && response.data.code === 2015) {
      yield put(
        setEcommerceInfoSuccess(
          response.data.systemUserChlebaAccessInformationOutput
        )
      );
    }
  } catch (error) {
    payload.callbackError(error.response.data.code);
  }
}

function* getEcommerceLink({ payload }) {
  const { selectedEvent, selectedContract, callbackError } = payload;
  try {
    const response = yield call(
      getEcommerceLinkService,
      selectedEvent,
      selectedContract
    );
    window.open(
      response.data.ecommerceChlebaAuthenticationOutput.generatedUrl,
      "_blank"
    );
  } catch (err) {
    callbackError(err.response.data.code);
  }
}

export default all([
  takeLatest("GET_ECOMMERCE_INFO", getEcommerceInfo),
  takeLatest("GET_ECOMMERCE_LINK", getEcommerceLink),
]);
