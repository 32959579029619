import React, { useEffect, useRef, useState, useCallback } from "react";
import { useField } from "@unform/core";
import { MdError } from "react-icons/md";
import { Editor } from "react-draft-wysiwyg";
import { EditorState, convertToRaw, ContentState } from 'draft-js';
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';

import { Container, Error, RootContainer, Label, AsteriskText } from "./styles";

const TextareaWithLabelAndEditor = ({
  name,
  containerStyle = {},
  icon: Icon,
  validationBlur,
  label,
  className,
  disabled,
  required,
  customFontSizeOptions = [8, 9, 10, 11, 12, 14, 16, 18, 24, 30, 36, 48, 60],
  ...rest
}) => {
  const inputRef = useRef(null);

  const [isFocused, setIsFocused] = useState(false);
  const [isFilled, setIsFilled] = useState(false);
  const { fieldName, defaultValue, error, registerField } = useField(name);

  const [editorState, setEditorState] = useState(() => {
    const processedValue = !defaultValue ? "" : defaultValue
    const contentBlock = htmlToDraft(processedValue)
    if (contentBlock) {
      const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
      return EditorState.createWithContent(contentState);
    }
    else return EditorState.createEmpty()
  });

  const handleInputFocus = useCallback(() => {
    setIsFocused(true);
  }, []);

  const handleInputBlur = useCallback(async () => {
    let fieldHasValue = !!inputRef.current?.value;
    setIsFocused(false);

    setIsFilled(fieldHasValue);

    if (validationBlur) {
      validationBlur(fieldName, inputRef.current?.value);
    }
  }, []);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      path: "value",
    });
  }, [fieldName, registerField]);

  const removeBlankString = (string) =>{ 
    if (string.replace(/(<p[^>]+?>|<p>|<\/p>)/img, "") === "\n") return ''
    return string
  }

  return (
    <RootContainer className={className}>
      <Label>
        {label}
        {required && <AsteriskText>*</AsteriskText>}
      </Label>
      <Container
        style={containerStyle}
        isFocused={isFocused}
        isFilled={isFilled}
        isErrored={!!error}
        disabled={disabled}
        data-testid="input-container"
      >
        <Editor
          wrapperClassName={'editor-input'}
          editorState={editorState}
          onEditorStateChange={setEditorState}
          toolbar={{
            inline: {
              superscript: { className: 'no-display' },
              subscript: { className: 'no-display' },
              monospace: { className: 'no-display' },
            },
            fontSize: {
              options: customFontSizeOptions
            },
            list: { inDropdown: true },
            textAlign: { className: 'no-display' },
            history: { className: 'no-display' },
            fontFamily: { className: 'no-display' }, 
            emoji: { className: 'no-display' },
            embedded: { className: 'no-display' },
            remove: { className: 'no-display' },
            blockType: { className: 'no-display' },
          }}
        />
        <textarea
          onFocus={handleInputFocus}
          onBlur={handleInputBlur}
          ref={inputRef}
          disabled={disabled}
          value={removeBlankString(draftToHtml(convertToRaw(editorState.getCurrentContent())))}
          {...rest}
        />
      </Container>
      {error && (
        <Error title={error}>
          <MdError color="#e00000" size={20} />
          {error}
        </Error>
      )}
    </RootContainer>
  );
};

export default TextareaWithLabelAndEditor;
