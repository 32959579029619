import React, { useCallback, useRef, useState } from "react";
import { useHistory, useParams } from "react-router-dom";

import { Form } from "@unform/web";
import * as Yup from "yup";
import getValidationErrors from "../../../utils/getValidationErrors";

import { useSelector } from "react-redux";

import { FormattedMessage, useIntl } from "react-intl";

import {
  Container,
  TitleContainer,
  Title,
  Session,
  FieldsContainer,
  ActionContainer,
  BadgeContainer,
} from "./styles";

import { useToast } from "../../../hooks/toast";
import { validateSingleFieldOnBlur } from "../../../utils/formValidations";
import api from "../../../service";
import AnimatedMainContainer from "../../../components/AnimatedMainContainer";
import InputWithHorizontalLabel from "../../../components/InputWithHorizontalLabel";
import SelectWithLabel from "../../../components/SelectWithLabel";
import Badge from "../../../components/Badge";
import Toggle from "../../../components/Toggle";

import { useEffect } from "react";
import { useLoading } from "../../../hooks/loading";
import credentialTypesConstants from "../../../constants/credentialTypes";

const EditCategory = () => {
  const formRef = useRef(null);
  const intl = useIntl();
  const { addToast } = useToast();
  const { token } = useSelector((state) => state.auth);
  const { id } = useParams();

  const [categoryData, setCategoryData] = useState();

  const { handleLoading } = useLoading();
  const [loading, setLoading] = useState(false);
  const [isCategory, setIsCategory] = useState(false);
  const [selectedCategoryType, setSelectedCategoryType] = useState();
  const [key, setKey] = useState();

  const history = useHistory();
  const credentialTypes = credentialTypesConstants();

  const schema = Yup.object().shape({
    title: Yup.string().required(
      intl.formatMessage({ id: "error.field_required" })
    ),
    english_title: Yup.string().required(
      intl.formatMessage({ id: "error.field_required" })
    ),
  });

  useEffect(() => {
    handleLoading(true);
    api
      .get(`/admin/ecommerce/chleba/category/${id}`, {
        headers: {
          "Content-Type": "application/json",
          Accept: "*/*",
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        setCategoryData(response.data.ecommerceChlebaCategoryOutput);
        setIsCategory(response.data.ecommerceChlebaCategoryOutput.credential);
        setKey(new Date());
      })
      .catch((err) =>
        addToast({
          type: "error",
          title: intl.formatMessage({ id: "error" }),
          description: intl.formatMessage({
            id: "api.error." + err.response.data.code,
          }),
        })
      )
      .finally(() => {
        handleLoading(false);
      });
  }, [addToast, id, intl, token, handleLoading]);

  const handleSubmit = useCallback(
    async (data) => {
      try {
        formRef.current?.setErrors({});
        setLoading(true);
        if (loading) {
          return;
        }
        await schema.validate(data, { abortEarly: false });

        api
          .put(
            `/admin/ecommerce/chleba/category/${id}`,
            {
              title: data.title,
              englishTitle: data.english_title,
              credential: data.credential,
              credentialType: credentialTypes.find(
                  (item) => item.value === data.credential_type)?.value,
            },
            {
              headers: {
                "Content-Type": "application/json",
                Accept: "*/*",
                Authorization: `Bearer ${token}`,
              },
            }
          )
          .then((result) => {
            addToast({
              type: "success",
              title: intl.formatMessage({
                id: "success",
              }),
              description: intl.formatMessage({
                id: "success.category_update",
              }),
            });
            history.push("/admin/categories");
          })
          .catch((err) =>
            addToast({
              type: "error",
              title: intl.formatMessage({
                id: "error",
              }),
              description: intl.formatMessage({
                id: "api.error." + err.response.data.code,
              }),
            })
          )
          .finally(setLoading(false));
      } catch (err) {
        setLoading(false);
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);
          formRef.current?.setErrors(errors);
          return;
        }
      }
    },
    [addToast, history, id, intl, loading, schema, token]
  );

  const validationBlur = (name) => {
    let errors = formRef.current.getErrors();
    validateSingleFieldOnBlur(errors, schema, name, formRef);
  };

  useEffect(() => {
    if (categoryData) {
      const selectedCategoryType = credentialTypes.find(
        (category) => category.value === categoryData.credentialType
      );
      setSelectedCategoryType(selectedCategoryType);
    }
  },[categoryData]);

  return (
    <AnimatedMainContainer>
      <Container>
        <Form
          ref={formRef}
          onSubmit={handleSubmit}
          key={key}
          initialData={{
            title: categoryData?.title,
            english_title: categoryData?.englishTitle,
            credential: categoryData?.credential,
            credential_type: selectedCategoryType,
          }}
        >
          <TitleContainer>
            <Title>
              <FormattedMessage id="editing_category" />
            </Title>
            <ActionContainer>
              <BadgeContainer
                onClick={() =>
                  history.push("/admin/categories", {
                    prevPath: history.location,
                  })
                }
              >
                <Badge
                  color="#C9C9C9"
                  text={intl.formatMessage({ id: "goback" })}
                  fontSize="12px"
                />
              </BadgeContainer>
              <BadgeContainer onClick={() => formRef.current.submitForm()}>
                <Badge
                  loading={loading}
                  color="#002244"
                  text={intl.formatMessage({ id: "save" })}
                  fontSize="12px"
                />
              </BadgeContainer>
            </ActionContainer>
          </TitleContainer>
          <Session>
            <FieldsContainer>
              <InputWithHorizontalLabel
                name="title"
                placeholder={intl.formatMessage({
                  id: "title",
                })}
                label={intl.formatMessage({
                  id: "title",
                })}
                validationBlur={validationBlur}
                labelWidth="80px"
                required
              />
              <InputWithHorizontalLabel
                name="english_title"
                placeholder={intl.formatMessage({
                  id: "title_en",
                })}
                label={intl.formatMessage({
                  id: "title_en",
                })}
                validationBlur={validationBlur}
                labelWidth="80px"
                required
              />
              <Toggle
                name="credential"
                label={intl.formatMessage({ id: "is_credential" })}
                onClick={() => setIsCategory(!isCategory)}
              />
              <SelectWithLabel
                name="credential_type"
                label={intl.formatMessage({ id: "credential_type" })}
                placeholder={intl.formatMessage({ id: "credential_type" })}
                labelOrientation="horizontal"
                labelWidth="80px"
                options={credentialTypes}
                disabled={!isCategory}
              />
            </FieldsContainer>
          </Session>
        </Form>
      </Container>
    </AnimatedMainContainer>
  );
};

export default EditCategory;
