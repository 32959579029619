import React, { useEffect, useRef, useState, useCallback } from "react";
import { useField } from "@unform/core";
import { MdError } from "react-icons/md";

import { Container, Error, RootContainer, Label, AsteriskText, Counter } from "./styles";

const TextareaWithLabel = ({
  name,
  containerStyle = {},
  icon: Icon,
  validationBlur,
  label,
  className,
  disabled,
  required,
  maxlength,
  ...rest
}) => {
  const inputRef = useRef(null);

  const [isFocused, setIsFocused] = useState(false);
  const [isFilled, setIsFilled] = useState(false);
  const [fieldLength, setFieldLength] = useState(0)

  const { fieldName, defaultValue, error, registerField } = useField(name);

  const onChangeFieldValue = (input) => {
    setFieldLength(input.target.value.length)
  }

  const handleInputFocus = useCallback(() => {
    setIsFocused(true);
  }, []);

  const handleInputBlur = useCallback(async () => {
    let fieldHasValue = !!inputRef.current?.value;
    setIsFocused(false);

    setIsFilled(fieldHasValue);

    if (validationBlur) {
      validationBlur(fieldName, inputRef.current?.value);
    }
  }, []);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      path: "value",
    });
    setFieldLength(inputRef.current.value.length)
  }, [fieldName, registerField]);

  return (
    <RootContainer className={className}>
      <Label>
        {label}
        {required && <AsteriskText>*</AsteriskText>}
      </Label>
      <Container
        style={containerStyle}
        isFocused={isFocused}
        isFilled={isFilled}
        isErrored={!!error}
        disabled={disabled}
        data-testid="input-container"
      >
        <textarea
          onFocus={handleInputFocus}
          onBlur={handleInputBlur}
          onChange={onChangeFieldValue}
          ref={inputRef}
          disabled={disabled}
          maxlength={maxlength}
          {...rest}
        >
          {defaultValue}
        </textarea>
      </Container>
      {maxlength && (
        <Counter>
          <p>{fieldLength}/{maxlength}</p>
        </Counter>
      )}
      {error && (
        <Error title={error}>
          <MdError color="#e00000" size={20} />
          {error}
        </Error>
      )}
    </RootContainer>
  );
};

export default TextareaWithLabel;
