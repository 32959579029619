import React from "react";

const Right = (props) => {
  const { size = "30", color = "black", ...old } = props;

  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...old}
    >
      <path
        d="M9.89777 6.16257L3.86027 1.44694C3.84449 1.43452 3.82553 1.42681 3.80557 1.42468C3.7856 1.42254 3.76544 1.42609 3.7474 1.4349C3.72936 1.44371 3.71417 1.45743 3.70357 1.47449C3.69298 1.49154 3.68741 1.51124 3.6875 1.53132V2.56659C3.6875 2.63221 3.7183 2.69516 3.7692 2.73534L8.59063 6.50007L3.7692 10.2648C3.71697 10.305 3.6875 10.3679 3.6875 10.4336V11.4688C3.6875 11.5586 3.79063 11.6081 3.86027 11.5532L9.89777 6.83757C9.94908 6.79754 9.9906 6.74634 10.0191 6.68786C10.0477 6.62937 10.0625 6.56515 10.0625 6.50007C10.0625 6.43499 10.0477 6.37077 10.0191 6.31228C9.9906 6.2538 9.94908 6.2026 9.89777 6.16257Z"
        fill={color}
        fillOpacity="0.85"
      />
    </svg>
  );
};

export default Right;
