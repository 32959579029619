import React, {
  useState,
  useMemo,
  useEffect,
  useCallback,
  useContext,
} from "react";
import { useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import useNotification from "../../../hooks/newUXNotification";
import { DialogContext } from "../../../hooks/dialog/context";
import { useLoading } from "../../../hooks/loading";
import {
  deleteEmployee,
  getCollaborators,
} from "../../../redux/modules/employee/actions";
import ConfirmationModal from "../../../newUXComponents/Modal/ConfirmationModal";
import Button from "../../../newUXComponents/Button";
import Table from "../../../newUXComponents/Table";
import Edit from "../../../newUXAssets/icons/Edit";
import Trash from "../../../newUXAssets/icons/Trash";
import CredentialsHeader from "../Components/Header";
import {
  Container,
  Title,
  ButtonContainer,
  TableContainer,
  Actions,
  BtnActions,
} from "./styles";

const Register = () => {
  const intl = useIntl();

  const history = useHistory();
  const dispatch = useDispatch();
  const notification = useNotification();
  const { handleLoading } = useLoading();
  const { createDialog } = useContext(DialogContext);

  const [tablePagination, setTablePagination] = useState({
    current: 0,
    defaultCurrent: 0,
    pageSize: 10,
    showQuickJumper: true,
    total: 0,
  });

  const { collaboratorsList, paginationInfo } = useSelector(
    (state) => state.employee
  );

  const callBackError = useCallback(
    (message) => {
      handleLoading(false);
      notification.displayError(
        intl.formatMessage({ id: "api.error." + message }),
        "get-collaborators-error-message"
      );
    },
    [handleLoading, intl, notification]
  );

  const handleGetInfoTable = useCallback(
    (page = 0, pageSize = 10, search = "") => {
      handleLoading(false);
      dispatch(
        getCollaborators({
          page,
          perPage: pageSize,
          search: search,
          callBackError,
        })
      );
    },
    [callBackError, dispatch, handleLoading]
  );

  const callBackSuccess = useCallback(
    (message) => {
      notification.displaySuccess(
        intl.formatMessage({ id: "success." + message }),
        "post-new-employee-success-message"
      );
      handleGetInfoTable();
    },
    [handleGetInfoTable, intl, notification]
  );

  const handleDeleteEmployee = useCallback(
    (id) => {
      createDialog({
        id: "modal-delete-employee",
        Component: ConfirmationModal,
        props: {
          title: intl.formatMessage({ id: "delete_collaborator" }),
          message: intl.formatMessage({ id: "delete_collaborator_message" }),
          confirmation: () => {
            handleLoading(true);
            dispatch(deleteEmployee({ id, callBackSuccess, callBackError }));
          },
        },
      });
    },
    [
      callBackError,
      callBackSuccess,
      createDialog,
      dispatch,
      handleLoading,
      intl,
    ]
  );

  const columns = useMemo(
    () => [
      {
        title: intl.formatMessage({ id: "name" }),
        dataIndex: "name",
        key: "name",
      },
      {
        title: intl.formatMessage({ id: "document" }),
        dataIndex: "document",
        key: "document",
      },
      {
        title: intl.formatMessage({ id: "assignment" }),
        dataIndex: "jobTitle",
        key: "jobTitle",
      },
      {
        title: intl.formatMessage({ id: "actions" }),
        dataIndex: "actions",
        key: "actions",
        render: (_, record) => (
          <Actions>
            <BtnActions
              $isEdit
              onClick={() => history.push("/credentials/register/edit", record)}
            >
              <Edit />
            </BtnActions>
            <BtnActions
              $isDelete
              onClick={() => handleDeleteEmployee(record.id)}
            >
              <Trash />
            </BtnActions>
          </Actions>
        ),
      },
    ],
    [intl]
  );

  useEffect(() => {
    setTablePagination({
      current: paginationInfo.pageNumber + 1,
      defaultCurrent: 1,
      pageSize: paginationInfo.maxSize,
      showQuickJumper: true,
      total: paginationInfo.totalElements,
    });
  }, [paginationInfo]);

  useEffect(() => handleGetInfoTable(), []);

  return (
    <Container>
      <Title>{intl.formatMessage({ id: "credentials" })}</Title>
      <CredentialsHeader isRegisterSelected />
      <ButtonContainer>
        <Button
          type="primary"
          label={`+ ${intl.formatMessage({ id: "new_collaborator" })}`}
          onClick={() => history.push("/credentials/register/new")}
        />
      </ButtonContainer>
      <TableContainer>
        <Table
          title={intl.formatMessage({ id: "registred_collaborators" })}
          dataSource={collaboratorsList}
          columns={columns}
          tablePagination={tablePagination}
          handleGetInfoTable={handleGetInfoTable}
        />
      </TableContainer>
    </Container>
  );
};

export default Register;
