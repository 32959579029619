import React from "react";

const PaperPlane = (props) => {
  const { size = "17", color = "#282828", filled = false } = props;

  if (filled) {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={size}
        height={size}
        viewBox="0 0 17 16"
        fill="none"
      >
        <g clipPath="url(#clip0_295_45258)">
          <path
            d="M14.4811 2.6366C14.5052 2.55107 14.506 2.46066 14.4836 2.37468C14.4612 2.2887 14.4162 2.21026 14.3534 2.14742C14.2905 2.08459 14.2121 2.03964 14.1261 2.01719C14.0401 1.99474 13.9497 1.99562 13.8642 2.01972L1.86419 5.65847C1.76622 5.68612 1.67903 5.74301 1.61426 5.82155C1.54949 5.90008 1.51024 5.99652 1.50174 6.09796C1.49325 6.1994 1.51592 6.30102 1.56673 6.38923C1.61754 6.47744 1.69406 6.54804 1.78607 6.5916L7.13732 9.12535C7.2415 9.17476 7.32541 9.25866 7.37482 9.36285L9.90919 14.7135C9.95275 14.8055 10.0233 14.882 10.1116 14.9328C10.1998 14.9836 10.3014 15.0063 10.4028 14.9978C10.5043 14.9893 10.6007 14.95 10.6792 14.8853C10.7578 14.8205 10.8147 14.7333 10.8423 14.6353L14.4811 2.6366Z"
            stroke="#444444"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M7.27734 9.22375L10.5011 6"
            stroke="#444444"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </g>
        <defs>
          <clipPath id="clip0_295_45258">
            <rect
              width="16"
              height="16"
              fill="white"
              transform="translate(0.5)"
            />
          </clipPath>
        </defs>
      </svg>
    );
  }

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 17 16"
      fill="none"
    >
      <g clipPath="url(#clip0_295_45258)">
        <path
          d="M14.4811 2.6366C14.5052 2.55107 14.506 2.46066 14.4836 2.37468C14.4612 2.2887 14.4162 2.21026 14.3534 2.14742C14.2905 2.08459 14.2121 2.03964 14.1261 2.01719C14.0401 1.99474 13.9497 1.99562 13.8642 2.01972L1.86419 5.65847C1.76622 5.68612 1.67903 5.74301 1.61426 5.82155C1.54949 5.90008 1.51024 5.99652 1.50174 6.09796C1.49325 6.1994 1.51592 6.30102 1.56673 6.38923C1.61754 6.47744 1.69406 6.54804 1.78607 6.5916L7.13732 9.12535C7.2415 9.17476 7.32541 9.25866 7.37482 9.36285L9.90919 14.7135C9.95275 14.8055 10.0233 14.882 10.1116 14.9328C10.1998 14.9836 10.3014 15.0063 10.4028 14.9978C10.5043 14.9893 10.6007 14.95 10.6792 14.8853C10.7578 14.8205 10.8147 14.7333 10.8423 14.6353L14.4811 2.6366Z"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M7.27734 9.22375L10.5011 6"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_295_45258">
          <rect
            width={size}
            height={size}
            fill={color}
            transform="translate(0.5)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default PaperPlane;
