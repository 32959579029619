import React from "react";

const ListChecks = (props) => {
  const { size = "26", color = "#444444" } = props;

  return (
    <svg width={size} height={size} viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_603_6367)">
        <path d="M13 13H21.9375" stroke={color} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M13 6.5H21.9375" stroke={color} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M13 19.5H21.9375" stroke={color} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M4.0625 6.5L5.6875 8.125L8.9375 4.875" stroke={color} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M4.0625 13L5.6875 14.625L8.9375 11.375" stroke={color} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M4.0625 19.5L5.6875 21.125L8.9375 17.875" stroke={color} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
      </g>
      <defs>
        <clipPath id="clip0_603_6367">
          <rect width={size} height={size} fill="white"/>
        </clipPath>
      </defs>
    </svg>
  );
};

export default ListChecks;
