export const INITIAL_VALUES = {
  event: "",
  contract: "",
};

export const validation = (key, intl) => {
  switch (key) {
    case "event":
      return [
        {
          required: true,
          message: intl.formatMessage({ id: "error.field_required" }),
        },
      ];

    case "contract":
      return [
        {
          required: true,
          message: intl.formatMessage({ id: "error.field_required" }),
        },
      ];

    default:
      return [];
  }
};
