import React, { useState } from 'react'
import CustomModal from "../../../../../components/CustomModal";
import Button from "../../../../../newUXComponents/Button";
import { useIntl } from "react-intl";
import {
  Container,
  Header,
  Title,
  Hr,
  Content,
  ButtonContainer
} from './styles'

const ConfirmationModal = ({ open, handleClose, id, onConfirmation })=>{
  const [isOpen, setIsOpen] = useState(open)
  const intl = useIntl();

  const cancelModal = ()=>{
    handleClose()
    setIsOpen(false)
  }

  const confirmModal = ()=>{
    onConfirmation()
    setIsOpen(false)
  }

  return (
    <CustomModal handleClose={cancelModal} isModalVisible={isOpen} id={id}>
      <Container>
        <Header>
          <Title>{intl.formatMessage({ id: "new_collaborator" })}</Title>
        </Header>
        <Hr />
        <Content>
          <p>{intl.formatMessage({ id: "new_collaborator_confirmation" })}</p>
          <ButtonContainer>
            <Button 
              label={intl.formatMessage({ id: "no" })}
              type="link"
              onClick={cancelModal}
            />
            <Button 
              label={intl.formatMessage({ id: "yes" })}
              type="primary"
              onClick={confirmModal}
            />
          </ButtonContainer>
        </Content>
      </Container>
    </CustomModal>
  )
}

export default ConfirmationModal