import React from "react";

const File = (props) => {
  const { size = "17", color = "#282828", filled = false } = props;

  if (filled) {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={size}
        height={size}
        viewBox="0 0 17 16"
        fill="none"
      >
        <path
          d="M10.166 1.33301H4.49935C4.14573 1.33301 3.80659 1.47348 3.55654 1.72353C3.30649 1.97358 3.16602 2.31272 3.16602 2.66634V13.333C3.16602 13.6866 3.30649 14.0258 3.55654 14.2758C3.80659 14.5259 4.14573 14.6663 4.49935 14.6663H12.4993C12.853 14.6663 13.1921 14.5259 13.4422 14.2758C13.6922 14.0258 13.8327 13.6866 13.8327 13.333V4.99967L10.166 1.33301Z"
          stroke="#282828"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M9.83398 1.33301V5.33301H13.834"
          stroke="#282828"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    );
  }

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 17 16"
      fill="none"
    >
      <path
        d="M10.166 1.33301H4.49935C4.14573 1.33301 3.80659 1.47348 3.55654 1.72353C3.30649 1.97358 3.16602 2.31272 3.16602 2.66634V13.333C3.16602 13.6866 3.30649 14.0258 3.55654 14.2758C3.80659 14.5259 4.14573 14.6663 4.49935 14.6663H12.4993C12.853 14.6663 13.1921 14.5259 13.4422 14.2758C13.6922 14.0258 13.8327 13.6866 13.8327 13.333V4.99967L10.166 1.33301Z"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.83398 1.33301V5.33301H13.834"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default File;
