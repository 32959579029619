import styled, { css } from 'styled-components'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 900px;
  max-height: 90vh;
`

export const Header = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 15px;
`

export const Title = styled.h2`
  color:  #11A7D9;
  text-align: center;
  font-family: 'Open Sans';
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 32px;
  letter-spacing: -0.48px;
  padding: 16px 32px 0 32px;
`

export const Subtitle = styled.p`
  color: #6C6C6C;
  text-align: center;
  font-family: 'Open Sans';
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
`

export const Hr = styled.hr`
  border: 0.5px solid #D4D4D4;
`

export const Content = styled.div`
  color: #676F82;
  font-family: 'Open Sans';
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  padding: 16px 32px;
  text-align: justify;
  text-justify: inter-word;
  white-space: pre-line;

  ol, ul {
    margin-left: 30px;
  }
`

export const Footer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  padding: 16px 32px;
`

export const Button = styled.button`
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 35px;
  padding: 7.5px 16px;
  font-family: 'Inter';
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 15px;
  flex-shrink: 0;
  cursor: pointer;

  ${(props) =>
    props.color
      ? css`
          background: ${props.color};
          color: #11A7D9;
          border: none;
        `
      : css`
          background: #11A7D9;
          border: solid 1px #11A7D9;
          color: #FFF;
          box-shadow: 0px 0.75px 1.5px 0px rgba(16, 24, 40, 0.05);
          border-radius: 10px;
        `}
`

export const DownloadContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  color: #11A7D9;
  text-decoration: underline;
  margin-top: 10px;

  a, a:link, a:visited, a:hover, a:active {
    color: #11A7D9;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }
`