import styled from "styled-components";

import { Form, Steps } from "antd";

export const ContentForm = styled(Form)`
  margin-top: 30px;
  display: flex;
  width: 595px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 4px;
`;

export const ContainerBtn = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  gap: 8px;
`;

export const ContainerInscription = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 16px;
  width: 100%;
`;

export const CustomSteps = styled(Steps)`
  padding-bottom: 25px;
  width: 100%;
  height: 100%;

  .ant-steps-item {
    width: 50%;
  }

  .ant-steps-item-icon {
    width: 50px;
    height: 50px;
    margin: -11px 0 0 30px;
    font-size: 20px;
    line-height: 45px;
    border-style: double;
    border-width: unset;
  }

  .ant-steps-item-container {
    width: 110px;
  }

  .ant-steps-item-title {
    color: #6c6c6c;
    text-align: center;
    font-family: "Open Sans";
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 150%;
  }

  .ant-steps-finish-icon {
    svg {
      display: none;
    }
  }

  .ant-steps-label-vertical .ant-steps-item {
    border-color: transparent;
  }

  .ant-steps-finish-icon:after {
    content: "1";
  }

  .ant-steps-item-finish .ant-steps-item-icon {
    border-color: #6c6c6c;
    background-color: #c7c9d9;

    .ant-steps-icon {
      color: #6c6c6c;
    }
  }

  .ant-steps-item-finish
    > .ant-steps-item-container
    > .ant-steps-item-content
    > .ant-steps-item-title {
    color: #ffffff;
  }

  .ant-steps-item-process .ant-steps-item-icon {
    background: #49d78c;
    border-color: #ffffff;
    border-style: double;
    border-width: unset;
  }
`;

export const StepsContent = styled.div`
  width: 100%;
  height: 100%;
`;

export const StepsAction = styled.div`
  margin-top: 15px;
  width: 100%;
`;
