import styled from "styled-components";

import { Select } from "antd";

export const Container = styled.div``;

export const CustomSelect = styled(Select)`
  align-items: center;
  color: #444444 !important;
  font-family: 'Open Sans';
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  
  .ant-select-arrow .anticon > svg  {
    color: #444444;
    width: 14px;
    height: 14px;
  }

  .anticon {
    padding-left: 5px;
  }

  .ant-select-selection-placeholder{
    color: #444444 !important;
  }
`;
