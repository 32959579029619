import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  height: 94vh;
  background: #fcfcfc;
  padding: 25px 75px 50px 70px;
  overflow-y: auto;

  ::-webkit-scrollbar {
    width: 4px;
    background-color: #fcfcfc;
  }

  ::-webkit-scrollbar-thumb {
    background: #11a7d9;
    -webkit-border-radius: 1ex;
    -webkit-box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.75);
  }

  ::-webkit-scrollbar-corner {
    background: #11a7d9;
  }
`;

export const Title = styled.h1`
  color: #444;
  font-family: "Inter";
  font-size: 30px;
  font-style: normal;
  font-weight: 700;
  line-height: 100%;
  padding-bottom: 15px;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
`;

export const SubTitle = styled.div`
  width: 176px;
  height: 27px;
  flex-shrink: 0;
  color: #3a3a3c;
  font-family: "Open Sans";
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 170%;
`;

export const ContainerButton = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-items: center;
  justify-content: center;
  padding: 12px 0;
  gap: 6px;
  flex-shrink: 0;
`;

export const Link = styled.div`
  color: #11a7d9;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  text-decoration-line: underline;
  cursor: pointer;
`;
