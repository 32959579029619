import api from "./index";

export const getProfileExhibitorService = async (params) =>
  await api.patch("/exhibitor", {
    ...params,
  });

export const changeProfileExhibitorService = async (data) =>
  await api.put("/exhibitor/account", {
    ...data,
  });

export const changeProfileServiceProviderService = async (data) =>
  await api.put("/serviceprovider", {
    ...data,
  });
