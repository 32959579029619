import { notification } from "antd";
import Info from "../../newUXAssets/icons/Info";
import Error from "../../newUXAssets/icons/Error";
import WarningTriangle from "../../newUXAssets/icons/WarningTriangle";
import Success from "../../newUXAssets/icons/Success";

const useNotification = () => {
  const displaySuccess = (successText, successKey) => {
    notification.success({
      description: successText,
      placement: "topRight",
      key: successKey,
      icon: <Success />,
      style: {
        borderRadius: "5px",
        borderLeft: "2px solid  #05A660",
        background: "#FFF",
        width: "300px",
      },
    });
  };

  const displayWarning = (warningText, warningKey) => {
    notification.warning({
      description: warningText,
      placement: "topRight",
      key: warningKey,
      icon: <WarningTriangle />,
      style: {
        borderRadius: "5px",
        borderLeft: "2px solid  #E5B800",
        background: "#FFF",
        width: "300px",
      },
    });
  };

  const displayError = (errorText, errorKey) => {
    notification.error({
      description: errorText,
      placement: "topRight",
      key: errorKey,
      icon: <Error />,
      style: {
        borderRadius: "5px",
        borderLeft: "2px solid  #E53535",
        background: "#FFF",
        width: "300px",
      },
    });
  };

  const displayInfo = (infoText, infoKey) => {
    notification.info({
      description: infoText,
      placement: "topRight",
      key: infoKey,
      icon: <Info />,
      style: {
        borderRadius: "5px",
        borderLeft: "2px solid  #004FC4",
        background: "#FFF",
        width: "300px",
      },
    });
  };

  const close = (key) => {
    notification.close(key);
  };

  return {
    displaySuccess,
    displayWarning,
    displayError,
    displayInfo,
    close,
  };
};

export default useNotification;
