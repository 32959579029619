import React, { useEffect, useMemo, useRef, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useSelector } from "react-redux";
import { useHistory } from "react-router";
import AnimatedMainContainer from "../../components/AnimatedMainContainer";
import { useToast } from "../../hooks/toast";
import * as Yup from "yup";
import getValidationErrors from "../../utils/getValidationErrors";
import api from "../../service";
import { useCallback } from "react";
import InputWithLabel from "../../components/InputWithLabel";
import FormButton from "../../components/FormButton";
import { Form } from "@unform/web";
import { validateSingleFieldOnBlur } from "../../utils/formValidations";
import { format } from "date-fns";

import {
  Container,
  FormContainer,
  TitleContainer,
  Title,
  FieldsContainer,
  Horizontal,
  FilterActionsContainer,
  SubmitContainer,
  LinkButton,
  GoBackContainer,
} from "./styles";
import SelectWithLabel from "../../components/SelectWithLabel";
import Table from "../../components/Table";
import Badge from "../../components/Badge";
import UserModal from "./UserModal";
import { useLoading } from "../../hooks/loading";

const Users = () => {
  const { token } = useSelector((state) => state.auth);
  const [data, setData] = useState([]);
  const [selectedUser, setSelectedUser] = useState();
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const formRef = useRef(null);
  const intl = useIntl();
  const history = useHistory();
  const { addToast } = useToast();
  const [loading, setLoading] = useState(false);

  const [totalRows, setTotalRows] = useState(0);
  const [perPage, setPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [submittedForm, setSubmittedForm] = useState({
    type: "ALL",
    commercialName: "",
    email: "",
    active: null,
  });

  const schema = Yup.object().shape({
    type: Yup.string().nullable(),
    email: Yup.string().nullable(),
    commercial_name: Yup.string().nullable(),
    status: Yup.string().nullable(),
  });

  const fetchData = useCallback(
    async (
      page,
      size = perPage,
      requestBody = submittedForm,
      resetCurrentPage
    ) => {
      setLoading(true);
      if (loading) return;

      if (resetCurrentPage) setCurrentPage(1);

      api
        .patch(`/users?page=${page}&per_page=${size}`, requestBody, {
          headers: {
            "Content-Type": "application/json",
            Accept: "*/*",
            Authorization: `Bearer ${token}`,
          },
        })
        .then((result) => {
          setTotalRows(result.data.paginationInfo.totalElements);
          setData(result.data.users);
        })
        .catch((err) =>
          addToast({
            type: "error",
            title: intl.formatMessage({
              id: "error",
            }),
            description: intl.formatMessage({
              id: "api.error." + err.response.data.code,
            }),
          })
        )
        .finally(() => setLoading(false));
    },
    [addToast, intl, loading, perPage, submittedForm, token]
  );

  useEffect(() => fetchData(0, 10), []);

  const handleSubmit = useCallback(
    async (data) => {
      try {
        formRef.current?.setErrors({});

        await schema.validate(data, { abortEarly: false });

        const formData = {
          type: data.type,
          commercialName: data.commercial_name,
          email: data.email,
          active: data.status,
        };

        setSubmittedForm(formData);

        fetchData(0, perPage, formData, true);
      } catch (err) {
        setLoading(false);
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);

          formRef.current?.setErrors(errors);

          return;
        }
      }
    },
    [schema, fetchData, perPage]
  );

  const clearFields = () => {
    formRef.current.reset();
    formRef.current.setFieldValue("type", {
      label: intl.formatMessage({ id: "all" }),
      value: "ALL",
    });
    formRef.current.setFieldValue("status", {
      label: intl.formatMessage({ id: "both" }),
      value: null,
    });
  };

  const validationBlur = (name) => {
    let errors = formRef.current.getErrors();

    validateSingleFieldOnBlur(errors, schema, name, formRef);
  };

  const handleShowModal = (user) => {
    setSelectedUser(user);
    setModalIsOpen(true);
  };

  const handlePageChange = (page) => {
    fetchData(page - 1);
    setCurrentPage(page);
  };

  const handlePerRowsChange = async (newPerPage, page) => {
    fetchData(page - 1, newPerPage);
    setPerPage(newPerPage);
  };

  const columns = useMemo(
    () => [
      {
        name: intl.formatMessage({ id: "creation" }),
        selector: (row) => row.creation,
        format: (row) => {
          return format(new Date(row.createdAt), "dd/MM/yyyy");
        },
        maxWidth: "105px",
      },
      {
        name: "E-mail",
        selector: (row) => row.email,
      },
      {
        name: intl.formatMessage({ id: "company" }),
        selector: (row) => row.commercialName,
      },
      {
        name: intl.formatMessage({ id: "user_type" }),
        selector: (row) => row.roleType,
        format: (row) => {
          return intl.formatMessage({
            id: `${row.roleType.toLowerCase().replace(/ /g, "_")}`,
          });
        },
      },
      {
        name: intl.formatMessage({ id: "profile" }),
        selector: (row) => row.currentRoleName,
      },
      {
        name: "Status",
        selector: (row) => row.status,
        cell: (row) => {
          if (row.status === "ACTIVATED") {
            return (
              <Badge
                color="#33d27e"
                text={intl.formatMessage({ id: "active" })}
              />
            );
          }
          return (
            <Badge
              color="#C9C9C9"
              text={intl.formatMessage({ id: "inactive" })}
            />
          );
        },
        maxWidth: "200px",
      },
      {
        width: "125px",
        cell: (row) => (
          <>
            {(row.roleType.toLowerCase().replace(/ /g, "_") ===
              "service_provider" &&
              row.hasAccountServiceProvider) && (
              <LinkButton to={`/admin/service-provider-edit/${row.id}`}>
                <Badge
                  color="#002244"
                  text={intl.formatMessage({ id: "edit" })}
                />
              </LinkButton>
            )}
          </>
        ),
        right: true,
      },
      {
        width: "125px",
        cell: (row) => (
          <div
            onClick={() => handleShowModal(row)}
            style={{ cursor: "pointer", margin: "0 8px" }}
          >
            <Badge
              color="#002244"
              text={intl.formatMessage({ id: "manage" })}
            />
          </div>
        ),
        right: true,
      },
    ],
    [intl]
  );

  return (
    <AnimatedMainContainer
      breadcrumb={intl.formatMessage({ id: "breadcrumb.users" })}
    >
      <Container>
        <FormContainer>
          <Form
            ref={formRef}
            onSubmit={handleSubmit}
            initialData={{
              type: {
                label: intl.formatMessage({ id: "all" }),
                value: "ALL",
              },
              status: {
                label: intl.formatMessage({ id: "both" }),
                value: null,
              },
            }}
          >
            <TitleContainer>
              <Title>
                <FormattedMessage id="search_for_users" />
              </Title>
              <GoBackContainer>
                <LinkButton onClick={() => history.push("/dashboard")}>
                  <Badge
                    color="#002244"
                    text={intl.formatMessage({ id: "goback" })}
                    fontSize="12px"
                  />
                </LinkButton>
              </GoBackContainer>
            </TitleContainer>
            <FieldsContainer>
              <Horizontal>
                <SelectWithLabel
                  name="type"
                  options={[
                    {
                      label: intl.formatMessage({ id: "all" }),
                      value: "ALL",
                    },
                    {
                      label: intl.formatMessage({ id: "administrator" }),
                      value: "ADMINISTRATOR",
                    },
                    {
                      label: intl.formatMessage({ id: "staff" }),
                      value: "STAFF",
                    },
                    {
                      label: intl.formatMessage({ id: "exhibitor" }),
                      value: "EXHIBITOR",
                    },
                    {
                      label: intl.formatMessage({ id: "service_provider" }),
                      value: "SERVICE_PROVIDER",
                    },
                    {
                      label: intl.formatMessage({ id: "integration" }),
                      value: "INTEGRATION",
                    },
                  ]}
                  validationBlur={validationBlur}
                  label={intl.formatMessage({ id: "type" })}
                />
                <InputWithLabel
                  name="commercial_name"
                  validationBlur={validationBlur}
                  label={intl.formatMessage({ id: "company" })}
                />
                <InputWithLabel
                  name="email"
                  validationBlur={validationBlur}
                  label="E-mail"
                />

                <SelectWithLabel
                  name="status"
                  options={[
                    {
                      label: intl.formatMessage({ id: "active" }),
                      value: true,
                    },
                    {
                      label: intl.formatMessage({ id: "inactive" }),
                      value: false,
                    },
                    {
                      label: intl.formatMessage({ id: "both" }),
                      value: null,
                    },
                  ]}
                  validationBlur={validationBlur}
                  label="Status"
                />
              </Horizontal>
              <Horizontal>
                <FilterActionsContainer>
                  <SubmitContainer>
                    <FormButton
                      color="#C9C9C9"
                      type="button"
                      onClick={clearFields}
                    >
                      <FormattedMessage id="clear_fields" />
                    </FormButton>
                  </SubmitContainer>
                  <SubmitContainer>
                    <FormButton type="submit" loading={loading}>
                      <FormattedMessage id="search" />
                    </FormButton>
                  </SubmitContainer>
                </FilterActionsContainer>
              </Horizontal>
            </FieldsContainer>
          </Form>
        </FormContainer>
        <Table
          data={data}
          columns={columns}
          progressPending={loading}
          paginationServer
          paginationTotalRows={totalRows}
          paginationDefaultPage={currentPage}
          onChangeRowsPerPage={handlePerRowsChange}
          onChangePage={handlePageChange}
        />
        <UserModal
          user={selectedUser}
          setIsOpen={setModalIsOpen}
          modalIsOpen={modalIsOpen}
          updateTable={() => formRef.current.submitForm()}
        />
      </Container>
    </AnimatedMainContainer>
  );
};

export default Users;
