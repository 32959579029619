import React from "react";

const Basket = (props) => {
  const { size = "26", color = "#444444" } = props;

  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 26 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_539_13192)">
        <path
          d="M13 12.1875V17.875"
          stroke={color}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M18.6875 8.9375L13 2.4375L7.3125 8.9375"
          stroke={color}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M3.24926 8.9375C3.13405 8.9376 3.02018 8.96221 2.9152 9.00968C2.81023 9.05716 2.71655 9.12641 2.64038 9.21286C2.56422 9.2993 2.5073 9.40095 2.47342 9.51107C2.43954 9.62119 2.42947 9.73725 2.44387 9.85156C2.75567 12.1875 3.57426 18.33 3.85254 20.4141C3.87727 20.6103 3.97271 20.7908 4.12098 20.9216C4.26925 21.0525 4.46016 21.1248 4.65794 21.125H21.3406C21.5374 21.125 21.7276 21.0536 21.8757 20.9239C22.0239 20.7943 22.1199 20.6153 22.146 20.4202L23.5547 9.85766C23.57 9.74288 23.5606 9.62615 23.5272 9.51529C23.4937 9.40443 23.437 9.302 23.3607 9.21487C23.2844 9.12774 23.1904 9.05791 23.085 9.01007C22.9795 8.96224 22.8651 8.93749 22.7493 8.9375H3.24926Z"
          stroke={color}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M18.3617 12.1875L17.793 17.875"
          stroke={color}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M7.63672 12.1875L8.20547 17.875"
          stroke={color}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_824_1626">
          <rect width="32" height="32" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default Basket;
