import React from "react";

import { useHistory } from "react-router-dom";

import { useSelector } from "react-redux";

import CredentialsHeader from "../Components/Header";

import { useIntl } from "react-intl";

import { Container, Title } from "./styles";

import TermsTextPT from "./pt";
import TermsTextEN from "./en";

const MyAccount = () => {
  const intl = useIntl();
  const history = useHistory();

  const { code } = useSelector((state) => state.language);

  return (
    <Container>
      <Title>
        {intl.formatMessage({
          id: "my_account",
        })}
      </Title>
      <CredentialsHeader isTermsOfUse />
      {code === "pt" ? <TermsTextPT /> : <TermsTextEN />}
    </Container>
  );
};

export default MyAccount;
