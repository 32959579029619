import React from "react";

const ChatDots = (props) => {
  const { size = "26", color = "#444444" } = props;

  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 26 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_539_13202)">
        <path
          d="M4.58555 23.3705C4.46721 23.4701 4.32291 23.5338 4.16961 23.5542C4.01631 23.5746 3.86038 23.5508 3.72013 23.4856C3.57988 23.4205 3.46115 23.3166 3.37787 23.1863C3.29459 23.056 3.25023 22.9046 3.25 22.75V6.5C3.25 6.28451 3.3356 6.07785 3.48798 5.92548C3.64035 5.7731 3.84701 5.6875 4.0625 5.6875H21.9375C22.153 5.6875 22.3597 5.7731 22.512 5.92548C22.6644 6.07785 22.75 6.28451 22.75 6.5V19.5C22.75 19.7155 22.6644 19.9222 22.512 20.0745C22.3597 20.2269 22.153 20.3125 21.9375 20.3125H8.37891C8.18751 20.3126 8.00228 20.3802 7.85586 20.5034L4.58555 23.3705Z"
          stroke={color}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M13 14.2188C13.6731 14.2188 14.2188 13.6731 14.2188 13C14.2188 12.3269 13.6731 11.7812 13 11.7812C12.3269 11.7812 11.7812 12.3269 11.7812 13C11.7812 13.6731 12.3269 14.2188 13 14.2188Z"
          fill={color}
        />
        <path
          d="M8.53125 14.2188C9.20435 14.2188 9.75 13.6731 9.75 13C9.75 12.3269 9.20435 11.7812 8.53125 11.7812C7.85815 11.7812 7.3125 12.3269 7.3125 13C7.3125 13.6731 7.85815 14.2188 8.53125 14.2188Z"
          fill={color}
        />
        <path
          d="M17.4688 14.2188C18.1418 14.2188 18.6875 13.6731 18.6875 13C18.6875 12.3269 18.1418 11.7812 17.4688 11.7812C16.7957 11.7812 16.25 12.3269 16.25 13C16.25 13.6731 16.7957 14.2188 17.4688 14.2188Z"
          fill={color}
        />
      </g>
      <defs>
        <clipPath id="clip0_824_1616">
          <rect width="32" height="32" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default ChatDots;
