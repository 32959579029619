import styled, { css } from "styled-components";

import { Dropdown } from "antd";

export const Container = styled.div`
  width: 100%;
`;

export const DropdownCustom = styled(Dropdown)``;

export const ContainerFlag = styled.div`
  width: 130px;
  height: 50px;
  flex-shrink: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;

  span {
    padding: 2px;
  }
`;

export const Flag = styled.img`
  flex-shrink: 0;

  ${(props) =>
    props.oppened
      ? css`
          width: 25.517px;
          height: 25.517px;
        `
      : css`
          width: 22px;
          height: 22px;
        `}
`;
