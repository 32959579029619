import produce from "immer";

const INITIAL_STATE = {
  event: {},
  contract: {}
};

const settings = (state = INITIAL_STATE, action) => {
  return produce(state, (draft) => {
    switch (action.type) {
      case "ADD_SETTINGS": {
        const { event, contract } = action.payload;

        draft.event = event;
        draft.contract = contract;

        localStorage.setItem(
          "InformaMarket:initialSettings",
          JSON.stringify({event, contract})
        );

        break;
      }

      case "CLEAR_SETTINGS": {
        draft.event = [];
        draft.contract = [];
        localStorage.removeItem("InformaMarket:initialSettings");

        break;
      }

      default: {

        if(localStorage.getItem('InformaMarket:initialSettings')){

          const { event, contract } = JSON.parse(localStorage.getItem('InformaMarket:initialSettings'));

          draft.event = event;
          draft.contract = contract;
        }

        return draft;
      }
    }
  });
};

export default settings;
