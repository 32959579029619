import React, { useCallback, useEffect, useRef, useState } from "react";
import { useIntl, FormattedMessage } from "react-intl";
import { useSelector } from "react-redux";
import { useHistory, useParams } from "react-router";
import AnimatedMainContainer from "../../../components/AnimatedMainContainer";
import ProfileModal from "./ProfileModal";
import axios from "axios";
import {
  Container,
  TitleContainer,
  Title,
  FieldsContainer,
  SessionContainer,
  SessionTitle,
  Session,
  SessionDivider,
  ActionContainer,
  BadgeContainer,
} from "./styles";
import * as Yup from "yup";
import getValidationErrors from "../../../utils/getValidationErrors";
import { validateSingleFieldOnBlur } from "../../../utils/formValidations";
import api from "../../../service";
import { Form } from "@unform/web";
import SelectWithLabel from "../../../components/SelectWithLabel";
import InputWithHorizontalLabel from "../../../components/InputWithHorizontalLabel";
import Toggle from "../../../components/Toggle";
import { useToast } from "../../../hooks/toast";
import Badge from "../../../components/Badge";
import CheckboxInput from "../../../components/CheckboxInput";
import { useLoading } from "../../../hooks/loading";
import ConfirmationModal from "../../../components/ConfirmationModal";

const EditProfile = () => {
  const { token } = useSelector((state) => state.auth);
  const { id } = useParams();
  const [data, setData] = useState([]);
  const [userData, setUserData] = useState();
  const [key, setKey] = useState();
  const [userPermissions, setUserPermissions] = useState([]);
  const [, setFormPermissions] = useState([]);
  const [userType, setUserType] = useState(null);
  const { handleLoading } = useLoading();
  const [loading, setLoading] = useState(false);

  const formRef = useRef(null);
  const intl = useIntl();
  const history = useHistory();
  const userTypeOptions = [
    {
      label: intl.formatMessage({ id: "administrator" }),
      value: "administrator",
    },
    {
      label: `${intl.formatMessage({ id: "exhibitor" })}`,
      value: "exhibitor",
    },
    {
      label: `${intl.formatMessage({ id: "service_provider"})}`,
      value: "service_provider",
    },
  ];

  const schema = Yup.object().shape({
    name: Yup.string().required(
      intl.formatMessage({ id: "error.field_required" })
    ),
    user_type: Yup.string().required(
      intl.formatMessage({ id: "error.field_required" })
    ),
  });

  const { addToast } = useToast();
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [modalMessage, setModalMessage] = useState("");
  const [usersAlreadyWithThatRole, setUsersAlreadyWithThatRole] = useState();
  const [originalStatus, setOriginalStatus] = useState(true);
  const [isModalDeactiveOpen, setIsModalDeactiveOpen] = useState(false);
  const [modalProfileIsOpen, setModalProfileIsOpen] = useState(false);

  const closeAllModals = () => {
    setModalProfileIsOpen(false);
    setIsModalDeactiveOpen(false);
    setModalIsOpen(false);
  };

  const handleGetInformation = useCallback(() => {
    handleLoading(true);
    closeAllModals();
    axios
      .all([
        api.get("/role/permissions", {
          headers: {
            "Content-Type": "application/json",
            Accept: "*/*",
            Authorization: `Bearer ${token}`,
          },
        }),
        api.get(`/role/${id}`, {
          headers: {
            "Content-Type": "application/json",
            Accept: "*/*",
            Authorization: `Bearer ${token}`,
          },
        }),
      ])
      .then(
        axios.spread((permissionsResponse, roleResponse) => {
          let permissionsOutput = permissionsResponse.data;
          let roleOutput = roleResponse.data;

          setData(permissionsOutput.permissionCategories);

          setFormPermissions(
            permissionsOutput.permissionCategories.reduce(
              (array, item) => [...array.concat(item.permissions)],
              []
            )
          );
          setUserData(roleOutput.role);
          setOriginalStatus(roleOutput.role.status === "ACTIVATED");
          setKey(new Date());
          setUserPermissions(
            roleOutput.role.permissionCategories.reduce(
              (array, item) => [...array.concat(item.permissions)],
              []
            )
          );
          setUserType(roleOutput.role.type.toUpperCase());
          setUsersAlreadyWithThatRole(roleOutput.role.haveUsers);
        })
      )
      .catch((err) => {
        addToast({
          type: "error",
          title: intl.formatMessage({
            id: "error",
          }),
          description: intl.formatMessage({
            id: "api.error." + err.response.data.code,
          }),
        });
      })
      .finally(() => {
        handleLoading(false);
      });
  }, [addToast, handleLoading, id, intl, token]);

  useEffect(() => {
    handleGetInformation();
  }, [handleGetInformation]);

  const handleSubmit = useCallback(
    async (formData) => {
      try {
        setLoading(true);
        if (loading) {
          return;
        }
        formRef.current?.setErrors({});

        await schema.validate(formData, { abortEarly: false });

        const selectedPermissions = data.reduce(
          (array, item) => [...array.concat(formData[item.code])],
          []
        );
        const statusValue = formRef.current.getFieldValue("status");

        if (statusValue !== originalStatus) {
          setModalIsOpen(true);
          if (statusValue) {
            setModalMessage(
              intl.formatMessage({ id: "activate_profile_message" })
            );
          } else {
            if (!usersAlreadyWithThatRole) {
              setModalMessage(
                intl.formatMessage({ id: "deactivate_profile_message" })
              );
            } else {
              setModalIsOpen(false);
              setIsModalDeactiveOpen(true);
            }
          }
        } else {
          formRef.current.submitForm();
        }

        api
          .put(
            "/role",
            {
              id: parseInt(id),
              name: formData.name,
              type: formData.user_type.toUpperCase(),
              permissions: selectedPermissions,
              active: formData.status,
            },
            {
              headers: {
                "Content-Type": "application/json",
                Accept: "*/*",
                Authorization: `Bearer ${token}`,
              },
            }
          )
          .then((result) => {
            setLoading(false);
            addToast({
              type: "success",
              title: intl.formatMessage({
                id: "success",
              }),
              description: intl.formatMessage({
                id: "success.role_updated",
              }),
            });
            history.push("/profiles");
          })
          .catch((err) => {
            setLoading(false);
            addToast({
              type: "error",
              title: intl.formatMessage({
                id: "error",
              }),
              description: intl.formatMessage({
                id: "api.error." + err.response.data.code,
              }),
            });
          });
      } catch (err) {
        setLoading(false);
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);

          formRef.current?.setErrors(errors);

          return;
        }
      }
    },
    [
      loading,
      schema,
      data,
      id,
      token,
      usersAlreadyWithThatRole,
      addToast,
      intl,
      history,
      originalStatus,
    ]
  );

  const validationBlur = (name) => {
    let errors = formRef.current.getErrors();

    validateSingleFieldOnBlur(errors, schema, name, formRef);
  };

  const handleOpenModal = useCallback(() => {
    const statusValue = formRef.current.getFieldValue("status");

    if (statusValue !== originalStatus) {
      setModalIsOpen(true);
      if (statusValue) {
        setModalMessage(intl.formatMessage({ id: "activate_profile_message" }));
      } else {
        if (!usersAlreadyWithThatRole) {
          setModalMessage(
            intl.formatMessage({ id: "deactivate_profile_message" })
          );
        } else {
          setModalIsOpen(false);
          setIsModalDeactiveOpen(true);
        }
      }
    } else {
      formRef.current.submitForm();
    }
  }, [usersAlreadyWithThatRole, originalStatus, intl]);

  const cancelActivation = useCallback(() => {
    const previousValue = formRef.current.getFieldValue("status");
    formRef.current.setFieldValue("status", !previousValue);
  }, []);

  const handleShowModal = () => {
    setModalProfileIsOpen(true);
  };

  return (
    <AnimatedMainContainer
      breadcrumb={intl.formatMessage({ id: "breadcrumb.profiles" })}
    >
      <Container>
        <Form
          key={key}
          ref={formRef}
          onSubmit={handleSubmit}
          initialData={{
            status: userData?.status === "ACTIVATED",
            name: userData?.name,
            user_type: {
              label: userTypeOptions.filter(
                (value) => value.value === userData?.type?.toLowerCase()
              )[0]?.label,
              value: userData?.type?.toLowerCase(),
            },
          }}
        >
          <TitleContainer>
            <Title>
              <FormattedMessage id="permission_creation" />
            </Title>
            <ActionContainer>
              <BadgeContainer onClick={() => history.push("/profiles")}>
                <Badge
                  color="#C9C9C9"
                  text={intl.formatMessage({ id: "goback" })}
                  fontSize="12px"
                />
              </BadgeContainer>
              <BadgeContainer onClick={() => handleOpenModal()}>
                <Badge
                  color="#002244"
                  text={intl.formatMessage({ id: "save" })}
                  fontSize="12px"
                  loading={loading}
                />
              </BadgeContainer>
            </ActionContainer>
          </TitleContainer>
          <InputWithHorizontalLabel
            name="name"
            label={intl.formatMessage({ id: "name" })}
            labelWidth="150px"
            validationBlur={validationBlur}
            required
          />
          <SelectWithLabel
            name="user_type"
            label={intl.formatMessage({ id: "user_type" })}
            placeholder={intl.formatMessage({ id: "user_type" })}
            labelWidth="150px"
            labelOrientation="horizontal"
            options={userTypeOptions}
            validationBlur={validationBlur}
            onChange={(e) => {
              data.forEach((value) => {
                formRef.current.clearField(value.code);
              });

              setUserType(e.value);
            }}
            required
          />
          <Toggle label="Status" name="status" labelWidth="150px" />
          <SessionDivider>
            {data.map((value) => {
              return (
                <Session key={value.id}>
                  <SessionContainer>
                    <SessionTitle>
                      <FormattedMessage id={value.code.toLowerCase()} />
                    </SessionTitle>
                  </SessionContainer>
                  <FieldsContainer>
                    <CheckboxInput
                      name={value.code}
                      options={value.permissions.map((permission) => {
                        return {
                          id: permission.code,
                          value: permission.code,
                          label: intl.formatMessage({
                            id: permission.code.toLowerCase(),
                          }),
                          disabled: !permission?.types?.includes(
                            userType?.toUpperCase()
                          ),
                        };
                      })}
                      values={userPermissions}
                    />
                  </FieldsContainer>
                </Session>
              );
            })}
          </SessionDivider>
        </Form>
      </Container>
      <ConfirmationModal
        setIsOpen={setModalIsOpen}
        modalIsOpen={modalIsOpen}
        confirmationFunction={() => formRef.current.submitForm()}
        cancelationFunction={() => cancelActivation()}
        description={modalMessage}
        confirmationText={intl.formatMessage({ id: "yes" })}
        cancelText={intl.formatMessage({ id: "no" })}
      />
      <ConfirmationModal
        setIsOpen={setIsModalDeactiveOpen}
        modalIsOpen={isModalDeactiveOpen}
        confirmationFunction={() => handleShowModal()}
        cancelationFunction={() => cancelActivation()}
        description={intl.formatMessage({
          id: "deactivate_profile_with_user_message",
        })}
        confirmationText={intl.formatMessage({ id: "yes" })}
        cancelText={intl.formatMessage({ id: "no" })}
      />

      <ProfileModal
        currentRoleId={id}
        setIsOpen={setModalProfileIsOpen}
        modalIsOpen={modalProfileIsOpen}
        updateTable={() => formRef.current.submitForm()}
        closeCallback={() => closeAllModals()}
      />
    </AnimatedMainContainer>
  );
};

export default EditProfile;
