import React, { useEffect, useRef, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useSelector } from "react-redux";
import { useHistory } from "react-router";
import AnimatedMainContainer from "../../../components/AnimatedMainContainer";
import { useToast } from "../../../hooks/toast";
import * as Yup from "yup";
import getValidationErrors from "../../../utils/getValidationErrors";
import api from "../../../service";
import { useCallback } from "react";
import InputWithLabel from "../../../components/InputWithLabel";
import MaskedInputWithLabel from "../../../components/MaskedInputWithLabel";
import FormButton from "../../../components/FormButton";
import { Form } from "@unform/web";
import { validateSingleFieldOnBlur } from "../../../utils/formValidations";

import {
  Container,
  FormContainer,
  TitleContainer,
  Title,
  FieldsContainer,
  Horizontal,
  FilterActionsContainer,
  SubmitContainer,
  LinkButton,
  ActionContainer,
  BadgeContainer,
} from "./styles";
import Table from "../../../components/Table";
import Badge from "../../../components/Badge";
import ConfirmationModal from "../../../components/ConfirmationModal";
import { useLoading } from "../../../hooks/loading";
import useGenericDocumentMask from "../../../utils/useGenericDocumentMask";

const AssociateColladorators = () => {
  const { token } = useSelector((state) => state.auth);
  const [data, setData] = useState([]);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [collaboratorId, setCollaboratorId] = useState(null);
  const formRef = useRef(null);
  const intl = useIntl();
  const history = useHistory();
  const { addToast } = useToast();
  const { handleLoading } = useLoading();
  const [loading, setLoading] = useState(false);
  const [documentMask, setDocumentMask] = useGenericDocumentMask();

  const [totalRows, setTotalRows] = useState(0);
  const [perPage, setPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [submittedForm, setSubmittedForm] = useState({
    document: "",
    name: "",
  });

  const schema = Yup.object().shape({
    document: Yup.string(),
    name: Yup.string(),
  });

  const fetchData = useCallback(
    async (
      page,
      size = perPage,
      requestBody = submittedForm,
      resetCurrentPage
    ) => {
      setLoading(true);
      if (loading) return;

      if (resetCurrentPage) setCurrentPage(1);

      api
        .patch(
          `/associate-collaborators?page=${page}&per_page=${size}`,
          requestBody,
          {
            headers: {
              "Content-Type": "application/json",
              Accept: "*/*",
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((result) => {
          setData(result.data.associateCollaboratorListOutput);
          setTotalRows(result.data.paginationInfo.totalElements);
        })
        .catch((err) =>
          addToast({
            type: "error",
            title: intl.formatMessage({
              id: "error",
            }),
            description: intl.formatMessage({
              id: "api.error." + err.response.data.code,
            }),
          })
        )
        .finally(() => setLoading(false));
    },
    [addToast, intl, loading, perPage, submittedForm, token]
  );

  const showConfirmation = () => {
    setModalIsOpen(true);
  };

  const getAllAssociateCollaborators = useCallback(() => {
    handleLoading(true);
    api
      .get("/associate-collaborators/", {
        headers: {
          "Content-Type": "application/json",
          Accept: "*/*",
          Authorization: `Bearer ${token}`,
        },
      })
      .then((result) => {
        setData(result.data.associateCollaboratorListOutput);
      })
      .catch((err) => {
        addToast({
          type: "error",
          title: intl.formatMessage({
            id: "error",
          }),
          description: intl.formatMessage({
            id: "api.error." + err.response.data.code,
          }),
        });
      })
      .finally(() => {
        handleLoading(false);
      });
  }, [addToast, intl, token, handleLoading]);

  useEffect(() => {
    const params = new URLSearchParams(document.location.search);

    if(params.get("name")) formRef.current.setFieldValue('name', params.get("name"))
    if(params.get("document")) formRef.current.setFieldValue('document', String(params.get("document")))
    formRef.current.submitForm()
  },[]);

  const handleSubmit = useCallback(
    async (data) => {
      try {
        formRef.current?.setErrors({});

        await schema.validate(data, { abortEarly: false });

        setLoading(true);
        if (loading) {
          return;
        }

        const formData = {
          name: data.name,
          document: data.document,
        };

        setSubmittedForm(formData);
        fetchData(0, perPage, formData, true);
      } catch (err) {
        setLoading(false);
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);

          formRef.current?.setErrors(errors);

          return;
        }
      }
    },
    [fetchData, loading, perPage, schema]
  );

  const handleDeletion = () => {
    handleLoading(true);
    api
      .delete(`/associate-collaborators/${collaboratorId}`, {
        headers: {
          "Content-Type": "application/json",
          Accept: "*/*",
          Authorization: `Bearer ${token}`,
        },
      })
      .then((_) => {
        addToast({
          type: "success",
          title: intl.formatMessage({
            id: "success",
          }),
          description: intl.formatMessage({
            id: "success.collaborator_deletion",
          }),
        });
        setData(data.filter((value) => value.id !== collaboratorId));
        setCollaboratorId(null);
        setModalIsOpen(false);
      })
      .catch((err) => {
        setCollaboratorId(null);
        addToast({
          type: "error",
          title: intl.formatMessage({
            id: "error",
          }),
          description: intl.formatMessage({
            id: "api.error." + err.response.data.code,
          }),
        });
      })
      .finally(() => handleLoading(false));
  };

  const handlePageChange = (page) => {
    fetchData(page - 1);
    setCurrentPage(page);
  };

  const handlePerRowsChange = async (newPerPage, page) => {
    fetchData(page - 1, newPerPage);
    setPerPage(newPerPage);
  };

  const validationBlur = (name) => {
    let errors = formRef.current.getErrors();

    validateSingleFieldOnBlur(errors, schema, name, formRef);
  };

  const clearFields = () => {
    formRef.current.reset();
    setSubmittedForm({
      document: "",
      name: "",
    })
    getAllAssociateCollaborators();
  };

  const columns = [
    {
      name: intl.formatMessage({ id: "name" }),
      selector: (row) => row.name,
      sortable: true,
    },
    {
      name: intl.formatMessage({ id: "document_filter" }),
      selector: (row) => row.document,
    },
    {
      name: intl.formatMessage({ id: "assignment" }),
      selector: (row) => row.jobTitle,
      sortable: true,
    },
    {
      cell: (row) => (
        <>
          <LinkButton onClick={()=> handleEditClick(row.id)}>
            <Badge color="#002244" text={intl.formatMessage({ id: "edit" })} />
          </LinkButton>

          <Badge
            onClick={() => {
              setCollaboratorId(row.id);
              showConfirmation();
            }}
            color="#cc0000"
            text={intl.formatMessage({ id: "delete" })}
          />
        </>
      ),
      right: true,
    },
  ];

  const buildReturnUrl = ()=>{
    const initialURL = `/associate-collaborators?`
    const partialURLName = !submittedForm.name ? "" : `&name=${submittedForm.name}`
    const partialURLDocument = !submittedForm.document ? "" : `&document=${submittedForm.document}`

    return initialURL + partialURLName + partialURLDocument
  }

  const handleEditClick = (id) =>{
    history.push(buildReturnUrl())
    history.push(`associate-collaborator/edit/${id}`)
  }


  return (
    <AnimatedMainContainer
      breadcrumb={intl.formatMessage({
        id: "breadcrumb.consult_registered_collaborators",
      })}
    >
      <Container>
        <FormContainer>
          <Form ref={formRef} onSubmit={handleSubmit}>
            <TitleContainer>
              <Title>
                <FormattedMessage id="search_for_collaborators" />
              </Title>
              <ActionContainer>
                <BadgeContainer onClick={() => history.push("/dashboard")}>
                  <Badge
                    color="#C9C9C9"
                    text={intl.formatMessage({ id: "goback" })}
                    fontSize="12px"
                  />
                </BadgeContainer>
              </ActionContainer>
            </TitleContainer>
            <FieldsContainer>
              <Horizontal>
                <InputWithLabel
                  name="name"
                  validationBlur={validationBlur}
                  label={intl.formatMessage({ id: "name" })}
                />
                <MaskedInputWithLabel
                  name="document"
                  maskChar={null}
                  mask={documentMask}
                  onChange={setDocumentMask}
                  defaultValue={submittedForm.document}
                  validationBlur={validationBlur}
                  label={intl.formatMessage({ id: "document_cpf_or_passport" })}
                />
              </Horizontal>
              <Horizontal>
                <FilterActionsContainer>
                  <SubmitContainer>
                    <FormButton
                      color="#C9C9C9"
                      type="button"
                      onClick={clearFields}
                    >
                      <FormattedMessage id="clear_fields" />
                    </FormButton>
                  </SubmitContainer>
                  <SubmitContainer>
                    <FormButton type="submit" loading={loading}>
                      <FormattedMessage id="search" />
                    </FormButton>
                  </SubmitContainer>
                </FilterActionsContainer>
              </Horizontal>
            </FieldsContainer>
          </Form>
        </FormContainer>
      </Container>
      <Table
        columns={columns}
        data={data}
        title={intl.formatMessage({ id: "results" })}
        progressPending={loading}
        paginationServer
        paginationTotalRows={totalRows}
        paginationDefaultPage={currentPage}
        onChangeRowsPerPage={handlePerRowsChange}
        onChangePage={handlePageChange}
      />
      <ConfirmationModal
        setIsOpen={setModalIsOpen}
        modalIsOpen={modalIsOpen}
        confirmationFunction={handleDeletion}
        description={intl.formatMessage({ id: "exclusion_confirmation" })}
        confirmationText={intl.formatMessage({ id: "yes" })}
        cancelText={intl.formatMessage({ id: "no" })}
      />
    </AnimatedMainContainer>
  );
};

export default AssociateColladorators;
