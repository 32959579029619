export const INITIAL_VALUES = {
  name: "",
  telephone: "",
  date: "",
  hour: "",
};

export const validation = (key, intl) => {
  switch (key) {
    case "name":
      return [
        {
          required: true,
          message: intl.formatMessage({ id: "error.field_required" }),
        },
      ];


    case "date":
      return [
        {
          required: true,
          message: intl.formatMessage({ id: "error.field_required" }),
        },
      ];

    case "telephone":
      return [
        {
          required: true,
          message: intl.formatMessage({ id: "error.field_required" }),
        },
      ];

    case "hour":
      return [
        {
          required: true,
          message: intl.formatMessage({ id: "error.field_required" }),
        },
      ];

    default:
      return [];
  }
};
