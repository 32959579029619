import React, { useMemo } from "react";
import { useSelector } from "react-redux";

import Phone from "../../newUXAssets/icons/Phone";

import { useIntl } from "react-intl";

import WhatsappLogo from "../../newUXAssets/images/WhastappLogo.png";

import {
  Container,
  Title,
  Content,
  Information,
  Label,
  Description,
  LogoWhatsApp,
  Hr,
} from "./styles";

const Contact = () => {
  const intl = useIntl();

  const { whatsapp, telephone, openingHoursPt, openingHoursEn } = useSelector(
    (state) => state.system
  );

  const description = useMemo(() => {
    if (intl.locale === "pt") {
      return openingHoursPt;
    }

    return openingHoursEn;
  }, [intl, openingHoursPt, openingHoursEn]);

  return (
    <Container>
      <Title>
        {intl.formatMessage({
          id: "contact",
        })}
      </Title>
      <Hr />
      <Content>
        <Information>
          <Label>
            {intl.formatMessage({
              id: "telephone",
            })}
          </Label>
          <Description type="phone">
            <Phone color="#11A7D9" />
            <span>{telephone}</span>
          </Description>
        </Information>
        <Information>
          <Label>WhatsApp</Label>
          <Description type="whatsapp">
            <a href={whatsapp} rel="noreferrer" target="_blank">
              <LogoWhatsApp src={WhatsappLogo} alt="logo Whatsapp" />
              <span>
                {intl.formatMessage({
                  id: "open_whatsapp",
                })}
              </span>
            </a>
          </Description>
        </Information>
        <Information>
          <Label>
            {intl.formatMessage({
              id: "opening_hours",
            })}
          </Label>
          <Description type="service">
            <span>{description}</span>
          </Description>
        </Information>
      </Content>
    </Container>
  );
};

export default Contact;
