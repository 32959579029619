export const INITIAL_VALUES = {
  type: "",
  name: "",
  corporateName: "",
  document: "",
  stateInscription: "",
  cityInscription: "",
  country: "",
  email: "",
  taxes: "",
  provisionTypes: [],
  zipCode: "",
  address: "",
  addressNumber: "",
  district: "",
  city: "",
  state: "",
  phone: "",
  mobile: "",
};

export const validation = (key, intl) => {
  switch (key) {
    case "type":
      return [
        {
          required: true,
          message: intl.formatMessage({ id: "error.field_required" }),
        },
      ];

    case "name":
      return [
        {
          required: true,
          message: intl.formatMessage({ id: "error.field_required" }),
        },
      ];
    case "corporateName":
      return [
        {
          required: true,
          message: intl.formatMessage({ id: "error.company_name_required" }),
        },
      ];

    case "document":
      return [
        {
          required: true,
          message: intl.formatMessage({ id: "error.field_required" }),
        },
      ];

    case "stateInscription":
      return [
        {
          required: false,
          message: intl.formatMessage({ id: "error.field_required" }),
        },
      ];

    case "cityInscription":
      return [
        {
          required: false,
          message: intl.formatMessage({ id: "error.field_required" }),
        },
      ];

    case "country":
      return [
        {
          required: false,
          message: intl.formatMessage({ id: "error.field_required" }),
        },
      ];

    case "email":
      return [
        {
          required: false,
          message: intl.formatMessage({ id: "error.email_required" }),
        },
      ];

    case "taxes":
      return [
        {
          required: true,
          message: intl.formatMessage({ id: "error.field_required" }),
        },
      ];

    case "typesOfService":
      return [
        {
          required: true,
          message: intl.formatMessage({ id: "error.field_required" }),
        },
      ];

    case "zipCode":
      return [
        {
          required: false,
          message: intl.formatMessage({ id: "error.field_required" }),
        },
      ];

    case "address":
      return [
        {
          required: false,
          message: intl.formatMessage({ id: "error.field_required" }),
        },
      ];

    case "addressNumber":
      return [
        {
          required: false,
          message: intl.formatMessage({ id: "error.field_required" }),
        },
      ];

    case "district":
      return [
        {
          required: false,
          message: intl.formatMessage({ id: "error.field_required" }),
        },
      ];

    case "city":
      return [
        {
          required: false,
          message: intl.formatMessage({ id: "error.field_required" }),
        },
      ];

    case "state":
      return [
        {
          required: false,
          message: intl.formatMessage({ id: "error.field_required" }),
        },
      ];

    case "phone":
      return [
        {
          required: true,
          message: intl.formatMessage({ id: "error.field_required" }),
        },
      ];

    case "mobile":
      return [
        {
          required: true,
          message: intl.formatMessage({ id: "error.field_required" }),
        },
      ];

    default:
      return [];
  }
};
