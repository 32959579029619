import { all, takeLatest, call, put } from "redux-saga/effects";

import { setBenefitedSuccess, setTransferHistorySuccess } from "./actions";
import {
  getBenefitedService,
  getFindServiceServices,
  getTypesCredentialOpportunityServices,
  createCredentialTransferServices,
  getTransferHistoryService,
} from "../../../service/transferCredential.service";

function* getBenefited({ payload }) {
  try {
    const response = yield call(getBenefitedService, payload.opportunityId);

    if (response.status === 200) {
      yield put(
        setBenefitedSuccess(response.data.systemUserByOpportunityOutputs)
      );
    }
  } catch (error) {
    payload.callbackError(error.response.data.code);
  }
}

function* getFindServices({ payload }) {
  try {
    const response = yield call(getFindServiceServices, payload.params);

    if (response.status === 200) {
      payload.callbackSuccess(response.data.output);
    }
  } catch (error) {
    payload.callbackError(error.response.data.code);
  }
}

function* getTypesCredentialOpportunity({ payload }) {
  try {
    const response = yield call(
      getTypesCredentialOpportunityServices,
      payload.params
    );

    if (response.status === 200) {
      payload.callbackSuccess(
        response.data.ecommerceChlebaCategoryCredentialAvailableTransferOutputs
      );
    }
  } catch (error) {
    payload.callbackError(error.response.data.code);
  }
}

function* createCredentialTransfer({ payload }) {
  try {
    const response = yield call(
      createCredentialTransferServices,
      payload.params
    );

    if (response.status === 200) {
      payload.callbackSuccess();
    }
  } catch (error) {
    payload.callbackError(error.response.data.code);
  }
}

function* getTransferHistory({ payload }) {
  try {
    const response = yield call(getTransferHistoryService, payload.params);

    if (response.status === 200) {
      yield put(
        setTransferHistorySuccess(response.data.credentialTransferOutputs)
      );
      payload.callbackPaginationInfo(response.data?.paginationInfo);
    }
  } catch (error) {
    payload.callbackError(error.response.data.code);
  }
}

export default all([
  takeLatest("GET_BENEFITED", getBenefited),
  takeLatest("GET_FIND_SERVICES", getFindServices),
  takeLatest("GET_TYPES_CREDENTIAL_OPPORTUNITY", getTypesCredentialOpportunity),
  takeLatest("CREATE_CREDENTIAL_TRANSFER", createCredentialTransfer),
  takeLatest("GET_TRANSFER_HISTORY", getTransferHistory),
]);
