import styled from "styled-components";

import { Collapse } from "antd";

const { Panel } = Collapse;

export const Container = styled.div`
  width: 100%;
  height: 94vh;
  background: #fcfcfc;
  padding: 25px 75px 50px 70px;
  overflow-y: auto;

  ::-webkit-scrollbar {
    width: 4px;
    background-color: #fcfcfc;
  }

  ::-webkit-scrollbar-thumb {
    background: #11a7d9;
    -webkit-border-radius: 1ex;
    -webkit-box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.75);
  }

  ::-webkit-scrollbar-corner {
    background: #11a7d9;
  }
`;

export const Title = styled.h1`
  color: #11a7d9;
  font-family: "Inter";
  font-size: 30px;
  font-style: normal;
  font-weight: 700;
  line-height: 100%;
  border-bottom: 1px solid #d4d4d4;
  padding-bottom: 15px;
`;

export const Hr = styled.hr`
  border-radius: 30px;
  background: #11a7d9;
  width: 376px;
  height: 2px;
  margin-bottom: 24px;
  margin-top: -19.5px;
`;

export const Content = styled.div`
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  width: 100%;
`;

export const Actions = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 64px;
  gap: 5px;
`;

export const BtnActions = styled.div`
  display: flex;
  height: 35px;
  width: 40px;
  padding: 8px 12px;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  cursor: pointer;
  box-shadow: 0px 0.751px 1.503px 0px rgba(16, 24, 40, 0.05);
  background: ${({ $isDelete, $disabled }) => {
    if ($isDelete && !$disabled) {
      return "#024";
    }

    if (!$isDelete && !$disabled) {
      return "#11a7d9";
    }

    if ($disabled) {
      return "#C7C9D9";
    }
  }};
`;
