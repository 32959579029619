import React from "react";

import { ModalContainer } from "./styles";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    borderRadius: "10px",
    transform: "translate(-50%, -50%)",
  },
  overlay: {
    zIndex: 999999,
    backgroundColor: "rgba(68, 68, 68, 0.8)"
  },
};

const CustomModal = ({ isModalVisible, handleClose, id, children }) => {
  return (
    <ModalContainer
      id={id}
      isOpen={isModalVisible}
      onRequestClose={() => handleClose()}
      style={customStyles}
      transparent={true}
      className="Modal"
    >
      {children}
    </ModalContainer>
  );
};

export default CustomModal;
