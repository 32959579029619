import React, { useMemo } from "react";

import { useIntl } from "react-intl";

import Table from "../../../newUXComponents/Table";

import { Container } from "./styles";

const ContractProductsServices = ({ data = [] }) => {
  const intl = useIntl();

  const columnsProducts = useMemo(
    () => [
      {
        title: intl.formatMessage({ id: "product_name" }),
        dataIndex: "productLocalName",
        key: "productLocalName",
      },
      {
        title: intl.formatMessage({ id: "quantity" }),
        dataIndex: "quantity",
        key: "quantity",
        width: 200,
      },
    ],
    [intl]
  );

  return (
    <Container>
      <Table
        title={intl.formatMessage({ id: "contract_products_and_services" })}
        dataSource={data}
        columns={columnsProducts}
        disabledPagination
        isDisabledSeach
      />
    </Container>
  );
};

export default ContractProductsServices;
