import produce from "immer";

const INITIAL_STATE = {
  allContractByEvent: [],
  contractInfo: {},
  order: [],
};

const contract = (state = INITIAL_STATE, action) => {
  return produce(state, (draft) => {
    switch (action.type) {
      case "SET_ALL_CONTRACT": {
        const { allContract } = action.payload;

        draft.allContractByEvent = allContract;

        localStorage.setItem(
          "InformaMarket:allContractByEvent",
          JSON.stringify(allContract)
        );

        break;
      }

      case "CLEAR_ALL_CONTRACT": {

        draft.allContractByEvent = [];

        localStorage.setItem(
          "InformaMarket:allContractByEvent",
          JSON.stringify([])
        );

        break;
      }

      case "SET_CONTRACT_SUCCESS": {
        const { contract } = action.payload;

        draft.contractInfo = contract;

        break;
      }

      case "SET_ORDER_BY_CONTRACT_SUCCESS": {
        const { order } = action.payload;

        draft.order = order;

        break;
      }

      default: {
        return draft;
      }
    }
  });
};

export default contract;
