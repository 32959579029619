import React, { useCallback, useEffect, useRef, useState } from "react";
import { useHistory, useParams } from "react-router-dom";

import { Form } from "@unform/web";
import * as Yup from "yup";
import getValidationErrors from "../../../utils/getValidationErrors";

import { useSelector } from "react-redux";

import { FormattedMessage, useIntl } from "react-intl";
import { validateSingleFieldOnBlur } from "../../../utils/formValidations";

import {
  ActionContainer,
  BadgeContainer,
  Container,
  Session,
  SessionContainer,
  SessionDivider,
  SessionTitle,
  Horizontal,
} from "./styles";

import { useToast } from "../../../hooks/toast";
import api from "../../../service";
import AnimatedMainContainer from "../../../components/AnimatedMainContainer";
import { useLoading } from "../../../hooks/loading";
import InputWithLabel from "../../../components/InputWithLabel";
import TextareaWithLabelAndEditor from "../../../newUXComponents/TextareaWithLabelAndEditor"
import { FieldsContainer, Title, TitleContainer } from "../styles";
import Badge from "../../../components/Badge";

const FaqEdition = (props) => {
  const formRef = useRef(null);
  const intl = useIntl();
  const { addToast } = useToast();
  const { token } = useSelector((state) => state.auth);
  const { id } = useParams();
  const { handleLoading } = useLoading();
  const [loading, setLoading] = useState(false);
  const [formKey, setFormKey] = useState();

  const [data, setData] = useState({
    questionPT: "",
    answerPT: "",
    questionEN: "",
    answerEN: "",
  });

  const history = useHistory();

  const schema = Yup.object().shape({
    questionPT: Yup.string().required(
      intl.formatMessage({ id: "error.field_required" })
    ),
    answerPT: Yup.string().required(
      intl.formatMessage({ id: "error.field_required" })
    ),
    questionEN: Yup.string().required(
      intl.formatMessage({ id: "error.field_required" })
    ),
    answerEN: Yup.string().required(
      intl.formatMessage({ id: "error.field_required" })
    ),
    linkvideo: Yup.string().url(
      intl.formatMessage({ id: "error.valid_url" })
    ),
    faqPosition: Yup.string().matches(
      /^(^$|([0-9]*))$/g,
      intl.formatMessage({ id: "error.need_to_be_number" })
    ),
  });

  const handleSubmit = useCallback(
    async (data) => {
      try {
        formRef.current?.setErrors({});

        setLoading(true);
        if (loading) {
          return;
        }

        await schema.validate(data, { abortEarly: false });

        api
          .put(
            `/faq/${id}`,
            {
              questionPT: data.questionPT,
              answerPT: data.answerPT,
              questionEN: data.questionEN,
              answerEN: data.answerEN,
              url: data.linkvideo,
              faqPosition: data.faqPosition,
            },
            {
              headers: {
                "Content-Type": "application/json",
                Accept: "*/*",
                Authorization: `Bearer ${token}`,
              },
            }
          )
          .then((data) => {
            setLoading(false);
            addToast({
              type: "success",
              title: intl.formatMessage({
                id: "success",
              }),
              description: intl.formatMessage({
                id: "success.faq_updated",
              }),
            });
            history.push("/faq");
          })
          .catch((err) => {
            setLoading(false);
            addToast({
              type: "error",
              title: intl.formatMessage({
                id: "error",
              }),
              description: intl.formatMessage({
                id: "api.error." + err.response.data.code,
              }),
            });
          });
      } catch (err) {
        setLoading(false);
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);

          formRef.current?.setErrors(errors);

          return;
        }
      }
    },
    [addToast, history, id, intl, loading, schema, token]
  );

  const validationBlur = (name) => {
    let errors = formRef.current.getErrors();

    validateSingleFieldOnBlur(errors, schema, name, formRef);
  };

  useEffect(() => {
    handleLoading(true);
    api
      .get(`/faq/${id}`, {
        headers: {
          "Content-Type": "application/json",
          Accept: "*/*",
          Authorization: `Bearer ${token}`,
        },
      })
      .then((result) => {
        setData(result.data.faqOutput);
        setFormKey(new Date());
      })
      .catch((err) => {
        addToast({
          type: "error",
          title: intl.formatMessage({
            id: "error",
          }),
          description: intl.formatMessage({
            id: "api.error." + err.response.data.code,
          }),
        });
      })
      .finally(() => {
        handleLoading(false);
      });
  }, [addToast, history, id, intl, token, handleLoading]);

  const pushBack = () => {
    history.push("/faq");
  };

  return (
    <AnimatedMainContainer>
      <Container>
        <Form
          ref={formRef}
          onSubmit={handleSubmit}
          initialData={{
            questionPT: data.questionPT,
            answerPT: data.answerPT,
            questionEN: data.questionEN,
            answerEN: data.answerEN,
            linkvideo: data.url,
            faqPosition: data.faqPosition,
          }}
          key={formKey}
        >
          <TitleContainer>
            <Title>
              <FormattedMessage id="faq.edit" />
            </Title>
            <ActionContainer>
              <BadgeContainer onClick={() => pushBack()}>
                <Badge
                  color="#C9C9C9"
                  text={intl.formatMessage({ id: "goback" })}
                  fontSize="12px"
                />
              </BadgeContainer>
              <BadgeContainer onClick={() => formRef.current.submitForm()}>
                <Badge
                  color="#002244"
                  text={intl.formatMessage({ id: "save" })}
                  fontSize="12px"
                  loading={loading}
                />
              </BadgeContainer>
            </ActionContainer>
          </TitleContainer>
          <Session>
            <FieldsContainer>
              <Horizontal>
                <InputWithLabel
                  name="linkvideo"
                  validationBlur={validationBlur}
                  placeholder={intl.formatMessage({ id: "video_link" })}
                  label={intl.formatMessage({ id: "video_link" })}
                />

                <InputWithLabel
                  name="faqPosition"
                  validationBlur={validationBlur}
                  placeholder={intl.formatMessage({ id: "faq_position" })}
                  label={intl.formatMessage({ id: "faq_position" })}
                />
              </Horizontal>
            </FieldsContainer>
          </Session>
          <SessionDivider nr_items="2">
            <Session>
              <SessionContainer>
                <SessionTitle>
                  <FormattedMessage id="portuguese" />
                </SessionTitle>
              </SessionContainer>
              <FieldsContainer>
                <InputWithLabel
                  name="questionPT"
                  validationBlur={validationBlur}
                  placeholder={intl.formatMessage({ id: "question_pt" })}
                  label={intl.formatMessage({ id: "question_pt" })}
                />
                <TextareaWithLabelAndEditor
                  name="answerPT"
                  placeholder={intl.formatMessage({ id: "answer_pt" })}
                  label={intl.formatMessage({ id: "answer_pt" })}
                  validationBlur={validationBlur}
                />
              </FieldsContainer>
            </Session>
            <Session>
              <SessionContainer>
                <SessionTitle>
                  <FormattedMessage id="english" />
                </SessionTitle>
              </SessionContainer>
              <FieldsContainer>
                <InputWithLabel
                  name="questionEN"
                  validationBlur={validationBlur}
                  placeholder={intl.formatMessage({ id: "question_en" })}
                  label={intl.formatMessage({ id: "question_en" })}
                />
                <TextareaWithLabelAndEditor
                  name="answerEN"
                  placeholder={intl.formatMessage({ id: "answer_en" })}
                  label={intl.formatMessage({ id: "answer_en" })}
                  validationBlur={validationBlur}
                />
              </FieldsContainer>
            </Session>
          </SessionDivider>
        </Form>
      </Container>
    </AnimatedMainContainer>
  );
};

export default FaqEdition;
