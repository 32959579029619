import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";

import { useHistory } from "react-router";
import { Form } from "@unform/web";
import { FormattedMessage, useIntl } from "react-intl";
import { useSelector } from "react-redux";
import * as Yup from "yup";
import { CircularProgressbar } from "react-circular-progressbar";
import { FaCheckSquare } from "react-icons/fa";
import { BsSquare } from "react-icons/bs";
import format from "date-fns/format";

import AnimatedMainContainer from "../../components/AnimatedMainContainer";
import Badge from "../../components/Badge";
import FormButton from "../../components/FormButton";
import api from "../../service";
import { useToast } from "../../hooks/toast";
import { useLoading } from "../../hooks/loading";
import SelectWithLabel from "../../components/SelectWithLabel";
import { validateSingleFieldOnBlur } from "../../utils/formValidations";
import Table from "../../components/Table";
import getValidationErrors from "../../utils/getValidationErrors";

import {
  Container,
  TitleContainer,
  Title,
  FieldsContainer,
  Horizontal,
  FilterActionsContainer,
  SubmitContainer,
  LinkButton,
  GoBackContainer,
  Session, 
  CentralizedContainer,
  ProgressBarContainer,
  ProgressBarTitle
} from "./styles";

const AccountJourney = () =>{
  const [events, setEvents] = useState([]);
  const [exhibitors, setExhibitors] = useState([]);
  const [contracts, setContracts] = useState([])
  const [data, setData] = useState()
  const [selectedEvent, setSelectedEvent] = useState();
  const [selectedExhibitor, setSelectedExhibitor] = useState();
  const [totalJourneyItems, setTotalJourneyItems] = useState(0);
  const [completedJourneyItems, setCompletedJourneyItems] = useState(0);
  const [percentage, setPercentage] = useState(0);
  const [loading, setLoading] = useState(false)
  const [selectedContract, setSelectedContract] = useState();
  const [filtered, setFiltered] = useState(false);

  const { token } = useSelector((state) => state.auth);

  const intl = useIntl();
  const history = useHistory();
  const { addToast } = useToast();
  const { handleLoading } = useLoading();
  const formRef = useRef(null);

  const schema = Yup.object().shape({
    event: Yup.string().required(
      intl.formatMessage({ id: "error.field_required" })
    ),
    exhibitor: Yup.string().required(
      intl.formatMessage({ id: "error.field_required" })
    ),
    contract: Yup.string().required(
      intl.formatMessage({ id: "error.field_required" })
    ),
  });

  useEffect(()=>{
    api
      .get(`/admin/events`, {
        headers: {
          "Content-Type": "application/json",
          Accept: "*/*",
          Authorization: `Bearer ${token}`,
        },
      })
      .then((eventResponse) => {
        const events = eventResponse.data.output.map((event) => ({
          label: event.eventEditionName,
          value: event.eventEditionCode,
        }));
        let eventsSort = [...events].sort((a, b) =>
          a.label < b.label ? -1 : a.label > b.label ? 1 : 0
        );
        setEvents(eventsSort);
      })
      .catch((err) =>
        addToast({
          type: "error",
          title: intl.formatMessage({
            id: "error",
          }),
          description: intl.formatMessage({
            id: "api.error." + err.response.data.code,
          }),
        })
      )
      .finally(() => handleLoading(false));
  },[token, addToast, handleLoading, intl])

  useEffect(()=>{
    if(!selectedEvent) return

    api
      .patch(
        "/admin/account/by-event-and-role",
        {
          eventEditionCode: selectedEvent,
          roleType: "EXHIBITOR"
        },
        {
          headers: {
            "Content-Type": "application/json",
            Accept: "*/*",
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((response) => {
        setExhibitors(response.data.accountDropdownOutputList)
      })
      .catch((err) => {
        addToast({
          type: "error",
          title: intl.formatMessage({
            id: "error",
          }),
          description: intl.formatMessage({
            id: "api.error." + err.response.data.code,
          }),
        });
      })
      .finally(() => {
        handleLoading(false);
      });
  },[selectedEvent, token, addToast, handleLoading, intl])

  useEffect(() => {
    setPercentage(
      ((completedJourneyItems / totalJourneyItems) * 100).toFixed(2)
    );
  }, [completedJourneyItems, totalJourneyItems]);

  useEffect(()=>{
    if(!selectedEvent) return
    if(!selectedExhibitor) return

    api
      .patch(
        "/admin/opportunity/by-event-and-account",
        {
          eventEditionCode: selectedEvent,
          accountId: selectedExhibitor,
          roleType: "EXHIBITOR"
        },
        {
          headers: {
            "Content-Type": "application/json",
            Accept: "*/*",
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((response) => {
        setContracts(response.data.opportunityDropdownOutputList)
      })
      .catch((err) => {
        addToast({
          type: "error",
          title: intl.formatMessage({
            id: "error",
          }),
          description: intl.formatMessage({
            id: "api.error." + err.response.data.code,
          }),
        });
      })
      .finally(() => {
        handleLoading(false);
      });
  },[selectedEvent, selectedExhibitor, token, addToast, handleLoading, intl])

  const validationBlur = (name) => {
    let errors = formRef.current.getErrors()
    validateSingleFieldOnBlur(errors, schema, name, formRef)
  };

  const onChangeEvent = (event) => {
    setSelectedEvent(event?.value)
    formRef.current.setFieldValue("exhibitor")
    setFiltered(false)
  };

  const onChangeExhibitor = (exhibitor) => {
    setSelectedExhibitor(exhibitor?.value)
    formRef.current.setFieldValue("contract")
    setFiltered(false)
  }

  const onChangeContract = (contract) => {
    setSelectedContract(contract?.value)
    setFiltered(false)
  }

  const clearFields = () =>{
    formRef.current.setFieldValue("event")
    formRef.current.setFieldValue("exhibitor")
    formRef.current.setFieldValue("contract")

    setFiltered(false)
  }

  const handleSubmit =  useCallback(
    async (data) => {
      try {
        formRef.current?.setErrors({});

        setLoading(true);
        if (loading) {
          return;
        }

        await schema.validate(data, { abortEarly: false });
        api
          .patch(
            "/route-log",
            {
              eventEditionCode: selectedEvent,
              accountIdNumber: selectedExhibitor,
              opportunityIdNumber: selectedContract
            },
            {
              headers: {
                "Content-Type": "application/json",
                Accept: "*/*",
                Authorization: `Bearer ${token}`,
              },
            }
          )
          .then((response) => {
            setFiltered(true)
            let finalTotal = 0;
            setLoading(false);

            response.data.routeLogsList.forEach((value) => {
              if (value.done) {
                finalTotal++;
              }
            });

            setData(response.data.routeLogsList)
            setCompletedJourneyItems(finalTotal);
            setTotalJourneyItems(response.data.routeLogsList.length);
            })
            .catch((err) => {
              addToast({
                type: "error",
                title: intl.formatMessage({
                  id: "error",
                }),
                description: intl.formatMessage({
                  id: "api.error." + err.response.data.code,
                }),
              });
            })
            .finally(() => {
              handleLoading(false);})
    } catch (err) {
        setLoading(false);
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);
          formRef.current?.setErrors(errors);
          return;
        }
      }
    }, [addToast, history, intl, loading, schema, token]);
  

  const columns = useMemo(
    () => [
      {
        name: intl.formatMessage({ id: "task" }),
        cell: (row) => (
          <>
            {row.done ? (
              <FaCheckSquare size={20} color="#002244" />
            ) : (
              <BsSquare size={20} color="#002244" />
            )}
            <p style={{ marginLeft: "8px" }}>
              {row.name && <FormattedMessage id={row.name} />}
            </p>
          </>
        ),
        minWidth: "300px",
      },
      {
        name: "E-mail",
        selector: (row) => row.email,
        minWidth: "300px",
      },
      {
        name: intl.formatMessage({ id: "action_date" }),
        selector: (row) => row.lastDate,
        format: (row) => {
          return (
            row.lastDate && format(new Date(row.lastDate), "dd/MM/yyyy HH:mm")
          );
        },
        minWidth: "150px",
      },
    ],
    [intl]
  );


  return (
    <AnimatedMainContainer
      breadcrumb={intl.formatMessage({ id: "account_journey" })}
    >
      <Container>
        <Form
          ref={formRef}
          onSubmit={handleSubmit}
        >
          <TitleContainer>
            <Title>
              {intl.formatMessage({ id: "select_a_contract" })}
            </Title>
            <GoBackContainer>
              <LinkButton onClick={() => history.push("/dashboard")}>
                <Badge
                  color="#002244"
                  text={intl.formatMessage({ id: "goback" })}
                  fontSize="12px"
                />
              </LinkButton>
            </GoBackContainer>
          </TitleContainer>
          <FieldsContainer>
            <Horizontal>
              <SelectWithLabel
                name="event"
                validationBlur={validationBlur}
                label={intl.formatMessage({ id: "event_edition" })}
                placeholder={intl.formatMessage({ id: "event_edition" })}
                labelOrientation="vertical"
                options={events}
                onChange={onChangeEvent}
                required
              />
              <SelectWithLabel
                name="exhibitor"
                validationBlur={validationBlur}
                label={intl.formatMessage({ id: "exhibitor" })}
                placeholder={intl.formatMessage({ id: "exhibitor" })}
                labelOrientation="vertical"
                options={exhibitors}
                onChange={onChangeExhibitor}
                disabled={!selectedEvent}
                required
              />
              <SelectWithLabel
                name="contract"
                validationBlur={validationBlur}
                label={intl.formatMessage({ id: "contract" })}
                placeholder={intl.formatMessage({ id: "contract" })}
                labelOrientation="vertical"
                options={contracts}
                onChange={onChangeContract}
                disabled={!selectedExhibitor}
                required
              />
            </Horizontal>
            <Horizontal>
                <FilterActionsContainer>
                  <SubmitContainer>
                    <FormButton
                      color="#C9C9C9"
                      type="button"
                      onClick={clearFields}
                    >
                      <FormattedMessage id="clear_fields" />
                    </FormButton>
                  </SubmitContainer>
                  <SubmitContainer>
                    <FormButton type="submit" loading={loading}>
                      <FormattedMessage id="search" />
                    </FormButton>
                  </SubmitContainer>
                </FilterActionsContainer>
              </Horizontal>
          </FieldsContainer>
          {filtered && 
          <Session>
            <FieldsContainer>
              <CentralizedContainer>
                <ProgressBarContainer>
                  <ProgressBarTitle>
                    <FormattedMessage id="user_completed_percent" />:
                  </ProgressBarTitle>

                  <CircularProgressbar
                    value={percentage}
                    text={`${!loading ? percentage : 0}%`}
                    styles={{
                      root: {},
                      trail: {
                        stroke: "#d6d6d6",
                        strokeLinecap: "butt",
                        transform: "rotate(0.25turn)",
                        transformOrigin: "center center",
                      },
                      text: {
                        fill: "#002244",
                        fontSize: "16px",
                      },
                      background: {
                        fill: "#002244",
                      },
                      path: {
                        stroke: `#002244`,
                      },
                    }}
                  />
                </ProgressBarContainer>
              </CentralizedContainer>
              <Table
                data={data}
                columns={columns}
                progressPending={loading}
                hasPagination={false}
              />
            </FieldsContainer>
          </Session>
          }
        </Form>
      </Container>
    </AnimatedMainContainer>
  )
}

export default AccountJourney