import { ModalContainer } from "./styles";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
  },
  overlay: {
    zIndex: 999999,
    backgroundColor: "rgba(201, 201, 201, 0.8)",
  },
};

function ConfirmationModal({
  modalIsOpen,
  setIsOpen,
  children,
  modalWidth,
  backgroundColor= ' #fff',
  overflowX,
  closeCallback,
  dontCloseByClick,
  border,
  borderRadius,
  backgroundImage
}) {
  function closeModal() {
    if (!dontCloseByClick) {
      if (closeCallback) {
        closeCallback();
      }
      setIsOpen(false);
    }
  }

  return (
    <ModalContainer
      isOpen={modalIsOpen}
      onRequestClose={closeModal}
      style={customStyles}
      contentLabel="Confirmação"
      className="Modal"
      overflowX={overflowX}
      modalWidth={modalWidth}
      backgroundColor={backgroundColor}
      border={border}
      borderRadius={borderRadius}
      backgroundImage={backgroundImage}
    >
      {children}
    </ModalContainer>
  );
}

export default ConfirmationModal;
