import React from "react";

const Trash = (props) => {
  const { size = "16", color = "#FFF" } = props;

  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_59_10067)">
        <path
          d="M13.501 3.5H2.50098"
          stroke={color}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M6.50098 6.5V10.5"
          stroke={color}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M9.50098 6.5V10.5"
          stroke={color}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M12.501 3.5V13C12.501 13.1326 12.4483 13.2598 12.3545 13.3536C12.2608 13.4473 12.1336 13.5 12.001 13.5H4.00098C3.86837 13.5 3.74119 13.4473 3.64742 13.3536C3.55366 13.2598 3.50098 13.1326 3.50098 13V3.5"
          stroke={color}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M10.501 3.5V2.5C10.501 2.23478 10.3956 1.98043 10.2081 1.79289C10.0205 1.60536 9.76619 1.5 9.50098 1.5H6.50098C6.23576 1.5 5.98141 1.60536 5.79387 1.79289C5.60633 1.98043 5.50098 2.23478 5.50098 2.5V3.5"
          stroke={color}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_59_10067">
          <rect
            width={size}
            height={size}
            fill={color}
            transform="translate(0.000976562)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default Trash;
