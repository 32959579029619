import styled, { css } from "styled-components";

export const Container = styled.div`
  background: transparent;
  border-radius: 5px;
  border: 1px solid #dadada;
  padding: 8px 16px;
  color: #002244;
  display: flex;
  align-items: center;
  

  .editor-input{
    white-space: pre-line;
  }

  & + div {
    margin-top: 24px;
  }

  ${(props) =>
    props.disabled &&
    css`
      opacity: 0.7;
      background-color: #f2f2f2;
    `}

  ${(props) =>
    props.isErrored &&
    css`
      border-color: #c53030;
    `}

  ${(props) =>
    props.isFocused &&
    css`
      color: #5ddb98;
      border-color: #5ddb98;
    `}
  
  ${(props) =>
    props.isFilled &&
    css`
      color: #5ddb98;
    `}

  textarea {
    display: none;
    background: transparent;
    font-size: 14px;
    border: 0;
    flex: 1;
    color: #002244;
    padding: 8px;
    resize: none;
    &::placeholder {
      color: #002244;
    }
    &:focus {
      border: 0;
      outline: none;
    }
    height: 150px;
    max-height: 150px;
    min-height: 150px;
  }
  svg {
    margin-right: 16px;
  }

  .no-display{
    display: none;
  }
`;

export const Error = styled.div`
  margin: 5px 0px !important;
  display: flex;
  font-size: 10px;
  align-items: center;
  justify-content: flex-start;
  color: #e00000;
  text-align: left;
  svg {
    margin-right: 4px;
    /* margin-left: 16px; */
    min-width: 32px;
  }
  span {
    background: #c53030;
    color: #fff;
    &::before {
      border-color: #c53030 transparent;
    }
  }
`;

export const RootContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Label = styled.span`
  margin-bottom: 8px;
  font-size: 12px;
  font-weight: 600;
`;

export const AsteriskText = styled.span`
  color: #c53030;
`;
