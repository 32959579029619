import {
  Container,
  Navigation,
  UserContainer,
  NotificationContainer,
  NotificationLabel,
  LanguageContainer,
  SelectedLanguageContainer,
  SelectedLanguage,
  LanguageDropdownContainer,
  NotificationDropdownContainer,
  Flag,
  FlagContainer,
  FlagText,
  BellContainer,
  NotificationTitle,
  NotificationDescription,
  NotificationItemContainer,
  HideSidebarContainer,
  AccountContainer,
  ExhibitorNProviderContainer,
  InfoText,
  ContactContainer,
} from "./styles";

import { BsChevronRight, BsChevronLeft } from "react-icons/bs";
import { FaWhatsapp } from "react-icons/fa";
import {
  setNotificationAsRead,
  setNotifications,
} from "../../redux/modules/notification/actions";

import { MdNotifications, MdPhoneInTalk } from "react-icons/md";
import brazilFlag from "../../assets/images/brazil-flag.png";
import ukFlag from "../../assets/images/uk-flag.png";
import { useDispatch, useSelector } from "react-redux";
import { useCallback, useEffect, useState } from "react";
import { changeLanguagePreference } from "../../redux/modules/language/actions";
import { useSidebar } from "../../hooks/sidebar";
import Modal from "../Modal";
import { useIntl } from "react-intl";
import ReactSelect from "react-select";
import { useLoading } from "../../hooks/loading";
import { useToast } from "../../hooks/toast";
import { authSuccess } from "../../redux/modules/auth/actions";
import api from "../../service";
import { useHistory } from "react-router-dom";
import { changeApplicationLanguage } from "../../redux/modules/language/actions";

const languages = [
  { code: "pt", flag: brazilFlag, text: "Português" },
  { code: "en", flag: ukFlag, text: "English" },
];

function Topbar() {
  const dispatch = useDispatch();
  const { addToast } = useToast();
  const { code } = useSelector((state) => state.language);
  const {
    user,
    token,
    serviceProviderLoggingAsAnExhibitor,
    adminToken,
    keepSignedIn,
  } = useSelector((state) => state.auth);
  const { isAccountLoading, handleLoading, handleAccountLoading } =
    useLoading();
  const [loading, setLoading] = useState(false);
  const history = useHistory();
  const [key, setKey] = useState();
  const [accounts, setAccounts] = useState([]);
  const isServiceProvider = useCallback(
    () =>
      !adminToken &&
      (serviceProviderLoggingAsAnExhibitor ||
        user?.role?.type === "SERVICE_PROVIDER"),
    [adminToken, serviceProviderLoggingAsAnExhibitor, user?.role?.type]
  );
  const isExhibitor = useCallback(
    () => !adminToken && user?.role?.type === "EXHIBITOR",
    [adminToken, user?.role?.type]
  );
  const intl = useIntl();
  const { notifications, unread } = useSelector((state) => state.notification);
  const [languageVisible, setLanguageVisible] = useState(false);
  const [activeFlag, setActiveFlag] = useState(ukFlag);
  const [notificationVisible, setNotificationVisible] = useState(false);
  const { sidebarIsVisible, toogleSidebar } = useSidebar();
  const [timerDisabled, setTimerDisabled] = useState(false);

  const [contactInformation, setContactInformation] = useState({
    whatsapp: "",
    telephone: "",
  });

  const [isNotificationModalOpen, setNotificationModalOpen] = useState(false);
  const [selectedNotification, setSelectedNotification] = useState();
  const [accountData, setAccountData] = useState();

  const handleLanguageDropdown = () => {
    if (languageVisible) {
      setLanguageVisible(false);
    } else {
      setLanguageVisible(true);
      setNotificationVisible(false);
    }
  };

  const handleNotificationDropdown = () => {
    if (notificationVisible) {
      setNotificationVisible(false);
    } else {
      setNotificationVisible(true);
      setLanguageVisible(false);
    }
  };

  const handleLanguageChange = (language) => {
    dispatch(
      changeLanguagePreference({
        code: language,
        token: token,
      })
    );
  };

  const formatNotification = (text, type) => {
    const sanitizedType = type.toUpperCase().replaceAll(" ", "_");
    let message = "";

    switch (sanitizedType) {
      case "ADMIN_VIP_DEADLINE":
        message = text.replace(
          "[CLICK_HERE_LINK]",
          `<a href="/admin/guests-report" style="text-decoration: none; color: #002244;">${intl.formatMessage(
            {
              id: "click_here",
            }
          )}</a>`
        );
        break;

      case "ADMIN_CREDENTIALS_ASSOCIATION":
        message = text.replace(
          "[CLICK_HERE_LINK]",
          `<a href="/admin/exhibitors-didnt-create-credential-report" style="text-decoration: none; color: #002244;">${intl.formatMessage(
            { id: "click_here" }
          )}</a>`
        );
        break;

      case "ADMIN_ADVANCE_PURCHASE":
        message = text.replace(
          "[CLICK_HERE_LINK]",
          `<a href="/admin/digital-products-report" style="text-decoration: none; color: #002244;">${intl.formatMessage(
            {
              id: "click_here",
            }
          )}</a>`
        );
        break;

      default:
        message = text;
        break;
    }

    return message;
  };

  const handleOpenNotification = (id, text, read, notificationType) => {
    handleNotificationDropdown();

    if (!read) {
      dispatch(setNotificationAsRead({ id, token }));
    }

    const message = formatNotification(text, notificationType);

    let newNotifications = [];

    notifications.forEach((value) => {
      if (id === value.id) {
        newNotifications.push({ ...value, read: true });
      } else {
        newNotifications.push(value);
      }
    });

    dispatch(setNotifications({ notifications: newNotifications, unread }));

    setSelectedNotification(message);
    setNotificationModalOpen(true);
  };

  useEffect(() => {
    const language = languages.find((language) => language.code === code);
    if (language) {
      setActiveFlag(language.flag);
    }
  }, [code]);

  useEffect(() => {
    if (isServiceProvider() || isExhibitor()) {
      api
        .get("/system/contact-information", {
          headers: {
            "Content-Type": "application/json",
            Accept: "*/*",
            Authorization: `Bearer ${token}`,
          },
        })
        .then((result) => {
          const contactData = result.data.contactOutput;
          setContactInformation({
            whatsapp: contactData.whatsapp,
            telephone: contactData.telephone,
          });
        })
        .catch((err) => {
          addToast({
            type: "error",
            title: intl.formatMessage({
              id: "error",
            }),
            description: intl.formatMessage({
              id: "api.error." + err.response.data.code,
            }),
          });
        });
    }
  }, [token, addToast, intl, isExhibitor, isServiceProvider]);

  useEffect(() => {
    if (isServiceProvider()) {
      setLoading(true);
      if (loading) {
        return;
      }

      handleLoading(true);

      api
        .get("/provider-association/service-provider-accounts", {
          headers: {
            "Content-Type": "application/json",
            Accept: "*/*",
            Authorization: `Bearer ${token}`,
          },
        })
        .then((result) => {
          setKey(new Date());
          setAccountData();
          const mappedAccounts = [
            { value: 0, label: intl.formatMessage({ id: "my_profile" }) },
            ...result.data.serviceProviderAccountsAssociated.map((account) => {
              return {
                value: account.idNumber,
                label: account.accountName,
              };
            }),
          ];
          setAccounts(mappedAccounts);
        })
        .catch((err) =>
          addToast({
            type: "error",
            title: intl.formatMessage({
              id: "error",
            }),
            description: intl.formatMessage({
              id: "api.error." + err.response.data.code,
            }),
          })
        )
        .finally(() => {
          setLoading(false);
          handleLoading(false);
        });
    } else if (isExhibitor()) {
      setLoading(true);
      if (loading) {
        return;
      }

      handleLoading(true);

      api
        .get("/exhibitor/account/associated-accounts", {
          headers: {
            "Content-Type": "application/json",
            Accept: "*/*",
            Authorization: `Bearer ${token}`,
          },
        })
        .then((result) => {
          setKey(new Date());
          setAccountData();
          const mappedAccounts = result.data.associatedAccountOutputs.map(
            ({ accountId, accountName }) => {
              return {
                value: accountId,
                label: accountName,
              };
            }
          );
          setAccounts(mappedAccounts);
        })
        .catch((err) =>
          addToast({
            type: "error",
            title: intl.formatMessage({
              id: "error",
            }),
            description: intl.formatMessage({
              id: "api.error." + err.response.data.code,
            }),
          })
        )
        .finally(() => {
          setLoading(false);
          handleLoading(false);
        });
    }
  }, [addToast, intl, token, handleLoading, isServiceProvider, isExhibitor]);

  const handleChangeAccount = useCallback(
    (account) => {
      if (
        (!account && account !== 0) ||
        (!isServiceProvider() && !isExhibitor()) ||
        isAccountLoading
      )
        return;

      setLoading(true);
      if (loading) {
        return;
      }

      handleAccountLoading(true);

      api
        .post(
          isServiceProvider()
            ? "/auth/change-service-provider-sign-in"
            : isExhibitor()
            ? "/auth/change-exhibitor-sign-in"
            : null,
          { accountIdNumber: account },
          {
            headers: {
              "Content-Type": "application/json",
              Accept: "*/*",
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((result) => setAccountData(result.data))
        .catch((err) => {
          if (err.response.data.code === -2025) {
            addToast({
              type: "info",
              title: intl.formatMessage({
                id: "info",
              }),
              description: intl.formatMessage({
                id: "api.error." + err.response.data.code,
              }),
            });
          } else {
            addToast({
              type: "error",
              title: intl.formatMessage({
                id: "error",
              }),
              description: intl.formatMessage({
                id: "api.error." + err.response.data.code,
              }),
            });
          }
        })
        .finally(() => {
          setLoading(false);
          handleAccountLoading(false);
        });
    },
    [
      isServiceProvider,
      isExhibitor,
      isAccountLoading,
      loading,
      handleAccountLoading,
      token,
      addToast,
      intl,
    ]
  );

  useEffect(() => {
    if (isAccountLoading && accountData) {
      dispatch(
        authSuccess({
          user: accountData.user,
          token: accountData.token,
          customerCenterToken: accountData.customerCenterToken,
          accountCreated: accountData.accountCreated,
          keepSignedIn,
          serviceProviderLoggingAsAnExhibitor:
            accountData.serviceProviderLoggingAsAnExhibitor,
        })
      );
      handleAccountLoading(false);
      if (isServiceProvider() || isExhibitor()) history.push("/checklist");
      else history.push("/dashboard");
    }
  }, [
    accountData,
    dispatch,
    handleAccountLoading,
    history,
    isAccountLoading,
    keepSignedIn,
    isServiceProvider,
    isExhibitor,
  ]);

  useEffect(() => {
    if (isAccountLoading) {
      setTimerDisabled(true);
    } else if (timerDisabled) {
      setTimeout(() => setTimerDisabled(false), 5000);
    }
  }, [isAccountLoading, timerDisabled]);

  return (
    <Container>
      <Navigation sidebarIsVisible={sidebarIsVisible}>
        <HideSidebarContainer>
          {sidebarIsVisible ? (
            <BsChevronLeft size={19} onClick={() => toogleSidebar()} />
          ) : (
            <BsChevronRight size={19} onClick={() => toogleSidebar()} />
          )}
        </HideSidebarContainer>
        <UserContainer>
          {(isServiceProvider() || isExhibitor()) && (
            <ExhibitorNProviderContainer>
              <ContactContainer>
                <MdPhoneInTalk size={22} color="#002244" />
                <InfoText>{contactInformation.telephone}</InfoText>
                <a
                  href={contactInformation.whatsapp}
                  rel="noreferrer"
                  target="_blank"
                >
                  <FaWhatsapp size={22} color="#002244" />
                </a>
              </ContactContainer>
              <AccountContainer>
                <ReactSelect
                  key={key}
                  name="account"
                  placeholder={
                    user?.name.length > 17
                      ? `${user?.name.substring(0, 16)}...`
                      : user?.name ||
                        intl.formatMessage({ id: "select_your_profile" })
                  }
                  options={accounts}
                  onChange={(e) => handleChangeAccount(e?.value)}
                  $isDisabled={loading || isAccountLoading || timerDisabled}
                  styles={{
                    placeholder: (base) => ({
                      ...base,
                      fontSize: "13.3333px",
                      color: "#002244",
                      fontWeight: 400,
                      paddingLeft: "16px",
                    }),
                    singleValue: (base) => ({
                      ...base,
                      fontSize: "13.3333px",
                      color: "#002244",
                      fontWeight: 400,
                      paddingLeft: "16px",
                    }),
                    valueContainer: (base) => ({
                      ...base,
                      paddingTop: "8px",
                      paddingBottom: "8px",
                    }),
                    menuList: (base) => ({
                      ...base,
                      zIndex: "9999999999999",
                    }),
                    menuPortal: (base) => ({
                      ...base,
                      zIndex: "9999999999999",
                    }),
                    control: (base) => ({
                      ...base,
                      borderColor: "#dadada",
                      ":hover": {
                        borderColor: "#dadada",
                        ":hover": {
                          borderColor: "#dadada",
                        },
                      },
                    }),
                  }}
                />
              </AccountContainer>
            </ExhibitorNProviderContainer>
          )}
          <NotificationContainer>
            <BellContainer onClick={() => handleNotificationDropdown()}>
              <MdNotifications color="#002244" size={24} />
              {unread > 0 && <NotificationLabel>{unread}</NotificationLabel>}
            </BellContainer>

            {notificationVisible && (
              <NotificationDropdownContainer
                sidebarIsVisible={sidebarIsVisible}
              >
                {notifications?.map((notification, index) => {
                  const { id, title, body, read, notificationType } =
                    notification;

                  return (
                    <NotificationItemContainer
                      key={index}
                      read={read}
                      onClick={() =>
                        handleOpenNotification(id, body, read, notificationType)
                      }
                    >
                      <NotificationTitle>{title}</NotificationTitle>
                      <NotificationDescription>{body}</NotificationDescription>
                    </NotificationItemContainer>
                  );
                })}
              </NotificationDropdownContainer>
            )}
          </NotificationContainer>
          <LanguageContainer>
            <SelectedLanguageContainer>
              <SelectedLanguage
                onClick={() => handleLanguageDropdown()}
                src={activeFlag}
                size={24}
              />
            </SelectedLanguageContainer>
            {languageVisible && (
              <LanguageDropdownContainer sidebarIsVisible={sidebarIsVisible}>
                {languages.map((language, index) => (
                  <FlagContainer
                    key={index}
                    onClick={() => handleLanguageChange(language.code)}
                  >
                    <Flag src={language.flag} />
                    <FlagText>{language.text}</FlagText>
                  </FlagContainer>
                ))}
              </LanguageDropdownContainer>
            )}
          </LanguageContainer>
        </UserContainer>
      </Navigation>
      <Modal
        modalIsOpen={isNotificationModalOpen}
        setIsOpen={setNotificationModalOpen}
      >
        <div dangerouslySetInnerHTML={{ __html: selectedNotification }}></div>
      </Modal>
    </Container>
  );
}

export default Topbar;
