import { all, takeLatest, call, put } from "redux-saga/effects";

import {
  setListCredentialsSuccess,
  getTypeCredentialSuccess,
  setQuotaCredentialSuccess,
  setCredentialScheduleSuccess,
  setCredentialScheduleAvailableDates,
  setCredentialScheduleAvailableTimes,
} from "./actions";
import {
  getListCredentialsService,
  getCollaboratorOfContractService,
  getCredentialService,
  handleAssociateCredentialService,
  updateAssociateCredentialService,
  removeAssociateCredentialService,
  getQuotaCredentialService,
  getCredentialScheduleService,
  editCredentialScheduleService,
  postCredentialScheduleService,
  getCredentialScheduleAvailableDatesService,
  getCredentialScheduleAvailableTimesService,
} from "../../../service/credentials.service";

function* getListCredentials({ payload }) {
  try {
    const response = yield call(getListCredentialsService, payload.params);

    if (response.status === 200) {
      yield put(setListCredentialsSuccess(response.data.credentialOutputList));
      payload.callbackPaginationInfo(response.data.paginationInfo);
    }
  } catch (error) {
    payload.callbackError(error.response.data.code);
  }
}

function* getCollaboratorOfContract({ payload }) {
  try {
    const response = yield call(
      getCollaboratorOfContractService,
      payload.params
    );

    if (response.status === 200) {
      payload.callbackSuccess(response.data.output);
    }
  } catch (error) {
    payload.callbackError(error.response.data.code);
  }
}

function* getCredential({ payload }) {
  try {
    const response = yield call(getCredentialService, payload.id);

    if (response.status === 200) {
      payload.callbackSuccess(response.data.credentialOutput);
    }
  } catch (error) {
    payload.callbackError(error.response.data.code);
  }
}

function* handleAssociateCredential({ payload }) {
  try {
    const response = yield call(handleAssociateCredentialService, payload.data);

    if (response.status === 200) {
      getListCredentials(
        {
          opportunity_id: payload.data.opportunityId,
          event_id: payload.data.eventId,
          search: "",
        },
        payload.callbackError
      );

      payload.callbackSuccess();
    }
  } catch (error) {
    payload.callbackError(error.response.data.code);
  }
}

function* updateAssociateCredential({ payload }) {
  try {
    const response = yield call(updateAssociateCredentialService, payload.data);

    if (response.status === 200) {
      getListCredentials(
        {
          opportunity_id: payload.data.opportunityId,
          event_id: payload.data.eventId,
          search: "",
        },
        payload.callbackError
      );

      payload.callbackSuccess();
    }
  } catch (error) {
    payload.callbackError(error.response.data.code);
  }
}

function* deleteAssociateCredential({ payload }) {
  try {
    const response = yield call(removeAssociateCredentialService, payload.id);

    if (response.status === 200) {
      payload.callbackSuccess(response.data.code);
    }
  } catch (error) {
    payload.callbackError(error.response.data.code);
  }
}

function* getQuotaCredential({ payload }) {
  try {
    const response = yield call(getQuotaCredentialService, payload.params);

    if (response.status === 200) {
      yield put(
        setQuotaCredentialSuccess(response.data.credentialSumQuotaOutput)
      );
      yield put(
        getTypeCredentialSuccess(response.data.credentialCategoryOutputList)
      );
    }
  } catch (error) {
    payload.callbackError(error.response.data.code);
  } finally {
  }
}

function* getCredentialSchedule({ payload }) {
  const { event, opportunity } = payload;
  try {
    const response = yield call(
      getCredentialScheduleService,
      event,
      opportunity
    );
    if (response.status === 200) {
      yield put(
        setCredentialScheduleSuccess(response.data.credentialScheduleOutput)
      );
    }
  } catch (error) {
    payload.callbackError(error.response.data.code);
  }
}

function* postCredentialSchedule({ payload }) {
  const { params } = payload;
  try {
    const response = yield call(postCredentialScheduleService, params);
    if (response.status === 200) {
      payload.callbackSuccess(response.data.code);
    }
  } catch (error) {
    payload.callbackError(error.response.data.code);
  }
}

function* editCredentialSchedule({ payload }) {
  const { params, id } = payload;
  try {
    const response = yield call(editCredentialScheduleService, params, id);
    if (response.status === 200) {
      payload.callbackSuccess(response.data.code);
    }
  } catch (error) {
    payload.callbackError(error.response.data.code);
  }
}

function* getCredentialScheduleAvailableDates({ payload }) {
  try {
    const response = yield call(getCredentialScheduleAvailableDatesService, payload.params);

    if (response.status === 200) {
      yield put(
        setCredentialScheduleAvailableDates(response.data.dateTimeSelectOutputList)
      );
    }
  } catch (error) {
    payload.callbackError(error.response.data.code);
  }
}

function* getCredentialScheduleAvailableTimes({ payload }) {
  try {
    const response = yield call(getCredentialScheduleAvailableTimesService, payload.params);

    if (response.status === 200) {
      yield put(
        setCredentialScheduleAvailableTimes(response.data.dateTimeSelectOutputList)
      );
    }
  } catch (error) {
    payload.callbackError(error.response.data.code);
  }
}

export default all([
  takeLatest("GET_LIST_CREDENTIALS", getListCredentials),
  takeLatest("GET_COLLABORATOR_OF_CONTRACT", getCollaboratorOfContract),
  takeLatest("GET_CREDENTIAL", getCredential),
  takeLatest("HANDLE_ASSOCIATE_CREDENTIAL", handleAssociateCredential),
  takeLatest("UPDATE_ASSOCIATE_CREDENTIAL", updateAssociateCredential),
  takeLatest("DELETE_ASSOCIATE_CREDENTIAL", deleteAssociateCredential),
  takeLatest("GET_QUOTA_CREDENTIAL", getQuotaCredential),
  takeLatest("GET_CREDENTIAL_SCHEDULE", getCredentialSchedule),
  takeLatest("POST_CREDENTIAL_SCHEDULE", postCredentialSchedule),
  takeLatest("EDIT_CREDENTIAL_SCHEDULE", editCredentialSchedule),
  takeLatest("GET_CREDENTIAL_SCHEDULE_AVAILABLE_DATES", getCredentialScheduleAvailableDates),
  takeLatest("GET_CREDENTIAL_SCHEDULE_AVAILABLE_TIMES", getCredentialScheduleAvailableTimes),
]);
