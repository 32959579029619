import React, { useCallback, useRef, useState } from "react";
import { useHistory, useParams } from "react-router-dom";

import { Form } from "@unform/web";
import * as Yup from "yup";
import getValidationErrors from "../../../utils/getValidationErrors";

import { useSelector } from "react-redux";

import { FormattedMessage, useIntl } from "react-intl";

import {
  Container,
  TitleContainer,
  Title,
  Session,
  FieldsContainer,
  ActionContainer,
  BadgeContainer,
  InfoContainer,
  NumericalInfoContainer,
  NumericalInfoText,
  DateInfoContainer,
  DateInfoText,
} from "./styles";

import { useToast } from "../../../hooks/toast";
import { validateSingleFieldOnBlur } from "../../../utils/formValidations";
import api from "../../../service";
import { convertToDateOnly } from "../../../utils/date";
import AnimatedMainContainer from "../../../components/AnimatedMainContainer";
import InputWithHorizontalLabel from "../../../components/InputWithHorizontalLabel";
import SelectWithLabel from "../../../components/SelectWithLabel";
import Badge from "../../../components/Badge";
import Toggle from "../../../components/Toggle";
import { useEffect } from "react";
import { useLoading } from "../../../hooks/loading";
import axios from "axios";
import
  TelephoneInputWithLabel,
  {
    getFullTelephoneNumber,
    getCountryFromTelephoneNumber,
  }
from "../../../components/TelephoneInputWithLabel";

const EditGuest = () => {
  const formRef = useRef(null);
  const intl = useIntl();
  const { addToast } = useToast();
  const { token } = useSelector((state) => state.auth);
  const { id } = useParams();

  const [data, setData] = useState();
  const [events, setEvents] = useState();
  const [eventItem, setEventItem] = useState();
  const [exhibitors, setExhibitors] = useState();
  const [exhibitorItem, setExhibitorItem] = useState();

  const [invitationInformation, setInvitationInformation] = useState();
  const [selectedEvent, setSelectedEvent] = useState();
  const [selectedExhibitor, setSelectedExhibitor] = useState();
  const { handleLoading } = useLoading();
  const [loading, setLoading] = useState(false);

  const [formKey, setFormKey] = useState();

  const history = useHistory();

  const schema = Yup.object().shape({
    event: Yup.string().required(
      intl.formatMessage({ id: "error.field_required" })
    ),
    exhibitor: Yup.string().required(
      intl.formatMessage({ id: "error.field_required" })
    ),
    name: Yup.string().required(
      intl.formatMessage({ id: "error.field_required" })
    ),
    lastName: Yup.string().required(
      intl.formatMessage({ id: "error.field_required" })
    ),
    assignment: Yup.string().required(
      intl.formatMessage({ id: "error.field_required" })
    ),
    industry: Yup.string().required(
      intl.formatMessage({ id: "error.field_required" })
    ),
    company: Yup.string().required(
      intl.formatMessage({ id: "error.field_required" })
    ),
    email: Yup.string()
      .email(intl.formatMessage({ id: "error.valid_email" }))
      .required(intl.formatMessage({ id: "error.field_required" })),
    telephone: Yup.string()
      .max(30, intl.formatMessage({ id: "error.max_length" }, { length: 30 }))
      .required(intl.formatMessage({ id: "error.field_required" })),
  });

  useEffect(() => {
    handleLoading(true);

    axios
      .all([
        api.get(`/admin/manage-guests/${id}`, {
          headers: {
            "Content-Type": "application/json",
            Accept: "*/*",
            Authorization: `Bearer ${token}`,
          },
        }),
        api.get(`admin/events`, {
          headers: {
            "Content-Type": "application/json",
            Accept: "*/*",
            Authorization: `Bearer ${token}`,
          },
        }),
      ])
      .then(
        axios.spread((manageGuestsResponse, eventsResponse) => {
          const output = manageGuestsResponse.data.manageGuestAdminFormOutput;
          setData(output);

          const events = eventsResponse.data.output.map((event) => ({
            label: event.eventEditionName,
            value: event.eventEditionCode,
          }));
          let eventsSort = [...events].sort((a, b) =>
            a.label < b.label ? -1 : a.label > b.label ? 1 : 0
          );
          setEvents(eventsSort);
        })
      )
      .catch((err) =>
        addToast({
          type: "error",
          title: intl.formatMessage({
            id: "error",
          }),
          description: intl.formatMessage({
            id: "api.error." + err.response.data.code,
          }),
        })
      )
      .finally(() => handleLoading(false));
  }, [addToast, id, intl, token, handleLoading]);

  useEffect(() => {
    if (data && events) {
      const { eventEditionCode } = data;
      const item = events.find((event) => event.value === eventEditionCode);
      setEventItem(item);
      setSelectedEvent(item.value);
      setFormKey(`event-${new Date().getTime()}`);
    }
  }, [data, events]);

  useEffect(() => {
    if (data && exhibitors) {
      const { exhibitorId } = data;
      const item = exhibitors.find(
        (exhibitor) => exhibitor.value === exhibitorId
      );
      setExhibitorItem(item);
      setSelectedExhibitor(item.value);
      setFormKey(`exhibitor-${new Date().getTime()}`);
    }
  }, [data, exhibitors]);

  useEffect(() => {
    formRef.current?.setFieldValue("exhibitor", "");
    if (selectedEvent) {
      handleLoading(true);
      api
        .get(`/users/exhibitor/event/${selectedEvent}`, {
          headers: {
            "Content-Type": "application/json",
            Accept: "*/*",
            Authorization: `Bearer ${token}`,
          },
        })
        .then((result) => {
          const exhibitors = result.data.users.map((user) => ({
            label: user.commercialName,
            value: user.id,
          }));
          setExhibitors(exhibitors);
        })
        .catch((err) => {
          addToast({
            type: "error",
            title: intl.formatMessage({
              id: "error",
            }),
            description: intl.formatMessage({
              id: "api.error." + err.response.data.code,
            }),
          });
        })
        .finally(() => {
          handleLoading(false);
        });
    } else {
      setSelectedExhibitor();
      setExhibitors();
      setInvitationInformation();
    }
  }, [selectedEvent, addToast, intl, token, handleLoading]);

  useEffect(() => {
    if (selectedEvent && selectedExhibitor) {
      handleLoading(true);
      api
        .patch(
          `admin/manage-guests/invitation-information/${selectedEvent}`,
          {
            exhibitor: selectedExhibitor,
          },
          {
            headers: {
              "Content-Type": "application/json",
              Accept: "*/*",
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((result) => {
          setInvitationInformation(
            result.data.manageGuestAdminInvitationInformationOutput
          );
        })
        .catch((err) => {
          addToast({
            type: "error",
            title: intl.formatMessage({
              id: "error",
            }),
            description: intl.formatMessage({
              id: "api.error." + err.response.data.code,
            }),
          });
        })
        .finally(() => {
          handleLoading(false);
        });
    }
  }, [selectedEvent, addToast, intl, token, selectedExhibitor, handleLoading]);

  const handleSubmit = useCallback(
    async (data) => {
      try {
        formRef.current?.setErrors({});

        setLoading(true);
        if (loading) {
          return;
        }

        await schema.validate(data, { abortEarly: false });

        api
          .put(
            `/admin/manage-guests/${id}`,
            {
              vip: data.vip,
              eventEditionCode: data.event,
              exhibitorId: data.exhibitor,
              name: data.name,
              lastName: data.lastName,
              assignment: data.assignment,
              industry: data.industry,
              company: data.company,
              email: data.email,
              telephone: getFullTelephoneNumber(
                data.telephone, data.telephone_selected_country),
            },
            {
              headers: {
                "Content-Type": "application/json",
                Accept: "*/*",
                Authorization: `Bearer ${token}`,
              },
            }
          )
          .then((result) => {
            setLoading(false);
            addToast({
              type: "success",
              title: intl.formatMessage({
                id: "success",
              }),
              description: intl.formatMessage({
                id: "success.guest_update",
              }),
            });
            history.push("/admin/manage-guests");
          })
          .catch((err) => {
            setLoading(false);
            addToast({
              type: "error",
              title: intl.formatMessage({
                id: "error",
              }),
              description: intl.formatMessage({
                id: "api.error." + err.response.data.code,
              }),
            });
          });
      } catch (err) {
        setLoading(false);
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);

          formRef.current?.setErrors(errors);

          return;
        }
      }
    },
    [addToast, history, id, intl, loading, schema, token]
  );

  const validationBlur = (name) => {
    let errors = formRef.current.getErrors();

    validateSingleFieldOnBlur(errors, schema, name, formRef);
  };

  return (
    <AnimatedMainContainer>
      <Container>
        <Form
          ref={formRef}
          key={formKey}
          initialData={{
            vip: data?.vip,
            event: eventItem,
            exhibitor: exhibitorItem,
            name: data?.name,
            lastName: data?.lastName,
            assignment: data?.assignment,
            industry: data?.industry,
            company: data?.company,
            email: data?.email,
            telephone: data?.telephone,
            telephone_selected_country:
              getCountryFromTelephoneNumber(data?.telephone),
          }}
          onSubmit={handleSubmit}
        >
          <TitleContainer>
            <Title>
              <FormattedMessage id="editing_guest" />
            </Title>
            <ActionContainer>
              <BadgeContainer
                onClick={() => history.push("/admin/manage-guests")}
              >
                <Badge
                  color="#C9C9C9"
                  text={intl.formatMessage({ id: "goback" })}
                  fontSize="12px"
                />
              </BadgeContainer>
              <BadgeContainer onClick={() => formRef.current.submitForm()}>
                <Badge
                  loading={loading}
                  color="#002244"
                  text={intl.formatMessage({ id: "save" })}
                  fontSize="12px"
                />
              </BadgeContainer>
            </ActionContainer>
          </TitleContainer>
          <Session>
            <FieldsContainer>
              <Toggle
                name="vip"
                label={intl.formatMessage({ id: "vip_guest" })}
                required
              />
              <SelectWithLabel
                name="event"
                label={intl.formatMessage({ id: "event_edition" })}
                placeholder={intl.formatMessage({ id: "event_edition" })}
                validationBlur={validationBlur}
                onChange={(option) => setSelectedEvent(option.value)}
                labelOrientation="horizontal"
                labelWidth="150px"
                options={events}
                required
              />
              <SelectWithLabel
                name="exhibitor"
                label={intl.formatMessage({ id: "exhibitor" })}
                placeholder={intl.formatMessage({ id: "exhibitor" })}
                validationBlur={validationBlur}
                onChange={(option) => setSelectedExhibitor(option.value)}
                labelOrientation="horizontal"
                labelWidth="150px"
                options={exhibitors}
                required
              />
              <InputWithHorizontalLabel
                name="name"
                placeholder={intl.formatMessage({
                  id: "name",
                })}
                label={intl.formatMessage({
                  id: "name",
                })}
                validationBlur={validationBlur}
                labelWidth="150px"
                required
              />
              <InputWithHorizontalLabel
                name="lastName"
                placeholder={intl.formatMessage({
                  id: "last_name",
                })}
                label={intl.formatMessage({
                  id: "last_name",
                })}
                validationBlur={validationBlur}
                labelWidth="150px"
                required
              />
              <InputWithHorizontalLabel
                name="assignment"
                placeholder={intl.formatMessage({
                  id: "assignment",
                })}
                label={intl.formatMessage({
                  id: "assignment",
                })}
                validationBlur={validationBlur}
                labelWidth="150px"
                required
              />
              <InputWithHorizontalLabel
                name="industry"
                placeholder={intl.formatMessage({
                  id: "industry",
                })}
                label={intl.formatMessage({
                  id: "industry",
                })}
                validationBlur={validationBlur}
                labelWidth="150px"
                required
              />
              <InputWithHorizontalLabel
                name="company"
                placeholder={intl.formatMessage({
                  id: "company",
                })}
                label={intl.formatMessage({
                  id: "company",
                })}
                validationBlur={validationBlur}
                labelWidth="150px"
                required
              />
              <InputWithHorizontalLabel
                name="email"
                placeholder="E-mail"
                label="E-mail"
                validationBlur={validationBlur}
                labelWidth="150px"
                required
              />
              <TelephoneInputWithLabel
                name="telephone"
                placeholder={intl.formatMessage({
                  id: "telephone",
                })}
                label={intl.formatMessage({
                  id: "telephone",
                })}
                validationBlur={validationBlur}
                labelOrientation="horizontal"
                labelWidth="150px"
                required
              />
            </FieldsContainer>
          </Session>
        </Form>
        {invitationInformation && selectedEvent && selectedExhibitor && (
          <InfoContainer>
            <NumericalInfoContainer>
              <NumericalInfoText>
                {intl.formatMessage({
                  id: "vip_guests_used",
                })}
                : {invitationInformation.used}
              </NumericalInfoText>
              <NumericalInfoText>
                {intl.formatMessage({
                  id: "vip_guests_remaining",
                })}
                : {invitationInformation.remaining}
              </NumericalInfoText>
            </NumericalInfoContainer>
            <DateInfoContainer>
              <DateInfoText>
                {intl.formatMessage({
                  id: "deadline_for_registration",
                })}
                :{" "}
                {invitationInformation.deadline
                  ? convertToDateOnly(
                      new Date(invitationInformation?.deadline),
                      "dd/MM/yyyy"
                    )
                  : "-"}
              </DateInfoText>
            </DateInfoContainer>
          </InfoContainer>
        )}
      </Container>
    </AnimatedMainContainer>
  );
};

export default EditGuest;
