import api from "./index";
import axios from "axios";

export const getSystemService = async () =>
  await api.get("system/contact-information");

export const getListCountriesService = async () =>
  await axios.get(
    "https://raw.githubusercontent.com/apilayer/restcountries/master/src/main/resources/countriesV2.json"
  );

export const getCepBrasilApiService = async (cep) =>
  await axios.get(`https://brasilapi.com.br/api/cep/v1/${cep}`);
