import React from "react";

import CustomModal from "../../../components/CustomModal";

import Button from "../../../newUXComponents/Button";

import {
  Container,
  ContainerButton,
  Description,
  Divider,
  Header,
} from "./styles";

const MessageDeleteAssociateCompany = ({
  open,
  handleClose,
  id,
  title = "",
  message,
}) => {
  return (
    <CustomModal id={id} isModalVisible={open} handleClose={() => {}}>
      <Container>
        {title && (
          <>
            <Header>
              <span>{title}</span>
            </Header>
            <Divider />
          </>
        )}
        <Description>{message}</Description>

        <ContainerButton>
          <Button type="primary" onClick={() => handleClose()} label="Ok" />
        </ContainerButton>
      </Container>
    </CustomModal>
  );
};

export default MessageDeleteAssociateCompany;
