export function addInitialSettingsSuccess(initialSettingsState) {
  return {
    type: "ADD_SETTINGS",
    payload: {
      event: initialSettingsState.event,
      contract: initialSettingsState.contract
    },
  };
}

export function clearInitialSettingsState() {
  return {
    type: "CLEAR_SETTINGS",
  };
}