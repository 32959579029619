import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  height: 94vh;
  background: #fcfcfc;
  padding: 25px 75px 50px 70px;
  overflow-y: auto;
  
  ::-webkit-scrollbar {
    width: 4px;
    background-color: #fcfcfc;
  }

  ::-webkit-scrollbar-thumb {
    background: #11a7d9;
    -webkit-border-radius: 1ex;
    -webkit-box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.75);
  }

  ::-webkit-scrollbar-corner {
    background: #11a7d9;
  }
`;

export const Title = styled.h1`
  color: #11a7d9;
  font-family: "Inter";
  font-size: 30px;
  font-style: normal;
  font-weight: 700;
  line-height: 100%;
  border-bottom: 1px solid #d4d4d4;
  padding-bottom: 15px;
`;

export const Hr = styled.hr`
  border-radius: 30px;
  background: #11a7d9;
  width: 119px;
  height: 2px;
  margin-bottom: 24px;
  margin-top: -19.5px;
`;

export const Content = styled.div`
  gap: 24px;
  display: flex;
  flex-direction: column;
`;

export const Banner = styled.div`
  height: 200px;
  width: 100%;
  display: flex;
  flex-shrink: 0;

  img {
    border-radius: 10px;
  }
`;

export const BoxContent = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  align-content: flex-start;
`;

export const Box = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  min-height: 184px;
  max-height: 233px;
  padding: 24px;
  gap: 5px;
  flex-shrink: 0;
  border-radius: 12px;
  background: #fff;
  box-shadow: 0px 4px 7px 0px rgba(0, 0, 0, 0.07);

  :not(:last-child) {
    margin-right: 20px;
  }
`;

export const TitleBox = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  min-width: 447px;
  width: 100%;
  gap: 7px;
  font-family: "Open Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  color: #282828;
  padding-bottom: 7px;
  border-bottom: 1px solid #2828280d;
  margin-bottom: 10px;

  span:nth-child(3) {
    color: #004fc4;
  }
`;

export const Label = styled.div`
  width: 100%;
  color: ${({ $isBooth = false }) => ($isBooth ? "#444" : "#282828")};
  font-family: "Open Sans";
  font-size: 14px;
  font-style: normal;
  font-weight: ${({ $isBooth = false }) => ($isBooth ? 600 : 400)};
  line-height: ${({ $isBooth = false }) => ($isBooth ? "150%" : "20px")};
  display: flex;
  align-items: center;
  gap: 7px;

  span > ins {
    color: #5b8def;
    margin-left: 10px;
  }
`;

export const InfoContract = styled.div`
  min-height: 184px;
  padding: 24px;
  gap: 5px;
  flex-shrink: 0;
  border-radius: 12px;
  background: #fff;
  box-shadow: 0px 4px 7px 0px rgba(0, 0, 0, 0.07);

  p {
    display: flex;
  }
`;

export const GroupLabel = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

export const SpecialConditions = styled.div`
  width: 510px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  gap: 5px;

  p {
    color: #6c6c6c;
    font-family: "Open Sans";
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
`;

export const Booth = styled.div`
  background: #fff;
  box-shadow: 0px 4px 7px 0px rgba(0, 0, 0, 0.07);
  flex-shrink: 0;
  border-radius: 12px;
  max-height: 385px;
  padding: 24px;
  display: flex;
  flex-direction: column;
  gap: 15px;
`;

export const FormField = styled.div`
  display: flex;
  flex-direction: column;

  span {
    color: #444;
    font-family: "Open Sans";
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 150%;
  }

  div {
    display: flex;
    flex-direction: row;
    margin-bottom: 0px;
  }

  input {
    color: #6c6c6c;
    font-weight: 400;
    line-height: 20px;
    border-radius: 10px !important;
    border: 1px solid #c7c9d9;
    background: #fff;
    padding: 10px 32px 8px 24px;
    width: 100%;
  }

  .ant-select-selector {
    height: 40px !important;
  }

  .ant-select-selection-placeholder {
    line-height: 35px !important;
  }
  .ant-input-group.ant-input-group-compact > * {
    border-radius: 8px !important;
  }

  .ant-select-single .ant-select-selector .ant-select-selection-item {
    line-height: 40px !important;
  }
`;

export const Btn = styled.div`
  display: flex;
  width: 42px;
  height: 40px;
  padding: 10px 13px;
  justify-content: center;
  align-items: center;
  cursor: ${({ $disabled }) => ($disabled ? "not-allowed" : "pointer")};
  border-radius: 8px;
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  margin-left: 10px;
  background: ${({ $disabled }) => {
    if (!$disabled) {
      return "#11a7d9";
    }

    if ($disabled) {
      return "#C7C9D9";
    }
  }};
`;

export const HTMLCanvas = styled.div`
  white-space: pre-line;
  overflow-wrap: break-word;

  ul,
  ol {
    padding-left: 10px;
  }
`;

export const DocumentOpenImage = styled.img`
  margin-left: 8px;
  justify-self: center;
  max-height: 100px;
  @media (max-width: 500px) {
    width: 100%;
  }
`;
