import React, {
  useContext,
  useMemo,
  useState,
  useEffect,
  useCallback,
} from "react";

import { useSelector, useDispatch } from "react-redux";

import {
  getAllAccountsUser,
  getAccountsExhibitor,
  changeAccounts,
} from "../../../redux/modules/account/actions";

import { DialogContext } from "../../../hooks/dialog/context";
import { useLoading } from "../../../hooks/loading";

import { useIntl } from "react-intl";

import { useHistory } from "react-router-dom";

import useNotification from "../../../hooks/newUXNotification";

import SelectUser from "../../SelectUser";
import DropdownFlags from "../../DropdownFlags";

import ModalSettings from "../../../newUXComponents/ModalSettings";
import ConfirmationModal from "../../../components/ConfirmationModal";

import AlterEvent from "../../../newUXAssets/icons/AlterEvent";
import SignOut from "../../../newUXAssets/icons/SignOut";
import LogoutFirstAccessModal from "../../Modal/LogoutFirstAccessModal";

import { Container, ContentLeft, ContainerRight, Icon, Text } from "./styles";

const Header = () => {
  const intl = useIntl();
  const { createDialog } = useContext(DialogContext);
  const dispatch = useDispatch();
  const history = useHistory();
  const notification = useNotification();

  const [modalIsOpen, setModalIsOpen] = useState(false);

  const {
    user,
    adminToken,
    serviceProviderLoggingAsAnExhibitor,
    keepSignedIn,
    accountCreated,
  } = useSelector((state) => state.auth);
  const { event, contract } = useSelector((state) => state.initialSettings);
  const { accountsExhibitor, accountsServiceProvider } = useSelector(
    (state) => state.account
  );

  const { isLoading } = useLoading();

  const handleLogout = () => {
    if (user?.role?.type === "ADMINISTRATOR" || adminToken) {
      window.location.href =
        process.env.REACT_APP_SERVER_URL + "saml/logout?local=true";
    } else {
      window.location.href = process.env.REACT_APP_LOGOUT_URI;
    }
  };

  const handleModalSettings = () =>
    createDialog({
      id: "new-header-settings",
      Component: ModalSettings,
      props: {},
    });

  const handleModal = () => {
    if (!accountCreated) {
      createDialog({
        id: "logout-account-created",
        Component: LogoutFirstAccessModal,
        props: {
          handleLogout,
        },
      });
    } else {
      setModalIsOpen(true);
    }
  };

  const callBackMessage = useCallback(
    (message) => {
      if (message === -2025) {
        notification.displayInfo(
          intl.formatMessage({
            id: "api.error." + message,
          }),
          "info"
        );
      } else {
        notification.displayError(
          intl.formatMessage({
            id: "api.error." + message,
          }),
          "error"
        );
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [intl]
  );

  const callbackSuccess = useCallback(() => {
    history.push("/checklist");
  }, [history]);

  const handleChangeAccount = useCallback(
    (accountId) => {
      dispatch(
        changeAccounts({
          accountId,
          accountType:
            !adminToken &&
            (serviceProviderLoggingAsAnExhibitor ||
              user?.role?.type === "SERVICE_PROVIDER")
              ? "SERVICE_PROVIDER"
              : "EXHIBITOR",
          keepSignedIn,
          callbackSuccess,
          callBackMessage,
        })
      );
    },
    [
      callBackMessage,
      callbackSuccess,
      keepSignedIn,
      user,
      dispatch,
      serviceProviderLoggingAsAnExhibitor,
      adminToken,
    ]
  );

  const accountsOptions = useMemo(() => {
    if (
      !adminToken &&
      (serviceProviderLoggingAsAnExhibitor ||
        user?.role?.type === "SERVICE_PROVIDER")
    ) {
      return accountsServiceProvider;
    }

    return accountsExhibitor;
  }, [
    accountsExhibitor,
    accountsServiceProvider,
    adminToken,
    user,
    serviceProviderLoggingAsAnExhibitor,
  ]);

  const nameEvent = useMemo(() => {
    if (event?.label) {
      return event?.label;
    }
    return intl.formatMessage({ id: "event_edition" });
  }, [event, intl]);

  const nameContract = useMemo(() => {
    if (contract?.label) {
      return contract?.label;
    }
    return intl.formatMessage({ id: "contract" });
  }, [contract, intl]);

  useEffect(() => {
    if (
      !adminToken &&
      (serviceProviderLoggingAsAnExhibitor ||
        user?.role?.type === "SERVICE_PROVIDER")
    ) {
      dispatch(getAllAccountsUser({ callBackMessage, intl }));
    } else if (!adminToken && user?.role?.type === "EXHIBITOR") {
      dispatch(getAccountsExhibitor({ callBackMessage, intl }));
    }
  }, [
    dispatch,
    callBackMessage,
    adminToken,
    user,
    serviceProviderLoggingAsAnExhibitor,
    intl,
  ]);

  return (
    <Container>
      <ContentLeft>
        <Text>
          <span>{nameEvent}</span>
          <span>{nameContract}</span>
        </Text>
        <Icon onClick={() => handleModalSettings()}>
          <AlterEvent />
        </Icon>
      </ContentLeft>
      <ContainerRight>
        {!adminToken && (
          <SelectUser
            name="account"
            options={accountsOptions}
            defaultValue={user?.name}
            placeholder={
              user?.name || intl.formatMessage({ id: "select_your_profile" })
            }
            onChange={(value) => handleChangeAccount(value)}
            $isDisabled={isLoading}
          />
        )}
        <DropdownFlags />
        <Icon onClick={() => handleModal()}>
          <SignOut />
        </Icon>
      </ContainerRight>
      <ConfirmationModal
        setIsOpen={setModalIsOpen}
        modalIsOpen={modalIsOpen}
        confirmationFunction={handleLogout}
        description={intl.formatMessage({ id: "logout_confirmation" })}
        confirmationText={intl.formatMessage({ id: "yes" })}
        cancelText={intl.formatMessage({ id: "no" })}
        isLoading={isLoading}
      />
    </Container>
  );
};

export default Header;
