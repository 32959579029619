import { isBefore, isAfter } from "date-fns";

export function isTimeBefore(initialTime, finalTime) {
  var auxInitialTime = initialTime;
  var auxFinalTime = finalTime;

  const split_initial = auxInitialTime.split(":");
  const split_final = auxFinalTime.split(":");

  auxInitialTime = new Date(
    2021,
    6,
    3,
    Number(split_initial[0]),
    Number(split_initial[1])
  );

  auxFinalTime = new Date(
    2021,
    6,
    3,
    Number(split_final[0]),
    Number(split_final[1])
  );

  if (initialTime && finalTime) {
    return isBefore(new Date(auxInitialTime), new Date(auxFinalTime));
  } else {
    return true;
  }
}

export function isTimeAfter(initialTime, finalTime) {
  var auxInitialTime = initialTime;
  var auxFinalTime = finalTime;

  const split_initial = auxInitialTime.split(":");
  const split_final = auxFinalTime.split(":");

  auxInitialTime = new Date(
    2021,
    6,
    3,
    Number(split_initial[0]),
    Number(split_initial[1])
  );

  auxFinalTime = new Date(
    2021,
    6,
    3,
    Number(split_final[0]),
    Number(split_final[1])
  );

  if (initialTime && finalTime) {
    return isAfter(new Date(auxInitialTime), new Date(auxFinalTime));
  } else {
    return true;
  }
}
