import { all, takeLatest, call } from "redux-saga/effects";

import {
  getGuestsService,
  SignOnSystemGuestsService,
} from "../../../service/guests.service";

function* getGuests({ payload }) {
  try {
    const response = yield call(getGuestsService, payload.params);

    if (response.status === 200) {
      payload.callbackSuccess(response.data.output);
    }
  } catch (error) {
    payload.callbackError(error.response.data.code);
  }
}

function* SignOnSystemGuests({ payload }) {
  try {
    const response = yield call(SignOnSystemGuestsService, payload.params);

    if (response.status === 200) {
      payload.callbackSuccess(response.data.digitalInvitesGenerateTokenOutput);
    }
  } catch (error) {
    payload.callbackError(error.response.data.code);
  }
}

export default all([
  takeLatest("GET_GUESTS", getGuests),
  takeLatest("SIGN_ON_SYSTEM_GUESTS", SignOnSystemGuests),
]);
