import React, { useEffect, useMemo, useRef, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useSelector } from "react-redux";
import AnimatedMainContainer from "../../components/AnimatedMainContainer";
import { useToast } from "../../hooks/toast";
import * as Yup from "yup";
import getValidationErrors from "../../utils/getValidationErrors";
import api from "../../service";
import { useCallback } from "react";
import FormButton from "../../components/FormButton";
import { Form } from "@unform/web";
import { validateSingleFieldOnBlur } from "../../utils/formValidations";
import printJS from "print-js";

import {
  Container,
  FormContainer,
  TitleContainer,
  Title,
  FieldsContainer,
  Horizontal,
  FilterActionsContainer,
  FilterEditionContainer,
  SubmitContainer,
  FilterSubmitContainer,
  BadgeContainer,
  CredentialsActionContainer,
  LinkButton,
  GoBackContainer,
  ActionContainer,
  AdditionalInformationContainer,
  InformationContainer,
  InformationLabel,
  InformationValue,
  AssociateContainer,
  AssociateActionsContainer
} from "./styles";
import SelectWithLabel from "../../components/SelectWithLabel";
import Table from "../../components/Table";
import Badge from "../../components/Badge";
import Loader from "react-loader-spinner";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import { useLoading } from "../../hooks/loading";
import PrintModal from "./PrintModal";
import PrintCompanyNameModal from "./PrintCompanyNameModal";
import { useHistory } from "react-router";
import axios from "axios";
import { FaExclamation } from "react-icons/fa";
import InputWithLabel from "../../components/InputWithLabel";
import MaskedInputWithLabel from "../../components/MaskedInputWithLabel";
import Modal from "../../components/Modal";
import InputWithHorizontalLabel from "../../components/InputWithHorizontalLabel";
import useGenericDocumentMask from "../../utils/useGenericDocumentMask";
import Toggle from "../../components/Toggle";

const ManageCredentials = () => {
  const { user, token } = useSelector((state) => state.auth);
  const [data, setData] = useState([]);
  const formRef = useRef(null);
  const intl = useIntl();
  const { addToast } = useToast();
  const history = useHistory();

  const [loading, setLoading] = useState(false);
  const [filtered, setFiltered] = useState(false);

  const [events, setEvents] = useState([]);
  const [selectedEvent, setSelectedEvent] = useState();
  const [roles, setRoles] = useState([]);
  const [selectedRole, setSelectedRole] = useState();
  const [accounts, setAccounts] = useState([]);
  const [selectedAccount, setSelectedAccount] = useState();
  const [opportunities, setOpportunities] = useState([]);
  const [selectedOpportunity, setSelectedOpportunity] = useState();
  const [selectedCategory, setSelectedCategory] = useState();
  const [selectedAllFilters, setSelectedAllFilters] = useState(false);
  const [collaborators, setCollaborators] = useState([]);
  const [types, setTypes] = useState([]);
  const { isLoading, handleLoading } = useLoading();
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [toggledClearRows, setToggledClearRows] = useState(false);
  const [credentialsToPrintProtocol, setCredentialsToPrintProtocol] = useState(
    []
  );
  const editFormRef = useRef(null);
  const [editModalIsOpen, setEditModalIsOpen] = useState(false);
  const [credentialsToPrint, setCredentialsToPrint] = useState([]);
  const [printCompanyNameModalIsOpen, setPrintCompanyNameModalIsOpen] =
    useState(false);
  const [printCompanyNameKey, setPrintCompanyNameKey] = useState();
  const [credentialCompanyName, setCredentialCompanyName] = useState();
  const [editData, setEditData] = useState([]);
  const [key, setKey] = useState();
  const [editOpportunities, setEditOpportunities] = useState([]);
  const [editCollaborators, setEditCollaborators] = useState([]);
  const [editCategories, setEditCategories] = useState([]);
  const [editSelectedOpportunity, setEditSelectedOpportunity] = useState();
  const [editSelectedCollaborator, setEditSelectedCollaborator] = useState();
  const [editOpportunityItem, setEditOpportunityItem] = useState();
  const [editCollaboratorItem, setEditCollaboratorItem] = useState();
  const [editCategoryItem, setEditCategoryItem] = useState();
  const [selectedRows, setSelectedRows] = useState([]);
  const [statusBasedValues, setStatusBasedValues] = useState([]);
  const [documentMask, setDocumentMask] = useGenericDocumentMask();
  const [isFastTrack, setIsFastTrack] = useState(false);
  const [searchCanBePreserved, setSearchCanBePreserved] = useState(true);
  const [jobTitles, setJobTitles] = useState([]);
  const [signatureNames, setSignatureNames] = useState([]);
  const isPortuguese = useMemo(()=> intl.locale === 'pt', [intl]);

  const schema = Yup.object().shape({
    event: Yup.string()
      .nullable()
      .required(intl.formatMessage({ id: "error.field_required" })),
    role: Yup.string()
      .nullable()
      .required(intl.formatMessage({ id: "error.field_required" })),
    account: Yup.string()
      .nullable()
      .required(intl.formatMessage({ id: "error.field_required" })),
    opportunity: Yup.string()
      .nullable()
      .required(intl.formatMessage({ id: "error.field_required" })),
    category: Yup.string()
      .nullable()
      .required(intl.formatMessage({ id: "error.field_required" })),
    collaborator: Yup.array().min(
      1,
      intl.formatMessage({ id: "select_at_least_one_of_the_options" })
    ),
    name: Yup.string().nullable(),
    document: Yup.string().nullable(),
    is_printed: Yup.bool().nullable(),
    company_name: Yup.string().nullable(),
    assignment: Yup.string().nullable(),
    responsible_who_withdrew: Yup.string().nullable(),
    is_collaborator_inactive: Yup.bool().nullable(),
  });

  const editSchema = Yup.object().shape({
    edit_opportunity: Yup.string().required(
      intl.formatMessage({ id: "error.field_required" })
    ),
    edit_collaborator: Yup.string().required(
      intl.formatMessage({ id: "error.field_required" })
    ),
    edit_category: Yup.string().required(
      intl.formatMessage({ id: "error.field_required" })
    ),
    edit_assignment: Yup.string().required(
      intl.formatMessage({ id: "error.field_required" })
    ),
    edit_credential_name: Yup.string().required(
      intl.formatMessage({ id: "error.field_required" })
    ),
    edit_email: Yup.string()
      .email(intl.formatMessage({ id: "error.valid_email" }))
      .nullable()
      .when("fast_track", {
        is: (fast_track) => !fast_track,
        then: Yup.string()
          .nullable()
          .required(
            intl.formatMessage({
              id: "error.field_required",
            })
          ),
      }
    ),
  });

  const handleClearRows = useCallback(() => {
    setToggledClearRows(!toggledClearRows);
  }, [toggledClearRows]);

  const handleUpdateCategoriesAndCollaborators = useCallback(() => {
    handleLoading(true);
    const accountId = formRef.current.getFieldValue("account");
    const eventId = formRef.current.getFieldValue("event");
    const opportunityId = formRef.current.getFieldValue("opportunity");
    const config = {
      headers: {
        "Content-Type": "application/json",
        Accept: "*/*",
        Authorization: `Bearer ${token}`,
      },
    };
    axios
      .all([
        api.patch(
          `/accreditation/admin/quota/${accountId}`,
          { eventId: eventId, opportunityId: opportunityId },
          config
        ),
        api.patch(
          `/ecommerce/category/available/opportunity/admin-accreditation`,
          { userId: accountId, opportunityId: opportunityId },
          config
        ),
        api.get(
          `/associate-collaborators/admin-accreditation/by-user/${accountId}?opportunity=${opportunityId}&credential=0`,
          config
        ),
      ])
      .then(
        axios.spread(
          (quotaResponse, categoryResponse, collaboratorsResponse) => {
            const { total, used } = quotaResponse.data.credentialQuotesOutput;
            const credentialCategories = handleCategoryReduce(
              categoryResponse.data
                .ecommerceChlebaCategoryCredentialAvailableOutputs,
              total,
              used,
              formRef.current.getFieldValue("role")
            );
            setTypes(credentialCategories);
            setCollaborators(collaboratorsResponse.data.output);
          }
        )
      )
      .catch((error) =>
        addToast({
          type: "error",
          title: intl.formatMessage({
            id: "error",
          }),
          description: intl.formatMessage({
            id: "api.error." + error.response.data.code,
          }),
        })
      )
      .finally(handleLoading(false));
  }, [addToast, handleLoading, intl, token]);

  const clearFields = () => {
    formRef.current.reset();
    formRef.current.setFieldValue("name", "");
    formRef.current.setFieldValue("document", "");
    formRef.current.setFieldValue("is_printed", {
      label: intl.formatMessage({ id: "all" }),
      value: null,
    });
    formRef.current.setFieldValue("company_name", "");
    formRef.current.setFieldValue("is_collaborator_inactive", {
      label: intl.formatMessage({ id: "all" }),
      value: null,
    });
    formRef.current.setFieldValue("assignment");
    formRef.current.setFieldValue("responsible_who_withdrew");
    formRef.current.setFieldValue("collaborator");
    formRef.current.setFieldValue("category");
    setFiltered(false);
    handleUpdateCategoriesAndCollaborators();
  };

  const handleSearchForAssociatedCredentials = useCallback(() => {
    try {
      if (selectedAllFilters) {
        setLoading(true);
        if (loading) {
          return;
        }

        handleClearRows();

        api
          .patch(
            `/admin/accreditation`,
            {
              eventId: formRef.current.getFieldValue("event"),
              role: formRef.current.getFieldValue("role"),
              systemUserId: formRef.current.getFieldValue("account"),
              opportunityId: formRef.current.getFieldValue("opportunity"),
              categoryId: formRef.current.getFieldValue("category"),
              collaboratorName: formRef.current.getFieldValue("name"),
              collaboratorDocument: formRef.current.getFieldValue("document"),
              isPrinted: formRef.current.getFieldValue("is_printed"),
              companyName: formRef.current.getFieldValue("company_name"),
              jobTitle: formRef.current.getFieldValue("assignment"),
              responsibleWhoWithdrew: formRef.current.getFieldValue(
                "responsible_who_withdrew"
              ),
              isCollaboratorInactive: formRef.current.getFieldValue(
                "is_collaborator_inactive"
              ),
            },
            {
              headers: {
                "Content-Type": "application/json",
                Accept: "*/*",
                Authorization: `Bearer ${token}`,
              },
            }
          )
          .then((result) => {
            setFiltered(true);
            setData(result.data);
          })
          .catch((err) =>
            addToast({
              type: "error",
              title: intl.formatMessage({
                id: "error",
              }),
              description: intl.formatMessage({
                id: "api.error." + err.response.data.code,
              }),
            })
          )
          .finally(() => setLoading(false));
      }
    } catch (err) {
      setLoading(false);
      if (err instanceof Yup.ValidationError) {
        const errors = getValidationErrors(err);

        formRef.current?.setErrors(errors);

        return;
      }
    }
  }, [addToast, intl, token, loading, handleClearRows, selectedAllFilters]);

  const handleAssociateSubmit = useCallback(
    async (data) => {
      try {
        formRef.current?.setErrors({});

        await schema.validate(data, { abortEarly: false });

        setLoading(true);
        if (loading) {
          return;
        }

        api
          .post(
            `/accreditation/admin/${data.account}`,
            {
              eventId: data.event,
              opportunityId: data.opportunity,
              associateCollaboratorList: data.collaborator,
              categoryId: data.category,
            },
            {
              headers: {
                "Content-Type": "application/json",
                Accept: "*/*",
                Authorization: `Bearer ${token}`,
              },
            }
          )
          .then(() => {
            if (data.collaborator.length === 1)
              addToast({
                type: "success",
                title: intl.formatMessage({
                  id: "success",
                }),
                description: intl.formatMessage({
                  id: "success.credential_associated",
                }),
              });
            else 
              addToast({
                type: "success",
                title: intl.formatMessage({
                  id: "success",
                }),
                description: intl.formatMessage({
                  id: "success.credentials_associated",
                }),
              });
            clearFields();
          })
          .catch((err) =>
            addToast({
              type: "error",
              title: intl.formatMessage({
                id: "error",
              }),
              description: intl.formatMessage({
                id: "api.error." + err.response.data.code,
              }),
            })
          )
          .finally(() => setLoading(false));
      } catch (err) {
        setLoading(false);
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);

          formRef.current?.setErrors(errors);

          return;
        }
      }
    },
    [addToast, intl, loading, schema, token]
  );

  useEffect(() => {
    handleLoading(true);
    setRoles([
      {
        label: intl.formatMessage({ id: "exhibitor" }),
        value: "EXHIBITOR",
      },
      {
        label: intl.formatMessage({ id: "service_provider" }),
        value: "SERVICE_PROVIDER",
      },
    ]);

    setStatusBasedValues([
      {
        label: intl.formatMessage({ id: "all" }),
        value: null,
      },
      {
        label: intl.formatMessage({ id: "yes" }),
        value: true,
      },
      {
        label: intl.formatMessage({ id: "no" }),
        value: false,
      },
    ]);

    const config = {
      headers: {
        "Content-Type": "application/json",
        Accept: "*/*",
        Authorization: `Bearer ${token}`,
      },
    };

    api
      .get(`/admin/events`, config)
      .then((eventResponse) => {
        const events = eventResponse.data.output.map((event) => ({
          label: event.eventEditionName,
          value: event.idNumber,
        }));
        let eventsSort = [...events].sort((a, b) =>
          a.label < b.label ? -1 : a.label > b.label ? 1 : 0
        );
        setEvents(eventsSort);
      })
      .catch((err) =>
        addToast({
          type: "error",
          title: intl.formatMessage({
            id: "error",
          }),
          description: intl.formatMessage({
            id: "api.error." + err.response.data.code,
          }),
        })
      )
      .finally(() => handleLoading(false));
  }, [addToast, handleLoading, intl, token]);

  const validationBlur = (name) => {
    let errors = formRef.current.getErrors();
    validateSingleFieldOnBlur(errors, schema, name, formRef);
  };

  const handleOpenProtocolModal = useCallback(
    ({ printAll }) => {
      if (printAll) {
        setCredentialsToPrintProtocol(
          data.credentialAdminOutputList.map((value) => {
            return value.id;
          })
        );
        setModalIsOpen(true);
      } else {
        if (selectedRows.length > 0) {
          setModalIsOpen(true);
          setCredentialsToPrintProtocol(selectedRows);
        }
      }
    },
    [data.credentialAdminOutputList, selectedRows]
  );

  useEffect(() => {
    formRef.current.setFieldValue("account");
    if (selectedEvent && selectedRole) {
      handleLoading(true);
      api
        .patch(
          `/users/by-event-and-role`,
          {
            eventId: selectedEvent,
            roleType: selectedRole,
            useEmailInTheServiceProviderLabel: false,
            useCancelledOpportunities: false,
          },
          {
            headers: {
              "Content-Type": "application/json",
              Accept: "*/*",
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((result) => setAccounts(result.data.accountDropdownOutputList))
        .catch((err) =>
          addToast({
            type: "error",
            title: intl.formatMessage({
              id: "error",
            }),
            description: intl.formatMessage({
              id: "api.error." + err.response.data.code,
            }),
          })
        )
        .finally(() => handleLoading(false));
    }
  }, [addToast, intl, selectedEvent, selectedRole, token, handleLoading]);

  useEffect(() => {
    formRef.current.setFieldValue("opportunity");
    if (selectedEvent && selectedRole && selectedAccount) {
      handleLoading(true);

      const config = {
        headers: {
          "Content-Type": "application/json",
          Accept: "*/*",
          Authorization: `Bearer ${token}`,
        },
      };
      api
        .patch(
          `/exhibitor/opportunity/admin-accreditation/event`,
          { eventId: selectedEvent, userId: selectedAccount },
          config
        )
        .then((result) =>
          setOpportunities(
            [...result.data.output].sort((a, b) =>
              a.label < b.label ? -1 : a.label > b.label ? 1 : 0
            )
          )
        )
        .catch((err) =>
          addToast({
            type: "error",
            title: intl.formatMessage({
              id: "error",
            }),
            description: intl.formatMessage({
              id: "api.error." + err.response.data.code,
            }),
          })
        )
        .finally(() => handleLoading(false));
    }
  }, [
    addToast,
    intl,
    selectedAccount,
    selectedEvent,
    selectedRole,
    token,
    handleLoading,
  ]);

  const handleCategoryReduce = (categories, total, used, userRole) => {
    return categories.reduce(
      (categories, category) => [
        ...categories,
        {
          value: category.id,
          label: isPortuguese ? category.title : category.englishTitle,
          information: {
            total: category.total || 0,
            used: category.used || 0,
          },
        },
      ],
      userRole === "EXHIBITOR"
        ? [
            {
              label:
                intl.formatMessage({ id: "contract" }) +
                " - " +
                (used || "0") +
                "/" +
                (total || "0"),
              value: 0,
              information: {
                total: total || 0,
                used: used || 0,
              },
            },
          ]
        : []
    );
  };

  useEffect(() => {
    formRef.current.setFieldValue("category");
    formRef.current.setFieldValue("collaborator");
    if (selectedEvent && selectedOpportunity && selectedAccount) {
      handleUpdateCategoriesAndCollaborators();
    }
  }, [selectedOpportunity, selectedEvent, selectedAccount]);

  useEffect(() => {
    if (
      selectedEvent &&
      selectedRole &&
      selectedAccount &&
      selectedOpportunity &&
      selectedCategory !== undefined
    ) {
      setSelectedAllFilters(true);

      formRef.current.setFieldValue("assignment");
      formRef.current.setFieldValue("responsible_who_withdrew");

      handleLoading(true);

      api
        .patch(
          "/admin/accreditation/job-title-signature-name-filters",
          {
            eventId: selectedEvent,
            role: selectedRole,
            systemUserId: selectedAccount,
            opportunityId: selectedOpportunity,
            categoryId: selectedCategory,
          },
          {
            headers: {
              "Content-Type": "application/json",
              Accept: "*/*",
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((result) => {
          setJobTitles(result.data.collaboratorJobTitleList);
          setSignatureNames(result.data.protocolSignatureNameList);
        })
        .catch((err) =>
          addToast({
            type: "error",
            title: intl.formatMessage({
              id: "error",
            }),
            description: intl.formatMessage({
              id: "api.error." + err.response.data.code,
            }),
          })
        )
        .finally(() => handleLoading(false));
    } else {
      setSelectedAllFilters(false);
      setFiltered(false);
    }
  }, [
    addToast,
    intl,
    token,
    selectedAccount,
    selectedEvent,
    selectedRole,
    selectedCategory,
    selectedOpportunity,
  ]);

  const handleReprintProtocol = useCallback(
    (id) => {
      handleLoading(true);
      api
        .get(`/credential-reports/${id}`, {
          headers: {
            "Content-Type": "application/json",
            Accept: "*/*",
            Authorization: `Bearer ${token}`,
          },
        })
        .then((result) => {
          const output = result.data.reportOutput;

          printJS({
            printable: output.file,
            type: "pdf",
            base64: true,
          });
        })
        .catch((err) => {
          addToast({
            type: "error",
            title: intl.formatMessage({
              id: "error",
            }),
            description: intl.formatMessage({
              id: "api.error." + err.response.data.code,
            }),
          });
        })
        .finally(() => {
          handleLoading(false);
        });
    },
    [addToast, handleLoading, intl, token]
  );

  const handleUnassociation = useCallback(
    (id) => {
      handleLoading(true);
      api
        .delete(`/accreditation/admin/${id}`, {
          headers: {
            "Content-Type": "application/json",
            Accept: "*/*",
            Authorization: `Bearer ${token}`,
          },
        })
        .then((result) => {
          addToast({
            type: "success",
            title: intl.formatMessage({
              id: "success",
            }),
            description: intl.formatMessage({
              id: "success.credential_unassociated",
            }),
          });
          clearFields();
        })
        .catch((err) =>
          addToast({
            type: "error",
            title: intl.formatMessage({
              id: "error",
            }),
            description: intl.formatMessage({
              id: "api.error." + err.response.data.code,
            }),
          })
        )
        .finally(() => handleLoading(false));
    },
    [addToast, intl, token, handleLoading]
  );

  const handleEdition = useCallback(
    (id) => {
      handleLoading(true);

      const config = {
        headers: {
          "Content-Type": "application/json",
          Accept: "*/*",
          Authorization: `Bearer ${token}`,
        },
      };

      axios
        .all([api.get(`/accreditation/admin/${id}`, config)])
        .then(
          axios.spread(async (accreditationResponse) => {
            const data = accreditationResponse.data.credentialOutput;
            setEditData(data);

            const {
              eventId,
              associateCollaboratorId,
              opportunityId,
              categoryId,
              userId,
              userRole,
            } = accreditationResponse.data.credentialOutput;

            return {
              categoryId,
              opportunityId,
              eventId,
              associateCollaboratorId,
              userId,
              userRole,
              opportunityResponse: await api.patch(
                `/exhibitor/opportunity/admin-accreditation/event`,
                { eventId: eventId, userId: userId },
                config
              ),
              collaboratorsResponse: await api.get(
                `/associate-collaborators/admin-accreditation/by-user/${userId}?opportunity=${opportunityId}&credential=${id}`,
                config
              ),
            };
          })
        )
        .then(
          async ({
            categoryId,
            opportunityId,
            eventId,
            associateCollaboratorId,
            userId,
            userRole,
            opportunityResponse,
            collaboratorsResponse,
          }) => {
            const opportunities = [...opportunityResponse.data.output].sort(
              (a, b) => (a.label < b.label ? -1 : a.label > b.label ? 1 : 0)
            );
            setEditOpportunities(opportunities);
            handleOpportunityItemSelection(opportunities, opportunityId);
            const collaborators = collaboratorsResponse.data.output;
            setEditCollaborators(collaborators);
            handleCollaboratorItemSelection(
              collaborators,
              associateCollaboratorId
            );

            return {
              categoryId,
              userRole,
              quotaResponse: await api.patch(
                `/accreditation/admin/quota/${userId}`,
                { eventId: eventId, opportunityId: opportunityId },
                config
              ),
              categoryResponse: await api.patch(
                `/ecommerce/category/available/opportunity/admin-accreditation`,
                { userId: userId, opportunityId: opportunityId },
                config
              ),
            };
          }
        )
        .then(({ categoryId, userRole, quotaResponse, categoryResponse }) => {
          const { total, used } = quotaResponse.data.credentialQuotesOutput;
          const credentialCategories = handleCategoryReduce(
            categoryResponse.data
              .ecommerceChlebaCategoryCredentialAvailableOutputs,
            total,
            used,
            userRole
          );
          setEditCategories(credentialCategories);
          handleCategoryItemSelection(credentialCategories, categoryId);
          setEditModalIsOpen(true);
        })
        .catch((err) => {
          addToast({
            type: "error",
            title: intl.formatMessage({
              id: "error",
            }),
            description: intl.formatMessage({
              id: "api.error." + err.response.data.code,
            }),
          });
          setEditModalIsOpen(false);
        })
        .finally(() => {
          handleLoading(false);
          setKey(new Date());
          setLoading(false);
        });
    },
    [addToast, intl, token, handleLoading]
  );

  const handleOpportunityItemSelection = (opportunities, id) => {
    const item = opportunities.find((opportunity) => opportunity.value === id);
    setEditOpportunityItem(item);
    setEditSelectedOpportunity(id);
  };

  const handleCategoryItemSelection = (categories, id) => {
    const item = categories.find((category) => category.value === id);
    setEditCategoryItem(item);
  };

  const handleCollaboratorItemSelection = (collaborators, id) => {
    const item = collaborators.find(
      (collaborator) => collaborator.value === id
    );
    setEditCollaboratorItem(item);
    setEditSelectedCollaborator(item);
  };

  useEffect(() => {
    if (editModalIsOpen && editSelectedCollaborator) {
      editFormRef.current.setFieldValue(
        "edit_assignment",
        editSelectedCollaborator.jobTitle
      );
      editFormRef.current.setFieldValue(
        "edit_credential_name",
        editSelectedCollaborator.credentialName
      );
      editFormRef.current.setFieldValue(
        "fast_track",
        editSelectedCollaborator.fastTrack
      );
      editFormRef.current.setFieldValue(
        "edit_email",
        editSelectedCollaborator.email
      );
    }
  }, [editSelectedCollaborator]);

  useEffect(() => {
    if (editModalIsOpen && !loading) {
      editFormRef.current.setFieldValue("edit_category");
      editFormRef.current.setFieldValue("edit_collaborator");
      editFormRef.current.setFieldValue("edit_assignment", "");
      editFormRef.current.setFieldValue("edit_credential_name", "");
      editFormRef.current.setFieldValue("edit_email", "");
      editFormRef.current.setFieldValue("fast_track", false);
      if (editData && editSelectedOpportunity) {
        handleLoading(true);
        const config = {
          headers: {
            "Content-Type": "application/json",
            Accept: "*/*",
            Authorization: `Bearer ${token}`,
          },
        };
        axios
          .all([
            api.patch(
              `/accreditation/admin/quota/${editData.userId}`,
              {
                eventId: editData.eventId,
                opportunityId: editSelectedOpportunity,
              },
              config
            ),
            api.patch(
              `/ecommerce/category/available/opportunity/admin-accreditation`,
              {
                userId: editData.userId,
                opportunityId: editSelectedOpportunity,
              },
              config
            ),
            api.get(
              `/associate-collaborators/admin-accreditation/by-user/${editData.userId}?opportunity=${editSelectedOpportunity}&credential=${editData.id}`,
              config
            ),
          ])
          .then(
            axios.spread(
              (quotaResponse, categoryResponse, collaboratorsResponse) => {
                const { total, used } =
                  quotaResponse.data.credentialQuotesOutput;
                const credentialCategories = handleCategoryReduce(
                  categoryResponse.data
                    .ecommerceChlebaCategoryCredentialAvailableOutputs,
                  total,
                  used,
                  editData.userRole
                );
                setEditCategories(credentialCategories);
                setEditCollaborators(collaboratorsResponse.data.output);
              }
            )
          )
          .catch((error) =>
            addToast({
              type: "error",
              title: intl.formatMessage({
                id: "error",
              }),
              description: intl.formatMessage({
                id: "api.error." + error.response.data.code,
              }),
            })
          )
          .finally(() => handleLoading(false));
      }
    }
    //loading shouldn't be in the dependency array
  }, [addToast, handleLoading, intl, editSelectedOpportunity, editData, token]);

  const columns = useMemo(
    () => [
      {
        name: intl.formatMessage({ id: "associated_credential" }),
        selector: (row) => row.collaboratorName,
        sortable: true,
        allowOverflow: true,
        wrap: false,
        minWidth: "115px",
        cell: (row) => (
          <div
            title={`${
              !row.collaboratorStatus
                ? intl.formatMessage({ id: "collaborator_inactive" })
                : ""
            }`}
            style={{ display: "flex", justifyContent: "center" }}
          >
            {!row.collaboratorStatus ? (
              <div>
                <FaExclamation color="#cc0000" />
              </div>
            ) : null}
            <div>{row.collaboratorName}</div>
          </div>
        ),
      },
      {
        name: intl.formatMessage({ id: "assignment" }),
        selector: (row) => row.assignment,
      },
      {
        name: intl.formatMessage({ id: "type_of_credential" }),
        selector: (row) => row.category,
        cell: (row) => row.category || intl.formatMessage({ id: "contract" }),
      },
      {
        name: intl.formatMessage({ id: "credential_name" }),
        selector: (row) => row.credentialName,
      },
      {
        name: intl.formatMessage({ id: "company_name_credential" }),
        selector: (row) => row.companyName,
      },
      {
        name: intl.formatMessage({ id: "printed_credential" }),
        cell: (row) =>
          row.printed
            ? intl.formatMessage({ id: "yes" })
            : intl.formatMessage({ id: "no" }),
      },
      {
        name: intl.formatMessage({ id: "scheduled" }),
        selector: (row) => row.scheduled,
        cell: (row) =>
          row.credentialScheduleId
            ? intl.formatMessage({ id: "yes" })
            : intl.formatMessage({ id: "no" }),
      },
      {
        name: intl.formatMessage({ id: "protocol" }),
        selector: (row) => row.protocol,
        cell: (row) => {
          if (row.protocol) {
            return (
              <div
                style={{ cursor: "pointer" }}
                onClick={() => handleReprintProtocol(row.protocol)}
              >
                {intl.formatMessage(
                  { id: "click_here_to_reprint" },
                  {
                    protocolNumber: row.protocol,
                  }
                )}
              </div>
            );
          }
          return;
        },
        allowOverflow: true,
        wrap: false,
        minWidth: "315px",
      },
      {
        name: intl.formatMessage({
          id: "name_responsible_withdrew_credential",
        }),
        selector: (row) => row.nameOfSignature || "",
      },
      {
        cell: (row) => {
          return user?.role?.type === "ADMINISTRATOR" ||
            (row.collaboratorStatus && !row.credentialScheduleId && !row.protocol) ? (
            <Badge
              color="#002244"
              text={intl.formatMessage({ id: "edit" })}
              onClick={() => handleEdition(row.id)}
            />
          ) : (
            <div></div>
          );
        },
        right: true,
        width: "125px",
      },
      {
        cell: (row) => {
          return !row.printed ? (
            <Badge
              color="#002244"
              text={intl.formatMessage({ id: "unassociate" })}
              onClick={() => handleUnassociation(row.id)}
            />
          ) : (
            <div></div>
          );
        },
        right: true,
        width: "125px",
      },
    ],
    [intl, handleReprintProtocol, handleUnassociation, handleEdition]
  );

  const handleOpenPrintCompanyNameModal = useCallback(
    ({ printAll }) => {
      if (printAll) {
        setCredentialsToPrint(
          data.credentialAdminOutputList.map((value) => {
            return value.id;
          })
        );
        setPrintCompanyNameModalIsOpen(true);
      } else {
        if (selectedRows.length > 0) {
          setPrintCompanyNameModalIsOpen(true);
          setCredentialsToPrint(selectedRows);
        }
      }
      setPrintCompanyNameKey(new Date());
    },
    [data.credentialAdminOutputList, selectedRows]
  );

  const editValidationBlur = (name) => {
    let errors = editFormRef.current.getErrors();
    validateSingleFieldOnBlur(errors, editSchema, name, editFormRef);
  };

  const handleEditionSubmit = useCallback(
    async (data) => {
      try {
        if (editModalIsOpen && editData) {
          editFormRef.current?.setErrors({});

          await editSchema.validate(data, { abortEarly: false });

          setLoading(true);
          if (loading) return;

          handleLoading(true);

          api
            .put(
              `/accreditation/admin/${editData.userId}`,
              {
                credentialId: editData.id,
                eventId: editData.eventId,
                opportunityId: data.edit_opportunity,
                associateCollaboratorId: data.edit_collaborator,
                categoryId: data.edit_category,
                credentialName: data.edit_credential_name,
                jobTitle: data.edit_assignment,
                email: data.edit_email,
                fastTrack: data.fast_track,
              },
              {
                headers: {
                  "Content-Type": "application/json",
                  Accept: "*/*",
                  Authorization: `Bearer ${token}`,
                },
              }
            )
            .then((result) => {
              addToast({
                type: "success",
                title: intl.formatMessage({
                  id: "success",
                }),
                description: intl.formatMessage({
                  id: "success.credential_updated",
                }),
              });
              if(!searchCanBePreserved) clearFields()
              else { 
                handleSearchForAssociatedCredentials();
                handleUpdateCategoriesAndCollaborators();
              }
              setSearchCanBePreserved(true)
            })
            .catch((err) =>
              addToast({
                type: "error",
                title: intl.formatMessage({
                  id: "error",
                }),
                description: intl.formatMessage({
                  id: "api.error." + err.response.data.code,
                }),
              })
            )
            .finally(() => {
              setEditModalIsOpen(false);
              setLoading(false);
              handleLoading(false);
            });
        }
      } catch (err) {
        setLoading(false);
        handleLoading(false);
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);
          editFormRef.current?.setErrors(errors);
          return;
        }
      }
    },
    [
      editSchema,
      loading,
      handleLoading,
      token,
      addToast,
      intl,
      editModalIsOpen,
      editData,
    ]
  );

  useEffect(()=>{
    const params = new URLSearchParams(document.location.search);
    if(!events) return
    if(!params.get("event")) return
    formRef.current.setFieldValue('event', {
      label: events.filter(
        (i) => i.value === Number(params.get("event"))
      )[0]?.label,
      value: params.get("event")
    },)
    if(params.get("role")) formRef.current.setFieldValue('role', {
      label: roles.filter(
        (i) => i.value === params.get("role")
      )[0]?.label,
      value: params.get("role")
    },)
  },[events, roles])

  useEffect(()=>{
    const params = new URLSearchParams(document.location.search);
    if(!accounts) return
    if(!params.get("account")) return
    formRef.current.setFieldValue('account', {
      label: accounts.filter(
        (i) => i.value === Number(params.get("account"))
      )[0]?.label,
      value: params.get("account")
    },)
  },[accounts])

  const buildReturnUrl = ()=>{
    const initialURL = `/manage-credentials`
    const partialURLEvent = !formRef.current.getFieldValue('event') ? "" : `?event=${formRef.current.getFieldValue('event')}`
    const partialURLRole = !formRef.current.getFieldValue('role') ? "" : `&role=${formRef.current.getFieldValue('role')}`
    const partialURLAccount = !formRef.current.getFieldValue('account') ? "" : `&account=${formRef.current.getFieldValue('account')}`

    return initialURL + partialURLEvent + partialURLRole + partialURLAccount
  }


  const handleNewCollaboratorClick = () =>{
    history.replace(buildReturnUrl())
    history.push('/manage-credentials/new')
  }


  return (
    <AnimatedMainContainer
      breadcrumb={intl.formatMessage({ id: "breadcrumb.credentials" })}
    >
      <Container>
        <FormContainer>
          <Form
            ref={formRef}
            onSubmit={handleAssociateSubmit}
            initialData={{
              is_printed: {
                label: intl.formatMessage({ id: "all" }),
                value: null,
              },
              is_collaborator_inactive: {
                label: intl.formatMessage({ id: "all" }),
                value: null,
              },
            }}
          >
            <TitleContainer>
              <Title>
                <FormattedMessage id="manage_credentials" />
              </Title>
              <GoBackContainer>
                <ActionContainer>
                  <LinkButton to="/dashboard">
                    <Badge
                      color="#C9C9C9"
                      text={intl.formatMessage({ id: "goback" })}
                      fontSize="12px"
                    />
                  </LinkButton>
                  <LinkButton
                    onClick={handleNewCollaboratorClick}
                  >
                    <Badge
                      color="#002244"
                      text={intl.formatMessage({ id: "new_collaborator" })}
                      fontSize="12px"
                    />
                  </LinkButton>
                </ActionContainer>
              </GoBackContainer>
            </TitleContainer>
            <FieldsContainer>
              <SelectWithLabel
                name="event"
                validationBlur={validationBlur}
                label={intl.formatMessage({ id: "event_edition" })}
                placeholder={intl.formatMessage({ id: "event_edition" })}
                labelOrientation="horizontal"
                labelWidth="150px"
                options={events}
                onChange={(event) => setSelectedEvent(event?.value)}
                required
              />
              <SelectWithLabel
                name="role"
                validationBlur={validationBlur}
                label={intl.formatMessage({ id: "type" })}
                placeholder={intl.formatMessage({ id: "type" })}
                labelOrientation="horizontal"
                labelWidth="150px"
                options={roles}
                onChange={(role) => setSelectedRole(role?.value)}
                required
              />
              <SelectWithLabel
                name="account"
                validationBlur={validationBlur}
                label={intl.formatMessage({ id: "account" })}
                placeholder={intl.formatMessage({ id: "account" })}
                labelOrientation="horizontal"
                labelWidth="150px"
                options={accounts}
                onChange={(account) => {
                  setSelectedAccount(account?.value);
                  setCredentialCompanyName(account?.credentialCompanyName);
                }}
                required
              />
              <SelectWithLabel
                name="opportunity"
                validationBlur={validationBlur}
                label={intl.formatMessage({ id: "contract" })}
                placeholder={intl.formatMessage({ id: "contract" })}
                labelOrientation="horizontal"
                labelWidth="150px"
                options={opportunities}
                onChange={(opportunity) =>
                  setSelectedOpportunity(opportunity?.value)
                }
                required
              />
              <SelectWithLabel
                name="category"
                validationBlur={validationBlur}
                label={intl.formatMessage({ id: "credential_types" })}
                placeholder={intl.formatMessage({ id: "credential_types" })}
                labelOrientation="horizontal"
                labelWidth="150px"
                options={types}
                onChange={(category) => setSelectedCategory(category?.value)}
                required
              />
            </FieldsContainer>
            {selectedAllFilters ? (
              <>
                <FieldsContainer>
                  <SelectWithLabel
                    name="collaborator"
                    closeMenuOnSelect={false}
                    placeholder={intl.formatMessage({
                      id: "collaborator",
                    })}
                    label={intl.formatMessage({
                      id: "collaborator",
                    })}
                    validationBlur={validationBlur}
                    labelOrientation="horizontal"
                    labelWidth="150px"
                    options={collaborators}
                    isMulti
                    required
                  />
                  <AssociateActionsContainer>
                    <AssociateContainer>
                      <FormButton type="submit" loading={loading} marginTop={'0px'}>
                        <FormattedMessage id="associate_selected_collaborators" />
                      </FormButton>
                    </AssociateContainer>
                  </AssociateActionsContainer>
                </FieldsContainer>
                <TitleContainer>
                  <Title>
                    <FormattedMessage id="search_for_associated_credentials" />
                  </Title>
                </TitleContainer>
                <FieldsContainer>
                  <Horizontal>
                    <InputWithLabel
                      name="name"
                      validationBlur={validationBlur}
                      label={intl.formatMessage({ id: "name" })}
                      placeholder={intl.formatMessage({ id: "name" })}
                    />
                    <MaskedInputWithLabel
                      name="document"
                      maskChar={null}
                      mask={documentMask}
                      onChange={setDocumentMask}
                      validationBlur={validationBlur}
                      label={intl.formatMessage({ id: "document_cpf_or_passport" })}
                      placeholder={intl.formatMessage({
                        id: "document_filter",
                      })}
                    />
                    <SelectWithLabel
                      name="is_printed"
                      validationBlur={validationBlur}
                      label={intl.formatMessage({ id: "printed_credential" })}
                      placeholder={intl.formatMessage({
                        id: "printed_credential",
                      })}
                      options={statusBasedValues}
                    />
                  </Horizontal>
                  <Horizontal>
                    <InputWithLabel
                      name="company_name"
                      validationBlur={validationBlur}
                      label={intl.formatMessage({
                        id: "company_name_credential",
                      })}
                      placeholder={intl.formatMessage({
                        id: "company_name_credential",
                      })}
                    />
                    <SelectWithLabel
                      name="is_collaborator_inactive"
                      validationBlur={validationBlur}
                      label={intl.formatMessage({ id: "display_deleted" })}
                      placeholder={intl.formatMessage({
                        id: "display_deleted",
                      })}
                      options={statusBasedValues}
                    />
                  </Horizontal>
                  <Horizontal>
                    <SelectWithLabel
                      name="assignment"
                      validationBlur={validationBlur}
                      label={intl.formatMessage({
                        id: "assignment",
                      })}
                      placeholder={intl.formatMessage({
                        id: "assignment",
                      })}
                      options={jobTitles}
                      clearable
                    />
                    <SelectWithLabel
                      name="responsible_who_withdrew"
                      validationBlur={validationBlur}
                      label={intl.formatMessage({
                        id: "name_responsible_withdrew_credentials",
                      })}
                      placeholder={intl.formatMessage({
                        id: "name_responsible_withdrew_credentials",
                      })}
                      options={signatureNames}
                      clearable
                    />
                  </Horizontal>
                  <FilterActionsContainer>
                    <FilterSubmitContainer>
                      <FormButton
                        type="button"
                        onClick={handleSearchForAssociatedCredentials}
                        loading={loading}
                      >
                        <FormattedMessage id="search" />
                      </FormButton>
                    </FilterSubmitContainer>
                  </FilterActionsContainer>
                </FieldsContainer>
              </>
            ) : null}
          </Form>
        </FormContainer>
      </Container>

      <CredentialsActionContainer></CredentialsActionContainer>

      {filtered && selectedAllFilters ? (
        <>
          <Table
            columns={columns}
            data={data.credentialAdminOutputList}
            selectableRows={true}
            onSelectedRowsChange={(rowsInformation) =>
              setSelectedRows(rowsInformation.selectedRows.map((row) => row.id))
            }
            title={intl.formatMessage({ id: "results" })}
            progressPending={loading}
            clearSelectedRows={toggledClearRows}
            progressComponent={
              <Loader type="Oval" color="#00BFFF" height={100} width={100} />
            }
          />
          <CredentialsActionContainer>
            <BadgeContainer
              onClick={() =>
                handleOpenPrintCompanyNameModal({ printAll: false })
              }
            >
              <Badge
                color="#33d27e"
                text={intl.formatMessage({ id: "print_selected" })}
                fontSize="16px"
                disabled={selectedRows.length <= 0}
              />
            </BadgeContainer>
            <BadgeContainer
              onClick={() => handleOpenProtocolModal({ printAll: false })}
            >
              <Badge
                color="#33d27e"
                text={intl.formatMessage({ id: "fill_withdrawal_protocol" })}
                fontSize="16px"
                disabled={selectedRows.length <= 0}
              />
            </BadgeContainer>
          </CredentialsActionContainer>
        </>
      ) : (
        <CredentialsActionContainer>
          <FormattedMessage id="need_to_filter_first" />
        </CredentialsActionContainer>
      )}
      <PrintModal
        setIsOpen={setModalIsOpen}
        modalIsOpen={modalIsOpen}
        credentialsId={credentialsToPrintProtocol}
        updateCallback={() => handleSearchForAssociatedCredentials()}
      />
      <PrintCompanyNameModal
        setIsOpen={setPrintCompanyNameModalIsOpen}
        modalIsOpen={printCompanyNameModalIsOpen}
        credentialsId={credentialsToPrint}
        originalCredentialCompanyName={credentialCompanyName}
        key={printCompanyNameKey}
        updateCallback={() => handleSearchForAssociatedCredentials()}
      />
      <Modal
        setIsOpen={setEditModalIsOpen}
        modalIsOpen={editModalIsOpen}
        modalWidth="50%"
        overflowX="auto"
      >
        <FormContainer>
          <Form
            ref={editFormRef}
            onSubmit={handleEditionSubmit}
            key={key}
            initialData={{
              edit_opportunity: editOpportunityItem,
              edit_collaborator: editCollaboratorItem,
              edit_category: editCategoryItem,
              edit_assignment: editData?.associateCollaboratorJobTitle,
              edit_credential_name: editData?.credentialName,
              edit_email: editData?.associateCollaboratorEmail,
              fast_track: editData?.associateCollaboratorFastTrack,
            }}
          >
            <TitleContainer>
              <Title>
                <FormattedMessage id="edit_association" />
              </Title>
            </TitleContainer>
            <FieldsContainer>
              <AdditionalInformationContainer>
                <InformationContainer>
                  <InformationLabel>
                    <FormattedMessage id="event_edition" />
                  </InformationLabel>
                  <InformationValue>
                    {editData?.eventName || "-"}
                  </InformationValue>
                </InformationContainer>
                <InformationContainer>
                  <InformationLabel>
                    <FormattedMessage id="type" />
                  </InformationLabel>
                  <InformationValue>
                    {editData?.userRole === "EXHIBITOR"
                      ? intl.formatMessage({ id: "exhibitor" })
                      : intl.formatMessage({ id: "service_provider" })}
                  </InformationValue>
                </InformationContainer>
                <InformationContainer>
                  <InformationLabel>
                    <FormattedMessage id="account" />
                  </InformationLabel>
                  <InformationValue>
                    {editData?.userName || "-"}
                  </InformationValue>
                </InformationContainer>
              </AdditionalInformationContainer>
              <SelectWithLabel
                name="edit_opportunity"
                placeholder={intl.formatMessage({
                  id: "contract",
                })}
                label={intl.formatMessage({
                  id: "contract",
                })}
                validationBlur={editValidationBlur}
                labelOrientation="horizontal"
                labelWidth="150px"
                options={editOpportunities}
                onChange={(opportunity) => {
                  setEditSelectedOpportunity(opportunity?.value)
                  setSearchCanBePreserved(false)
                  }
                }
                required
              />
              <SelectWithLabel
                name="edit_category"
                validationBlur={editValidationBlur}
                label={intl.formatMessage({ id: "credential_types" })}
                placeholder={intl.formatMessage({ id: "credential_types" })}
                onChange = {()=> setSearchCanBePreserved(false)}
                labelOrientation="horizontal"
                labelWidth="150px"
                options={editCategories}
                required
              />
              <SelectWithLabel
                name="edit_collaborator"
                placeholder={intl.formatMessage({
                  id: "collaborator",
                })}
                label={intl.formatMessage({
                  id: "collaborator",
                })}
                validationBlur={editValidationBlur}
                labelOrientation="horizontal"
                labelWidth="150px"
                options={editCollaborators}
                onChange={(collaborator) =>
                  setEditSelectedCollaborator(collaborator)
                }
                required
              />
              <InputWithHorizontalLabel
                name="edit_assignment"
                placeholder={intl.formatMessage({
                  id: "assignment",
                })}
                label={intl.formatMessage({
                  id: "assignment",
                })}
                validationBlur={editValidationBlur}
                labelWidth="150px"
                required
              />
              <InputWithHorizontalLabel
                name="edit_credential_name"
                placeholder={intl.formatMessage({
                  id: "credential_name",
                })}
                label={intl.formatMessage({
                  id: "credential_name",
                })}
                validationBlur={editValidationBlur}
                labelWidth="150px"
                required
              />
              <Toggle
                name="fast_track"
                label={intl.formatMessage({
                  id: "fast_track",
                })}
                onClick={() => setIsFastTrack(!isFastTrack)}
              />
              <InputWithHorizontalLabel
                name="edit_email"
                placeholder={intl.formatMessage({
                  id: "email",
                })}
                label={intl.formatMessage({
                  id: "email",
                })}
                validationBlur={editValidationBlur}
                labelWidth="150px"
              />
              <Horizontal>
                <FilterEditionContainer>
                  <SubmitContainer>
                    <FormButton
                      color="#C9C9C9"
                      type="button"
                      onClick={() => setEditModalIsOpen(false)}
                      loading={isLoading}
                    >
                      <FormattedMessage id="close" />
                    </FormButton>
                  </SubmitContainer>
                  <SubmitContainer>
                    <FormButton type="submit" loading={loading}>
                      <FormattedMessage id="save" />
                    </FormButton>
                  </SubmitContainer>
                </FilterEditionContainer>
              </Horizontal>
            </FieldsContainer>
          </Form>
        </FormContainer>
      </Modal>
    </AnimatedMainContainer>
  );
};

export default ManageCredentials;
