import { useIntl } from "react-intl";

const intl = useIntl


const productTypes = () => {
    
    const productTypesArray =[
        {
        label: intl().formatMessage({ id: "national" }),
        value: 0
        },
        {
        label: intl().formatMessage({ id: "international" }),
        value: 1
        },
        {
        label: intl().formatMessage({ id: "both" }),
        value: 2
        },
    ]
    return productTypesArray

}

export default productTypes