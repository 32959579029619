import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  height: 94vh;
  background: #fcfcfc;
  padding: 25px 75px 50px 70px;
  overflow-y: auto;

  ::-webkit-scrollbar {
    width: 4px;
    background-color: #fcfcfc;
  }

  ::-webkit-scrollbar-thumb {
    background: #11a7d9;
    -webkit-border-radius: 1ex;
    -webkit-box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.75);
  }

  ::-webkit-scrollbar-corner {
    background: #11a7d9;
  }
`;

export const Title = styled.h1`
  color: #444444;
  font-family: "Inter";
  font-size: 30px;
  font-style: normal;
  font-weight: 700;
  line-height: 100%;
  padding-bottom: 15px;
`;

export const SubTitle = styled.h1`
  color: #11a7d9;
  font-family: "Open Sans";
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 150%;
  border-bottom: 1px solid #d4d4d4;
  padding-bottom: 5px;
  width: 100%;
`;

export const Hr = styled.hr`
  border-radius: 30px;
  background: #11a7d9;
  width: 140px;
  height: 2px;
  margin-bottom: 24px;
  margin-top: -11.5px;
`;

export const Content = styled.div`
  margin-top: 15px;
  display: flex;
  justify-content: space-between;
`;
