import React, { useEffect, useMemo, useRef, useState } from "react";
import FormButton from "../FormButton";
import {
  ModalContainer,
  CheckboxDescription,
  Horizontal,
  FilterActionsContainer,
  SubmitContainer,
} from "./styles";
import CheckboxInput from "../CheckboxInput";
import { FormattedMessage, useIntl } from "react-intl";
import { Form } from "@unform/web";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
  },
  overlay: {
    zIndex: 999999,
    backgroundColor: "rgba(201, 201, 201, 0.5)",
  },
};

function ModalWithCheckbox({
  modalIsOpen,
  setIsOpen,
  confirmationFunction,
  cancelationFunction,
  confirmationText,
  cancelText,
  title,
  description,
  isLoading,
}) {
  const [isConfirmed, setIsConfirmed] = useState(false);
  const intl = useIntl();
  const formRef = useRef(null);

  const checkboxOptionsObject = useMemo(
    () => [
      {
        id: "modal_approval_checkbox_message",
        value: true,
        label: intl.formatMessage({
          id: "modal_approval_checkbox_message",
        })
      },
    ],
    [intl]
  );

  useEffect(() => {
    if (modalIsOpen) {
      setIsConfirmed(false);
    }
  }, [
    modalIsOpen,
  ]);

  function closeModal() {
    if (cancelationFunction) {
      cancelationFunction();
    }
    setIsOpen(false);
  }

  function handleConfirmation() {
    if (isLoading) {
      return;
    }
    confirmationFunction();
  }

  return (
    <ModalContainer
      isOpen={modalIsOpen}
      onRequestClose={closeModal}
      style={customStyles}
      contentLabel="Confirmation"
      className="Modal"
    >
      <h1>{title}</h1>
      <p>{description}</p>
      <Form ref={formRef} onSubmit={handleConfirmation}>
        <CheckboxDescription>
          <CheckboxInput
            name="checkbox"
            onChange={e => setIsConfirmed(e?.target?.checked)}
            options={checkboxOptionsObject}
          />
        </CheckboxDescription>
        <Horizontal>
          <FilterActionsContainer>
            <SubmitContainer>
              <FormButton
                color="#002244"
                type="submit"
                disabled={!isConfirmed}
              >
                {confirmationText}
              </FormButton>
            </SubmitContainer>
            {cancelText && (
              <SubmitContainer>
                <FormButton
                  color="#C9C9C9"
                  type="button"
                  onClick={() => {
                    closeModal();
                  }}
                >
                  {cancelText}
                </FormButton>
              </SubmitContainer>
            )}
          </FilterActionsContainer>
        </Horizontal>
      </Form>
    </ModalContainer>
  );
}

export default ModalWithCheckbox;
