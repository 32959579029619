import React from "react";

const Calendar = (props) => {
  const { size = "17", color = "#282828", filled = false } = props;

  if (filled) {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={size}
        height={size}
        viewBox="0 0 17 16"
        fill="none"
      >
        <path
          d="M13.1667 2.66699H3.83333C3.09695 2.66699 2.5 3.26395 2.5 4.00033V13.3337C2.5 14.07 3.09695 14.667 3.83333 14.667H13.1667C13.903 14.667 14.5 14.07 14.5 13.3337V4.00033C14.5 3.26395 13.903 2.66699 13.1667 2.66699Z"
          stroke="#282828"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M11.166 1.33301V3.99967"
          stroke="#282828"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M5.83398 1.33301V3.99967"
          stroke="#282828"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M2.5 6.66699H14.5"
          stroke="#282828"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M5.83398 9.33301H5.84065"
          stroke="#282828"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M8.5 9.33301H8.50667"
          stroke="#282828"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M11.166 9.33301H11.1727"
          stroke="#282828"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M5.83398 12H5.84065"
          stroke="#282828"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M8.5 12H8.50667"
          stroke="#282828"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M11.166 12H11.1727"
          stroke="#282828"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    );
  }

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 17 16"
      fill="none"
    >
      <path
        d="M13.1667 2.66699H3.83333C3.09695 2.66699 2.5 3.26395 2.5 4.00033V13.3337C2.5 14.07 3.09695 14.667 3.83333 14.667H13.1667C13.903 14.667 14.5 14.07 14.5 13.3337V4.00033C14.5 3.26395 13.903 2.66699 13.1667 2.66699Z"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.166 1.33301V3.99967"
        stroke={color}
        strokeLinecap="round"
        strokLinejoin="round"
      />
      <path
        d="M5.83398 1.33301V3.99967"
        stroke={color}
        strokeLinecap="round"
        strokLinejoin="round"
      />
      <path
        d="M2.5 6.66699H14.5"
        stroke={color}
        strokeLinecap="round"
        strokLinejoin="round"
      />
      <path
        d="M5.83398 9.33301H5.84065"
        stroke={color}
        strokeLinecap="round"
        strokLinejoin="round"
      />
      <path
        d="M8.5 9.33301H8.50667"
        stroke={color}
        strokeLinecap="round"
        strokLinejoin="round"
      />
      <path
        d="M11.166 9.33301H11.1727"
        stroke={color}
        strokeLinecap="round"
        strokLinejoin="round"
      />
      <path
        d="M5.83398 12H5.84065"
        stroke={color}
        strokeLinecap="round"
        strokLinejoin="round"
      />
      <path
        d="M8.5 12H8.50667"
        stroke={color}
        strokeLinecap="round"
        strokLinejoin="round"
      />
      <path
        d="M11.166 12H11.1727"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default Calendar;
