import React, { useCallback, useRef, useState } from "react";
import { useHistory } from "react-router-dom";

import { Form } from "@unform/web";
import * as Yup from "yup";
import getValidationErrors from "../../../utils/getValidationErrors";

import { useSelector } from "react-redux";

import { FormattedMessage, useIntl } from "react-intl";
import { validateSingleFieldOnBlur } from "../../../utils/formValidations";

import {
  ActionContainer,
  BadgeContainer,
  Container,
  FieldsContainer,
  Session,
  Title,
  TitleContainer,
} from "./styles";

import { useToast } from "../../../hooks/toast";
import api from "../../../service";
import AnimatedMainContainer from "../../../components/AnimatedMainContainer";
import InputWithLabel from "../../../components/InputWithLabel";
import Badge from "../../../components/Badge";

const CreateFilteredSalesforceEventEditions = () => {
  const formRef = useRef(null);
  const intl = useIntl();
  const { addToast } = useToast();
  const { token } = useSelector((state) => state.auth);

  const [loading, setLoading] = useState(false);

  const history = useHistory();

  const schema = Yup.object().shape({
    event_edition_code: Yup.string().required(
      intl.formatMessage({ id: "error.field_required" })
    ),
    event_edition_name: Yup.string(),
  });

  const handleSubmit = useCallback(
    async (data) => {
      try {
        formRef.current?.setErrors({});

        setLoading(true);
        if (loading) {
          return;
        }

        await schema.validate(data, { abortEarly: false });

        api
          .post(
            "/salesforce-event-editions",
            {
              eventEditionCode: data.event_edition_code,
              eventEditionName: data.event_edition_name,
            },
            {
              headers: {
                "Content-Type": "application/json",
                Accept: "*/*",
                Authorization: `Bearer ${token}`,
              },
            }
          )
          .then(() => {
            setLoading(false);
            addToast({
              type: "success",
              title: intl.formatMessage({
                id: "success",
              }),
              description: intl.formatMessage({
                id: "success.salesforce_event_edition_added",
              }),
            });
            pushBack();
          })
          .catch((err) => {
            setLoading(false);
            addToast({
              type: "error",
              title: intl.formatMessage({
                id: "error",
              }),
              description: intl.formatMessage({
                id: "api.error." + err.response.data.code,
              }),
            });
          });
      } catch (err) {
        setLoading(false);
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);

          formRef.current?.setErrors(errors);

          return;
        }
      }
    },
    [addToast, history, intl, schema, token, loading]
  );

  const validationBlur = (name) => {
    let errors = formRef.current.getErrors();

    validateSingleFieldOnBlur(errors, schema, name, formRef);
  };

  const pushBack = () => {
    history.push("/manage-salesforce-event-editions");
  };

  return (
    <AnimatedMainContainer
      breadcrumb={intl.formatMessage({
        id: "breadcrumb.add_salesforce_integration_event_edition"
      })}
    >
      <Container>
        <Form ref={formRef} onSubmit={handleSubmit}>
          <TitleContainer>
            <Title>
              <FormattedMessage id="add_event_edition" />
            </Title>
            <ActionContainer>
              <BadgeContainer onClick={() => pushBack()}>
                <Badge
                  color="#C9C9C9"
                  text={intl.formatMessage({ id: "goback" })}
                  fontSize="12px"
                />
              </BadgeContainer>
              <BadgeContainer onClick={() => formRef.current.submitForm()}>
                <Badge
                  color="#002244"
                  text={intl.formatMessage({ id: "save" })}
                  fontSize="12px"
                  loading={loading}
                />
              </BadgeContainer>
            </ActionContainer>
          </TitleContainer>
          <Session>
            <FieldsContainer>
              <InputWithLabel
                name="event_edition_code"
                validationBlur={validationBlur}
                placeholder={intl.formatMessage({
                  id: "edition",
                })}
                label={intl.formatMessage({
                  id: "edition",
                })}
                labelWidth="150px"
                required
              />
              <InputWithLabel
                name="event_edition_name"
                validationBlur={validationBlur}
                label={intl.formatMessage({
                  id: "name",
                })}
                placeholder={intl.formatMessage({
                  id: "name",
                })}
                labelWidth="150px"
              />
            </FieldsContainer>
          </Session>
        </Form>
      </Container>
    </AnimatedMainContainer>
  );
};

export default CreateFilteredSalesforceEventEditions;
