import styled, { css } from "styled-components";
import { Input } from "antd";

export const Container = styled.div`
  width: 100%;
  height: 94vh;
  background: #fcfcfc;
  padding: 25px 75px 50px 70px;
  overflow-y: auto;

  ::-webkit-scrollbar {
    width: 4px;
    background-color: #fcfcfc;
  }

  ::-webkit-scrollbar-thumb {
    background: #11a7d9;
    -webkit-border-radius: 1ex;
    -webkit-box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.75);
  }

  ::-webkit-scrollbar-corner {
    background: #11a7d9;
  }
`;

export const Title = styled.h1`
  color: #11a7d9;
  font-family: "Inter";
  font-size: 30px;
  font-style: normal;
  font-weight: 700;
  line-height: 100%;
  border-bottom: 1px solid #d4d4d4;
  padding-bottom: 15px;
`;

export const Hr = styled.hr`
  border-radius: 30px;
  background: #11a7d9;
  width: 316px;
  height: 2px;
  margin-bottom: 24px;
  margin-top: -19.5px;
`;

export const Content = styled.div`
  width: 500px;
`;

export const ContainerButton = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-items: center;
  justify-content: center;
  padding: 12px 0;
  gap: 6px;
  flex-shrink: 0;
`;

export const Button = styled.button`
  height: 30px;
  padding: 7.5px 12px;
  font-family: "Inter";
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 15px;
  flex-shrink: 0;
  cursor: pointer;

  ${(props) =>
    props.color
      ? css`
          background: ${props.color};
          color: #11a7d9;
          border: none;
        `
      : css`
          background: #11a7d9;
          border: solid 1px #11a7d9;
          color: #fff;
          box-shadow: 0px 0.75px 1.5px 0px rgba(16, 24, 40, 0.05);
          border-radius: 10px;
        `}
`;

export const Icon = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const SwitchContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  gap: 15px;
  margin-bottom: 20px;

  p {
    color: #6c6c6c;
    font-family: "Open Sans";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    margin: 0;
  }
`;

export const Label = styled.div`
  color: #444;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  margin-bottom: 10px;
  display: flex;
  align-items: center;

  span {
    display: flex;
    align-items: center;
    margin-left: 5px;
  }
`;
