import React from "react";
import ReactExport from "react-data-export";

const File = ReactExport.ExcelFile;
const Sheet = ReactExport.ExcelFile.ExcelSheet;
const Column = ReactExport.ExcelFile.ExcelColumn;

const Report = ({
  data,
  filename,
  intl,
  provisionTypesArray,
  children
}) => (
  <File element={children} filename={filename} fileExtension="xlsx">
    <Sheet data={data} name="Relatório">
      <Column label="tipo" value="type" />
      <Column label="razao_social" value="accountName" />
      <Column label="documento" value="document" />
      <Column label="inscricao_estadual" value="stateInscription" />
      <Column label="inscricao_municipal" value="municipalInscription" />
      <Column
        label="classificao_fiscal"
        value={(col) =>
          intl.formatMessage({ id: col.taxClassification })
        }
      />
      <Column label="país" value="country" />
      <Column label="rua" value="street" />
      <Column label="numero" value="number" />
      <Column label="cep" value="zipCode" />
      <Column label="bairro" value="district" />
      <Column label="cidade" value="city" />
      <Column label="estado" value="state" />
      <Column label="telefone" value="telephone" />
      <Column label="celular" value="mobile" />
      <Column
        label="tipos_prestacao"
        value={(col) =>
          col.provisionTypes?.map((type) => {
            return provisionTypesArray.filter((pt) => pt.value === type)[0]?.label
          }).join(", ")
        }
      />
    </Sheet>
  </File>
);

export default Report;
