import React from "react";

const UserFocus = (props) => {
  const { size = "17", color = "#444444" } = props;

  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 17 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_347_57346)">
        <path
          d="M11.75 2.5H13.5C13.6326 2.5 13.7598 2.55268 13.8536 2.64645C13.9473 2.74021 14 2.86739 14 3V4.75"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M11.75 13.5H13.5C13.6326 13.5 13.7598 13.4473 13.8536 13.3536C13.9473 13.2598 14 13.1326 14 13V11.25"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M5.25 13.5H3.5C3.36739 13.5 3.24021 13.4473 3.14645 13.3536C3.05268 13.2598 3 13.1326 3 13V11.25"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M5.25 2.5H3.5C3.36739 2.5 3.24021 2.55268 3.14645 2.64645C3.05268 2.74021 3 2.86739 3 3V4.75"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M8.5 9C9.60457 9 10.5 8.10457 10.5 7C10.5 5.89543 9.60457 5 8.5 5C7.39543 5 6.5 5.89543 6.5 7C6.5 8.10457 7.39543 9 8.5 9Z"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M5.5 10.5C5.8493 10.0343 6.30224 9.65625 6.82295 9.3959C7.34366 9.13554 7.91783 9 8.5 9C9.08217 9 9.65634 9.13554 10.1771 9.3959C10.6978 9.65625 11.1507 10.0343 11.5 10.5"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_347_57346">
          <rect
            width={size}
            height={size}
            fill="white"
            transform="translate(0.5)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default UserFocus;
