import styled from "styled-components";
import SelectWithLabel from "../../components/SelectWithLabel";
import { FaCheck } from "react-icons/fa";
import { MdClose } from "react-icons/md";


export const ScreenContainer = styled.div`
    background-image: linear-gradient(to right, rgb(0,34,68), rgb(33,136,96), rgb(44,202,91));
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    border: solid 1px gray;
    border-radius: 7.5px;
    padding: 10px;
`

export const TitleAndChecklistContainer = styled.div`
    min-height: 400px;
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
`

export const Title = styled.h1`
    font-family: 'Basier Circle', sans-serif;
    font-size: 36px;
    line-height: 36px;
    letter-spacing: -0.2px;
    color: white;
    padding-left: 10px;
    margin-bottom: 10px;

    @media (max-width: 1415px) {
        margin-bottom: 0;
    }

    @media (max-width: 515px) {
        font-size: 26px;
        line-height: 26px;
    }
`
export const TitleContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    width: 80%;
    align-self: center;

    @media (max-width: 1415px) {
        padding-bottom: 15px;
        flex-direction: column;
        align-items: center;
        height: 115px;
    }

     @media (max-width: 515px) {
        width: 100%;
     }
`
export const ErrorMessage = styled.h1`
    font-family: 'Basier Circle', sans-serif;
    font-size: 36px;
    line-height: 36px;
    letter-spacing: -0.2px;
    color: white;
    padding-top: 15px;

    @media (max-width: 515px) {
        font-size: 26px;
        line-height: 26px;
    }
`

export const ErrorContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    height: 400px;
    width: 1100px;

    @media (max-width: 750px) {
        width: auto;
        flex-direction: column;
        text-align: center;
    }
`

export const StyledSelectWithLabel = styled(SelectWithLabel)`
    width: 950px;
    padding-left: 25px;
    align-self: center;
    
    @media (max-width: 1415px) {
        width: 600px;
    }
    
    @media (max-width: 1100px) {
        width: 475px;
    }

    @media (max-width: 750px) {
        width: 300px;
    }

    @media (max-width: 515px) {
        width: 275px;
        padding: 0 10px;
    }
`

export const ChecklistContainer = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    width: 100%;

    @media (max-width: 1415px) {
        grid-template-columns: 1fr 1fr 1fr;
    }

    @media (max-width: 1100px) {
        grid-template-columns: 1fr 1fr;
    }

    @media (max-width: 750px) {
        grid-template-columns: 1fr;
    }
`

export const ChecklistStepContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    border: solid 0.25px gray;
    border-color: white;
    border-radius: 5px;
    margin: 10px;
    padding: 10px;
    cursor: pointer;
    min-height: 250px;

    &:hover,
    &:focus {
        box-shadow: 0 0.5em 0.5em -0.4em var(--hover);
        transform: translateY(-0.25em);
        transition: transform 0.3s;
    }
`
export const ModalStepContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-height: 75vh;
    height: fit-content;
`

export const CheckContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin: 65px 0;
    height: 70px;
`

export const CheckButton = styled.button`
    background-color: rgba(0, 0, 0, 0);
    border-style: none;
    &:hover {
        cursor: pointer;
    }
`

export const InfoContainer = styled.div`
    width: 90%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
`

export const ModalInfoContainer = styled.div`
    width: 100%;
    min-height: 250px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 0 10px;
`


export const StepDescription = styled.p`
    padding: 3px 3px 0 0;
    font-family: 'Basier Circle', sans-serif;
    font-size: 36px;
    line-height: 36px;
    letter-spacing: -0.2px;
    font-weight: bold;
    color: white;
    margin-right: 10px;

    @media (max-width: 515px) {
        font-size: 26px;
        line-height: 26px;
    }
`

export const CheckDescription = styled.p`
    padding: 3px 3px 0 0;
    font-family: 'Basier Circle', sans-serif;
    font-size: 36px;
    line-height: 36px;
    letter-spacing: -0.2px;
    font-weight: bold;
    color: white;
    margin-bottom: 30px;

    @media (max-width: 515px) {
        font-size: 26px;
        line-height: 26px;
    }
`

export const StepAdditionalInfo = styled.p`
    margin-bottom: 30px;
    font-family: 'Basier Circle', sans-serif;
    color: white;
    font-size: 26px;
    line-height: 34px;
    font-weight: 400;
    text-align: justify;

    @media (max-width: 515px) {
        font-size: 22px;
        line-height: 22px;
    }
`

export const StepExpiresDate = styled.p`
    margin-bottom: 30px;
    font-family: 'Basier Circle', sans-serif;
    color: white;
    font-size: 22px;
    line-height: 22px;
    font-weight: 400;
    text-align: right;

    @media (max-width: 515px) {
        font-size: 18px;
        line-height: 18px;
    }
`

export const StepHowToDo = styled.a`
    margin-bottom: 30px;
    color: white;
    font-size: 22px;
    font-style: italic;
    line-height: 22px;
    text-decoration: none;
    text-align: justify;

    @media (max-width: 515px) {
        font-size: 18px;
        line-height: 18px;
    }
`
export const EventContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    width: 100%;
    align-items: center;
    margin-bottom: 20px;

    @media (max-width: 575px) {
        flex-direction: column;
    }
`

export const CheckIcon = styled(FaCheck)`
    align-self: flex-end;
`

export const CheckIconModal = styled(FaCheck)`
    border: 7px solid white;
    border-radius: 5px;
    padding: 1.5px;
`

export const CloseButtonContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 35px;
`

export const CloseButton = styled(MdClose)`
    &:hover {
        cursor: pointer;
    }
`