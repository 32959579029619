import React from "react";
import {
    Container,
    Filler,
    Label
} from "./styles"

const ProgressBar = (props) => {
  const { completed } = props;

  return (
    <Container>
      <Filler completed = {completed}>
        <Label >{`${completed}`}</Label>
      </Filler>
    </Container>
  );
};

export default ProgressBar;
