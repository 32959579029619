import React, { useEffect, useRef, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useSelector } from "react-redux";
import { useToast } from "../../../hooks/toast";
import * as Yup from "yup";
import getValidationErrors from "../../../utils/getValidationErrors";
import api from "../../../service";
import { useCallback } from "react";
import InputWithLabel from "../../../components/InputWithLabel";
import { Form } from "@unform/web";
import { validateSingleFieldOnBlur } from "../../../utils/formValidations";

import {
  FormContainer,
  TitleContainer,
  Title,
  FieldsContainer,
  Horizontal,
  FilterActionsContainer,
  SubmitContainer,
  Toggle,
} from "./styles";
import SelectWithLabel from "../../../components/SelectWithLabel";
import Modal from "../../../components/Modal";
import Badge from "../../../components/Badge";
import FormButton from "../../../components/FormButton";
import { useLoading } from "../../../hooks/loading";

const Users = ({ user, setIsOpen, modalIsOpen, updateTable }) => {
  const { token } = useSelector((state) => state.auth);
  const [profilesSelect, setProfilesSelect] = useState([]);
  const [key, setKey] = useState();
  const formRef = useRef(null);
  const intl = useIntl();
  const { addToast } = useToast();
  const { handleLoading } = useLoading();
  const [loading, setLoading] = useState(false);

  const schema = Yup.object().shape({
    profile: Yup.string().nullable(),
    email: Yup.string()
      .email(intl.formatMessage({ id: "error.valid_email" }))
      .nullable(),
    status: Yup.string().nullable(),
  });

  useEffect(() => {
    if (user) {
      handleLoading(true);
      if (loading) {
        return;
      }
      api
        .patch(
          "/role",
          {
            name: "",
            active: true,
            type: user?.roleType,
          },
          {
            headers: {
              "Content-Type": "application/json",
              Accept: "*/*",
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((result) => {
          setProfilesSelect(
            result.data.roles.map((role) => {
              return { label: role.name, value: role.id };
            })
          );
          setKey(new Date());
        })
        .catch((err) => {
          addToast({
            type: "error",
            title: intl.formatMessage({
              id: "error",
            }),
            description: intl.formatMessage({
              id: "api.error." + err.response.data.code,
            }),
          });
        })
        .finally(() => {
          handleLoading(false);
        });
    }
  }, [user, addToast, intl, token, handleLoading, loading]);

  const handleSubmit = useCallback(
    async (data) => {
      try {
        formRef.current?.setErrors({});

        setLoading(true);
        if (loading) {
          return;
        }

        await schema.validate(data, { abortEarly: false });

        api
          .put(
            "/users",
            {
              id: user?.id,
              email: data.email,
              active: data.status,
              roleId: data.profile,
            },
            {
              headers: {
                "Content-Type": "application/json",
                Accept: "*/*",
                Authorization: `Bearer ${token}`,
              },
            }
          )
          .then((_) => {
            addToast({
              type: "success",
              title: intl.formatMessage({
                id: "success",
              }),
              description: intl.formatMessage({
                id: "success.user_updated",
              }),
            });
            setIsOpen(false);
            updateTable();
            setLoading(false);
          })
          .catch((err) => {
            setLoading(false);
            addToast({
              type: "error",
              title: intl.formatMessage({
                id: "error",
              }),
              description: intl.formatMessage({
                id: "api.error." + err.response.data.code,
              }),
            });
          });
      } catch (err) {
        setLoading(false);
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);

          formRef.current?.setErrors(errors);

          return;
        }
      }
    },
    [user, addToast, intl, schema, setIsOpen, token, updateTable, loading]
  );

  const validationBlur = (name) => {
    let errors = formRef.current.getErrors();

    validateSingleFieldOnBlur(errors, schema, name, formRef);
  };

  return (
    <Modal setIsOpen={setIsOpen} modalIsOpen={modalIsOpen} modalWidth="50%">
      <FormContainer>
        <Form
          ref={formRef}
          onSubmit={handleSubmit}
          key={key}
          initialData={{
            email: user?.email,
            status: user?.status === "ACTIVATED" ? true : false,
            profile: {
              label: profilesSelect.filter(
                (field) => field.label === user?.currentRoleName
              )[0]?.label,
              value: profilesSelect.filter(
                (field) => field.label === user?.currentRoleName
              )[0]?.value,
            },
          }}
        >
          <TitleContainer>
            <Title>Status:</Title>
            {user?.status === "ACTIVATED" ? (
              <Badge
                color="#33d27e"
                text={intl.formatMessage({ id: "active" })}
                fontSize="12px"
              />
            ) : (
              <Badge
                color="#C9C9C9"
                text={intl.formatMessage({ id: "inactive" })}
                fontSize="12px"
              />
            )}
          </TitleContainer>
          <FieldsContainer>
            <InputWithLabel
              name="email"
              validationBlur={validationBlur}
              label="E-mail"
              disabled
            />

            <SelectWithLabel
              name="profile"
              options={profilesSelect}
              validationBlur={validationBlur}
              label={intl.formatMessage({ id: "profile" })}
            />
            <Toggle name="status" label="Status" />
            <Horizontal>
              <FilterActionsContainer>
                <SubmitContainer>
                  <FormButton
                    color="#C9C9C9"
                    type="button"
                    onClick={() => setIsOpen(false)}
                  >
                    <FormattedMessage id="close" />
                  </FormButton>
                </SubmitContainer>
                <SubmitContainer>
                  <FormButton type="submit" loading={loading}>
                    <FormattedMessage id="save" />
                  </FormButton>
                </SubmitContainer>
              </FilterActionsContainer>
            </Horizontal>
          </FieldsContainer>
        </Form>
      </FormContainer>
    </Modal>
  );
};

export default Users;
