import React, { useEffect, useRef, useState, useMemo } from "react";
import { useTransition, useChain } from "react-spring";
import { MdEdit, MdPhone } from "react-icons/md";
import { FaWhatsapp } from "react-icons/fa";

import {
  Container,
  LinkMenuContainer,
  TitleSection,
  LinkMenuText,
  InformaLogoText,
  InfoContainer,
  InfoText,
  PhoneContainer,
  WhatsappContainer,
  FlatButton,
  InformaLogoTextContainer,
  Content,
} from "./styles";

import informaLogoText from "../../../assets/images/Informa_Logo_Texto.png";
import api from "../../../service";
import { useSelector } from "react-redux";
import { FormattedMessage, useIntl } from "react-intl";
import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { useToast } from "../../../hooks/toast";

const Menus = ({ data, title, setSelectedId, selected }) => {
  const sidebarRef = useRef();
  const { token, permissionCategories, user } = useSelector((state) => state.auth);
  const [userCanEdit, setUserCanEdit] = useState(false);
  const [hasOpeningHoursText, setHasOpeningHoursText] = useState(false);
  const intl = useIntl();
  const { addToast } = useToast();

  const [contactInformation, setContactInformation] = useState({
    whatsapp: "",
    telephone: "",
    openingHoursPt: "",
    openingHoursEn: "",
  });

  const location = useLocation();

  const selectedSubitemFullPath = location.pathname;

  const transition = useTransition(selected ? data : [], (item) => item.id, {
    from: {
      transform: "translateX(-300px)",
    },
    enter: {
      transform: "translateX(0px)",
    },
    leave: {
      transform: "translateY(-350px)",
    },
    unique: true,
    ref: sidebarRef,
  });

  const itemsRef = useRef();

  const trail = useTransition(selected ? data.subitem : [], (item) => item.id, {
    from: {
      opacity: 0,
    },
    enter: {
      opacity: 1,
    },
    leave: {
      opacity: 0,
    },
    ref: itemsRef,
    trail: 100,
    unique: true,
  });

  const isExhibitor = user?.role?.type === "EXHIBITOR";
  const isServiceProvider = user?.role?.type === "SERVICE_PROVIDER";

  const isPortuguese = useMemo(()=> intl.locale === 'pt', [intl])

  useChain(
    selected ? [sidebarRef, itemsRef] : [itemsRef, sidebarRef],
    selected ? [0, 0.25] : [0, 0.4]
  );

  useEffect(() => {
    if (data.id === "CONTACT") {
      const category = permissionCategories.find(
        (pc) => pc.code === "CONTACT"
      );
      if (category) {
        setUserCanEdit(
          category.permissions.find(
            (sub) => sub.code === "EDIT_CONTACT_INFORMATION"
          )
        );
      }
      api
        .get("/system/contact-information", {
          headers: {
            "Content-Type": "application/json",
            Accept: "*/*",
            Authorization: `Bearer ${token}`,
          },
        })
        .then((result) => {
          const contactData = result.data.contactOutput;
          setContactInformation({
            whatsapp: contactData.whatsapp,
            telephone: contactData.telephone,
            openingHoursPt: contactData.openingHoursPt,
            openingHoursEn: contactData.openingHoursEn,
          });
          setHasOpeningHoursText(
            contactData.openingHoursPt
            && contactData.openingHoursEn
          );
        })
        .catch((err) => {
          addToast({
            type: "error",
            title: intl.formatMessage({
              id: "error",
            }),
            description: intl.formatMessage({
              id: "api.error." + err.response.data.code,
            }),
          });
        });
    }
  }, [data.id, permissionCategories, token, addToast, intl]);

  const handleClickFunction = (item, setSelectedId) => {
    if (item.clickFunction) {
      item.clickFunction();
      setSelectedId(0);
    }
  };

  return transition.map(({ item, key, props }) =>
    item ? (
      <Container key={key} style={props}>
        <InformaLogoTextContainer>
          <InformaLogoText src={informaLogoText} />
        </InformaLogoTextContainer>
        <Content>
          <TitleSection>{title}</TitleSection>
          {trail.map(({ item, props }) => (
            <React.Fragment key={item.id}>
              {(item.icon) && (
                <LinkMenuContainer
                  style={props}
                  onClick={() => handleClickFunction(item, setSelectedId)}
                  active={Number(
                    !!item?.routeName?.includes(selectedSubitemFullPath)
                )}
                >
                  {item.icon}
                  <LinkMenuText>{item.name}</LinkMenuText>
                </LinkMenuContainer>
              )}
              {item.id === "info-item" && (
                <InfoContainer>
                  <InfoText>
                    <FormattedMessage id="contact_us_by_phone" />
                  </InfoText>
                  <PhoneContainer>
                    <MdPhone size={18} color="#002244" />
                    <InfoText>{contactInformation.telephone}</InfoText>
                  </PhoneContainer>
                  <WhatsappContainer>
                    <InfoText>
                      <FormattedMessage id="or_directly_via_whatsapp" />:
                    </InfoText>
                    <a
                      href={contactInformation.whatsapp}
                      rel="noreferrer"
                      target="_blank"
                    >
                      <FlatButton>
                        <FaWhatsapp size={18} color="#002244" />
                        WhatsApp
                      </FlatButton>
                    </a>
                    {userCanEdit && (
                      <Link to="/contact" onClick={() => setSelectedId(0)}>
                        <FlatButton>
                          <MdEdit size={18} color="#002244" />
                          <FormattedMessage id="edit" />
                        </FlatButton>
                      </Link>
                    )}
                  </WhatsappContainer>
                  {(isExhibitor || isServiceProvider)
                    && hasOpeningHoursText && (
                    <>
                      <PhoneContainer>
                        <InfoText>
                          <FormattedMessage id="opening_hours" />
                        </InfoText>
                      </PhoneContainer>
                      <WhatsappContainer>
                        <InfoText>
                          {isPortuguese
                            ? contactInformation.openingHoursPt
                            : contactInformation.openingHoursEn
                          }
                        </InfoText>
                      </WhatsappContainer>
                    </>
                  )}
                </InfoContainer>
              )}
            </React.Fragment>
          ))}
        </Content>
      </Container>
    ) : null
  );
};

export default Menus;
