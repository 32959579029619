import React, { useRef, useEffect, useState, useCallback } from "react";

import { useSelector, useDispatch } from "react-redux";

import useNotification from "../../hooks/newUXNotification";
import { useLoading } from "../../hooks/loading";

import { getAllEvent } from "../../redux/modules/event/actions";
import { getAllContractByEvent } from "../../redux/modules/contract/actions";
import { addInitialSettingsSuccess } from "../../redux/modules/initialSettings/actions";
import { getChecklist } from "../../redux/modules/checklist/actions";

import CustomModal from "../../components/CustomModal";
import Select from "../Select";
import Button from "../Button";

import { useIntl } from "react-intl";

import { Form } from "antd";
import { INITIAL_VALUES, validation } from "./validations";

import {
  Container,
  Header,
  Title,
  Hr,
  FormContainer,
  ButtonContainer,
} from "./styles";

const ModalSettings = ({ open, handleClose, id }) => {
  const intl = useIntl();
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const { handleLoading } = useLoading();
  const notification = useNotification();

  const { allEvent } = useSelector((state) => state.event);
  const { allContractByEvent } = useSelector((state) => state.contract);
  const { isEmpty } = useSelector((state) => state.checklist);

  const [isLoading, setIsLoading] = useState(false);
  const [eventIdExists, setEventIdExists] = useState(false);
  const [selectedEvent, setSelectedEvent] = useState({});
  const [selectedContract, setSelectedContract] = useState({});

  const callBackMessage = useCallback(
    (message) => {
      handleLoading(false);
      setIsLoading(false)
      notification.displayError(
        intl.formatMessage({
          id: "api.error." + message,
        }),
        "error-get-checklist"
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [intl]
  );

  const callBackEmptyChecklist = ()=>{
    if (isEmpty)
      notification.displayInfo(
        intl.formatMessage({ id: "checklist.empty_message" }),
        "empty-checklist-message"
      );
  }

  const callBackSuccessChecklist = ()=>{
    handleLoading(false)
  }

  const handleSubmit = () => {
    const formData = {
      event: selectedEvent,
      contract: selectedContract
    }
    handleLoading(true);

    dispatch(addInitialSettingsSuccess(formData));
    dispatch(
      getChecklist({
        selectedEvent: formData.event.value,
        callbackSuccess: callBackSuccessChecklist,
        callbackError: callBackMessage,
        callbackEmpty: callBackEmptyChecklist
      })
    );

    handleClose();
  };

  const callbackSuccess = () =>{
    setIsLoading(false)
  }

  const handleContractId = (selectedEvent) => {
    setSelectedEvent(selectedEvent);
    let eventId = selectedEvent?.value;

    if (eventId) {
      form.setFieldValue("contract", "");
      setIsLoading(true)
      dispatch(getAllContractByEvent(eventId, callBackMessage, callbackSuccess));
      setEventIdExists(true);
    }
  };

  useEffect(() => {
    dispatch(getAllEvent());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <CustomModal id={id} isModalVisible={open} handleClose={handleClose}>
      <Container>
        <Header>
          <Title>{intl.formatMessage({ id: "select_event_and_contract" })}</Title>
        </Header>
        <Hr />
        <FormContainer>
          <Form
            layout="vertical"
            form={form}
            name="modal-settings"
            onFinish={handleSubmit}
            initialValues={INITIAL_VALUES}
          >
            <Select
              name="event"
              label={intl.formatMessage({ id: "event" })}
              placeholder={intl.formatMessage({ id: "select" })}
              options={allEvent}
              onChange={(eventId, selectedEvent) =>
                handleContractId(selectedEvent)
              }
              rules={validation("event", intl)}
            />
            <Select
              name="contract"
              label={intl.formatMessage({ id: "active_contracts" })}
              placeholder={intl.formatMessage({ id: "select" })}
              options={allContractByEvent}
              rules={validation("contract", intl)}
              disabled={!eventIdExists || isLoading}
              onChange={(contractId, selectedContract) =>
                setSelectedContract(selectedContract)
              }
              isLoading={isLoading}
            />
            <ButtonContainer>
              <Button
                type="primary"
                label={intl.formatMessage({ id: "confirm" })}
                htmlType="submit"
              />
              <Button
                type="link"
                label={intl.formatMessage({ id: "cancel" })}
                onClick={() => handleClose()}
              />
            </ButtonContainer>
          </Form>
        </FormContainer>
      </Container>
    </CustomModal>
  );
};

export default ModalSettings;
