import React, { useMemo, useState } from "react";
import { useIntl } from "react-intl";

import Right from "../../newUXAssets/icons/Right";
import Down from "../../newUXAssets/icons/Down";

import {
  Container,
  Header,
  Text,
  Seach,
  CustomTable,
  InputSearch,
  Empty,
} from "./styles";

const Table = ({
  dataSource,
  title,
  height = 368,
  tablePagination = {},
  handleGetInfoTable = () => {},
  isDisabledSeach = false,
  haveExpandable = false,
  notEven = false,
  disabledPagination = false,
  expandedRowRender = () => {},
  ...props
}) => {
  const intl = useIntl();

  const [expandedKey, setExpandedKey] = useState([]);
  const [searchValue, setSearchValue] = useState('')

  const handleSetValueSearch = (value) => {
    setTimeout(() => {
      handleGetInfoTable(0, 10, value);
    }, 1000);
    setSearchValue(value)
  };

  const optionsPagination = useMemo(() => {
    if (disabledPagination) {
      return false;
    }

    return {
      simple: true,
      position: ["bottomCenter"],
      ...tablePagination,
      onChange: (page, pageSize) => {
        handleGetInfoTable(page - 1, pageSize, searchValue);
      },
    };
  }, [disabledPagination, tablePagination, handleGetInfoTable]);

  const otherProps = useMemo(() => {
    if (haveExpandable) {
      return {
        ...props,
        expandable: {
          expandedRowRender,
          expandedRowKeys: expandedKey,
          expandIcon: ({ expanded, onExpand, record }) =>
            expanded ? (
              <Down
                onClick={(e) => {
                  onExpand(record, e);
                  setExpandedKey([]);
                }}
              />
            ) : (
              <Right
                onClick={(e) => {
                  onExpand(record, e);
                  setExpandedKey([record.key]);
                }}
              />
            ),
        },
      };
    }

    return props;
  }, [props, haveExpandable, expandedRowRender]);

  return (
    <Container $notEven={notEven}>
      {(title || !isDisabledSeach) && (
        <Header>
          <Text>{title}</Text>
          {!isDisabledSeach && (
            <Seach>
              <InputSearch
                name="search-table"
                type="seach"
                placeholder={intl.formatMessage({ id: "search" })}
                onChange={({ target }) => handleSetValueSearch(target.value)}
              />
            </Seach>
          )}
        </Header>
      )}
      <CustomTable
        dataSource={dataSource}
        $isEmpty={dataSource?.length === 0}
        $notEven={notEven}
        locale={{
          emptyText: (
            <Empty>{intl.formatMessage({ id: "no_results_to_display" })}</Empty>
          ),
        }}
        scroll={{
          x: "100%",
          y: height,
        }}
        pagination={optionsPagination}
        {...otherProps}
      />
    </Container>
  );
};

export default Table;
