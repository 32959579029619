import React, { useCallback, useEffect, useMemo } from "react";

import { useSelector, useDispatch } from "react-redux";

import { useIntl } from "react-intl";

import { getProfileExhibitor } from "../../../redux/modules/profile/actions";

import useNotification from "../../../hooks/newUXNotification";

import { getFullTelephoneNumber } from "../../../components/TelephoneInputWithLabel";

import Button from "../../../newUXComponents/Button";
import Edit from "../../../newUXAssets/icons/Edit";

import {
  Container,
  Content,
  Information,
  Label,
  Description,
  BtnEdit,
} from "./styles";

const ViewInformation = ({ onClickEdit }) => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const notification = useNotification();

  const { exhibitor } = useSelector((state) => state.profile);
  const { contract, event } = useSelector((state) => state.initialSettings);
  const { permission } = useSelector((state) => state.auth);

  const isContractSelected = useMemo(() => {
    return !(
      !contract ||
      contract.length === 0 ||
      JSON.stringify(contract) === "{}"
    );
  }, [contract]);

  const dispatchInfoMessage = (message) => {
    notification.displayInfo(
      intl.formatMessage({ id: message }),
      "event-select"
    );
  };

  const callBackError = useCallback(
    (message) => {
      notification.displayError(
        intl.formatMessage({
          id: "api.error." + message,
        }),
        "get-exhibitor-error"
      );
    },
    [notification, intl]
  );

  useEffect(() => {
    if (!isContractSelected) {
      dispatchInfoMessage("error.contract_not_selected");
    } else {
      dispatch(getProfileExhibitor(event.value, contract.value, callBackError));
      notification.close("event-not-selected-message");
    }
  }, [isContractSelected]);

  return (
    <>
      <Container>
        <Content>
          <Information>
            <Label>{intl.formatMessage({ id: "company_name_credential" })}</Label>
            <Description>{exhibitor.credentialName || " - "}</Description>
          </Information>
          <Information>
            <Label>{intl.formatMessage({ id: "operational_email" })}</Label>
            <Description>{exhibitor.operationsContactEmail || " - "}</Description>
          </Information>
        </Content>

        <Content>
          <Information>
            <Label>{intl.formatMessage({ id: "company_name" })}</Label>
            <Description>
              {exhibitor.companyName || exhibitor.name || " - "}
            </Description>
          </Information>
          <Information>
            <Label>{intl.formatMessage({ id: "address" })}</Label>
            <Description>{exhibitor.avenue || " - "}</Description>
          </Information>
        </Content>

        <Content>
          <Information>
            <Label>{intl.formatMessage({ id: "commercial_name" })}</Label>
            <Description>{exhibitor.accountAlias || " - "}</Description>
          </Information>
          <Information>
            <Label>{intl.formatMessage({ id: "address_number" })}</Label>
            <Description>{exhibitor.number || " - "}</Description>
          </Information>
        </Content>

        <Content>
          <Information>
            <Label>{intl.formatMessage({ id: "id_number_cnpj" })}</Label>
            <Description>{exhibitor.cnpj || " - "}</Description>
          </Information>
          <Information>
            <Label>{intl.formatMessage({ id: "city" })}</Label>
            <Description>{exhibitor.city || " - "}</Description>
          </Information>
        </Content>

        <Content>
          <Information>
            <Label>{intl.formatMessage({ id: "classification" })}</Label>
            <Description>{exhibitor.nationalClassification}</Description>
          </Information>
          <Information>
            <Label>{intl.formatMessage({ id: "state" })}</Label>
            <Description>{exhibitor.state || " - "}</Description>
          </Information>
        </Content>
        <Content>
          <Information>
            <Label>
              {intl.formatMessage({ id: "responsible_for_the_invoice" })}
            </Label>
            <Description>{exhibitor.billingContact || " - "}</Description>
          </Information>
          <Information>
            <Label>{intl.formatMessage({ id: "address_complement" })}</Label>
            <Description>{" - "}</Description>
            {/* Campo não existe no response do back */}
          </Information>
        </Content>

        <Content>
          <Information>
            <Label>{intl.formatMessage({ id: "operational_manager" })}</Label>
            <Description>{exhibitor.operationsContact || " - "}</Description>
          </Information>
          <Information>
            <Label>{intl.formatMessage({ id: "telephone" })}</Label>
            <Description>
              {exhibitor.telephone || " - "}
            </Description>
          </Information>
        </Content>

        <Content>
          <Information>
            <Label>{intl.formatMessage({ id: "fiscal_email" })}</Label>
            <Description>{exhibitor.billingContactEmail || " - "}</Description>
          </Information>
          <Information>
            <Label>{intl.formatMessage({ id: "mobile" })}</Label>
            <Description>
              {exhibitor.mobile || " - "}
            </Description>
          </Information>
        </Content>

        <Content>
          <Information>
            <Label>{intl.formatMessage({ id: "marketing_email" })}</Label>
            <Description>{exhibitor.marketingContactEmail || " - "}</Description>
          </Information>
        </Content>
      </Container>
      <BtnEdit>
        <Button
          onClick={() => onClickEdit()}
          label={intl.formatMessage({ id: "edit" })}
          type="primary"
          icon={
            <Edit
              color={
                !permission.includes("EDIT_EXHIBITOR_PROFILE_INFORMATION")
                  ? "#C7C9D9"
                  : "#fff"
              }
            />
          }
          disabled={
            !isContractSelected ||
            !permission.includes("EDIT_EXHIBITOR_PROFILE_INFORMATION")
          }
        />
      </BtnEdit>
    </>
  );
};

export default ViewInformation;
