import React from 'react'

const WarningCircle = (props) => {
  const {
    size = '16',
    color = '#E6B800'
  } = props


  return (
    <svg width={size} height={size} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_794_4395)">
        <path d="M8 14C11.3137 14 14 11.3137 14 8C14 4.68629 11.3137 2 8 2C4.68629 2 2 4.68629 2 8C2 11.3137 4.68629 14 8 14Z" stroke={color} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M8 8.5V5" stroke={color} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M8 11.375C8.34518 11.375 8.625 11.0952 8.625 10.75C8.625 10.4048 8.34518 10.125 8 10.125C7.65482 10.125 7.375 10.4048 7.375 10.75C7.375 11.0952 7.65482 11.375 8 11.375Z" fill={color}/>
      </g>
      <defs>
        <clipPath id="clip0_794_4395">
          <rect width={size} height={size} fill="white"/>
        </clipPath>
      </defs>
    </svg>
  )
}

export default WarningCircle