import { Link } from "react-router-dom";
import styled, { css } from "styled-components";

export const Container = styled.div`
  width: 100%;
  padding: 16px;
  background-color: #fff;
`;

export const TableContainer = styled.div`
  width: 100%;
  background-color: #fff;
  margin-top: 32px;
`;

export const FormContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  form {
    display: flex;
    flex-direction: column;
    width: 100%;
  }
`;

export const Title = styled.h1`
  font-size: 14px;
  font-weight: normal;
`;

export const TitleContainer = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  padding-bottom: 21px;
  padding-left: 8px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.16);
  & > div {
    margin-left: 16px;
  }
`;

export const FieldsContainer = styled.div`
  margin-top: 16px;
`;

export const Horizontal = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  & > div {
    display: flex;
    flex: 1 0 100px;
    margin-left: 16px;
    @media (max-width: 768px) {
      margin-left: 0px;
      flex: 1 1 auto;
      width: 100%;
    }
  }

  & > div:first-child {
    margin-left: 0px;
  }
`;

export const FilterActionsContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  font-size: 14px;
  flex-wrap: wrap;

  & > div:first-child {
    margin-left: 0px;
  }

  @media (max-width: 500px) {
    width: 100%;
  }

  & > div {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    margin-left: 16px;
    @media (max-width: 500px) {
      margin-left: 0px;
    }
  }
`;

export const SubmitContainer = styled.div`
  width: 100%;
  max-width: 150px;

  @media (max-width: 500px) {
    margin-left: 0px;
    max-width: none;
    width: 100%;
  }
`;

export const TableTitleContainer = styled.div`
  padding: 16px 0px 16px 24px;
`;

export const TableTitle = styled.p``;

export const LinkButton = styled(Link)`
  text-decoration: none;
`;

export const GoBackContainer = styled.div``;

export const Label = styled.span`
  margin-bottom: 8px;
  font-size: 12px;
  font-weight: 600;
`;

export const LabelContainer = styled.div`
  margin-right: 8px;
  margin-bottom: 8px;
`;

export const FileDropzoneContainer = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  border: 1px dotted #525a5c;
  align-items: center;
  padding: 8px;
  font-size: 14px;

  ul, p {
    margin: 0;
  }

  & > div {
    display: flex;
    flex: 1 0 100px;
    padding: 8px;
    max-height: 32px;
    @media (max-width: 768px) {
      margin-left: 0px;
      flex: 1 1 auto;
    }
  }

  ${(props) =>
    props.disabled &&
    css`
      opacity: 0.7;
      background-color: #f2f2f2;
    `}
`;

export const DocumentVisualization = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  cursor: default;
  flex-direction: row;

  & > div {
    display: flex;
    padding: 0px;
    @media (max-width: 768px) {
      margin-left: 0px;
      flex: 1 1 auto;
    }
  }
`;

export const DocumentAction = styled.div`
  align-self: center;
  justify-self: flex-end;

  svg {
    cursor: pointer;
  }
`;

export const DocumentName = styled.p`
  flex: 1;
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  text-align: center;
`;
