import produce from "immer";

const INITIAL_STATE = {
  checklist: []
};

const checklist = (state = INITIAL_STATE, action) => {
  return produce(state, (draft) => {
    switch (action.type) {
      case "SET_CHECKLIST": {
        const { checklist } = action.payload;

        if (!checklist) {
          localStorage.removeItem("InformaMarket:checklist")
          break
        }

        draft.checklist = checklist

        localStorage.setItem(
          "InformaMarket:checklist",
          JSON.stringify(checklist)
        );

        break;
      }

      case "SET_EMPTY_CHECKLIST": {
        const { isEmpty } = action.payload;

        draft.isEmpty = isEmpty

        localStorage.setItem(
          "InformaMarket:checklist.isEmpty",
          JSON.stringify(isEmpty)
        );

        break;
      }

      case "CLEAR_CHECKLIST": {
        draft.checklist = [];
        localStorage.removeItem("InformaMarket:checklist");

        break;
      }


      default: {
        if(localStorage.getItem('InformaMarket:checklist')){
          const checklist = JSON.parse(localStorage.getItem('InformaMarket:checklist'));
          draft.checklist = checklist
          
        }
        if(localStorage.getItem('InformaMarket:checklist.isEmpty')){
          const isEmpty = JSON.parse(localStorage.getItem('InformaMarket:checklist.isEmpty'))
          draft.isEmpty = isEmpty
        }
        return draft;
      }
    }
  });
};

export default checklist;
