import React from "react";
import { BrowserRouter } from "react-router-dom";

import GlobalStyles from "./style";
import store from "./redux";
import { Provider} from "react-redux";

import AppProvider from "./hooks";
import pt from "date-fns/locale/pt-BR";
import en from "date-fns/locale/en-US";
import { registerLocale } from "react-datepicker";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import LanguageProvider from "./components/LanguageProvider";
import { DialogProvider } from "./hooks/dialog/context";
import MultiRouter from "./routes/MultiRouter";

import 'antd/dist/antd.css';

registerLocale("pt", pt);
registerLocale("en", en);

export default function App() {
  
  return (
    <BrowserRouter>
      <AppProvider>
        <Provider store={store}>
          <LanguageProvider>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <DialogProvider>
                <MultiRouter />
              </DialogProvider>
            </MuiPickersUtilsProvider>
            <GlobalStyles />
          </LanguageProvider>
        </Provider>
      </AppProvider>
    </BrowserRouter>
  );
}
