import React from 'react'
import { uniqBy } from "../../utils/uniqBy";

export const Types = {
  create : 'CREATE_DIALOG',
  close : 'CLOSE_DIALOG',
  remove: 'REMOVE_DIALOG',
  reset :'RESET_DIALOGS'
}

export const dialogReducer = (dialogs = [], action = {}) => {
 
  switch (action?.type) {
    case Types.create:
      return uniqBy(
        [
          ...dialogs,
          {
            ...action?.payload,
            open: true,
          },
        ],
        "id"
      );

    case Types.close:
      return dialogs.length && dialogs.map((dialog) => {
        if (dialog?.id === action?.payload?.id) {
          return { ...dialog, open: false };
        }
        return dialog;
      });

    case Types.remove:
      return dialogs.length && dialogs.filter((dialog) => dialog?.id !== action?.payload?.id);

    case Types.reset: 
      return [];

    default:
      return dialogs;
  }
};
