import api from "./index";

export const getAllAssociateCompanyService = async (params) =>
  await api.get("/provider-association", { params });

export const getUserDocumentService = async (document) =>
  await api.get(`/provider-association/service-provider/${document}`);

export const createAssociateCompanyService = async (data) =>
  await api.post("/provider-association", data);

export const removeAssociationCompanyService = async (id) =>
  await api.delete(`/provider-association/${id}`);

export const updateLoginPermissionService = async (params) =>
  await api.put(`/provider-association/${params.id}/update-manage-permission`, {
    allowManageAccount: params.allowManageAccount,
  });
