import styled, { css } from "styled-components";
import { animated } from "react-spring";

export const Container = styled(animated.div)`
  position: fixed;
  background-color: #fff;
  width: 264px;
  min-height: 100%;
  -webkit-box-shadow: 10px 0px 28px -4px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 10px 0px 28px -4px rgba(0, 0, 0, 0.75);
  box-shadow: 10px 0px 28px -4px rgba(0, 0, 0, 0.75);
  z-index: 99;
  @media (max-width: 472px) {
    width: calc(100% - 80px);
  }
`;

export const TitleSection = styled.div`
  font-size: 24px;
  border-bottom: 1px solid black;
  padding-bottom: 8px;
  margin-bottom: 19px;
  padding-top: 16px;
  color: #002244;
  font-weight: 600;
`;

export const LinkMenuContainer = styled(animated.div)`
  margin-left: 8px;
  padding: 12px 0px 12px 12px;
  display: flex;
  align-items: center;
  font-size: 14px;
  color: #002244;
  border-radius: 8px;
  cursor: pointer;

  &:hover {
    background-color: rgba(17, 167, 217, 0.1);
  }

  ${(props) =>
    props.active &&
    css`
      color: #5ddb98;
    `}
`;

export const LinkMenuText = styled.span`
  margin-left: 8px;
  font-weight: 600;
`;

export const InformaLogoText = styled.img``;

export const InformaLogoTextContainer = styled.div`
  width: 100%;
  background-color: #4c637b;
  padding: 0;
  height: 72px;
  padding-top: 13px;
`;

export const Content = styled.div`
  max-height: calc(100vh - 72px);
  overflow-y: auto;
  padding: 0px 16px;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }
`;

export const InfoContainer = styled.div`
  padding-top: 16px;
  display: flex;
  align-items: center;
  flex-direction: column;
`;

export const PhoneContainer = styled.div`
  display: flex;
  justify-content: center;
  margin: 8px 0px;
  svg {
    margin-right: 8px;
  }
  p {
    font-weight: bold;
    font-size: 14px;
  }
`;

export const WhatsappContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  margin: 8px 0px;
  a {
    text-decoration: none;
  }
`;

export const InfoText = styled.p`
  font-size: 14px;
  font-weight: normal;
`;

export const FlatButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: transparent;
  color: #002244;
  border-radius: 5px;
  border: 1px solid #dadada;
  padding: 0 16px;
  width: 232px;
  height: 48px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  font-size: 14px;
  letter-spacing: normal;
  transition: background-color 0.2s;
  cursor: pointer;
  svg {
    margin-right: 8px;
  }
  margin-top: 8px;
`;
