import { all, takeLatest, call, put } from "redux-saga/effects";

import { changeApplicationLanguage } from "./actions";
import { getNotifications } from "../notification/actions";
import { alertRequest } from "../alerts/actions";
import api from "../../../service";

function* language({ payload }) {
  const { code, token } = payload;
  try {
    yield call(() =>
      api.put(
        "/users/language",
        { language: code },
        {
          headers: {
            "Content-Type": "application/json",
            Accept: "*/*",
            Authorization: `Bearer ${token}`,
          },
        }
      )
    );
    yield put(
      changeApplicationLanguage({
        code: code,
      })
    );

    yield put(
      getNotifications({
        token: token,
      })
    );
  } catch (error) {
    if (error.response.data.code !== -1001) {
      yield put(
        alertRequest({
          title: "error",
          message: "api.error." + String(error.response.data.code),
          messageType: "error",
          isDialog: true,
        })
      );
    }
  }
}

export default all([takeLatest("CHANGE_LANGUAGE_PREFERENCE", language)]);
