import React, { useMemo, useState, useCallback, useEffect } from "react";

import { useIntl } from "react-intl";
import { useHistory } from "react-router-dom";

import { useDispatch, useSelector } from "react-redux";

import { getTransferHistory } from "../../../../redux/modules/transferCredential/actions";

import useNotification from "../../../../hooks/newUXNotification";
import { useLoading } from "../../../../hooks/loading";

import ArrowUp from "../../../../newUXAssets/icons/ArrowUp";

import Table from "../../../../newUXComponents/Table";

import { Container, Content, GoBack } from "./styles";

const ViewHistory = () => {
  const intl = useIntl();

  const history = useHistory();
  const dispatch = useDispatch();
  const notification = useNotification();
  const { handleLoading } = useLoading();

  const { transferHistory } = useSelector((state) => state.transferCredential);
  const { contract, event } = useSelector((state) => state.initialSettings);

  const isPortuguese = useMemo(() => intl.locale === "pt", [intl]);

  const [tablePagination, setTablePagination] = useState({
    current: 0,
    defaultCurrent: 0,
    pageSize: 10,
    showQuickJumper: true,
    total: 0,
  });

  const callbackPaginationInfo = useCallback(
    (pagination) => {
      handleLoading(false);
      setTablePagination({
        current: pagination.pageNumber + 1,
        defaultCurrent: 1,
        pageSize: pagination.maxSize,
        showQuickJumper: true,
        total: pagination.totalElements,
      });
    },
    [handleLoading]
  );

  const callbackError = useCallback(
    (message) => {
      handleLoading(false);
      notification.displayError(
        intl.formatMessage({
          id: "api.error." + message,
        }),
        "get-credentials-associate-error"
      );
    },
    [notification, intl]
  );

  const handleGetInfoTable = useCallback(
    (page = 0, pageSize = 10, search = "") => {
      handleLoading(true);
      dispatch(
        getTransferHistory(
          {
            page,
            per_page: pageSize,
            opportunity_id: contract.value,
            event_id: event.value,
            search,
          },
          callbackPaginationInfo,
          callbackError
        )
      );
    },
    [
      contract,
      event,
      callbackPaginationInfo,
      callbackError,
      dispatch,
      handleLoading,
    ]
  );

  const columns = useMemo(
    () => [
      {
        title: intl.formatMessage({ id: "category" }),
        dataIndex: "category",
        key: "category",
        render: (_, record) => {
          if (isPortuguese) {
            return record.category;
          }

          return record.englishCategory;
        },
      },
      {
        title: intl.formatMessage({ id: "user" }),
        dataIndex: "user",
        key: "user",
      },
      {
        title: intl.formatMessage({ id: "quantity" }),
        dataIndex: "quantity",
        key: "quantity",
        align: "center",
      },
      {
        title: intl.formatMessage({ id: "benefited" }),
        dataIndex: "benefited",
        key: "benefited",
      },
    ],
    [intl]
  );

  useEffect(() => {
    if (!contract.value || !event.value) {
      notification.displayInfo(
        intl.formatMessage({ id: "error.contract_not_selected" }),
        "event-not-selected-message"
      );
    } else {
      handleGetInfoTable();
      notification.close("event-not-selected-message");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contract.value, event.value]);

  return (
    <Container>
      <GoBack onClick={() => history.push("/credentials/transfer")}>
        <ArrowUp />
        {intl.formatMessage({ id: "goback" })}
      </GoBack>
      <Content>
        <Table
          title={intl.formatMessage({ id: "transfer_history" })}
          dataSource={transferHistory}
          columns={columns}
          tablePagination={tablePagination}
          handleGetInfoTable={handleGetInfoTable}
        />
      </Content>
    </Container>
  );
};

export default ViewHistory;
