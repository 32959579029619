import React from "react";

const Down = (props) => {
  const { size = "30", color = "black", ...old } = props;

  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...old}
    >
      <path
        d="M6.33572 6.39777L11.0513 0.360269C11.0638 0.344493 11.0715 0.325533 11.0736 0.305568C11.0757 0.285603 11.0722 0.265441 11.0634 0.247399C11.0546 0.229357 11.0409 0.214166 11.0238 0.203571C11.0067 0.192976 10.987 0.187406 10.967 0.187501L9.9317 0.187501C9.86608 0.187501 9.80313 0.218305 9.76295 0.269198L5.99822 5.09063L2.23349 0.269197C2.19331 0.216965 2.13036 0.187501 2.06474 0.187501L1.02947 0.187501C0.939739 0.187501 0.890185 0.290626 0.945096 0.360269L5.66072 6.39777C5.70075 6.44908 5.75195 6.4906 5.81043 6.51915C5.86892 6.5477 5.93314 6.56254 5.99822 6.56254C6.0633 6.56254 6.12753 6.5477 6.18601 6.51915C6.24449 6.4906 6.29569 6.44908 6.33572 6.39777Z"
        fill={color}
        fillOpacity="0.85"
      />
    </svg>
  );
};

export default Down;
