import React, { useState, useMemo, useCallback, useEffect } from "react";

import { useIntl } from "react-intl";

import { useDispatch, useSelector } from "react-redux";

import useNotification from "../../hooks/newUXNotification";
import { useLoading } from "../../hooks/loading";

import { getDocuments, getFile } from "../../redux/modules/document/actions";

import { convertToDateOnly } from "../../utils/date";

import Table from "../../newUXComponents/Table";
import DownloadSimple from "../../newUXAssets/icons/DownloadSimple";

import { Container, Title, Content, Hr, Actions, BtnActions } from "./styles";

const Document = () => {
  const intl = useIntl();
  const notification = useNotification();
  const dispatch = useDispatch();
  const { handleLoading } = useLoading();

  const { documents } = useSelector((state) => state.document);
  const { contract, event } = useSelector((state) => state.initialSettings);

  const [tablePagination, setTablePagination] = useState({
    current: 0,
    defaultCurrent: 0,
    pageSize: 10,
    showQuickJumper: true,
    total: 0,
  });

  const callbackError = useCallback(
    (message) => {
      handleLoading(false);
      notification.displayError(
        intl.formatMessage({
          id: "api.error." + message,
        }),
        "get-all-document-error"
      );
    },
    [notification, intl, handleLoading]
  );

  const callbackPaginationInfo = useCallback(
    (pagination) => {
      handleLoading(false);
      setTablePagination({
        current: pagination.pageNumber + 1,
        defaultCurrent: 1,
        pageSize: pagination.maxSize,
        showQuickJumper: true,
        total: pagination.totalElements,
      });
    },
    [handleLoading]
  );

  const handleGetInfoTable = useCallback(
    (page = 0, pageSize = 10, search = "") => {
      handleLoading(true);
      dispatch(
        getDocuments(
          {
            page,
            per_page: pageSize,
            opportunity_id: contract.value,
            event_id: event.value,
            search,
          },
          callbackPaginationInfo,
          callbackError
        )
      );
    },
    [
      callbackError,
      callbackPaginationInfo,
      contract.value,
      dispatch,
      event.value,
      handleLoading,
    ]
  );

  const callbackSuccessFile = useCallback((data) => {
    handleLoading(false);
    if (data.document) {
      window.open(data.document, "_blank");
      return;
    }

    notification.displayError(
      intl.formatMessage({
        id: "documents_not_be_found",
      }),
      "documents_not_be_found"
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleDownloadFile = useCallback(
    (id) => {
      handleLoading(true);
      dispatch(getFile(id, callbackSuccessFile, callbackError));
    },
    [callbackError, callbackSuccessFile, dispatch, handleLoading]
  );

  const columns = useMemo(
    () => [
      {
        title: intl.formatMessage({ id: "name" }),
        dataIndex: "title",
        key: "title",
      },
      {
        title: intl.formatMessage({ id: "upload_date" }),
        dataIndex: "lastModification",
        key: "lastModification",
        render: (text) => {
          if (text) {
            return convertToDateOnly(text);
          }
          return " - ";
        },
      },
      {
        title: "Download",
        dataIndex: "download",
        key: "download",
        align: "center",
        render: (_, record) => {
          return (
            <Actions>
              <BtnActions onClick={() => handleDownloadFile(record.id)}>
                <DownloadSimple />
              </BtnActions>
            </Actions>
          );
        },
      },
    ],
    [handleDownloadFile, intl]
  );

  useEffect(() => {
    if (!contract.value || !event.value) {
      notification.displayInfo(
        intl.formatMessage({ id: "error.contract_not_selected" }),
        "event-not-selected-message"
      );
    } else {
      handleGetInfoTable();
      notification.close("event-not-selected-message");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contract.value, event.value]);

  return (
    <Container>
      <Title>
        {intl.formatMessage({
          id: "document_download",
        })}
      </Title>
      <Hr />
      {contract.value && (
        <Content>
          <Table
            title={intl.formatMessage({ id: "documents" })}
            dataSource={documents}
            columns={columns}
            tablePagination={tablePagination}
            handleGetInfoTable={handleGetInfoTable}
          />
        </Content>
      )}
    </Container>
  );
};

export default Document;
