import produce from "immer";

const INITIAL_STATE = {
  documents: [
    {
      id: 1,
      name: "Doc teste",
      creationDate: "2021-10-07T09:21:41.543404-03:00",
    },
  ],
};

const document = (state = INITIAL_STATE, action) => {
  return produce(state, (draft) => {
    switch (action.type) {
      case "SET_DOCUMENTS_SUCCESS": {
        const { documents } = action.payload;

        draft.documents = documents;

        localStorage.setItem(
          "InformaMarket:documents",
          JSON.stringify(documents)
        );

        break;
      }

      default: {
        return draft;
      }
    }
  });
};

export default document;
