import React, {
  useMemo,
  useState,
  useEffect,
  useCallback,
  useContext,
} from "react";
import { Menu as AntdMenu } from "antd";
import { useLocation, useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { useIntl } from "react-intl";

import { DialogContext } from "../../../../hooks/dialog/context";

import useNotification from "../../../../hooks/newUXNotification";

import { financialSingleSignOn } from "../../../../redux/modules/billing/actions";
import { getGuests } from "../../../../redux/modules/guests/actions";

import Basket from "../../../../newUXAssets/icons/Basket";
import ChatDots from "../../../../newUXAssets/icons/ChatDots";
import Files from "../../../../newUXAssets/icons/Files";
import IdentificationCard from "../../../../newUXAssets/icons/IdentificationCard";
import ListChecks from "../../../../newUXAssets/icons/ListChecks";
import Question from "../../../../newUXAssets/icons/Question";
import UserCircle from "../../../../newUXAssets/icons/UserCircle";
import Billing from "../../../../newUXAssets/icons/Billing";
import Guests from "../../../../newUXAssets/icons/Guests";
import Package from "../../../../newUXAssets/icons/Package";
import FileArrowDown from "../../../../newUXAssets/icons/FileArrowDown";
import UserSwitch from "../../../../newUXAssets/icons/UserSwitch";

import GuestsModal from "./GuestsModal";

const items = [
  {
    key: "1",
    path: "/accounts-users",
  },
  {
    key: "2",
    path: "/checklist",
  },
  {
    key: "3",
    path: "/billing",
  },
  {
    key: "4",
    path: "/user",
    urls: [
      {
        url: "/user/view",
        permission: "EDIT_EXHIBITOR_PROFILE_INFORMATION",
      },
      {
        url: "/user/view",
        permission: "EDIT_PROFILE_INFORMATION",
      },
      {
        url: "/user/terms-of-use",
        permission: "ACCESS_TERMS_OF_USE",
      },
    ],
  },
  {
    key: "5",
    path: "/associate-company",
  },
  {
    key: "6",
    path: "/document",
  },
  {
    key: "7",
    path: "/contract",
  },
  {
    key: "8",
    path: "/credentials",
    urls: [
      {
        url: "/credentials/associate",
        permission: "MANAGE_CREDENTIALS",
      },
      {
        url: "/credentials/register",
        permission: "MANAGE_ASSOCIATE_COLLABORATOR",
      },
      {
        url: "/credentials/schedule",
        permission: "MANAGE_CREDENTIALS",
      },
      {
        url: "/credentials/transfer",
        permission: "TRANSFER_CREDENTIALS",
      },
    ],
  },
  {
    key: "9",
    path: "/ecommerce",
  },
  {
    key: "10",
    path: "/contact",
  },
  {
    key: "11",
    path: "/guests",
  },
  {
    key: "12",
    path: "/faq",
  },
];

const Menu = () => {
  const { pathname } = useLocation();
  const history = useHistory();
  const intl = useIntl();
  const notification = useNotification();
  const dispatch = useDispatch();
  const { createDialog } = useContext(DialogContext);

  const [menuItems, setMenuItems] = useState([]);
  const { user, adminToken, permission } = useSelector((state) => state.auth);
  const { contract, event } = useSelector((state) => state.initialSettings);
  const [currentEvent, setCurrentEvent] =  useState()

  const [isGuestsActive, setIsGuestsActive] = useState(false)

  const mainPath = `/${pathname.split("/")[1]}`;

  const selectedKey = useMemo(() => {
    const findedItem = items.find((item) => item.path === mainPath);
    if (!findedItem) return "";

    return findedItem.key;
  }, [mainPath, user]);

  const generateHiddenInputAndAppend = (name, value, form) => {
    const field = document.createElement("input");
    field.type = "hidden";
    field.name = name;
    field.value = value;
    form.appendChild(field);
  };

  const callbackSuccess = useCallback(({ token, url }) => {
    const form = document.createElement("form");
    form.method = "post";
    form.action = url;
    form.target = "_blank";

    generateHiddenInputAndAppend("bypass_key", token, form);

    document.body.appendChild(form);
    form.submit();
    document.body.removeChild(form);
  }, []);

  const callbackError = useCallback(
    (codeError) => {
      if (codeError === -22000) {
        notification.displayInfo(
          intl.formatMessage({
            id: "api.error." + codeError,
          }),
          "financial-Single-SignOn-info"
        );
      } else {
        notification.displayError(
          intl.formatMessage({
            id: "api.error." + codeError,
          }),
          "financial-Single-SignOn-info"
        );
      }
    },
    [intl, notification]
  );

  const handleFinancialSingleSignOn = useCallback((event, contract)=>{
    if (!contract.value || !event.value) {
      notification.displayInfo(
        intl.formatMessage({ id: "error.contract_not_selected" }),
        "event-not-selected-message"
      );
      return;
    }
    dispatch(financialSingleSignOn(callbackSuccess, callbackError))
  },[event, contract])


  const alternativeCallBackSuccessGuests = useCallback(
    () => {
      setIsGuestsActive(true)
    },
    []
  );

  const alternativeCallBackErrorGuests = useCallback(
    () => {
      setIsGuestsActive(false)
    },
    []
  );

  const handleModalGuests = useCallback(
    (data) => {
      createDialog({
        id: "modal-guests",
        Component: GuestsModal,
        props: {
          idContract: contract?.value,
          eventId: event?.value,
          data,
        },
      });
    },
    [contract?.value, createDialog, event?.value]
  );

  const handleGuests = () => {
    if (!contract.value || !event.value) {
      notification.displayInfo(
        intl.formatMessage({ id: "error.contract_not_selected" }),
        "event-not-selected-message"
      );
      return;
    }

    dispatch(
      getGuests(
        {
          eventId: event.value,
        },
        handleModalGuests,
        callbackError
      )
    );
  };

  const onClickGoToPage = (path) => () => history.push(path);

  const getFirstUrlToPage = ({ urls }) => {
    const firstUrl = urls.find((item) => permission.includes(item.permission));

    if (firstUrl?.url) {
      history.push(firstUrl.url);
    }
  };

  const getIconColor = (uri) => {
    if (pathname.includes(uri)) return "white";
    return "#444444";
  };

  useEffect(()=>{
    if(!event) {
      setCurrentEvent()
      return
    }
    if(currentEvent !== event?.value) { 
      setCurrentEvent(event?.value)
      return
    }
  },[event])

  useEffect(()=>{
    if(!currentEvent) {
      setIsGuestsActive(false)
      return
    }
    dispatch(
      getGuests(
        {
          eventId: currentEvent,
        },
        alternativeCallBackSuccessGuests,
        alternativeCallBackErrorGuests
      )
    );
  },[currentEvent])

  useEffect(() => {
    const mappedMenuItems = user.permissionCategories.map((permission) => {
      switch (permission.code) {
        case "CHECKLIST":
          return {
            key: "2",
            icon: <ListChecks color={getIconColor("/checklist")} />,
            onClick: onClickGoToPage("/checklist"),
            label: intl.formatMessage({ id: "sidebar.checklist" }),
            title: intl.formatMessage({ id: "sidebar.checklist" }),
          };

        case "BILLS":
          return {
            key: "3",
            icon: <Billing color={getIconColor("/billing")} />,
            onClick: () => handleFinancialSingleSignOn(event, contract),
            label: intl.formatMessage({ id: "sidebar.bills" }),
            title: intl.formatMessage({ id: "sidebar.bills" }),
          };

        case "PROFILE":
          return {
            key: "4",
            icon: <UserCircle color={getIconColor("/user")} />,
            onClick: () => getFirstUrlToPage(items[3]),
            label: intl.formatMessage({ id: "sidebar.my_account" }),
            title: intl.formatMessage({ id: "sidebar.my_account" }),
          };

        case "SERVICE_PROVIDERS":
          return {
            key: "5",
            icon: <Package color={getIconColor("/associate-company/view")} />,
            onClick: onClickGoToPage("/associate-company/view"),
            label: intl.formatMessage({ id: "service_provider" }),
            title: intl.formatMessage({ id: "service_provider" }),
          };

        case "DOWNLOAD_DOCUMENTS":
          return {
            key: "6",
            icon: <FileArrowDown color={getIconColor("/document")} />,
            onClick: onClickGoToPage("/document"),
            label: intl.formatMessage({ id: "document_download" }),
            title: intl.formatMessage({ id: "document_download" }),
          };

        case "CONTRACT":
          return {
            key: "7",
            icon: <Files color={getIconColor("/contract")} />,
            onClick: onClickGoToPage("/contract"),
            label: intl.formatMessage({ id: "contract" }),
            title: intl.formatMessage({ id: "contract" }),
          };

        case "ACCREDITATION":
          return {
            key: "8",
            icon: <IdentificationCard color={getIconColor("/credentials")} />,
            onClick: () => getFirstUrlToPage(items[7]),
            label: intl.formatMessage({ id: "sidebar.credentials" }),
            title: intl.formatMessage({ id: "sidebar.credentials" }),
          };

        case "ECOMMERCE":
          return {
            key: "9",
            icon: <Basket color={getIconColor("/ecommerce")} />,
            onClick: onClickGoToPage("/ecommerce"),
            label: intl.formatMessage({ id: "sidebar.ecommerce" }),
            title: intl.formatMessage({ id: "sidebar.ecommerce" }),
          };

        case "CONTACT":
          return {
            key: "10",
            icon: <ChatDots color={getIconColor("/contact")} />,
            onClick: onClickGoToPage("/contact"),
            label: intl.formatMessage({ id: "sidebar.contact" }),
            title: intl.formatMessage({ id: "sidebar.contact" }),
          };

        case "SYSTEMS":
          if(!isGuestsActive) return null
          return {
            key: "11",
            icon: <Guests color={getIconColor("/guests")} />,
            onClick: () => handleGuests(),
            label: intl.formatMessage({ id: "sidebar.guests" }),
            title: intl.formatMessage({ id: "sidebar.guests" }),
          };

        case "FAQ":
          return {
            key: "12",
            icon: <Question color={getIconColor("/faq")} />,
            onClick: onClickGoToPage("/faq"),
            label: intl.formatMessage({ id: "FAQ" }),
            title: intl.formatMessage({ id: "FAQ" }),
          };

        default:
          return null;
      }
    });

    const orderedMenuItems = mappedMenuItems.sort((itemA, itemB) => {
      if (!itemA) return 1;
      if (!itemB) return -1;
      if (itemA === itemB) return 0;
      return Number(itemA.key) - Number(itemB.key);
    });

    if (adminToken) {
      setMenuItems([
        {
          key: "1",
          icon: <UserSwitch color={getIconColor("/accounts-users")} />,
          onClick: onClickGoToPage("/accounts-users"),
          label: intl.formatMessage({ id: "accounts_users" }),
          title: intl.formatMessage({ id: "accounts_users" }),
        },
        ...orderedMenuItems,
      ]);
      return;
    }

    setMenuItems(orderedMenuItems);
  }, [user, mainPath, intl, adminToken, event, contract, isGuestsActive]);

  return (
    <AntdMenu
      style={{ border: "none" }}
      defaultSelectedKeys={[selectedKey]}
      activeKey={selectedKey}
      selectedKeys={selectedKey}
      multiple={false}
      mode="inline"
      items={menuItems}
    />
  );
};

export default Menu;
