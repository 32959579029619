import React, { useEffect, useMemo, useRef, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useSelector } from "react-redux";
import { useHistory } from "react-router";
import AnimatedMainContainer from "../../components/AnimatedMainContainer";
import { useToast } from "../../hooks/toast";
import * as Yup from "yup";
import getValidationErrors from "../../utils/getValidationErrors";
import api from "../../service";
import { useCallback } from "react";
import FormButton from "../../components/FormButton";
import { Form } from "@unform/web";
import { validateSingleFieldOnBlur } from "../../utils/formValidations";
import { convertToDateOnly } from "../../utils/date";

import {
  Container,
  FormContainer,
  TitleContainer,
  Title,
  FieldsContainer,
  Horizontal,
  FilterActionsContainer,
  SubmitContainer,
  GoBackContainer,
  ActionContainer,
  BadgeContainer,
  CredentialsActionContainer,
} from "./styles";
import SelectWithLabel from "../../components/SelectWithLabel";
import Table from "../../components/Table";
import Badge from "../../components/Badge";
import Loader from "react-loader-spinner";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import { useLoading } from "../../hooks/loading";
import axios from "axios";

const AdminCredentialTransfer = () => {
  const { user, token } = useSelector((state) => state.auth);
  const [data, setData] = useState([]);
  const formRef = useRef(null);
  const intl = useIntl();
  const history = useHistory();
  const { addToast } = useToast();

  const [loading, setLoading] = useState(false);
  const [events, setEvents] = useState([]);
  const [selectedEvent, setSelectedEvent] = useState();
  const [opportunities, setOpportunities] = useState([]);
  const [selectedOpportunity, setSelectedOpportunity] = useState();
  const [categories, setCategories] = useState([]);
  const [accountTypes, setAccountTypes] = useState([]);
  const [selectedAccountType, setSelectedAccountType] = useState();
  const [operations, setOperations] = useState([]);
  const [users, setUsers] = useState([]);
  const [selectedUser, setSelectedUser] = useState();
  const [filtered, setFiltered] = useState(false);
  const { handleLoading } = useLoading();
  const isAdministrator = user?.role?.type === "ADMINISTRATOR";
  const isPortuguese = useMemo(()=> intl.locale === 'pt', [intl]);

  const schema = Yup.object().shape({
    event: Yup.string().required(
      intl.formatMessage({ id: "error.field_required" })
    ),
    accountType: Yup.string().nullable(),
    opportunity: Yup.string().nullable(),
    category: Yup.string().nullable(),
    operation: Yup.string().nullable(),
    user: Yup.string().nullable(),
  });

  const handleSubmit = useCallback(
    async (data) => {
      try {
        formRef.current?.setErrors({});

        setLoading(true);
        if (loading) {
          return;
        }

        await schema.validate(data, { abortEarly: false });
        setFiltered(true);

        api
          .patch(
            `/admin-credential-transfer`,
            {
              eventId: data.event,
              roleType: data.accountType ? data.accountType : null,
              opportunityId: data.opportunity ? data.opportunity : null,
              categoryId: data.category ? data.category : null,
              operation: data.operation ? data.operation : null,
              systemUserId: data.user ? data.user : null,
            },
            {
              headers: {
                "Content-Type": "application/json",
                Accept: "*/*",
                Authorization: `Bearer ${token}`,
              },
            }
          )
          .then((result) => setData(result.data.credentialTransferOutputs))
          .catch((err) =>
            addToast({
              type: "error",
              title: intl.formatMessage({
                id: "error",
              }),
              description: intl.formatMessage({
                id: "api.error." + err.response.data.code,
              }),
            })
          )
          .finally(() => setLoading(false));
      } catch (err) {
        setLoading(false);
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);
          formRef.current?.setErrors(errors);
          return;
        }
      }
    },
    [loading, schema, token, addToast, intl]
  );

  useEffect(() => {
    handleLoading(true);

    setAccountTypes([
      {
        label: intl.formatMessage({ id: "all" }),
        value: null,
      },
      {
        label: intl.formatMessage({ id: "exhibitor" }),
        value: "EXHIBITOR",
      },
      {
        label: intl.formatMessage({ id: "service_provider" }),
        value: "SERVICE_PROVIDER",
      },
    ]);

    setOperations([
      {
        label: intl.formatMessage({ id: "all" }),
        value: null,
      },
      {
        label: intl.formatMessage({ id: "transferred" }),
        value: "TRANSFERRED",
      },
      {
        label: intl.formatMessage({ id: "received" }),
        value: "RECEIVED",
      },
    ]);

    api
      .get(`/admin/events`, {
        headers: {
          "Content-Type": "application/json",
          Accept: "*/*",
          Authorization: `Bearer ${token}`,
        },
      })
      .then((eventResponse) => {
        const events = eventResponse.data.output.map((event) => ({
          label: event.eventEditionName,
          value: event.idNumber,
        }));
        let eventsSort = [...events].sort((a, b) =>
          a.label < b.label ? -1 : a.label > b.label ? 1 : 0
        );
        setEvents(eventsSort);
      })
      .catch((err) =>
        addToast({
          type: "error",
          title: intl.formatMessage({
            id: "error",
          }),
          description: intl.formatMessage({
            id: "api.error." + err.response.data.code,
          }),
        })
      )
      .finally(() => handleLoading(false));
  }, [addToast, intl, token, handleLoading]);

  useEffect(() => {
    formRef.current.setFieldValue("user");
    if (selectedEvent && selectedAccountType !== undefined) {
      handleLoading(true);
      api
        .patch(
          `/users/by-event-and-role`,
          {
            eventId: selectedEvent,
            roleType: selectedAccountType,
            useEmailInTheServiceProviderLabel: true,
            useCancelledOpportunities: false,
          },
          {
            headers: {
              "Content-Type": "application/json",
              Accept: "*/*",
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((result) =>
          setUsers([
            { value: 0, label: intl.formatMessage({ id: "all" }) },
            ...result.data.accountDropdownOutputList,
          ])
        )
        .catch((err) =>
          addToast({
            type: "error",
            title: intl.formatMessage({
              id: "error",
            }),
            description: intl.formatMessage({
              id: "api.error." + err.response.data.code,
            }),
          })
        )
        .finally(() => handleLoading(false));
    }
  }, [
    addToast,
    intl,
    selectedEvent,
    selectedAccountType,
    token,
    handleLoading
  ]);

  useEffect(() => {
    formRef.current.setFieldValue("opportunity");
    if (selectedEvent && selectedUser) {
      handleLoading(true);
      api
        .patch(
          `/exhibitor/opportunity/admin-accreditation/event`,
          { eventId: selectedEvent, userId: selectedUser },
          {
            headers: {
              "Content-Type": "application/json",
              Accept: "*/*",
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((result) =>
          setOpportunities(
            [...result.data.output].sort((a, b) =>
              a.label < b.label ? -1 : a.label > b.label ? 1 : 0
            )
          )
        )
        .catch((err) =>
          addToast({
            type: "error",
            title: intl.formatMessage({
              id: "error",
            }),
            description: intl.formatMessage({
              id: "api.error." + err.response.data.code,
            }),
          })
        )
        .finally(() => handleLoading(false));
    }
  }, [
    selectedEvent,
    selectedUser,
    addToast,
    intl,
    token,
    handleLoading
  ]);

  useEffect(() => {
    formRef.current.setFieldValue("category");
    formRef.current.setFieldValue("operation");
    if (selectedOpportunity && selectedUser) {
      handleLoading(true);

      const config = {
        headers: {
          "Content-Type": "application/json",
          Accept: "*/*",
          Authorization: `Bearer ${token}`,
        },
      };

      api
        .patch(
          `/ecommerce/category/available/opportunity/admin-accreditation`,
          {
            userId: selectedUser,
            opportunityId: selectedOpportunity,
          },
          config
        )
        .then((categoryResult) => {
          const credentialCategories =
            categoryResult.data.ecommerceChlebaCategoryCredentialAvailableOutputs.map(
              (category) => ({
                value: category.id,
                label: isPortuguese ? category.title : category.englishTitle,
              })
            );
          setCategories([
            {
              value: 0,
              label: intl.formatMessage({ id: "all" })
            },
            ...credentialCategories
          ]);
        })
        .catch((err) =>
          addToast({
            type: "error",
            title: intl.formatMessage({
              id: "error",
            }),
            description: intl.formatMessage({
              id: "api.error." + err.response.data.code,
            }),
          })
        )
        .finally(() => handleLoading(false));
    }
  }, [addToast, handleLoading, intl, selectedOpportunity, selectedUser, token]);

  const validationBlur = (name) => {
    let errors = formRef.current.getErrors();
    validateSingleFieldOnBlur(errors, schema, name, formRef);
  };

  const columns = useMemo(
    () => [
      {
        name: intl.formatMessage({ id: "transfer_date" }),
        selector: (row) => row.transferDate,
        format: (row) => convertToDateOnly(row.transferDate),
        minWidth: "150px",
        maxWidth: "180px",
      },
      {
        name: intl.formatMessage({ id: "contract" }),
        selector: (row) => row.opportunity,
        maxWidth: "100px",
      },
      {
        name: intl.formatMessage({ id: "category" }),
        selector: (row) => row.category,
        minWidth: "100px",
        maxWidth: "200px",
      },
      {
        name: intl.formatMessage({ id: "user" }),
        selector: (row) => row.user,
        minWidth: "150px",
      },
      {
        name: intl.formatMessage({ id: "quantity" }),
        selector: (row) => row.quantity,
        maxWidth: "120px",
      },
      {
        name: intl.formatMessage({ id: "transferred_by" }),
        selector: (row) => row.transferredBy,
        minWidth: "250px",
      },
      {
        name: intl.formatMessage({ id: "benefited" }),
        selector: (row) => row.benefited,
        minWidth: "150px",
      },
    ],
    [intl]
  );

  const clearFields = () => {
    formRef.current.reset();
    formRef.current.setFieldValue("event");
    formRef.current.setFieldValue("accountType");
    formRef.current.setFieldValue("opportunity");
    formRef.current.setFieldValue("category");
    formRef.current.setFieldValue("operation");
    formRef.current.setFieldValue("user");
    setFiltered(false);
  };

  const onChangeEvent = (event) => {
    setSelectedEvent(event?.value);
    setData([]);
    setFiltered(false);
    formRef.current.setFieldValue("user");
  };

  return (
    <AnimatedMainContainer
      breadcrumb={intl.formatMessage({ id: "breadcrumb.credential_transfer" })}
    >
      <Container>
        <FormContainer>
          <Form ref={formRef} onSubmit={handleSubmit}>
            <TitleContainer>
              <Title>
                <FormattedMessage id="credential_transfer" />
              </Title>
              <GoBackContainer>
                <ActionContainer>
                  <BadgeContainer onClick={() => history.push("/dashboard")}>
                    <Badge
                      color="#C9C9C9"
                      text={intl.formatMessage({ id: "goback" })}
                      fontSize="12px"
                    />
                  </BadgeContainer>
                  {isAdministrator ? (
                    <BadgeContainer
                      onClick={() => history.push("/admin-credential-transfer/new")}
                    >
                      <Badge
                        color="#002244"
                        text={intl.formatMessage({ id: "new_transfer" })}
                        fontSize="12px"
                      />
                    </BadgeContainer>
                  ) : (
                    <BadgeContainer>
                    </BadgeContainer>
                  )}
                </ActionContainer>
              </GoBackContainer>
            </TitleContainer>
            <FieldsContainer>
              <Horizontal>
                <SelectWithLabel
                  name="event"
                  validationBlur={validationBlur}
                  label={intl.formatMessage({ id: "event_edition" })}
                  placeholder={intl.formatMessage({ id: "event_edition" })}
                  labelOrientation="vertical"
                  options={events}
                  onChange={onChangeEvent}
                  required
                />
                <SelectWithLabel
                  name="accountType"
                  validationBlur={validationBlur}
                  label={intl.formatMessage({ id: "account_type" })}
                  placeholder={intl.formatMessage({ id: "account_type" })}
                  labelOrientation="vertical"
                  onChange={(accountType) =>
                    setSelectedAccountType(accountType?.value)
                  }
                  options={accountTypes}
                />
                <SelectWithLabel
                  name="user"
                  validationBlur={validationBlur}
                  label={intl.formatMessage({ id: "account_or_user" })}
                  placeholder={intl.formatMessage({ id: "account_or_user" })}
                  labelOrientation="vertical"
                  onChange={(user) =>
                    setSelectedUser(user?.value)
                  }
                  options={users}
                />
              </Horizontal>
              <Horizontal>
                <SelectWithLabel
                  name="opportunity"
                  validationBlur={validationBlur}
                  label={intl.formatMessage({ id: "contract" })}
                  placeholder={intl.formatMessage({ id: "contract" })}
                  labelOrientation="vertical"
                  options={opportunities}
                  onChange={(e) => setSelectedOpportunity(e?.value)}
                />
                <SelectWithLabel
                  name="category"
                  validationBlur={validationBlur}
                  label={intl.formatMessage({ id: "category" })}
                  placeholder={intl.formatMessage({ id: "category" })}
                  labelOrientation="vertical"
                  options={categories}
                />
                <SelectWithLabel
                  name="operation"
                  validationBlur={validationBlur}
                  label={intl.formatMessage({ id: "operation" })}
                  placeholder={intl.formatMessage({ id: "operation" })}
                  labelOrientation="vertical"
                  options={operations}
                />
              </Horizontal>
              <Horizontal>
                <FilterActionsContainer>
                  <SubmitContainer>
                    <FormButton
                      color="#C9C9C9"
                      type="button"
                      onClick={clearFields}
                    >
                      <FormattedMessage id="clear_fields" />
                    </FormButton>
                  </SubmitContainer>
                  <SubmitContainer>
                    <FormButton type="submit" loading={loading}>
                      <FormattedMessage id="search" />
                    </FormButton>
                  </SubmitContainer>
                </FilterActionsContainer>
              </Horizontal>
            </FieldsContainer>
          </Form>
        </FormContainer>
      </Container>
      {!filtered ? (
        <CredentialsActionContainer>
          <FormattedMessage id="need_to_filter_first" />
        </CredentialsActionContainer>
      ) : (
        <Table
          columns={columns}
          data={data}
          title={intl.formatMessage({ id: "results" })}
          progressPending={loading}
          progressComponent={
            <Loader type="Oval" color="#00BFFF" height={100} width={100} />
          }
        />
      )}
    </AnimatedMainContainer>
  );
};

export default AdminCredentialTransfer;
