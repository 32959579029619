import styled, { css } from 'styled-components'

export const Container = styled.div`
    height: 35px;
    width: 650px;
    background-color: white;
    border-radius: 10px;

    @media (max-width: 1415px) {
        width: 90%;
    }

    @media (max-width: 515px) {
        width: 80%;
        margin-left: 15px;
    }
`

export const Filler = styled.div`
    height: 100%;
    ${(props) => 
    css`
        width: ${props.completed};
    `};
    background-color:  rgb(0,34,68);
    transition: width 0.5s ease-in-out;
    border-radius: inherit;
    text-align: right;
    display: flex;
    justify-content: flex-end;
    align-items: center;
`

export const Label = styled.span`
    padding: 5px;
    color: white;
    font-weight: bold;
`