import React, { useState, useEffect, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  getEcommerceLink,
  getEcommerceInfo,
} from "../../redux/modules/ecommerce/actions";
import { useIntl } from "react-intl";
import useNotification from "../../hooks/newUXNotification";

import {
  Container,
  Title,
  Hr,
  Card,
  Row,
  Cell,
  Label,
  Text,
  AlertText,
  Button,
  ButtonLabel,
  Checkbox,
} from "./styles";

const Ecommerce = () => {
  const [isChecked, setIsChecked] = useState(false);
  const { ecommerceInfo } = useSelector((state) => state.ecommerce);
  const { user } = useSelector((state) => state.auth);
  const { contract, event } = useSelector((state) => state.initialSettings);

  const dispatch = useDispatch();
  const intl = useIntl();
  const notification = useNotification();

  const onChangeCheck = (e) => {
    setIsChecked(e.target.checked);
  };

  const formatString = (string) => {
    if (!string || string === "") return "-";
    return string;
  };

  const callbackError = useCallback(
    (message) =>
      notification.displayError(
        intl.formatMessage({
          id: "api.error." + message,
        }),
        "get-ecommerce-inf-error"
      ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [intl]
  );

  const onSubmit = () => {
    if (!contract?.value) {
      notification.displayInfo(
        intl.formatMessage({ id: "error.contract_not_selected" }),
        "event-not-selected-message"
      );
      return;
    }
    dispatch(
      getEcommerceLink({
        callbackError,
        selectedEvent: event.value,
        selectedContract: contract.value,
      })
    );
  };

  useEffect(() => {
    if (!contract?.value) {
      notification.displayInfo(
        intl.formatMessage({ id: "error.contract_not_selected" }),
        "event-not-selected-message"
      );
      return;
    } else {
      dispatch(
        getEcommerceInfo(
          {
            opportunity_id: contract.value,
            event_id: event.value,
          },
          callbackError
        )
      );
      notification.close("event-not-selected-message");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contract?.value, event.value]);

  return (
    <Container>
      <Title>{intl.formatMessage({ id: "ecommerce" })}</Title>
      <Hr />
      <Card>
        <Row>
          <Cell>
            <Label>{intl.formatMessage({ id: "company_name" })}</Label>
            <Text>{formatString(ecommerceInfo.corporateName)}</Text>
          </Cell>
          <Cell>
            <Label>{intl.formatMessage({ id: "address" })}</Label>
            <Text>{formatString(ecommerceInfo.address)}</Text>
          </Cell>
        </Row>
        <Row>
          <Cell>
            <Label>{intl.formatMessage({ id: "client_type" })}</Label>
            <Text>
              {formatString(intl.formatMessage({ id: user.role.type }))}
            </Text>
          </Cell>
          <Cell>
            <Label>{intl.formatMessage({ id: "district" })}</Label>
            <Text>{formatString(ecommerceInfo.district)}</Text>
          </Cell>
        </Row>
        <Row>
          <Cell>
            <Label>{intl.formatMessage({ id: "document" })}</Label>
            <Text>{formatString(ecommerceInfo.cnpj)}</Text>
          </Cell>
          <Cell>
            <Label>{intl.formatMessage({ id: "city" })}</Label>
            <Text>{formatString(ecommerceInfo.city)}</Text>
          </Cell>
        </Row>
        <Row>
          <Cell>
            <Label>{intl.formatMessage({ id: "telephone" })}</Label>
            <Text>{formatString(ecommerceInfo.telephone)}</Text>
          </Cell>
          <Cell>
            <Label>{intl.formatMessage({ id: "state" })}</Label>
            <Text>{formatString(ecommerceInfo.state)}</Text>
          </Cell>
        </Row>
        <Row>
          <Cell>
            <Label>{intl.formatMessage({ id: "zip_code" })}</Label>
            <Text>{formatString(ecommerceInfo.cep)}</Text>
          </Cell>
          <Cell>
            <Label>{intl.formatMessage({ id: "taxes" })}</Label>
            <Text>{formatString(ecommerceInfo.taxType)}</Text>
          </Cell>
        </Row>
        <Row>
          <Cell>
            <Label>{intl.formatMessage({ id: "invoice_email" })}</Label>
            <Text>{formatString(ecommerceInfo.billingEmail)}</Text>
            <AlertText>
              {intl.formatMessage({ id: "invoice_alert_text" })}
            </AlertText>
          </Cell>
        </Row>
      </Card>
      <Checkbox onChange={onChangeCheck}>
        {intl.formatMessage({ id: "chleba_authentication_confirmation" })}
      </Checkbox>
      <Button disabled={!isChecked} onClick={onSubmit}>
        <ButtonLabel className={!isChecked && "disabled"}>
          {intl.formatMessage({ id: "access_ecommerce" })}
        </ButtonLabel>
      </Button>
    </Container>
  );
};

export default Ecommerce;
