import React, { useEffect, useRef, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useSelector } from "react-redux";
import { useHistory } from "react-router";
import AnimatedMainContainer from "../../../components/AnimatedMainContainer";
import { useToast } from "../../../hooks/toast";
import * as Yup from "yup";
import getValidationErrors from "../../../utils/getValidationErrors";
import api from "../../../service";
import { useCallback } from "react";
import InputWithLabel from "../../../components/InputWithLabel";
import MaskedInputWithLabel from "../../../components/MaskedInputWithLabel";
import FormButton from "../../../components/FormButton";
import { Form } from "@unform/web";
import { validateSingleFieldOnBlur } from "../../../utils/formValidations";

import {
  Container,
  FormContainer,
  TitleContainer,
  Title,
  FieldsContainer,
  Horizontal,
  FilterActionsContainer,
  SubmitContainer,
  LinkButton,
  ActionContainer,
  BadgeContainer,
  NotificationContainer,
} from "./styles";
import Table from "../../../components/Table";
import Badge from "../../../components/Badge";
import ConfirmationModal from "../../../components/ConfirmationModal";
import { useLoading } from "../../../hooks/loading";
import SelectWithLabel from "../../../components/SelectWithLabel";
import useGenericDocumentMask from "../../../utils/useGenericDocumentMask";

const AdminAssociateCollaborators = () => {
  const { token } = useSelector((state) => state.auth);
  const [data, setData] = useState([]);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [collaboratorId, setCollaboratorId] = useState(null);
  const formRef = useRef(null);
  const intl = useIntl();
  const history = useHistory();
  const { addToast } = useToast();
  const { handleLoading } = useLoading();
  const [loading, setLoading] = useState(false);
  const [users, setUsers] = useState([]);
  const [filtered, setFiltered] = useState(false);
  const [documentMask, setDocumentMask] = useGenericDocumentMask();

  const [totalRows, setTotalRows] = useState(0);
  const [perPage, setPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [submittedForm, setSubmittedForm] = useState({
    document: "",
    name: "",
    userId: 0,
  });

  useEffect(()=>{
    const params = new URLSearchParams(document.location.search);
    if(!users) return
    if(!params.get("name")) return
    formRef.current.setFieldValue('name', params.get("name"))
    if(params.get("user")) formRef.current.setFieldValue('user', {
      label: users.filter(
        (i) => i.value === Number(params.get("user"))
      )[0]?.label,
      value: params.get("user")
    },)
    if(params.get("document")) formRef.current.setFieldValue('document', params.get("document"))

    const formData = {
      name: params.get("name"),
      document: params.get("document"),
      userId: params.get("user"),
    };

    setSubmittedForm(formData);
    fetchData(0, perPage, formData, true);
  },[users])

  const schema = Yup.object().shape({
    document: Yup.string(),
    name: Yup.string()
      .nullable()
      .required(intl.formatMessage({ id: "error.field_required" })),
    user: Yup.string(),
  });

  const fetchData = useCallback(
    async (
      page,
      size = perPage,
      requestBody = submittedForm,
      resetCurrentPage
    ) => {
      setLoading(true);
      if (loading) return;

      if (resetCurrentPage) setCurrentPage(1);

      api
        .patch(
          `/associate-collaborators/admin?page=${page}&per_page=${size}`,
          requestBody,
          {
            headers: {
              "Content-Type": "application/json",
              Accept: "*/*",
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((result) => {
          setData(result.data.associateCollaboratorListOutput);
          setTotalRows(result.data.paginationInfo.totalElements);
          setFiltered(true);
        })
        .catch((err) =>
          addToast({
            type: "error",
            title: intl.formatMessage({
              id: "error",
            }),
            description: intl.formatMessage({
              id: "api.error." + err.response.data.code,
            }),
          })
        )
        .finally(() => setLoading(false));
    },
    [addToast, intl, loading, perPage, submittedForm, token]
  );

  const showConfirmation = () => {
    setModalIsOpen(true);
  };

  useEffect(() => {
    handleLoading(true);
    api
      .get(`/users/active-exhibitors-and-service-providers`, {
        headers: {
          "Content-Type": "application/json",
          Accept: "*/*",
          Authorization: `Bearer ${token}`,
        },
      })
      .then((result) => {
        const users = result.data.users.map((user) => ({
          label: user.commercialName,
          value: user.id,
        }));
        setUsers(users);
      })
      .catch((err) =>
        addToast({
          type: "error",
          title: intl.formatMessage({
            id: "error",
          }),
          description: intl.formatMessage({
            id: "api.error." + err.response.data.code,
          }),
        })
      )
      .finally(() => handleLoading(false));
  }, [addToast, intl, token, handleLoading]);

  const handleSubmit = useCallback(
    async (data) => {
      try {
        formRef.current?.setErrors({});

        await schema.validate(data, { abortEarly: false });

        setLoading(true);
        if (loading) {
          return;
        }

        const formData = {
          name: data.name,
          document: data.document,
          userId: data.user,
        };

        setSubmittedForm(formData);
        fetchData(0, perPage, formData, true);
      } catch (err) {
        setLoading(false);
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);

          formRef.current?.setErrors(errors);

          return;
        }
      }
    },
    [fetchData, loading, perPage, schema]
  );

  const handleDeletion = () => {
    handleLoading(true);
    api
      .delete(`/associate-collaborators/admin/${collaboratorId}`, {
        headers: {
          "Content-Type": "application/json",
          Accept: "*/*",
          Authorization: `Bearer ${token}`,
        },
      })
      .then((_) => {
        addToast({
          type: "success",
          title: intl.formatMessage({
            id: "success",
          }),
          description: intl.formatMessage({
            id: "success.collaborator_deletion",
          }),
        });
        setData(data.filter((value) => value.id !== collaboratorId));
        setCollaboratorId(null);
        setModalIsOpen(false);
      })
      .catch((err) => {
        setCollaboratorId(null);
        addToast({
          type: "error",
          title: intl.formatMessage({
            id: "error",
          }),
          description: intl.formatMessage({
            id: "api.error." + err.response.data.code,
          }),
        });
      })
      .finally(() => {
        handleLoading(false);
      });
  };

  const handlePageChange = (page) => {
    fetchData(page - 1);
    setCurrentPage(page);
  };

  const handlePerRowsChange = async (newPerPage, page) => {
    fetchData(page - 1, newPerPage);
    setPerPage(newPerPage);
  };

  const validationBlur = (name) => {
    let errors = formRef.current.getErrors();

    validateSingleFieldOnBlur(errors, schema, name, formRef);
  };

  const clearFields = () => {
    formRef.current.reset();
    formRef.current.setFieldValue("user");
    formRef.current.setFieldValue("name");
    formRef.current.setFieldValue("document");
    setSubmittedForm({
      document: "",
      name: "",
      userId: 0,
    })
    setFiltered(false);
  };

  const columns = [
    {
      name: intl.formatMessage({ id: "name" }),
      selector: (row) => row.name,
      sortable: true,
    },
    {
      name: intl.formatMessage({ id: "document_filter" }),
      selector: (row) => row.document,
      minWidth: "150px",
      maxWidth: "200px",
    },
    {
      name: intl.formatMessage({ id: "assignment" }),
      selector: (row) => row.jobTitle,
      sortable: true,
    },
    {
      name: intl.formatMessage({ id: "company" }),
      selector: (row) => row.company,
      sortable: true,
    },
    {
      cell: (row) => (
        <>
          <LinkButton onClick={()=> handleEditClick(row.id)}>
            <Badge color="#002244" text={intl.formatMessage({ id: "edit" })} />
          </LinkButton>

          <Badge
            onClick={() => {
              setCollaboratorId(row.id);
              showConfirmation();
            }}
            color="#cc0000"
            text={intl.formatMessage({ id: "delete" })}
          />
        </>
      ),
      right: true,
    },
  ];

  const buildReturnUrl = ()=>{
    const initialURL = `/admin/associate-collaborators?name=${submittedForm.name}`
    const partialURLUser = !submittedForm.userId ? "" : `&user=${submittedForm.userId}`
    const partialURLDocument = !submittedForm.document ? "" : `&document=${submittedForm.document}`

    return initialURL + partialURLUser + partialURLDocument
  }

  const handleEditClick = (id) =>{
    history.push(buildReturnUrl())
    history.push(`/admin/associate-collaborator/edit/${id}`)
  }

  return (
    <AnimatedMainContainer
      breadcrumb={intl.formatMessage({
        id: "breadcrumb.consult_registered_collaborators",
      })}
    >
      <Container>
        <FormContainer>
          <Form
            ref={formRef}
            onSubmit={handleSubmit}
          >
            <TitleContainer>
              <Title>
                <FormattedMessage id="search_for_collaborators" />
              </Title>
              <ActionContainer>
                <BadgeContainer onClick={() => history.push("/dashboard")}>
                  <Badge
                    color="#C9C9C9"
                    text={intl.formatMessage({ id: "goback" })}
                    fontSize="12px"
                  />
                </BadgeContainer>
              </ActionContainer>
            </TitleContainer>
            <FieldsContainer>
              <Horizontal>
                <SelectWithLabel
                  name="user"
                  validationBlur={validationBlur}
                  label={intl.formatMessage({ id: "company" })}
                  placeholder={intl.formatMessage({ id: "company" })}
                  options={users}
                  clearable
                />
                <InputWithLabel
                  name="name"
                  validationBlur={validationBlur}
                  label={intl.formatMessage({ id: "name" })}
                  required
                />
                <MaskedInputWithLabel
                  name="document"
                  mask={documentMask}
                  maskChar={null}
                  defaultValue={submittedForm.document}
                  onChange={setDocumentMask}
                  validationBlur={validationBlur}
                  label={intl.formatMessage({ id: "document_cpf_or_passport" })}
                />
              </Horizontal>
              <Horizontal>
                <FilterActionsContainer>
                  <SubmitContainer>
                    <FormButton
                      color="#C9C9C9"
                      type="button"
                      onClick={clearFields}
                    >
                      <FormattedMessage id="clear_fields" />
                    </FormButton>
                  </SubmitContainer>
                  <SubmitContainer>
                    <FormButton type="submit" loading={loading}>
                      <FormattedMessage id="search" />
                    </FormButton>
                  </SubmitContainer>
                </FilterActionsContainer>
              </Horizontal>
            </FieldsContainer>
          </Form>
        </FormContainer>
      </Container>
      {filtered ? (
        <>
          <Table
            columns={columns}
            data={data}
            title={intl.formatMessage({ id: "results" })}
            progressPending={loading}
            paginationServer
            paginationTotalRows={totalRows}
            paginationDefaultPage={currentPage}
            onChangeRowsPerPage={handlePerRowsChange}
            onChangePage={handlePageChange}
          />
        </>
      ) : (
        <NotificationContainer>
          <FormattedMessage id="need_to_filter_first" />
        </NotificationContainer>
      )}
      <ConfirmationModal
        setIsOpen={setModalIsOpen}
        modalIsOpen={modalIsOpen}
        confirmationFunction={handleDeletion}
        description={intl.formatMessage({ id: "exclusion_confirmation" })}
        confirmationText={intl.formatMessage({ id: "yes" })}
        cancelText={intl.formatMessage({ id: "no" })}
      />
    </AnimatedMainContainer>
  );
};

export default AdminAssociateCollaborators;
