import React from "react";

import { useIntl } from "react-intl";

import Input from "../../../newUXComponents/Input";
import Select from "../../../newUXComponents/Select";

import { validation } from "./validations";

import InputMask from "react-input-mask";

import { ContainerInscription } from "./styles";

const FirstForm = ({
  optionsProvision,
  taxesByPerson,
  types,
  countries,
  documentLabel,
  documentMask,
  refForm,
}) => {
  const intl = useIntl();
  return (
    <>
      <Select
        name="type"
        label={intl.formatMessage({ id: "type" })}
        showArrow
        allowClear
        placeholder={intl.formatMessage({ id: "type" })}
        rules={validation("type", intl)}
        options={types}
        onChange={() => {
          refForm.setFieldsValue({ document: "" });
        }}
      />

      <Input
        type="text"
        name="name"
        label={intl.formatMessage({ id: "name_lastname" })}
        placeholder={intl.formatMessage({ id: "name_lastname" })}
        rules={validation("name", intl)}
        maxLength={255}
      />

      <Input
        type="text"
        name="companyName"
        label={intl.formatMessage({ id: "corporate_name" })}
        placeholder={intl.formatMessage({ id: "corporate_name" })}
        rules={validation("corporateName", intl)}
        maxLength={255}
      />

      <Input
        type="text"
        name="document"
        as={InputMask}
        mask={documentMask}
        label={documentLabel}
        placeholder={documentLabel}
        rules={validation("document", intl)}
      />

      <ContainerInscription>
        <Input
          type="text"
          as={InputMask}
          mask="***************"
          name="stateInscription"
          label={intl.formatMessage({ id: "state_inscription" })}
          placeholder={intl.formatMessage({ id: "state_inscription" })}
          rules={validation("stateInscription", intl)}
        />

        <Input
          type="text"
          name="cityInscription"
          as={InputMask}
          mask="***************"
          label={intl.formatMessage({ id: "city_inscription" })}
          placeholder={intl.formatMessage({ id: "city_inscription" })}
          rules={validation("cityInscription", intl)}
        />
      </ContainerInscription>

      <Select
        name="country"
        label={intl.formatMessage({ id: "country" })}
        placeholder={intl.formatMessage({ id: "country" })}
        showArrow
        allowClear
        rules={validation("country", intl)}
        options={countries}
        disabled
      />
      <Input
        type="email"
        name="email"
        label={intl.formatMessage({ id: "email" })}
        placeholder={intl.formatMessage({ id: "email" })}
        rules={validation("email", intl)}
        maxLength={255}
        disabled
      />
      <Select
        name="taxes"
        label={intl.formatMessage({ id: "taxes" })}
        showArrow
        placeholder={intl.formatMessage({ id: "taxes" })}
        rules={validation("taxes", intl)}
        options={taxesByPerson}
      />
      <Select
        name="provisionTypes"
        label={intl.formatMessage({ id: "types_of_service" })}
        mode="multiple"
        showArrow
        placeholder={intl.formatMessage({ id: "types_of_service" })}
        rules={validation("typesOfService", intl)}
        options={optionsProvision}
      />
    </>
  );
};

export default FirstForm;
