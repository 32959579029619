import React from "react";

import { Container, CustomInput, InputSearch } from "./styles";

const Input = ({
  label,
  icon,
  type,
  disabled,
  name,
  rules,
  tooltip,
  isTelephone = false,
  enterButton = false,
  onSearch = () => {},
  ...props
}) => {
  return (
    <Container
      name={name}
      rules={rules}
      label={label}
      $isTelephone={isTelephone}
      tooltip={tooltip}
    >
      {enterButton ? (
        <InputSearch
          disabled={disabled}
          type={type}
          enterButton={enterButton}
          onSearch={onSearch}
          {...props}
        />
      ) : (
        <CustomInput
          disabled={disabled}
          type={type}
          icon={icon}
          $isTelephone={isTelephone}
          {...props}
        />
      )}
    </Container>
  );
};

export default Input;
