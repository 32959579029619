export function getInfoFaq(callbackError) {
  return {
    type: "GET_INFO_FAQ",
    payload: {
      callbackError,
    },
  };
}

export function setInfoFaqSuccess(faq) {
  return {
    type: "SET_INFO_FAQ",
    payload: {
      faq,
    },
  };
}
