import axios from "axios";

export const getEventsService = async (adminToken) =>
  await axios.get(`${process.env.REACT_APP_SERVER_URL}admin/events`, {
    headers: {
      "Content-Type": "application/json",
      Accept: "*/*",
      Authorization: `Bearer ${adminToken}`,
    },
  });

export const getAccountsByEventAndTypeUserService = async (
  params,
  adminToken
) =>
  await axios.patch(
    `${process.env.REACT_APP_SERVER_URL}users/by-event-and-role`,
    params,
    {
      headers: {
        "Content-Type": "application/json",
        Accept: "*/*",
        Authorization: `Bearer ${adminToken}`,
      },
    }
  );

export const changeAdminStaffSignInService = async (params, adminToken) =>
  await axios.post(
    `${process.env.REACT_APP_SERVER_URL}auth/change-admin-staff-sign-in`,
    params,
    {
      headers: {
        "Content-Type": "application/json",
        Accept: "*/*",
        Authorization: `Bearer ${adminToken}`,
      },
    }
  );

export const handleReturnToUserService = async (params, adminToken) =>
  await axios.post(
    `${process.env.REACT_APP_SERVER_URL}auth/change-admin-staff-sign-in`,
    params,
    {
      headers: {
        "Content-Type": "application/json",
        Accept: "*/*",
        Authorization: `Bearer ${adminToken}`,
      },
    }
  );
