import React, { useEffect, useRef, useState, useMemo } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useSelector } from "react-redux";
import { useHistory } from "react-router";
import AnimatedMainContainer from "../../components/AnimatedMainContainer";
import { useToast } from "../../hooks/toast";
import * as Yup from "yup";
import getValidationErrors from "../../utils/getValidationErrors";
import api from "../../service";
import { useCallback } from "react";
import FormButton from "../../components/FormButton";
import { Form } from "@unform/web";
import { validateSingleFieldOnBlur } from "../../utils/formValidations";
import { base64ToBlob, downloadBlob } from "../../utils/file";
import DatePickerWithLabel from "../../components/DatePickerWithLabel";
import { convertToYearOnly } from "../../utils/date";
import { isBefore, isEqual } from "date-fns";

import {
  Container,
  FormContainer,
  TitleContainer,
  Title,
  FieldsContainer,
  Horizontal,
  FilterActionsContainer,
  SubmitContainer,
  GoBackContainer,
  ActionContainer,
  BadgeContainer,
  LinkButton,
  NotificationContainer,
} from "./styles";
import SelectWithLabel from "../../components/SelectWithLabel";
import Badge from "../../components/Badge";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import { useLoading } from "../../hooks/loading";
import Table from "../../components/Table";
import Report from './report';

const ManageCustomersJourneyReport = () => {
  const { token } = useSelector((state) => state.auth);
  const formRef = useRef(null);
  const intl = useIntl();
  const history = useHistory();
  const { addToast } = useToast();
  const { handleLoading } = useLoading();
  const [loading, setLoading] = useState(false);
  const [events, setEvents] = useState([]);
  const [data, setData] = useState([]);
  const [showExport, setShowExport] = useState(false);
  const [filtered, setFiltered] = useState(false);
  const [filename, setFilename] = useState();
  const [eventStartDate, setEventStartDate] = useState();

  const schema = Yup.object().shape({
    event: Yup.string()
      .nullable()
      .required(intl.formatMessage({ id: "error.field_required" })),
    initial_date: Yup.string()
      .nullable()
      .required(intl.formatMessage({ id: "error.field_required" })),
    final_date: Yup.string()
      .nullable()
      .test(
        "is-incorrect",
        intl.formatMessage({
          id: "error.initial_date_later",
        }),
        () => {
          const initial_date = formRef.current.getFieldValue("initial_date");
          const final_date = formRef.current.getFieldValue("final_date");

          if (initial_date && final_date) {
            return (
              isBefore(new Date(initial_date), new Date(final_date)) ||
              isEqual(new Date(initial_date), new Date(final_date))
            );
          }
          return true;
        }
      )
      .test(
        "is-incorrect2",
        intl.formatMessage({ id: "error.no_initial_date" }),
        () => {
          const initial_date = formRef.current.getFieldValue("initial_date");
          const final_date = formRef.current.getFieldValue("final_date");

          if (!initial_date && final_date) {
            return false;
          }

          return true;
        }
      )
  });

  const handleSubmit = useCallback(
    async (data) => {
      try {
        formRef.current?.setErrors({});

        setLoading(true);
        if (loading) {
          return;
        }

        await schema.validate(data, { abortEarly: false });

        api
          .patch(
            `/admin/manage-action-control-and-event-indicators`,
            {
              eventEditionCode: data.event,
              initialDate: data.initial_date && new Date(data.initial_date),
              finalDate: data.final_date && new Date(data.final_date),
            },
            {
              headers: {
                "Content-Type": "application/json",
                Accept: "*/*",
                Authorization: `Bearer ${token}`,
              },
            }
          )
          .then((result) => {
            setData(result.data.output);
            setShowExport(true);
          })
          .catch((err) => {
            setData([]);
            setShowExport(false);
            addToast({
              type: "error",
              title: intl.formatMessage({
                id: "error",
              }),
              description: intl.formatMessage({
                id: "api.error." + err.response.data.code,
              }),
            });
          })
          .finally(() => {
            setLoading(false);
            setFiltered(true);
          });
      } catch (err) {
        setLoading(false);
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);

          formRef.current?.setErrors(errors);

          return;
        }
      }
    },
    [addToast, intl, loading, schema, token]
  );

  useEffect(() => {
    handleLoading(true);
    api
      .get(`admin/events`, {
        headers: {
          "Content-Type": "application/json",
          Accept: "*/*",
          Authorization: `Bearer ${token}`,
        },
      })
      .then((result) => {
        const events = result.data.output.map((event) => ({
          label: event.eventEditionName,
          value: event.eventEditionCode,
          startDate: event.eventStartDate,
        }));
        let eventsSort = [...events].sort((a, b) =>
          a.label < b.label ? -1 : a.label > b.label ? 1 : 0
        );
        setEvents(eventsSort);
      })
      .catch((err) => {
        addToast({
          type: "error",
          title: intl.formatMessage({
            id: "error",
          }),
          description: intl.formatMessage({
            id: "api.error." + err.response.data.code,
          }),
        });
      })
      .finally(() => {
        handleLoading(false);
      });
  }, [addToast, intl, token, handleLoading]);

  const validationBlur = (name) => {
    let errors = formRef.current.getErrors();
    validateSingleFieldOnBlur(errors, schema, name, formRef);
  };

  const clearFields = () => {
    formRef.current.reset();
    formRef.current.setFieldValue("event", "");
    setShowExport(false);
    setData([]);
    setFiltered(false);
  };

  const onChangeEvent = (event) => {
    setFilename(event?.label?.toUpperCase());
    setEventStartDate(event?.startDate);
    setShowExport(false);
    setData([]);
    setFiltered(false);
  };

  const columns = useMemo(
    () => [
      {
        name: intl.formatMessage({ id: "date" }),
        selector: (row) => row.date,
        allowOverflow: true,
        wrap: true,
        minWidth: "70px",
      },
      {
        name: intl.formatMessage({ id: "exhibitors_quantity" }),
        selector: (row) => row.totalOpportunities,
        allowOverflow: true,
        wrap: true,
        minWidth: "100px",
      },
      {
        name: intl.formatMessage({ id: "sign_in_access" }),
        selector: (row) => row.totalSignIns,
        allowOverflow: true,
        wrap: true,
        minWidth: "100px",
      },
      {
        name: intl.formatMessage({ id: "sign_in_access_percentage" }),
        selector: (row) => row.accessPercentage,
        allowOverflow: true,
        wrap: true,
        minWidth: "100px",
      },
      {
        name: intl.formatMessage({ id: "ecommerce_sales" }),
        selector: (row) => row.totalEcommerceSalesValue,
        allowOverflow: true,
        wrap: true,
        minWidth: "150px",
      },
      {
        name: intl.formatMessage({ id: "registered_credentials" }),
        selector: (row) => row.totalCredentials,
        allowOverflow: true,
        wrap: true,
        minWidth: "100px",
      },
      {
        name: intl.formatMessage({ id: "associated_assemblers" }),
        selector: (row) => row.totalRegisteredAssemblersProviders,
        allowOverflow: true,
        wrap: true,
        minWidth: "100px",
      },
    ],
    [intl]
  );

  return (
    <AnimatedMainContainer
      breadcrumb={intl.formatMessage({
        id: "breadcumb.customers_journey_report",
      })}
    >
      <Container>
        <FormContainer>
          <Form ref={formRef} onSubmit={handleSubmit}>
            <TitleContainer>
              <Title>
                <FormattedMessage id="export_customers_journey_report" />
              </Title>
              <GoBackContainer>
                <ActionContainer>
                  <BadgeContainer>
                    <LinkButton onClick={() => history.push("/dashboard")}>
                      <Badge
                        color="#002244"
                        text={intl.formatMessage({ id: "goback" })}
                        fontSize="12px"
                      />
                    </LinkButton>
                  </BadgeContainer>
                </ActionContainer>
              </GoBackContainer>
            </TitleContainer>
            <FieldsContainer>
              <Horizontal>
                <SelectWithLabel
                  name="event"
                  validationBlur={validationBlur}
                  label={intl.formatMessage({ id: "event_edition" })}
                  placeholder={intl.formatMessage({ id: "event_edition" })}
                  labelOrientation="vertical"
                  options={events}
                  onChange={onChangeEvent}
                  required
                />
              </Horizontal>
              <Horizontal>
                <DatePickerWithLabel
                  name="initial_date"
                  validationBlur={validationBlur}
                  label={intl.formatMessage({ id: "initial_date" })}
                  validateEnd={() => validationBlur("final_date")}
                  labelOrientation="vertical"
                  required
                />
                <DatePickerWithLabel
                  name="final_date"
                  validationBlur={validationBlur}
                  label={intl.formatMessage({ id: "final_date" })}
                  labelOrientation="vertical"
                />
              </Horizontal>
              <Horizontal>
                <FilterActionsContainer>
                  <SubmitContainer>
                    <FormButton
                      color="#C9C9C9"
                      type="button"
                      onClick={clearFields}
                    >
                      <FormattedMessage id="clear_fields" />
                    </FormButton>
                  </SubmitContainer>
                  <SubmitContainer>
                    <FormButton type="submit" loading={loading}>
                      <FormattedMessage id="search" />
                    </FormButton>
                  </SubmitContainer>
                  {showExport ? (
                    <SubmitContainer>
                      <FormButton type="button" loading={loading}>
                        <Report
                          filename={`${filename}_ACTION_CONTROL_AND_EVENT_INDICATORS_REPORT`}
                          data={data}
                        >
                          <FormattedMessage id="export" />
                        </Report>
                      </FormButton>
                    </SubmitContainer>
                  ) : null}
                </FilterActionsContainer>
              </Horizontal>
            </FieldsContainer>
          </Form>
        </FormContainer>
      </Container>
      {filtered ? (
        <Table
          columns={columns}
          data={data}
          title={intl.formatMessage({ id: "results" })}
          hasPagination
        />
      ) : (
        <NotificationContainer>
          <FormattedMessage id="need_to_filter_first" />
        </NotificationContainer>
      )}
    </AnimatedMainContainer>
  );
};

export default ManageCustomersJourneyReport;
