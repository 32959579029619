export function getAllAssociateCompany(params, callbackSuccess, callbackError) {
  return {
    type: "GET_ALL_ASSOCIATE_COMPANY",
    payload: {
      params,
      callbackSuccess,
      callbackError,
    },
  };
}

export function setAllAssociateCompanySuccess(associatedCompanies) {
  return {
    type: "SET_ALL_ASSOCIATE_COMPANY",
    payload: {
      associatedCompanies,
    },
  };
}

export function getUserDocument(document, callbackSuccess, callbackError) {
  return {
    type: "GET_USER_DOCUMENT",
    payload: {
      document,
      callbackSuccess,
      callbackError,
    },
  };
}

export function createAssociateCompany(data, callbackSuccess, callbackError) {
  return {
    type: "CREATE_ASSOCIATE_COMPANY",
    payload: {
      data,
      callbackSuccess,
      callbackError,
    },
  };
}

export function removeAssociationCompany(id, callbackSuccess, callbackError) {
  return {
    type: "REMOVE_ASSOCIATE_COMPANY",
    payload: {
      id,
      callbackSuccess,
      callbackError,
    },
  };
}

export function updateLoginPermission(params, callbackSuccess, callbackError) {
  return {
    type: "UPDATE_LOGIN_PERMISSION",
    payload: {
      params,
      callbackSuccess,
      callbackError,
    },
  };
}
