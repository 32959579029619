import React, {
  useMemo,
  useCallback,
  useState,
  useEffect,
  useContext,
} from "react";
import { Form } from "antd";
import { useIntl } from "react-intl";
import { useHistory } from "react-router-dom";

import FileSaver from "file-saver";

import useNotification from "../../../../hooks/newUXNotification";
import { useLoading } from "../../../../hooks/loading";

import { useDispatch, useSelector } from "react-redux";

import { DialogContext } from "../../../../hooks/dialog/context";

import {
  getCollaboratorOfContract,
  handleAssociateCredential,
  updateAssociateCredential,
} from "../../../../redux/modules/credentials/actions";

import CustomModal from "../../../../components/CustomModal";
import ConfirmationModal from "../../../../newUXComponents/Modal/ConfirmationModal";

import Button from "../../../../newUXComponents/Button";
import Input from "../../../../newUXComponents/Input";
import Select from "../../../../newUXComponents/Select";
import Edit from "../../../../newUXAssets/icons/Edit";

import {
  Container,
  Header,
  Divider,
  Content,
  ContainerButton,
  Icon,
} from "./styles";

import { INITIAL_VALUES, validation } from "./validations";

const FormModal = ({
  open,
  handleClose,
  id,
  data,
  isExhibitor,
  typesCredentials,
  idContract,
  eventId,
  callbackSuccess,
}) => {
  const intl = useIntl();
  const [form] = Form.useForm();
  const history = useHistory();
  const dispatch = useDispatch();
  const notification = useNotification();
  const { handleLoading } = useLoading();
  const { createDialog } = useContext(DialogContext);

  const { user } = useSelector((state) => state.auth);

  const [optionsCollaborator, setOptionsCollaborator] = useState([]);

  const filterSelectedCollaboratorsWithoutLastNameOrEmail = (formData) => {
    const collaboratorError = optionsCollaborator.map((collaborator) => {
      const item = formData.collaborator.find(
        (item) =>
          item === collaborator.value &&
          (!collaborator.email || !collaborator.lastName)
      );

      if (item) {
        return collaborator;
      }
    });

    return collaboratorError.filter(Boolean);
  };

  const generateCollaboratorsWithoutLastNameOrEmailTxtText = (ids) => {
    let data =
      intl.formatMessage({
        id: "collaborators_without_last_name_email_file_message",
      }) + "\n\n";

    let collaboratorLabelList = ids.map((collaborator) => collaborator.label);

    return data + collaboratorLabelList.join("\n");
  };

  const downloadCollaboratorsWithoutLastNameOrEmailTxt = (
    listCollaborators
  ) => {
    const collaboratorsTxtData = new Blob(
      [generateCollaboratorsWithoutLastNameOrEmailTxtText(listCollaborators)],
      {
        type: "text/plain",
      }
    );
    FileSaver.saveAs(
      collaboratorsTxtData,
      "collaborators_without_last_name_and_email.txt"
    );
  };

  const handleModalConfirmation = (errorCollaborators) => {
    createDialog({
      id: "modal-error-without-last-name-email",
      Component: ConfirmationModal,
      props: {
        title: intl.formatMessage({
          id: "associate_credential",
        }),
        message: intl.formatMessage({
          id: "collaborators_without_last_name_email_modal",
        }),
        confirmation: () =>
          downloadCollaboratorsWithoutLastNameOrEmailTxt(errorCollaborators),
      },
    });
  };

  const handleEditNameCredential = () => {
    handleClose();
    history.push("/user/update-user-exhibitor");
  };

  const callbackhandleAssociateCredentialSuccess = () => {
    handleLoading(false);
    if (data?.id) {
      notification.displaySuccess(
        intl.formatMessage({
          id: "success.credential_updated",
        }),
        "update_credentials-associate-success"
      );
    } else {
      notification.displaySuccess(
        intl.formatMessage({
          id: "success.credentials_associated",
        }),
        "get-credentials-associate-success"
      );
    }
    callbackSuccess();
    handleClose();
  };

  const callbackError = useCallback(
    (message) => {
      handleLoading(false);
      notification.displayError(
        intl.formatMessage({
          id: "api.error." + message,
        }),
        "get-collaborator-error"
      );
    },
    [notification, intl, handleLoading]
  );

  const onFinish = () => {
    const formData = form.getFieldsValue(true);

    if (!data?.id) {
      const errorCollaborators =
        filterSelectedCollaboratorsWithoutLastNameOrEmail(formData);

      if (isExhibitor && errorCollaborators.length > 0) {
        handleModalConfirmation(errorCollaborators);
        return;
      }
    }

    if (data?.id) {
      handleLoading(true);
      dispatch(
        updateAssociateCredential(
          {
            ...formData,
            eventId: eventId,
            opportunityId: idContract,
            id: data?.id,
          },
          callbackhandleAssociateCredentialSuccess,
          callbackError
        )
      );
    } else {
      dispatch(
        handleAssociateCredential(
          {
            eventId: eventId,
            opportunityId: idContract,
            associateCollaboratorList: formData.collaborator,
            categoryId:
              formData.credentialType || formData.credentialType.value,
          },
          callbackhandleAssociateCredentialSuccess,
          callbackError
        )
      );
    }
  };

  const initialValues = useMemo(() => {
    if (data?.id) {
      return {
        associateCollaboratorId: data.associateCollaboratorId,
        categoryId: data.categoryId,
        credentialName: data.credentialName,
        jobTitle: data.associateCollaboratorJobTitle,
        email: data.associateCollaboratorEmail,
      };
    }
    return INITIAL_VALUES;
  }, [data]);

  const credentialTypeOptions = useMemo(() => {
    if (typesCredentials && typesCredentials.length) {
      return typesCredentials.map((item) => ({
        label: item.title,
        value: item.id,
      }));
    }

    return [];
  }, [typesCredentials]);

  const callbackGetCollaboratorSuccess = useCallback((data) => {
    if (data.length) {
      setOptionsCollaborator(data);
    }
  }, []);

  const handleGetCollaborator = (id) => {
    // validar com Douglas se precisa realizar a busca novamente pelos colaboradores caso troque o tipo de credencial
    if (id) {
      dispatch(
        getCollaboratorOfContract(
          {
            idContract,
            idCredential: id,
          },
          callbackGetCollaboratorSuccess,
          callbackError
        )
      );
    }
  };

  const handleUpdateFormItem = (value) => {
    const dataCollaborator = optionsCollaborator.find(
      (item) => item.value === value
    );

    if (dataCollaborator?.value) {
      form.setFieldsValue({
        credentialName: dataCollaborator?.credentialName,
        jobTitle: dataCollaborator?.jobTitle,
        email: dataCollaborator?.email,
      });
    }
  };

  useEffect(() => {
    dispatch(
      getCollaboratorOfContract(
        {
          idContract,
          idCredential: data?.id || 0,
        },
        callbackGetCollaboratorSuccess,
        callbackError
      )
    );

    form.setFieldValue("companyNameCredential", user.credentialName);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [idContract]);

  return (
    <CustomModal id={id} isModalVisible={open} handleClose={handleClose}>
      <Container>
        <Header>
          {data?.id
            ? intl.formatMessage({ id: "edit_association" })
            : intl.formatMessage({ id: "associate_credential" })}
        </Header>
        <Divider />
        <Content>
          <Form
            layout="vertical"
            form={form}
            name="credential-form"
            onFinish={onFinish}
            initialValues={initialValues}
          >
            <Select
              name={data?.id ? "associateCollaboratorId" : "collaborator"}
              label={intl.formatMessage({ id: "collaborator" })}
              allowClear
              placeholder={intl.formatMessage({ id: "collaborator" })}
              rules={validation("collaborator", intl)}
              options={optionsCollaborator}
              {...(!data?.id && { mode: "multiple" })}
              onChange={(value) => {
                handleUpdateFormItem(value);
              }}
            />
            <Select
              name={data?.id ? "categoryId" : "credentialType"}
              label={intl.formatMessage({ id: "credential_type" })}
              allowClear
              placeholder={intl.formatMessage({ id: "credential_type" })}
              rules={validation("credentialType", intl)}
              options={credentialTypeOptions}
              onChange={(value) => handleGetCollaborator(value)}
            />

            {data?.id && (
              <>
                <Input
                  type="text"
                  name="jobTitle"
                  placeholder={intl.formatMessage({
                    id: "assignment",
                  })}
                  label={intl.formatMessage({
                    id: "assignment",
                  })}
                  rules={validation("jobTitle", intl)}
                />

                <Input
                  type="text"
                  name="credentialName"
                  label={intl.formatMessage({ id: "credential_name" })}
                  placeholder={intl.formatMessage({
                    id: "credential_name",
                  })}
                  rules={validation("jobTitle", intl)}
                />

                <Input
                  type="email"
                  name="email"
                  placeholder={intl.formatMessage({
                    id: "email",
                  })}
                  label={intl.formatMessage({
                    id: "email",
                  })}
                  rules={validation("email", intl)}
                />
              </>
            )}

            {isExhibitor && !data?.id && (
              <Input
                type="text"
                name="companyNameCredential"
                label={intl.formatMessage({ id: "company_name_credential" })}
                placeholder={intl.formatMessage({
                  id: "company_name_credential",
                })}
                rules={validation("companyNameCredential", intl)}
                tooltip={{
                  icon: (
                    <Icon onClick={() => handleEditNameCredential()}>
                      <Edit color="#11A7D9" />
                    </Icon>
                  ),
                }}
                disabled
              />
            )}

            <ContainerButton>
              <Button
                type="primary"
                label={intl.formatMessage({ id: "confirm" })}
                htmlType="submit"
              />
              <Button
                type="link"
                label={intl.formatMessage({ id: "cancel" })}
                onClick={() => handleClose()}
              />
            </ContainerButton>
          </Form>
        </Content>
      </Container>
    </CustomModal>
  );
};

export default FormModal;
