import React, { useEffect, useMemo, useRef, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useSelector } from "react-redux";
import { useHistory } from "react-router";
import * as XLSX from "xlsx";
import AnimatedMainContainer from "../../components/AnimatedMainContainer";
import { useToast } from "../../hooks/toast";
import * as Yup from "yup";
import { useCallback } from "react";
import FormButton from "../../components/FormButton";
import { Form } from "@unform/web";
import { validateSingleFieldOnBlur } from "../../utils/formValidations";
import FileSaver from "file-saver";
import Dropzone from "../../components/Dropzone";

import {
  Container,
  FormContainer,
  TitleContainer,
  Title,
  FieldsContainer,
  Horizontal,
  FilterActionsContainer,
  SubmitContainer,
  ActionContainer,
  BadgeContainer,
  SerproActionContainer,
  FileDropzone,
} from "./styles";

import Badge from "../../components/Badge";
import { useLoading } from "../../hooks/loading";
import api from "../../service";
import getValidationErrors from "../../utils/getValidationErrors";
import DatePickerWithLabel from "../../components/DatePickerWithLabel";
import CheckboxInput from "../../components/CheckboxInput";

const UpdateRegistrationDataSerpro = () => {
  const { token } = useSelector((state) => state.auth);
  const [loading, setLoading] = useState(false);
  const formRef = useRef(null);
  const intl = useIntl();
  const history = useHistory();
  const { addToast } = useToast();
  const { handleLoading } = useLoading();
  const [xlsxData, setXlsxData] = useState([]);
  const [isConfirmed, setIsConfirmed] = useState(false);
  const [updateRegistrationDataTimer, setUpdateRegistrationDataTimer] = useState();

  const schema = Yup.object().shape({
    updateExhibitorsFromDate: Yup.string().nullable(),
    file: Yup.array()
      .nullable()
      .min(1, intl.formatMessage({ id: "error.field_required" })),
  });

  const readExcel = useCallback(
    (file) => {
      handleLoading(true);
      new Promise((resolve, reject) => {
        const fileReader = new FileReader();
        fileReader.readAsArrayBuffer(file);
        fileReader.onloadend = (e) => {
          const bufferArray = e.target.result;
          const wb = XLSX.read(bufferArray, {
            type: "buffer",
            cellDates: true,
          });
          const wsname = wb.SheetNames[0];
          const ws = wb.Sheets[wsname];
          const data = XLSX.utils.sheet_to_json(ws);
          resolve(data);
        };

        fileReader.onerror = (error) => {
          reject(error);
        };
      })
        .then((content) => {
          setXlsxData(content);
        })
        .finally(() => handleLoading(false));
    },
    [handleLoading]
  );

  const handleSubmit = useCallback(
    async (data) => {
      try {
        formRef.current?.setErrors({});
        await schema.validate(data, { abortEarly: false });
        setLoading(true);
        if (loading) {
          return;
        }
        await readExcel(data.file[0]);
      } catch (err) {
        setLoading(false);
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);
          formRef.current?.setErrors(errors);
          return;
        }
      }
    },
    [schema, loading, readExcel]
  );

  useEffect(() => {
    if (xlsxData.length > 0) {
      handleLoading(true);
      let inputList = [];
      const updateExhibitorsFromDate = formRef.current.getFieldValue("updateExhibitorsFromDate");

      xlsxData.map((cellValue) => {
        inputList.push(cellValue.CNPJ);
        return inputList;
      });

      api
        .put(
          `/serpro`,
          {
            updateExhibitorsFromDate: updateExhibitorsFromDate && new Date(updateExhibitorsFromDate),
            documents: inputList,
          },
          {
            headers: {
              "Content-Type": "application/json",
              Accept: "*/*",
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((result) => {
          const requestToken = result.data.token;
          verifyUpdateRegistrationDataAsyncStatus(requestToken);
        })
        .catch((err) => {
          addToast({
            type: "error",
            title: intl.formatMessage({
              id: "error",
            }),
            description: intl.formatMessage({
              id: "api.error." + err.response.data.code,
            }),
          });
          setLoading(false);
          handleLoading(false);
        });
    }
  }, [addToast, intl, token, xlsxData, handleLoading]);

  const handleAsyncUpdateRegistrationDataReturn = (requestToken) => {
    api
      .get(`/serpro/return/${requestToken}`, {
        headers: {
          "Content-Type": "application/json",
          Accept: "*/*",
          Authorization: `Bearer ${token}`,
        },
      })
      .then((result) => {
        const ACCEPTED = 202;

        if (result.status === ACCEPTED) {
          verifyUpdateRegistrationDataAsyncStatus(requestToken);
        } else {
          stopUpdateRegistrationDataCalls();
          const successfulOutput = result.data.successfulOutput;
          const errorOutput = result.data.errorOutput;
          if (successfulOutput.length > 0) {
            generateSuccessFilesFromSerproUpdateRegistrationDataResponse(
              successfulOutput
            );
          }
          if (errorOutput.length > 0) {
            generateErrorFilesFromSerproUpdateRegistrationDataResponse(
              errorOutput
            );
          }
          addToast({
            type: "success",
            title: intl.formatMessage({
              id: "success",
            }),
            description: intl.formatMessage({
              id: "success.registration_data_updated",
            }),
          });
          clearFields();
        }
      })
      .catch((err) => {
        addToast({
          type: "error",
          title: intl.formatMessage({
            id: "error",
          }),
          description: intl.formatMessage({
            id: "api.error." + err.response.data.code,
          }),
        });
        stopUpdateRegistrationDataCalls();
      });
  };

  const verifyUpdateRegistrationDataAsyncStatus = (requestToken) => {
    const timerRef = setTimeout(() => {
      handleAsyncUpdateRegistrationDataReturn(requestToken);
      setUpdateRegistrationDataTimer();
    }, 1000);
    setUpdateRegistrationDataTimer(timerRef);
  };

  const stopUpdateRegistrationDataCalls = () => {
    clearTimeout(updateRegistrationDataTimer);
    setUpdateRegistrationDataTimer();
    setLoading(false);
    handleLoading(false);
  };

  const generateSuccessFilesFromSerproUpdateRegistrationDataResponse = (successOutput) => {
    const successColumns = [["ID da conta",
                          "Nome da conta",
                          "CNPJ",
                          "Inscrição Municipal",
                          "Endereço de Nota Fiscal",
                          "Cidade da Nota Fiscal",
                          "Estado/Província de cobrança",
                          "CEP de Nota Fiscal",
                          "Email",
                          "Classificação Fiscal"]];

    const worksheet = XLSX.utils.json_to_sheet(successOutput);
    XLSX.utils.sheet_add_aoa(worksheet, successColumns, { origin: "A1" });

    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "CNPJ Serpro Válidos");
    const xlsBuffer = XLSX.write(workbook, { bookType: "xls", bookSST: false, type: "array" });
    const xlsBlobData = new Blob([xlsBuffer], { type: "application/octet-stream" });
    FileSaver.saveAs(xlsBlobData, "serpro_salesforce.xls");

    const csvOutput = XLSX.utils.sheet_to_csv(worksheet);
    const csvBlobData = new Blob([csvOutput], { type: "text/csv;charset=utf-8" });
    FileSaver.saveAs(csvBlobData, "serpro_data_loader.csv");
  };

  const generateErrorFilesFromSerproUpdateRegistrationDataResponse = (errorOutput) => {
    const errorColumns = [["CNPJ", "Retorno"]];

    const worksheet = XLSX.utils.json_to_sheet(errorOutput);
    XLSX.utils.sheet_add_aoa(worksheet, errorColumns, { origin: "A1" });

    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "CNPJ Serpro Inválidos");
    const xlsBuffer = XLSX.write(workbook, { bookType: "xls", bookSST: false, type: "array" });
    const blobData = new Blob([xlsBuffer], { type: "application/octet-stream" });
    FileSaver.saveAs(blobData, "serpro_cnpj_invalidos.xls");
  };

  const clearFields = () => {
    formRef.current.reset();
    formRef.current.clearField("file");
    setIsConfirmed(false);
  };

  const validationBlur = (name) => {
    let errors = formRef.current.getErrors();

    validateSingleFieldOnBlur(errors, schema, name, formRef);
  };

  const handleDownloadTemplate = () => {
    FileSaver.saveAs(
      `${process.env.PUBLIC_URL}/serpro_template.xlsx`,
      "Serpro_Template.xlsx"
    );
  };

  const checkboxOptions = useMemo(
    () => [
      {
        id: "serpro_confirmation",
        value: true,
        label: intl.formatMessage({
          id: "serpro_confirmation",
        }),
      },
    ],
    [intl]
  );

  return (
    <AnimatedMainContainer
      breadcrumb={intl.formatMessage({ id: "breadcrumb.update_registration_data" })}
    >
      <Container>
        <FormContainer>
          <Form ref={formRef} onSubmit={handleSubmit}>
            <TitleContainer>
              <Title>
                <FormattedMessage id="update_registration_data_serpro" />
              </Title>
              <ActionContainer>
                <BadgeContainer onClick={() => history.push("/dashboard")}>
                  <Badge
                    color="#C9C9C9"
                    text={intl.formatMessage({ id: "goback" })}
                    fontSize="12px"
                  />
                </BadgeContainer>
              </ActionContainer>
            </TitleContainer>
            <FieldsContainer>
              <Horizontal>
                <DatePickerWithLabel
                  label={intl.formatMessage({
                    id: "update_exhibitors_from_date",
                  })}
                  validationBlur={validationBlur}
                  labelWidth="unset"
                  popperPlacement="bottom-start"
                  name="updateExhibitorsFromDate"
                  labelOrientation="horizontal"
                />
              </Horizontal>
              <Horizontal>
                <CheckboxInput
                  name="checkbox"
                  onChange={e => setIsConfirmed(e?.target?.checked)}
                  options={checkboxOptions}
                />
              </Horizontal>
              <Horizontal>
                <FileDropzone>
                  <Dropzone
                    name="file"
                    accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                    type="XLSX"
                    multiple={false}
                  />
                </FileDropzone>
              </Horizontal>
              <Horizontal>
                <FilterActionsContainer>
                  <SubmitContainer>
                    <FormButton
                      color="#C9C9C9"
                      type="button"
                      onClick={clearFields}
                    >
                      <FormattedMessage id="clear_fields" />
                    </FormButton>
                  </SubmitContainer>
                  <SubmitContainer>
                    <FormButton type="submit" disabled={!isConfirmed}>
                      <FormattedMessage id="update_button" />
                    </FormButton>
                  </SubmitContainer>
                </FilterActionsContainer>
              </Horizontal>
            </FieldsContainer>
          </Form>
        </FormContainer>
      </Container>
      <SerproActionContainer>
        <BadgeContainer onClick={() => handleDownloadTemplate()}>
          <Badge
            color="#33d27e"
            text={intl.formatMessage({ id: "download_template" })}
            fontSize="16px"
          />
        </BadgeContainer>
      </SerproActionContainer>
    </AnimatedMainContainer>
  );
};

export default UpdateRegistrationDataSerpro;
