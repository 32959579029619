export const phoneMask = (value) => {
  if (!value) return "";
  value = value.replace(/\D/g, "");
  // value = value.replace(/^(\d)/, "+$1"); Remover caso queira adicionar o +DD
  // value = value.replace(/(.{2})(\d)/, "$1 $2");
  value = value.replace(/(.{2})(\d)/, "$1 $2");
  if (value.length === 6) {
    value = value.replace(/(.{1})$/, " $1");
  } else if (value.length === 7) {
    value = value.replace(/(.{2})$/, " $1");
  } else if (value.length === 8) {
    value = value.replace(/(.{3})$/, " $1");
  } else if (value.length === 9) {
    value = value.replace(/(.{4})$/, " $1");
  } else if (value.length > 10) {
    value = value.replace(/(.{4})$/, " $1");
  }
  return value;
};
