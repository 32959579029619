import React from "react";
import { MdKeyboardArrowDown, MdKeyboardArrowUp } from "react-icons/md";
import { FormattedMessage, useIntl } from "react-intl";
import {
  Container,
  Item,
  Header,
  ValueInformationContainer,
  TextContainer,
  Title,
  Subtitle,
  InfoContainer,
} from "./styles";

const GrowthComponent = ({ growthItems }) => {
  const intl = useIntl();
  return (
    <Container>
      {growthItems?.map((growthItem, index) => (
        <Item key={index}>
          <Title>{growthItem.year}</Title>
          <Header>
            <ValueInformationContainer informationValue={growthItem.value}>
              <TextContainer>
                <FormattedMessage id="value" />
              </TextContainer>

              <InfoContainer>
                {growthItem.value.toFixed(2)}%
                {growthItem.value < 0 && (
                  <MdKeyboardArrowDown size={20} color="#fff" />
                )}
                {growthItem.value > 0 && (
                  <MdKeyboardArrowUp size={20} color="#fff" />
                )}
              </InfoContainer>
              <InfoContainer>{growthItem.amountValue.toFixed(2)}</InfoContainer>
            </ValueInformationContainer>
            <ValueInformationContainer informationValue={growthItem.area}>
              <TextContainer>
                <FormattedMessage id="area" />
              </TextContainer>
              <InfoContainer>
                {growthItem.area.toFixed(2)}%
                {growthItem.area < 0 && (
                  <MdKeyboardArrowDown size={20} color="#fff" />
                )}
                {growthItem.area > 0 && (
                  <MdKeyboardArrowUp size={20} color="#fff" />
                )}
              </InfoContainer>
              <InfoContainer>{growthItem.amountArea.toFixed(2)}</InfoContainer>
            </ValueInformationContainer>
          </Header>

          <Subtitle>
            {intl.formatMessage(
              { id: "in_relation_to" },
              { value: growthItem.year - 1 }
            )}
          </Subtitle>
        </Item>
      ))}
    </Container>
  );
};

export default GrowthComponent;
