import React from "react";

const Check = (props) => {
  const { size = "15", color = "#11A7D9" } = props;

  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 15 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_781_2522)">
        <path
          d="M2.33081 7.99786L5.40085 11.0679L12.4181 4.05066"
          stroke={color}
          strokeWidth="2.55172"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_781_2522">
          <rect
            width="14.0345"
            height="14.0345"
            fill="white"
            transform="translate(0.137939 0.103455)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default Check;
