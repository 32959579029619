import produce from "immer";

const INITIAL_STATE = {
  collaboratorsList: [],
  categories: [],
  paginationInfo: []
};

const employee = (state = INITIAL_STATE, action) => {
  return produce(state, (draft) => {
    switch (action.type) {
      case "SET_NEW_EMPLOYEE_CATEGORIES": {
        const { categories } = action.payload;

        if (!categories) {
          localStorage.removeItem("InformaMarket:employee.categories")
          break
        }

        draft.categories = categories

        localStorage.setItem(
          "InformaMarket:employee.categories",
          JSON.stringify(categories)
        );

        break;
      }

      case "CLEAR_EMPLOYEE": {
        draft.employee = [];
        localStorage.removeItem("InformaMarket:employee.categories");
        localStorage.removeItem("InformaMarket:employee.collaboratorsList");
        localStorage.removeItem("InformaMarket:employee.paginationInfo");
        break;
      }

      case "SET_SINGLE_COLLABORATOR":{
        const { collaborator } = action.payload;

        if (!collaborator) {
          localStorage.removeItem("InformaMarket:employee.collaborator");
          break
        }

        draft.collaborator = collaborator

        localStorage.setItem(
          "InformaMarket:employee.collaborator",
          JSON.stringify(collaborator)
        );
        break;
      }

      case "SET_COLLABORATORS":{
        const { collaboratorsInfo } = action.payload;

        if (!collaboratorsInfo) {
          localStorage.removeItem("InformaMarket:employee.collaboratorsList");
          localStorage.removeItem("InformaMarket:employee.paginationInfo");
          break
        }

        draft.collaboratorsList = collaboratorsInfo.associateCollaboratorListOutput

        localStorage.setItem(
          "InformaMarket:employee.collaboratorsList",
          JSON.stringify(collaboratorsInfo.associateCollaboratorListOutput)
        );

        draft.paginationInfo = collaboratorsInfo.paginationInfo

        localStorage.setItem(
          "InformaMarket:employee.paginationInfo",
          JSON.stringify(collaboratorsInfo.paginationInfo)
        );

        break;
      }

      default: {
        if(localStorage.getItem('InformaMarket:employee.collaboratorsList')){
          const collaboratorsList = JSON.parse(localStorage.getItem('InformaMarket:employee.collaboratorsList'));
          draft.collaboratorsList = collaboratorsList
        }

        if(localStorage.getItem('InformaMarket:employee.paginationInfo')){
          const paginationInfo = JSON.parse(localStorage.getItem('InformaMarket:employee.paginationInfo'));
          draft.paginationInfo = paginationInfo
        }

        if(localStorage.getItem('InformaMarket:employee.collaborator')){
          const collaborator = JSON.parse(localStorage.getItem('InformaMarket:employee.collaborator'));
          draft.collaborator = collaborator
        }

        if(localStorage.getItem('InformaMarket:employee.categories')){
          const categories = JSON.parse(localStorage.getItem('InformaMarket:employee.categories'));
          draft.categories = categories
        }
        return draft;
      }
    }
  });
};

export default employee;
