import produce from "immer";

const INITIAL_STATE = {
  accountsServiceProvider: [],
  accountsExhibitor: [],
};

const accounts = (state = INITIAL_STATE, action) => {
  return produce(state, (draft) => {
    switch (action.type) {
      case "SET_ACCOUNTS_SERVICE_PROVIDER": {
        const { accounts } = action.payload;

        draft.accountsServiceProvider = accounts;

        localStorage.setItem(
          "InformaMarket:accountsServiceProvider",
          JSON.stringify(accounts)
        );

        break;
      }

      case "SET_ACCOUNTS_EXHIBITOR": {
        const { accounts } = action.payload;

        draft.accountsExhibitor = accounts;

        localStorage.setItem(
          "InformaMarket:accountsExhibitor",
          JSON.stringify(accounts)
        );

        break;
      }

      case "CLEAR_ACCOUNTS": {
        draft.accountsExhibitor = [];
        draft.accountsServiceProvider = [];

        localStorage.removeItem("InformaMarket:accountsExhibitor");
        localStorage.removeItem("InformaMarket:accountsServiceProvider");

        break;
      }

      default: {
        return draft;
      }
    }
  });
};

export default accounts;
