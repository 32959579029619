import React from "react";

import { useIntl } from "react-intl";

import CustomModal from "../../../components/CustomModal";

import Button from "../../Button";

import {
  Container,
  ContainerButton,
  Description,
  Divider,
  Header,
} from "./styles";

const ConfirmationModal = ({
  open,
  handleClose,
  id,
  message,
  confirmation,
  title = "",
  labelBtnConfir = "yes",
  labelClose = "no",
  disabledBtnConfirm = false,
}) => {
  const intl = useIntl();

  const handleConfirmationEvent = () => {
    handleClose();
    confirmation();
  };

  return (
    <CustomModal id={id} isModalVisible={open} handleClose={() => {}}>
      <Container>
        {title && (
          <>
            <Header>
              <span>{title}</span>
            </Header>
            <Divider />
          </>
        )}
        <Description>{message}</Description>
        <ContainerButton>
          <Button
            type="primary"
            onClick={() => handleConfirmationEvent()}
            label={intl.formatMessage({ id: labelBtnConfir })}
            disabled={disabledBtnConfirm}
          />
          <Button
            type="link"
            onClick={() => handleClose()}
            label={intl.formatMessage({ id: labelClose })}
          />
        </ContainerButton>
      </Container>
    </CustomModal>
  );
};

export default ConfirmationModal;
