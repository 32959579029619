import React from "react";

const BackArrow = (props) => {
  const { size = '26', color = "#11A7D9" } = props;

  return (
    <svg width={size} height={size} viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M21.9375 13L4.0625 13" stroke={color} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M11.375 20.3125L4.0625 13L11.375 5.6875" stroke={color} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
  );
};

export default BackArrow;
