import React from "react";
import ReactExport from "react-data-export";

const File = ReactExport.ExcelFile;
const Sheet = ReactExport.ExcelFile.ExcelSheet;
const Column = ReactExport.ExcelFile.ExcelColumn;

const Report = ({
  data,
  filename,
  intl,
  opportunityStatusTypes,
  children
}) =>
 (
  <File element={children} filename={filename} fileExtension="xlsx">
    <Sheet data={data} name="Relatório">
      <Column label="evento" value="event" />
      <Column label="razao_social" value="accountName" />
      <Column label="nome_fantasia" value="accountAlias" />
      <Column label="contrato" value="opportunity" />
      <Column
        label="status_oportunidade"
        value={ (col) => opportunityStatusTypes.filter((pt) =>
          pt.value === col.opportunityStatus)[0]?.label }
      />
      <Column label="numero_pedido" value="orderNumber" />
      <Column label="nome_produto_salesforce_pt" value="salesforceProductNamePT" />
      <Column label="nome_produto_salesforce_en" value="salesforceProductNameEN" />
      <Column label="nome_produto_ecommerce_pt" value="ecommerceProductNamePT" />
      <Column label="nome_produto_ecommerce_en" value="ecommerceProductNameEN" />
      <Column label="usuario_efetuou_compra" value="clientName" />
    </Sheet>
  </File>
);
export default Report;