import React from 'react'

const OutlinedPhone = (props) => {
  const {
    size = '16',
    color = '#444444'
  } = props


  return (
    <svg width={size} height={size} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_465_13956)">
        <path d="M10.2744 9.08349C10.3436 9.03742 10.4233 9.00934 10.5061 9.00181C10.5889 8.99428 10.6723 9.00753 10.7488 9.04037L13.6963 10.361C13.7956 10.4034 13.8785 10.4769 13.9326 10.5704C13.9867 10.664 14.009 10.7725 13.9963 10.8797C13.8991 11.6054 13.5418 12.2711 12.9907 12.753C12.4395 13.2349 11.7321 13.5003 11 13.4997C8.74566 13.4997 6.58365 12.6042 4.98959 11.0101C3.39553 9.41609 2.5 7.25408 2.5 4.99974C2.49944 4.26762 2.7648 3.56021 3.24673 3.00908C3.72865 2.45794 4.39435 2.10059 5.12 2.00349C5.22727 1.99075 5.33578 2.01309 5.4293 2.06717C5.52281 2.12125 5.5963 2.20416 5.63875 2.30349L6.95938 5.25349C6.99182 5.32926 7.00504 5.41187 6.99784 5.49398C6.99064 5.57609 6.96326 5.65515 6.91813 5.72412L5.5825 7.31224C5.53512 7.38373 5.50711 7.46629 5.50119 7.55185C5.49528 7.63741 5.51166 7.72304 5.54875 7.80037C6.06563 8.85849 7.15938 9.93912 8.22063 10.451C8.29836 10.4879 8.38439 10.5039 8.47021 10.4975C8.55602 10.491 8.63867 10.4623 8.71 10.4141L10.2744 9.08349Z" stroke={color} strokeLinecap="round" strokeLinejoin="round"/>
      </g>
      <defs>
        <clipPath id="clip0_465_13956">
          <rect width="16" height="16" fill="white"/>
        </clipPath>
      </defs>
    </svg>
  )
}

export default OutlinedPhone