import React from "react";

const WarningTriangle = (props) => {
  const { size = "24", color = "#FDDD48" } = props;

  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_14_8534)">
        <path
          d="M22.2 17.6335L14.0016 3.39569C13.7967 3.04687 13.5042 2.75764 13.1531 2.55668C12.802 2.35572 12.4045 2.25 12 2.25C11.5955 2.25 11.198 2.35572 10.8469 2.55668C10.4958 2.75764 10.2033 3.04687 9.99844 3.39569L1.8 17.6335C1.60288 17.9709 1.49899 18.3546 1.49899 18.7454C1.49899 19.1361 1.60288 19.5199 1.8 19.8572C2.00225 20.2082 2.29422 20.499 2.64596 20.6998C2.9977 20.9006 3.39655 21.0043 3.80156 21.0001H20.1984C20.6031 21.0039 21.0016 20.9001 21.353 20.6993C21.7044 20.4985 21.996 20.2079 22.1981 19.8572C22.3955 19.52 22.4997 19.1364 22.5001 18.7456C22.5004 18.3549 22.3968 17.9711 22.2 17.6335ZM11.25 9.75006C11.25 9.55115 11.329 9.36038 11.4697 9.21973C11.6103 9.07908 11.8011 9.00006 12 9.00006C12.1989 9.00006 12.3897 9.07908 12.5303 9.21973C12.671 9.36038 12.75 9.55115 12.75 9.75006V13.5001C12.75 13.699 12.671 13.8897 12.5303 14.0304C12.3897 14.171 12.1989 14.2501 12 14.2501C11.8011 14.2501 11.6103 14.171 11.4697 14.0304C11.329 13.8897 11.25 13.699 11.25 13.5001V9.75006ZM12 18.0001C11.7775 18.0001 11.56 17.9341 11.375 17.8105C11.19 17.6868 11.0458 17.5111 10.9606 17.3056C10.8755 17.1 10.8532 16.8738 10.8966 16.6556C10.94 16.4374 11.0472 16.2369 11.2045 16.0796C11.3618 15.9222 11.5623 15.8151 11.7805 15.7717C11.9988 15.7283 12.2249 15.7505 12.4305 15.8357C12.6361 15.9208 12.8118 16.065 12.9354 16.25C13.059 16.4351 13.125 16.6526 13.125 16.8751C13.125 17.1734 13.0065 17.4596 12.7955 17.6706C12.5845 17.8815 12.2984 18.0001 12 18.0001Z"
          fill={color}
        />
      </g>
      <defs>
        <clipPath id="clip0_14_8534">
          <rect width={size} height={size} fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default WarningTriangle;
