import React, { useEffect, useContext } from "react";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import { useDispatch, useSelector } from "react-redux";
import { logoutRequest } from "../../redux/modules/auth/actions";
import { useLoading } from "../../hooks/loading";
import { DialogContext } from "../../hooks/dialog/context";

const Logout = () => {
  const dispatch = useDispatch();
  const { token } = useSelector((state) => state.auth);
  const { handleLoading } = useLoading();
  const { resetDialogs } = useContext(DialogContext);

  useEffect(() => {
    handleLoading(true);
    dispatch(
      logoutRequest({
        token,
        callbackLoading: handleLoading,
      })
    );
  }, [token, dispatch, handleLoading ]);

  useEffect(() => {
    resetDialogs()
  }, [resetDialogs])

  return <div style={{ height: "100vh" }}></div>;
};

export default Logout;
