import React, { useState, useEffect } from 'react'
import { Form } from "antd";
import { useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import CustomModal from "../../../../components/CustomModal";
import Button from "../../../../newUXComponents/Button";
import useNotification from "../../../../hooks/newUXNotification";
import TelephoneInput,  {
  getFullTelephoneNumber,
  removeCountryFromTelephoneNumber,
  getCountryFromTelephoneNumber
} from "../../../../newUXComponents/TelephoneInput";
import Input from "../../../../newUXComponents/Input";
import Select from "../../../../newUXComponents/Select";
import { validation, INITIAL_VALUES } from "./validations";
import {
  Container,
  Header,
  Title,
  Hr,
  FormContainer,
  ButtonContainer
} from './styles'
import { 
  postCredentialSchedule,
  editCredentialSchedule,
  getCredentialSchedule,
  getCredentialScheduleAvailableDates,
  getCredentialScheduleAvailableTimes,
  clearCredentialScheduleAvailableDates,
  clearCredentialScheduleAvailableTimes
} from '../../../../redux/modules/credentials/actions';

const ScheduleModal = ({ 
    open,
    id,
    handleClose,
    credentialSchedule,
    event,
    contract,
    isEdit=false
  })=>{
  const [defaultCountry, setDefaultCountry] = useState('BR')

  const [isOpen, setIsOpen] = useState(open)
  const intl = useIntl();
  const [form] = Form.useForm();
  const notification = useNotification();
  const dispatch = useDispatch();
  const {
    credentialScheduleAvailableDates,
    credentialScheduleAvailableTimes
  } = useSelector((state) => state.credentials);

  const callbackError = (message) =>
    notification.displayError(intl.formatMessage({id: "api.error." + message}), 'get-collaborators-error-message')
  
  const callbackSuccess = (message) => {
    notification.displaySuccess(intl.formatMessage({id: "success." + message}), 'post-new-employee-success-message')
    dispatch(getCredentialSchedule( event.value, contract.value, callbackError ))
    handleClose()
  }

  const confirmModal = ()=>{
    form.validateFields()
    .then(()=>{
      const id = credentialSchedule.credentialScheduleId ? credentialSchedule.credentialScheduleId : ""
    const params = {
      contact: getFullTelephoneNumber(
        form.getFieldValue('telephone'), 
        form.getFieldValue('telephone_selected_country').value,
        defaultCountry
      ),
      nameOfWhoWithdraws: form.getFieldValue('name'),
      opportunityId: contract.value,
      eventId: event.value,
      date: form.getFieldValue('date'),
      time: form.getFieldValue('hour')
    }

    if (isEdit)
      dispatch(editCredentialSchedule(params, id, callbackError, callbackSuccess))
    else
      dispatch(postCredentialSchedule(params, callbackError, callbackSuccess))
    })
    .catch((error)=>{
    })
  }

  const handleDateChange = ()=>{
    dispatch(clearCredentialScheduleAvailableTimes());
    form.setFieldValue('hour', "");

    const date = form.getFieldValue('date');

    dispatch(
      getCredentialScheduleAvailableTimes(
        {
           event_id: event.value,
           opportunity_id: contract.value,
           date: date,
        },
        callbackError
      )
    );
  }

  useEffect(()=>{
    dispatch(clearCredentialScheduleAvailableDates());
    dispatch(clearCredentialScheduleAvailableTimes());

    dispatch(
      getCredentialScheduleAvailableDates(
        {
           event_id: event.value,
        },
        callbackError
      )
    );

    if (isEdit) {
      if (credentialSchedule.dateIsAnOptionInsideSchedulePeriod) {
        dispatch(
          getCredentialScheduleAvailableTimes(
            {
               event_id: event.value,
               opportunity_id: contract.value,
               date: credentialSchedule.dateSchedule,
            },
            callbackError
          )
        );
      }

      if (credentialSchedule.dateIsAnOptionInsideSchedulePeriod)
        form.setFieldValue('date', credentialSchedule.dateSchedule)
      if (credentialSchedule.timeIsAnOptionInsideSchedulePeriod)
        form.setFieldValue('hour', credentialSchedule.timeSchedule)
      form.setFieldValue('name', credentialSchedule.nameOfWhoWithdraws)

      if (credentialSchedule.contact.length !== 0 && !!credentialSchedule.contact){
        form.setFieldsValue({
          telephone: removeCountryFromTelephoneNumber(credentialSchedule.contact),
          telephone_selected_country: getCountryFromTelephoneNumber(
            credentialSchedule.contact
          ).value,
        });
        setDefaultCountry(getCountryFromTelephoneNumber(credentialSchedule.contact).value)
      }
      else
        form.setFieldsValue({
          telephone: "",
          telephone_selected_country: "",
        });
    }
  },[])

  return (
    <CustomModal handleClose={handleClose} isModalVisible={isOpen} id={id}>
      <Container>
        <Header>
          <Title>{intl.formatMessage({ id: isEdit? "edit_scheduling": "new_scheduling" })}</Title>
        </Header>
        <Hr />
        <FormContainer>
          <Form
            layout="vertical"
            form={form}
            name="new-employee"
            onFinish={confirmModal}
            initialValues={INITIAL_VALUES}
          >
            <Select 
              name="date"
              label={intl.formatMessage({ id: "date" })}
              showArrow
              allowClear
              placeholder={intl.formatMessage({ id: "date" })}
              rules={validation("date", intl)}
              onChange={(e) => handleDateChange(e)}
              options={credentialScheduleAvailableDates}
            />
            <Select 
              name="hour"
              label={intl.formatMessage({ id: "schedule_time" })}
              showArrow
              allowClear
              placeholder={intl.formatMessage({ id: "schedule_time" })}
              rules={validation("hour", intl)}
              options={credentialScheduleAvailableTimes}
            />
            <Input
              type="text"
              name="name"
              label={intl.formatMessage({ id: "name_of_who_withdraws" })}
              placeholder={intl.formatMessage({ id: "name_of_who_withdraws" })}
              rules={validation('name', intl)}
            />
            <TelephoneInput
              name="telephone"
              placeholder={intl.formatMessage({
                id: "telephone",
              })}
              label={intl.formatMessage({
                id: "telephone",
              })}
              rules={validation("telephone", intl)}
              refForm={form}
              defaultCountryCode={defaultCountry}
            />
          <ButtonContainer>
            <Button 
              label={intl.formatMessage({ id: "confirm" })}
              type="primary"
              onClick={confirmModal}
            />
            <Button 
              label={intl.formatMessage({ id: "cancel" })}
              type='link'
              onClick={handleClose}
            />
          </ButtonContainer>
          </Form>
        </FormContainer>
      </Container>
    </CustomModal>
  )
}

export default ScheduleModal