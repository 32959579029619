export function getGuests(params, callbackSuccess, callbackError) {
  return {
    type: "GET_GUESTS",
    payload: {
      params,
      callbackSuccess,
      callbackError,
    },
  };
}

export function SignOnSystemGuests(params, callbackSuccess, callbackError) {
  return {
    type: "SIGN_ON_SYSTEM_GUESTS",
    payload: {
      params,
      callbackSuccess,
      callbackError,
    },
  };
}
