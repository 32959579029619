import React, { useCallback, useEffect, useRef, useState } from "react";
import { useHistory } from "react-router-dom";

import { Form } from "@unform/web";
import * as Yup from "yup";
import getValidationErrors from "../../utils/getValidationErrors";

import { useSelector } from "react-redux";

import { FormattedMessage, useIntl } from "react-intl";
import { validateSingleFieldOnBlur } from "../../utils/formValidations";

import {
  ActionContainer,
  BadgeContainer,
  Container,
  FieldsContainer,
  Session,
  Title,
  TitleContainer,
} from "./styles";

import { useToast } from "../../hooks/toast";
import { useLoading } from "../../hooks/loading";
import api from "../../service";
import AnimatedMainContainer from "../../components/AnimatedMainContainer";
import InputWithLabel from "../../components/InputWithLabel";
import Badge from "../../components/Badge";
import
  TelephoneInputWithLabel,
  {
    getFullTelephoneNumber,
    getCountryFromTelephoneNumber,
  }
from "../../components/TelephoneInputWithLabel";

const ContactForm = () => {
  const formRef = useRef(null);
  const intl = useIntl();
  const { addToast } = useToast();
  const { token } = useSelector((state) => state.auth);
  const [data, setData] = useState(null);
  const [key, setKey] = useState(null);
  const { handleLoading } = useLoading();
  const [loading, setLoading] = useState(false);

  const history = useHistory();

  const schema = Yup.object().shape({
    whatsapp: Yup.string()
      .required(
        intl.formatMessage({
          defaultMessage: "A URL do Whatsapp é obrigatória",
          id: "error.whatsapp_required",
        })
      )
      .url(
        intl.formatMessage({
          defaultMessage: "URL inválida",
          id: "error.valid_url",
        })
      ),
    telephone: Yup.string()
      .max(30, intl.formatMessage({ id: "error.max_length" }, { length: 30 }))
      .required(
        intl.formatMessage({
          defaultMessage: "O telefone é obrigatório",
          id: "error.phone_required",
        })
      ),
      opening_hours_pt: Yup.string()
        .max(
          400,
          intl.formatMessage({ id: "error.max_length" },
            { length: 400 }
          )
        ),
      opening_hours_en: Yup.string()
        .max(
          400,
          intl.formatMessage({ id: "error.max_length" },
            { length: 400 }
          )
        ),
  });

  useEffect(() => {
    handleLoading(true);
    api
      .get("/system/contact-information", {
        headers: {
          "Content-Type": "application/json",
          Accept: "*/*",
          Authorization: `Bearer ${token}`,
        },
      })
      .then((result) => {
        setData(result.data.contactOutput);
        setKey(new Date());
      })
      .finally(() => {
        handleLoading(false);
      });
  }, [token, handleLoading]);

  const handleSubmit = useCallback(
    async (data) => {
      try {
        formRef.current?.setErrors({});

        setLoading(true);
        if (loading) {
          return;
        }

        await schema.validate(data, { abortEarly: false });

        api
          .put(
            "/system/contact-information",
            {
              telephone: getFullTelephoneNumber(
                data.telephone, data.telephone_selected_country),
              whatsapp: data.whatsapp,
              openingHoursPt: data.opening_hours_pt,
              openingHoursEn: data.opening_hours_en,
            },
            {
              headers: {
                "Content-Type": "application/json",
                Accept: "*/*",
                Authorization: `Bearer ${token}`,
              },
            }
          )
          .then(() => {
            setLoading(false);
            addToast({
              type: "success",
              title: intl.formatMessage({
                id: "success",
              }),
              description: intl.formatMessage({
                id: "success.contact_information_update",
              }),
            });
            history.push("/");
          })
          .catch((err) => {
            setLoading(false);
            addToast({
              type: "error",
              title: intl.formatMessage({
                id: "error",
              }),
              description: intl.formatMessage({
                id: "api.error." + err.response.data.code,
              }),
            });
          });
      } catch (err) {
        setLoading(false);
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);

          formRef.current?.setErrors(errors);

          return;
        }
      }
    },
    [addToast, history, intl, schema, token, loading]
  );

  const validationBlur = (name) => {
    let errors = formRef.current.getErrors();

    validateSingleFieldOnBlur(errors, schema, name, formRef);
  };

  const pushBack = () => {
    history.push("/dashboard");
  };

  return (
    <AnimatedMainContainer
      breadcrumb={intl.formatMessage({ id: "edit_contact_information" })}
    >
      <Container>
        <Form
          ref={formRef}
          onSubmit={handleSubmit}
          key={key}
          initialData={{
            whatsapp: data?.whatsapp,
            telephone: data?.telephone,
            telephone_selected_country:
              getCountryFromTelephoneNumber(data?.telephone),
            opening_hours_pt: data?.openingHoursPt,
            opening_hours_en: data?.openingHoursEn,
          }}
        >
          <TitleContainer>
            <Title>
              <FormattedMessage id="contact_data" />
            </Title>
            <ActionContainer>
              <BadgeContainer onClick={() => pushBack()}>
                <Badge
                  color="#C9C9C9"
                  text={intl.formatMessage({ id: "goback" })}
                  fontSize="12px"
                />
              </BadgeContainer>
              <BadgeContainer onClick={() => formRef.current.submitForm()}>
                <Badge
                  color="#002244"
                  text={intl.formatMessage({ id: "save" })}
                  fontSize="12px"
                  loading={loading}
                />
              </BadgeContainer>
            </ActionContainer>
          </TitleContainer>
          <Session>
            <FieldsContainer>
              <InputWithLabel
                name="whatsapp"
                validationBlur={validationBlur}
                placeholder="Whatsapp"
                label="Whatsapp"
                required
              />
              <TelephoneInputWithLabel
                name="telephone"
                placeholder={intl.formatMessage({
                  id: "telephone",
                })}
                label={intl.formatMessage({
                  id: "telephone",
                })}
                validationBlur={validationBlur}
                labelOrientation="vertical"
                required
              />
              <InputWithLabel
                name="opening_hours_pt"
                validationBlur={validationBlur}
                placeholder={intl.formatMessage({ id: "opening_hours_pt" })}
                label={intl.formatMessage({ id: "opening_hours_pt" })}
                maxLength="400"
              />
              <InputWithLabel
                name="opening_hours_en"
                validationBlur={validationBlur}
                placeholder={intl.formatMessage({ id: "opening_hours_en" })}
                label={intl.formatMessage({ id: "opening_hours_en" })}
                maxLength="400"
              />
            </FieldsContainer>
          </Session>
        </Form>
      </Container>
    </AnimatedMainContainer>
  );
};

export default ContactForm;
