export function getAllContractByEvent(id, callbackError,callbackSuccess) {
  return {
    type: "GET_ALL_CONTRACT_BY_EVENT",
    payload: {
      id,
      callbackError,
      callbackSuccess
    },
  };
}

export function setAllContractByEvent({ allContract }) {
  return {
    type: "SET_ALL_CONTRACT",
    payload: {
      allContract,
    },
  };
}

export function clearAllContractByEvent() {
  return {
    type: "CLEAR_ALL_CONTRACT",
    payload: {},
  };
}

export function getContractByExhibitor(params, callbackError) {
  return {
    type: "GET_CONTRACT_BY_EXHIBITOR",
    payload: {
      params,
      callbackError,
    },
  };
}

export function getContractByServiceProvider(params, callbackError) {
  return {
    type: "GET_CONTRACT_BY_SERVICE_PROVIDER",
    payload: {
      params,
      callbackError,
    },
  };
}

export function setContractSuccess(contract) {
  return {
    type: "SET_CONTRACT_SUCCESS",
    payload: {
      contract,
    },
  };
}

export function getOrderByContract(params, callbackError) {
  return {
    type: "GET_ORDER_BY_CONTRACT",
    payload: {
      params,
      callbackError,
    },
  };
}

export function setOrderByContractSuccess(order) {
  return {
    type: "SET_ORDER_BY_CONTRACT_SUCCESS",
    payload: {
      order,
    },
  };
}

export function changeHeadbandExhibitor(
  params,
  callbackSuccess,
  callbackError
) {
  return {
    type: "CHANGE_HEADBAND_EXHIBITOR",
    payload: {
      params,
      callbackSuccess,
      callbackError,
    },
  };
}
