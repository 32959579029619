import React, { useState, useEffect } from "react";
import { Form } from "antd";
import InputMask from "react-input-mask";
import { useIntl } from "react-intl";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import BackArrow from "../../../../newUXAssets/icons/BackArrow";
import Input from "../../../../newUXComponents/Input";
import Select from "../../../../newUXComponents/Select";
import Button from "../../../../newUXComponents/Button";
import TelephoneInput, {
  getFullTelephoneNumber,
  removeCountryFromTelephoneNumber,
  getCountryFromTelephoneNumber,
} from "../../../../newUXComponents/TelephoneInput";
import useNotification from "../../../../hooks/newUXNotification";
import { useLoading } from "../../../../hooks/loading";
import {
  getSingleCollaborator,
  editEmployee,
} from "../../../../redux/modules/employee/actions";

import { validation, INITIAL_VALUES } from "./validations";
import {
  Container,
  ReturnLink,
  Title,
  ButtonContainer,
  FormContainer,
} from "./styles";

const NewEmployee = () => {
  const intl = useIntl();
  const [documentMask, setDocumentMask] = useState("999.999.999-99");
  const [documentLabel, setDocumentLabel] = useState(
    intl.formatMessage({ id: "document_cpf" })
  );
  const [defaultCountry, setDefaultCountry] = useState('BR')


  const [form] = Form.useForm();
  const history = useHistory();
  const dispatch = useDispatch();
  const notification = useNotification();
  const { handleLoading } = useLoading();

  const handleTypeChanges = (value) => {
    switch (value) {
      case false:
        setDocumentLabel(intl.formatMessage({ id: "document_cpf" }));
        setDocumentMask("999.999.999-99");
        break;
      case true:
        setDocumentLabel(intl.formatMessage({ id: "document_passport" }));
        setDocumentMask("");
        break;
      default:
        setDocumentLabel(intl.formatMessage({ id: "document" }));
        break;
    }
    form.setFieldValue("document", "");
  };

  const callBackError = (message) => {
    handleLoading(false);
    notification.displayError(
      intl.formatMessage({ id: "api.error." + message }),
      "post-new-employee-error-message"
    );
  };

  const callBackSuccess = (message) => {
    handleLoading(false);
    notification.displaySuccess(
      intl.formatMessage({ id: "success." + message }),
      "post-new-employee-success-message"
    );
    history.push("/credentials/register");
  };

  const callBackSuccessLoad = (loadedCollaborator)=>{
    handleTypeChanges(loadedCollaborator.international);
    form.setFieldsValue({
      nationality: loadedCollaborator.international,
      document: loadedCollaborator.document,
      name: loadedCollaborator.name,
      last_name: loadedCollaborator.lastName,
      credential_name: loadedCollaborator.credentialName,
      email: loadedCollaborator.email,
      assignment: loadedCollaborator.jobTitle,
      address: loadedCollaborator.address,
    });
    if (loadedCollaborator.telephone.length !== 0 && !!loadedCollaborator.telephone){
      form.setFieldsValue({
        telephone: removeCountryFromTelephoneNumber(loadedCollaborator.telephone),
        telephone_selected_country: getCountryFromTelephoneNumber(
          loadedCollaborator.telephone
        ).value,
      });
      setDefaultCountry(getCountryFromTelephoneNumber(loadedCollaborator.telephone).value)
    }
    else
      form.setFieldsValue({
        telephone: "",
        telephone_selected_country: "",
      });
  }

  useEffect(() => {
    dispatch(
      getSingleCollaborator({ callBackError, callBackSuccess: callBackSuccessLoad, id: history.location.state.id })
    );

  }, []);

  const onFinish = (values) => {
    const newEmployee = {
      international: values.nationality,
      name: values.name,
      lastName: values.last_name,
      credentialName: values.credential_name,
      document: values.document,
      jobTitle: values.assignment,
      email: values.email,
      address: values.address,
      telephone: getFullTelephoneNumber(
        values.telephone,
        values.telephone_selected_country.value,
        defaultCountry
      ),
    };
    handleLoading(true);
    dispatch(
      editEmployee({
        employee: newEmployee,
        callBackError,
        callBackSuccess,
        id: history.location.state.id,
      })
    );
  };

  const handleGoBack = () => {
    history.push("/credentials/register");
  };

  return (
    <Container>
      <ReturnLink onClick={handleGoBack}>
        <BackArrow />
        <p>{intl.formatMessage({ id: "goback" })}</p>
      </ReturnLink>
      <Title>{intl.formatMessage({ id: "editing_collaborator" })}</Title>
      <FormContainer>
        <Form
          layout="vertical"
          form={form}
          name="edit-employee"
          onFinish={onFinish}
          initialValues={INITIAL_VALUES}
        >
          <Select
            name="nationality"
            label={intl.formatMessage({ id: "nationality" })}
            showArrow
            allowClear
            placeholder={intl.formatMessage({ id: "nationality" })}
            rules={validation("nationality", intl)}
            onChange={(e) => handleTypeChanges(e)}
            options={[
              {
                label: intl.formatMessage({ id: "national" }),
                value: false,
              },
              {
                label: intl.formatMessage({ id: "international" }),
                value: true,
              },
            ]}
          />
          <Input
            type="text"
            name="name"
            label={intl.formatMessage({ id: "name" })}
            placeholder={intl.formatMessage({ id: "name" })}
            rules={validation("name", intl)}
          />
          <Input
            type="text"
            name="last_name"
            label={intl.formatMessage({ id: "last_name" })}
            placeholder={intl.formatMessage({ id: "last_name" })}
            rules={validation("last_name", intl)}
          />
          <Input
            type="text"
            name="credential_name"
            label={intl.formatMessage({ id: "credential_name" })}
            placeholder={intl.formatMessage({ id: "credential_name" })}
            rules={validation("credential_name", intl)}
          />
          <Input
            type="text"
            name="document"
            as={InputMask}
            mask={documentMask}
            placeholder={documentLabel}
            label={documentLabel}
            rules={validation("document", intl)}
          />
          <Input
            type="text"
            name="assignment"
            label={intl.formatMessage({ id: "assignment" })}
            placeholder={intl.formatMessage({ id: "assignment" })}
            rules={validation("assignment", intl)}
          />
          <Input
            type="text"
            name="email"
            label={intl.formatMessage({ id: "email" })}
            placeholder={intl.formatMessage({ id: "email" })}
            rules={validation("email", intl)}
          />
          <Input
            type="text"
            name="address"
            label={intl.formatMessage({ id: "address" })}
            placeholder={intl.formatMessage({ id: "address" })}
            rules={validation("address", intl)}
          />
          <TelephoneInput
            name="telephone"
            placeholder={intl.formatMessage({
              id: "telephone",
            })}
            label={intl.formatMessage({
              id: "telephone",
            })}
            rules={validation("telephone", intl)}
            required={false}
            refForm={form}
            defaultCountryCode={defaultCountry}
          />
          <ButtonContainer>
            <Button
              label={intl.formatMessage({ id: "cancel" })}
              type="link"
              onClick={handleGoBack}
            />
            <Button
              label={intl.formatMessage({ id: "save" })}
              type="primary"
              htmlType="submit"
            />
          </ButtonContainer>
        </Form>
      </FormContainer>
    </Container>
  );
};

export default NewEmployee;
