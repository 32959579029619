export function getLogoSrcFromHtml(htmlTag) {
  let src = "";

  if (htmlTag) {
    let tmp = document.createElement("div");
    tmp.innerHTML = htmlTag;
    src = tmp.querySelector("img").getAttribute("src");
  }

  return src;
}
