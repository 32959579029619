import { all, takeLatest, call, put } from "redux-saga/effects";

import { setInfoFaqSuccess } from "./actions";
import { getInfoFaqService } from '../../../service/faq.service'

function* getInfoFaq({payload}) {
  
  try {
    const response = yield call(getInfoFaqService)

    if (response.status === 200 && response.data.code === 3003) {
      yield put(setInfoFaqSuccess(response.data.faqListOutput))
    }

  } catch (error) {
    payload.callbackError(error.response.data.code);
  }
}

export default all([takeLatest("GET_INFO_FAQ", getInfoFaq)]);
