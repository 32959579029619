import styled, { css } from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 325px;
  justify-content: space-between;
`;

export const Header = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 15px;
`;

export const Title = styled.div`
  color:  #11A7D9;
  text-align: center;
  font-family: 'Open Sans';
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 32px;
  letter-spacing: -0.48px;
  padding: 16px 32px 0 32px;
  margin: 0;
`;

export const Hr = styled.hr`
  border: 0.5px solid #D4D4D4;
`

export const ButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 5px;
  width: 100%;
`

export const FormContainer = styled.div`
  padding: 16px 37px;
`
