import produce from "immer";

const INITIAL_STATE = {
  benefited: [],
  transferHistory: [],
};

const transferCredential = (state = INITIAL_STATE, action) => {
  return produce(state, (draft) => {
    switch (action.type) {
      case "SET_BENEFITED_SUCCESS": {
        const { benefited } = action.payload;

        draft.benefited = benefited;

        localStorage.setItem(
          "InformaMarket:benefited",
          JSON.stringify(benefited)
        );

        break;
      }

      case "SET_TRANSFER_HISTORY_SUCCESS": {
        const { transferHistory } = action.payload;

        draft.transferHistory = transferHistory;

        localStorage.setItem(
          "InformaMarket:transferHistory",
          JSON.stringify(transferHistory)
        );

        break;
      }

      default: {
        if (localStorage.getItem("InformaMarket:benefited")) {
          const benefited = JSON.parse(
            localStorage.getItem("InformaMarket:benefited")
          );

          draft.benefited = benefited;
        }

        if (localStorage.getItem("InformaMarket:transferHistory")) {
          const transferHistory = JSON.parse(
            localStorage.getItem("InformaMarket:transferHistory")
          );

          draft.transferHistory = transferHistory;
        }

        return draft;
      }
    }
  });
};

export default transferCredential;
