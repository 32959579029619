import produce from "immer";

const INITIAL_STATE = {
  whatsapp: "",
  telephone: "",
  openingHoursPt: "",
  openingHoursEn: "",
  countries: [],
};

const system = (state = INITIAL_STATE, action) => {
  return produce(state, (draft) => {
    switch (action.type) {
      case "SET_SYSTEM": {
        const { system } = action.payload;

        draft.whatsapp = system.whatsapp;
        draft.telephone = system.telephone;
        draft.openingHoursPt = system.openingHoursPt;
        draft.openingHoursEn = system.openingHoursEn;

        localStorage.setItem("InformaMarket:system", JSON.stringify(system));

        break;
      }

      case "SET_LIST_COUNTRIES": {
        const { countries } = action.payload;

        draft.countries = countries;

        break;
      }

      case "CLEAR_SYSTEM": {
        draft.system = [];
        localStorage.removeItem("InformaMarket:system");

        break;
      }

      default: {
        if (localStorage.getItem("InformaMarket:system")) {
          const system = JSON.parse(
            localStorage.getItem("InformaMarket:system")
          );

          draft.whatsapp = system.whatsapp;
          draft.telephone = system.telephone;
          draft.openingHoursPt = system.openingHoursPt;
          draft.openingHoursEn = system.openingHoursEn;
        }

        return draft;
      }
    }
  });
};

export default system;
