import React from 'react'

const Phone = (props) => {
  const {
    size = '20',
    color = '#FFFFFF'
  } = props


  return (
    <svg width={size} height={size} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_946_656)">
        <path d="M18.1156 13.6782C17.9763 14.7368 17.4564 15.7085 16.6531 16.4118C15.8497 17.1151 14.8177 17.5019 13.75 17.5C7.54688 17.5 2.50001 12.4531 2.50001 6.25002C2.49809 5.18228 2.88492 4.15035 3.58824 3.34697C4.29155 2.54359 5.26326 2.0237 6.32188 1.8844C6.58958 1.85171 6.86067 1.90648 7.09468 2.04052C7.3287 2.17457 7.51309 2.3807 7.62032 2.62815L9.27032 6.31174V6.32112C9.35242 6.51054 9.38633 6.71734 9.36901 6.92305C9.3517 7.12877 9.2837 7.32699 9.1711 7.50003C9.15704 7.52112 9.14219 7.54065 9.12657 7.56018L7.50001 9.48831C8.08516 10.6774 9.32891 11.9102 10.5336 12.4969L12.4352 10.8789C12.4538 10.8632 12.4734 10.8486 12.4938 10.8352C12.6666 10.7199 12.8655 10.6495 13.0725 10.6304C13.2794 10.6113 13.4878 10.6441 13.6789 10.7258L13.6891 10.7305L17.3695 12.3797C17.6174 12.4866 17.8241 12.6708 17.9585 12.9048C18.093 13.1389 18.1481 13.4102 18.1156 13.6782Z" fill={color}/>
      </g>
      <defs>
        <clipPath id="clip0_946_656">
          <rect width={size} height={size} fill="white"/>
        </clipPath>
      </defs>
    </svg>
  )
}

export default Phone