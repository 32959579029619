import React, { useEffect, useRef, useState, useMemo } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useSelector } from "react-redux";
import { useHistory } from "react-router";
import AnimatedMainContainer from "../../components/AnimatedMainContainer";
import { useToast } from "../../hooks/toast";
import * as Yup from "yup";
import getValidationErrors from "../../utils/getValidationErrors";
import api from "../../service";
import { useCallback } from "react";
import FormButton from "../../components/FormButton";
import { Form } from "@unform/web";
import { validateSingleFieldOnBlur } from "../../utils/formValidations";
import InputWithLabel from "../../components/InputWithLabel";
import { cpf, cnpj } from "cpf-cnpj-validator";

import {
  Container,
  FormContainer,
  TitleContainer,
  Title,
  FieldsContainer,
  Horizontal,
  FilterActionsContainer,
  SubmitContainer,
  GoBackContainer,
  ActionContainer,
  BadgeContainer,
  LinkButton,
  NotificationContainer,
  LongRequestAlertContainer,
} from "./styles";
import SelectWithLabel from "../../components/SelectWithLabel";
import Badge from "../../components/Badge";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import { useLoading } from "../../hooks/loading";
import Table from "../../components/Table";
import Report from "./report";
import { isBefore, isEqual } from "date-fns";
import DatePickerWithLabel from "../../components/DatePickerWithLabel";
import opportunityStatusTypesConstants from "../../constants/opportunityStatusTypes";

const ManageOrderReport = () => {
  const { token } = useSelector((state) => state.auth);
  const formRef = useRef(null);
  const intl = useIntl();
  const history = useHistory();
  const { addToast } = useToast();
  const { handleLoading } = useLoading();
  const [loading, setLoading] = useState(false);
  const [events, setEvents] = useState([]);
  const [selectedEventIdList, setSelectedEventIdList] = useState([]);
  const [selectedContractType, setSelectedContractType] = useState();
  const [opportunities, setOpportunities] = useState([]);
  const [selectedOrderType, setSelectedOrderType] = useState();
  const [data, setData] = useState([]);
  const [showExport, setShowExport] = useState(false);
  const [filtered, setFiltered] = useState(false);
  const [productCategories, setProductCategories] = useState([]);
  const [getOrderReportTimer, setGetOrderReportTimer] = useState();
  const [selectedProductCategoriesList, setSelectedProductCategoriesList] =
    useState([]);
  const [isAdditionalOrderTypeSelected, setIsAdditionalOrderTypeSelected] =
    useState(false);

  const contractTypes = useMemo(
    () => [
      { value: 0, label: intl.formatMessage({ id: "all" }) },
      { value: 1, label: intl.formatMessage({ id: "valid" }) },
      { value: 2, label: intl.formatMessage({ id: "canceled" }) },
    ],
    [intl]
  );

  const orderTypes = useMemo(
    () => [
      { value: 0, label: intl.formatMessage({ id: "all" }) },
      { value: 1, label: intl.formatMessage({ id: "mandatory" }) },
      { value: 2, label: intl.formatMessage({ id: "additional" }) },
    ],
    [intl]
  );

  const language = useSelector((state) => state.language).code;
  const opportunityStatusTypes = opportunityStatusTypesConstants();

  const isAllRequiredFieldsEmpty = () => {
    const eventList = formRef.current.getFieldValue("event_list");
    const order = formRef.current.getFieldValue("order");
    const initialDate = formRef.current.getFieldValue("initial_date");

    return eventList?.length > 0 || order || initialDate;
  };

  const schema = Yup.object().shape({
    event_list: Yup.array()
      .nullable()
      .test(
        "is-all-required-fields-empty-event",
        intl.formatMessage({
          id: "error.event_period_order_required",
        }),
        isAllRequiredFieldsEmpty
      ),
    contract_type: Yup.string(),
    contract: Yup.string(),
    initial_date: Yup.string()
      .nullable()
      .test(
        "is-all-required-fields-empty-initial_date",
        intl.formatMessage({
          id: "error.event_period_order_required",
        }),
        isAllRequiredFieldsEmpty
      ),
    final_date: Yup.string()
      .nullable()
      .when("initial_date", {
        is: (initial_date) => initial_date,
        then: Yup.string()
          .nullable()
          .required(
            intl.formatMessage({
              id: "error.field_required",
            })
          ),
      })
      .test(
        "is-incorrect",
        intl.formatMessage({
          id: "error.initial_date_later",
        }),
        () => {
          const initial_date = formRef.current.getFieldValue("initial_date");
          const final_date = formRef.current.getFieldValue("final_date");

          if (initial_date && final_date) {
            return (
              isBefore(new Date(initial_date), new Date(final_date)) ||
              isEqual(new Date(initial_date), new Date(final_date))
            );
          }
          return true;
        }
      )
      .test(
        "is-incorrect2",
        intl.formatMessage({ id: "error.no_initial_date" }),
        () => {
          const initial_date = formRef.current.getFieldValue("initial_date");
          const final_date = formRef.current.getFieldValue("final_date");

          if (!initial_date && final_date) {
            return false;
          }

          return true;
        }
      ),
    document: Yup.string(),
    order_type: Yup.string(),
    order: Yup.string()
      .matches(
        /^(^$|([0-9]*))$/g,
        intl.formatMessage({ id: "error.need_to_be_number" })
      )
      .test(
        "is-all-required-fields-empty-order",
        intl.formatMessage({
          id: "error.event_period_order_required",
        }),
        isAllRequiredFieldsEmpty
      ),
    product_category_list: Yup.array(),
  });

  const handleSubmit = useCallback(
    async (data) => {
      try {
        formRef.current?.setErrors({});

        setLoading(true);
        if (loading) {
          return;
        }

        await schema.validate(data, { abortEarly: false });

        api
          .patch(
            `/admin/manage-order`,
            {
              eventIdList: data.event_list,
              contractId: data.contract,
              initialDate: data.initial_date && new Date(data.initial_date),
              finalDate: data.final_date && new Date(data.final_date),
              document: data.document,
              orderType: data.order_type ? data.order_type : null,
              contractType: data.contract_type ? data.contract_type : 0,
              order: data.order,
              productCategoryList: selectedProductCategoriesList,
            },
            {
              headers: {
                "Content-Type": "application/json",
                Accept: "*/*",
                Authorization: `Bearer ${token}`,
              },
            }
          )
          .then((result) => {
            const requestToken = result.data.token;
            verifyGetOrderReportAsyncStatus(requestToken);
          })
          .catch((err) => {
            setData([]);
            setShowExport(false);
            addToast({
              type: "error",
              title: intl.formatMessage({
                id: "error",
              }),
              description: intl.formatMessage({
                id: "api.error." + err.response.data.code,
              }),
            });
            setLoading(false);
            setFiltered(true);
          });
      } catch (err) {
        setLoading(false);
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);

          formRef.current?.setErrors(errors);

          return;
        }
      }
    },
    [addToast, intl, loading, schema, token]
  );

  const handleAsyncGetOrderReportReturn = (requestToken) => {
    api
      .get(`/admin/manage-order/return/${requestToken}`, {
        headers: {
          "Content-Type": "application/json",
          Accept: "*/*",
          Authorization: `Bearer ${token}`,
        },
      })
      .then((result) => {
        const ACCEPTED = 202;

        if (result.status === ACCEPTED) {
          verifyGetOrderReportAsyncStatus(requestToken);
        } else {
          stopGetOrderReportCalls();
          setData(result.data.output);
          if (result.data.output) {
            setShowExport(true);
          }
        }
      })
      .catch((err) => {
        setData([]);
        setShowExport(false);
        addToast({
          type: "error",
          title: intl.formatMessage({
            id: "error",
          }),
          description: intl.formatMessage({
            id: "api.error." + err.response.data.code,
          }),
        });
        stopGetOrderReportCalls();
      });
  };

  const verifyGetOrderReportAsyncStatus = (requestToken) => {
    const timerRef = setTimeout(() => {
      handleAsyncGetOrderReportReturn(requestToken);
      setGetOrderReportTimer();
    }, 1000);
    setGetOrderReportTimer(timerRef);
  };

  const stopGetOrderReportCalls = () => {
    clearTimeout(getOrderReportTimer);
    setGetOrderReportTimer();
    setLoading(false);
    setFiltered(true);
  };

  useEffect(() => {
    handleLoading(true);
    api
      .get(`/admin/events`, {
        headers: {
          "Content-Type": "application/json",
          Accept: "*/*",
          Authorization: `Bearer ${token}`,
        },
      })
      .then((result) => {
        const events = result.data.output.map((event) => ({
          label: event.eventEditionName,
          value: event.idNumber
        }));
        let eventsSort = [...events].sort((a, b) =>
          a.label < b.label ? -1 : a.label > b.label ? 1 : 0
        );
        setEvents(eventsSort);
      })
      .catch((err) => {
        addToast({
          type: "error",
          title: intl.formatMessage({
            id: "error",
          }),
          description: intl.formatMessage({
            id: "api.error." + err.response.data.code,
          }),
        });
      })
      .finally(() => {
        handleLoading(false);
      });
  }, [addToast, intl, token, handleLoading]);

  useEffect(() => {
    if (
      selectedEventIdList.length > 0 &&
      selectedContractType !== undefined &&
      selectedContractType !== null
    ) {
      handleLoading(true);
      api
        .patch(
          `/admin/opportunity/event-list-and-contract-type`,
          {
            eventIdList: selectedEventIdList,
            contractType: selectedContractType,
          },
          {
            headers: {
              "Content-Type": "application/json",
              Accept: "*/*",
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((result) =>
          setOpportunities(result.data.opportunityList)
        )
        .catch((err) =>
          addToast({
            type: "error",
            title: intl.formatMessage({
              id: "error",
            }),
            description: intl.formatMessage({
              id: "api.error." + err.response.data.code,
            }),
          })
        )
        .finally(() => handleLoading(false));
    } else {
      setOpportunities([]);
    }
  }, [
    addToast,
    handleLoading,
    intl,
    selectedEventIdList,
    selectedContractType,
    token,
  ]);

  useEffect(() => {
    formRef.current.setFieldValue("product_category_list");
    if (
      selectedEventIdList.length > 0 &&
      selectedOrderType !== undefined &&
      selectedOrderType !== null
    ) {
      handleLoading(true);
      api
        .patch(
          `/admin/manage-operation/product-categories`,
          {
            eventIdList: selectedEventIdList,
            type: selectedOrderType,
          },
          {
            headers: {
              "Content-Type": "application/json",
              Accept: "*/*",
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((result) => {
          const productCategories = result.data.output.map((category) => ({
            label: language === "pt" ? category.portugueseName : category.name,
            value: category.id + category.productCategoryType,
            id: category.id,
            productCategoryType: category.productCategoryType,
          }));
          let productCategoriesSort = [...productCategories].sort((a, b) =>
            a.label < b.label ? -1 : a.label > b.label ? 1 : 0
          );
          setProductCategories(productCategoriesSort);
        })
        .catch((err) => {
          addToast({
            type: "error",
            title: intl.formatMessage({
              id: "error",
            }),
            description: intl.formatMessage({
              id: "api.error." + err.response.data.code,
            }),
          });
        })
        .finally(() => {
          handleLoading(false);
        });
    } else {
      setProductCategories([]);
    }
  }, [
    addToast,
    intl,
    token,
    handleLoading,
    selectedEventIdList,
    selectedOrderType,
    language
  ]);

  const validationBlur = (name) => {
    let errors = formRef.current.getErrors();
    validateSingleFieldOnBlur(errors, schema, name, formRef);
  };

  const clearReportData = () => {
    setShowExport(false);
    setData([]);
    setFiltered(false);
  };

  const clearFields = () => {
    formRef.current.reset();
    formRef.current.setFieldValue("event_list");
    formRef.current.setFieldValue("contract", "");
    formRef.current.setFieldValue("order_type", "");
    formRef.current.setFieldValue("contract_type", "");
    formRef.current.setFieldValue("product_category_list");
    setSelectedEventIdList([]);
    setSelectedProductCategoriesList([]);
    clearReportData();
  };

  const onChangeEvent = (eventList) => {
    setSelectedEventIdList(eventList?.map(
      (event) => event.value
    ));
    clearReportData();
    formRef.current.setFieldValue("document", "");
    formRef.current.setFieldValue("contract", "");
    formRef.current.setFieldValue("product_category_list");
    setSelectedProductCategoriesList([]);
  };

  const onChangeContractType = (contractType) => {
    setSelectedContractType(contractType?.value);
    clearReportData();
    formRef.current.setFieldValue("contract", "");
  };

  const onChangeProductCategory = (productCategory) => {
    setSelectedProductCategoriesList(productCategory?.map((category) => ({
      id: category.id,
      type: category.productCategoryType
    })));
    clearReportData();
  };

  const removeDocumentFieldMask = (document) => {
    if (document) {
      formRef.current.setFieldValue("document", document?.replace(/\D/g, ""));
    }
  };

  const formatDocumentField = (document) => {
    if (document) {
      formRef.current.setFieldValue("document", formatDocument(document));
    }
  };

  const formatDocument = (documentString) => {
    if (documentString) {
      if (documentString.length === 14) {
        return cnpj.format(documentString);
      }
      if (documentString.length === 11) {
        return cpf.format(documentString);
      }
    }
    return documentString;
  };

  const onChangeOrderType = (orderType) => {
    setSelectedOrderType(orderType?.value);
    setIsAdditionalOrderTypeSelected(orderType?.value === 2);
    if (orderType?.value !== 2) {
      formRef.current.setFieldValue("order", "");
    }
  };

  const columns = useMemo(
    () => [
      {
        name: intl.formatMessage({ id: "id_number_cnpj_passport" }),
        cell: (row) => formatDocument(row.document),
        allowOverflow: true,
        wrap: true,
        minWidth: "150px",
        maxWidth: "200px",
        sortable: true,
      },
      {
        name: intl.formatMessage({ id: "event" }),
        selector: (row) => row.event,
        allowOverflow: false,
        wrap: false,
        minWidth: "150px",
        maxWidth: "350px",
        sortable: true,
      },
      {
        name: intl.formatMessage({ id: "corporate_name" }),
        selector: (row) => row.accountName,
        allowOverflow: false,
        wrap: false,
        minWidth: "250px",
        maxWidth: "400px",
        sortable: true,
      },
      {
        name: intl.formatMessage({ id: "commercial_name" }),
        selector: (row) => row.accountAlias,
        minWidth: "200px",
        maxWidth: "300px",
        allowOverflow: false,
        wrap: false,
        sortable: true,
      },
      {
        name: intl.formatMessage({ id: "contract" }),
        selector: (row) => row.opportunity,
        allowOverflow: true,
        wrap: true,
        minWidth: "120px",
        maxWidth: "130px",
        sortable: true,
      },
      {
        name: intl.formatMessage({ id: "opportunity_status" }),
        selector: (row) => row.opportunityStatus,
        cell: (row) =>
          opportunityStatusTypes.filter((pt) => pt.value === row.opportunityStatus)[0]?.label,
        allowOverflow: true,
        wrap: true,
        minWidth: "130px",
      },
      {
        name: intl.formatMessage({ id: "origin" }),
        selector: (row) => row.orderOrigin,
        allowOverflow: true,
        wrap: true,
        minWidth: "120px",
        maxWidth: "130px",
      },
      {
        name: intl.formatMessage({ id: "booth" }),
        selector: (row) => row.booth,
        allowOverflow: false,
        maxWidth: "130px",
        sortable: true,
        wrap: true,
      },
      {
        name: intl.formatMessage({ id: "metreage" }),
        selector: (row) => row.area,
        allowOverflow: true,
        wrap: true,
        minWidth: "140px",
        maxWidth: "140px",
        sortable: true,
      },
      {
        name: intl.formatMessage({ id: "order_type" }),
        selector: (row) => row.orderType,
        allowOverflow: true,
        wrap: true,
        minWidth: "130px",
        maxWidth: "140px",
      },
      {
        name: intl.formatMessage({ id: "order_number" }),
        selector: (row) => row.orderNumber,
        allowOverflow: true,
        wrap: true,
        minWidth: "140px",
        maxWidth: "140px",
      },
      {
        name: intl.formatMessage({ id: "order_status" }),
        selector: (row) => row.orderStatus,
        allowOverflow: true,
        wrap: true,
        minWidth: "150px",
        maxWidth: "200px",
      },
      {
        name: intl.formatMessage({ id: "payment_method" }),
        selector: (row) => row.paymentMethod,
        allowOverflow: true,
        wrap: true,
        minWidth: "130px",
      },
      {
        name: intl.formatMessage({ id: "product" }),
        selector: (row) => row.name,
        allowOverflow: false,
        wrap: false,
        minWidth: "200px",
        maxWidth: "300px",
      },
      {
        name: intl.formatMessage({ id: "category" }),
        selector: (row) => (row.orderType === 'Obrigatório') ? row.productType : row.categoryDescription,
        allowOverflow: true,
        wrap: true,
        minWidth: "150px",
        maxWidth: "150px",
      },
      {
        name: intl.formatMessage({ id: "description" }),
        selector: (row) => row.productDescription,
        allowOverflow: false,
        wrap: false,
        minWidth: "250px",
        maxWidth: "400px",
      },
      {
        name: intl.formatMessage({ id: "special_conditions" }),
        selector: (row) => row.specialConditions,
        allowOverflow: false,
        wrap: false,
        minWidth: "300px",
        maxWidth: "350px",
      },
      {
        name: intl.formatMessage({ id: "authorization_number" }),
        selector: (row) => row.authorizationNumber,
        allowOverflow: true,
        wrap: true,
        minWidth: "150px",
        maxWidth: "170px",
      },
      {
        name: intl.formatMessage({ id: "product_title" }),
        selector: (row) => row.productTitle,
        allowOverflow: false,
        wrap: false,
        minWidth: "200px",
        maxWidth: "300px",
      },
      {
        name: intl.formatMessage({ id: "currency" }),
        selector: (row) => row.currency,
        allowOverflow: true,
        wrap: true,
        minWidth: "120px",
        maxWidth: "130px",
      },
      {
        name: intl.formatMessage({ id: "unitary_value" }),
        selector: (row) => row.unitaryValue,
        allowOverflow: true,
        wrap: true,
        minWidth: "120px",
        maxWidth: "130px",
      },
      {
        name: intl.formatMessage({ id: "quantity" }),
        selector: (row) => row.quantity,
        allowOverflow: true,
        wrap: true,
        minWidth: "120px",
        maxWidth: "130px",
      },
      {
        name: intl.formatMessage({ id: "total_value_by_product" }),
        selector: (row) => row.totalValueByProduct,
        allowOverflow: true,
        wrap: true,
        minWidth: "180px",
        maxWidth: "180px",
      },
      {
        name: intl.formatMessage({ id: "total_value_by_order" }),
        selector: (row) => row.totalValueByOrder,
        allowOverflow: true,
        wrap: true,
        minWidth: "180px",
        maxWidth: "180px",
      },
      {
        name: intl.formatMessage({ id: "total_discount_in_order" }),
        selector: (row) => row.totalDiscountInOrder,
        allowOverflow: true,
        wrap: true,
        minWidth: "180px",
        maxWidth: "180px",
      },
      {
        name: intl.formatMessage({ id: "applied_discount_type" }),
        selector: (row) => row.appliedDiscountType,
        allowOverflow: true,
        wrap: true,
        minWidth: "150px",
        maxWidth: "160px",
      },
      {
        name: intl.formatMessage({ id: "provider_email" }),
        selector: (row) => row.providerEmail,
        minWidth: "250px",
        maxWidth: "300px",
        allowOverflow: true,
        wrap: true,
        sortable: true,
      },
      {
        name: intl.formatMessage({ id: "operational_responsible" }),
        selector: (row) => row.contact,
        allowOverflow: true,
        wrap: true,
        minWidth: "220px",
        sortable: true,
      },
      {
        name: intl.formatMessage({ id: "operational_telephone" }),
        selector: (row) => row.contactPhone,
        allowOverflow: true,
        wrap: true,
        minWidth: "210px",
      },
      {
        name: intl.formatMessage({ id: "operational_email" }),
        selector: (row) => row.contactMail,
        allowOverflow: true,
        wrap: true,
        minWidth: "220px",
      },
      {
        name: intl.formatMessage({ id: "date" }),
        selector: (row) => row.date,
        allowOverflow: true,
        wrap: true,
        minWidth: "150px",
        maxWidth: "200px",
      },
      {
        name: intl.formatMessage({ id: "payment_date" }),
        selector: (row) => row.paymentDate,
        allowOverflow: true,
        wrap: true,
        minWidth: "150px",
        maxWidth: "200px",
      },
      {
        name: intl.formatMessage({ id: "initial_date" }),
        selector: (row) => row.startDateAssembly,
        allowOverflow: true,
        wrap: true,
        minWidth: "150px",
        maxWidth: "200px",
      },
      {
        name: intl.formatMessage({ id: "final_date" }),
        selector: (row) => row.finalDateAssembly,
        allowOverflow: true,
        wrap: true,
        minWidth: "150px",
        maxWidth: "200px",
      },
      {
        name: intl.formatMessage({ id: "contracted_period" }),
        selector: (row) => row.periodAssembly,
        allowOverflow: true,
        wrap: true,
        minWidth: "150px",
        maxWidth: "170px",
      },
      {
        name: intl.formatMessage({ id: "on_site_sale_code" }),
        selector: (row) => row.onSiteSaleCode,
        allowOverflow: true,
        wrap: true,
        minWidth: "150px",
        maxWidth: "170px",
      },
    ],
    [intl, opportunityStatusTypes]
  );

  return (
    <AnimatedMainContainer
      breadcrumb={intl.formatMessage({ id: "breadcumb.order_report" })}
    >
      <Container>
        <FormContainer>
          <Form ref={formRef} onSubmit={handleSubmit}>
            <TitleContainer>
              <Title>
                <FormattedMessage id="export_order_report" />
              </Title>
              <GoBackContainer>
                <ActionContainer>
                  <BadgeContainer>
                    <LinkButton onClick={() => history.push("/dashboard")}>
                      <Badge
                        color="#002244"
                        text={intl.formatMessage({ id: "goback" })}
                        fontSize="12px"
                      />
                    </LinkButton>
                  </BadgeContainer>
                </ActionContainer>
              </GoBackContainer>
            </TitleContainer>
            <FieldsContainer>
              <Horizontal>
                <SelectWithLabel
                  name="event_list"
                  validationBlur={validationBlur}
                  label={intl.formatMessage({ id: "event_edition" })}
                  placeholder={intl.formatMessage({ id: "event_edition" })}
                  labelOrientation="vertical"
                  options={events}
                  onChange={onChangeEvent}
                  clearable
                  isMulti
                />
                <InputWithLabel
                  name="document"
                  label={intl.formatMessage({ id: "document_cnpj_or_passport" })}
                  placeholder={intl.formatMessage({
                    id: "document_cnpj_or_passport",
                  })}
                  maxLength={14}
                  onFocus={(event) =>
                    removeDocumentFieldMask(event.target.value)
                  }
                  onBlur={(event) => formatDocumentField(event.target.value)}
                />
              </Horizontal>
              <Horizontal>
                <SelectWithLabel
                  name="contract_type"
                  validationBlur={validationBlur}
                  label={intl.formatMessage({ id: "contract_type" })}
                  placeholder={intl.formatMessage({ id: "contract_type" })}
                  labelOrientation="vertical"
                  clearable
                  options={contractTypes}
                  onChange={onChangeContractType}
                />
                <SelectWithLabel
                  name="contract"
                  validationBlur={validationBlur}
                  label={intl.formatMessage({ id: "contract" })}
                  placeholder={intl.formatMessage({ id: "contract" })}
                  labelOrientation="vertical"
                  clearable
                  options={opportunities}
                />
              </Horizontal>
              <Horizontal>
                <DatePickerWithLabel
                  name="initial_date"
                  validationBlur={validationBlur}
                  label={intl.formatMessage({ id: "initial_date" })}
                  validateEnd={() => validationBlur("final_date")}
                  labelOrientation="vertical"
                  validateBlurBasedOnOwnValue={true}
                />
                <DatePickerWithLabel
                  name="final_date"
                  validationBlur={validationBlur}
                  label={intl.formatMessage({ id: "final_date" })}
                  labelOrientation="vertical"
                />
              </Horizontal>
              <Horizontal>
                <SelectWithLabel
                  name="order_type"
                  validationBlur={validationBlur}
                  label={intl.formatMessage({ id: "type" })}
                  placeholder={intl.formatMessage({ id: "type" })}
                  labelOrientation="vertical"
                  options={orderTypes}
                  onChange={(type) => onChangeOrderType(type)}
                />
                <SelectWithLabel
                  name="product_category_list"
                  label={intl.formatMessage({ id: "product_category" })}
                  placeholder={intl.formatMessage({ id: "product_category" })}
                  labelOrientation="vertical"
                  clearable
                  options={productCategories}
                  onChange={onChangeProductCategory}
                  isMulti
                />
              </Horizontal>
              <Horizontal>
                <InputWithLabel
                  name="order"
                  validationBlur={validationBlur}
                  label={intl.formatMessage({ id: "order" })}
                  maxLength={14}
                  disabled={!isAdditionalOrderTypeSelected}
                />
              </Horizontal>
              <Horizontal>
                <FilterActionsContainer>
                  <SubmitContainer>
                    <FormButton
                      color="#C9C9C9"
                      type="button"
                      onClick={clearFields}
                    >
                      <FormattedMessage id="clear_fields" />
                    </FormButton>
                  </SubmitContainer>
                  <SubmitContainer>
                    <FormButton type="submit" loading={loading}>
                      <FormattedMessage id="search" />
                    </FormButton>
                  </SubmitContainer>
                  {showExport ? (
                    <SubmitContainer>
                      <FormButton type="button" loading={loading}>
                        <Report
                          filename={`ORDER_REPORT`}
                          data={data}
                          intl={intl}
                          opportunityStatusTypes={opportunityStatusTypes}
                        >
                          <FormattedMessage id="export" />
                        </Report>
                      </FormButton>
                    </SubmitContainer>
                  ) : null}
                </FilterActionsContainer>
              </Horizontal>
              {loading && (
                <LongRequestAlertContainer>
                  <FormattedMessage id="long_request_alert" />
                </LongRequestAlertContainer>
              )}
            </FieldsContainer>
          </Form>
        </FormContainer>
      </Container>
      {filtered ? (
        <Table
          columns={columns}
          data={data}
          title={intl.formatMessage({ id: "results" })}
          hasPagination
        />
      ) : (
        <NotificationContainer>
          <FormattedMessage id="need_to_filter_first" />
        </NotificationContainer>
      )}
    </AnimatedMainContainer>
  );
};

export default ManageOrderReport;
