import React, { useEffect, useState, useRef } from "react";

import * as Yup from "yup";
import { Form } from "@unform/web";

import { useSelector } from "react-redux";
import { useToast } from "../../hooks/toast";
import { useHistory, useParams } from "react-router";
import { FormattedMessage, useIntl } from "react-intl";
import { validateSingleFieldOnBlur } from "../../utils/formValidations";
import getValidationErrors from "../../utils/getValidationErrors";

import api from "../../service";

import AnimatedMainContainer from "../../components/AnimatedMainContainer";
import ConfirmationModal from "../../components/ConfirmationModal";
import InputWithLabel from "../../components/InputWithLabel";
import Table from "../../components/Table";
import FormButton from "../../components/FormButton";
import Badge from "../../components/Badge";


import {
  Container,
  FormContainer,
  LinkButton,
  Title,
  TitleContainer,
  BadgeContainer,
  ActionContainer,
  Horizontal,
  FieldsContainer,
  SubmitContainer
} from "./styles";
import { useCallback } from "react";

const Checklist = () => {
  const [isLoading, setIsLoading] = useState(false)
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [checkList, setChecklist] = useState()
  const [deleteId, setDeleteId] = useState(0);

  const formRef = useRef(null);
  const intl = useIntl();
  const { token } = useSelector((state) => state.auth);
  const { addToast } = useToast();
  const { id } = useParams();
  const history = useHistory();

  const schema =  Yup.object().shape({
    checklistName: Yup.string()
  })

  const showConfirmation = (id) => {
    setModalIsOpen(true);
    setDeleteId(id);
  };

  const getChecklist = useCallback(
    async (data) => {
      try {
        setIsLoading(true);
        if (isLoading) {
          return;
        }
        formRef.current?.setErrors({});

        await schema.validate(data, { abortEarly: false });

        api
          .patch(`event-checklist/`, 
            {
              eventId: id,
              descriptionName: data.checklistName
            },
            {
              headers: {
                "Content-Type": "application/json",
                Accept: "*/*",
                Authorization: `Bearer ${token}`,
              },
            }
          )
          .then((result) => {
            setIsLoading(false);
            const checkListResult = result.data.output;
            setChecklist(checkListResult)
          })
          .catch((err) => {
            setIsLoading(false);
            addToast({
              type: "error",
              title: intl.formatMessage({
                id: "error",
              }),
              description: intl.formatMessage({
                id: "api.error." + err.response.data.code,
              }),
            });
          });
      } catch (err) {
        setIsLoading(false);
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);

          formRef.current?.setErrors(errors);

          return;
        }
      }
    },
    [addToast, intl, schema, token, isLoading, id]
  );

  const callDefaultChecklist = () => {
    setIsLoading(true);
    api
    .patch(`event-checklist/`, 
    {
      eventId: id,
      descriptionName: ""
    }, {
        headers: {
          "Content-Type": "application/json",
          Accept: "*/*",
          Authorization: `Bearer ${token}`,
        },
      })
      .then((result) => {
        setIsLoading(false);
        const checkListResult = result.data.output;
        setChecklist(checkListResult)
      })
      .catch((err) => {
        addToast({
          type: "error",
          title: intl.formatMessage({
            id: "error",
          }),
          description: intl.formatMessage({
            id: "api.error." + err.response.data.code,
          }),
        });
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  useEffect(()=> callDefaultChecklist(),[])

  const clearFields = () => {
    formRef.current.reset();
    callDefaultChecklist();
  };

  const validationBlur = (name) => {
    let errors = formRef.current.getErrors();

    validateSingleFieldOnBlur(errors, schema, name, formRef);
  };


  const handleSubmit = getChecklist

  const handleDeleteItem = () => {
    setIsLoading(true);
    if (isLoading) {
      return;
    }
    api
      .delete(`event-checklist/${deleteId}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((result) => {
        setIsLoading(false);
        addToast({
          type: "success",
          title: intl.formatMessage({
            id: "success",
          }),
          description: intl.formatMessage({
            id: "success.faq_delete",
          }),
        });
        const dataRemaining = checkList.filter((d) => d.idNumber !== deleteId);

        setChecklist(dataRemaining);
        setModalIsOpen(false);
      })
      .catch((err) => {
        setIsLoading(false);
        addToast({
          type: "error",
          title: intl.formatMessage({
            id: "error",
          }),
          description: intl.formatMessage({
            id: "api.error." + err.response.data.code,
          }),
        });
        setModalIsOpen(false);
      });
    setDeleteId(0);
  };

  const columns = [
    {
      name: intl.formatMessage({ id: "checklist.abrev.item_description_pt" }),
      selector: (row) => row.descriptionPt,
      sortable: true,
      maxWidth: "250px",
    },
    {
      name: intl.formatMessage({ id: "checklist.abrev.additional_info_pt" }),
      selector: (row) => row.additionalInfoPt,
      sortable: true,
      maxWidth: "400px",
    },
    {
      name: intl.formatMessage({ id: "checklist.abrev.item_description_en" }),
      selector: (row) => row.descriptionEn,
      sortable: true,
      maxWidth: "250px",
    },
    {
      name: intl.formatMessage({ id: "checklist.abrev.additional_info_en" }),
      selector: (row) => row.additionalInfoEn,
      sortable: true,
      maxWidth: "400px",
    },
    {
      cell: (row) => (
        <LinkButton to={`/event-checklist/${id}/item/edit/${row.idNumber}`}>
          <Badge color="#002244" text={intl.formatMessage({ id: "edit" })} />
        </LinkButton>
      ),
      right: true,
      maxWidth: "140px",
      minWidth: "140px",
    },
    {
      cell: (row) => (
        <Badge
          color="#cc0000"
          text={intl.formatMessage({ id: "delete" })}
          onClick={() => showConfirmation(row.idNumber)}
        />
      ),
      right: true,
      maxWidth: "140px",
      minWidth: "140px",
    },
  ];

  const pushBack = () => {
    history.push("/events");
  };

  return (
    <>
      <AnimatedMainContainer breadcrumb="Checklist">
        <Form
          ref={formRef}
          onSubmit={handleSubmit}
        >
          <Container>
            <FormContainer>
              <TitleContainer>
                <Title>
                  <FormattedMessage id="checklist.edit" />
                </Title>
                <ActionContainer>
                  <BadgeContainer onClick={pushBack}>
                    <Badge
                      color="#002244"
                      text={intl.formatMessage({ id: "goback" })}
                      fontSize="12px"
                    />
                  </BadgeContainer>
                  <BadgeContainer onClick={() => history.push(`/event-checklist/${id}/item/create`)}>
                    <Badge
                      color="#002244"
                      text={intl.formatMessage({ id: "new" })}
                      fontSize="12px"
                    />
                  </BadgeContainer>
                </ActionContainer>
              </TitleContainer>
              <FieldsContainer>
                <Horizontal>
                  <InputWithLabel
                    name="checklistName"
                    validationBlur={validationBlur}
                    label={intl.formatMessage({ id: "item" })}
                  />
                  <SubmitContainer>
                    <FormButton
                      color="#C9C9C9"
                      type="button"
                      onClick={clearFields}
                    >
                      <FormattedMessage id="clear_fields" />
                    </FormButton>
                  </SubmitContainer>
                  <SubmitContainer>
                    <FormButton type="submit" loading={isLoading}>
                      <FormattedMessage id="search" />
                    </FormButton>
                  </SubmitContainer>
                </Horizontal>
              </FieldsContainer>
            </FormContainer>
          </Container>
        </Form>
        <Table
          columns={columns}
          data={checkList}
          title={intl.formatMessage({ id: "results" })}
        />
      </AnimatedMainContainer>
      <ConfirmationModal
        setIsOpen={setModalIsOpen}
        modalIsOpen={modalIsOpen}
        confirmationFunction={() => handleDeleteItem()}
        description={intl.formatMessage({ id: "exclusion_confirmation" })}
        confirmationText={intl.formatMessage({ id: "yes" })}
        cancelText={intl.formatMessage({ id: "no" })}
        isLoading={isLoading}
      />
    </>
  )
}

export default Checklist;