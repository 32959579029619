import React from "react";

const HashStraight = (props) => {
  const { size = "15", color = "#11A7D9", filled = false } = props;

  if (filled) {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={size}
        height={size}
        viewBox="0 0 16 17"
        fill="none"
      >
        <g clipPath="url(#clip0_295_46493)">
          <path
            d="M2.5 6.5H13.5"
            stroke="#444444"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M2.5 10.5H13.5"
            stroke="#444444"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M10 3V14"
            stroke="#444444"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M6 3V14"
            stroke="#444444"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </g>
        <defs>
          <clipPath id="clip0_295_46493">
            <rect
              width="16"
              height="16"
              fill="white"
              transform="translate(0 0.5)"
            />
          </clipPath>
        </defs>
      </svg>
    );
  }

  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 15 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_295_46493)">
        <path
          d="M2.5 6.5H13.5"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M2.5 10.5H13.5"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M10 3V14"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M6 3V14"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_295_46493">
          <rect
            width="16"
            height="16"
            fill="white"
            transform="translate(0 0.5)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default HashStraight;
