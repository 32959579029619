import React from "react";

const DownloadSimple = (props) => {
  const { size = "16", color = "white" } = props;

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 16 16"
      fill="none"
    >
      <g clipPath="url(#clip0_435_1039)">
        <path
          d="M8.3335 9.5V2.5"
          stroke={color}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M13.8335 9.5V13C13.8335 13.1326 13.7808 13.2598 13.687 13.3536C13.5933 13.4473 13.4661 13.5 13.3335 13.5H3.3335C3.20089 13.5 3.07371 13.4473 2.97994 13.3536C2.88617 13.2598 2.8335 13.1326 2.8335 13V9.5"
          stroke={color}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M10.8335 7L8.3335 9.5L5.8335 7"
          stroke={color}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_435_1039">
          <rect
            width="16"
            height="16"
            fill={color}
            transform="translate(0.333496)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default DownloadSimple;
