import { combineReducers } from "redux";
import auth from "./auth/reducer";
import alerts from "./alerts/reducer";
import language from "./language/reducer";
import notification from "./notification/reducer";
import event from "./event/reducer";
import initialSettings from "./initialSettings/reducer";
import contract from "./contract/reducer";
import account from "./account/reducer";
import system from "./system/reducer";
import faq from "./faq/reducer";
import checklist from "./checklist/reducer";
import profile from "./profile/reducer";
import ecommerce from "./ecommerce/reducer";
import employee from "./employee/reducer";
import credentials from "./credentials/reducer";
import transferCredential from "./transferCredential/reducer";
import associateCompany from "./associateCompany/reducer";
import document from "./document/reducer";
import impersonate from "./impersonate/reducer";
import billing from "./billing/reducer";
import guests from "./guests/reducer";

export default combineReducers({
  auth,
  alerts,
  language,
  notification,
  event,
  initialSettings,
  contract,
  account,
  system,
  faq,
  checklist,
  profile,
  ecommerce,
  employee,
  credentials,
  transferCredential,
  associateCompany,
  document,
  impersonate,
  billing,
  guests,
});
