export function getListCredentials(
  params,
  callbackPaginationInfo,
  callbackError
) {
  return {
    type: "GET_LIST_CREDENTIALS",
    payload: {
      params,
      callbackPaginationInfo,
      callbackError,
    },
  };
}

export function setListCredentialsSuccess(credentials) {
  return {
    type: "SET_LIST_CREDENTIALS_SUCCESS",
    payload: {
      credentials,
    },
  };
}

export function getTypeCredentialSuccess(types) {
  return {
    type: "GET_TYPE_CREDENTIAL_SUCCESS",
    payload: {
      types,
    },
  };
}

export function getCollaboratorOfContract(
  params,
  callbackSuccess,
  callbackError
) {
  return {
    type: "GET_COLLABORATOR_OF_CONTRACT",
    payload: {
      params,
      callbackSuccess,
      callbackError,
    },
  };
}

export function getCollaboratorOfContractSuccess(collaborator) {
  return {
    type: "GET_COLLABORATOR_OF_CONTRACT_SUCCESS",
    payload: {
      collaborator,
    },
  };
}

export function getCredential(id, callbackSuccess, callbackError) {
  return {
    type: "GET_CREDENTIAL",
    payload: {
      id,
      callbackSuccess,
      callbackError,
    },
  };
}

export function handleAssociateCredential(
  data,
  callbackSuccess,
  callbackError
) {
  return {
    type: "HANDLE_ASSOCIATE_CREDENTIAL",
    payload: {
      data,
      callbackSuccess,
      callbackError,
    },
  };
}

export function updateAssociateCredential(
  data,
  callbackSuccess,
  callbackError
) {
  return {
    type: "UPDATE_ASSOCIATE_CREDENTIAL",
    payload: {
      data,
      callbackSuccess,
      callbackError,
    },
  };
}

export function deleteAssociateCredential(id, callbackSuccess, callbackError) {
  return {
    type: "DELETE_ASSOCIATE_CREDENTIAL",
    payload: {
      id,
      callbackSuccess,
      callbackError,
    },
  };
}

export function getQuotaCredential(params, callbackError) {
  return {
    type: "GET_QUOTA_CREDENTIAL",
    payload: {
      params,
      callbackError,
    },
  };
}

export function setQuotaCredentialSuccess(quota) {
  return {
    type: "SET_QUOTA_CREDENTIAL_SUCCESS",
    payload: {
      quota,
    },
  };
}

export function getCredentialSchedule(event, opportunity, callbackError) {
  return {
    type: "GET_CREDENTIAL_SCHEDULE",
    payload: {
      event,
      opportunity,
      callbackError,
    },
  };
}

export function setCredentialScheduleSuccess(credentialSchedule) {
  return {
    type: "SET_CREDENTIAL_SCHEDULE_SUCCESS",
    payload: {
      credentialSchedule,
    },
  };
}

export function postCredentialSchedule(params, callbackError, callbackSuccess) {
  return {
    type: "POST_CREDENTIAL_SCHEDULE",
    payload: {
      params,
      callbackError,
      callbackSuccess,
    },
  };
}

export function editCredentialSchedule(
  params,
  id,
  callbackError,
  callbackSuccess
) {
  return {
    type: "EDIT_CREDENTIAL_SCHEDULE",
    payload: {
      params,
      id,
      callbackError,
      callbackSuccess,
    },
  };
}

export function cleanQuataCredential() {
  return {
    type: "CLEAN_QUOTA_CREDENTIAL",
  };
}

export function getCredentialScheduleAvailableDates(params, callbackError) {
  return {
    type: "GET_CREDENTIAL_SCHEDULE_AVAILABLE_DATES",
    payload: {
      params,
      callbackError,
    },
  };
}

export function getCredentialScheduleAvailableTimes(params, callbackError) {
  return {
    type: "GET_CREDENTIAL_SCHEDULE_AVAILABLE_TIMES",
    payload: {
      params,
      callbackError,
    },
  };
}

export function setCredentialScheduleAvailableDates(dates) {
  return {
    type: "SET_CREDENTIAL_SCHEDULE_AVAILABLE_DATES",
    payload: {
      dates,
    },
  };
}

export function setCredentialScheduleAvailableTimes(times) {
  return {
    type: "SET_CREDENTIAL_SCHEDULE_AVAILABLE_TIMES",
    payload: {
      times,
    },
  };
}

export function clearCredentialScheduleAvailableDates() {
  return {
    type: "CLEAR_CREDENTIAL_SCHEDULE_AVAILABLE_DATES",
  };
}

export function clearCredentialScheduleAvailableTimes() {
  return {
    type: "CLEAR_CREDENTIAL_SCHEDULE_AVAILABLE_TIMES",
  };
}
