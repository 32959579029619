import React from 'react'

const SmallClock = (props) => {
  const {
    size = '16',
    color = 'black'
  } = props


  return (
    <svg width={size} height={size} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_4_5825)">
        <path d="M8 14C11.3137 14 14 11.3137 14 8C14 4.68629 11.3137 2 8 2C4.68629 2 2 4.68629 2 8C2 11.3137 4.68629 14 8 14Z" stroke={color} strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M8 4.5V8H11.5" stroke={color} strokeLinecap="round" strokeLinejoin="round"/>
      </g>
      <defs>
        <clipPath id="clip0_4_5825">
          <rect width={size} height={size} fill="white"/>
        </clipPath>
      </defs>
    </svg>
  )
}

export default SmallClock