export function getSystem(callbackError) {
  return {
    type: "GET_SYSTEM",
    payload: {
      callbackError,
    },
  };
}

export function setSystemSuccess(system) {
  return {
    type: "SET_SYSTEM",
    payload: {
      system,
    },
  };
}

export function getListCountries(callbackError) {
  return {
    type: "GET_LIST_COUNTRIES",
    payload: {
      callbackError,
    },
  };
}

export function setListCountriesSuccess(countries) {
  return {
    type: "SET_LIST_COUNTRIES",
    payload: {
      countries,
    },
  };
}

export function getCep(cep, callBackSuccess, callbackError) {
  return {
    type: "GET_CEP",
    payload: {
      cep,
      callBackSuccess,
      callbackError,
    },
  };
}

export function clearSystem() {
  return {
    type: "CLEAR_SYSTEM",
  };
}
