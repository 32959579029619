import React, { useEffect, useRef, useState, useCallback } from "react";
import { useField } from "@unform/core";
import { MdError } from "react-icons/md";

import {
  Container,
  Error,
  Label,
  LabelContainer,
  RootContainer,
  InputRow,
  AsteriskText,
} from "./styles";
import InputMask from "react-input-mask";

import { cpf, cnpj } from "cpf-cnpj-validator";
import { cnpjMask, cpfMask, genericDocumentMask } from "../../utils/mask";

const CpfCnpj = ({
  name,
  containerStyle = {},
  icon: Icon,
  validationBlur,
  disabled,
  personType,
  placeholder,
  labelOrientation,
  labelWidth,
  hasLabel,
  required,
  ...rest
}) => {
  const inputRef = useRef(null);

  const [mask, setMask] = useState(genericDocumentMask);
  const [maskChar, setMaskChar] = useState();
  const [isFocused, setIsFocused] = useState(false);
  const [isFilled, setIsFilled] = useState(false);

  const { fieldName, defaultValue, error, registerField } = useField(name);

  const handleInputFocus = useCallback(() => {
    setIsFocused(true);
  }, []);

  const handleInputBlur = useCallback(() => {
    setIsFocused(false);

    setIsFilled(!!inputRef.current?.value);

    if (validationBlur) {
      validationBlur(fieldName, inputRef.current?.value);
    }
  }, [fieldName, validationBlur]);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      path: "value",
    });
  }, [fieldName, registerField]);

  useEffect(() => {
    if (personType === "J") {
      setMask(cnpjMask);
      setMaskChar();
    } else if (personType === "F") {
      setMask(cpfMask);
      setMaskChar();
    } else {
      setMask(genericDocumentMask);
      setMaskChar(null);
    }
  }, [personType]);

  return (
    <RootContainer labelOrientation={labelOrientation}>
      <InputRow labelOrientation={labelOrientation}>
        {hasLabel && (
          <LabelContainer labelWidth={labelWidth}>
            <Label>{placeholder}</Label>
            {required && <AsteriskText>*</AsteriskText>}
          </LabelContainer>
        )}
        <Container
          style={containerStyle}
          isFocused={isFocused}
          isFilled={isFilled}
          isErrored={!!error}
          disabled={disabled}
          data-testid="input-container"
        >
          {Icon && <Icon size={24} />}
          <InputMask
            mask={mask}
            maskChar={maskChar}
            onFocus={handleInputFocus}
            onBlur={handleInputBlur}
            defaultValue={defaultValue}
            ref={inputRef}
            disabled={disabled}
            placeholder={placeholder}
            {...rest}
          />
        </Container>
      </InputRow>
      {error && (
        <Error
          labelWidth={labelWidth}
          labelOrientation={labelOrientation}
          title={error}
        >
          <MdError color="#e00000" size={20} />
          {error}
        </Error>
      )}
    </RootContainer>
  );
};

export default CpfCnpj;
