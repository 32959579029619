import React from "react";

const UserCircle = (props) => {
  const { size = "26", color = "#444444" } = props;

  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 26 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_539_13146)">
        <path
          d="M13 22.75C18.3848 22.75 22.75 18.3848 22.75 13C22.75 7.61522 18.3848 3.25 13 3.25C7.61522 3.25 3.25 7.61522 3.25 13C3.25 18.3848 7.61522 22.75 13 22.75Z"
          stroke={color}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M13 16.25C15.2437 16.25 17.0625 14.4312 17.0625 12.1875C17.0625 9.94384 15.2437 8.125 13 8.125C10.7563 8.125 8.9375 9.94384 8.9375 12.1875C8.9375 14.4312 10.7563 16.25 13 16.25Z"
          stroke={color}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M6.48047 20.2483C7.09178 19.0442 8.02458 18.0329 9.17547 17.3264C10.3264 16.62 11.6504 16.2461 13.0008 16.2461C14.3512 16.2461 15.6752 16.62 16.8261 17.3264C17.977 18.0329 18.9098 19.0442 19.5211 20.2483"
          stroke={color}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_824_1660">
          <rect width={size} height={size} fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default UserCircle;
