import { useIntl } from "react-intl";

const intl = useIntl

const swapcard_return_types = () => {
  const swapcard_return_array = [
    {
      label: intl().formatMessage({ id: "GRAPHQL_VALIDATION_FAILED" }),
      value: "GRAPHQL_VALIDATION_FAILED"
    },
    {
      label: intl().formatMessage({ id: "EMAIL_INVALID" }),
      value: "EMAIL_INVALID"
    },
    {
      label: intl().formatMessage({ id: "EMAIL_EMPTY" }),
      value: "EMAIL_EMPTY"
    },
    {
      label: intl().formatMessage({ id: "CLIENT_ID_CONFLICT" }),
      value: "CLIENT_ID_CONFLICT"
    },
    {
      label: intl().formatMessage({ id: "INTERNAL_SERVER_ERROR" }),
      value: "INTERNAL_SERVER_ERROR"
    },
    {
      label: intl().formatMessage({ id: "BAD_USER_INPUT" }),
      value: "BAD_USER_INPUT"
    },
    {
      label: intl().formatMessage({ id: "NOT_ALLOWED" }),
      value: "NOT_ALLOWED"
    },
    {
      label: intl().formatMessage({ id: "FORBIDDEN" }),
      value: "FORBIDDEN"
    },
    {
      label: intl().formatMessage({ id: "EMPTY_RESPONSE" }),
      value: "EMPTY_RESPONSE"
    },
    {
      label: intl().formatMessage({ id: "EMPTY_BODY" }),
      value: "EMPTY_BODY"
    },
    {
      label: intl().formatMessage({ id: "UNPROCESSABLE_RESPONSE" }),
      value: "UNPROCESSABLE_RESPONSE"
    },
    {
      label: intl().formatMessage({ id: "SWAPCARD_SUCCESS" }),
      value: "SWAPCARD_SUCCESS"
    },
    {
      label: intl().formatMessage({ id: "EMAIL_CONFLICT" }),
      value: "EMAIL_CONFLICT"
    },
    {
      label: intl().formatMessage({ id: "URL_INVALID" }),
      value: "URL_INVALID",
    },
    {
      label: intl().formatMessage({ id: "ID_NOT_FOUND" }),
      value: "ID_NOT_FOUND"
    },
    {
      label: intl().formatMessage({ id: "TEXT_TOO_LONG" }),
      value: "TEXT_TOO_LONG",
    },
    {
      label: intl().formatMessage({ id: "FIELD_VALUE_BAD_INPUT" }),
      value: "FIELD_VALUE_BAD_INPUT",
    },
    {
      label: intl().formatMessage({ id: "INTERNAL_ERROR" }),
      value: "INTERNAL_ERROR"
    },
    {
      label: intl().formatMessage({ id: "GRAPHQL_PARSE_FAILED" }),
      value: "GRAPHQL_PARSE_FAILED"
    },
  ];

  return swapcard_return_array;
}

export default swapcard_return_types;

