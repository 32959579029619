export function fillTimepicker(interval = null) {
  var finalArray = [];
  for (let hourIndex = 0; hourIndex < 24; hourIndex++) {
    for (let minuteIndex = 0; minuteIndex < 60; minuteIndex++) {
      if (interval) {
        if (minuteIndex % interval === 0) {
          finalArray.push({
            value: formatHour(hourIndex, minuteIndex),
            label: formatHour(hourIndex, minuteIndex),
          });
        }
      } else {
        finalArray.push({
          value: formatHour(hourIndex, minuteIndex),
          label: formatHour(hourIndex, minuteIndex),
        });
      }
    }
  }
  return finalArray;
}

export function fillTimePickerBetween(
  interval = null,
  start = "00:00",
  end = "00:00",
  exclusions = []
) {
  var finalArray = [];

  const startSplit = start.split(":");
  var startHour = parseInt(startSplit[0]);
  var startMinute = parseInt(startSplit[1]);

  const endSplit = end.split(":");
  var endHour = parseInt(endSplit[0]);
  var endMinute = parseInt(endSplit[1]);

  var startTime = startHour * 60 + startMinute;
  var endTime = endHour * 60 + endMinute;

  var isMultipleOfInterval = (startTime - endTime) % interval === 0;

  for (
    let i = startTime;
    i < (isMultipleOfInterval ? endTime : endTime - interval);
    i += interval
  ) {
    let hh = Math.floor(i / 60);
    let mm = i % 60;

    const hour = formatHour(hh, mm);

    if (!exclusions.includes(hour)) {
      finalArray.push({
        value: hour,
        label: hour,
      });
    }
  }
  return finalArray;
}

export function getIntervalHours(
  interval = null,
  start = "00:00",
  end = "00:00",
  exclusions = []
) {
  var finalArray = [];

  const startSplit = start.split(":");
  var startHour = parseInt(startSplit[0]);
  var startMinute = parseInt(startSplit[1]);

  const endSplit = end.split(":");
  var endHour = parseInt(endSplit[0]);
  var endMinute = parseInt(endSplit[1]);

  var startTime = startHour * 60 + startMinute;
  var endTime = endHour * 60 + endMinute;

  var isMultipleOfInterval = (startTime - endTime) % interval === 0;

  for (
    let i = startTime;
    i < (isMultipleOfInterval ? endTime : endTime - interval);
    i += interval
  ) {
    let hh = Math.floor(i / 60);
    let mm = i % 60;

    const hour = formatHour(hh, mm);

    if (!exclusions.includes(hour)) {
      finalArray.push(
        hour
      );
    }
  }
  return finalArray;
}

function formatHour(hour, minute) {
  return `${String(hour).padStart(2, "0")}:${String(minute).padStart(2, "0")}`;
}
