import styled from "styled-components";

export const Tabs = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  border-bottom: 1px solid #d4d4d4;
  margin-bottom: 25px;

  .selectedTab {
    border-bottom: 2px solid #11a7d9;
    h2 {
      color: #11a7d9;
    }
  }

  .removeTab {
    display: none;
  }
`;

export const TabBox = styled.div`
  height: 100%;
  display: ${({ $removeTab }) => ($removeTab ? "none" : "flex")};
  flex-direction: column;
  justify-content: center;
  margin-right: 55px;
  padding-right: 10px;
  cursor: pointer;
`;

export const TabTitle = styled.h2`
  color: #6c6c6c;
  font-family: "Open Sans";
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 150%;
  height: 100%;
`;
