import React from "react";
import Header from "./Header";
import Footer from "./Footer";
import Sidebar from "./Sidebar";

import { Container, Content } from "./styles";

const Layout = ({ children }) => {
  return (
    <Container>
      <Sidebar />
      <Content>
        <Header />
        {children}
      </Content>
      <Footer />
    </Container>
  );
};

export default Layout;
