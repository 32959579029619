import React, { useEffect, useRef, useState, useCallback } from "react";
import { useField } from "@unform/core";
import { MdError } from "react-icons/md";
import InputMask from "react-input-mask";

import {
  Container,
  Error,
  RootContainer,
  Label,
  LabelContainer,
  AsteriskText,
} from "./styles";

const InputWithLabel = ({
  name,
  containerStyle = {},
  icon: Icon,
  validationBlur,
  disabled,
  label,
  className,
  maxWidth,
  required,
  mask,
  ...rest
}) => {
  const inputRef = useRef(null);

  const [isFocused, setIsFocused] = useState(false);
  const [isFilled, setIsFilled] = useState(false);

  const { fieldName, defaultValue, error, registerField } = useField(name);

  const handleInputFocus = useCallback(() => {
    setIsFocused(true);
  }, []);

  const handleInputBlur = useCallback(async () => {
    let fieldHasValue = !!inputRef.current?.value;
    setIsFocused(false);

    setIsFilled(fieldHasValue);

    if (validationBlur) {
      validationBlur(fieldName, inputRef.current?.value);
    }
  }, []);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      path: "value",
    });
  }, [fieldName, registerField]);

  return (
    <RootContainer className={className} maxWidth={maxWidth}>
      <LabelContainer>
        <Label>{label}</Label>
        {required && <AsteriskText>*</AsteriskText>}
      </LabelContainer>

      <Container
        style={containerStyle}
        isFocused={isFocused}
        isFilled={isFilled}
        isErrored={!!error}
        disabled={disabled}
      >
        <InputMask
          mask={mask}
          onFocus={handleInputFocus}
          onBlur={handleInputBlur}
          defaultValue={defaultValue}
          ref={inputRef}
          disabled={disabled}
          {...rest}
        />
      </Container>
      {error && (
        <Error title={error}>
          <MdError color="#e00000" size={20} />
          {error}
        </Error>
      )}
    </RootContainer>
  );
};

export default InputWithLabel;
