import React, { 
  useEffect, 
  useState, 
  useRef, 
  useCallback,
  useMemo
} from "react";
import { Form } from "@unform/web";
import * as Yup from "yup";

import { useToast } from "../../hooks/toast";
import { useHistory } from "react-router";
import { useSelector } from "react-redux";
import { FormattedMessage, useIntl } from "react-intl";
import api from "../../service";

import AnimatedMainContainer from "../../components/AnimatedMainContainer";
import Table from "../../components/Table";
import Badge from "../../components/Badge";
import FormButton from "../../components/FormButton";
import SelectWithLabel from "../../components/SelectWithLabel";

import { validateSingleFieldOnBlur } from "../../utils/formValidations";
import getValidationErrors from "../../utils/getValidationErrors";
import { convertToDateOnly } from "../../utils/date";
import provisionTypesConstants from "../../constants/provisionTypes";

import {
    Container,
    FormContainer,
    TitleContainer,
    Title,
    FieldsContainer,
    Horizontal,
    FilterActionsContainer,
    SubmitContainer,
    LinkButton,
    GoBackContainer,
    NotificationContainer
  } from "./styles";


const ConsultProviderByEvent = () => {
  const [providerOptions, setProviderOptions] = useState([])
  const [selectedProvider, setSelectedProvider] = useState()
  const [data, setData] = useState()
  const [serviceTypesOptions, setServiceTypesOptions] = useState([])
  const [eventOptions, setEventsOptions] = useState([])
  const [loading, setLoading] = useState(false)
  
  const intl = useIntl();
  const { token } = useSelector((state) => state.auth);
  const { addToast } = useToast();
  const formRef = useRef(null);
  const history = useHistory();
  const provisionTypes = provisionTypesConstants();

  const schema = Yup.object().shape({
    serviceProviderId: Yup.string().required(intl.formatMessage({ id: "error.field_required" })),
    serviceTypeId: Yup.string().nullable().required(intl.formatMessage({ id: "error.field_required" })),
    eventId: Yup.string().nullable()
  })

  useEffect(()=>{
      setLoading(true)
      api
      .get("/serviceprovider", {
        headers: {
          "Content-Type": "application/json",
          Accept: "*/*",
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => setProviderOptions(response.data.serviceProviderDropdownOutputList)
      )
      .catch((err) =>
        addToast({
          type: "error",
          title: intl.formatMessage({
            id: "error",
          }),
          description: intl.formatMessage({
            id: "api.error." + err.response.data.code,
          }),
        })
      )
      .finally(() => setLoading(false));
  },[intl, token, addToast])

  useEffect(()=>{
    if(!selectedProvider) return
    setLoading(true)
    api
      .get(`/serviceprovider/services/${selectedProvider}`, {
        headers: {
          "Content-Type": "application/json",
          Accept: "*/*",
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        const serviceTypes =
          response.data.provisionTypes.map(
            (service) => ({
              value: service.provisionType,
              label: provisionTypes.find((pt) => pt.value === service.provisionType)?.label,
            })
          )
          const allOption = [{
            label: intl.formatMessage({ id: "all" }),
            value: 0
          }]
        setServiceTypesOptions([...allOption, ...serviceTypes ])
      })
      .catch((err) =>
        addToast({
          type: "error",
          title: intl.formatMessage({
            id: "error",
          }),
          description: intl.formatMessage({
            id: "api.error." + err.response.data.code,
          }),
        })
      )
      .finally(() => setLoading(false));

  },[intl, token, addToast, selectedProvider])

  useEffect(()=>{
    if(!selectedProvider) return
    setLoading(true)
    api
      .get(`/serviceprovider/events/${selectedProvider}`, {
        headers: {
          "Content-Type": "application/json",
          Accept: "*/*",
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        const events =
          response.data.events.map(
            (event) => ({
              value: event.id,
              label: event.eventName
            })
          )
        setEventsOptions(events)
      })
      .catch((err) =>
        addToast({
          type: "error",
          title: intl.formatMessage({
            id: "error",
          }),
          description: intl.formatMessage({
            id: "api.error." + err.response.data.code,
          }),
        })
      )
      .finally(() => setLoading(false));

  },[intl, token, addToast, selectedProvider])
  

  const handleSubmit = useCallback(
    async (data) => {
      try {
        formRef.current?.setErrors({});

        setLoading(true);
        if (loading) return;

        await schema.validate(data, { abortEarly: false });

        api
          .patch(
            `/admin/provider-association/get-service-provider-associations`,
            {
              serviceProviderId: data.serviceProviderId,
              serviceTypeId: data.serviceTypeId,
              eventId: data.eventId
            },
            {
              headers: {
                "Content-Type": "application/json",
                Accept: "*/*",
                Authorization: `Bearer ${token}`,
              },
            }
          )
          .then((result) => {
            setData(result.data.serviceProviderAssociationsOutputList)
          })
          .catch((err) =>{
            addToast({
              type: "error",
              title: intl.formatMessage({
                id: "error",
              }),
              description: intl.formatMessage({
                id: "api.error." + err.response.data.code,
              }),
            })
          })
          .finally(() => setLoading(false));
      } catch (err) {
        setLoading(false);
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);
          formRef.current?.setErrors(errors);
          return;
        }
      }
    },
    [addToast, intl, loading, schema, token]
  );

  const onChangeProvider = (event) =>{
    formRef.current.setFieldValue("serviceTypeId")
    formRef.current.setFieldValue("eventId")
    setSelectedProvider(event?.value)
  }

  const validationBlur = (name) => {
      let errors = formRef.current.getErrors()
      validateSingleFieldOnBlur(errors, schema, name, formRef)
  };

  const clearFields = () => {
    formRef.current.reset()
    formRef.current.setFieldValue("serviceProviderId", "")
    formRef.current.setFieldValue("serviceTypeId", "")
    formRef.current.setFieldValue("eventId", "")
    setData()
    setSelectedProvider()
  };

  const columns = useMemo(
    () => [
      {
        name: intl.formatMessage({ id: "exhibitor" }),
        cell: (row) => row.exhibitorName,
        allowOverflow: true,
        wrap: true,
        minWidth: "150px",
        maxWidth: "300px",
        sortable: true,
      },
      {
        name: intl.formatMessage({ id: "event_edition" }),
        cell: (row) => row.eventEdition,
        allowOverflow: true,
        wrap: true,
        minWidth: "150px",
        maxWidth: "350px",
        sortable: true,
      },
      {
        name: intl.formatMessage({ id: "contract" }),
        cell: (row) => row.contract,
        allowOverflow: true,
        wrap: true,
        minWidth: "150px",
        maxWidth: "150px",
        sortable: true,
      },
      {
        name: intl.formatMessage({ id: "contract_type" }),
        cell: (row) => row.contractType,
        allowOverflow: true,
        wrap: true,
        minWidth: "150px",
        maxWidth: "175px",
        sortable: true,
      },
      {
        name: intl.formatMessage({ id: "user" }),
        cell: (row) => row.user,
        allowOverflow: true,
        wrap: true,
        minWidth: "150px",
        maxWidth: "300px",
        sortable: true,
      },
      {
        name: intl.formatMessage({ id: "association_date" }),
        cell: (row) => convertToDateOnly(row.associationDate),
        allowOverflow: true,
        wrap: true,
        minWidth: "150px",
        maxWidth: "200px",
        sortable: true,
      },
      {
        name: intl.formatMessage({ id: "service_type" }),
        cell: (row) => 
          provisionTypes.find((pt) => pt.value === row.provisionType)?.label,
        allowOverflow: true,
        wrap: true,
        minWidth: "150px",
        maxWidth: "250px",
        sortable: true,
      },
    ],[intl, provisionTypes])

  return (
    <AnimatedMainContainer>
      <Container>
        <FormContainer>
          <Form
            ref={formRef}
            onSubmit={handleSubmit}    
          >
            <TitleContainer>
              <Title>
                <FormattedMessage id="sidebar.consult_provider_by_event" />
              </Title>
              <GoBackContainer>
                <LinkButton onClick={() => history.push("/dashboard")}>
                  <Badge
                    color="#002244"
                    text={intl.formatMessage({ id: "goback" })}
                    fontSize="12px"
                  />
                </LinkButton>
              </GoBackContainer>
            </TitleContainer>
            <FieldsContainer>
              <Horizontal>
                <SelectWithLabel
                  name="serviceProviderId"
                  options={providerOptions}
                  validationBlur={validationBlur}
                  label={intl.formatMessage({ id: "service_providers" })}
                  placeholder={intl.formatMessage({ id: "service_providers" })}
                  loading={loading}
                  onChange={onChangeProvider}
                  required
                />
                <SelectWithLabel
                  name="serviceTypeId"
                  options={serviceTypesOptions}
                  disabled={serviceTypesOptions.length === 0}
                  validationBlur={validationBlur}
                  label={intl.formatMessage({ id: "service_type" })}
                  placeholder={intl.formatMessage({ id: "service_type" })}
                  loading={loading}
                  required
                />
              </Horizontal>
              <Horizontal>
                <SelectWithLabel
                  name="eventId"
                  options={eventOptions}
                  validationBlur={validationBlur}
                  label={intl.formatMessage({ id: "event" })}
                  placeholder={intl.formatMessage({ id: "event" })}
                  disabled={eventOptions.length === 0}
                  clearable
                  loading={loading}
                />
              </Horizontal>
              <Horizontal>
                <FilterActionsContainer>
                  <SubmitContainer>
                    <FormButton
                      color="#C9C9C9"
                      type="button"
                      onClick={clearFields}
                    >
                      <FormattedMessage id="clear_fields" />
                    </FormButton>
                  </SubmitContainer>
                  <SubmitContainer>
                    <FormButton type="submit" loading={loading}>
                      <FormattedMessage id="search" />
                    </FormButton>
                  </SubmitContainer>
                </FilterActionsContainer>
              </Horizontal>
            </FieldsContainer>
          </Form>
        </FormContainer>
      </Container>
      {data ? (
      <Table
        columns={columns}
        data={data}
        title={intl.formatMessage({ id: "results" })}
        hasPagination
      />
    ) : (
      <NotificationContainer>
        <FormattedMessage id="need_to_filter_first" />
      </NotificationContainer>
    )}
    </AnimatedMainContainer>
  )
}

export default ConsultProviderByEvent