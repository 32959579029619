import styled from "styled-components";

import { Form } from "antd";

export const Container = styled.div`
  height: 94vh;
  background: #fcfcfc;
  padding: 50px 110px 100px 70px;
`;

export const ContentForm = styled(Form)`
  margin-top: 30px;
  display: flex;
  width: 595px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 4px;
`;

export const ContainerBtn = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  gap: 8px;
  margin-top: 15px;
`;
