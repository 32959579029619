import produce from "immer";

const INITIAL_STATE = {
  allEvent: [],
};

const event = (state = INITIAL_STATE, action) => {
  return produce(state, (draft) => {
    switch (action.type) {
      case "SET_ALL_EVENT": {
        const { allEvent } = action.payload;

        draft.allEvent = allEvent;

        localStorage.setItem(
          "InformaMarket:allEvent",
          JSON.stringify(allEvent)
        );

        break;
      }

      case "CLEAR_ALL_EVENT": {

        draft.allEvent = [];

        localStorage.setItem(
          "InformaMarket:allEvent",
          JSON.stringify([])
        );

        break;
      }

      default: {
        return draft;
      }
    }
  });
};

export default event;
