import { all, takeLatest, call, put } from "redux-saga/effects";

import {
  setChecklistSuccess,
  clearChecklist,
  setEmptyChecklist,
} from "./actions";
import {
  getChecklistService,
  solveChecklistStepService,
} from "../../../service/checklist.service";

function* getChecklist({ payload }) {
  const { selectedEvent } = payload;
  try {
    const response = yield call(getChecklistService, selectedEvent);

    if (response.status === 200 && response.data.code === 33005) {
      yield put(setChecklistSuccess(response.data.output));

      if (response.data.output.length === 0) { 
        yield put(setEmptyChecklist(true));
        payload.callbackEmpty()
      }
      else yield put(setEmptyChecklist(false));
    }
  } catch (error) {
    payload.callbackError(error.response.data.code);
  } finally {
    payload.callbackSuccess();
  }
}

function* solveChecklistStep({ payload }) {
  const {
    eventChecklistId,
    userChecklistId,
    solved,
    selectedEvent,
    callbackError,
  } = payload;
  const response = yield call(
    solveChecklistStepService,
    eventChecklistId,
    userChecklistId,
    solved
  );
  if (response.status === 200) {
    try {
      const responseChecklist = yield call(getChecklistService, selectedEvent);

      if (
        responseChecklist.status === 200 &&
        responseChecklist.data.code === 33005
      ) {
        yield put(clearChecklist());
        yield put(setChecklistSuccess(responseChecklist.data.output));
      }
    } catch (error) {
      callbackError(error.responseChecklist.data.code);
    }
  }
}

export default all([
  takeLatest("GET_CHECKLIST", getChecklist),
  takeLatest("SOLVE_CHECKLIST_STEP", solveChecklistStep),
]);
