import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  border: 1px solid black;
  width: 550px;
  min-height: 730px;
  margin: 30px 0 25px 24px;
`

export const ContainerPage2 = styled.div`
  margin-top: 150px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  border: 1px solid black;
  width: 550px;
  min-height: 590px;
  margin: 70px 0 0 24px;
`

export const Header = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
`

export const LogoContainer = styled.div`
  width: 30%;
  padding: 32.5px 0 25px 0;
  margin-left: 10px;
  img {
    width: 180px;
  }
`
export const InformaMarketDescriptionContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: left;
  padding: 22.5px 0;
  width: 50%;
`
export const InformaMarketDescriptionTitle = styled.h1`
  width: 100%;
  font-size: 12px;
  font-weight: bold;
`
export const InformaMarketDescriptionContent = styled.p`
  width: 100%;
  font-size: 10px;
`
export const InvoiceContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`

export const HeaderTitle = styled.h1`
  color: white;
  font-size: 11px;
  font-weight: bold;
  text-align: center;
  padding-bottom: 3px;
`

export const ContainerHeader = styled.div`
  width: 100%;
  background-color: rgb(0,34,68);
  align-items: center;
  border: 0.5px solid black;
`

export const DividedHeader = styled.div`
  width: 100%;
  background-color: rgb(0,34,68);
  align-items: center;
  border: 0.5px solid black;
  display: grid;
  grid-template-columns: 70% 30%;

  div:first-child{
    border-right: 1px solid black;
  }
`

export const FirstContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 25px 0;
`

export const InfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
`

export const InfoLabelContent = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin: 2.5px 0;
  width: 325px;
`
export const InfoLabel = styled.div`
  width: 27%;
  background-color: rgb(0,34,68);
  border: 0.25px solid black;
  margin-left: 3px;
`
export const InfoContent = styled.div`
  width: 73%;
`

export const Label = styled.h1`
  color: white;
  font-size: 9.5px;
  font-weight: bold;
  text-align: left;
  padding: 0 0 3px 1px;
`
export const Content = styled.p`
  font-size: 9px;
  text-align: left;
  padding-left: 5px;
  padding-bottom: 2px;
`

export const Product = styled.p`
  font-size: 10px;
  text-align: left;
  padding: 5px 0 5px 5px;
`

export const Observation = styled.p`
  font-size: 10px;
  text-align: left;
  font-weight: bold;
  padding: 7.5px 0 5px 5px;
`

export const DividedBody = styled.div`
  width: 100%;
  align-items: center;
  display: grid;
  grid-template-columns: 70% 30%;

  border-bottom: 0.01px solid black;

  div:first-child{
    border-right: 1px solid black;
    min-height: 12px;
  }
  div{
    display: flex;
    flex-direction: row;
  }
`

export const DividedBodyObservation = styled.div`
  width: 90%;
  align-items: center;
  height: 100%;
  display: grid;
  grid-template-columns: 70% 30%;

  div{
    display: flex;
    flex-direction: row;
  }
`

export const Divider = styled.div`
  border-bottom: 0.5px solid black;
  height: 0.5px;
`;

export const ProductsContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 548px;
  min-height: 550px;

  .firstCell {
    padding-top: 15px;
  }

  .taxCell {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    text-align: right;
    height: 100%;
    p {
      padding-right: 3px;
      font-size: 7px;
    }
  }

  .lastCell {
    height: 100%;
    padding-top: 15px;
  }
`

export const TotalAmountContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  border-top: 1px solid black;
`

export const FirstPageContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
`

export const ResultLabelContent = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin: 2.5px 0;
  min-width: 215px;
`

export const ResultLabel = styled.div`
  width: 40%;
  background-color: rgb(0,34,68);
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  border: 0.25px solid black;
  margin-left: 3px;
`

export const PageLabel = styled.div`
  width: 15%;
  margin-right: 3px;
  h1{
    color: black;
    text-align: right;
  }
`

export const ResultContent = styled.div`
  width: 60%;
`