import React from "react";
import ReactExport from "react-data-export";
import { useIntl } from "react-intl";

const File = ReactExport.ExcelFile;
const Sheet = ReactExport.ExcelFile.ExcelSheet;
const Column = ReactExport.ExcelFile.ExcelColumn;

const Report = ({ data, filename, children }) => {
  const intl = useIntl();

  return (
  <File element={children} filename={filename} fileExtension="xlsx">
    <Sheet data={data} name="Relatório">
      <Column label="categoria" value="category" />
      <Column label="titulo"
        value={(col)=> (intl.locale === 'pt' ?
        col.title : col.englishTitle)}
      />
      <Column label="produto_dependente" 
        value={(col)=> (intl.locale === 'pt' ?
        col.dependentProductTitle : col.dependentProductEnglishTitle)} />
      <Column label="codigo" value="code" />
    </Sheet>
  </File>
)};

export default Report;
