import React, { 
  useEffect, 
  useState, 
  useMemo,
  useRef, 
  useCallback,
} from "react";

import { useSelector } from "react-redux";

import api from "../../service";
import { useIntl } from "react-intl";
import { Form } from "@unform/web";
import { useHistory } from "react-router-dom";
import { useToast } from "../../hooks/toast";

import { useLoading } from "../../hooks/loading";
import AnimatedMainContainer from "../../components/AnimatedMainContainer";
import Modal from "../../components/Modal";

import { ImCheckboxUnchecked} from "react-icons/im"
import { validateSingleFieldOnBlur } from "../../utils/formValidations";
import * as Yup from "yup";
import { convertToDateOnly } from "../../utils/date"

import ProgressBar from './ProgressBar'

import {
  TitleContainer,
  Title,
  ChecklistContainer,
  ChecklistStepContainer,
  CheckContainer,
  InfoContainer,
  StepDescription,
  StepAdditionalInfo,
  StepHowToDo,
  CheckButton,
  StyledSelectWithLabel,
  ErrorMessage,
  ErrorContainer,
  EventContainer,
  ScreenContainer,
  ModalStepContainer,
  ModalInfoContainer,
  CheckIcon,
  CheckIconModal,
  TitleAndChecklistContainer,
  CloseButtonContainer,
  CloseButton,
  CheckDescription,
  StepExpiresDate
} from "./styles.js"

const ChecklistView= () => {
  const [checklistSteps, setChecklistSteps] = useState([])
  const [selectedStep, setSelectedStep] = useState()
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [events, setEvents] = useState([])
  const [selectedEvent, setSelectedEvent] = useState()
  const [totalSteps, setTotalSteps] = useState(0)
  const [completedSteps, setCompletedSteps] = useState(0)

  const {
    user,
    token,
    serviceProviderLoggingAsAnExhibitor,
    adminToken,
  } = useSelector((state) => state.auth);
  const { addToast } = useToast();
  const formRef = useRef(null);
  const { handleLoading } = useLoading();
  const intl = useIntl();
  const history = useHistory();

  const isServiceProvider = useCallback(
    () =>
      !adminToken &&
      (serviceProviderLoggingAsAnExhibitor ||
        user?.role?.type === "SERVICE_PROVIDER"),
    [adminToken, serviceProviderLoggingAsAnExhibitor, user?.role?.type]
  );
  const isExhibitor = useCallback(
    () => !adminToken && user?.role?.type === "EXHIBITOR",
    [adminToken, user?.role?.type]
  );

  const isPortuguese = useMemo(()=> intl.locale === 'pt', [intl])

  const schema = Yup.object().shape({
    eventId: Yup.string()
  })

  useEffect(()=>{
    if(!isModalOpen) setSelectedStep(null)
  },[isModalOpen])

  useEffect(()=>{
    if(!selectedStep) return
    setIsModalOpen(true)
  },[selectedStep])

  useEffect(()=>{
    if(checklistSteps.length === 0) return

    setTotalSteps(checklistSteps.length)

    const mappedCompletedSteps = checklistSteps.filter((step) => step.solved)

    setCompletedSteps(mappedCompletedSteps.length)
  },[checklistSteps, setTotalSteps, setCompletedSteps])

  useEffect(()=>{
    handleLoading(true);
    api.get(
      `/event-user-checklist/events/by-user `,
      {
        headers: {
          "Content-Type": "application/json",
          Accept: "*/*",
          Authorization: `Bearer ${token}`,
        },
      }
    )
    .then((response) => {
      setEvents(response.data.eventOutput)
    })
    .catch((err) => {
      if(!isServiceProvider || isExhibitor) {
        history.push('/dashboard')
        return
      }
      addToast({
        type: "error",
        title: intl.formatMessage({
          id: "error",
        }),
        description: intl.formatMessage({
          id: "api.error." + err.response.data.code,
        }),
      })
    })
    .finally(() => handleLoading(false));
  },[token, addToast, handleLoading, intl, history])

  useEffect(()=>{
    if(!events) return
    getDefaultChecklist()
  },[token, addToast, handleLoading, intl, history, events])

  const CheckStep = (indexStep) =>{
    api
    .put(
      "/event-user-checklist/",
      {
        eventChecklistId: checklistSteps[indexStep].eventChecklistId,
        userChecklistId: checklistSteps[indexStep].userChecklistId, 
        solved: !checklistSteps[indexStep].solved
      },
      {
        headers: {
          "Content-Type": "application/json",
          Accept: "*/*",
          Authorization: `Bearer ${token}`,
        },
      }
    )
    .then((result) => {
      const newSteps = checklistSteps.map((step, index)=>{
        if(indexStep===index)
          return (
            {...step,
              solved: !step.solved
            }
          )
        return (
          {...step}
        )
      })
      setChecklistSteps(newSteps)
      setIsModalOpen(false)
    })
    .catch((err) =>
      addToast({
        type: "error",
        title: intl.formatMessage({
          id: "error",
        }),
        description: intl.formatMessage({
          id: "api.error." + err.response.data.code,
        }),
      })
    )
  }

  const getPercentage = () =>{
    return `${Math.round(completedSteps * 100 / totalSteps)}%`
  }

  const validationBlur = (name) => {
    let errors = formRef.current.getErrors();
    validateSingleFieldOnBlur(errors, schema, name, formRef);
  };

  const getDefaultChecklist = () =>{
    handleLoading(true);
    api
      .patch(
        `/event-user-checklist`,
        {},
        {
          headers: {
            "Content-Type": "application/json",
            Accept: "*/*",
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((response) => {  
        if(!response.data.output) return
        const formattedSteps = response.data.output.map((step, index)=>({
          ...step,
          index
        }))
        setChecklistSteps(formattedSteps)
        if(response.data.output.length > 0){
          const defaultEvent = events.filter(
            event=> event.value == response.data.output[0].eventIdNumber)
          formRef.current.setFieldValue("eventId", defaultEvent);
        }
      })
      .catch((err) => {
        if(!isServiceProvider || !isExhibitor) {
          history.push('/dashboard')
          return
        }
        addToast({
          type: "error",
          title: intl.formatMessage({
            id: "error",
          }),
          description: intl.formatMessage({
            id: "api.error." + err.response.data.code,
          }),
        })
      })
      .finally(() => handleLoading(false));
  }

  const handleSubmmit = () =>{
    handleLoading(true);
    api
      .patch(
        `/event-user-checklist`,
        {
          eventId: selectedEvent
        },
        {
          headers: {
            "Content-Type": "application/json",
            Accept: "*/*",
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((response) => {
        const formattedSteps = response.data.output.map((step, index)=>({
          ...step,
          index
        }))
        setChecklistSteps(formattedSteps)
      })
      .catch((err) => {
        addToast({
          type: "error",
          title: intl.formatMessage({
            id: "error",
          }),
          description: intl.formatMessage({
            id: "api.error." + err.response.data.code,
          }),
        })
        history.push('/dashboard')
      })
      .finally(() => handleLoading(false));
  }

  useEffect(()=>{
    if(!selectedEvent) return
    handleSubmmit()
  },[selectedEvent])

  const LinkedText = () =>{
    switch (true){
      case (isPortuguese && Boolean(selectedStep.linkPt)) :
        return (
          <StepHowToDo href={selectedStep.linkPt} target="_blank">
            {selectedStep.howToDoPt} ↗️
          </StepHowToDo>
        )
      case (isPortuguese && !selectedStep.linkPt) : 
        return (
          <StepHowToDo>
            {selectedStep.howToDoPt}
          </StepHowToDo>
        )
      case (!isPortuguese && Boolean(selectedStep.linkEn)) :
        return (
          <StepHowToDo href={selectedStep.linkEn} target="_blank">
            {selectedStep.howToDoEn} ↗️
          </StepHowToDo>
        )
      case (!isPortuguese && !selectedStep.linkEn) : 
        return (
          <StepHowToDo>
            {selectedStep.howToDoEn}
          </StepHowToDo>
        )
      default: return <></>
    }
  }

  return (
    <AnimatedMainContainer>
      <ScreenContainer>
        <Form ref={formRef} onSubmit={handleSubmmit}>
          <EventContainer>
            <TitleContainer>
              <Title>{intl.formatMessage({ id: "event" })}</Title>
            </TitleContainer>
            <StyledSelectWithLabel
              name="eventId"
              validationBlur={validationBlur}
              placeholder={intl.formatMessage({ id: "event_edition" })}
              onChange={(option) => setSelectedEvent(option.value)}
              labelOrientation="vertical"
              options={events}
            />
          </EventContainer>
        </Form>
        {checklistSteps.length > 0 && ( 
          <TitleAndChecklistContainer>
            <TitleContainer>
              <Title>
                {intl.formatMessage(
                  { id: "checklist_step_count" }, 
                  {completedSteps: completedSteps, totalSteps: totalSteps}
                )}
              </Title>
              <ProgressBar completed={getPercentage()}></ProgressBar>
            </TitleContainer>
            <ChecklistContainer>
              {
                checklistSteps.map((step, index)=>(
                  <ChecklistStepContainer 
                    onClick={()=>{setSelectedStep(step)}}
                    >
                      <InfoContainer>
                          <StepDescription>
                            {isPortuguese ? step.descriptionPt : step.descriptionEn}
                          </StepDescription>
                        </InfoContainer>
                      {step.solved ? 
                        <CheckIcon 
                          color={'white'}
                          size={70}
                        /> : <></>
                      }
                  </ChecklistStepContainer>
                ))
              }
            </ChecklistContainer>
          </TitleAndChecklistContainer>
        )}
        {!events.length > 0 && (
          <ErrorContainer>
            <ErrorMessage>{intl.formatMessage({ id: "checklist.no_active_events" })}</ErrorMessage>
          </ErrorContainer>
        )}
        {selectedEvent && checklistSteps.length === 0 && (
          <ErrorContainer>
            <ErrorMessage>{intl.formatMessage({ id: "checklist.no_checklist_for_event" })}</ErrorMessage>
          </ErrorContainer>
        )}
      </ScreenContainer>
      <Modal
        setIsOpen={setIsModalOpen}
        modalIsOpen={isModalOpen}
        modalWidth='1250'
        backgroundImage="linear-gradient(to right, rgb(0,34,68), rgb(33,136,96), rgb(44,202,91))"
        border= "solid 0.25px white"
        borderRadius= "10px"
      >
        {selectedStep && 
        <ModalStepContainer>
          <ModalInfoContainer>
            <CloseButtonContainer>
              <StepDescription>
                {intl.formatMessage({ id: "checklist.step" })} {isPortuguese ? selectedStep.descriptionPt : selectedStep.descriptionEn}
              </StepDescription>
              <CloseButton 
                size={50}
                color={'white'}
                onClick={()=>{setIsModalOpen(false)}}
              />
            </CloseButtonContainer>
            <StepAdditionalInfo>
              {isPortuguese ? selectedStep.additionalInfoPt : selectedStep.additionalInfoEn}
            </StepAdditionalInfo>
            <LinkedText />
             <StepExpiresDate>
              {intl.formatMessage({ id: "checklist.expires" })}: {convertToDateOnly(selectedStep.expiresIn)}
            </StepExpiresDate>
          </ModalInfoContainer>
          <CheckContainer>
            <CheckButton 
              onClick={()=>{CheckStep(selectedStep.index)}}
              className={(selectedStep.solved && 'checked')}
            >
              {selectedStep.solved ? 
                <>
                  <CheckDescription>{intl.formatMessage({ id: "checklist.solved_step" })}</CheckDescription>
                  <CheckIconModal
                    color={'white'}
                    size={60}
                  />
                </> :
                <>
                  <CheckDescription>{intl.formatMessage({ id: "checklist.solved_step?" })}</CheckDescription>
                  <ImCheckboxUnchecked 
                    size={60}
                    color={'white'}
                  /> 
                </>
              }
            </CheckButton>
          </CheckContainer>
        </ModalStepContainer>
        }
      </Modal>
    </AnimatedMainContainer >
  )
}

export default ChecklistView
