import React, { useEffect, useMemo, useRef, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useSelector } from "react-redux";
import { useHistory } from "react-router";
import AnimatedMainContainer from "../../components/AnimatedMainContainer";
import { useToast } from "../../hooks/toast";
import * as Yup from "yup";
import getValidationErrors from "../../utils/getValidationErrors";
import api from "../../service";
import { useCallback } from "react";
import InputWithLabel from "../../components/InputWithLabel";
import MaskedInputWithLabel from "../../components/MaskedInputWithLabel";
import FormButton from "../../components/FormButton";
import { Form } from "@unform/web";
import { validateSingleFieldOnBlur } from "../../utils/formValidations";
import { format } from "date-fns";

import {
  Container,
  FormContainer,
  TitleContainer,
  Title,
  FieldsContainer,
  Horizontal,
  FilterActionsContainer,
  SubmitContainer,
  LinkButton,
  GoBackContainer,
  ActionContainer,
  BadgeContainer,
} from "./styles";
import Table from "../../components/Table";
import Badge from "../../components/Badge";
import { useLoading } from "../../hooks/loading";
import useGenericDocumentMask from "../../utils/useGenericDocumentMask";

const ServiceProviders = () => {
  const { token } = useSelector((state) => state.auth);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const formRef = useRef(null);
  const intl = useIntl();
  const history = useHistory();
  const { addToast } = useToast();
  const [documentMask, setDocumentMask] = useGenericDocumentMask();

  const [totalRows, setTotalRows] = useState(0);
  const [perPage, setPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [submittedForm, setSubmittedForm] = useState({
    name: null,
    document: null,
  });

  const schema = Yup.object().shape({
    document: Yup.string().nullable(),
    commercial_name: Yup.string().nullable(),
  });

  const fetchData = useCallback(
    async (
      page,
      size = perPage,
      requestBody = submittedForm,
      resetCurrentPage
    ) => {
      setLoading(true);
      if (loading) return;

      if (resetCurrentPage) setCurrentPage(1);

      api
        .patch(`/serviceprovider?page=${page}&per_page=${size}`, requestBody, {
          headers: {
            "Content-Type": "application/json",
            Accept: "*/*",
            Authorization: `Bearer ${token}`,
          },
        })
        .then((result) => {
          setTotalRows(result.data.paginationInfo.totalElements);
          setData(result.data.serviceProviders);
        })
        .catch((err) =>
          addToast({
            type: "error",
            title: intl.formatMessage({
              id: "error",
            }),
            description: intl.formatMessage({
              id: "api.error." + err.response.data.code,
            }),
          })
        )
        .then(() => setLoading(false));
    },
    [addToast, intl, loading, perPage, submittedForm, token]
  );

  useEffect(() => fetchData(0, 10), []);

  const handleSubmit = useCallback(
    async (data) => {
      try {
        formRef.current?.setErrors({});

        setLoading(true);
        if (loading) {
          return;
        }

        await schema.validate(data, { abortEarly: false });

        const formData = {
          name: data.commercial_name,
          document: data.document,
        };

        setSubmittedForm(formData);
        fetchData(0, perPage, formData, true);
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);

          formRef.current?.setErrors(errors);

          return;
        }
      }
    },
    [loading, schema, fetchData, perPage]
  );

  const clearFields = () => {
    formRef.current.reset();
    formRef.current.setFieldValue("event", {
      label: intl.formatMessage({ id: "all" }),
      value: "ALL",
    });
    formRef.current.setFieldValue("document", "");
    formRef.current.setFieldValue("commercial_name", "");
    setData([]);
  };

  const handlePageChange = (page) => {
    fetchData(page - 1);
    setCurrentPage(page);
  };

  const handlePerRowsChange = async (newPerPage, page) => {
    fetchData(page - 1, newPerPage);
    setPerPage(newPerPage);
  };

  const validationBlur = (name) => {
    let errors = formRef.current.getErrors();

    validateSingleFieldOnBlur(errors, schema, name, formRef);
  };

  const columns = useMemo(
    () => [
      {
        name: intl.formatMessage({ id: "register_date" }),
        selector: (row) => row.createdAt,
        format: (row) => {
          return format(new Date(row.createdAt), "dd/MM/yyyy");
        },
      },
      {
        name: intl.formatMessage({ id: "corporate_name" }),
        selector: (row) => row.companyName,
      },
      {
        name: intl.formatMessage({ id: "document_filter" }),
        selector: (row) => row.document,
      },
      {
        cell: (row) => (
          <LinkButton to={`/admin/service-provider-edit/${row.systemUserId}`}>
            <Badge color="#002244" text={intl.formatMessage({ id: "edit" })} />
          </LinkButton>
        ),
        right: true,
      },
    ],
    [intl]
  );

  return (
    <AnimatedMainContainer
      breadcrumb={intl.formatMessage({ id: "sidebar.manage_providers" })}
    >
      <Container>
        <FormContainer>
          <Form ref={formRef} onSubmit={handleSubmit}>
            <TitleContainer>
              <Title>
                <FormattedMessage id="search_for_service_providers" />
              </Title>
              <GoBackContainer>
                <ActionContainer>
                  <BadgeContainer onClick={() => history.push("/dashboard")}>
                    <Badge
                      color="#C9C9C9"
                      text={intl.formatMessage({ id: "goback" })}
                      fontSize="12px"
                    />
                  </BadgeContainer>
                </ActionContainer>
              </GoBackContainer>
            </TitleContainer>
            <FieldsContainer>
              <Horizontal>
                <MaskedInputWithLabel
                  name="document"
                  maskChar={null}
                  mask={documentMask}
                  onChange={setDocumentMask}
                  validationBlur={validationBlur}
                  label={intl.formatMessage({ id: "document_cnpj_or_passport" })}
                />
                <InputWithLabel
                  name="commercial_name"
                  validationBlur={validationBlur}
                  label={intl.formatMessage({ id: "name_corporate_name" })}
                />
              </Horizontal>
              <Horizontal>
                <FilterActionsContainer>
                  <SubmitContainer>
                    <FormButton
                      color="#C9C9C9"
                      type="button"
                      onClick={clearFields}
                    >
                      <FormattedMessage id="clear_fields" />
                    </FormButton>
                  </SubmitContainer>
                  <SubmitContainer>
                    <FormButton type="submit" loading={loading}>
                      <FormattedMessage id="search" />
                    </FormButton>
                  </SubmitContainer>
                </FilterActionsContainer>
              </Horizontal>
            </FieldsContainer>
          </Form>
        </FormContainer>
        <Table
          data={data}
          columns={columns}
          progressPending={loading}
          paginationServer
          paginationTotalRows={totalRows}
          paginationDefaultPage={currentPage}
          onChangeRowsPerPage={handlePerRowsChange}
          onChangePage={handlePageChange}
        />
      </Container>
    </AnimatedMainContainer>
  );
};

export default ServiceProviders;
