import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useHistory, useParams } from "react-router-dom";

import { Form } from "@unform/web";
import * as Yup from "yup";
import getValidationErrors from "../../../utils/getValidationErrors";

import { useSelector } from "react-redux";
import format from "date-fns/format";

import { FormattedMessage, useIntl } from "react-intl";

import {
  Container,
  TitleContainer,
  Title,
  Session,
  FieldsContainer,
  ActionContainer,
  BadgeContainer,
  Horizontal,
  TableHeader,
} from "./styles";

import { useToast } from "../../../hooks/toast";
import { validateSingleFieldOnBlur } from "../../../utils/formValidations";

import api from "../../../service";

import AnimatedMainContainer from "../../../components/AnimatedMainContainer";
import InputWithHorizontalLabel from "../../../components/InputWithHorizontalLabel";
import SelectWithLabel from "../../../components/SelectWithLabel";
import Badge from "../../../components/Badge";
import { fillTimePickerBetween, getIntervalHours } from "../../../utils/fillTimepicker";
import Table from "../../../components/Table";
import { inputTimeInsideDate, listDatesBetween } from "../../../utils/date";
import { useLoading } from "../../../hooks/loading";
import
  TelephoneInputWithLabel,
  {
    getFullTelephoneNumber,
    getCountryFromTelephoneNumber,
  }
from "../../../components/TelephoneInputWithLabel";

import { parse } from "date-fns";
import axios from "axios";

const ScheduleWithdrawal = () => {
  const formRef = useRef(null);
  const intl = useIntl();
  const { id } = useParams();
  const [data, setData] = useState({});
  const [key, setKey] = useState();
  const { addToast } = useToast();
  const { token } = useSelector((state) => state.auth);
  const [eventId, setEventId] = useState();
  const [contractId, setContractId] = useState();
  const [contracts, setContracts] = useState();

  const [enableFields, setEnableFields] = useState(false);

  const [timePickerOptions, setTimePickerOptions] = useState();
  const [firstDayExcludedHours, setFirstDayExcludedHours] = useState([])
  const [filteredTimePickerOptions, setFilteredTimePickerOptions] = useState();
  const [dateOptions, setDateOptions] = useState();
  const [firstDay, setFirstDay] = useState()
  const [credentials, setCredentials] = useState();
  const { handleLoading } = useLoading();
  const [loading, setLoading] = useState(false);

  const [schedules, setSchedules] = useState([]);

  const history = useHistory();

  const schema = Yup.object().shape({
    date: Yup.string().required(
      intl.formatMessage({ id: "error.field_required" })
    ),
    time: Yup.string().required(
      intl.formatMessage({ id: "error.field_required" })
    ),
    name_of_who_withdraws: Yup.string().required(
      intl.formatMessage({ id: "error.field_required" })
    ),
    contact: Yup.string()
      .max(30, intl.formatMessage({ id: "error.max_length" }, { length: 30 }))
      .required(intl.formatMessage({ id: "error.field_required" })),
  });

  const handleSubmit = useCallback(
    async (data) => {
      try {
        formRef.current?.setErrors({});

        setLoading(true);
        if (loading) {
          return;
        }

        await schema.validate(data, { abortEarly: false });

        api
          .put(
            `/credential-schedule/${id}`,
            {
              eventId: eventId,
              date: inputTimeInsideDate({ time: data.time, date: data.date }),
              nameOfWhoWithdraws: data.name_of_who_withdraws,
              contact: getFullTelephoneNumber(
                data.contact, data.contact_selected_country),
              contractId: data.contract,
            },
            {
              headers: {
                "Content-Type": "application/json",
                Accept: "*/*",
                Authorization: `Bearer ${token}`,
              },
            }
          )
          .then(() => {
            addToast({
              type: "success",
              title: intl.formatMessage({
                id: "success",
              }),
              description: intl.formatMessage({
                id: "success.schedule_creation",
              }),
            });
            history.push("/credential/schedule-withdrawal");
          })
          .catch((err) =>
            addToast({
              type: "error",
              title: intl.formatMessage({
                id: "error",
              }),
              description: intl.formatMessage({
                id: "api.error." + err.response.data.code,
              }),
            })
          )
          .finally(() => setLoading(false));
      } catch (err) {
        setLoading(false);
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);

          formRef.current?.setErrors(errors);

          return;
        }
      }
    },
    [loading, schema, id, eventId, token, addToast, intl, history]
  );

  useEffect(() => {
    if (eventId && contractId) {
      handleLoading(true);
      api
        .patch(
          `/credential-schedule`,
          {
            eventId: eventId,
            contractId: contractId,
          },
          {
            headers: {
              "Content-Type": "application/json",
              Accept: "*/*",
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((result) => {
          const {
            credentialOutputList,
            credentialScheduleOutput,
          } = result.data;
          setEnableFields(true);

          const timeOptions = fillTimePickerBetween(
            credentialScheduleOutput.answeringTimeInterval,
            credentialScheduleOutput.startTimeToScheduleCredentials,
            credentialScheduleOutput.endTimeToScheduleCredentials
          );
          setTimePickerOptions(timeOptions);
          
          const excludedHours =  [...getIntervalHours(
            credentialScheduleOutput.answeringTimeInterval,
            credentialScheduleOutput.startTimeToScheduleCredentials,
            credentialScheduleOutput.firstDayStartTimeToScheduleCredentials
          ), ...getIntervalHours(
            credentialScheduleOutput.answeringTimeInterval,
            credentialScheduleOutput.firstDayEndTimeToScheduleCredentials,
            credentialScheduleOutput.endTimeToScheduleCredentials
          )]

          const firstDayOfEvent = credentialScheduleOutput.startDateForSchedulingCredentials

          setFirstDay(firstDayOfEvent)

          setFirstDayExcludedHours(excludedHours)

          const formattedDate = data.date.value.toISOString().split("T")[0];

          const hours = schedules.reduce((hours, schedule) => {
            if (schedule.date === formattedDate) {
              hours.push(
                schedule.hour.substring(0, schedule.hour.lastIndexOf(":"))
              );
            }
            return hours;
          }, []);
          
          const firstDayHours = formattedDate === firstDayOfEvent ? excludedHours : []

          const formattedHours = [...hours, ...firstDayHours]
          setFilteredTimePickerOptions(
            timeOptions.filter((option) => !formattedHours.includes(option.value))
          );

          setDateOptions(
            listDatesBetween(
              new Date(
                credentialScheduleOutput.startDateForSchedulingCredentials
              ),
              new Date(
                credentialScheduleOutput.endDateForSchedulingCredentials
              ),
              true
            )
          );
          setCredentials(credentialOutputList);
        })
        .catch((err) => {
          setEnableFields(false);
          formRef.current.reset();
          addToast({
            type: "error",
            title: intl.formatMessage({
              id: "error",
            }),
            description: intl.formatMessage({
              id: "api.error." + err.response.data.code,
            }),
          });
        })
        .finally(() => handleLoading(false));
    }
  }, [
    eventId,
    addToast,
    intl,
    token,
    handleLoading,
    contractId,
    schedules,
    data,
  ]);

  useEffect(() => {
    handleLoading(true);

    const config = {
      headers: {
        "Content-Type": "application/json",
        Accept: "*/*",
        Authorization: `Bearer ${token}`,
      },
    };

    axios
      .all([
        api.get("admin/manage-credential-schedule", config),
        api.get(`credential-schedule/${id}`, config),
      ])
      .then(
        axios.spread((manageCredentialResult, scheduleResult) => {
          setSchedules(
            manageCredentialResult.data.credentialScheduleReportResponse.filter(
              (schedule) => schedule.scheduleId.toString() !== id
            )
          );

          const {
            contact,
            date,
            eventId,
            eventEditionName,
            nameOfWhoWithdraws,
          } = scheduleResult.data.credentialScheduleOutput;
          const dateInstance = new Date(date);
          setEventId(eventId);

          let dateValue, timeValue;
          const formattedDate = format(dateInstance, "dd/MM/yyyy");
          const formattedTime = format(dateInstance, "HH:mm");
          dateValue = { value: dateInstance, label: formattedDate };
          timeValue = { value: formattedTime, label: formattedTime };

          setData({
            event: eventEditionName,
            date: dateValue,
            time: timeValue,
            name_of_who_withdraws: nameOfWhoWithdraws,
            contact: contact,
            contact_selected_country:
              getCountryFromTelephoneNumber(contact),
          });
        })
      )
      .catch((err) =>
        addToast({
          type: "error",
          title: intl.formatMessage({
            id: "error",
          }),
          description: intl.formatMessage({
            id: "api.error." + err.response.data.code,
          }),
        })
      )
      .finally(() => {
        setKey(new Date());
        handleLoading(false);
      });
  }, [addToast, intl, token, handleLoading, id]);

  useEffect(() => {
    if (eventId) {
      handleLoading(true);
      api
        .get(`/exhibitor/opportunity/event/${eventId}`, {
          headers: {
            "Content-Type": "application/json",
            Accept: "*/*",
            Authorization: `Bearer ${token}`,
          },
        })
        .then((result) => setContracts(result.data.output))
        .catch((err) =>
          addToast({
            type: "error",
            title: intl.formatMessage({
              id: "error",
            }),
            description: intl.formatMessage({
              id: "api.error." + err.response.data.code,
            }),
          })
        )
        .finally(() => handleLoading(false));
    }
  }, [eventId, addToast, intl, token, handleLoading]);

  const handleDateChange = (e) => {
    formRef.current?.setFieldValue("time");
    const date = e.value.toISOString().split("T")[0];
    const hours = schedules.reduce((hours, schedule) => {
      if (schedule.date === date) {
        hours.push(schedule.hour.substring(0, schedule.hour.lastIndexOf(":")));
      }
      return hours;
    }, []);

    const firstDayHours = date === firstDay ? firstDayExcludedHours : []

    const formattedHours = [...hours, ...firstDayHours]

    setFilteredTimePickerOptions(
      timePickerOptions.filter((option) => !formattedHours.includes(option.value))
    );
  };

  const validationBlur = (name) => {
    let errors = formRef.current.getErrors();
    validateSingleFieldOnBlur(errors, schema, name, formRef);
  };

  const columns = useMemo(
    () => [
      {
        name: intl.formatMessage({ id: "name" }),
        selector: "collaboratorName",
      },
      {
        name: intl.formatMessage({ id: "assignment" }),
        selector: "jobTitle",
      },
    ],
    [intl]
  );

  const columnsSchedulesTable = useMemo(
    () => [
      {
        name: intl.formatMessage({ id: "event" }),
        selector: (row) => row.eventName,
        sortable: true,
        allowOverflow: true,
        wrap: true,
      },
      {
        name: intl.formatMessage({ id: "created_at" }),
        sortable: true,
        cell: (row) =>
          intl.formatDate(parse(row.createdDate, "yyyy-MM-dd", new Date())),
      },
      {
        name: intl.formatMessage({ id: "withdraw_date" }),
        sortable: true,
        cell: (row) =>
          intl.formatDate(parse(row.date, "yyyy-MM-dd", new Date())),
      },
      {
        name: intl.formatMessage({ id: "schedule_time" }),
        selector: (row) => row.hour,
        sortable: true,
      },
      {
        name: intl.formatMessage({ id: "name_of_who_withdraws" }),
        selector: (row) => row.whoWithdrawsName,
        sortable: true,
        allowOverflow: true,
        wrap: true,
      },
      {
        name: intl.formatMessage({ id: "is_active" }),
        cell: (row) =>
          row.scheduleActive
            ? intl.formatMessage({ id: "yes" })
            : intl.formatMessage({ id: "no" }),
      },
    ],
    [intl]
  );

  return (
    <AnimatedMainContainer>
      <Container>
        <Form
          ref={formRef}
          key={key}
          onSubmit={handleSubmit}
          initialData={data}
        >
          <TitleContainer>
            <Title>
              <FormattedMessage id="reschedule_withdrawal" />
            </Title>
            <ActionContainer>
              <BadgeContainer
                onClick={() => history.push("/credential/schedule-withdrawal")}
              >
                <Badge
                  color="#C9C9C9"
                  text={intl.formatMessage({ id: "goback" })}
                  fontSize="12px"
                />
              </BadgeContainer>
              <BadgeContainer onClick={() => formRef.current.submitForm()}>
                <Badge
                  color="#002244"
                  text={intl.formatMessage({ id: "confirm" })}
                  fontSize="12px"
                  loading={loading}
                />
              </BadgeContainer>
            </ActionContainer>
          </TitleContainer>
          <Session>
            <FieldsContainer>
              <Horizontal>
                <InputWithHorizontalLabel
                  name="event"
                  label={intl.formatMessage({ id: "event_edition" })}
                  placeholder={intl.formatMessage({ id: "event_edition" })}
                  labelWidth="150px"
                  disabled
                  required
                />
                <SelectWithLabel
                  name="contract"
                  label={intl.formatMessage({ id: "contract" })}
                  placeholder={intl.formatMessage({ id: "contract" })}
                  labelOrientation="horizontal"
                  labelWidth="150px"
                  options={contracts}
                  onChange={(e) => setContractId(e?.value)}
                  required
                  disabled={!eventId}
                />
              </Horizontal>

              <Horizontal>
                <SelectWithLabel
                  name="date"
                  placeholder={intl.formatMessage({
                    id: "date",
                  })}
                  label={intl.formatMessage({
                    id: "date",
                  })}
                  validationBlur={validationBlur}
                  labelOrientation="horizontal"
                  labelWidth="150px"
                  options={dateOptions}
                  onChange={handleDateChange}
                  disabled={!enableFields}
                  required
                />
                <SelectWithLabel
                  name="time"
                  placeholder={intl.formatMessage({
                    id: "schedule_time",
                  })}
                  clearable={true}
                  label={intl.formatMessage({
                    id: "schedule_time",
                  })}
                  options={filteredTimePickerOptions}
                  validationBlur={validationBlur}
                  labelOrientation="horizontal"
                  labelWidth="150px"
                  disabled={!enableFields}
                  required
                />
              </Horizontal>
              <Horizontal>
                <InputWithHorizontalLabel
                  name="name_of_who_withdraws"
                  placeholder={intl.formatMessage({
                    id: "name_of_who_withdraws",
                  })}
                  label={intl.formatMessage({
                    id: "name_of_who_withdraws",
                  })}
                  validationBlur={validationBlur}
                  labelWidth="150px"
                  disabled={!enableFields}
                  required
                />
                <TelephoneInputWithLabel
                  name="contact"
                  placeholder={intl.formatMessage({
                    id: "contact",
                  })}
                  label={intl.formatMessage({
                    id: "contact",
                  })}
                  validationBlur={validationBlur}
                  labelOrientation="horizontal"
                  labelWidth="150px"
                  disabled={!enableFields}
                  required
                />
              </Horizontal>
              {!enableFields ? (
                <div style={{ textAlign: "center" }}>
                  <FormattedMessage id="need_to_filter_first" />
                </div>
              ) : (
                <>
                  <TableHeader>
                    <div>
                      <FormattedMessage id="list_of_associated_credentials" />
                    </div>
                  </TableHeader>
                  <Table
                    data={credentials}
                    columns={columns}
                    hasPagination={false}
                  />
                </>
              )}
            </FieldsContainer>
          </Session>
        </Form>
      </Container>
      <Table
        columns={columnsSchedulesTable}
        data={schedules}
        title={intl.formatMessage({ id: "results" })}
        hasPagination
      />
    </AnimatedMainContainer>
  );
};

export default ScheduleWithdrawal;
