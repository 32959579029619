import styled, { css } from "styled-components";

export const Container = styled.div`
  display: flex;
  flex: 1 0;

  justify-content: center;
  flex-direction: row;

  flex-wrap: wrap;
`;

export const Item = styled.div`
  border-radius: 16px;
  padding: 16px;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  margin: 8px 8px;
  flex: 1 1 350px;
  flex-shrink: initial;
  max-width: 400px;
`;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  flex-wrap: wrap;

  @media (max-width: 880px) {
    justify-content: center;
  }

  flex: 1;
`;

export const ValueInformationContainer = styled.div`
  display: flex;
  flex-direction: column;

  flex-basis: 100px;

  justify-content: center;

  align-items: center;

  ${(props) =>
    props.informationValue > 0 &&
    css`
      background-color: #5ddb98;
    `}

  ${(props) =>
    props.informationValue < 0 &&
    css`
      background-color: #c53030;
    `}

    ${(props) =>
    props.informationValue === 0 &&
    css`
      background-color: #bcbcbc;
    `}

  min-width: 85px;

  max-width: 140px;

  height: 85px;

  margin: 16px 24px 0px 24px;

  border-radius: 16px;

  @media (max-width: 450px) {
    margin: 16px 0px;
  }
`;

export const TextContainer = styled.div``;

export const Title = styled.h1`
  color: #002244;
  text-align: center;
`;

export const Subtitle = styled.h5`
  margin-top: 16px;
  color: #002244;
  text-align: center;
`;

export const InfoContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;
