import api from "./index";

export const getAccountsServiceProviderService = async () =>
  await api.get("/provider-association/service-provider-accounts");
export const getAccountsExhibitorService = async () =>
  await api.get("/exhibitor/account/associated-accounts");

export const changeAccountsExhibitorService = async ({ accountIdNumber }) =>
  await api.post("/auth/change-exhibitor-sign-in", { accountIdNumber });
export const changeAccountsServiceProviderService = async ({
  accountIdNumber,
}) =>
  await api.post("/auth/change-service-provider-sign-in", { accountIdNumber });
