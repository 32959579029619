import { all, takeLatest, call, put } from "redux-saga/effects";

import { setSystemSuccess, setListCountriesSuccess } from "./actions";
import {
  getSystemService,
  getListCountriesService,
  getCepBrasilApiService,
} from "../../../service/system.service";

function* getSystem({ payload }) {
  try {
    const response = yield call(getSystemService);

    if (response.status === 200 && response.data.code === 4000) {
      yield put(setSystemSuccess(response.data.contactOutput));
    }
  } catch (error) {
    payload.callbackError(error.response.data.code);
  }
}

function* getListCountries({ payload }) {
  try {
    const response = yield call(getListCountriesService);

    if (response.status === 200) {
      const newList = response.data.map((item) => ({
        label: item.name,
        value: item.name,
      }));
      yield put(setListCountriesSuccess(newList));
    }
  } catch (error) {
    payload.callbackError(error.response.data.code);
  }
}

function* getCep({ payload }) {
  try {
    const response = yield call(getCepBrasilApiService, payload.cep);

    if (response.status === 200) {
      payload.callBackSuccess(response.data);
    }
  } catch (error) {
    payload.callbackError("-0");
  }
}

export default all([
  takeLatest("GET_SYSTEM", getSystem),
  takeLatest("GET_LIST_COUNTRIES", getListCountries),
  takeLatest("GET_CEP", getCep),
]);
