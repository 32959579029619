import { useIntl } from "react-intl";

const intl = useIntl

const opportunity_status_types = () => {
  const opportunity_status_array = [
    {
      label: intl().formatMessage({ id: "accounting_rejected" }),
      value: "Accounting Rejected"
    },
    {
      label: intl().formatMessage({ id: "awaiting_payment" }),
      value: "Awaiting Payment"
    },
    {
      label: intl().formatMessage({ id: "closed_lost" }),
      value: "Closed Lost"
    },
    {
      label: intl().formatMessage({ id: "do_not_bill_status" }),
      value: "Do Not Bill Status"
    },
    {
      label: intl().formatMessage({ id: "legacy_won" }),
      value: "Legacy Won"
    },
    {
      label: intl().formatMessage({ id: "opportunity_cancelled" }),
      value: "Opportunity Cancelled"
    },
    {
      label: intl().formatMessage({ id: "pending_accounting_approval" }),
      value: "Pending Accounting Approval"
    },
    {
      label: intl().formatMessage({ id: "pending_change" }),
      value: "Pending Change"
    },
  ];

  return opportunity_status_array;
}

export default opportunity_status_types;

