import React, { useEffect, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";

import { getSystem } from "../../../redux/modules/system/actions";

import { useToast } from "../../../hooks/toast";
import { useIntl } from "react-intl";

import Phone from "../../../newUXAssets/icons/Phone";
import WhatsappLogo from "../../../newUXAssets/images/WhastappLogo.png";

import { convertToYearOnly } from "../../../utils/date";

import { Container, PhoneLabel, InformaLabel, Logo } from "./styles";

const Footer = () => {
  const dispatch = useDispatch();
  const intl = useIntl();
  const { addToast } = useToast();

  const { whatsapp, telephone } = useSelector((state) => state.system);

  const callBackMessage = useCallback(
    (message) =>
      addToast({
        type: "error",
        title: intl.formatMessage({
          id: "error",
        }),
        description: intl.formatMessage({
          id: "api.error." + message,
        }),
      }),
    [addToast, intl]
  );

  useEffect(() => {
    if (!whatsapp && !telephone) {
      dispatch(getSystem(callBackMessage));
    }
  }, [whatsapp, telephone, dispatch, callBackMessage]);

  const formatWhatsapp = (link) => {
    const onlyNumbers = link.replace(/\D/g, "");
    const formattedNumber =
      onlyNumbers.length === 12
        ? `+${onlyNumbers.slice(0, 2)} (${onlyNumbers.slice(
            2,
            4
          )}) ${onlyNumbers.slice(4, 8)}-${onlyNumbers.slice(8)}`
        : `+${onlyNumbers.slice(0, 2)} (${onlyNumbers.slice(
            2,
            4
          )}) ${onlyNumbers.slice(4, 9)}-${onlyNumbers.slice(9)}`;

    return formattedNumber;
  };

  const currentDate = new Date();

  return (
    <Container>
      <div>
        <PhoneLabel>
          <Phone /> <p>{telephone}</p>
        </PhoneLabel>
        <PhoneLabel>
          <a href={whatsapp} rel="noreferrer" target="_blank">
            <Logo src={WhatsappLogo} alt="logo Whatsapp" />
            <p>{formatWhatsapp(whatsapp)}</p>
          </a>
        </PhoneLabel>
      </div>
      <div>
        <InformaLabel>
          Informa Markets © {convertToYearOnly(currentDate)}
        </InformaLabel>
      </div>
    </Container>
  );
};

export default Footer;
