import produce from "immer";

const INITIAL_STATE = {};

const guests = (state = INITIAL_STATE, action) => {
  return produce(state, (draft) => {
    switch (action.type) {
      default: {
        return draft;
      }
    }
  });
};

export default guests;
