import React from "react";

const FileArrowDown = (props) => {
  const { size = "26", color = "white" } = props;

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 26 26"
      fill="none"
    >
      <g clipPath="url(#clip0_435_6574)">
        <path
          d="M20.3125 22.75H5.6875C5.47201 22.75 5.26535 22.6644 5.11298 22.512C4.9606 22.3597 4.875 22.153 4.875 21.9375V4.0625C4.875 3.84701 4.9606 3.64035 5.11298 3.48798C5.26535 3.3356 5.47201 3.25 5.6875 3.25H15.4375L21.125 8.9375V21.9375C21.125 22.153 21.0394 22.3597 20.887 22.512C20.7347 22.6644 20.528 22.75 20.3125 22.75Z"
          stroke={color}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M15.4375 3.25V8.9375H21.125"
          stroke={color}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M13 12.1875V18.6875"
          stroke={color}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M10.5625 16.25L13 18.6875L15.4375 16.25"
          stroke={color}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_435_6574">
          <rect width="26" height="26" fill={color} />
        </clipPath>
      </defs>
    </svg>
  );
};

export default FileArrowDown;
