import React, { useRef, useEffect } from "react";
import ReactSelect, { components } from "react-select";
import { useField } from "@unform/core";

import { MdError } from "react-icons/md";
import { Error, InputContainer, Container } from "./styles";

const Control = ({ children, ...props }) => {
  const { Icon } = props.selectProps;

  return (
    <components.Control {...props}>
      {Icon && (
        <InputContainer>
          <Icon size={24} />
        </InputContainer>
      )}
      {children}
    </components.Control>
  );
};

const Select = ({ name, icon: Icon, validationBlur, disabled, ...rest }) => {
  const selectRef = useRef(null);
  const { fieldName, defaultValue, registerField, error } = useField(name);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: selectRef.current,
      getValue: (ref) => {
        if (rest.isMulti) {
          if (!ref.state.value) {
            return [];
          }
          return ref.state.value.map((option) => option.value);
        }
        if (!ref.state.value) {
          return "";
        }
        return ref.state.value.value;
      },
      setValue: (ref, value) => {
        ref.select.setValue(value);
      },
    });
  }, [fieldName, registerField, rest.isMulti]);

  const handleBlur = () => {
    if (validationBlur) {
      validationBlur(fieldName, selectRef.current?.value);
    }
  };

  return (
    <>
      <Container>
        <ReactSelect
          defaultValue={defaultValue}
          ref={selectRef}
          Icon={Icon}
          isDisabled={disabled}
          classNamePrefix="react-select"
          components={{ Control }}
          onBlur={handleBlur}
          menuPortalTarget={document.body}
          styles={{
            placeholder: (base) => ({
              ...base,
              fontSize: "14px",
              color: "#002244",
              fontWeight: 400,
              paddingLeft: Icon ? "0px" : "16px",
            }),
            singleValue: (base) => ({
              ...base,
              fontSize: "14px",
              color: "#002244",
              fontWeight: 400,
              paddingLeft: Icon ? "0px" : "16px",
            }),
            valueContainer: (base) => ({
              ...base,
              paddingTop: "8px",
              paddingBottom: "8px",
            }),
            menuList: (base) => ({
              ...base,
              zIndex: "9999999999999",
            }),
            menuPortal: (base) => ({
              ...base,
              zIndex: "9999999999999",
            }),
            control: (base) => ({
              ...base,
              borderColor: error ? "#e00000" : "#dadada",
              ":hover": {
                borderColor: error ? "#e00000" : "#dadada",
                ":hover": {
                  borderColor: error ? "#e00000" : "#dadada",
                },
              },
            }),
          }}
          {...rest}
        />
      </Container>
      {error && (
        <Error title={error}>
          <MdError color="#e00000" size={20} />
          {error}
        </Error>
      )}
    </>
  );
};
export default Select;
