import { all, takeLatest, call, put } from "redux-saga/effects";

import { setAllAssociateCompanySuccess } from "./actions";
import {
  getAllAssociateCompanyService,
  getUserDocumentService,
  createAssociateCompanyService,
  removeAssociationCompanyService,
  updateLoginPermissionService,
} from "../../../service/associateCompany.service";

function* getAllAssociateCompany({ payload }) {
  try {
    const response = yield call(getAllAssociateCompanyService, payload.params);

    if (response.status === 200) {
      yield put(setAllAssociateCompanySuccess(response.data.output));
      payload.callbackSuccess(response.data.paginationInfo);
    }
  } catch (error) {
    payload.callbackError(error.response.data.code);
  }
}

function* getUserDocument({ payload }) {
  try {
    const response = yield call(getUserDocumentService, payload.document);

    if (response.status === 200) {
      payload.callbackSuccess(response.data.output);
    }
  } catch (error) {
    payload.callbackError(error.response.data.code);
  }
}

function* createAssociateCompany({ payload }) {
  try {
    const response = yield call(createAssociateCompanyService, payload.data);

    if (response.status === 200) {
      payload.callbackSuccess();
    }
  } catch (error) {
    payload.callbackError(error.response.data.code);
  }
}

function* removeAssociationCompany({ payload }) {
  try {
    const response = yield call(removeAssociationCompanyService, payload.id);

    if (response.status === 200) {
      payload.callbackSuccess();
    }
  } catch (error) {
    payload.callbackError(error.response.data.code);
  }
}

function* updateLoginPermission({ payload }) {
  try {
    const response = yield call(updateLoginPermissionService, payload.params);

    if (response.status === 200) {
      payload.callbackSuccess();
    }
  } catch (error) {
    payload.callbackError(error.response.data.code);
  }
}

export default all([
  takeLatest("GET_ALL_ASSOCIATE_COMPANY", getAllAssociateCompany),
  takeLatest("GET_USER_DOCUMENT", getUserDocument),
  takeLatest("CREATE_ASSOCIATE_COMPANY", createAssociateCompany),
  takeLatest("REMOVE_ASSOCIATE_COMPANY", removeAssociationCompany),
  takeLatest("UPDATE_LOGIN_PERMISSION", updateLoginPermission),
]);
