export const INITIAL_VALUES = {
  collaborator: [],
  credentialType: "",
  companyNameCredential: "",

  associateCollaboratorId: "",
  categoryId: "",
  credentialName: "",
  jobTitle: "",
  email: "",
};

export const validation = (key, intl) => {
  switch (key) {
    case "collaborator":
      return [
        {
          required: true,
          message: intl.formatMessage({ id: "error.field_required" }),
        },
      ];

    case "credentialType":
      return [
        {
          required: true,
          message: intl.formatMessage({ id: "error.field_required" }),
        },
      ];
    case "companyNameCredential":
      return [
        {
          required: false,
          message: intl.formatMessage({ id: "error.company_name_required" }),
        },
      ];

    case "credentialName":
      return [
        {
          required: true,
          message: intl.formatMessage({ id: "error.field_required" }),
        },
      ];

    case "associateCollaboratorId":
      return [
        {
          required: true,
          message: intl.formatMessage({ id: "error.field_required" }),
        },
      ];

    case "categoryId":
      return [
        {
          required: true,
          message: intl.formatMessage({ id: "error.field_required" }),
        },
      ];

    case "credentialName":
      return [
        {
          required: true,
          message: intl.formatMessage({ id: "error.field_required" }),
        },
      ];

    case "jobTitle":
      return [
        {
          required: true,
          message: intl.formatMessage({ id: "error.field_required" }),
        },
      ];

    case "email":
      return [
        {
          required: true,
          message: intl.formatMessage({ id: "error.field_required" }),
        },
      ];

    default:
      return [];
  }
};
