import api from "./index"

export const getNewEmployeeCategorieServiceQuota = async (params) => 
await  api.get(`/accreditation/quota`, { params })

export const postNewCollaboratorService = async (employee) => await  api.post(`/associate-collaborators`, {...employee})

export const editCollaboratorService = async (id, employee) => await  api.put(`/associate-collaborators/${id}`, {...employee})

export const deleteCollaboratorService = async (id) => await  api.delete(`/associate-collaborators/${id}`)

export const getCollaboratorsService = async(page, perPage, search) =>
  await api.get(`/associate-collaborators?page=${page}&per_page=${perPage}&search=${search}`)

export const getSingleCollaboratorService = async(id) =>
  await api.get(`/associate-collaborators/${id}`)
