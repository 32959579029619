import styled, { css } from 'styled-components'

export const Container = styled.div`
    height: 20px;
    width: 370px;
    background-color: #D9D9D9;
    border-radius: 10px;
`

export const Filler = styled.div`
    height: 100%;
    ${(props) => 
    css`
        width: ${props.completed};
    `};
    background-color:  #49D78C;
    transition: width 0.5s ease-in-out;
    border-radius: inherit;
    text-align: right;
    display: flex;
    justify-content: flex-end;
    align-items: center;
`

export const Label = styled.span`
    padding: 5px;
    color: white;
    font-weight: bold;
`