import styled from "styled-components";

export const Container = styled.aside`
  position: absolute;
  width: 280px;
  height: 94vh;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  background: #fff;
`;

export const Image = styled.img`
  margin: 0;
  height: 80px;
`;
