import React from "react";

const Ticket = (props) => {
  const { size = "15", color = "#11A7D9", filled = false } = props;

  if (filled) {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={size}
        height={size}
        viewBox="0 0 16 17"
        fill="none"
      >
        <g clipP ath="url(#clip0_295_46501)">
          <path
            d="M6 4V13"
            stroke="#444444"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M1.5 10.9494C1.50012 10.8342 1.53999 10.7226 1.61287 10.6335C1.68575 10.5443 1.78717 10.483 1.9 10.46C2.35212 10.3682 2.75859 10.1229 3.05055 9.7657C3.34251 9.4085 3.50201 8.96134 3.50201 8.5C3.50201 8.03866 3.34251 7.5915 3.05055 7.2343C2.75859 6.87709 2.35212 6.63181 1.9 6.54C1.78717 6.51697 1.68575 6.4557 1.61287 6.36653C1.53999 6.27737 1.50012 6.16578 1.5 6.05062V4.5C1.5 4.36739 1.55268 4.24021 1.64645 4.14645C1.74021 4.05268 1.86739 4 2 4H14C14.1326 4 14.2598 4.05268 14.3536 4.14645C14.4473 4.24021 14.5 4.36739 14.5 4.5V6.05062C14.4999 6.16578 14.46 6.27737 14.3871 6.36653C14.3143 6.4557 14.2128 6.51697 14.1 6.54C13.6479 6.63181 13.2414 6.87709 12.9494 7.2343C12.6575 7.5915 12.498 8.03866 12.498 8.5C12.498 8.96134 12.6575 9.4085 12.9494 9.7657C13.2414 10.1229 13.6479 10.3682 14.1 10.46C14.2128 10.483 14.3143 10.5443 14.3871 10.6335C14.46 10.7226 14.4999 10.8342 14.5 10.9494V12.5C14.5 12.6326 14.4473 12.7598 14.3536 12.8536C14.2598 12.9473 14.1326 13 14 13H2C1.86739 13 1.74021 12.9473 1.64645 12.8536C1.55268 12.7598 1.5 12.6326 1.5 12.5V10.9494Z"
            stroke="#444444"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </g>
        <defs>
          <clipPath id="clip0_295_46501">
            <rect
              width="16"
              height="16"
              fill="white"
              transform="translate(0 0.5)"
            />
          </clipPath>
        </defs>
      </svg>
    );
  }

  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 15 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_295_46501)">
        <path
          d="M6 4V13"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M1.5 10.9494C1.50012 10.8342 1.53999 10.7226 1.61287 10.6335C1.68575 10.5443 1.78717 10.483 1.9 10.46C2.35212 10.3682 2.75859 10.1229 3.05055 9.7657C3.34251 9.4085 3.50201 8.96134 3.50201 8.5C3.50201 8.03866 3.34251 7.5915 3.05055 7.2343C2.75859 6.87709 2.35212 6.63181 1.9 6.54C1.78717 6.51697 1.68575 6.4557 1.61287 6.36653C1.53999 6.27737 1.50012 6.16578 1.5 6.05062V4.5C1.5 4.36739 1.55268 4.24021 1.64645 4.14645C1.74021 4.05268 1.86739 4 2 4H14C14.1326 4 14.2598 4.05268 14.3536 4.14645C14.4473 4.24021 14.5 4.36739 14.5 4.5V6.05062C14.4999 6.16578 14.46 6.27737 14.3871 6.36653C14.3143 6.4557 14.2128 6.51697 14.1 6.54C13.6479 6.63181 13.2414 6.87709 12.9494 7.2343C12.6575 7.5915 12.498 8.03866 12.498 8.5C12.498 8.96134 12.6575 9.4085 12.9494 9.7657C13.2414 10.1229 13.6479 10.3682 14.1 10.46C14.2128 10.483 14.3143 10.5443 14.3871 10.6335C14.46 10.7226 14.4999 10.8342 14.5 10.9494V12.5C14.5 12.6326 14.4473 12.7598 14.3536 12.8536C14.2598 12.9473 14.1326 13 14 13H2C1.86739 13 1.74021 12.9473 1.64645 12.8536C1.55268 12.7598 1.5 12.6326 1.5 12.5V10.9494Z"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_295_46501">
          <rect
            width={size}
            height={size}
            fill="white"
            transform="translate(0 0.5)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default Ticket;
