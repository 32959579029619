import React from "react";

import { Container, CustomSelect } from "./styles";

const SelectUser = ({ options, ...rest }) => {
  return (
    <Container>
      <CustomSelect
        bordered={false}
        options={options}
        placement="bottomRight"
        {...rest}
      />
    </Container>
  );
};

export default SelectUser;
