import React, { useMemo, useContext, useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { loadLanguagePreference } from "../../redux/modules/language/actions";
import { loadUser } from "../../redux/modules/auth/actions";
import ModalSettings from "../../newUXComponents/ModalSettings";
import { useIntl } from "react-intl";
import { convertToDateOnly } from "../../utils/date";

import ChecklistImagePlaceholder  from "../../newUXAssets/images/ChecklistPlaceholder.png";
import { DialogContext } from "../../hooks/dialog/context";
import useNotification from "../../hooks/newUXNotification";

import ChecklistStepModal from "./ChecklistStepModal";
import ProgressBar from "./ProgressBar";
import Label from './Label'

import {
  Container,
  Title,
  Hr,
  ChecklistArea,
  Card,
  CardTitle,
  CardSubtitle,
  CardText,
  CardTextContainer,
  CardHeader,
  CardFooter,
  DetailButton,
  CardIMG,
  ProgressContainer,
} from "./styles";

const Checklist = () => {
  const [selectedStep, setSelectedStep] = useState();
  const [totalSteps, setTotalSteps] = useState(0);
  const [completedSteps, setCompletedSteps] = useState(0);
  const intl = useIntl();
  const { checklist, isEmpty } = useSelector((state) => state.checklist);
  const { event } = useSelector((state) => state.initialSettings);
  const { createDialog } = useContext(DialogContext);

  const dispatch = useDispatch();
  const isPortuguese = useMemo(() => intl.locale === "pt", [intl]);

  const notification = useNotification();
  const { accountCreated } = useSelector((state) => state.auth);

  useEffect(() => {
    if (accountCreated) {
      if (!event || event.length === 0 || JSON.stringify(event) === "{}") {
        notification.close("event-not-selected-message");
        notification.displayInfo(
          intl.formatMessage({ id: "error.contract_not_selected" }),
          "event-not-selected-message"
        );
        dispatch(loadUser());
        dispatch(loadLanguagePreference());
        createDialog({
          id: "modal-settings-login",
          Component: ModalSettings,
          props: {},
        });
        return;
      } else notification.close("event-not-selected-message");
    }
  }, [accountCreated, checklist, event]);

  useEffect(()=>{
    if (!event.value) return
    if (isEmpty) {
      notification.displayInfo(
        intl.formatMessage({ id: "checklist.empty_message" }),
        "empty-checklist-message"
      );
    } else notification.close("empty-checklist-message");
  },[isEmpty])

  useEffect(() => {
    if (!selectedStep) return;
    createDialog({
      id: "checklist-step-display",
      Component: ChecklistStepModal,
      props: { step: selectedStep },
    });
    setSelectedStep();
  }, [selectedStep]);

  useEffect(() => {
    if (checklist.length === 0) return;

    setTotalSteps(checklist.length);

    const mappedCompletedSteps = checklist.filter((step) => step.status === 'Concluded');

    setCompletedSteps(mappedCompletedSteps.length);
  }, [checklist, setTotalSteps, setCompletedSteps]);

  const getPercentage = () => {
    return `${Math.round((completedSteps * 100) / totalSteps)}%`;
  };

  return (
    <Container>
      <Title>Checklist</Title>
      <Hr />
      {checklist.length > 0 && (
        <ProgressContainer>
          <h3>
            {intl.formatMessage(
              { id: "checklist_progress_bar" },
              { completedSteps: completedSteps, totalSteps: totalSteps }
            )}
          </h3>
          <ProgressBar completed={getPercentage()}></ProgressBar>
        </ProgressContainer>
      )}
      <ChecklistArea>
        {checklist.length > 0 &&
          checklist.map((step, index) => (
            <Card status={step.status} key={`checklist_step_${index}`}>
              <CardIMG>
                {!step.cardImageLink && 
                  <img 
                  src={ChecklistImagePlaceholder} 
                  alt='Checklist Step Cover'
                  style={{maxWidth: '100%', height: '100%'}}
                  />
                }
                {step.cardImageLink && <img src={step.cardImageLink} alt='Checklist Step Cover'/>}
              </CardIMG>
              <CardHeader>
                <CardTitle
                  ellipsis={{
                    rows: 2,
                    expandable: false,
                    tooltip: true,
                  }}
                >
                  {isPortuguese ? step.descriptionPt : step.descriptionEn}
                </CardTitle>
                <CardSubtitle>
                  {intl.formatMessage({ id: "checklist.expires" })}:{" "}
                  {convertToDateOnly(step.expiresIn)}
                </CardSubtitle>
              </CardHeader>
              <CardTextContainer>
                <CardText
                  ellipsis={{
                    rows: 3,
                    expandable: false,
                    tooltip: true,
                  }}
                >
                  <p>
                    {isPortuguese
                      ? step.additionalInfoPt
                      : step.additionalInfoEn}
                  </p>
                </CardText>
              </CardTextContainer>
              <CardFooter>
                <Label status={step.status} />
                <DetailButton onClick={() => setSelectedStep(step)}>
                  {intl.formatMessage({ id: "see_details" })}
                </DetailButton>
              </CardFooter>
            </Card>
          ))}
      </ChecklistArea>
    </Container>
  );
};

export default Checklist;
