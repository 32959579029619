import { useState } from "react";
import { cpfMask, cnpjMask, genericDocumentMask } from "./mask";

const useGenericDocumentMask = () => {
  const [mask, setMask] = useState("");

  const handleInputChange = async (event) => {
    const value = event?.target?.value?.replace(/\W/g, "");
    const maskType = handleMaskType(value);
    await setMask(maskType);
    window.requestAnimationFrame(() => {
      event.target.selectionStart = maskType.length;
      event.target.selectionEnd = maskType.length;
    });
  };

  const handleMaskType = (value) => {
    if (value?.length === 11) return cpfMask + "*";
    if (value?.length === 14) return cnpjMask + "*";
    return genericDocumentMask;
  };

  return [mask, handleInputChange];
};

export default useGenericDocumentMask;
