import React from "react";

const AlterEvent = (props) => {
  const { size = "32", color = "#6C6C6C" } = props;

  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.3538 3.64625L12.3538 1.64625C12.3073 1.59976 12.2522 1.56288 12.1915 1.53772C12.1308 1.51255 12.0657 1.4996 12 1.4996C11.9343 1.4996 11.8692 1.51255 11.8085 1.53772C11.7478 1.56288 11.6927 1.59976 11.6462 1.64625L5.64625 7.64625C5.59983 7.69272 5.56303 7.74787 5.53793 7.80857C5.51284 7.86927 5.49995 7.93432 5.5 8V10C5.5 10.1326 5.55268 10.2598 5.64645 10.3535C5.74021 10.4473 5.86739 10.5 6 10.5H8C8.06568 10.5 8.13073 10.4872 8.19143 10.4621C8.25212 10.437 8.30728 10.4002 8.35375 10.3537L14.3538 4.35375C14.4002 4.30731 14.4371 4.25217 14.4623 4.19147C14.4874 4.13077 14.5004 4.0657 14.5004 4C14.5004 3.93429 14.4874 3.86923 14.4623 3.80853C14.4371 3.74783 14.4002 3.69268 14.3538 3.64625ZM7.79313 9.5H6.5V8.20687L10.5 4.20687L11.7931 5.5L7.79313 9.5ZM12.5 4.79312L11.2069 3.5L12 2.70687L13.2931 4L12.5 4.79312ZM14 7.5V13C14 13.2652 13.8946 13.5196 13.7071 13.7071C13.5196 13.8946 13.2652 14 13 14H3C2.73478 14 2.48043 13.8946 2.29289 13.7071C2.10536 13.5196 2 13.2652 2 13V3C2 2.73478 2.10536 2.48043 2.29289 2.29289C2.48043 2.10535 2.73478 2 3 2H8.5C8.63261 2 8.75979 2.05268 8.85355 2.14644C8.94732 2.24021 9 2.36739 9 2.5C9 2.6326 8.94732 2.75978 8.85355 2.85355C8.75979 2.94732 8.63261 3 8.5 3H3V13H13V7.5C13 7.36739 13.0527 7.24021 13.1464 7.14644C13.2402 7.05268 13.3674 7 13.5 7C13.6326 7 13.7598 7.05268 13.8536 7.14644C13.9473 7.24021 14 7.36739 14 7.5Z"
        fill={color}
      />
    </svg>
  );
};

export default AlterEvent;
