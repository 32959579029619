import React, {
  useMemo,
  useEffect,
  useState,
  useCallback,
  useContext,
} from "react";

import { Form } from "antd";
import { useIntl } from "react-intl";
import { useHistory } from "react-router-dom";

import { useDispatch, useSelector } from "react-redux";

import { DialogContext } from "../../../hooks/dialog/context";
import { useLoading } from "../../../hooks/loading";

import { numberMask } from "../../../utils/numberMask";

import provisionTypesConstants from "../../../constants/provisionTypes";

import useNotification from "../../../hooks/newUXNotification";

import {
  getBenefited,
  getFindServices,
  getTypesCredentialOpportunity,
  createCredentialTransfer,
} from "../../../redux/modules/transferCredential/actions";

import CredentialsHeader from "../Components/Header";
import Button from "../../../newUXComponents/Button";
import Input from "../../../newUXComponents/Input";
import Select from "../../../newUXComponents/Select";
import ConfirmationModal from "../../../newUXComponents/Modal/ConfirmationModal";

import {
  Container,
  Title,
  Content,
  SubTitle,
  ContainerButton,
  Link,
} from "./styles";

import { INITIAL_VALUES, validation } from "./validations";

const TransferCredential = () => {
  const intl = useIntl();
  const [form] = Form.useForm();
  const history = useHistory();
  const dispatch = useDispatch();
  const notification = useNotification();
  const { handleLoading } = useLoading();
  const provisionTypes = provisionTypesConstants();
  const { createDialog } = useContext(DialogContext);

  const { contract, event } = useSelector((state) => state.initialSettings);
  const { benefited } = useSelector((state) => state.transferCredential);

  const [serviceTypes, setServiceTypes] = useState([]);
  const [typesCredentialOpportunity, setTypesCredentialOpportunity] = useState(
    []
  );

  const [disabledFieldTypesService, setDisabledFieldTypesService] =
    useState(true);

  const isPortuguese = useMemo(() => intl.locale === "pt", [intl]);

  const callbackSuccess = useCallback(() => {
    handleLoading(false);
    form.resetFields();
    notification.displaySuccess(
      intl.formatMessage({ id: "success.credential_transfer" }),
      "success.credential_transfer"
    );
  }, [form, intl, notification, handleLoading]);

  const callbackError = useCallback(
    (code) => {
      handleLoading(false);
      notification.displayError(
        intl.formatMessage({ id: "api.error." + code }),
        "find-services-error"
      );
    },
    [intl, notification, handleLoading]
  );

  const onFinish = () => {
    const formData = form.getFieldsValue(true);
    createDialog({
      id: "modal-associate-credential",
      Component: ConfirmationModal,
      props: {
        title: intl.formatMessage({ id: "credential_transfer" }),
        message: intl.formatMessage({ id: "credential_transfer_message" }),
        labelBtnConfir: "confirm",
        labelClose: "cancel",
        confirmation: () => {
          handleLoading(true);
          dispatch(
            createCredentialTransfer(
              { ...formData, opportunityId: contract.value },
              callbackSuccess,
              callbackError
            )
          );
        },
      },
    });
  };

  const callbackErrorTypesCredentialOpportunity = useCallback(() => {
    notification.displayError(
      intl.formatMessage({ id: "types_of_service_error" }),
      "find-services-error"
    );
  }, [notification, intl]);

  const callbackSuccessTypesCredentialOpportunity = useCallback(
    (item) => {
      if (item.length) {
        const credentialCategories = item.map((category) => ({
          value: category.id,
          label: isPortuguese ? category.title : category.englishTitle,
        }));
        setTypesCredentialOpportunity(credentialCategories);
      }
    },
    [isPortuguese]
  );

  const handleGetTypesCredentialOpportunity = useCallback(
    (value) => {
      const idBenefited = form.getFieldValue("benefitedId");
      dispatch(
        getTypesCredentialOpportunity(
          {
            opportunityId: contract.value,
            systemUserBenefitedId: idBenefited,
            serviceProviderAssociationId: value,
          },
          callbackSuccessTypesCredentialOpportunity,
          callbackErrorTypesCredentialOpportunity
        )
      );
    },
    [
      dispatch,
      contract.value,
      callbackSuccessTypesCredentialOpportunity,
      callbackErrorTypesCredentialOpportunity,
      form,
    ]
  );

  const callbackErrorFindServices = useCallback(() => {
    notification.displayError(
      intl.formatMessage({ id: "types_of_service_error" }),
      "find-services-error"
    );
  }, [notification, intl]);

  const callbackSuccessFindServices = useCallback(
    (item) => {
      if (item.length) {
        const serviceTypes = item.map((service) => ({
          value: service.serviceProviderAssociationId,
          label: provisionTypes.find((pt) => pt.value === service.serviceType)
            ?.label,
        }));
        setServiceTypes(serviceTypes);
      }
    },
    [provisionTypes]
  );

  const handleGetFindServices = useCallback(
    (value) => {
      dispatch(
        getFindServices(
          {
            opportunityId: contract.value,
            systemUserBenefitedId: value,
          },
          callbackSuccessFindServices,
          callbackErrorFindServices
        )
      );
    },
    [
      dispatch,
      contract.value,
      callbackErrorFindServices,
      callbackSuccessFindServices,
    ]
  );

  const handleFieldBenefited = (value, options) => {
    form.setFieldsValue({
      serviceProviderAssociationId: "",
      categoryId: "",
    });
    if (options?.serviceProvider) {
      setDisabledFieldTypesService(false);
      handleGetFindServices(value);
    } else {
      handleGetTypesCredentialOpportunity(null);
      setDisabledFieldTypesService(true);
    }
  };

  const benefitedOptions = useMemo(() => {
    if (benefited.length) {
      return benefited.map((item) => ({
        label: item.commercialName,
        value: item.id,
        serviceProvider: item.serviceProvider,
      }));
    }
    return [];
  }, [benefited]);

  useEffect(() => {
    if (!contract.value || !event.value) {
      notification.displayInfo(
        intl.formatMessage({ id: "error.contract_not_selected" }),
        "event-not-selected-message"
      );
    } else {
      dispatch(getBenefited(contract.value));
      form.resetFields();
      notification.close("event-not-selected-message");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contract.value, event.value]);

  return (
    <Container>
      <Title>{intl.formatMessage({ id: "credentials" })}</Title>
      <CredentialsHeader isTransferSelected />
      <Content>
        <SubTitle>{intl.formatMessage({ id: "new_transfer" })}</SubTitle>
        {contract?.value && event?.value && (
          <>
            <Form
              layout="vertical"
              form={form}
              name="credential-form"
              onFinish={onFinish}
              initialValues={INITIAL_VALUES}
              style={{
                width: 500,
              }}
            >
              <Select
                name="benefitedId"
                label={intl.formatMessage({ id: "benefited" })}
                allowClear
                placeholder={intl.formatMessage({ id: "benefited" })}
                rules={validation("benefitedId", intl)}
                options={benefitedOptions}
                onChange={(value, options) => {
                  handleFieldBenefited(value, options);
                }}
              />

              <Select
                name="serviceProviderAssociationId"
                placeholder={intl.formatMessage({
                  id: "types_of_service",
                })}
                label={intl.formatMessage({
                  id: "types_of_service",
                })}
                rules={validation("serviceProviderAssociationId", intl, {
                  required: !disabledFieldTypesService,
                })}
                options={serviceTypes}
                disabled={disabledFieldTypesService}
                onChange={(value) => {
                  handleGetTypesCredentialOpportunity(value);
                }}
                allowClear
              />
              <Select
                name="categoryId"
                label={intl.formatMessage({ id: "credential_types" })}
                allowClear
                placeholder={intl.formatMessage({ id: "credential_types" })}
                rules={validation("categoryId", intl)}
                options={typesCredentialOpportunity}
              />

              <Input
                type="text"
                name="quantity"
                placeholder={intl.formatMessage({
                  id: "quantity",
                })}
                label={intl.formatMessage({
                  id: "quantity",
                })}
                rules={validation("quantity", intl)}
                onChange={({ target }) => {
                  form.setFieldValue("quantity", numberMask(target.value));
                }}
              />
              <ContainerButton>
                <Button
                  type="link"
                  label={intl.formatMessage({ id: "clear_fields" })}
                  onClick={() => {
                    form.resetFields();
                  }}
                />
                <Button
                  type="primary"
                  label={intl.formatMessage({ id: "save" })}
                  htmlType="submit"
                />
              </ContainerButton>
            </Form>
            <Link
              onClick={() => {
                history.push("/credentials/transfer-history");
              }}
            >
              {intl.formatMessage({ id: "view_transfer_history" })}
            </Link>
          </>
        )}
      </Content>
    </Container>
  );
};

export default TransferCredential;
