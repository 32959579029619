import styled, { css } from "styled-components";

import { Form, Select, Input } from "antd";

export const Container = styled(Form.Item)`
  width: 100%;

  .ant-form-item-label {
    padding: 0 0 5px !important;
  }

  .ant-form-item-label > label {
    color: #444;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
  }

  ${(props) => {
    switch (props.$margin) {
      case true:
        return css`
          margin-bottom: 0 !important;
        `;
      default:
        return css`
          margin-bottom: 24px;
        `;
    }
  }}
`;

export const CustomSelect = styled(Select)`
  align-items: center;
  color: #6c6c6c !important;
  font-family: "Open Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;

  .ant-select-arrow .anticon > svg {
    color: #444444;
    width: 14px;
    height: 14px;
  }

  .anticon {
    padding-left: 5px;
  }

  .ant-select-selector {
    min-height: 40px !important;
    line-height: 40px !important;
    border-radius: 10px !important;
    border: 1px solid #c7c9d9 !important;

    ${(props) => {
      if (props.$margin) {
        return css`
          border-bottom-right-radius: 0px !important;
          border-top-right-radius: 0px !important;
        `;
      }
    }}
  }

  .ant-select-selection-placeholder {
    color: #6c6c6c !important;
    font-family: "Open Sans";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 40px !important;
  }
  .ant-select-selection-item {
    line-height: 40px !important;
    margin-left: 12px !important;
    height: 45px !important;
  }

  .ant-select-selection-search-input {
    display: ${({ $isDropdownSelect }) => ($isDropdownSelect ? "none" : "flex")};
  }
`;

export const Content = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
`;

export const InputSearch = styled(Input.Search)`
  input,
  .ant-input-group-addon {
    color: #6c6c6c;
    background: #ffffff;
    font-family: "Open Sans";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    border-radius: 15px;
    border-right: none;

    button {
      background: #ffffff;
      border-radius: 0 10px 10px 0 !important;
      height: 29px !important;
      border-left: none;

      :hover {
        border-color: #d9d9d9 !important;
      }

      :focus {
        border-color: #d9d9d9 !important;
      }
    }

    :hover {
      border-color: #d9d9d9 !important;
    }

    :focus {
      border-color: #d9d9d9 !important;
    }
  }
`;

export const NotFound = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;
