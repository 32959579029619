import React, { useCallback } from "react";
import { useSelector } from "react-redux";
import Routes from "./legacyRoutes";
import NewUXRoutes from "./newUXRoutes";

const MultiRouter = () => {
  const { user, serviceProviderLoggingAsAnExhibitor, adminToken } = useSelector(
    (state) => state.auth
  );

  const isServiceProvider = useCallback(
    () =>
      !adminToken &&
      (serviceProviderLoggingAsAnExhibitor ||
        user?.role?.type === "SERVICE_PROVIDER"),
    [adminToken, serviceProviderLoggingAsAnExhibitor, user?.role?.type]
  );
  const isExhibitor = useCallback(
    () => !adminToken && user?.role?.type === "EXHIBITOR",
    [adminToken, user?.role?.type]
  );

  return isExhibitor() || isServiceProvider() || adminToken ? (
    <NewUXRoutes />
  ) : (
    <Routes />
  );
};

export default MultiRouter;
