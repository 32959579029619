import React, { useCallback, useEffect, useRef, useState, useMemo } from "react";
import { Prompt } from "react-router";
import { useIntl, FormattedMessage } from "react-intl";
import { useSelector } from "react-redux";
import { useParams } from "react-router";
import AnimatedMainContainer from "../../../../components/AnimatedMainContainer";
import {
  Container,
  Title,
  FieldsContainer,
  Horizontal,
  SessionContainer,
  SessionIconContainer,
  SessionTitle,
  Session,
  SessionDivider,
  InformationEditionRow,
  InformationEditionContainer,
  DocumentsContainer,
  SelectContractContainer,
  ContractSelection,
  FormButtonContainer,
} from "./styles";
import * as Yup from "yup";
import getValidationErrors from "../../../../utils/getValidationErrors";
import { validateSingleFieldOnBlur } from "../../../../utils/formValidations";
import api from "../../../../service";
import { Form } from "@unform/web";
import InputWithLabel from "../../../../components/InputWithLabel";
import SelectWithLabel from "../../../../components/SelectWithLabel";
import InputWithHorizontalLabel from "../../../../components/InputWithHorizontalLabel";
import { MdInfo, MdDescription, MdImage, MdAssignment, MdHome } from "react-icons/md";
import { useToast } from "../../../../hooks/toast";
import Table from "../../../../components/Table";
import Button from "../../../../components/Button";
import PDFModal from "../../../../components/PDFModal";
import { Strings } from "../../../../utils/stringSanitize";
import { getLogoSrcFromHtml } from "../../../../utils/htmlManipulation";
import HtmlContainer from "../../../../components/HtmlContainer";
import { convertToDateOnly } from "../../../../utils/date";
import {
  ActionContainer,
  DocumentOpenImage,
  DocumentOpenText,
  DocumentVisualization,
  TitleContainer,
} from "../../Detail/styles";
import { BadgeContainer } from "../../../../components/Badge/styles";
import Badge from "../../../../components/Badge";
import { useHistory } from "react-router-dom";
import { useLoading } from "../../../../hooks/loading";
import FormButton from "../../../../components/FormButton";
import ExpandableRow from "./ExpandableRow";

const Detail = () => {
  const { user, token } = useSelector((state) => state.auth);
  const { id } = useParams();
  const history = useHistory();

  const [opportunitySelected, setOpportunitySelected] = useState(null);
  const [opportunitiesList, setOpportunitiesList] = useState([]);

  const [logoSrc, setLogoSrc] = useState();
  const { handleLoading } = useLoading();
  const [additionalProducts, setAdditionalProducts] = useState([]);
  const [loading, setLoading] = useState(false);
  const [boothList, setBoothList] = useState([]);
  const [selectedBoothNumber, setSelectedBoothNumber] = useState("");
  const [selectedBoothArea, setSelectedBoothArea] = useState("");
  const [selectedBoothType, setSelectedBoothType] = useState("");
  const [eventFileOpportunityList, setEventFileOpportunityList] =
    useState([]);
  const [viewEventFileModalOpen, setViewEventFileModalOpen] =
    useState(false);
  const [viewEventFileLocation, setViewEventFileLocation] =
    useState();

  const [data, setData] = useState({
    feira: "",
    feira_ingles: "",
    edicaoDaFeira: "",
    year: "",
    eventEditionNumber: "",
    place: "",
    pavilion: "",
    address: "",
    fair_email: "",
    responsible: "",
    video_link: "",
    opportunities: [{ booths: [] }],
    headBand: "",
  });

  const formRef = useRef(null);
  const intl = useIntl();

  const { addToast } = useToast();

  const schema = Yup.object().shape({
    contract_id: Yup.string()
      .nullable()
      .required(intl.formatMessage({ id: "error.field_required" })),
    headband: Yup.string()
      .nullable()
      .required(intl.formatMessage({ id: "error.field_required" })),
  });

  const schema_headband = Yup.object().shape({
    contract_id: Yup.string()
      .nullable()
      .required(intl.formatMessage({ id: "front_sign_error" })),
    headband: Yup.string()
      .nullable()
      .required(intl.formatMessage({ id: "front_sign_error" })),
  });

  const handleSubmit = useCallback(
    async (data) => {
      try {
        formRef.current?.setErrors({});
        setLoading(true);
        handleLoading(true);
        if (loading) {
          return;
        }
        await schema.validate(data, { abortEarly: false });

        api
          .post(
            `/exhibitor/opportunity/headband`,
            {
              opportunityId: data.contract_id,
              headband: data.headband,
            },
            {
              headers: {
                "Content-Type": "application/json",
                Accept: "*/*",
                Authorization: `Bearer ${token}`,
              },
            }
          )
          .then((result) => {
            addToast({
              type: "success",
              title: intl.formatMessage({
                id: "success",
              }),
              description: intl.formatMessage({
                id: "success.front_sign_opportunity_creation",
              }),
            });
          })
          .catch((err) => {
            setLoading(false);
            addToast({
              type: "error",
              title: intl.formatMessage({
                id: "error",
              }),
              description: intl.formatMessage({
                id: "api.error." + err.response.data.code,
              }),
            });
          })
          .finally(() => {
            setLoading(false);
            handleLoading(false);
          });
      } catch (err) {
        setLoading(false);
        handleLoading(false);
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);

          formRef.current?.setErrors(errors);

          return;
        }
      }
    },
    [schema, loading, addToast, handleLoading, intl, token]
  );

  useEffect(() => {
    handleLoading(true);
    api
      .get(`/exhibitor/events/${id}`, {
        headers: {
          "Content-Type": "application/json",
          Accept: "*/*",
          Authorization: `Bearer ${token}`,
        },
      })
      .then((result) => {
        setData(result.data.output);
        const opportunities = result.data.output.opportunities;
        if (opportunities.length > 0) {
          setOpportunitiesList(opportunities
              .map((opportunity) => ({
                  label: opportunity.contractNumber,
                  value: opportunity.opportunityId,
              }))
              .sort((a, b) =>
                  a.label < b.label ? -1 : a.label > b.label ? 1 : 0
              )
          );
        }
        setLogoSrc(getLogoSrcFromHtml(result.data.output.logo));
      })
      .catch((err) => {
        addToast({
          type: "error",
          title: intl.formatMessage({
            id: "error",
          }),
          description: intl.formatMessage({
            id: "api.error." + err.response.data.code,
          }),
        });
      })
      .finally(() => {
        handleLoading(false);
      });
  }, [addToast, id, intl, token, handleLoading]);

  const validationBlur = (name) => {
    let errors = formRef.current.getErrors();

    validateSingleFieldOnBlur(errors, schema, name, formRef);
  };

  const validationBlurHeadband = (name) => {
    let errors = formRef.current.getErrors();

    validateSingleFieldOnBlur(errors, schema_headband, name, formRef);
  };

  const mandatoryServicesColumns = [
    {
      name: intl.formatMessage({ id: "description" }),
      selector: (row) => row.description,
      format: (row) =>
        row.productLocalName ? row.productLocalName : row.description,
    },
    {
      name: intl.formatMessage({ id: "quantity" }),
      selector: (row) => row.quantity,
      right: true,
    },
  ];

  const optionalServiceColumns = [
    {
      name: intl.formatMessage({ id: "order_number" }),
      selector: (row) => row.orderId,
    },
    {
      name: intl.formatMessage({ id: "situation" }),
      selector: (row) => row.paid,
      format: (row) =>
        row.paid
          ? intl.formatMessage(
              { id: "paid_on" },
              { value: convertToDateOnly(row.paymentDate) }
            )
          : row.statusDesc,
    },
    {
      name: intl.formatMessage({ id: "sale_date" }),
      selector: (row) => row.saleDate,
      format: (row) => convertToDateOnly(row.saleDate),
    },
    {
      name: intl.formatMessage({ id: "value" }),
      selector: (row) => row.value,
      format: (row) => `${row.value.toFixed(2)} ${row.currency || ""}`,
    },
    {
      name: intl.formatMessage({ id: "type" }),
      selector: (row) => row.exhibitor,
      format: (row) =>
        row.exhibitor
          ? intl.formatMessage({ id: "exhibitor" })
          : intl.formatMessage({ id: "service_provider" }),
    },
    {
      name: intl.formatMessage({ id: "name" }),
      selector: (row) => row.clientName,
      format: (row) => row.clientName || "",
    },
  ];

  const handleContractVisualization = () => {
    const opportunityId = formRef.current.getFieldValue("contract_id");
    setOpportunitySelected(null);
    formRef.current.setFieldValue("booth_select", {
      label: intl.formatMessage({ id: "select_a_booth" }),
      value: "",
    });
    setSelectedBoothNumber("");
    setSelectedBoothArea("");
    setSelectedBoothType("");
    if (opportunityId === "") {
      return;
    }
    let opportunities = data.opportunities;

    let opportunityFiltered = opportunities.filter(
      (value) => value.opportunityId === opportunityId
    )[0];

    setOpportunitySelected(opportunityFiltered);
    setBoothList(opportunityFiltered.booths
        .filter((booth) => booth.boothNumber)
        .map((booth) => ({
            label: booth.boothNumber,
            value: booth.boothNumber,
        }))
        .sort((a, b) =>
            a.label < b.label ? -1 : a.label > b.label ? 1 : 0
        )
    );
    setLoading(true);
    handleLoading(true);
    api
      .patch(
        "/ecommerce/chleba/order",
        {
          contractNumber: opportunityFiltered.contractNumber,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Accept: "*/*",
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((result) => {
        setAdditionalProducts(result.data.ecommerceChlebaOrderFilterOutput);
      })
      .catch((err) => {
        addToast({
          type: "error",
          title: intl.formatMessage({
            id: "error",
          }),
          description: intl.formatMessage({
            id: "api.error." + err.response.data.code,
          }),
        });
      })
      .finally(() => {
        handleLoading(false);
        setLoading(false);
      });
  };

  const handleBoothVisualization = () => {
    const boothNumber = formRef.current.getFieldValue("booth_select");
    setSelectedBoothNumber("");
    setSelectedBoothArea("");
    setSelectedBoothType("");
    if (boothNumber === "") {
      return;
    }

    let boothFiltered = opportunitySelected.booths.filter(
      (booth) => booth.boothNumber === boothNumber
    )[0];

    setSelectedBoothNumber(boothFiltered?.boothNumber);
    setSelectedBoothArea(boothFiltered?.area);
    setSelectedBoothType(boothFiltered?.type);
  };

  useEffect(() => {
    if (opportunitySelected) {
      if (opportunitySelected?.contractCurrency === "BRL") {
        setEventFileOpportunityList(data.eventFilePtList);
      } else {
        setEventFileOpportunityList(data.eventFileEnList);
      }
    }
  }, [
    opportunitySelected,
    data.eventFilePtList,
    data.eventFileEnList,
  ]);

  const preventOutPage = (headbandValue) => {
    if (
      user.role.type === "EXHIBITOR" &&
      (headbandValue === "" || headbandValue == null)
    ) {
      try {
        formRef.current?.setErrors({});
        validationBlurHeadband("headband");
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);

          formRef.current?.setErrors(errors);

          return;
        }
      }
      return true;
    } else {
      return false;
    }
  };

  const handleViewEventFile = useCallback(
    (id) => {
      handleLoading(true);
      api
        .get(`/event-file/${id}?action=VIEW`, {
          headers: {
            "Content-Type": "application/json",
            Accept: "*/*",
            Authorization: `Bearer ${token}`,
          },
        })
        .then((result) => {
          const sendMethod = result.data.output.sendMethod;

          if (sendMethod === "UPLOAD") {
            setViewEventFileLocation(result.data.output.document);
            setViewEventFileModalOpen(true);
          } else {
            window.open(
              result.data.output.link,
              "_blank"
            );
          }
        })
        .catch((err) =>
          addToast({
            type: "error",
            title: intl.formatMessage({
              id: "error",
            }),
            description: intl.formatMessage({
              id: "api.error." + err.response.data.code,
            }),
          })
        )
        .finally(() => handleLoading(false));
    },
    [addToast, intl, token, handleLoading]
  );

  const eventFileColumns = useMemo(
    () => [
      {
        name: intl.formatMessage({ id: "title" }),
        selector: (row) => row.title,
        sortable: true,
        allowOverflow: true,
        wrap: false,
        minWidth: "200px",
      },
      {
        cell: (row) => (
          <Badge
            color="#002244"
            text={intl.formatMessage({ id: "visualize" })}
            onClick={() => handleViewEventFile(row.id)}
          />
        ),
        right: true,
        width: "150px",
      },
    ],
    [intl, handleViewEventFile]
  );

  return (
    <>
      <AnimatedMainContainer
        breadcrumb={intl.formatMessage({ id: "edition_information" })}
      >
        <Form
          ref={formRef}
          onSubmit={handleSubmit}
          initialData={{
            event: data.eventEditionName,
            edicaoDaFeira: data.eventEditionCode,
            information_start:
              data.eventStartDate && convertToDateOnly(data.eventStartDate),
            information_end:
              data.eventEndDate && convertToDateOnly(data.eventEndDate),
            place: data.eventVenue,
            pavilion: data?.opportunities[0]?.booths[0]?.pavilion,
            status: data.active,
            headband: opportunitySelected?.headband,
          }}
        >
          <Container>
            <TitleContainer>
              <Title>
                <FormattedMessage id="search_for_event_edition" />
              </Title>
              <ActionContainer>
                <BadgeContainer
                  onClick={() => history.push("/events-exhibitor-visualization")}
                >
                  <Badge
                    color="#C9C9C9"
                    text={intl.formatMessage({ id: "goback" })}
                    fontSize="12px"
                  />
                </BadgeContainer>
              </ActionContainer>
            </TitleContainer>
            <SessionDivider nr_items="2">
              <Session>
                <SessionContainer>
                  <SessionIconContainer>
                    <MdInfo size="16px" color="#002244" />
                  </SessionIconContainer>
                  <SessionTitle>
                    <FormattedMessage id="informations" />
                  </SessionTitle>
                </SessionContainer>
                <FieldsContainer>
                  <InputWithLabel
                    name="event"
                    validationBlur={validationBlur}
                    disabled
                    label={intl.formatMessage({ id: "event_edition" })}
                  />
                  <InformationEditionContainer>
                    <InformationEditionRow>
                      <InputWithHorizontalLabel
                        name="edicaoDaFeira"
                        validationBlur={validationBlur}
                        disabled
                        className="edition"
                        label={intl.formatMessage({ id: "edition" })}
                      />
                      <InputWithHorizontalLabel
                        name="information_start"
                        validationBlur={validationBlur}
                        disabled
                        label={intl.formatMessage({ id: "start" })}
                      />
                      <InputWithHorizontalLabel
                        name="information_end"
                        validationBlur={validationBlur}
                        disabled
                        label={intl.formatMessage({ id: "end" })}
                      />
                    </InformationEditionRow>
                  </InformationEditionContainer>

                  <Horizontal>
                    <InputWithLabel
                      name="place"
                      validationBlur={validationBlur}
                      disabled
                      label={intl.formatMessage({ id: "place" })}
                    />

                    <InputWithLabel
                      name="pavilion"
                      validationBlur={validationBlur}
                      disabled
                      label={intl.formatMessage({ id: "pavilion" })}
                    />
                  </Horizontal>
                </FieldsContainer>
              </Session>
              <Session>
                <SessionContainer>
                  <SessionIconContainer>
                    <MdImage size="16px" color="#002244" />
                  </SessionIconContainer>
                  <SessionTitle>
                    <FormattedMessage id="fair_logo" />
                  </SessionTitle>
                </SessionContainer>
                <FieldsContainer>
                  <DocumentsContainer>
                    {logoSrc ? (
                      <DocumentVisualization>
                        <DocumentOpenText>
                          <DocumentOpenImage src={logoSrc} />
                        </DocumentOpenText>
                      </DocumentVisualization>
                    ) : null}
                  </DocumentsContainer>
                </FieldsContainer>
              </Session>
            </SessionDivider>
          </Container>
          <Container>
            <Session>
              <SessionContainer>
                <SessionIconContainer>
                  <MdAssignment size="16px" color="#002244" />
                </SessionIconContainer>
                <SessionTitle>
                  <FormattedMessage id="contracts" /> (
                  {data.opportunities ? data.opportunities.length : 0})
                </SessionTitle>
              </SessionContainer>
              <FieldsContainer>
                <SelectContractContainer>
                  <SessionDivider nr_items="2">
                    <Session>
                      <ContractSelection>
                        <SelectWithLabel
                          name="contract_id"
                          menuPosition="fixed"
                          label={intl.formatMessage({
                            id: "select_a_contract",
                          })}
                          placeholder={intl.formatMessage({
                            id: "select_an_option",
                          })}
                          options={opportunitiesList}
                          required
                        />
                        <Button
                          type="button"
                          onClick={() => handleContractVisualization()}
                          style={{ marginTop: "30px" }}
                        >
                          <FormattedMessage id="visualize" />
                        </Button>
                      </ContractSelection>
                    </Session>

                    <Session></Session>
                  </SessionDivider>
                </SelectContractContainer>

                {opportunitySelected && (
                  <>
                    <Prompt
                      when={preventOutPage(
                        formRef.current.getFieldValue("headband")
                      )}
                    ></Prompt>
                    <SessionDivider nr_items="2">
                      <Session>
                        <SessionContainer>
                          <SessionIconContainer>
                            <MdInfo size="16px" color="#002244" />
                          </SessionIconContainer>
                          <SessionTitle>
                            <FormattedMessage id="contract_detail" />
                          </SessionTitle>
                        </SessionContainer>
                        <FieldsContainer>
                          <Horizontal>
                            <InformationEditionRow className="contract_information_row">
                              <InputWithLabel
                                name="contract_number"
                                validationBlur={validationBlur}
                                disabled
                                label={intl.formatMessage({
                                  id: "contract_number",
                                })}
                                value={opportunitySelected?.contractNumber}
                              />
                            </InformationEditionRow>
                          </Horizontal>
                          <Horizontal>
                            <InputWithLabel
                              name="responsible"
                              validationBlur={validationBlur}
                              disabled
                              label={intl.formatMessage({ id: "responsible" })}
                              value={
                                Strings.orEmpty(
                                  opportunitySelected?.opportunityContact
                                    ?.firstName
                                ) +
                                " " +
                                Strings.orEmpty(
                                  opportunitySelected?.opportunityContact
                                    ?.lastName
                                )
                              }
                            />
                            <InputWithLabel
                              name="contact"
                              validationBlur={validationBlur}
                              disabled
                              label={intl.formatMessage({ id: "contact" })}
                              value={
                                opportunitySelected?.opportunityContact?.email
                              }
                            />
                          </Horizontal>
                          <Horizontal>
                            <HtmlContainer
                              label={intl.formatMessage({
                                id: "special_conditions",
                              })}
                              disabled={true}
                              value={opportunitySelected?.specialConditions}
                            />
                          </Horizontal>
                        </FieldsContainer>
                      </Session>
                      <Session>
                        <SessionContainer>
                          <SessionIconContainer>
                            <MdHome size="16px" color="#002244" />
                          </SessionIconContainer>
                          <SessionTitle>
                            <FormattedMessage id="booth" />
                          </SessionTitle>
                        </SessionContainer>
                        <FieldsContainer>
                          <Horizontal>
                            <SelectWithLabel
                              name="booth_select"
                              label={intl.formatMessage({
                                id: "select_a_booth",
                              })}
                              placeholder={intl.formatMessage({
                                id: "select_an_option",
                              })}
                              options={boothList}
                            />
                            <FormButtonContainer>
                              <FormButton
                                type="button"
                                loading={loading}
                                onClick={handleBoothVisualization}
                              >
                                <FormattedMessage id="visualize" />
                              </FormButton>
                            </FormButtonContainer>
                          </Horizontal>
                          <Horizontal>
                            <InputWithLabel
                              name="booth_number"
                              validationBlur={validationBlur}
                              disabled
                              className="edition"
                              label={intl.formatMessage({ id: "booth_number" })}
                              value={selectedBoothNumber}
                            />
                            <InputWithLabel
                              name="area"
                              validationBlur={validationBlur}
                              disabled
                              label={intl.formatMessage({ id: "area" })}
                              value={selectedBoothArea}
                            />
                          </Horizontal>
                          <Horizontal>
                            <InputWithLabel
                              name="booth_type"
                              validationBlur={validationBlur}
                              disabled
                              label={intl.formatMessage({ id: "booth_type" })}
                              value={selectedBoothType}
                            />
                          </Horizontal>
                          <Horizontal>
                            <InputWithLabel
                              name="headband"
                              validationBlur={validationBlur}
                              label={intl.formatMessage({ id: "front_sign" })}
                              maxLength="500"
                              required
                            />
                            <FormButtonContainer>
                              <FormButton
                                type="submit"
                                loading={loading}
                              >
                                <FormattedMessage id="save" />
                              </FormButton>
                            </FormButtonContainer>
                          </Horizontal>
                        </FieldsContainer>
                      </Session>
                    </SessionDivider>
                    <Session>
                      <SessionContainer>
                        <SessionIconContainer>
                          <MdDescription size="16px" color="#002244" />
                        </SessionIconContainer>
                        <SessionTitle>
                          <FormattedMessage id="documentation" />
                        </SessionTitle>
                      </SessionContainer>
                      <FieldsContainer>
                        <Table
                          data={eventFileOpportunityList}
                          columns={eventFileColumns}
                          hasPagination={false}
                        />
                        <PDFModal
                          setIsOpen={setViewEventFileModalOpen}
                          modalIsOpen={viewEventFileModalOpen}
                          pdfLocation={viewEventFileLocation}
                        />
                      </FieldsContainer>
                    </Session>
                    <Table
                      className="mandatory-table"
                      data={opportunitySelected?.mandatoryProducts}
                      columns={mandatoryServicesColumns}
                      title={intl.formatMessage({
                        id: "mandatory_products_and_services",
                      })}
                      titleMarginLeft="0px"
                      progressPending={loading}
                      hasPagination={false}
                    />
                    <Table
                      className="mandatory-table"
                      data={additionalProducts}
                      columns={optionalServiceColumns}
                      title={intl.formatMessage({
                        id: "optional_products_and_services",
                      })}
                      titleMarginLeft="0px"
                      progressPending={loading}
                      hasPagination={false}
                      expandableRows
                      expandableRowsComponent={ExpandableRow}
                    />
                  </>
                )}
              </FieldsContainer>
            </Session>
          </Container>
        </Form>
      </AnimatedMainContainer>
    </>
  );
};

export default Detail;
