import api from "./index";

export const getBenefitedService = async (id) =>
  await api.get(`/users/transfer/opportunity/${id}`);

export const getFindServiceServices = async (params) =>
  await api.patch("/provider-association/find-services", params);

export const getTypesCredentialOpportunityServices = async (params) =>
  await api.patch("/ecommerce/category/available-transfer/opportunity", params);

export const createCredentialTransferServices = async (params) =>
  await api.post("/credential-transfer", params);

export const getTransferHistoryService = async (params) =>
  await api.get("/credential-transfer", { params });
