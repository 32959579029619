import styled, { css } from "styled-components";

export const Container = styled.div`
  display: flex;
  align-items: center;
  flex: 1;
  width: 100%;
  & > div {
    width: 100%;
  }

  & + div {
    margin-top: 24px;
  }

  input {
    font-size: 14px;
  }
`;

export const Error = styled.div`
  margin: 5px 0px !important;
  display: flex;
  font-size: 10px;
  align-items: center;
  justify-content: flex-start;
  color: #e00000;
  text-align: left;
  svg {
    margin-right: 4px;
    /* margin-left: 16px; */
    min-width: 32px;
  }
  span {
    background: #c53030;
    color: #fff;
    &::before {
      border-color: #c53030 transparent;
    }
  }
`;

export const InputContainer = styled.div`
  background: transparent;
  border-radius: 5px;
  padding: 8px 16px;
  color: #dadada;
  display: flex;
  align-items: center;
`;
