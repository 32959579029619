import { useIntl } from "react-intl";

const intl = useIntl

const credentialTypes = () => {
  const credentialTypesArray = [
      {
        label: intl().formatMessage({ id: "assemble" }),
        value: "ASSEMBLE",
      },
      {
        label: intl().formatMessage({ id: "service" }),
        value: "SERVICE",
      },
      {
        label: intl().formatMessage({ id: "maintenance" }),
        value: "MAINTENANCE",
      },
      {
        label: intl().formatMessage({ id: "security_session" }),
        value: "SECURITY",
      },
      {
        label: intl().formatMessage({ id: "exhibitor" }),
        value: "EXHIBITOR",
      },
  ];

  return credentialTypesArray;
}

export default credentialTypes;
