import React, { useRef, useState, useMemo } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useSelector } from "react-redux";
import { useHistory } from "react-router";
import AnimatedMainContainer from "../../components/AnimatedMainContainer";
import { useToast } from "../../hooks/toast";
import * as Yup from "yup";
import getValidationErrors from "../../utils/getValidationErrors";
import api from "../../service";
import { useCallback } from "react";
import FormButton from "../../components/FormButton";
import { Form } from "@unform/web";
import { validateSingleFieldOnBlur } from "../../utils/formValidations";
import { base64ToBlob, downloadBlob } from "../../utils/file";

import {
  Container,
  FormContainer,
  TitleContainer,
  Title,
  FieldsContainer,
  Horizontal,
  FilterActionsContainer,
  SubmitContainer,
  GoBackContainer,
  ActionContainer,
  BadgeContainer,
  LinkButton,
  NotificationContainer,
} from "./styles";
import Badge from "../../components/Badge";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import Table from "../../components/Table";
import InputWithLabel from "../../components/InputWithLabel";
import provisionTypesConstants from "../../constants/provisionTypes";
import MaskedInputWithLabel from "../../components/MaskedInputWithLabel";
import useGenericDocumentMask from "../../utils/useGenericDocumentMask";
import Report from "./report";

const ManageServiceProvidersReport = () => {
  const { token } = useSelector((state) => state.auth);
  const formRef = useRef(null);
  const intl = useIntl();
  const history = useHistory();
  const { addToast } = useToast();
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [showExport, setShowExport] = useState(false);
  const [filtered, setFiltered] = useState(false);
  const [documentMask, setDocumentMask] = useGenericDocumentMask();
  const provisionTypesArray = provisionTypesConstants();

  const schema = Yup.object().shape({
    company: Yup.string().nullable(),
    document: Yup.string().nullable(),
  });

  const handleSubmit = useCallback(
    async (data) => {
      try {
        formRef.current?.setErrors({});

        setLoading(true);
        if (loading) {
          return;
        }

        await schema.validate(data, { abortEarly: false });

        api
          .patch(
            `/admin/manage-service-providers`,
            {},
            {
              params: {
                accountName: data.company,
                document: data.document,
              },
              headers: {
                "Content-Type": "application/json",
                Accept: "*/*",
                Authorization: `Bearer ${token}`,
              },
            }
          )
          .then((result) => {
            setData(result.data.output);
            setShowExport(true);
          })
          .catch((err) => {
            setData([]);
            setShowExport(false);
            addToast({
              type: "error",
              title: intl.formatMessage({
                id: "error",
              }),
              description: intl.formatMessage({
                id: "api.error." + err.response.data.code,
              }),
            });
          })
          .finally(() => {
            setLoading(false);
            setFiltered(true);
          });
      } catch (err) {
        setLoading(false);
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);

          formRef.current?.setErrors(errors);

          return;
        }
      }
    },
    [addToast, intl, loading, schema, token]
  );

  const validationBlur = (name) => {
    let errors = formRef.current.getErrors();
    validateSingleFieldOnBlur(errors, schema, name, formRef);
  };

  const clearFields = () => {
    formRef.current.reset();
    formRef.current.setFieldValue("company", "");
    formRef.current.setFieldValue("document", "");
    setShowExport(false);
    setData([]);
    setFiltered(false);
  };

  const columns = useMemo(
    () => [
      {
        name: intl.formatMessage({ id: "type" }),
        selector: (row) => row.type,
        allowOverflow: true,
        wrap: true,
        minWidth: "140px",
        sortable: true,
      },
      {
        name: intl.formatMessage({ id: "company_name" }),
        selector: (row) => row.accountName,
        allowOverflow: true,
        wrap: true,
        minWidth: "155px",
        sortable: true,
      },
      {
        name: intl.formatMessage({ id: "document" }),
        selector: (row) => row.document,
        allowOverflow: true,
        wrap: true,
        minWidth: "155px",
        sortable: true,
      },
      {
        name: intl.formatMessage({ id: "state_inscription" }),
        selector: (row) => row.stateInscription,
        allowOverflow: true,
        wrap: true,
        maxWidth: "150px",
        sortable: true,
      },
      {
        name: intl.formatMessage({ id: "city_inscription" }),
        selector: (row) => row.municipalInscription,
        allowOverflow: true,
        wrap: true,
        maxWidth: "150px",
        sortable: true,
      },
      {
        name: intl.formatMessage({ id: "taxes" }),
        selector: (row) => intl.formatMessage({ id: row.taxClassification }),
        allowOverflow: true,
        wrap: true,
        minWidth: "160px",
        sortable: true,
      },
      {
        name: intl.formatMessage({ id: "country" }),
        selector: (row) => row.country,
        allowOverflow: true,
        wrap: true,
        minWidth: "130px",
        sortable: true,
      },
      {
        name: intl.formatMessage({ id: "avenue" }),
        selector: (row) => row.street,
        allowOverflow: true,
        wrap: true,
        minWidth: "130px",
        sortable: true,
      },
      {
        name: intl.formatMessage({ id: "address_number" }),
        selector: (row) => row.number,
        allowOverflow: true,
        wrap: true,
        minWidth: "130px",
        sortable: true,
      },
      {
        name: intl.formatMessage({ id: "zip_code" }),
        selector: (row) => row.zipCode,
        allowOverflow: true,
        wrap: true,
        minWidth: "130px",
        sortable: true,
      },
      {
        name: intl.formatMessage({ id: "district" }),
        selector: (row) => row.district,
        allowOverflow: true,
        wrap: true,
        minWidth: "130px",
        sortable: true,
      },
      {
        name: intl.formatMessage({ id: "city" }),
        selector: (row) => row.city,
        allowOverflow: true,
        wrap: true,
        minWidth: "130px",
        sortable: true,
      },
      {
        name: intl.formatMessage({ id: "state" }),
        selector: (row) => row.state,
        allowOverflow: true,
        wrap: true,
        minWidth: "130px",
        sortable: true,
      },
      {
        name: intl.formatMessage({ id: "phone" }),
        selector: (row) => row.telephone,
        allowOverflow: true,
        wrap: true,
        minWidth: "140px",
        sortable: true,
      },
      {
        name: intl.formatMessage({ id: "mobile" }),
        selector: (row) => row.mobile,
        allowOverflow: true,
        wrap: true,
        minWidth: "140px",
        sortable: true,
      },
      {
        name: intl.formatMessage({ id: "provision_types" }),
        cell: (row) => row?.provisionTypes?.map((type) => {
          return provisionTypesArray.filter((pt) => pt.value === type)[0]?.label
        }).join(", "),
        wrap: true,
        maxWidth: "160px",
        style:
          "text-overflow: ellipsis; word-wrap: break-word; overflow: hidden; overflow-y: auto; max-height: 10.0em; display: -webkit-box;",
      },
    ],
    [intl, provisionTypesArray]
  );

  return (
    <AnimatedMainContainer
      breadcrumb={intl.formatMessage({
        id: "breadcumb.service_providers_report",
      })}
    >
      <Container>
        <FormContainer>
          <Form ref={formRef} onSubmit={handleSubmit}>
            <TitleContainer>
              <Title>
                <FormattedMessage id="export_service_providers_report" />
              </Title>
              <GoBackContainer>
                <ActionContainer>
                  <BadgeContainer>
                    <LinkButton onClick={() => history.push("/dashboard")}>
                      <Badge
                        color="#002244"
                        text={intl.formatMessage({ id: "goback" })}
                        fontSize="12px"
                      />
                    </LinkButton>
                  </BadgeContainer>
                </ActionContainer>
              </GoBackContainer>
            </TitleContainer>
            <FieldsContainer>
              <Horizontal>
                <InputWithLabel
                  name="company"
                  validationBlur={validationBlur}
                  label={intl.formatMessage({ id: "company_name" })}
                />
                <MaskedInputWithLabel
                  name="document"
                  validationBlur={validationBlur}
                  maskChar={null}
                  mask={documentMask}
                  onChange={setDocumentMask}
                  label={intl.formatMessage({ id: "document_cnpj_or_passport" })}

                />
              </Horizontal>
              <Horizontal>
                <FilterActionsContainer>
                  <SubmitContainer>
                    <FormButton
                      color="#C9C9C9"
                      type="button"
                      onClick={clearFields}
                    >
                      <FormattedMessage id="clear_fields" />
                    </FormButton>
                  </SubmitContainer>
                  <SubmitContainer>
                    <FormButton type="submit" loading={loading}>
                      <FormattedMessage id="search" />
                    </FormButton>
                  </SubmitContainer>
                  {showExport ? (
                    <SubmitContainer>
                      <FormButton type="button" loading={loading}>
                        <Report
                          filename={`SERVICE_PROVIDERS_REPORT`}
                          data={data}
                          intl={intl}
                          provisionTypesArray={provisionTypesArray}
                        >
                          <FormattedMessage id="export" />
                        </Report>
                      </FormButton>
                    </SubmitContainer>
                  ) : null}
                </FilterActionsContainer>
              </Horizontal>
            </FieldsContainer>
          </Form>
        </FormContainer>
      </Container>
      {filtered ? (
        <Table
          columns={columns}
          data={data}
          title={intl.formatMessage({ id: "results" })}
          hasPagination
        />
      ) : (
        <NotificationContainer>
          <FormattedMessage id="need_to_filter_first" />
        </NotificationContainer>
      )}
    </AnimatedMainContainer>
  );
};

export default ManageServiceProvidersReport;
