import React from "react";

import { Content, SubTitle } from "../styles";

function TermsTextPT() {
  return (
    <Content>
      <SubTitle>Termos & Condições</SubTitle>

      <p>
        Esta página (juntamente com quaisquer outros documentos citados sobre
        ele) informa os termos de uso ("Termos") sobre o qual você pode fazer
        uso do nosso site https://portal.informamarkets.com.br (nosso "Site").
        Por favor, leia estes Termos cuidadosamente antes de começar a usar o
        nosso Site. Ao usar nosso Site, você indica que aceita esses Termos e
        que você concorda em cumpri-los. Se você não concordar com estes Termos,
        por favor, abstente-se de usar o nosso Site imediatamente.
      </p>
      <p>
        Podemos revisar estes Termos a qualquer momento através da alteração
        desta página. Espera-se que você verifique esta página de tempos em
        tempos para tomar conhecimento de quaisquer alterações que fazemos, já
        que elas são obrigatórias em você. Algumas das disposições contidas
        nestes Termos também podem ser suplantadas por provisões ou avisos
        publicados em outro lugar do nosso Site.
      </p>
      <p>
        <h3>Informações sobre nós</h3>
      </p>
      <p>
        O nosso site é operado pela Informa Group plc ("nós", "nós" ou "nossa").
        Esta é uma parte da Informa PLC, registrada no Reino Unido sob a empresa
        número 8860726. O nosso endereço de escritório registrado é 5 Howick
        Place, Londres, SW1P 1WG, UK. All Informa PLC UK trading subsidiárias
        estão registadas para IVA no âmbito de um grupo de IVA do Reino Unido:
        GB365462636
      </p>
      <p>
        <h3>Acessando nosso site</h3>
      </p>
      <p>
        O acesso ao nosso Site é fornecido em caráter temporário e reservamo-nos
        o direito de retirar o acesso ao nosso Site ou emendar o serviço que
        fornecemos em nosso Site sem aviso prévio. Não seremos responsáveis se
        por qualquer motivo nosso Site estiver indisponível a qualquer momento
        ou para qualquer período.
      </p>
      <p>
        Você é responsável por fazer todos os arranjos necessários para que você
        tenha acesso ao nosso Site. Você também é responsável por garantir que
        todas as pessoas que acessam nosso Site através de sua conexão à
        internet estejam cientes destes Termos, e que eles cumpram com eles.
        Você concorda em não danificar, interferir ou interromper o acesso ao
        Site ou fazer qualquer coisa que possa interromper ou prejudicar sua
        funcionalidade.
      </p>
      <p>
        Qualquer coisa em nosso Site pode estar desatualizada em qualquer
        momento, e não estamos sob nenhuma obrigação de atualizá-lo. Buscamos
        garantir que as informações publicadas em nosso Site sejam precisas
        quando postadas, mas não podemos ser responsabilizado por sua exatidão
        ou atemporalidade e podemos alterar as informações a qualquer momento
        sem aviso prévio. Você não deve confiar em informações no Site e
        reconhece que deve tomar as providências adequadas para verificar essas
        informações antes de agir sobre ele.
      </p>
      <p>
        <h3>Monitoramento</h3>
      </p>
      <p>
        Reservamo-nos o direito de monitorar e acompanhar suas visitas ao Site.
      </p>
      <p>
        <h3>Propriedade intelectual</h3>
      </p>
      <p>
        Nós somos o proprietário ou o licenciado de todos os direitos autorais,
        marcas comerciais, direitos de design, direitos de base de dados,
        informações confidenciais ou quaisquer outros direitos de propriedade
        intelectual (juntos a Propriedade Intelectual) em nosso Site. A
        Propriedade Intelectual em nosso Site é protegida por direitos autorais
        e outras leis e tratados de propriedade intelectual em todo o mundo.
        Todos esses direitos são reservados.
      </p>
      <p>
        <h3>Licença</h3>
      </p>
      <p>
        É permitido imprimir e fazer o download de extratos do Site para uso
        próprio na seguinte base:
      </p>
      <p>
        (a)
        <span>
          nenhum documento ou gráficos relacionados no Site são modificados de
          qualquer forma;
        </span>
      </p>
      <p>
        (b)
        <span>
          nenhum gráfico no Site é utilizado separadamente do texto
          correspondente; e
        </span>
      </p>
      <p>
        (c)
        <span>
          os nossos avisos de direitos autorais e de marca comercial e este
          aviso de permissão aparecem em todas as cópias.
        </span>
      </p>
      <p>
        Salvo disposição em contrário, os direitos autorais e outros direitos de
        propriedade intelectual em todo o material no Site (inclusive sem
        fotografias de limitação e imagens gráficas) são de propriedade de nós
        ou de nossos licenciadores. Para efeitos destes Termos, é proibida
        qualquer utilização de extractos do Site que não de acordo com esta
        licença para qualquer finalidade. Se você violar qualquer um dos termos
        neste aviso legal, sua permissão para usar o Site terminará
        automaticamente e você deve destruir imediatamente quaisquer extratos
        baixados ou impressos do Site.
      </p>
      <p>
        Sujeito a esta licença, nenhuma parte do Site poderá ser reproduzida ou
        armazenada em qualquer outro website ou incluído em qualquer sistema ou
        serviço de recuperação eletrónica público ou privado sem a nossa prévia
        permissão por escrito. Quaisquer direitos não expressamente concedidos
        nestes termos são reservados. Você concorda em não se adaptar, alterar
        ou criar um trabalho derivativo a partir de qualquer um dos materiais
        contidos neste Site ou usá-lo para qualquer outra finalidade do que para
        uso pessoal, não comercial.
      </p>
      <p>
        <h3>Disclaimer</h3>
      </p>
      <p>
        Enquanto nos esforçamos para garantir que as informações sobre o Site
        estão corretas, não exaltamos a exatidão e a integralidade do material
        no Site. Nós podemos fazer alterações no material no Site, a qualquer
        momento sem aviso prévio. O material no Site pode estar desatualizado, e
        não assumimos nenhum compromisso de atualizar esse material.
      </p>
      <p>
        <h3>Termos Implícitos</h3>
      </p>
      <p>
        As disposições expressas destes Termos estão no lugar de todas as
        garantias, condições, termos, empresas e obrigações implicadas por
        estatuto, direito comum, uso comercial, curso de tratamento ou de outra
        forma, todos excluídos na medida máxima permitida por lei.
      </p>
      <p>
        <h3>Responsabilidade</h3>
      </p>
      <p>
        O material no Site é fornecido "como está", sem quaisquer condições,
        garantias ou outros termos de qualquer natureza.
      </p>
      <p>
        Nós, qualquer outra parte (se envolvemos ou não em criar, produzir,
        manter ou entregar o Site), e qualquer uma de nossas empresas de grupo e
        os oficiais, diretores, funcionários, acionistas ou agentes de qualquer
        deles, excluem qualquer responsabilidade e responsabilidade por qualquer
        quantia ou tipo de perda ou dano que possa resultar em você ou em um
        terceiro (incluindo sem limitação, qualquer perda ou danos diretos,
        indiretos ou consequentes, ou qualquer perda de renda, lucros, boa
        vontade, dados, contratos, uso de dinheiro ou perda ou danos decorrentes
        ou conectados de qualquer forma a interrupção de negócios, e seja em
        torto (incluindo sem negligência), contrato ou de outra forma) em
        conexão com o Site de qualquer forma ou em conexão com o uso,
        incapacidade de usar ou os resultados de uso do Site, quaisquer websites
        vinculados ao Site ou o material em tais websites, incluindo mas não
        limitado a perda ou dano devido a vírus que possam infectar seus
        equipamentos de computador, software, dados ou outro imóvel por conta de
        seu acesso, uso ou navegação do Site ou seu download de qualquer
        material do Site ou de quaisquer websites vinculados ao Site. Nada
        nestes Termos deve excluir ou limitar a nossa responsabilidade por:
      </p>
      <p>
        (a)
        <span>
          morte ou lesão corporal causada por negligência (uma vez que tal termo
          é definido pelo Unfair Contrato Terms Act 1977); ou
        </span>
      </p>
      <p>
        (b) <span>fraude.</span>
      </p>
      <p>
        <h3>Investidores</h3>
      </p>
      <p>
        As informações contidas em nosso Site (incluindo, sem limitação, as
        informações financeiras relativas a nós ou ao nosso grupo corporativo)
        não é um convite para investir em ações ou outros valores mobiliários,
        ou quaisquer outros produtos ou serviços ou, de outra forma, tratam
        destes ou firam um contrato com a gente ou qualquer outra empresa. As
        informações fornecidas não devem ser invocadas em relação a qualquer
        decisão de investimento. Se precisar de aconselhamento, consulte com um
        consultor financeiro profissional.
      </p>
      <p>
        O desempenho passado de nós ou de qualquer outra empresa referida no
        Site não pode ser invocado como um guia para o seu desempenho futuro. O
        preço das ações e a renda derivada deles podem ir para baixo, assim como
        para cima e os investidores podem não recuperar o montante originalmente
        investido.
      </p>
      <p>
        Nosso Site contém certas declarações prospectivas que não são resultados
        financeiros informados nem outras informações históricas. Como essas
        declarações prospectivas estão sujeitas a suposições, riscos e
        incertezas, os resultados futuros reais podem diferir materialmente dos
        expressos em ou implícitos por tais declarações. Muitas dessas
        suposições, riscos e incertezas se relacionam com fatores que estão além
        de nossa capacidade de controlar ou estimar precisamente, tais como
        atrasos na obtenção ou condições adversas contidas nas aprovações
        regulatórias, concorrência e reestruturação da indústria, mudanças nas
        condições econômicas, flutuações cambiais, mudanças nas taxas de juros e
        impostos, mudanças nas leis, regulamentos ou políticas regulatórias,
        desenvolvimentos em doutrinas de políticas legais ou públicas,
        desenvolvimentos tecnológicos, disponibilidade de novas oportunidades de
        aquisição ou o momento e sucesso de futuras oportunidades de aquisição.
        Essas declarações prospectivas falam apenas a partir da data de sua
        publicação no Site. Nós não assumimos nenhuma obrigação de liberar
        publicamente quaisquer revisões a estas declarações prospectivas para
        refletir eventos ou circunstâncias após a data de sua publicação no
        Site.
      </p>
      <p>
        <h3>Jurisdição e direito aplicável</h3>
      </p>
      <p>
        Os tribunais ingleses terão jurisdição não exclusiva sobre qualquer
        reclamação decorrente, ou relacionada com, uma visita ao nosso Site,
        embora conservemos o direito de trazer processos contra você por
        violação destes Termos em seu país de residência ou de qualquer outro
        país relevante.
      </p>
      <p>
        Estes Termos e qualquer litígio ou reivindicação decorrente ou em
        conexão com eles ou sua matéria ou formação de sujeito ou de formação
        (incluindo, sem limitação, disputas ou reivindicações não contratuais)
        serão regidos e interpretados de acordo com as leis da Inglaterra e do
        País de Gales.
      </p>
      <p>Obrigado por visitar nosso Site.</p>
      <a href="https://www.informa.com/privacy-policy/" target="_blank">
        Políticas de privacidade
      </a>
    </Content>
  );
}

export default TermsTextPT;
