import React, { createContext, useContext, useCallback, useState } from "react";
import { v4 } from "uuid";

import ToastContainer from "../components/ToastContainer";
import { useLoading } from "./loading";

const ToastContext = createContext({});

const ToastProvider = ({ children }) => {
  const { isAccountLoading } = useLoading();
  const [messages, setMessages] = useState([]);

  const addToast = useCallback(
    ({ title, type, description, time = 5000 }) => {
      if (isAccountLoading) return;
      const id = v4();

      const toast = {
        id,
        title,
        type,
        description,
        time,
      };

      setMessages((oldMessages) => {
        return oldMessages.reduce(
          (arr, item) => {
            if (item.description !== description) {
              arr.push(item);
            }
            return arr;
          },
          [toast]
        );
      });
    },
    [isAccountLoading]
  );

  const removeToast = useCallback((id) => {
    setMessages((currentState) => currentState.filter((msg) => msg.id !== id));
  }, []);

  return (
    <ToastContext.Provider value={{ addToast, removeToast }}>
      {children}
      <ToastContainer messages={messages} />
    </ToastContext.Provider>
  );
};

const useToast = () => {
  const context = useContext(ToastContext);

  if (!context) {
    throw new Error("useToast must be used within a ToastProvider");
  }

  return context;
};

export { ToastProvider, useToast };
