import { createStore, applyMiddleware } from "redux";

import createSagaMiddleware from "redux-saga";

import { composeWithDevTools } from "redux-devtools-extension";
import rootReducer from "./modules/rootReducer";
import rootSaga from "./modules/rootSaga";

import api from "../service/index";

const sagaMiddleware = createSagaMiddleware();

const middlewares = [sagaMiddleware];

if (process.env.NODE_ENV === `development`) {
  const { logger } = require(`redux-logger`);

  middlewares.push(logger);
}

const store = createStore(
  rootReducer,
  composeWithDevTools(applyMiddleware(...middlewares))
);

sagaMiddleware.run(rootSaga);

store.subscribe(() => {
  const { token } = store.getState().auth;

  if (token) {
    api.defaults.headers.common.Authorization = `Bearer ${token}`;
  }
});

export default store;
