import React from "react";
import { Container, Message } from "./style";

import { useIntl } from "react-intl";

const NotFound = () => {
  const intl = useIntl();

  return (
    <Container>
      <Message>{intl.formatMessage({ id: "not_found.one" })}</Message>
      <Message>{intl.formatMessage({ id: "not_found.two" })}</Message>
    </Container>
  );
};

export default NotFound;
