import React, { useState } from "react";
import { useIntl } from "react-intl";

import {
  Container,
  CustomSelect,
  Content,
  InputSearch,
  NotFound,
} from "./styles";

const Select = ({
  options,
  label,
  rules,
  name,
  margin,
  isDropdownSelect = true,
  optionFilterProp = "label",
  ...props
}) => {
  const intl = useIntl();

  const [search, setSearch] = useState("");

  const handleSetValueSearch = (value) => {
    setTimeout(() => {
      setSearch(value);
    }, 300);
  };

  return (
    <Container name={name} rules={rules} label={label} $margin={margin}>
      <CustomSelect
        options={options}
        $margin={margin}
        searchValue={search}
        optionFilterProp={optionFilterProp}
        $isDropdownSelect={isDropdownSelect}
        notFoundContent={
          <NotFound>{intl.formatMessage({ id: "not_found" })}</NotFound>
        }
        dropdownRender={(menu) => (
          <>
            {(options?.length > 0 && isDropdownSelect)
                && (
              <Content>
                <InputSearch
                  name="search-table"
                  type="seach"
                  placeholder={intl.formatMessage({ id: "search" })}
                  onChange={({ target }) => handleSetValueSearch(target.value)}
                />
              </Content>
            )}
            {menu}
          </>
        )}
        {...props}
      />
    </Container>
  );
};

export default Select;
