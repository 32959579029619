import React from "react";
import ReactExport from "react-data-export";

const File = ReactExport.ExcelFile;
const Sheet = ReactExport.ExcelFile.ExcelSheet;
const Column = ReactExport.ExcelFile.ExcelColumn;

const Report = ({ data, filename, children }) => (
  <File element={children} filename={filename} fileExtension="xlsx">
    <Sheet data={data} name="Relatório">
      <Column label="cnpj" value="accountCode" />
      <Column label="razao_social" value="accountName" />
      <Column label="nome_fantasia" value="accountAlias" />
      <Column label="estande" value="booth" />
      <Column label="area" value="area" />
      <Column label="contrato" value="opportunity" />
      <Column label="telefone_conta" value="phone" />
      <Column label="email_login" value="mail" />
      <Column label="responsavel_operacional" value="contact" />
      <Column label="email_operacional" value="contactMail" />
      <Column label="telefone_operacional" value="contactPhone" />
      <Column label="vendedor" value="salesman" />
      <Column label="tipo_usuario" value="userType" />
    </Sheet>
  </File>
);

export default Report;
