import styled from 'styled-components'

export const Container = styled.div`
  height: 6vh;
  width: 100vw;
  background-color: #002244;
  color: white;
  position: fixed;
  bottom: 0;
  right: 0;
  padding: 20px 30px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  a:link, a:visited, a:hover, a:active {
    cursor: pointer;
    text-decoration: none;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    gap: 7px;
  }

  div {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
  }
`

export const PhoneLabel = styled.div`
  margin-right: 30px;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  gap: 7px;

  p {
    color: white;
    font-family: 'Inter';
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.28px;
    margin: 0;
  }
`

export const InformaLabel = styled.label`
  font-family: 'Product Sans';
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal; 
`

export const Logo = styled.img`
  width: 20px;
  height: 20px;
  flex-shrink: 0;
`