import React, { useEffect, useRef } from "react";
import { useField } from "@unform/core";

import { Input, Container } from "./styles";

const CheckboxInput = ({ name, options, values = [], ...rest }) => {
  const inputRefs = useRef([]);
  const { fieldName, registerField, defaultValue = [...values] } = useField(
    name
  );

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRefs.current,
      getValue: (refs) => {
        return refs.filter((ref) => ref?.checked).map((ref) => ref.value); // REFACTOR (FAZER RETORNAR O ID NA CHAVE AO INVÉS DO SEQUENCIAL)
      },
      clearValue: (refs) => {
        refs.forEach((ref) => {
          ref.checked = false;
        });
      },
      setValue: (refs, values) => {
        refs.forEach((ref) => {
          if (values.includes(ref.id)) {
            ref.checked = true;
          }
        });
      },
    });
  }, [defaultValue, fieldName, registerField]);
  return (
    <Container>
      {options.map((option, index) => {
        if (option?.id)
          return (
            <label
              htmlFor={option.id}
              key={option.id}
              style={{
                textDecoration: `${option?.disabled ? "line-through" : "none"}`,
              }}
            >
              <Input
                defaultChecked={defaultValue.find((dv) => dv === option.id)}
                ref={(ref) => {
                  inputRefs.current[index] = ref;
                }}
                value={option.value}
                type="checkbox"
                id={option.id}
                disabled={option.disabled}
                {...rest}
              />
              {option.label}
            </label>
          );
      })}
    </Container>
  );
};
export default CheckboxInput;
