import React, { useCallback } from "react";
import { useSelector } from "react-redux";
import { useIntl } from "react-intl";

import { Route as ReactDOMRoute, Redirect, useHistory } from "react-router-dom";
import Layout from "../../newUXComponents/Layout";
import LoadingOverlay from "../../components/LoadingOverlay";

import useNotification from "../../hooks/newUXNotification";

const NewUXRoute = ({
  isPrivate = false,
  component: Component,
  roles,
  code,
  ...rest
}) => {
  const { user, loading, permission } = useSelector((state) => state.auth);
  const history = useHistory();
  const notification = useNotification();
  const intl = useIntl();

  const havePermission = useCallback(() => {
    if (code === "ADMIN" || code === "NOT_FOUND" || !code) {
      return true;
    }

    if (code === "PROFILE_INFORMATION") {
      return (
        permission.includes("EDIT_PROFILE_INFORMATION") ||
        permission.includes("EDIT_EXHIBITOR_PROFILE_INFORMATION")
      );
    }

    return permission.includes(code);
  }, [code, permission]);

  if (history.location.pathname === "/logout" && user) {
    return (
      <ReactDOMRoute
        {...rest}
        render={() => {
          return <Component />;
        }}
      />
    );
  }

  if (loading) {
    return <div></div>;
  }

  if (user && !havePermission()) {
    notification.displayError(
      intl.formatMessage({
        id: "api.error.-1002",
      }),
      "error-permission"
    );

    return (
      <Redirect
        to={{
          pathname: "/checklist",
        }}
      />
    );
  }

  if (user && isPrivate && roles) {
    if (roles.includes(user.role.type)) {
      return (
        <ReactDOMRoute
          {...rest}
          render={() => {
            return <Component />;
          }}
        />
      );
    }
    return (
      <Redirect
        to={{
          pathname: "/",
        }}
      />
    );
  }

  return (
    <ReactDOMRoute
      {...rest}
      render={({ location }) => {
        /* isPrivate/user (is signed)
          true/true --> OK
          true/false --> Redirect to Logon
          false/true --> Redirect to Dashboard
          false/false --> OK
        */

        return isPrivate === !!user ? (
          isPrivate ? (
            <LoadingOverlay>
              <Layout>
                <Component />
              </Layout>
            </LoadingOverlay>
          ) : (
            <LoadingOverlay>
              <Component />
            </LoadingOverlay>
          )
        ) : (
          <Redirect
            to={{
              pathname: isPrivate ? "/" : "/checklist",
              state: { from: location },
            }}
          />
        );
      }}
    />
  );
};

export default NewUXRoute;
