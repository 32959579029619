import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Form } from "antd";
import { useIntl } from "react-intl";
import { useHistory } from "react-router-dom";

import useNotification from "../../hooks/newUXNotification";

import { useLoading } from "../../hooks/loading";

import { useDispatch, useSelector } from "react-redux";

import {
  getEvents,
  getAccountsByEventAndTypeUser,
  changeAdminStaffSignIn,
  handleReturnToUser,
} from "../../redux/modules/impersonate/actions";
import { authSuccess } from "../../redux/modules/auth/actions";
import { cleanQuataCredential } from "../../redux/modules/credentials/actions";
import { changeApplicationLanguage } from "../../redux/modules/language/actions";
import { clearInitialSettingsState } from "../../redux/modules/initialSettings/actions";
import { clearChecklist } from "../../redux/modules/checklist/actions";
import { clearAccounts } from "../../redux/modules/account/actions";
import { clearProfileExhibitor } from "../../redux/modules/profile/actions";

import Button from "../../newUXComponents/Button";
import Select from "../../newUXComponents/Select";

import { Container, Title, Hr, Content, ContainerButton } from "./styles";

import { INITIAL_VALUES, validation } from "./validations";

const ImpersonateUser = () => {
  const intl = useIntl();
  const [form] = Form.useForm();
  const history = useHistory();
  const dispatch = useDispatch();
  const notification = useNotification();
  const { handleAccountLoading } = useLoading();

  const { adminToken, user, keepSignedIn } = useSelector((state) => state.auth);
  const { events } = useSelector((state) => state.impersonate);

  const [accountsOptions, setAccountsOptions] = useState([]);

  const typeUser = useMemo(
    () => [
      {
        label: intl.formatMessage({ id: "exhibitor" }),
        value: "EXHIBITOR",
      },
      {
        label: intl.formatMessage({ id: "service_provider" }),
        value: "SERVICE_PROVIDER",
      },
    ],
    [intl]
  );

  const callbackError = useCallback(
    (message) => {
      handleAccountLoading(false);
      if (message === -2025) {
        notification.displayInfo(
          intl.formatMessage({
            id: "api.error." + message,
          }),
          "info-impersonate"
        );
      } else {
        notification.displayError(
          intl.formatMessage({
            id: "api.error." + message,
          }),
          "error-impersonate"
        );
      }
    },
    [notification, intl, handleAccountLoading]
  );

  const callbackSetAccounts = useCallback(
    (data) => {
      handleAccountLoading(false);
      setAccountsOptions(data);
    },
    [handleAccountLoading]
  );

  const callbackSuccess = useCallback(
    (accountData) => {
      handleAccountLoading(false);
      dispatch(
        authSuccess({
          user: accountData.user,
          token: accountData.token,
          customerCenterToken: accountData.customerCenterToken,
          accountCreated: accountData.accountCreated,
          keepSignedIn,
          serviceProviderLoggingAsAnExhibitor:
            accountData.serviceProviderLoggingAsAnExhibitor,
          adminToken: accountData.adminToken,
        })
      );
      dispatch(
        changeApplicationLanguage({
          code: accountData.user.language,
        })
      );
      dispatch(clearInitialSettingsState());
      dispatch(clearChecklist());
      dispatch(clearAccounts());
      dispatch(clearProfileExhibitor());
      dispatch(cleanQuataCredential());

      if (accountData.adminToken) {
        history.push("/checklist");
      } else {
        history.push("/dashboard");
      }
    },
    [dispatch, handleAccountLoading, history, keepSignedIn]
  );

  const onFinish = (data) => {
    handleAccountLoading(true);

    dispatch(
      changeAdminStaffSignIn(
        {
          params: {
            systemUserId: data.account,
            loggedInUserId: user?.id,
          },
          adminToken,
        },
        callbackSuccess,
        callbackError
      )
    );
  };

  const handleGetAccounts = (value) => {
    handleAccountLoading(true);
    form.setFieldValue("account", "");
    const eventSelected = form.getFieldValue("event");

    dispatch(
      getAccountsByEventAndTypeUser(
        {
          params: {
            eventId: eventSelected,
            roleType: value,
            useEmailInTheServiceProviderLabel: false,
            useCancelledOpportunities: true,
          },
          adminToken,
        },
        callbackSetAccounts,
        callbackError
      )
    );
  };

  const returnToUser = () => {
    dispatch(
      handleReturnToUser(
        {
          params: {
            systemUserId: 0,
            loggedInUserId: user?.id,
          },
          adminToken,
        },
        callbackSuccess,
        callbackError
      )
    );
  };

  useEffect(() => {
    if (adminToken) {
      dispatch(getEvents(adminToken, callbackError));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [adminToken]);

  return (
    <Container>
      <Title>{intl.formatMessage({ id: "accounts_users" })}</Title>
      <Hr />
      <Content>
        <Form
          layout="vertical"
          form={form}
          name="impersonate-user-form"
          onFinish={onFinish}
          initialValues={INITIAL_VALUES}
        >
          <Select
            name="event"
            label={intl.formatMessage({ id: "event_edition" })}
            allowClear
            placeholder={intl.formatMessage({ id: "event_edition" })}
            rules={validation("event", intl)}
            options={events}
            onChange={() => {
              form.setFieldValue("type", "");
              form.setFieldValue("account", "");
            }}
          />
          <Select
            name="type"
            label={intl.formatMessage({ id: "type" })}
            allowClear
            placeholder={intl.formatMessage({ id: "type" })}
            rules={validation("type", intl)}
            options={typeUser}
            onChange={(value) => handleGetAccounts(value)}
          />

          <Select
            name="account"
            label={intl.formatMessage({ id: "account" })}
            allowClear
            placeholder={intl.formatMessage({ id: "account" })}
            rules={validation("account", intl)}
            options={accountsOptions}
            disabled={!accountsOptions.length}
          />

          <ContainerButton>
            <Button
              type="link"
              label={intl.formatMessage({ id: "return_to_user" })}
              onClick={() => returnToUser()}
            />
            <Button
              type="primary"
              label={intl.formatMessage({ id: "sidebar.impersonate_user" })}
              htmlType="submit"
            />
          </ContainerButton>
        </Form>
      </Content>
    </Container>
  );
};

export default ImpersonateUser;
