import styled, { css } from "styled-components";

export const Container = styled.div`
  display: flex;
 
  width: 390px;
  padding: 32px 24px;
  flex-direction: column;
  align-items: center;
  gap: 24px;
  
`;

export const Divider = styled.hr`
  width: 388px;
  background-color: #d4d4d4;
`;

export const Header = styled.div`
  color: #11a7d9;
  font-family: "Open Sans";
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 32px;
  letter-spacing: -0.48px;
`;

export const Content = styled.div`
  width: 100%;
`;

export const ContainerButton = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-items: center;
  justify-content: center;
  padding: 12px 0;
  gap: 6px;
  flex-shrink: 0;
`;

export const Button = styled.button`
  height: 30px;
  padding: 7.5px 12px;
  font-family: "Inter";
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 15px;
  flex-shrink: 0;
  cursor: pointer;

  ${(props) =>
    props.color
      ? css`
          background: ${props.color};
          color: #11a7d9;
          border: none;
        `
      : css`
          background: #11a7d9;
          border: solid 1px #11a7d9;
          color: #fff;
          box-shadow: 0px 0.75px 1.5px 0px rgba(16, 24, 40, 0.05);
          border-radius: 10px;
        `}
`;

export const Icon = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;
