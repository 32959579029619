export function getAllAccountsUser({ callBackMessage, intl }) {
  return {
    type: "GET_ACCOUNTS_SERVICE_PROVIDER",
    payload: {
      callbackError: callBackMessage,
      intl,
    },
  };
}

export function setAllAccountsUserSuccess(accounts) {
  return {
    type: "SET_ACCOUNTS_SERVICE_PROVIDER",
    payload: {
      accounts: accounts,
    },
  };
}

export function getAccountsExhibitor({ callBackMessage, intl }) {
  return {
    type: "GET_ACCOUNTS_EXHIBITOR",
    payload: {
      callbackError: callBackMessage,
      intl,
    },
  };
}

export function setAllAccountsExhibitorSuccess(accounts) {
  return {
    type: "SET_ACCOUNTS_EXHIBITOR",
    payload: {
      accounts: accounts,
    },
  };
}

export function changeAccounts(payload) {
  return {
    type: "CHANGE_ACCOUNT",
    payload: {
      accountId: payload.accountId,
      accountType: payload.accountType,
      keepSignedIn: payload.keepSignedIn,
      callbackSuccess: payload.callbackSuccess,
      callbackError: payload.callBackMessage,
    },
  };
}

export function clearAccounts() {
  return {
    type: "CLEAR_ACCOUNTS",
  };
}
