import React from "react";

import { Container, CustomBtn } from "./styles";

const Button = ({ label = "button", icon, type = "primary", ...props }) => {
  return (
    <Container>
      <CustomBtn type={type} icon={icon} {...props}>
        {label}
      </CustomBtn>
    </Container>
  );
};

export default Button;
