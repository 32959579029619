import React, { useMemo, useCallback } from "react";

import { useDispatch, useSelector } from "react-redux";

import { changeLanguagePreference } from "../../redux/modules/language/actions";

import Brazil from "../../newUXAssets/brazil- 1.png";
import Usa from "../../newUXAssets/usa 1.png";

import Check from "../../newUXAssets/icons/Check";

import { Container, DropdownCustom, ContainerFlag, Flag } from "./styles";

const DropdownFlags = () => {
  const dispatch = useDispatch();

  const { code } = useSelector((state) => state.language);
  const { token } = useSelector((state) => state.auth);

  const handleLanguageSelection = useCallback((language) => {
    dispatch(
      changeLanguagePreference({
        code: language,
        token: token,
      })
    );
  },[dispatch, token]);

  const items = useMemo(
    () => [
      {
        key: "1",
        style: {
          borderLeft: `${code === "pt" ? "solid 6.3px #11A7D9" : "solid 6.3px transparent"}`,
          borderBottom: "solid 1.2px #F2F2F5",
        },
        label: (
          <ContainerFlag>
            <span>
              <Flag src={Brazil} oppened />
              <span> (PT - BR) </span>
            </span>
            <span>{code === "pt" && <Check />}</span>
          </ContainerFlag>
        ),
        onClick: () => handleLanguageSelection("pt"),
      },
      {
        key: "2",
        style: {
          borderLeft: `${code === "en" ? "solid 6.3px #11A7D9" : "solid 6.3px transparent"}`,
        },
        label: (
          <ContainerFlag>
            <span>
              <Flag src={Usa} oppened />
              <span> (EN - US)</span>
            </span>
            <span>{code === "en" && <Check />}</span>
          </ContainerFlag>
        ),
        onClick: () => handleLanguageSelection("en"),
      },
    ],
    [code, handleLanguageSelection]
  );

  return (
    <Container>
      <DropdownCustom
        menu={{
          items,
        }}
        placement="bottom"
        arrow
      >
        <Flag src={code === "pt" ? Brazil : Usa} />
      </DropdownCustom>
    </Container>
  );
};

export default DropdownFlags;
