import styled, { css } from "styled-components";

export const Container = styled.div`
  background: transparent;
  border-radius: 5px;
  border: 1px solid #dadada;
  padding: 8px 16px;
  color: #dadada;
  display: flex;
  align-items: center;
  flex: 1;
  width: 100%;
  & + div {
    margin-top: 24px;
  }

  ${(props) =>
    props.isErrored &&
    css`
      border-color: #c53030;
    `}

  ${(props) =>
    props.isFocused &&
    css`
      color: #5ddb98;
      border-color: #5ddb98;
    `}
  
  ${(props) =>
    props.isFilled &&
    css`
      color: #5ddb98;
    `}

  ${(props) =>
    props.disabled &&
    css`
      opacity: 0.7;
      background-color: #f2f2f2;
    `}

  input {
    background: transparent;
    font-size: 14px;
    border: 0;
    flex: 1;
    color: #002244;
    padding: 8px;
    width: 100%;
    &::placeholder {
      color: #002244;
    }
    &:focus {
      border: 0;
      outline: none;
    }
  }
  svg {
    margin-right: 16px;
  }
`;

export const Error = styled.div`
  margin: 5px 0px !important;
  display: flex;
  font-size: 10px;
  align-items: center;
  justify-content: flex-start;
  color: #e00000;
  text-align: left;
  svg {
    margin-right: 4px;
    /* margin-left: 16px; */
    min-width: 32px;
  }
  span {
    background: #c53030;
    color: #fff;
    &::before {
      border-color: #c53030 transparent;
    }
  }

  ${(props) =>
    props.labelWidth && props.labelOrientation
      ? css`
          margin-left: ${props.labelWidth} !important;
          @media (max-width: 500px) {
            margin-left: 0px !important;
          }
        `
      : null}
`;

export const LabelContainer = styled.div`
  margin-right: 8px;
  margin-bottom: 8px;

  ${(props) =>
    props.labelWidth &&
    css`
      width: ${props.labelWidth};
      @media (max-width: 500px) {
        width: auto;
      }
    `}
`;

export const Label = styled.span`
  font-size: 12px;
  font-weight: 600;
  width: 100px;
  word-wrap: break-word;
`;

export const RootContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 16px;

  ${(props) =>
    props.labelOrientation === "horizontal" &&
    css`
      display: flex;
      flex: 1 1 auto;

      @media (max-width: 500px) {
        flex-direction: column;
        align-items: flex-start;
        & > div {
          width: 100%;
        }
      }
    `}
`;

export const InputRow = styled.div`
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  flex: 1 1 auto;
  @media (max-width: 500px) {
    flex-direction: column;
    align-items: flex-start;
  }
  ${(props) =>
    props.labelOrientation === "horizontal" &&
    css`
      flex-direction: row;
      align-items: center;
    `}
`;

export const AsteriskText = styled.span`
  color: #c53030;
`;
