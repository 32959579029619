import React, { useEffect, useState, useMemo } from "react";

import Select from "../Select";
import Input from "../Input";

import countriesPtEn from './combined.json'

import { useIntl } from "react-intl";

import { phoneMask } from "../../utils/phoneMask.js";

import { RootContainer, SelectContainer } from "./styles";

let brazilCountryCode = "BR";

export const getCountryCallingCode = (countryCode) =>{
  const response = countriesPtEn.find((country)=> country.code === countryCode).dial_code

  return response
}

export const getCountryFromTelephoneNumber = (number) => {
  if (typeof number === "string" && number) {
    const countryCode = number.split(' ')[0].substring(1, 10)
    const response = countriesPtEn.find((country)=> country.dial_code === countryCode)
    if(!response) return { value: "BR" }
    return { value: response.code }
  }

  return { value: "BR" };
};

export const removeCountryFromTelephoneNumber = (number) => {
  if(!number) return ''
   return number.replace(/^\+\d{1,4}\s*/, '');
};

export const getFullTelephoneNumber = (number, countryCode, defaultCountry = brazilCountryCode) => {
  if (!number) {
    return "";
  }
  if (!countryCode) {
    if (number.charAt(0) === "+") {
      return number;
    }

    //Flow due to problems in our select initial value in forms
    return "+" + getCountryCallingCode(defaultCountry) + " " + number;
  }

  if (number.charAt(0) === "+") {
    return number;
  }

  return "+" + getCountryCallingCode(countryCode) + " " + number;
};

const TelephoneInput = ({
  name = "",
  disabled,
  label,
  defaultCountryCode = brazilCountryCode,
  required = true,
  rules,
  refForm,
}) => {
  const intl = useIntl();

  const [countryList, setCountryList] = useState([]);
  const [countrySelectedValue, setCountrySelectedValue] = useState();

  const defaultValue = refForm.getFieldValue([`${name}_selected_country`]);

  const isPortuguese = useMemo(() => {
    return(intl.locale === "pt")}, [intl]);

  const handleCountrySelection = (countries, countryCode) => {
    const countryValue = countries.find(
      (country) => country.value === countryCode
    );
    setCountrySelectedValue(countryValue);
    refForm.setFieldsValue({ [`${name}_selected_country`]: countryValue });
  };

  useEffect(() => {
    const selectItems = countriesPtEn.map(country => (
      {
        label: (isPortuguese ? country.namePT : country.name) + " (+" + country.dial_code + ")",
        value: country.code
      }
    ))
    setCountryList(selectItems);

    if (defaultValue) {
      handleCountrySelection(selectItems, defaultValue.value ? defaultValue.value : defaultValue);
    } else {
      handleCountrySelection(selectItems, defaultCountryCode);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultCountryCode]);


  useEffect(()=>{
    if(!countryList) return
    
    if (defaultValue) {
      handleCountrySelection(countryList, defaultValue.value ? defaultValue.value : defaultValue);
    } else {
      handleCountrySelection(countryList, defaultCountryCode);
    }
  },[countryList, defaultValue])


  const handleChange = (value) => {
    refForm.setFieldsValue({ [name]: phoneMask(value) });
  };

  const handleCountryChanges = (countryCode) => {
    handleCountrySelection(countryList, countryCode);
    refForm.setFieldsValue({ [name]: "" });
  };

  return (
    <RootContainer>
      <SelectContainer>
        <Select
          name={`${name}_selected_country`}
          label={label}
          rules={[
            {
              required: required,
              message: "",
            },
          ]}
          placeholder={intl.formatMessage({ id: "country_code" })}
          disabled={disabled}
          options={countryList}
          value={countrySelectedValue}
          onChange={(event) => handleCountryChanges(event)}
          margin={true}
        />
        <Input
          name={name}
          label="&nbsp;"
          disabled={disabled}
          max="15"
          onChange={({ target }) => {
            handleChange(target.value);
          }}
          $isTelephone
          rules={!!rules[0] && [
            {
              require: false,
              message: rules[0].message,
              validator(_, value) {
                if (value < 1) {
                  return Promise.reject("Custom error message!");
                }
                return Promise.resolve();
              },
            },
          ]}
        />
      </SelectContainer>
    </RootContainer>
  );
};

export default TelephoneInput;
