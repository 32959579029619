export function getEvents(adminToken, callbackError) {
  return {
    type: "GET_EVENTS",
    payload: {
      adminToken,
      callbackError,
    },
  };
}

export function setEventsSuccess(events) {
  return {
    type: "SET_EVENTS",
    payload: {
      events,
    },
  };
}

export function getAccountsByEventAndTypeUser(
  data,
  callbackSuccess,
  callbackError
) {
  return {
    type: "GET_ACCOUNTS_BY_EVENT_AND_TYPE_USER",
    payload: {
      params: data.params,
      adminToken: data.adminToken,
      callbackSuccess,
      callbackError,
    },
  };
}

export function changeAdminStaffSignIn(data, callbackSuccess, callbackError) {
  return {
    type: "CHANGE_ADMIN_STAFF_SIGNIN",
    payload: {
      params: data.params,
      adminToken: data.adminToken,
      callbackSuccess,
      callbackError,
    },
  };
}

export function handleReturnToUser(data, callbackSuccess, callbackError) {
  return {
    type: "HANDLE_RETURN_TO_USER",
    payload: {
      params: data.params,
      adminToken: data.adminToken,
      callbackSuccess,
      callbackError,
    },
  };
}
