import React from "react";
import ReactExport from "react-data-export";

const File = ReactExport.ExcelFile;
const Sheet = ReactExport.ExcelFile.ExcelSheet;
const Column = ReactExport.ExcelFile.ExcelColumn;

const Report = ({ data, filename, children }) => (
  <File element={children} filename={filename} fileExtension="xlsx">
    <Sheet data={data} name="Relatório">
      <Column label="data" value="date" />
      <Column label="quantidade_expositores" value="totalOpportunities" />
      <Column label="acessos_portal" value="totalSignIns" />
      <Column label="porcentagem_acessos_portal" value="accessPercentage" />
      <Column label="vendas_ecommerce" value="totalEcommerceSalesValue" />
      <Column label="credenciais_registradas" value="totalCredentials" />
      <Column label="montadoras_associadas" value="totalRegisteredAssemblersProviders" />
    </Sheet>
  </File>
);

export default Report;
