import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  gap: 25px;
  margin-top: 20px;
`;

export const Title = styled.h1`
  color: #444444;
  font-family: "Inter";
  font-size: 30px;
  font-style: normal;
  font-weight: 700;
  line-height: 100%;
  padding-bottom: 15px;
`;

export const SubTitle = styled.h1`
  color: #11a7d9;
  font-family: "Open Sans";
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 150%;
  border-bottom: 1px solid #d4d4d4;
  padding-bottom: 5px;
`;

export const Hr = styled.hr`
  border-radius: 30px;
  background: #11a7d9;
  width: 128px;
  height: 2px;
  margin-bottom: 24px;
  margin-top: -11.5px;
`;

export const Content = styled.div`
  display: flex;
  align-items: flex-start;
  font-family: "Open Sans";
  gap: 120px;
  flex: 1 0 0;
`;

export const Information = styled.div`
  display: flex;
  width: 350px;
  flex-direction: column;
  align-items: flex-start;
`;

export const Label = styled.div`
  color: #444;
  font-size: 16px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
`;

export const Description = styled.div`
  color: #3a3a3c;
  font-family: "Inter";
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 170%;
  padding-top: 4.5px;
`;

export const BtnEdit = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100px;
`;
