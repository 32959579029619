import React from 'react'

const SmallFile = (props) => {
  const {
    size = '16',
    color = '#282828'
  } = props


  return (
    <svg width={size} height={size} viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M9.66602 1.8335H3.99935C3.64573 1.8335 3.30659 1.97397 3.05654 2.22402C2.80649 2.47407 2.66602 2.81321 2.66602 3.16683V13.8335C2.66602 14.1871 2.80649 14.5263 3.05654 14.7763C3.30659 15.0264 3.64573 15.1668 3.99935 15.1668H11.9993C12.353 15.1668 12.6921 15.0264 12.9422 14.7763C13.1922 14.5263 13.3327 14.1871 13.3327 13.8335V5.50016L9.66602 1.8335Z" stroke={color} strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M9.33203 1.8335V5.8335H13.332" stroke={color} strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
  )
}

export default SmallFile