import React, { useMemo } from "react";

import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";

import ViewInformationExhibitor from "./ViewInformationExhibitor";
import ViewInformationServiceProvider from "./ViewInformationServiceProvider";

import MyAccountHeader from "./Components/Header";

import { useIntl } from "react-intl";

import { Container, Title, Content } from "./styles";

const MyAccount = () => {
  const intl = useIntl();
  const history = useHistory();

  const { user } = useSelector((state) => state.auth);

  const typeUser = useMemo(() => user?.role?.type, [user]);

  const editUser = () => {
    if (typeUser === "EXHIBITOR") history.push("/user/update-user-exhibitor");
    else history.push("/user/update-user-service-provider");
  };

  return (
    <Container>
      <Title>
        {intl.formatMessage({
          id: "my_account",
        })}
      </Title>
      <MyAccountHeader isProfile />
      <Content>
        {typeUser === "EXHIBITOR" ? (
          <ViewInformationExhibitor onClickEdit={editUser} />
        ) : (
          <ViewInformationServiceProvider onClickEdit={editUser} />
        )}
      </Content>
    </Container>
  );
};

export default MyAccount;
