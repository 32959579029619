import React from "react";

const Star = (props) => {
  const { size = "17", color = "#444444", filled = false } = props;

  if (filled) {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={size}
        height={size}
        viewBox="0 0 17 16"
        fill="none"
      >
        <g clipPath="url(#clip0_295_45269)">
          <path
            d="M8.95853 1.80601L10.4104 5.26602C10.4456 5.34998 10.5029 5.42277 10.5764 5.47652C10.6499 5.53026 10.7366 5.56294 10.8273 5.57101L14.5435 5.89226C14.6421 5.90019 14.7362 5.93719 14.8137 5.99857C14.8913 6.05996 14.9489 6.14297 14.9793 6.23712C15.0097 6.33126 15.0115 6.4323 14.9844 6.52745C14.9573 6.62259 14.9027 6.70758 14.8273 6.77164L12.0079 9.23101C11.9394 9.29121 11.8884 9.36884 11.8605 9.45567C11.8325 9.54249 11.8286 9.63527 11.8492 9.72414L12.6942 13.3829C12.7165 13.4785 12.7102 13.5785 12.6761 13.6705C12.642 13.7625 12.5815 13.8424 12.5023 13.9003C12.4231 13.9582 12.3286 13.9916 12.2305 13.9961C12.1325 14.0007 12.0353 13.9763 11.951 13.926L8.75728 11.9885C8.67968 11.9413 8.59061 11.9164 8.49978 11.9164C8.40896 11.9164 8.31989 11.9413 8.24228 11.9885L5.04853 13.926C4.96427 13.9763 4.86706 14.0007 4.76903 13.9961C4.67101 13.9916 4.57649 13.9582 4.49726 13.9003C4.41803 13.8424 4.35759 13.7625 4.32348 13.6705C4.28936 13.5785 4.28308 13.4785 4.30541 13.3829L5.15041 9.72414C5.17095 9.63527 5.16704 9.54249 5.13908 9.45567C5.11113 9.36884 5.06019 9.29121 4.99166 9.23101L2.17228 6.77164C2.09691 6.70758 2.04223 6.62259 2.01517 6.52745C1.98811 6.4323 1.98988 6.33126 2.02025 6.23712C2.05063 6.14297 2.10825 6.05996 2.18582 5.99857C2.26339 5.93719 2.35743 5.90019 2.45603 5.89226L6.17228 5.57101C6.26295 5.56294 6.34969 5.53026 6.42316 5.47652C6.49662 5.42277 6.55402 5.34998 6.58916 5.26602L8.04103 1.80601C8.07981 1.71656 8.1439 1.64039 8.22541 1.58689C8.30691 1.53339 8.40229 1.50488 8.49978 1.50488C8.59728 1.50488 8.69266 1.53339 8.77416 1.58689C8.85567 1.64039 8.91976 1.71656 8.95853 1.80601Z"
            stroke="#444444"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </g>
        <defs>
          <clipPath id="clip0_295_45269">
            <rect
              width="16"
              height="16"
              fill="white"
              transform="translate(0.5)"
            />
          </clipPath>
        </defs>
      </svg>
    );
  }

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 17 16"
      fill="none"
    >
      <g clipPath="url(#clip0_295_45269)">
        <path
          d="M8.95853 1.80601L10.4104 5.26602C10.4456 5.34998 10.5029 5.42277 10.5764 5.47652C10.6499 5.53026 10.7366 5.56294 10.8273 5.57101L14.5435 5.89226C14.6421 5.90019 14.7362 5.93719 14.8137 5.99857C14.8913 6.05996 14.9489 6.14297 14.9793 6.23712C15.0097 6.33126 15.0115 6.4323 14.9844 6.52745C14.9573 6.62259 14.9027 6.70758 14.8273 6.77164L12.0079 9.23101C11.9394 9.29121 11.8884 9.36884 11.8605 9.45567C11.8325 9.54249 11.8286 9.63527 11.8492 9.72414L12.6942 13.3829C12.7165 13.4785 12.7102 13.5785 12.6761 13.6705C12.642 13.7625 12.5815 13.8424 12.5023 13.9003C12.4231 13.9582 12.3286 13.9916 12.2305 13.9961C12.1325 14.0007 12.0353 13.9763 11.951 13.926L8.75728 11.9885C8.67968 11.9413 8.59061 11.9164 8.49978 11.9164C8.40896 11.9164 8.31989 11.9413 8.24228 11.9885L5.04853 13.926C4.96427 13.9763 4.86706 14.0007 4.76903 13.9961C4.67101 13.9916 4.57649 13.9582 4.49726 13.9003C4.41803 13.8424 4.35759 13.7625 4.32348 13.6705C4.28936 13.5785 4.28308 13.4785 4.30541 13.3829L5.15041 9.72414C5.17095 9.63527 5.16704 9.54249 5.13908 9.45567C5.11113 9.36884 5.06019 9.29121 4.99166 9.23101L2.17228 6.77164C2.09691 6.70758 2.04223 6.62259 2.01517 6.52745C1.98811 6.4323 1.98988 6.33126 2.02025 6.23712C2.05063 6.14297 2.10825 6.05996 2.18582 5.99857C2.26339 5.93719 2.35743 5.90019 2.45603 5.89226L6.17228 5.57101C6.26295 5.56294 6.34969 5.53026 6.42316 5.47652C6.49662 5.42277 6.55402 5.34998 6.58916 5.26602L8.04103 1.80601C8.07981 1.71656 8.1439 1.64039 8.22541 1.58689C8.30691 1.53339 8.40229 1.50488 8.49978 1.50488C8.59728 1.50488 8.69266 1.53339 8.77416 1.58689C8.85567 1.64039 8.91976 1.71656 8.95853 1.80601Z"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_295_45269">
          <rect
            width={size}
            height={size}
            fill="white"
            transform="translate(0.5)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default Star;
