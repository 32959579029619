import React, { useEffect, useRef, useState, useMemo } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useSelector } from "react-redux";
import { useHistory } from "react-router";
import AnimatedMainContainer from "../../components/AnimatedMainContainer";
import { useToast } from "../../hooks/toast";
import * as Yup from "yup";
import getValidationErrors from "../../utils/getValidationErrors";
import api from "../../service";
import { useCallback } from "react";
import FormButton from "../../components/FormButton";
import { Form } from "@unform/web";
import { validateSingleFieldOnBlur } from "../../utils/formValidations";
import InputWithLabel from "../../components/InputWithLabel";

import {
  Container,
  FormContainer,
  TitleContainer,
  Title,
  FieldsContainer,
  Horizontal,
  FilterActionsContainer,
  SubmitContainer,
  GoBackContainer,
  ActionContainer,
  BadgeContainer,
  LinkButton,
  NotificationContainer,
  LongRequestAlertContainer,
} from "./styles";
import SelectWithLabel from "../../components/SelectWithLabel";
import Badge from "../../components/Badge";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import { useLoading } from "../../hooks/loading";
import Table from "../../components/Table";
import Report from "./report";
import opportunityStatusTypesConstants from "../../constants/opportunityStatusTypes";

const ManageDuplicatedProductsReport = () => {
  const { token } = useSelector((state) => state.auth);
  const formRef = useRef(null);
  const intl = useIntl();
  const history = useHistory();
  const { addToast } = useToast();
  const { handleLoading } = useLoading();
  const [loading, setLoading] = useState(false);
  const [events, setEvents] = useState([]);
  const [data, setData] = useState([]);
  const [showExport, setShowExport] = useState(false);
  const [filtered, setFiltered] = useState(false);
  const [getDuplicatedProductsReportTimer, setGetDuplicatedProductsReportTimer] = useState();

  const contractTypes = useMemo(
    () => [
      { value: 0, label: intl.formatMessage({ id: "all" }) },
      { value: 1, label: intl.formatMessage({ id: "valid" }) },
      { value: 2, label: intl.formatMessage({ id: "canceled" }) },
    ],
    [intl]
  );

  const opportunityStatusTypes = opportunityStatusTypesConstants();

  const schema = Yup.object().shape({
    event_list: Yup.array()
      .nullable()
      .min(1, intl.formatMessage({ id: "error.field_required" })),
    contract_type: Yup.string(),
    product_name: Yup.string().required(
      intl.formatMessage({ id: "error.field_required" })
    ),
  });

  const handleSubmit = useCallback(
    async (data) => {
      try {
        formRef.current?.setErrors({});

        setLoading(true);
        if (loading) {
          return;
        }

        await schema.validate(data, { abortEarly: false });

        api
          .patch(
            `/admin/manage-duplicated-products`,
            {
              eventIdList: data.event_list,
              contractType: data.contract_type ? data.contract_type : 0,
              productName: data.product_name,
            },
            {
              headers: {
                "Content-Type": "application/json",
                Accept: "*/*",
                Authorization: `Bearer ${token}`,
              },
            }
          )
          .then((result) => {
            const requestToken = result.data.token;
            verifyGetDuplicatedProductsReportAsyncStatus(requestToken);
          })
          .catch((err) => {
            setData([]);
            setShowExport(false);
            addToast({
              type: "error",
              title: intl.formatMessage({
                id: "error",
              }),
              description: intl.formatMessage({
                id: "api.error." + err.response.data.code,
              }),
            });
            setLoading(false);
            setFiltered(true);
          });
      } catch (err) {
        setLoading(false);
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);

          formRef.current?.setErrors(errors);

          return;
        }
      }
    },
    [addToast, intl, loading, schema, token]
  );

  const handleAsyncGetDuplicatedProductsReportReturn = (requestToken) => {
    api
      .get(`/admin/manage-duplicated-products/return/${requestToken}`, {
        headers: {
          "Content-Type": "application/json",
          Accept: "*/*",
          Authorization: `Bearer ${token}`,
        },
      })
      .then((result) => {
        const ACCEPTED = 202;

        if (result.status === ACCEPTED) {
          verifyGetDuplicatedProductsReportAsyncStatus(requestToken);
        } else {
          stopGetDuplicatedProductsReportCalls();
          setData(result.data.output);
          if (result.data.output) {
            setShowExport(true);
          }
        }
      })
      .catch((err) => {
        setData([]);
        setShowExport(false);
        addToast({
          type: "error",
          title: intl.formatMessage({
            id: "error",
          }),
          description: intl.formatMessage({
            id: "api.error." + err.response.data.code,
          }),
        });
        stopGetDuplicatedProductsReportCalls();
      });
  };

  const verifyGetDuplicatedProductsReportAsyncStatus = (requestToken) => {
    const timerRef = setTimeout(() => {
      handleAsyncGetDuplicatedProductsReportReturn(requestToken);
      setGetDuplicatedProductsReportTimer();
    }, 1000);
    setGetDuplicatedProductsReportTimer(timerRef);
  };

  const stopGetDuplicatedProductsReportCalls = () => {
    clearTimeout(getDuplicatedProductsReportTimer);
    setGetDuplicatedProductsReportTimer();
    setLoading(false);
    setFiltered(true);
  };

  useEffect(() => {
    handleLoading(true);
    api
      .get(`/admin/events`, {
        headers: {
          "Content-Type": "application/json",
          Accept: "*/*",
          Authorization: `Bearer ${token}`,
        },
      })
      .then((result) => {
        const events = result.data.output.map((event) => ({
          label: event.eventEditionName,
          value: event.idNumber
        }));
        let eventsSort = [...events].sort((a, b) =>
          a.label < b.label ? -1 : a.label > b.label ? 1 : 0
        );
        setEvents(eventsSort);
      })
      .catch((err) => {
        addToast({
          type: "error",
          title: intl.formatMessage({
            id: "error",
          }),
          description: intl.formatMessage({
            id: "api.error." + err.response.data.code,
          }),
        });
      })
      .finally(() => {
        handleLoading(false);
      });
  }, [addToast, intl, token, handleLoading]);

  const validationBlur = (name) => {
    let errors = formRef.current.getErrors();
    validateSingleFieldOnBlur(errors, schema, name, formRef);
  };

  const clearReportData = () => {
    setShowExport(false);
    setData([]);
    setFiltered(false);
  };

  const clearFields = () => {
    formRef.current.reset();
    formRef.current.setFieldValue("event_list");
    formRef.current.setFieldValue("contract_type", "");
    formRef.current.setFieldValue("product_name", "");
    clearReportData();
  };

  const columns = useMemo(
    () => [
      {
        name: intl.formatMessage({ id: "event" }),
        selector: (row) => row.event,
        allowOverflow: false,
        wrap: false,
        minWidth: "150px",
        maxWidth: "350px",
        sortable: true,
      },
      {
        name: intl.formatMessage({ id: "corporate_name" }),
        selector: (row) => row.accountName,
        allowOverflow: false,
        wrap: false,
        minWidth: "250px",
        maxWidth: "400px",
        sortable: true,
      },
      {
        name: intl.formatMessage({ id: "commercial_name" }),
        selector: (row) => row.accountAlias,
        minWidth: "200px",
        maxWidth: "300px",
        allowOverflow: false,
        wrap: false,
        sortable: true,
      },
      {
        name: intl.formatMessage({ id: "contract" }),
        selector: (row) => row.opportunity,
        allowOverflow: true,
        wrap: true,
        minWidth: "120px",
        maxWidth: "130px",
        sortable: true,
      },
      {
        name: intl.formatMessage({ id: "opportunity_status" }),
        selector: (row) => row.opportunityStatus,
        cell: (row) =>
          opportunityStatusTypes.filter((pt) => pt.value === row.opportunityStatus)[0]?.label,
        allowOverflow: true,
        wrap: true,
        minWidth: "130px",
      },
      {
        name: intl.formatMessage({ id: "order_number" }),
        selector: (row) => row.orderNumber,
        allowOverflow: true,
        wrap: true,
        minWidth: "140px",
        maxWidth: "140px",
      },
      {
        name: intl.formatMessage({ id: "salesforce_product_name_pt" }),
        selector: (row) => row.salesforceProductNamePT,
        allowOverflow: false,
        wrap: false,
        minWidth: "200px",
        maxWidth: "300px",
      },
      {
        name: intl.formatMessage({ id: "salesforce_product_name_en" }),
        selector: (row) => row.salesforceProductNameEN,
        allowOverflow: false,
        wrap: false,
        minWidth: "200px",
        maxWidth: "300px",
      },
      {
        name: intl.formatMessage({ id: "ecommerce_product_name_pt" }),
        selector: (row) => row.ecommerceProductNamePT,
        allowOverflow: false,
        wrap: false,
        minWidth: "200px",
        maxWidth: "300px",
      },
      {
        name: intl.formatMessage({ id: "ecommerce_product_name_en" }),
        selector: (row) => row.ecommerceProductNameEN,
        allowOverflow: false,
        wrap: false,
        minWidth: "200px",
        maxWidth: "300px",
      },
      {
        name: intl.formatMessage({ id: "user_who_have_bought" }),
        selector: (row) => row.clientName,
        allowOverflow: false,
        wrap: false,
        minWidth: "250px",
        sortable: true,
      },
    ],
    [intl, opportunityStatusTypes]
  );

  return (
    <AnimatedMainContainer
      breadcrumb={intl.formatMessage({ id: "breadcumb.duplicated_products_report" })}
    >
      <Container>
        <FormContainer>
          <Form ref={formRef} onSubmit={handleSubmit}>
            <TitleContainer>
              <Title>
                <FormattedMessage id="export_duplicated_products_report" />
              </Title>
              <GoBackContainer>
                <ActionContainer>
                  <BadgeContainer>
                    <LinkButton onClick={() => history.push("/dashboard")}>
                      <Badge
                        color="#002244"
                        text={intl.formatMessage({ id: "goback" })}
                        fontSize="12px"
                      />
                    </LinkButton>
                  </BadgeContainer>
                </ActionContainer>
              </GoBackContainer>
            </TitleContainer>
            <FieldsContainer>
              <Horizontal>
                <SelectWithLabel
                  name="event_list"
                  validationBlur={validationBlur}
                  label={intl.formatMessage({ id: "event_edition" })}
                  placeholder={intl.formatMessage({ id: "event_edition" })}
                  labelOrientation="vertical"
                  options={events}
                  onChange={() => clearReportData()}
                  clearable
                  isMulti
                  required
                />
                <SelectWithLabel
                  name="contract_type"
                  validationBlur={validationBlur}
                  label={intl.formatMessage({ id: "contract_type" })}
                  placeholder={intl.formatMessage({ id: "contract_type" })}
                  labelOrientation="vertical"
                  clearable
                  options={contractTypes}
                  onChange={() => clearReportData()}
                />
              </Horizontal>
              <Horizontal>
                <InputWithLabel
                  name="product_name"
                  label={intl.formatMessage({ id: "product_name" })}
                  placeholder={intl.formatMessage({id: "product_name"})}
                  required
                />
              </Horizontal>
              <Horizontal>
                <FilterActionsContainer>
                  <SubmitContainer>
                    <FormButton
                      color="#C9C9C9"
                      type="button"
                      onClick={clearFields}
                    >
                      <FormattedMessage id="clear_fields" />
                    </FormButton>
                  </SubmitContainer>
                  <SubmitContainer>
                    <FormButton type="submit" loading={loading}>
                      <FormattedMessage id="search" />
                    </FormButton>
                  </SubmitContainer>
                  {showExport ? (
                    <SubmitContainer>
                      <FormButton type="button" loading={loading}>
                        <Report
                          filename={`DUPLICATED_PRODUCTS_REPORT`}
                          data={data}
                          intl={intl}
                          opportunityStatusTypes={opportunityStatusTypes}
                        >
                          <FormattedMessage id="export" />
                        </Report>
                      </FormButton>
                    </SubmitContainer>
                  ) : null}
                </FilterActionsContainer>
              </Horizontal>
              {loading && (
                <LongRequestAlertContainer>
                  <FormattedMessage id="long_request_alert" />
                </LongRequestAlertContainer>
              )}
            </FieldsContainer>
          </Form>
        </FormContainer>
      </Container>
      {filtered ? (
        <Table
          columns={columns}
          data={data}
          title={intl.formatMessage({ id: "results" })}
          hasPagination
        />
      ) : (
        <NotificationContainer>
          <FormattedMessage id="need_to_filter_first" />
        </NotificationContainer>
      )}
    </AnimatedMainContainer>
  );
};

export default ManageDuplicatedProductsReport;
