import React, { useEffect, useMemo, useRef, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useSelector } from "react-redux";
import { useHistory } from "react-router";
import AnimatedMainContainer from "../../../components/AnimatedMainContainer";
import { useToast } from "../../../hooks/toast";
import * as Yup from "yup";
import getValidationErrors from "../../../utils/getValidationErrors";
import api from "../../../service";
import { useCallback } from "react";
import InputWithLabel from "../../../components/InputWithLabel";
import { Form } from "@unform/web";
import { validateSingleFieldOnBlur } from "../../../utils/formValidations";
import ConfirmationModal from "../../../components/ConfirmationModal";
import ModalWithCheckbox from "../../../components/ModalWithCheckbox";
import FormButton from "../../../components/FormButton";
import { format } from "date-fns";

import {
  Container,
  FormContainer,
  TitleContainer,
  Title,
  FieldsContainer,
  Horizontal,
  LinkButton,
  BadgeContainer,
  ActionContainer,
  SubmitContainer,
  FilterActionsContainer,
  ServiceProviderAssociationContainer,
} from "./styles";
import SelectWithLabel from "../../../components/SelectWithLabel";
import Table from "../../../components/Table";
import Badge from "../../../components/Badge";
import { useLoading } from "../../../hooks/loading";
import provisionTypesConstants from "../../../constants/provisionTypes";

const ServiceProviderAssociation = () => {
  const { token } = useSelector((state) => state.auth);
  const [allEvents, setAllEvents] = useState([]);
  const [data, setData] = useState([]);
  const [associationToDelete, setAssociationToDelete] = useState();
  const [associationToUpdate, setAssociationToUpdate] = useState();
  const [deleteAssociationModalOpen, setDeleteAssociationModalOpen] =
    useState(false);
  const [
    deleteAssociationWithCredentialsModalOpen,
    setDeleteAssociationWithCredentialsModalOpen,
  ] = useState(false);
  const [associationToUpdateModalOpen, setAssociationToUpdateModalOpen] =
    useState(false);
  const [exhibitors, setExhibitors] = useState([]);
  const [selectedExhibitor, setSelectedExhibitor] = useState();
  const [opportunities, setOpportunities] = useState([]);
  const [selectedEvent, setSelectedEvent] = useState();
  const formRef = useRef(null);
  const intl = useIntl();
  const history = useHistory();
  const { addToast } = useToast();
  const { handleLoading } = useLoading();
  const [loading, setLoading] = useState(false);
  const [filtered, setFiltered] = useState(false);
  const [submittedData, setSubmittedData] = useState();

  const provisionTypes = provisionTypesConstants().sort((a, b) => {
    if (a?.value === 0) return -1;
    if (b?.value === 0) return 1;
    return a.label.localeCompare(b.label);
  });

  const schema = Yup.object().shape({
    exhibitor: Yup.string()
      .nullable()
      .required(intl.formatMessage({ id: "error.field_required" })),
    event: Yup.string()
      .nullable()
      .required(intl.formatMessage({ id: "error.field_required" })),
    contract: Yup.string()
      .nullable()
      .required(intl.formatMessage({ id: "error.field_required" })),
    service_type: Yup.string()
      .nullable()
      .required(intl.formatMessage({ id: "error.field_required" })),
    service_provider: Yup.string().nullable(),
  });

  const handleSubmit = useCallback(
    async (data) => {
      try {
        formRef.current?.setErrors({});
        await schema.validate(data, { abortEarly: false });

        handleLoading(true);
        setLoading(true);
        if (loading) {
          return;
        }

        api
          .patch(
            "/admin/provider-association",
            {
              eventId: data.event,
              opportunityId: data.contract,
              serviceTypeId: data.service_type,
              serviceProviderName: data.service_provider,
              accountExhibitorId: data.exhibitor,
            },
            {
              headers: {
                "Content-Type": "application/json",
                Accept: "*/*",
                Authorization: `Bearer ${token}`,
              },
            }
          )
          .then((result) => {
            setData(result.data.output);
            setFiltered(true);
            setSubmittedData(data);
          })
          .catch((err) =>
            addToast({
              type: "error",
              title: intl.formatMessage({
                id: "error",
              }),
              description: intl.formatMessage({
                id: "api.error." + err.response.data.code,
              }),
              time: 7000,
            })
          )
          .finally(() => {
            setLoading(false);
            handleLoading(false);
          });
      } catch (err) {
        setLoading(false);
        handleLoading(false);
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);

          formRef.current?.setErrors(errors);

          return;
        }
      }
    },
    [schema, handleLoading, loading, token, addToast, intl]
  );

  const deleteAssociation = () => {
    try {
      handleLoading(true);
      api
        .delete(
          `/admin/provider-association/${associationToDelete.associationId}`,
          {
            params: {
              accountId: associationToDelete.accountId,
            },
            headers: {
              "Content-Type": "application/json",
              Accept: "*/*",
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((_) => {
          addToast({
            type: "success",
            title: intl.formatMessage({
              id: "success",
            }),
            description: intl.formatMessage({
              id: "success.delete_association",
            }),
          });
          handleSubmit(submittedData);
        })
        .catch((err) =>
          addToast({
            type: "error",
            title: intl.formatMessage({
              id: "error",
            }),
            description: intl.formatMessage({
              id: "api.error." + err.response.data.code,
            }),
          })
        )
        .finally(() => {
          setDeleteAssociationModalOpen(false);
          setDeleteAssociationWithCredentialsModalOpen(false);
          handleLoading(false);
        });
    } catch (err) {
      handleLoading(false);
      if (err instanceof Yup.ValidationError) {
        const errors = getValidationErrors(err);
        setDeleteAssociationModalOpen(false);
        setDeleteAssociationWithCredentialsModalOpen(false);

        formRef.current?.setErrors(errors);

        return;
      }
    }
  };

  const getEvents = useCallback(() => {
    handleLoading(true);
    api
      .patch(
        "/admin/provider-association/events",
        { accountExhibitorId: selectedExhibitor },
        {
          headers: {
            "Content-Type": "application/json",
            Accept: "*/*",
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((result) => {
        setAllEvents(
          result.data.output.map((x) => ({
            label: x.eventEditionName,
            value: x.idNumber,
          }))
        );
      })
      .catch((err) => {
        addToast({
          type: "error",
          title: intl.formatMessage({
            id: "error",
          }),
          description: intl.formatMessage({
            id: "api.error." + err.response.data.code,
          }),
        });
      })
      .finally(() => {
        handleLoading(false);
      });
  }, [addToast, intl, token, handleLoading, selectedExhibitor]);

  const getExhibitors = useCallback(() => {
    handleLoading(true);
    api
      .get("/admin/account", {
        headers: {
          "Content-Type": "application/json",
          Accept: "*/*",
          Authorization: `Bearer ${token}`,
        },
      })
      .then((result) => {
        setExhibitors(result.data.accountDropdownOutputList);
      })
      .catch((err) => {
        addToast({
          type: "error",
          title: intl.formatMessage({
            id: "error",
          }),
          description: intl.formatMessage({
            id: "api.error." + err.response.data.code,
          }),
        });
      })
      .finally(() => {
        handleLoading(false);
      });
  }, [addToast, intl, token, handleLoading]);

  const updatePermissionOfAssociation = useCallback(() => {
    try {
      handleLoading(true);
      api
        .put(
          `/admin/provider-association/${associationToUpdate.id}/update-manage-permission`,
          {
            allowManageAccount: !associationToUpdate.currentStatus,
            accountId: formRef.current.getFieldValue("exhibitor"),
          },
          {
            headers: {
              "Content-Type": "application/json",
              Accept: "*/*",
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((_) => {
          addToast({
            type: "success",
            title: intl.formatMessage({
              id: "success",
            }),
            description: intl.formatMessage({
              id: "success.update_association_permission",
            }),
          });
          formRef.current.submitForm();
        })
        .catch((err) => {
          addToast({
            type: "error",
            title: intl.formatMessage({
              id: "error",
            }),
            description: intl.formatMessage({
              id: "api.error." + err.response.data.code,
            }),
          });
          formRef.current.submitForm();
        })
        .finally(() => {
          handleLoading(false);
          setAssociationToUpdateModalOpen(false);
        });
    } catch (err) {
      handleLoading(false);
      if (err instanceof Yup.ValidationError) {
        const errors = getValidationErrors(err);
        setAssociationToUpdateModalOpen(false);

        formRef.current?.setErrors(errors);

        return;
      }
    }
  }, [addToast, associationToUpdate, handleLoading, intl, token]);

  const getOpportunities = useCallback(
    (event) => {
      handleLoading(true);
      api
        .patch(
          `/admin/provider-association/opportunities/${event}`,
          { accountExhibitorId: selectedExhibitor },
          {
            headers: {
              "Content-Type": "application/json",
              Accept: "*/*",
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((result) => {
          setOpportunities(
            result.data.output.map((x) => ({ label: x.name, value: x.id }))
          );
        })
        .catch((err) => {
          addToast({
            type: "error",
            title: intl.formatMessage({
              id: "error",
            }),
            description: intl.formatMessage({
              id: "api.error." + err.response.data.code,
            }),
          });
        })
        .finally(() => {
          handleLoading(false);
        });
    },
    [addToast, intl, token, handleLoading, selectedExhibitor]
  );

  useEffect(() => {
    getExhibitors();
  }, [getExhibitors]);

  useEffect(() => {
    formRef?.current?.setFieldValue("event");
    formRef?.current?.setFieldValue("contract");
    if (selectedExhibitor) {
      getEvents();
    }
  }, [getEvents, selectedExhibitor]);

  useEffect(() => {
    formRef?.current?.setFieldValue("contract");
    if (selectedEvent) {
      getOpportunities(selectedEvent);
    }
  }, [getOpportunities, selectedEvent]);

  const validationBlur = (name) => {
    let errors = formRef.current.getErrors();

    validateSingleFieldOnBlur(errors, schema, name, formRef);
  };

  const openDeleteModal = (
    associationId,
    accountId,
    hasAssociatedCredentials,
    hasAnotherAssociationWithTheSameServiceProvider
  ) => {
    setAssociationToDelete({
      accountId,
      associationId,
    });
    if (
      hasAssociatedCredentials &&
      !hasAnotherAssociationWithTheSameServiceProvider
    ) {
      setDeleteAssociationWithCredentialsModalOpen(true);
    } else {
      setDeleteAssociationModalOpen(true);
    }
  };

  const openUpdatePermissionModal = (associationId, allowManageAccount) => {
    setAssociationToUpdate({
      id: associationId,
      currentStatus: allowManageAccount,
    });
    setAssociationToUpdateModalOpen(true);
  };

  const clearFields = () => {
    formRef.current.reset();
    formRef.current.setFieldValue("event");
    formRef.current.setFieldValue("contract");
    formRef.current.setFieldValue("service_type");
    formRef.current.setFieldValue("exhibitor");
    setFiltered(false);
  };

  const columns = useMemo(
    () => [
      {
        name: intl.formatMessage({ id: "exhibitor" }),
        selector: (row) => row.exhibitorName,
        sortable: true,
        allowOverflow: true,
        wrap: true,
        minWidth: "210px",
      },
      {
        name: intl.formatMessage({ id: "event_edition" }),
        selector: (row) => row.event,
        sortable: true,
        allowOverflow: true,
        wrap: true,
        minWidth: "210px",
      },
      {
        name: intl.formatMessage({ id: "contract" }),
        selector: (row) => row.opportunity,
        sortable: true,
        allowOverflow: true,
        wrap: true,
        maxWidth: "100px",
      },
      {
        name: intl.formatMessage({ id: "type" }),
        selector: (row) => row.type,
        cell: (row) =>
          provisionTypes.filter((pt) => pt.value === row.type)[0].label,
      },
      {
        name: intl.formatMessage({ id: "service_provider" }),
        selector: (row) => row.serviceProviderName,
        sortable: true,
      },
      {
        name: intl.formatMessage({ id: "document" }),
        selector: (row) => row.document,
        sortable: true,
      },
      {
        name: intl.formatMessage({ id: "allow_login" }),
        cell: (row) =>
          row.allowManageAccount
            ? intl.formatMessage({ id: "yes" })
            : intl.formatMessage({ id: "no" }),
        sortable: true,
      },
      {
        name: intl.formatMessage({ id: "association_date" }),
        cell: (row) =>
          row.creationDate && format(new Date(row.creationDate), "dd/MM/yyyy"),
      },
      {
        name: intl.formatMessage({ id: "action" }),
        cell: (row) => (
          <>
            <Badge
              onClick={() => {
                openUpdatePermissionModal(row.id, row.allowManageAccount);
              }}
              color="#002244"
              text={intl.formatMessage({ id: "change_login_permission" })}
            />
            <Badge
              onClick={() =>
                openDeleteModal(
                  row.id,
                  row.exhibitorId,
                  row.hasAssociatedCredentials,
                  row.hasAnotherAssociationWithTheSameServiceProvider
                )
              }
              color="#cc0000"
              text={intl.formatMessage({ id: "delete" })}
              fontSize="12px"
              style={{ margin: "0px 4px" }}
            />
          </>
        ),
        minWidth: "410px",
      },
    ],
    [intl, provisionTypes]
  );

  return (
    <>
      <AnimatedMainContainer
        breadcrumb={intl.formatMessage({ id: "breadcrumb.association" })}
      >
        <Container>
          <FormContainer>
            <TitleContainer>
              <Title>
                <FormattedMessage id="search_for_association" />
              </Title>
              <ActionContainer>
                <LinkButton onClick={() => history.push("/dashboard")}>
                  <Badge
                    color="#C9C9C9"
                    text={intl.formatMessage({ id: "goback" })}
                    fontSize="12px"
                  />
                </LinkButton>
                <BadgeContainer
                  onClick={() =>
                    history.push("/admin/serviceprovider-association/new")
                  }
                >
                  <Badge
                    color="#002244"
                    text={intl.formatMessage({ id: "create_association" })}
                    fontSize="12px"
                  />
                </BadgeContainer>
              </ActionContainer>
            </TitleContainer>
            <FieldsContainer>
              <Form
                ref={formRef}
                onSubmit={handleSubmit}
                initialData={{
                  type: {
                    label: intl.formatMessage({ id: "all" }),
                    value: "ALL",
                  },
                  status: {
                    label: intl.formatMessage({ id: "both" }),
                    value: null,
                  },
                }}
              >
                <Horizontal>
                  <SelectWithLabel
                    name="exhibitor"
                    options={exhibitors}
                    validationBlur={validationBlur}
                    label={intl.formatMessage({ id: "exhibitor" })}
                    placeholder={intl.formatMessage({ id: "exhibitor" })}
                    onChange={(e) => setSelectedExhibitor(e?.value)}
                    required
                  />

                  <SelectWithLabel
                    name="event"
                    options={allEvents}
                    validationBlur={validationBlur}
                    label={intl.formatMessage({ id: "event_edition" })}
                    placeholder={intl.formatMessage({ id: "event_edition" })}
                    onChange={(e) => setSelectedEvent(e?.value)}
                    required
                  />

                  <SelectWithLabel
                    name="contract"
                    options={opportunities}
                    validationBlur={validationBlur}
                    label={intl.formatMessage({ id: "contract" })}
                    placeholder={intl.formatMessage({ id: "contract" })}
                    required
                  />
                </Horizontal>
                <Horizontal>
                  <SelectWithLabel
                    name="service_type"
                    options={provisionTypes}
                    validationBlur={validationBlur}
                    label={intl.formatMessage({ id: "service_type" })}
                    placeholder={intl.formatMessage({ id: "service_type" })}
                    required
                  />
                  <InputWithLabel
                    name="service_provider"
                    validationBlur={validationBlur}
                    label={intl.formatMessage({ id: "service_provider" })}
                  />
                </Horizontal>
                <Horizontal>
                  <FilterActionsContainer>
                    <SubmitContainer>
                      <FormButton
                        color="#C9C9C9"
                        type="button"
                        onClick={clearFields}
                      >
                        <FormattedMessage id="clear_fields" />
                      </FormButton>
                    </SubmitContainer>
                    <SubmitContainer>
                      <FormButton type="submit" loading={loading}>
                        <FormattedMessage id="search" />
                      </FormButton>
                    </SubmitContainer>
                  </FilterActionsContainer>
                </Horizontal>
              </Form>
            </FieldsContainer>
          </FormContainer>
        </Container>
        {filtered ? (
          <>
            <Table
              columns={columns}
              data={data}
              title={intl.formatMessage({ id: "results" })}
              hasPagination
            />
          </>
        ) : (
          <ServiceProviderAssociationContainer>
            <FormattedMessage id="need_to_filter_first" />
          </ServiceProviderAssociationContainer>
        )}
      </AnimatedMainContainer>
      <ConfirmationModal
        modalIsOpen={deleteAssociationModalOpen}
        setIsOpen={setDeleteAssociationModalOpen}
        confirmationFunction={deleteAssociation}
        description={intl.formatMessage({ id: "delete_association" })}
        confirmationText={intl.formatMessage({ id: "confirm" })}
        cancelText={intl.formatMessage({ id: "cancel" })}
      />
      <ConfirmationModal
        modalIsOpen={deleteAssociationWithCredentialsModalOpen}
        setIsOpen={setDeleteAssociationWithCredentialsModalOpen}
        confirmationFunction={deleteAssociation}
        description={intl.formatMessage({
          id: "delete_association_with_credentials",
        })}
        confirmationText={intl.formatMessage({ id: "yes" })}
        cancelText={intl.formatMessage({ id: "no" })}
      />
      <ModalWithCheckbox
        modalIsOpen={associationToUpdateModalOpen}
        setIsOpen={setAssociationToUpdateModalOpen}
        confirmationFunction={updatePermissionOfAssociation}
        description={intl.formatMessage({
          id: "associate_service_provider_modal_message",
        })}
        confirmationText={intl.formatMessage({ id: "confirm" })}
        cancelText={intl.formatMessage({ id: "cancel" })}
      />
    </>
  );
};

export default ServiceProviderAssociation;
