import React, { useEffect, useMemo, useRef, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useSelector } from "react-redux";
import { useHistory } from "react-router";
import AnimatedMainContainer from "../../components/AnimatedMainContainer";
import { useToast } from "../../hooks/toast";
import * as Yup from "yup";
import getValidationErrors from "../../utils/getValidationErrors";
import api from "../../service";
import { useCallback } from "react";
import InputWithLabel from "../../components/InputWithLabel";
import FormButton from "../../components/FormButton";
import { Form } from "@unform/web";
import { validateSingleFieldOnBlur } from "../../utils/formValidations";
import { format } from "date-fns";

import {
  Container,
  FormContainer,
  TitleContainer,
  Title,
  FieldsContainer,
  Horizontal,
  FilterActionsContainer,
  SubmitContainer,
  LinkButton,
  GoBackContainer,
} from "./styles";
import SelectWithLabel from "../../components/SelectWithLabel";
import DatePickerWithLabel from "../../components/DatePickerWithLabel";
import Table from "../../components/Table";
import Badge from "../../components/Badge";
import { isBefore, isEqual } from "date-fns";
import Loader from "react-loader-spinner";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import { useLoading } from "../../hooks/loading";

const Logs = () => {
  const { token } = useSelector((state) => state.auth);
  const [data, setData] = useState([]);
  const formRef = useRef(null);
  const intl = useIntl();
  const history = useHistory();
  const { addToast } = useToast();
  const { handleLoading } = useLoading();

  const [loading, setLoading] = useState(false);
  const [totalRows, setTotalRows] = useState(0);
  const [perPage, setPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [actions, setActions] = useState([]);
  const [key, setKey] = useState();

  const schema = Yup.object().shape({
    initial_date: Yup.string().nullable(),
    final_date: Yup.string()
      .nullable()
      .when("initial_date", {
        is: (initial_date) => initial_date,
        then: Yup.string()
          .nullable()
          .required(
            intl.formatMessage({
              id: "error.field_required",
            })
          ),
      })
      .test(
        "is-incorrect",
        intl.formatMessage({
          id: "error.initial_date_later",
        }),
        () => {
          const initial_date = formRef.current.getFieldValue("initial_date");
          const final_date = formRef.current.getFieldValue("final_date");

          if (initial_date && final_date) {
            return (
              isBefore(new Date(initial_date), new Date(final_date)) ||
              isEqual(new Date(initial_date), new Date(final_date))
            );
          }
          return true;
        }
      )
      .test(
        "is-incorrect2",
        intl.formatMessage({ id: "error.no_initial_date" }),
        () => {
          const initial_date = formRef.current.getFieldValue("initial_date");
          const final_date = formRef.current.getFieldValue("final_date");

          if (!initial_date && final_date) {
            return false;
          }

          return true;
        }
      ),
    type: Yup.string().nullable(),
    action: Yup.string().nullable(),
    name: Yup.string(),
  });

  const fetchData = useCallback(
    async (page, size = perPage) => {
      setLoading(true);
      api
        .patch(
          `/log?page=${page}&per_page=${size}`,
          {
            initialDate:
              formRef.current.getFieldValue("initial_date") &&
              new Date(formRef.current.getFieldValue("initial_date")),
            finalDate:
              formRef.current.getFieldValue("final_date") &&
              new Date(formRef.current.getFieldValue("final_date")),
            type: formRef.current.getFieldValue("type"),
            user: formRef.current.getFieldValue("name"),
            action: formRef.current.getFieldValue("action"),
            object: formRef.current.getFieldValue("object"),
          },
          {
            headers: {
              "Content-Type": "application/json",
              Accept: "*/*",
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((result) => {
          const finalData = [];

          result.data.logs.forEach((value) => {
            const date = new Date(value.date);

            finalData.push({
              ...value,
              date,
            });
          });
          setData(finalData);
          setTotalRows(result.data.paginationInfo.totalElements);
          setLoading(false);
        })
        .catch((err) => {
          addToast({
            type: "error",
            title: intl.formatMessage({
              id: "error",
            }),
            description: intl.formatMessage({
              id: "api.error." + err.response.data.code,
            }),
          });
        });
    },
    [addToast, intl, perPage, token]
  );

  useEffect(() => {
    fetchData(0);
    handleLoading(true);
    api
      .get(`/log/actions`, {
        headers: {
          "Content-Type": "application/json",
          Accept: "*/*",
          Authorization: `Bearer ${token}`,
        },
      })
      .then((result) => {
        let finalData = [];
        result.data.options.forEach((value) => {
          finalData.push({
            label: intl.formatMessage({ id: value.data }).replaceAll(":", ""),
            value: value.code,
          });
        });
        setActions(finalData);
      })
      .catch((err) => {
        addToast({
          type: "error",
          title: intl.formatMessage({
            id: "error",
          }),
          description: intl.formatMessage({
            id: "api.error." + err.response.data.code,
          }),
        });
      })
      .finally(() => {
        handleLoading(false);
      });
  }, [handleLoading, addToast, fetchData, intl, token]);

  const handleSubmit = useCallback(
    async (data) => {
      try {
        formRef.current?.setErrors({});

        await schema.validate(data, { abortEarly: false });

        setLoading(true);

        setCurrentPage(1);

        api
          .patch(
            `/log?page=0&per_page=${perPage}`,
            {
              initialDate:
                formRef.current.getFieldValue("initial_date") &&
                new Date(formRef.current.getFieldValue("initial_date")),
              finalDate:
                formRef.current.getFieldValue("final_date") &&
                new Date(formRef.current.getFieldValue("final_date")),
              type: formRef.current.getFieldValue("type"),
              user: formRef.current.getFieldValue("name"),
              action: formRef.current.getFieldValue("action"),
              object: formRef.current.getFieldValue("object"),
            },
            {
              headers: {
                "Content-Type": "application/json",
                Accept: "*/*",
                Authorization: `Bearer ${token}`,
              },
            }
          )
          .then((result) => {
            const finalData = [];
            result.data.logs.forEach((value) => {
              const date = new Date(value.date);

              finalData.push({
                ...value,
                date,
              });
            });
            setData(finalData);
            setTotalRows(result.data.paginationInfo.totalElements);
            setLoading(false);
          })
          .catch((err) => {
            setLoading(false);
            addToast({
              type: "error",
              title: intl.formatMessage({
                id: "error",
              }),
              description: intl.formatMessage({
                id: "api.error." + err.response.data.code,
              }),
            });
          });
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);

          formRef.current?.setErrors(errors);

          return;
        }
      }
    },
    [perPage, addToast, intl, schema, token]
  );

  const getAllResults = useCallback(
    async () => {
      setLoading(true);
      api
        .patch(
          `/log?page=0&per_page=${perPage}`,
          {
            initialDate:null,
            finalDate: null,
            type: 'ALL',
            user: '',
            action: 'ALL',
            object: formRef.current.getFieldValue("object"),
          },
          {
            headers: {
              "Content-Type": "application/json",
              Accept: "*/*",
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((result) => {
          const finalData = [];
          result.data.logs.forEach((value) => {
            const date = new Date(value.date);

            finalData.push({
              ...value,
              date,
            });
          });
          setData(finalData);
          setTotalRows(result.data.paginationInfo.totalElements);
          setLoading(false);
        })
        .catch((err) => {
          addToast({
            type: "error",
            title: intl.formatMessage({
              id: "error",
            }),
            description: intl.formatMessage({
              id: "api.error." + err.response.data.code,
            }),
          });
        });
    },
    [addToast, intl, token, perPage]
  );

  const validationBlur = (name) => {
    let errors = formRef.current.getErrors();
    validateSingleFieldOnBlur(errors, schema, name, formRef);
  };

  const handlePageChange = (page) => {
    fetchData(page - 1);
    setCurrentPage(page);
  };

  const handlePerRowsChange = async (newPerPage, page) => {
    fetchData(page - 1, newPerPage);
    setPerPage(newPerPage);
  };

  const columns = useMemo(
    () => [
      {
        name: intl.formatMessage({ id: "date" }),
        selector: (row) => row.date,
        sortable: true,
        format: (row) => format(row.date, "dd/MM/yyyy H:mm"),
        allowOverflow: true,
        wrap: false,
        minWidth: "115px",
      },
      {
        name: intl.formatMessage({ id: "type" }),
        selector: (row) => row.type,
        format: (row) =>
          intl.formatMessage({ id: `${row.type.toLowerCase()}` }),
        allowOverflow: true,
        wrap: false,
        minWidth: "115px",
      },
      {
        name: intl.formatMessage({ id: "profile" }),
        selector: (row) => row.role,
      },
      {
        name: intl.formatMessage({ id: "account" }),
        selector: (row) => row.nameAccount,
      },
      {
        name: intl.formatMessage({ id: "user" }),
        selector: (row) => row.userEmail,
        allowOverflow: true,
        wrap: true,
        minWidth: "250px",
      },
      {
        name: intl.formatMessage({ id: "action" }),
        selector: (row) => row.action,
        format: (row) =>
          intl.formatMessage({ id: row.action }) + " " + row.object,
        allowOverflow: true,
        wrap: true,
        minWidth: "250px",
      },
    ],
    [intl]
  );

  const clearFields = () => {
    formRef.current.reset();
    formRef.current.setFieldValue("type", {
      label: intl.formatMessage({ id: "all" }),
      value: "ALL",
    });
    formRef.current.setFieldValue("name", "");
    formRef.current.setFieldValue("action", {
      label: intl.formatMessage({ id: "all" }),
      value: "ALL",
    });
    setCurrentPage(1);
    getAllResults();
  };

  return (
    <AnimatedMainContainer
      breadcrumb={intl.formatMessage({ id: "breadcrumb.logs" })}
    >
      <Container>
        <FormContainer>
          <Form
            ref={formRef}
            onSubmit={handleSubmit}
            initialData={{
              type: {
                label: intl.formatMessage({ id: "all" }),
                value: "ALL",
              },
              action: {
                label: intl.formatMessage({ id: "all" }),
                value: "ALL",
              },
            }}
          >
            <TitleContainer>
              <Title>
                <FormattedMessage id="search_for_logs" />
              </Title>
              <GoBackContainer>
                <LinkButton onClick={() => history.push("/dashboard")}>
                  <Badge
                    color="#002244"
                    text={intl.formatMessage({ id: "goback" })}
                    fontSize="12px"
                  />
                </LinkButton>
              </GoBackContainer>
            </TitleContainer>
            <FieldsContainer>
              <Horizontal>
                <DatePickerWithLabel
                  name="initial_date"
                  validationBlur={validationBlur}
                  label={intl.formatMessage({ id: "initial_date" })}
                  validateEnd={() => validationBlur("final_date")}
                  labelOrientation="vertical"
                />
                <DatePickerWithLabel
                  name="final_date"
                  validationBlur={validationBlur}
                  label={intl.formatMessage({ id: "final_date" })}
                  labelOrientation="vertical"
                />

                <SelectWithLabel
                  name="type"
                  options={[
                    {
                      label: intl.formatMessage({ id: "all" }),
                      value: "ALL",
                    },
                    {
                      label: intl.formatMessage({ id: "administrator" }),
                      value: "ADMINISTRATOR",
                    },
                    {
                      label: intl.formatMessage({ id: "staff" }),
                      value: "STAFF",
                    },
                    {
                      label: intl.formatMessage({ id: "exhibitor" }),
                      value: "EXHIBITOR",
                    },
                    {
                      label: intl.formatMessage({ id: "service_provider" }),
                      value: "SERVICE_PROVIDER",
                    },
                    {
                      label: intl.formatMessage({ id: "integration" }),
                      value: "INTEGRATION",
                    },
                  ]}
                  validationBlur={validationBlur}
                  label={intl.formatMessage({ id: "type" })}
                />
                <InputWithLabel
                  name="name"
                  validationBlur={validationBlur}
                  label={intl.formatMessage({ id: "user" })}
                />
              </Horizontal>
              <Horizontal>
                <SelectWithLabel
                  name="action"
                  options={actions}
                  validationBlur={validationBlur}
                  label={intl.formatMessage({ id: "action" })}
                />
                <FilterActionsContainer>
                  <SubmitContainer>
                    <FormButton
                      color="#C9C9C9"
                      type="button"
                      onClick={clearFields}
                    >
                      <FormattedMessage id="clear_fields" />
                    </FormButton>
                  </SubmitContainer>
                  <SubmitContainer>
                    <FormButton type="submit" loading={loading}>
                      <FormattedMessage id="search" />
                    </FormButton>
                  </SubmitContainer>
                </FilterActionsContainer>
              </Horizontal>
            </FieldsContainer>
          </Form>
        </FormContainer>
      </Container>
      <Table
        columns={columns}
        data={data}
        title={intl.formatMessage({ id: "results" })}
        progressPending={loading}
        progressComponent={
          <Loader type="Oval" color="#00BFFF" height={100} width={100} />
        }
        paginationServer
        paginationTotalRows={totalRows}
        paginationDefaultPage={currentPage}
        onChangeRowsPerPage={handlePerRowsChange}
        onChangePage={handlePageChange}
      />
    </AnimatedMainContainer>
  );
};

export default Logs;
