import styled, { css } from "styled-components";

import { Button } from "antd";

export const Container = styled.div`
  width: 100%;
`;

export const CustomBtn = styled(Button)`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  width: 100%;
  padding: 10px 16px;
  border-radius: 10px;
  flex-shrink: 0;
  border: 1px solid #11a7d9;
  box-shadow: 0px 0.751px 1.503px 0px rgba(16, 24, 40, 0.05);

  ${(props) => {
    switch (props.type) {
      case "primary":
        return css`
          background: #11a7d9;
          color: #fff;
        `;
      case "link":
        return css`
          color: #11a7d9 !important;
        `;
      default:
        return css`
          color: #11a7d9;
        `;
    }
  }}

  ${(props) => {
    switch (props.disabled) {
      case true:
        return css`
          border: 1px solid #11a7d9 !important;
          box-shadow: 0px 0.751px 1.503px 0px rgba(16, 24, 40, 0.05) !important;
        `;

      default:
        return css`
          color: #fff;
        `;
    }
  }}


  :hover {
    border-color: #40a9ff !important;
  }

  svg {
    margin-right: 10px;
  }
`;
