import { Link } from "react-router-dom";
import styled, { css } from "styled-components";

export const Container = styled.div`
  width: 100%;
  padding: 16px;
  background-color: #fff;
`;

export const TableContainer = styled.div`
  padding-bottom: 16px;
`;

export const FormContainer = styled.div`
  form {
    display: flex;
    flex-direction: column;
  }
`;

export const Title = styled.h1`
  font-size: 14px;
  font-weight: normal;
`;

export const TitleContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;

  padding-bottom: 21px;
  padding-left: 8px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.16);
  margin-bottom: 16px;

  @media (max-width: 500px) {
    flex-direction: column;
    justify-content: flex-start;
  }
`;

export const FieldsContainer = styled.div`
  margin-top: 16px;
`;

export const Horizontal = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  & > div {
    display: flex;
    flex: 1 0 100px;
    margin-left: 16px;

    &:first-child {
      margin-left: 0px;
    }

    @media (max-width: 810px) {
      flex: 0 0 calc(50% - 16px);
      &:nth-child(3) {
        margin-left: 0px;
      }
    }

    @media (max-width: 730px) {
      margin-left: 0px;
      flex: 1 1 auto;
      width: 100%;
    }
  }
`;

export const FileActionsContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  font-size: 14px;
  flex-wrap: wrap;

  & > div:first-child {
    margin-left: 0px;
  }

  @media (max-width: 720px) {
    width: 100%;
  }

  & > div {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    margin-top: 16px;
    margin-left: 16px;
    @media (max-width: 720px) {
      margin-left: 0px;
    }
  }
`;

export const ClearButtonContainer = styled.div`
  margin-top: 10px;
  @media (max-width: 720px) {
    display: flex;
    margin-top: 0px;
    width: 100%;
  }
  
 

`;

export const LabelEndContainer = styled.div`
  margin-left: 8px;
  @media (max-width: 720px) {
    display: flex;
    margin-left: 0px;
    max-width: 100%;
  }
`;

export const TableTitleContainer = styled.div`
  padding: 16px 0px 16px 24px;
`;

export const TableTitle = styled.p``;

export const LinkButton = styled(Link)`
  text-decoration: none;
`;

export const CollapseToggle = styled.div`
  ${(props) =>
    props.oppened &&
    css`
      /* margin-bottom: 16px; */
    `}
  font-size: 14px;
  font-weight: normal;
  padding: 16px;
  display: flex;
  /* justify-content: space-between; */
  align-items: center;
  cursor: pointer;

  font-size: 13px;
`;

export const CollapseContainer = styled.div`
  border: 1px solid #ccc;
  padding: 8px;
  margin-bottom: 16px;
  & > .mandatory-table {
    margin-top: 0px;
  }
`;

export const Session = styled.div``;

export const SessionDivider = styled.div`
  display: flex;
  @media (max-width: 720px) {
    flex-direction: column;
  }
  ${(props) =>
    props.nr_items === "2" &&
    css`
      & > div {
        flex: 0.5;
        @media (max-width: 720px) {
          flex: 1 1 auto;
        }
      }
      & > div:nth-child(2) {
        margin-left: 32px;
        @media (max-width: 720px) {
          margin-left: 0px;
        }
      }
    `}
`;

export const SessionContainer = styled.div`
  border-bottom: 1px solid #dadada;
  display: flex;
  align-items: center;
  padding-bottom: 8px;
`;

export const SessionTitle = styled.h3`
  font-size: 14px;
  font-weight: bold;
  color: #002244;
  margin-left: 8px;
  text-transform: uppercase;
`;

export const SessionIconContainer = styled.div`
  display: flex;
`;

export const PeriodCategoryTitle = styled.p`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 25px;
  font-size: 12px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  color: #002244;

  @media (max-width: 720px) {
    margin-left: 0px;
    margin-bottom: 8px;
  }
`;

export const PeriodCategoryFieldsContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;
  flex: 1;

  & > div:last-child {
    margin-left: 16px;
  }

  & > div {
    margin-bottom: 0px;
  }

  @media (max-width: 500px) {
    & > div > div {
      flex: 1 1 auto;
    }
  }
`;

export const PeriodCategoryContainer = styled.div`
  display: flex;
  margin-bottom: 16px;
  @media (max-width: 1440px) {
    flex-direction: column;
    & > ${PeriodCategoryFieldsContainer} {
      justify-content: flex-start;

    }
  }

  @media (max-width: 900px) {
    flex-direction: column;
    & > ${PeriodCategoryFieldsContainer} {
      flex-wrap: wrap;
    }
  }

  & > .payment_limit > div {
    @media (max-width: 1440px) {
      margin-left: 0px;
    }
  }
`;

export const CredentialsContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const CredentialsTitle = styled.p`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-size: 12px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  color: #002244;
  margin-bottom: 8px;

  @media (max-width: 1440px) {
    margin-left: 16px;
  }

  @media (max-width: 720px) {
    margin-left: 0px;
    margin-bottom: 8px;
  }
`;

export const CredentialsFieldsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;

  & > div {
    margin-left: 16px;
    flex: 1;
  }

  & > div:first-child {
    margin-left: 0px;
  }

  @media (max-width: 1300px) {
    & > .interval {
      flex: 1 1 100%;
      margin-left: 0px;
      & > span {
        width: 100%;
      }
    }
  }
`;

export const InformationEditionRow = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;

  & > div {
    flex: 1;
    margin-left: 16px;
    @media (max-width: 1440px) {
      margin-left: 0px;
      &:nth-child(2) {
        margin-left: 16px;
      }
    }
  }

  & > .edition {
    margin-left: 0px;
  }

  &:first-child {
    margin-left: 0px;
  }
`;

export const InformationEditionContainer = styled.div`
  display: flex;
  @media (max-width: 1440px) {
    flex-direction: column;
  }
`;

export const DocumentsContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

export const DocumentsUploadContainer = styled.div`
  display: flex;
  flex: 1;
  flex-wrap: wrap;
  justify-content: center;
`;

export const DocumentUpload = styled.div`
  display: flex;
  flex: 0.2 1 100px;
  border: 1px solid #dadada;
  border-top: 8px solid #002244;
  border-radius: 8px;
  margin: 16px 0px 16px 0px;
  width: 100%;
  align-items: center;
  flex-direction: column;
  margin-left: 16px;

  &:first-child {
    margin-left: 0px;
  }

  @media (max-width: 500px) {
    margin-left: 0px;
    flex: auto;
  }

  padding: 0px 16px 0px 16px;
`;

export const DocumentDragAndDropContainer = styled.div`
  display: flex;
  padding: 8px;
  border: 1px dotted #525a5c;
  width: 100%;
  margin: 16px;
  margin-top: 8px;
  align-items: center;
  height: 100%;
`;

export const DocumentDragAndDropText = styled.span`
  font-size: 11px;
  font-weight: normal;
  color: #525a5c;
  margin-left: 8px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
`;

export const DocumentVisualization = styled.div`
  width: 100%;
  display: flex;
  height: 100%;
  flex-direction: column;
`;

export const DocumentAction = styled.div`
  flex: 0 0 30px;
  align-self: flex-end;
  justify-self: flex-end;

  svg {
    cursor: pointer;
  }
`;

export const DocumentOpenText = styled.span`
  flex: 1;
  display: flex;
  width: 100%;
  font-size: 16px;
  font-weight: normal;
  color: #525a5c;
  align-items: center;
  justify-content: center;
  text-align: center;
  cursor: pointer;
  a:link, a:visited {
    text-decoration: none;
    color: #525a5c;
  }
`;

export const DocumentOpenImage = styled.img`
  margin-left: 8px;
  justify-self: center;
  max-height: 100px;
  @media (max-width: 500px) {
    width: 100%;
  }
`;

export const ManageButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 16px;
`;

export const ActionContainer = styled.div`
  display: flex;
  flex-direction: row;

  div {
    margin-left: 16px;
  }
  @media (max-width: 500px) {
    flex-direction: column;
    div {
      margin-left: 0px;
    }
  }
`;

export const BadgeContainer = styled.div`
  cursor: pointer;
  @media (max-width: 500px) {
    margin-top: 16px;
  }
`;

export const VIPCategoryContainer = styled.div`
  display: flex;
  margin-bottom: 16px;

  & > div {
    margin-left: 16px;

    &:first-child {
      margin-left: 0px;
    }
  }

  @media (max-width: 1440px) {
    flex-direction: column;
    & > div {
      margin-left: 0;
    }
  }
`;

export const EditMetricsButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50px;
`
export const ModalSaveMetricsContainer = styled.div`
  width: 100%;
  display: flex;  
  justify-content: flex-end;
`




