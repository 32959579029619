export function getAllEvent() {
  return {
    type: "GET_ALL_EVENT",
  };
}

export function setAllEventSuccess(allEvent) {
  return {
    type: "SET_ALL_EVENT",
    payload: {
      allEvent: allEvent.event,
    },
  };
}

export function clearAllEvent() {
  return {
    type: "CLEAR_ALL_EVENT",
    payload: {},
  };
}