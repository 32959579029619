import styled from "styled-components";
import { Checkbox as AntdCheckbox, Button as AntdButton } from "antd";

export const Container = styled.div`
  width: 100%;
  height: 94vh;
  background: #fcfcfc;
  padding: 25px 75px 50px 70px;
  overflow-y: auto;

  ::-webkit-scrollbar {
    width: 4px;
    background-color: #fcfcfc;
  }

  ::-webkit-scrollbar-thumb {
    background: #11a7d9;
    -webkit-border-radius: 1ex;
    -webkit-box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.75);
  }

  ::-webkit-scrollbar-corner {
    background: #11a7d9;
  }
`;

export const Title = styled.h1`
  color: #11a7d9;
  font-family: "Inter";
  font-size: 30px;
  font-style: normal;
  font-weight: 700;
  line-height: 100%;
  border-bottom: 1px solid #d4d4d4;
  padding-bottom: 15px;
`;

export const Hr = styled.hr`
  border-radius: 30px;
  background: #11a7d9;
  width: 194px;
  height: 2px;
  margin-bottom: 24px;
  margin-top: -19.5px;
`;

export const Card = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: space-around;
`;

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  margin: 12px 0;
`;

export const Cell = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 40%;
  gap: 5px;
`;

export const Label = styled.label`
  color: #444;
  font-family: "Inter";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
`;

export const Text = styled.p`
  color: #3a3a3c;
  font-family: "Inter";
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  margin: 0;
`;

export const AlertText = styled.p`
  color: #0063f7;
  font-family: "Open Sans";
  font-size: 12px;
  font-style: italic;
  font-weight: 400;
  line-height: 20px;
`;

export const Button = styled(AntdButton)`
  display: flex;
  width: 215px;
  height: 40px;
  padding: 10px 16px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 10px;
  border: 1px solid #11a7d9;
  background: #11a7d9;
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  cursor: pointer;

  .disabled {
    color: rgba(58, 58, 60, 0.3);
  }

  :hover,
  :focus {
    background: #11a7d9;
  }
`;

export const ButtonLabel = styled.label`
  color: #fff;
  font-family: "Inter";
  font-size: 14px;
  font-style: normal;
  line-height: 20px;
  cursor: pointer;
`;

export const Checkbox = styled(AntdCheckbox)`
  margin-bottom: 20px;
  width: 700px;
`;
