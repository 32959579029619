import styled from 'styled-components'

export const Container = styled.div`
  width: 100%;
  height: 94vh;
  background: #fcfcfc;
  padding: 25px 75px 50px 70px;
  overflow-y: auto;

  ::-webkit-scrollbar {
    width: 4px;
    background-color: #fcfcfc;
  }

  ::-webkit-scrollbar-thumb {
    background: #11a7d9;
    -webkit-border-radius: 1ex;
    -webkit-box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.75);
  }

  ::-webkit-scrollbar-corner {
    background: #11a7d9;
  }
`;

export const Title = styled.h1`
  color: #444;
  font-family: "Inter";
  font-size: 30px;
  font-style: normal;
  font-weight: 700;
  line-height: 100%;
  padding-bottom: 15px;
`;

export const Button = styled.button`
  display: flex;
  min-width: 170px;
  height: 40px;
  padding: 10px 7.5px 10px 8px;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  border: 0.867px solid ${props => props.disabled ? "#C7C9D9" : "#11A7D9"};
  border-radius: 8px;
  background: ${props => props.disabled ? "#C7C9D9" : "#11A7D9"}; 
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  margin: 25px 0;
  cursor: ${props => props.disabled ? "not-allowed" : "pointer"}; 
`

export const SmallButton = styled(Button)`
  justify-content: space-around;
  min-width: 90px;
  height: 30px;
  margin: 0;
  padding: 10px 7px;
`

export const LabelButton = styled.label`
  color: #FFF;
  font-family: 'Inter';
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  cursor: ${props => props.disabled ? "not-allowed" : "pointer"};
`

export const ScheduleCard = styled.div`
  display: flex;
  width: 100%;
  padding: 24px 24px 28px 24px;
  flex-direction: column;
  align-items: flex-start;
  gap: 15px;
  border-radius: 12px;
  background: #FFF;
  box-shadow: 0px 4px 7px 0px rgba(0, 0, 0, 0.07);
`

export const AlertText = styled.p`
  color: #444;
  font-family: "Open Sans";
  font-size: 14px;
  font-style: italic;
  font-weight: 400;
  line-height: 20px;
  text-align: center;
  padding: 65px 0;
  width: 100%;
`

export const ScheduleCardHeader = styled.div`
  padding-bottom: 12px;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #444;
`

export const HeaderTitle = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  width: 140px;

  h3 {
    color: #444;
    font-family: "Open Sans";
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
    margin: 0;
  }
`

export const Label = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 8px;
  width: 100%;

  p {
    color: #282828;
    font-family: "Open Sans";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    margin: 0;
  }

  .event {
    color: #0063F7;
    font-weight: 600; 
  }
`

