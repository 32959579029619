import { createGlobalStyle } from "styled-components";

export default createGlobalStyle`

  * {
    margin: 0;
    padding: 0;
    outline: 0;
    box-sizing: border-box;
    
  }

  body {
    font-family: "Open Sans";
    -webkit-font-smoothing: antialiased !important;
    
  }

  @-moz-document url-prefix() {
    body {
      scrollbar-color: #11a7d9 white;
      scrollbar-width: thin;
    }
  }

  body::-webkit-scrollbar {
    width: 4px;
  }

  body html #root {
    height: 100%;
  }

  .ant-dropdown-menu {
    padding: 0 !important;
  }

  .ant-select-dropdown{
    width: 200px!important;
    padding: 0;
    z-index: 999999;
  }

  .ant-select-item {
    min-height: 52px;
    border-radius: 5px 5px 5px 0px;
    color:  #444;
    font-family: "Open Sans";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    padding: 16px 0px 16px 8px;
    border: 1px solid #EBEBF0;
    background: #FFF;
  }

  .ant-select-item-option-content {
    padding-left: 15px;
  }

  .ant-select-single.ant-select-open .ant-select-selection-item{
    color: #444444 !important;
  }

  .ant-menu{
      border-inline-end: none;
      overflow-y: auto;
      padding-bottom: 10px;
      ::-webkit-scrollbar {
        width: 4px;
        background-color: #fcfcfc;
      }

      ::-webkit-scrollbar-thumb {
        background: #11a7d9;
        -webkit-border-radius: 1ex;
        -webkit-box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.75);
      }

      ::-webkit-scrollbar-corner {
        background: #11a7d9;
      }
  }

  .ant-menu-item {
    font-family: 'Open Sans';
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
    height: 44px !important;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    margin: 0 5% !important;
    margin-top: 3px !important;
    width: 90% !important;

    @media (max-height: 870px) {
      height: 42px !important;
    }
  }

  .ant-menu-item:hover {
    border-radius: 10px; 
    cursor: pointer;
    background:  #FAFAFC !important;
    color: #444444 !important;
  }

  .ant-menu-item-active, .ant-menu-item-selected, .ant-menu-item-active:hover{
    border-radius: 10px;
    background: #11A7D9 !important;
    color: #FFFFFF !important;
    border-left: white !important;
    ::after {
      border-right: none!important;
    }
  }

  .ant-menu-title-content{
    padding-left: 15px;
  }

  .ant-notification-notice-icon{
    margin: 6px 0 0 4px;
  }

  .ant-notification{
    z-index: 9999999;
  }

  .ant-notification-notice-close {
    top: 5px;
    right: 7px;
  }
`;
