import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";

import { useHistory } from "react-router-dom";
import { useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { Tooltip } from "antd";

import { convertToDateOnly } from "../../utils/date";
import cnpjCpfFormatter from "../../utils/cnpjCpfFormatter";

import {
  getAllAssociateCompany,
  removeAssociationCompany,
  updateLoginPermission,
} from "../../redux/modules/associateCompany/actions";

import { DialogContext } from "../../hooks/dialog/context";
import useNotification from "../../hooks/newUXNotification";
import { useLoading } from "../../hooks/loading";

import { PlusOutlined } from "@ant-design/icons";

import Trash from "../../newUXAssets/icons/Trash";
import Key from "../../newUXAssets/icons/Key";

import Button from "../../newUXComponents/Button";
import Table from "../../newUXComponents/Table";
import ConfirmationModal from "../../newUXComponents/Modal/ConfirmationModal";

import provision_types from "../../constants/provisionTypes";

import ConfirmationAssociateCompany from "./Modal/ConfirmationAssociateCompany";
import MessageDeleteAssociateCompany from "./Modal/MessageDeleteAssociateCompany";

import {
  Container,
  Title,
  Hr,
  Content,
  ContainerBtn,
  Actions,
  BtnActions,
} from "./styles";

const AssociateCompany = () => {
  const intl = useIntl();
  const history = useHistory();
  const { createDialog } = useContext(DialogContext);
  const dispatch = useDispatch();
  const notification = useNotification();
  const optionsProvision = provision_types();
  const { handleLoading } = useLoading();

  const { associatedCompanies } = useSelector(
    (state) => state.associateCompany
  );
  const { contract, event } = useSelector((state) => state.initialSettings);

  const [tablePagination, setTablePagination] = useState({
    current: 0,
    defaultCurrent: 0,
    pageSize: 10,
    showQuickJumper: true,
    total: 0,
  });

  const callbackPaginationInfo = useCallback(
    (pagination) => {
      handleLoading(false);
      setTablePagination({
        current: pagination.pageNumber + 1,
        defaultCurrent: 1,
        pageSize: pagination.maxSize,
        showQuickJumper: true,
        total: pagination.totalElements,
      });
    },
    [handleLoading]
  );

  const callbackError = useCallback(
    (message) => {
      handleLoading(false);
      notification.displayError(
        intl.formatMessage({
          id: "api.error." + message,
        }),
        "get-credentials-associate-error"
      );
    },
    [notification, handleLoading, intl]
  );

  const handleGetInfoTable = useCallback(
    (page = 0, pageSize = 10, search = "") => {
      handleLoading(true);
      dispatch(
        getAllAssociateCompany(
          {
            page,
            per_page: pageSize,
            opportunity_id: contract.value,
            event_id: event.value,
            search,
          },
          callbackPaginationInfo,
          callbackError
        )
      );
    },
    [
      callbackError,
      callbackPaginationInfo,
      contract.value,
      dispatch,
      event.value,
      handleLoading,
    ]
  );

  const callbackEditPermission = useCallback(() => {
    notification.displaySuccess(
      intl.formatMessage({
        id: "success.update_association_permission",
      }),
      "update-associate-company-success"
    );
    handleGetInfoTable();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [notification, intl]);

  const handleEditPermission = useCallback(
    (data) => {
      createDialog({
        id: "modal-edit-permission",
        Component: ConfirmationAssociateCompany,
        props: {
          title: intl.formatMessage({ id: "change_login_permission" }),
          isRemovingPermission: data.allowManageAccount,
          confirmation: () => {
            handleLoading(true);
            dispatch(
              updateLoginPermission(
                {
                  id: data.id,
                  allowManageAccount: !data.allowManageAccount,
                },
                callbackEditPermission,
                callbackError
              )
            );
          },
        },
      });
    },
    [
      callbackEditPermission,
      callbackError,
      createDialog,
      dispatch,
      intl,
      handleLoading,
    ]
  );

  const callbackSuccessDelete = useCallback(() => {
    notification.displaySuccess(
      intl.formatMessage({
        id: "association_deleted_successfully",
      }),
      "remove-associate-company-success"
    );
    handleGetInfoTable();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [notification, intl]);

  const handleDeleteAssociateCompany = useCallback(
    (data) => {
      if (
        !data?.hasAnotherAssociationWithTheSameServiceProvider &&
        data?.hasAssociatedCredentials
      ) {
        createDialog({
          id: "modal-message-delete",
          Component: MessageDeleteAssociateCompany,
          props: {
            title: intl.formatMessage({ id: "delete_membership" }),
            message: intl.formatMessage({
              id: "error.delete_associate_comapany",
            }),
          },
        });

        return;
      }

      createDialog({
        id: "modal-associate-company",
        Component: ConfirmationModal,
        props: {
          title: intl.formatMessage({ id: "delete_membership" }),
          message: intl.formatMessage({
            id: "do_you_want_to_delete_the_association",
          }),
          confirmation: () => {
            handleLoading(true);
            dispatch(
              removeAssociationCompany(
                data.id,
                callbackSuccessDelete,
                callbackError
              )
            );
          },
        },
      });
    },
    [callbackError, callbackSuccessDelete, createDialog, dispatch, intl]
  );

  const columns = useMemo(
    () => [
      {
        title: intl.formatMessage({ id: "service_type" }),
        dataIndex: "type",
        key: "type",
        render: (text) => {
          if (text) {
            return optionsProvision.filter((pt) => pt.value === text)[0].label;
          }

          return " - ";
        },
      },
      {
        title: intl.formatMessage({ id: "associated.company_name" }),
        dataIndex: "serviceProviderName",
        key: "serviceProviderName",
      },
      {
        title: intl.formatMessage({ id: "document" }),
        dataIndex: "document",
        key: "document",
        render: (text) => {
          if (text.length > 9) {
            return cnpjCpfFormatter(text, "J");
          }

          return text;
        },
      },
      {
        title: intl.formatMessage({ id: "allow_login" }),
        dataIndex: "allowManageAccount",
        key: "allowManageAccount",
        align: "center",
        render: (text) => {
          if (text) {
            return intl.formatMessage({ id: "yes" });
          }

          return intl.formatMessage({ id: "no" });
        },
      },
      {
        title: intl.formatMessage({ id: "date_association" }),
        dataIndex: "creationDate",
        key: "creationDate",
        align: "center",
        render: (text) => {
          if (text) {
            return convertToDateOnly(text);
          }
          return " - ";
        },
      },
      {
        title: intl.formatMessage({ id: "actions" }),
        dataIndex: "actions",
        key: "actions",
        render: (_, record) => {
          return (
            <Actions>
              <Tooltip
                title={intl.formatMessage({
                  id: "tooltip.create_associate_company",
                })}>
                <BtnActions onClick={() => handleEditPermission(record)}>
                    <Key />
                </BtnActions>
              </Tooltip>
              <BtnActions
                onClick={() => handleDeleteAssociateCompany(record)}
                $isDelete
              >
                <Trash />
              </BtnActions>
            </Actions>
          );
        },
      },
    ],
    [intl, optionsProvision]
  );

  useEffect(() => {
    if (!contract.value || !event.value) {
      notification.displayInfo(
        intl.formatMessage({ id: "error.contract_not_selected" }),
        "event-not-selected-message"
      );
    } else {
      handleGetInfoTable();
      notification.close("event-not-selected-message");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contract.value, event.value]);

  return (
    <Container>
      <Title>{intl.formatMessage({ id: "service_provider" })}</Title>
      <Hr />
      {event.value && contract.value && (
        <>
          <ContainerBtn>
            <Button
              label={intl.formatMessage({ id: "associated_company" })}
              type="primary"
              icon={<PlusOutlined />}
              onClick={() => history.push("/associate-company/new")}
            />
          </ContainerBtn>

          <Content>
            <Table
              title={intl.formatMessage({ id: "associated_companies" })}
              dataSource={associatedCompanies}
              columns={columns}
              tablePagination={tablePagination}
              handleGetInfoTable={handleGetInfoTable}
            />
          </Content>
        </>
      )}
    </Container>
  );
};

export default AssociateCompany;
