import React from "react";

const SignOut = (props) => {
  const { size = "24", color = "#444444" } = props;

  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_632_4365)">
        <path
          d="M10.4998 20.25C10.4998 20.4489 10.4207 20.6397 10.2801 20.7803C10.1394 20.921 9.94869 21 9.74978 21H4.49995C4.10214 21 3.72062 20.842 3.43933 20.5607C3.15803 20.2794 3 19.8978 3 19.5V4.5C3 4.10218 3.15803 3.72064 3.43933 3.43934C3.72062 3.15804 4.10214 3 4.49995 3H9.74978C9.94869 3 10.1394 3.07902 10.2801 3.21967C10.4207 3.36032 10.4998 3.55109 10.4998 3.75C10.4998 3.94891 10.4207 4.13968 10.2801 4.28033C10.1394 4.42098 9.94869 4.5 9.74978 4.5H4.49995V19.5H9.74978C9.94869 19.5 10.1394 19.579 10.2801 19.7197C10.4207 19.8603 10.4998 20.0511 10.4998 20.25ZM20.78 11.4694L17.0302 7.71937C16.9253 7.61437 16.7916 7.54284 16.646 7.51385C16.5005 7.48487 16.3496 7.49972 16.2125 7.55653C16.0754 7.61335 15.9582 7.70957 15.8758 7.83301C15.7934 7.95646 15.7495 8.10158 15.7496 8.25V11.25H9.74978C9.55087 11.25 9.36011 11.329 9.21947 11.4697C9.07882 11.6103 8.9998 11.8011 8.9998 12C8.9998 12.1989 9.07882 12.3897 9.21947 12.5303C9.36011 12.671 9.55087 12.75 9.74978 12.75H15.7496V15.75C15.7495 15.8984 15.7934 16.0435 15.8758 16.167C15.9582 16.2904 16.0754 16.3867 16.2125 16.4435C16.3496 16.5003 16.5005 16.5151 16.646 16.4861C16.7916 16.4572 16.9253 16.3856 17.0302 16.2806L20.78 12.5306C20.8498 12.461 20.9051 12.3783 20.9428 12.2872C20.9806 12.1962 21 12.0986 21 12C21 11.9014 20.9806 11.8038 20.9428 11.7128C20.9051 11.6217 20.8498 11.539 20.78 11.4694Z"
          fill={color}
        />
      </g>
      <defs>
        <clipPath id="clip0_632_4365">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default SignOut;
