import React from "react";

const Key = (props) => {
  const { size = "16", color = "#fff" } = props;

  return (
    <svg width={size} height={size} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M7 7C8.65685 7 10 5.65685 10 4C10 2.34315 8.65685 1 7 1C5.34315 1 4 2.34315 4 4C4 5.65685 5.34315 7 7 7Z" fill={color}/>
      <path d="M14.9997 6.99996C15.0011 6.58205 14.8903 6.17141 14.6788 5.81096C14.4673 5.45051 14.1629 5.15348 13.7974 4.9509C13.4318 4.74832 13.0186 4.64764 12.6009 4.65937C12.1831 4.67109 11.7762 4.7948 11.4226 5.01757C11.069 5.24033 10.7817 5.55397 10.5908 5.92572C10.3998 6.29747 10.3122 6.71367 10.3372 7.13085C10.3621 7.54802 10.4986 7.95084 10.7324 8.29723C10.9662 8.64362 11.2887 8.92085 11.6663 9.09996V12.6666L12.6663 13.6666L14.333 12L13.333 11L14.333 9.99996L13.5063 9.17329C13.9454 9.00388 14.3229 8.70566 14.5894 8.3178C14.8559 7.92994 14.9989 7.47056 14.9997 6.99996ZM12.6663 6.99996C12.5345 6.99996 12.4056 6.96086 12.2959 6.88761C12.1863 6.81435 12.1009 6.71023 12.0504 6.58841C11.9999 6.4666 11.9867 6.33255 12.0125 6.20323C12.0382 6.07391 12.1017 5.95512 12.1949 5.86189C12.2882 5.76865 12.4069 5.70516 12.5363 5.67944C12.6656 5.65371 12.7996 5.66691 12.9214 5.71737C13.0433 5.76783 13.1474 5.85328 13.2206 5.96291C13.2939 6.07254 13.333 6.20144 13.333 6.33329C13.333 6.5101 13.2628 6.67967 13.1377 6.8047C13.0127 6.92972 12.8431 6.99996 12.6663 6.99996Z" fill={color}/>
      <path d="M9.62667 8.34667C9.11472 8.11841 8.56052 8.00031 8 8H6C4.93913 8 3.92172 8.42143 3.17157 9.17157C2.42143 9.92172 2 10.9391 2 12V13.3333H10.6667V9.66C10.2171 9.31461 9.85981 8.86346 9.62667 8.34667Z" fill={color}/>
    </svg>
  );
};

export default Key;
