import React from "react";
import ReactExport from "react-data-export";

const File = ReactExport.ExcelFile;
const Sheet = ReactExport.ExcelFile.ExcelSheet;
const Column = ReactExport.ExcelFile.ExcelColumn;

const Report = ({ data, filename, children }) => (
  <File element={children} filename={filename} fileExtension="xlsx">
    <Sheet data={data} name="Relatório">
      <Column label="razao_social" value="accountName" />
      <Column label="nome_fantasia" value="accountAlias" />
    </Sheet>
  </File>
);

export default Report;
