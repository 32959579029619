export const INITIAL_VALUES = {
  benefitedId: "",
  categoryId: "",
  serviceProviderAssociationId: "",
  quantity: "",
};

export const validation = (key, intl, props = {}) => {
  switch (key) {
    case "benefitedId":
      return [
        {
          required: true,
          message: intl.formatMessage({ id: "error.field_required" }),
        },
      ];

    case "serviceProviderAssociationId":
      return [
        {
          required: props?.required,
          message: intl.formatMessage({ id: "error.field_required" }),
        },
      ];

    case "categoryId":
      return [
        {
          required: true,
          message: intl.formatMessage({ id: "error.field_required" }),
        },
      ];

    case "quantity":
      return [
        {
          required: true,
          message: intl.formatMessage({ id: "error.field_required" }),
        },
        ({ getFieldValue }) => ({
            validator(_, value) {
              if (!value || getFieldValue('quantity') > 0) {
                return Promise.resolve();
              }
              return Promise.reject(new Error(intl.formatMessage({ id: "error.credential_min_quantity_value" })));
            },
          }),
      ];

    default:
      return [];
  }
};
