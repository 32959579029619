import React, { useEffect, useContext } from 'react'
import { parse } from "date-fns";
import { useIntl } from "react-intl";
import { useSelector, useDispatch } from "react-redux";
import useNotification from "../../../hooks/newUXNotification";
import { DialogContext } from "../../../hooks/dialog/context";
import ScheduleModal from './ScheduleModal';
import CredentialsHeader from '../Components/Header'
import SmallInfo from '../../../newUXAssets/icons/SmallInfo'
import SmallFile from '../../../newUXAssets/icons/SmallFile'
import SmallCalendar from '../../../newUXAssets/icons/SmallCalendar'
import SmallClock from '../../../newUXAssets/icons/SmallClock'
import UserCircle from '../../../newUXAssets/icons/UserCircle'
import Pencil from '../../../newUXAssets/icons/Pencil'
import OutlinedPhone from '../../../newUXAssets/icons/OutlinedPhone'
import { 
  Container,
  Title,
  ScheduleCard,
  ScheduleCardHeader,
  HeaderTitle,
  Label,
  Button,
  SmallButton,
  LabelButton,
  AlertText
} from './styles'
import { getCredentialSchedule } from '../../../redux/modules/credentials/actions';

const Schedule = () =>{
  const { event, contract } = useSelector((state) => state.initialSettings);
  const { credentialSchedule } = useSelector((state) => state.credentials);

  const intl = useIntl()
  const dispatch = useDispatch()
  const notification = useNotification()
  const { createDialog } = useContext(DialogContext);

  const callBackError =  (message) =>
    notification.displayError(intl.formatMessage({id: "api.error." + message}), 'get-collaborators-error-message')
  
  useEffect(()=> {
    if(!event || event.length === 0 || JSON.stringify(event) === '{}') {
      notification.displayInfo(
        intl.formatMessage({ id: "error.contract_not_selected" }),
        'event-not-selected-message'
      )
      return
    }
    else notification.close('event-not-selected-message')
    dispatch(getCredentialSchedule( event.value, contract.value, callBackError ))
  },[contract.value, event.value])


  const onClickNew = ()=>{
    createDialog({
      id: `new-credential-schedule-modal`,
      Component: ScheduleModal,
      props: {
        credentialSchedule,
        event,
        contract
      }
    });
  }

  const onClickEdit = ()=>{
    createDialog({
      id: `edit-credential-schedule-modal`,
      Component: ScheduleModal,
      props: {
        credentialSchedule,
        event,
        contract,
        isEdit: true
      }
    });
  }
  
  return(
    <Container>
      <Title>Credenciais</Title>
      <CredentialsHeader isScheduleSelected />
      {event.value && contract.value &&
        <Button 
          disabled={!!credentialSchedule?.credentialScheduleId} 
          onClick={onClickNew}>
          <LabelButton 
            disabled={!!credentialSchedule?.credentialScheduleId}>
              + {intl.formatMessage({id: "new_scheduling"})}
            </LabelButton>
        </Button>
      }
      <ScheduleCard>
        <ScheduleCardHeader>
          <HeaderTitle><SmallInfo/> <h3>{intl.formatMessage({id: "scheduling"})}</h3></HeaderTitle>
          {credentialSchedule && credentialSchedule.credentialScheduleId &&
            <SmallButton onClick={onClickEdit}>
              <Pencil /><LabelButton>{intl.formatMessage({id: "edit"})}</LabelButton>
            </SmallButton>
          }
        </ScheduleCardHeader>
        {credentialSchedule && credentialSchedule.credentialScheduleId && 
          <>
            <Label><SmallFile/><p className='event'>{credentialSchedule.eventEditionName}</p></Label>
            <Label><SmallCalendar/><p>{intl.formatMessage({id: "withdrawal_date"})} {intl.formatDate(parse(credentialSchedule.dateSchedule, "yyyy-MM-dd", new Date()))}</p></Label>
            <Label><SmallClock/><p>{intl.formatMessage({id: "schedule_time"})}: {credentialSchedule.timeSchedule}</p></Label>
            <Label><UserCircle size='16'/><p>{intl.formatMessage({id: "name_of_who_withdraws"})}: {credentialSchedule.nameOfWhoWithdraws}</p></Label>
            <Label><OutlinedPhone /><p>{intl.formatMessage({id: "telephone"})}: {credentialSchedule.contact}</p></Label>
          </>
        }
        {credentialSchedule && !credentialSchedule?.credentialScheduleId &&
          <AlertText>{intl.formatMessage({id: "no_schedule_to_exhibit"})}</AlertText>
        }
      </ScheduleCard>
    </Container>
  )
}

export default Schedule