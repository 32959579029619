import api from "./index";

export const getListCredentialsService = async (params) =>
  await api.get("/accreditation", { params });

export const getCollaboratorOfContractService = async (params) =>
  await api.get(
    `/associate-collaborators/by-user?opportunity=${params.idContract}&credential=${params.idCredential}`
  );

export const getCredentialService = async (id) =>
  await api.get(`/accreditation/${id}`);

export const handleAssociateCredentialService = async (data) =>
  await api.post("/accreditation", data);

export const updateAssociateCredentialService = async (data) =>
  await api.put(`/accreditation/${data.id}`, data);

export const removeAssociateCredentialService = async (id) =>
  await api.delete(`/accreditation/${id}`);

export const getQuotaCredentialService = async (params) =>
  await api.get("/accreditation/quota", { params });

export const getCredentialScheduleService = async(event, opportunity) =>
  await api.get(`/credential-schedule?event_id=${event}&opportunity_id=${opportunity}`)

export const postCredentialScheduleService = async(params) =>
  await api.post(`/credential-schedule`, {...params})

export const editCredentialScheduleService = async(params, id) =>
  await api.put(`/credential-schedule/${id}`, {...params})

export const getCredentialScheduleAvailableDatesService = async(params) =>
  await api.get(`/credential-schedule/available-dates`, { params })

export const getCredentialScheduleAvailableTimesService = async(params) =>
  await api.get(`/credential-schedule/available-times`, { params })
