import React, { useEffect, useMemo, useRef, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useSelector } from "react-redux";
import { useToast } from "../../../../hooks/toast";
import * as Yup from "yup";
import api from "../../../../service";
import { useCallback } from "react";
import InputWithLabel from "../../../../components/InputWithLabel";
import { Form } from "@unform/web";
import { validateSingleFieldOnBlur } from "../../../../utils/formValidations";

import {
  FormContainer,
  FieldsContainer,
  Horizontal,
  FilterActionsContainer,
  SubmitContainer,
  Label,
  LabelContainer,
  FileDropzoneContainer,
  Title,
  TitleContainer,
  DocumentVisualization,
  DocumentAction,
  DocumentName,
} from "./styles";
import Modal from "../../../../components/Modal";
import FormButton from "../../../../components/FormButton";
import SelectWithLabel from "../../../../components/SelectWithLabel";
import getValidationErrors from "../../../../utils/getValidationErrors";
import { useLoading } from "../../../../hooks/loading";
import Dropzone from "../../../../components/Dropzone";
import { MdClose } from "react-icons/md";

const EventFileModal = ({
  setIsOpen,
  modalIsOpen,
  fileId,
  eventEditionCode,
  updateCallback,
}) => {
  const { token } = useSelector((state) => state.auth);
  const formRef = useRef(null);
  const intl = useIntl();
  const { addToast } = useToast();
  const { handleLoading } = useLoading();
  const [formKey, setFormKey] = useState();
  const [isSentByUpload, setIsSentByUpload] = useState(true);
  const [isEditFileUnmodified, setIsEditFileUnmodified] = useState(false);

  const languageOptions = [
    {
      label: intl.formatMessage({ id: "portuguese" }),
      value: "PT",
    },
    {
      label: intl.formatMessage({ id: "english" }),
      value: "EN",
    },
  ];

  const sendMethodOptions = [
    {
      label: intl.formatMessage({ id: "upload" }),
      value: "UPLOAD",
    },
    {
      label: intl.formatMessage({ id: "link" }),
      value: "LINK",
    },
  ];

  const initialValues = {
    title: "",
    fileLanguage: "PT",
    sendMethod: "UPLOAD",
    link: "",
    document: null,
  };

  const [data, setData] = useState(initialValues);

  const schema = Yup.object().shape({
    title: Yup.string()
      .nullable()
      .max(50, intl.formatMessage({ id: "error.max_length" }, { length: 50 }))
      .required(intl.formatMessage({ id: "error.field_required" })),
    file_language: Yup.string()
      .nullable()
      .required(intl.formatMessage({ id: "error.field_required" })),
    send_method: Yup.string()
      .nullable()
      .required(intl.formatMessage({ id: "error.field_required" })),
    link: Yup.string()
      .nullable()
      .test(
        "link_validation_by_send_method",
        intl.formatMessage({
          id: "error.field_required",
        }),
        function (value) {
          const sendMethodSelected = this.resolve(Yup.ref("send_method"));
          if (sendMethodSelected === "LINK") {
            return !!value;
          }
          return true;
        }
      ),
    document: Yup.array()
      .nullable()
      .test(
        "document_validation_by_send_method",
        intl.formatMessage({
          id: "error.field_required",
        }),
        function (value) {
          const sendMethodSelected = this.resolve(Yup.ref("send_method"));
          if (!isEditFileUnmodified
            && sendMethodSelected === "UPLOAD") {
            return value?.length > 0;
          }
          return true;
        }
      )
      .test(
        "event_file_size",
        intl.formatMessage({
          id: "error.file-too-large",
        }),
        function (value) {
          const sendMethodSelected = this.resolve(Yup.ref("send_method"));
          if (!isEditFileUnmodified
            && sendMethodSelected === "UPLOAD"
            && value?.length > 0) {
            return value[0].size < 10000000;
          }
          return true;
        }
      ),
  });

  useEffect(() => {
    if (modalIsOpen) {
      setData(initialValues);
      setIsEditFileUnmodified(false);
      setIsSentByUpload(true);

      if (fileId) {
        handleLoading(true);

        api
          .get(`/event-file/${fileId}?action=EDIT`,
          {
            headers: {
              "Content-Type": "application/json",
              Accept: "*/*",
              Authorization: `Bearer ${token}`,
            }
          })
          .then((result) => {
            const isSentMethodUpload =
              result.data.output?.sendMethod === "UPLOAD";

            setData(result.data.output);
            setIsEditFileUnmodified(isSentMethodUpload);
            setIsSentByUpload(isSentMethodUpload);
            setFormKey(new Date());
          })
          .catch((err) => {
            addToast({
              type: "error",
              title: intl.formatMessage({
                id: "error",
              }),
              description: intl.formatMessage({
                id: "api.error." + err.response?.data?.code,
              }),
            });
          })
          .finally(() => {
            handleLoading(false);
          });
      }
    }
  }, [
    addToast,
    intl,
    token,
    handleLoading,
    fileId,
    modalIsOpen
  ]);

  const validationBlur = (name) => {
    let errors = formRef.current.getErrors();

    validateSingleFieldOnBlur(errors, schema, name, formRef);
  };

  const pageTitleLabel = useMemo(
    () => fileId
        ? 'edit_event_edition_file'
        : 'add_event_edition_file',
    [fileId]
  );

  const generateFormData = (data) => {
     let formData = new FormData();

     formData.set("title", data.title);
     formData.set("fileLanguage", data.file_language);
     formData.set("sendMethod", data.send_method);
     formData.set("link", data.link);
     formData.set("eventEditionCode", eventEditionCode);
     formData.set("hasFileChanged", !isEditFileUnmodified);

     if (data.document?.length > 0) {
       formData.set("document", data.document[0]);
     }

     return formData;
  };

  const postEventEditionFile = useCallback(
    async (data) => {
      try {
        formRef.current?.setErrors({});
        await schema.validate(data, { abortEarly: false });

        let formData = generateFormData(data);

        handleLoading(true);

        api
          .post(
            "/event-file",
            formData,
            {
              headers: {
                "Content-Type": "multipart/form-data",
                Accept: "*/*",
                Authorization: `Bearer ${token}`,
              },
            }
          )
          .then(() => {
            addToast({
              type: "success",
              title: intl.formatMessage({
                id: "success",
              }),
              description: intl.formatMessage({
                id: "success.event_file_added",
              }),
            });
            updateCallback();
            setIsOpen(false);
          })
          .catch((err) => {
            addToast({
              type: "error",
              title: intl.formatMessage({
                id: "error",
              }),
              description: intl.formatMessage({
                id: "api.error." + err.response.data.code,
              }),
            });
          })
          .finally(() => {
            handleLoading(false);
          });
      } catch (err) {
        handleLoading(false);

        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);
          formRef.current?.setErrors(errors);
          return;
        }
      }
    },
    [
      addToast,
      intl,
      schema,
      token,
      handleLoading,
      eventEditionCode
    ]
  );

  const putEventEditionFile = useCallback(
    async (data) => {
      try {
        formRef.current?.setErrors({});
        await schema.validate(data, { abortEarly: false });

        let formData = generateFormData(data);

        handleLoading(true);

        api
          .put(
            `/event-file/${fileId}`,
            formData,
            {
              headers: {
                "Content-Type": "multipart/form-data",
                Accept: "*/*",
                Authorization: `Bearer ${token}`,
              },
            }
          )
          .then((data) => {
            addToast({
              type: "success",
              title: intl.formatMessage({
                id: "success",
              }),
              description: intl.formatMessage({
                id: "success.event_file_edited",
              }),
            });
            updateCallback();
            setIsOpen(false);
          })
          .catch((err) => {
            addToast({
              type: "error",
              title: intl.formatMessage({
                id: "error",
              }),
              description: intl.formatMessage({
                id: "api.error." + err.response.data.code,
              }),
            });
          })
          .finally(() => {
            handleLoading(false);
          });
      } catch (err) {
        handleLoading(false);

        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);
          formRef.current?.setErrors(errors);
          return;
        }
      }
    },
    [
      addToast,
      intl,
      schema,
      token,
      handleLoading,
      fileId,
      eventEditionCode,
      isEditFileUnmodified
    ]
  );

  const handleSubmit = useMemo(
    () => fileId ? putEventEditionFile : postEventEditionFile,
    [fileId, putEventEditionFile, postEventEditionFile]
  );

  const onChangeSendMethodSelect = (sendMethodType) => {
    formRef.current?.setErrors({});
    setIsSentByUpload(sendMethodType?.value === "UPLOAD");
    formRef.current.setFieldValue("link", "");
    formRef.current.clearField("document");
    setIsEditFileUnmodified(false);
  };

  const handleRemoveLoadedDocument = () => {
    setIsEditFileUnmodified(false);
  }

  return (
    <Modal
      setIsOpen={setIsOpen}
      modalIsOpen={modalIsOpen}
      modalWidth="50%"
    >
      <FormContainer>
        <Form
          ref={formRef}
          onSubmit={handleSubmit}
          initialData={{
            title: data.title,
            file_language: {
              label: languageOptions.filter(
                (v) => v.value === data.fileLanguage
              )[0]?.label,
              value: data.fileLanguage,
            },
            send_method: {
              label: sendMethodOptions.filter(
                (v) => v.value === data.sendMethod
              )[0]?.label,
              value: data.sendMethod,
            },
            link: data.link,
            document: []
          }}
          key={formKey}
        >
          <TitleContainer>
            <Title>
              <FormattedMessage id={pageTitleLabel} />
            </Title>
          </TitleContainer>
          <FieldsContainer>
            <InputWithLabel
              name="title"
              validationBlur={validationBlur}
              label={intl.formatMessage({ id: "title" })}
              placeholder={intl.formatMessage({ id: "title" })}
              maxLength="50"
              required
            />
            <SelectWithLabel
              name="file_language"
              validationBlur={validationBlur}
              label={intl.formatMessage({ id: "file_language" })}
              placeholder={intl.formatMessage({ id: "file_language" })}
              options={languageOptions}
              required
            />
            <SelectWithLabel
              name="send_method"
              validationBlur={validationBlur}
              label={intl.formatMessage({ id: "send_method" })}
              placeholder={intl.formatMessage({ id: "send_method" })}
              options={sendMethodOptions}
              required
              onChange={(option) => onChangeSendMethodSelect(option)}
            />
            <InputWithLabel
              name="link"
              validationBlur={validationBlur}
              label={intl.formatMessage({ id: "link" })}
              placeholder={intl.formatMessage({ id: "link" })}
              disabled={isSentByUpload}
            />
            <LabelContainer>
              <Label>
                {intl.formatMessage({ id: "upload" })}
              </Label>
            </LabelContainer>
            <FileDropzoneContainer
              disabled={!isSentByUpload}
            >
              {isEditFileUnmodified ? (
                <DocumentVisualization>
                  <DocumentName>
                    {data.fileName}
                  </DocumentName>
                  <DocumentAction>
                    <MdClose
                      color="#e00000"
                      size={20}
                      onClick={() => {
                        handleRemoveLoadedDocument();
                      }}
                    />
                  </DocumentAction>
                </DocumentVisualization>
              ) : (
                <Dropzone
                  name="document"
                  accept="application/pdf"
                  type="PDF"
                  multiple={false}
                  disabled={!isSentByUpload}
                />
              )}
            </FileDropzoneContainer>
            <Horizontal>
              <FilterActionsContainer>
                <SubmitContainer>
                  <FormButton
                    color="#C9C9C9"
                    type="button"
                    onClick={() => setIsOpen(false)}
                  >
                    <FormattedMessage id="close" />
                  </FormButton>
                </SubmitContainer>
                <SubmitContainer>
                  <FormButton type="submit">
                    <FormattedMessage id="save" />
                  </FormButton>
                </SubmitContainer>
              </FilterActionsContainer>
            </Horizontal>
          </FieldsContainer>
        </Form>
      </FormContainer>
    </Modal>
  );
};

export default EventFileModal;
