import React from "react";

const Billing = (props) => {
  const { size = "26", color = "#444444" } = props;

  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 26 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13 1C6.37321 1 1 6.37321 1 13C1 19.6268 6.37321 25 13 25C19.6268 25 25 19.6268 25 13C25 6.37321 19.6268 1 13 1ZM13 22.9643C7.49821 22.9643 3.03571 18.5018 3.03571 13C3.03571 7.49821 7.49821 3.03571 13 3.03571C18.5018 3.03571 22.9643 7.49821 22.9643 13C22.9643 18.5018 18.5018 22.9643 13 22.9643ZM14.2777 12.3786L13.5973 12.2205V8.62321C14.6152 8.7625 15.2446 9.4 15.3518 10.1821C15.3652 10.2893 15.4563 10.367 15.5634 10.367H16.7661C16.892 10.367 16.9911 10.2571 16.9804 10.1313C16.817 8.4625 15.4429 7.39107 13.608 7.20625V6.33036C13.608 6.2125 13.5116 6.11607 13.3938 6.11607H12.6411C12.5232 6.11607 12.4268 6.2125 12.4268 6.33036V7.21429C10.5304 7.39911 9.04643 8.44643 9.04643 10.4018C9.04643 12.2125 10.3804 13.0857 11.7812 13.4205L12.4429 13.5893V17.4116C11.2589 17.2536 10.5946 16.6214 10.458 15.7696C10.442 15.6679 10.3509 15.5929 10.2464 15.5929H9.00893C8.88304 15.5929 8.78393 15.7 8.79464 15.8259C8.91518 17.2991 10.0321 18.6545 12.4161 18.8286V19.6696C12.4161 19.7875 12.5125 19.8839 12.6304 19.8839H13.3911C13.5089 19.8839 13.6054 19.7875 13.6054 19.667L13.6 18.8179C15.6973 18.633 17.1973 17.5107 17.1973 15.4964C17.1946 13.6375 16.0134 12.8071 14.2777 12.3786ZM12.4402 11.9446C12.2902 11.9018 12.1643 11.8616 12.0384 11.8107C11.133 11.4839 10.7125 10.9563 10.7125 10.2759C10.7125 9.30357 11.4491 8.74911 12.4402 8.62321V11.9446ZM13.5973 17.4196V13.8384C13.6804 13.8625 13.7554 13.8813 13.833 13.8973C15.1 14.283 15.5259 14.8187 15.5259 15.6411C15.5259 16.6884 14.7384 17.3179 13.5973 17.4196Z"
        fill={color}
      />
    </svg>
  );
};

export default Billing;
