export function getBenefited(opportunityId, callbackError) {
  return {
    type: "GET_BENEFITED",
    payload: {
      opportunityId,
      callbackError,
    },
  };
}

export function setBenefitedSuccess(benefited) {
  return {
    type: "SET_BENEFITED_SUCCESS",
    payload: {
      benefited,
    },
  };
}

export function getFindServices(params, callbackSuccess, callbackError) {
  return {
    type: "GET_FIND_SERVICES",
    payload: {
      params,
      callbackSuccess,
      callbackError,
    },
  };
}

export function getTypesCredentialOpportunity(
  params,
  callbackSuccess,
  callbackError
) {
  return {
    type: "GET_TYPES_CREDENTIAL_OPPORTUNITY",
    payload: {
      params,
      callbackSuccess,
      callbackError,
    },
  };
}

export function createCredentialTransfer(
  params,
  callbackSuccess,
  callbackError
) {
  return {
    type: "CREATE_CREDENTIAL_TRANSFER",
    payload: {
      params,
      callbackSuccess,
      callbackError,
    },
  };
}

export function getTransferHistory(
  params,
  callbackPaginationInfo,
  callbackError
) {
  return {
    type: "GET_TRANSFER_HISTORY",
    payload: {
      params,
      callbackPaginationInfo,
      callbackError,
    },
  };
}

export function setTransferHistorySuccess(transferHistory) {
  return {
    type: "SET_TRANSFER_HISTORY_SUCCESS",
    payload: {
      transferHistory,
    },
  };
}
