import React from "react";

const Guests = (props) => {
  const { size = "26", color = "#444444" } = props;

  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 26 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_544_14103)">
        <path
          d="M19.5 12.1875C20.4461 12.1868 21.3794 12.4067 22.2257 12.8299C23.072 13.253 23.8079 13.8677 24.375 14.625"
          stroke={color}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M1.625 14.625C2.19212 13.8677 2.92805 13.253 3.77431 12.8299C4.62057 12.4067 5.55385 12.1868 6.5 12.1875"
          stroke={color}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M13 18.6875C15.2437 18.6875 17.0625 16.8687 17.0625 14.625C17.0625 12.3813 15.2437 10.5625 13 10.5625C10.7563 10.5625 8.9375 12.3813 8.9375 14.625C8.9375 16.8687 10.7563 18.6875 13 18.6875Z"
          stroke={color}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M7.3125 21.9375C7.89576 20.9477 8.72723 20.1273 9.72471 19.5573C10.7222 18.9873 11.8512 18.6875 13 18.6875C14.1488 18.6875 15.2778 18.9873 16.2753 19.5573C17.2728 20.1273 18.1042 20.9477 18.6875 21.9375"
          stroke={color}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M16.3516 8.125C16.5035 7.53651 16.8174 7.00242 17.2577 6.58339C17.6979 6.16435 18.2469 5.87717 18.8421 5.75444C19.4374 5.63172 20.0552 5.67838 20.6252 5.88912C21.1953 6.09986 21.6949 6.46624 22.0672 6.94664C22.4395 7.42705 22.6697 8.00223 22.7315 8.60687C22.7933 9.2115 22.6844 9.82136 22.417 10.3672C22.1496 10.913 21.7346 11.3729 21.2189 11.6947C20.7033 12.0165 20.1078 12.1872 19.5 12.1875"
          stroke={color}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M6.49983 12.1875C5.89204 12.1872 5.29651 12.0165 4.78088 11.6947C4.26526 11.3729 3.85019 10.913 3.58282 10.3672C3.31545 9.82136 3.2065 9.2115 3.26833 8.60687C3.33016 8.00223 3.5603 7.42705 3.93262 6.94664C4.30493 6.46624 4.8045 6.09986 5.37458 5.88912C5.94467 5.67838 6.56243 5.63172 7.1577 5.75444C7.75297 5.87717 8.3019 6.16435 8.74215 6.58339C9.18239 7.00242 9.49632 7.53651 9.64827 8.125"
          stroke={color}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_854_2411">
          <rect width={size} height={size} fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default Guests;
