import React from "react";

const Pin = (props) => {
  const { size = "17", color = "#282828", filled = false } = props;

  if (filled) {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={size}
        height={size}
        viewBox="0 0 17 16"
        fill="none"
      >
        <path
          d="M8.5 11.333V14.6663"
          stroke="#282828"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M3.83398 11.333H13.1673V10.1597C13.1672 9.91162 13.0979 9.66852 12.9671 9.4577C12.8364 9.24689 12.6495 9.07673 12.4273 8.96634L11.2407 8.36634C11.0185 8.25596 10.8316 8.08579 10.7008 7.87498C10.5701 7.66416 10.5008 7.42106 10.5007 7.17301V3.99967H11.1673C11.5209 3.99967 11.8601 3.8592 12.1101 3.60915C12.3602 3.3591 12.5007 3.01996 12.5007 2.66634C12.5007 2.31272 12.3602 1.97358 12.1101 1.72353C11.8601 1.47348 11.5209 1.33301 11.1673 1.33301H5.83398C5.48036 1.33301 5.14122 1.47348 4.89118 1.72353C4.64113 1.97358 4.50065 2.31272 4.50065 2.66634C4.50065 3.01996 4.64113 3.3591 4.89118 3.60915C5.14122 3.8592 5.48036 3.99967 5.83398 3.99967H6.50065V7.17301C6.50052 7.42106 6.43119 7.66416 6.30046 7.87498C6.16974 8.08579 5.98279 8.25596 5.76065 8.36634L4.57398 8.96634C4.35184 9.07673 4.1649 9.24689 4.03417 9.4577C3.90344 9.66852 3.83412 9.91162 3.83398 10.1597V11.333Z"
          stroke="#282828"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    );
  }

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 17 16"
      fill="none"
    >
      <path
        d="M8.5 11.333V14.6663"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M3.83398 11.333H13.1673V10.1597C13.1672 9.91162 13.0979 9.66852 12.9671 9.4577C12.8364 9.24689 12.6495 9.07673 12.4273 8.96634L11.2407 8.36634C11.0185 8.25596 10.8316 8.08579 10.7008 7.87498C10.5701 7.66416 10.5008 7.42106 10.5007 7.17301V3.99967H11.1673C11.5209 3.99967 11.8601 3.8592 12.1101 3.60915C12.3602 3.3591 12.5007 3.01996 12.5007 2.66634C12.5007 2.31272 12.3602 1.97358 12.1101 1.72353C11.8601 1.47348 11.5209 1.33301 11.1673 1.33301H5.83398C5.48036 1.33301 5.14122 1.47348 4.89118 1.72353C4.64113 1.97358 4.50065 2.31272 4.50065 2.66634C4.50065 3.01996 4.64113 3.3591 4.89118 3.60915C5.14122 3.8592 5.48036 3.99967 5.83398 3.99967H6.50065V7.17301C6.50052 7.42106 6.43119 7.66416 6.30046 7.87498C6.16974 8.08579 5.98279 8.25596 5.76065 8.36634L4.57398 8.96634C4.35184 9.07673 4.1649 9.24689 4.03417 9.4577C3.90344 9.66852 3.83412 9.91162 3.83398 10.1597V11.333Z"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default Pin;
