import React, { useMemo } from "react";

import { useSelector } from "react-redux";

import { useIntl } from "react-intl";

import Button from "../../../newUXComponents/Button";
import Edit from "../../../newUXAssets/icons/Edit";

import provision_types from "../../../constants/provisionTypes";
import taxes from "../../../constants/taxes";

import {
  Container,
  Content,
  Information,
  Label,
  Description,
  BtnEdit,
} from "./styles";

const ViewInformation = ({ onClickEdit }) => {
  const intl = useIntl();

  const { user } = useSelector((state) => state.auth);

  const provisionTypes = provision_types();

  const taxesOptions = taxes;

  const userType = useMemo(() => {
    if (user.user_type === "J") {
      return intl.formatMessage({ id: "legal_entity" });
    }

    if (user.user_type === "F") {
      return intl.formatMessage({ id: "natural_person" });
    }

    if (user.user_type === "I") {
      return intl.formatMessage({
        id: "international",
      });
    }

    return " - ";
  }, [user, intl]);

  const filterProvisionTypes = useMemo(() => {
    if (!user.provisionTypes)
      return [
        {
          label: " - ",
        },
      ];

    return (
      user.provisionTypes.length &&
      user.provisionTypes.map((types) => {
        return provisionTypes.find((item) => item.value === types);
      })
    );
  }, [user, provisionTypes]);

  const labelTaxes = useMemo(() => {
    if (user.taxes === null) return " - ";
    return taxesOptions.find((item) => item.value === user.taxes).label;
  }, [taxesOptions, user]);

  return (
    <>
      <Container>
        <Content>
          <Information>
            <Label>{intl.formatMessage({ id: "type" })}</Label>
            <Description>{userType}</Description>
          </Information>
          <Information>
            <Label>{intl.formatMessage({ id: "types_of_service" })}</Label>
            <Description>
              {filterProvisionTypes.length &&
                filterProvisionTypes.map((item, index) => {
                  if (index + 1 < filterProvisionTypes.length) {
                    return item.label + ", ";
                  }
                  return item.label;
                })}
            </Description>
          </Information>
        </Content>
        <Content>
          <Information>
            <Label>{intl.formatMessage({ id: "name_lastname" })}</Label>
            <Description>{user.name || " - "}</Description>
          </Information>
          <Information>
            <Label>{intl.formatMessage({ id: "zip_code" })}</Label>
            <Description>{user.zipCode || " - "}</Description>
          </Information>
        </Content>

        <Content>
          <Information>
            <Label>{intl.formatMessage({ id: "corporate_name" })}</Label>
            <Description>{user.companyName || " - "}</Description>
          </Information>
          <Information>
            <Label>{intl.formatMessage({ id: "address" })}</Label>
            <Description>{user.avenue || " - "}</Description>
          </Information>
        </Content>

        <Content>
          <Information>
            <Label>
              {intl.formatMessage({ id: "id_number_cnpj_passport" })}
            </Label>
            <Description>{user.document || " - "}</Description>
          </Information>
          <Information>
            <Label>{intl.formatMessage({ id: "address_number" })}</Label>
            <Description>{user.number || " - "}</Description>
          </Information>
        </Content>

        <Content>
          <Information>
            <Label>{intl.formatMessage({ id: "state_inscription" })}</Label>
            <Description>{user.state_inscription || " - "}</Description>
          </Information>

          <Information>
            <Label>{intl.formatMessage({ id: "district" })}</Label>
            <Description>{user.district || " - "}</Description>
          </Information>
        </Content>

        <Content>
          <Information>
            <Label>{intl.formatMessage({ id: "city_inscription" })}</Label>
            <Description>{user.municipal_inscription || " - "}</Description>
          </Information>
          <Information>
            <Label>{intl.formatMessage({ id: "city" })}</Label>
            <Description>{user.city || " - "}</Description>
          </Information>
        </Content>

        <Content>
          <Information>
            <Label>{intl.formatMessage({ id: "country" })}</Label>
            <Description>{user.country || " - "}</Description>
          </Information>
          <Information>
            <Label>{intl.formatMessage({ id: "state" })}</Label>
            <Description>{user.state || " - "}</Description>
          </Information>
        </Content>

        <Content>
          <Information>
            <Label>{intl.formatMessage({ id: "email" })}</Label>
            <Description>{user.email || " - "}</Description>
          </Information>
          <Information>
            <Label>{intl.formatMessage({ id: "phone" })}</Label>
            <Description>{user.telephone || " - "}</Description>
          </Information>
        </Content>

        <Content>
          <Information>
            <Label>{intl.formatMessage({ id: "taxes" })}</Label>
            <Description>{labelTaxes}</Description>
          </Information>
          <Information>
            <Label>{intl.formatMessage({ id: "mobile" })}</Label>
            <Description>{user.mobile || " - "}</Description>
          </Information>
        </Content>
      </Container>
      <BtnEdit>
        <Button
          onClick={() => onClickEdit()}
          label={intl.formatMessage({ id: "edit" })}
          type="primary"
          icon={<Edit />}
        />
      </BtnEdit>
    </>
  );
};

export default ViewInformation;
