import React, { useCallback } from "react";

import { useIntl } from "react-intl";

import useNotification from "../../../../../hooks/newUXNotification";

import { SignOnSystemGuests } from "../../../../../redux/modules/guests/actions";

import { useDispatch } from "react-redux";

import CustomModal from "../../../../../components/CustomModal";

import Button from "../../../../../newUXComponents/Button";

import {
  Container,
  Header,
  Divider,
  Content,
  ContainerButton,
  Text,
} from "./styles";

const GuestsModal = ({ open, handleClose, id, data, idContract, eventId }) => {
  const intl = useIntl();

  const dispatch = useDispatch();
  const notification = useNotification();

  const generateHiddenInputAndAppend = (name, value, form) => {
    const field = document.createElement("input");
    field.type = "hidden";
    field.name = name;
    field.value = value;
    form.appendChild(field);
  };

  const callbackSuccess = useCallback(
    ({
      url,
      ev,
      i,
      cnpj,
      fantasyName,
      officialName,
      responsible,
      address,
      countryId,
      city,
      email,
      accountId,
      opportunityId,
      credentialCompanyName,
      footage,
      credentialVisitor,
      fullCongressionalCredential,
      credentialTicket,
      congressTicket,
      user,
      password,
    }) => {
      const form = document.createElement("form");
      form.method = "post";
      form.action = url;
      form.target = "_blank";

      generateHiddenInputAndAppend("USUARIO", user, form);
      generateHiddenInputAndAppend("SENHA", password, form);
      generateHiddenInputAndAppend("EV", ev, form);
      generateHiddenInputAndAppend("I", i, form);
      generateHiddenInputAndAppend("CNPJ", cnpj, form);
      generateHiddenInputAndAppend("NMFANTASIA", fantasyName, form);
      generateHiddenInputAndAppend("NMOFICIAL", officialName, form);
      generateHiddenInputAndAppend("RESPONSAVEL", responsible, form);
      generateHiddenInputAndAppend("ENDERECO", address, form);
      generateHiddenInputAndAppend("IDPAIS", countryId, form);
      generateHiddenInputAndAppend("CITY", city, form);
      generateHiddenInputAndAppend("EMAIL", email, form);
      generateHiddenInputAndAppend("BTS_CODCLIENTE", accountId, form);
      generateHiddenInputAndAppend("BTS_CODCONTRATO", opportunityId, form);
      generateHiddenInputAndAppend(
        "NOMEEMPRESACREDENCIAL",
        credentialCompanyName,
        form
      );
      generateHiddenInputAndAppend("METRAGEM", footage, form);
      generateHiddenInputAndAppend("CREDENTIALVISITOR", credentialVisitor, form);
      generateHiddenInputAndAppend(
        "FULLCONGRESSIONALCREDENTIAL",
        fullCongressionalCredential,
        form
      );
      generateHiddenInputAndAppend("CREDENTIALTICKET", credentialTicket, form);
      generateHiddenInputAndAppend("CONGRESSTICKET", congressTicket, form);

      document.body.appendChild(form);
      form.submit();
      document.body.removeChild(form);
    },
    []
  );

  const callbackError = useCallback(
    (codeError) => {
      if (codeError === -5029) {
        notification.displayInfo(
          intl.formatMessage({
            id: "api.error." + codeError,
          }),
          "financial-Single-SignOn-info"
        );
      } else {
        notification.displayError(
          intl.formatMessage({
            id: "api.error." + codeError,
          }),
          "financial-Single-SignOn-info"
        );
      }
    },
    [intl, notification]
  );

  const handleSignOn = () => {
    dispatch(
      SignOnSystemGuests(
        {
          eventId,
          opportunityId: idContract,
        },
        callbackSuccess,
        callbackError
      )
    );
  };

  return (
    <CustomModal id={id} isModalVisible={open} handleClose={handleClose}>
      <Container>
        <Header>{intl.formatMessage({ id: "accreditation_system" })}</Header>
        <Divider />
        <Content>
          <Text>
            {intl.formatMessage(
              { id: "access_accreditation_invitation_message" },
              { systemName: data?.integrationType }
            )}
          </Text>
          <ContainerButton>
            <Button
              type="primary"
              label={intl.formatMessage({ id: "confirm" })}
              onClick={() => handleSignOn()}
            />
            <Button
              type="link"
              label={intl.formatMessage({ id: "cancel" })}
              onClick={() => handleClose()}
            />
          </ContainerButton>
        </Content>
      </Container>
    </CustomModal>
  );
};

export default GuestsModal;
