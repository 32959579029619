import styled, { css } from "styled-components";

import { Table, Input } from "antd";

export const Container = styled.div`
  width: 100%;
  height: 100%;
  border-radius: 0px 0px 10px 10px;

  padding-left: ${({ $notEven }) => ($notEven ? "35px" : "0")};
`;

export const Header = styled.div`
  display: flex;
  padding: 8px 16px;
  justify-content: space-between;
  align-items: center;
  border-radius: 10px 10px 0px 0px;
`;

export const Text = styled.div`
  color: #3a3a3c;
  font-family: "Open Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
`;

export const Seach = styled.div``;

export const CustomTable = styled(Table)`
  line-height: 24px;
  font-style: normal;
  color: #3a3a3c;
  font-family: "Open Sans";

  thead {
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
  }

  tbody {
    font-size: 12px;
    font-weight: 400;
  }

  .ant-table-thead > tr > th {
    background: transparent;
    font-weight: 600;
    border-bottom: ${({ $notEven }) =>
      $notEven ? "none!important" : "1px solid #f0f0f0"};
  }

  tr {
    height: 64px;
  }

  tr.ant-table-expanded-row > td,
  tr.ant-table-expanded-row:hover > td {
    background: #fff !important;
  }

  .ant-table-tbody > tr > td {
    /* border-bottom: 1px solid #f0f0f0;
    padding: 16px 16px; */
    padding: 0px 16px !important;

    ${(props) =>
      props.$notEven &&
      css`
        padding: 0px 16px !important;
        border-bottom: none !important;
      `}
  }

  .ant-table-measure-row,
  tr:nth-child(even) {
    background-color: ${({ $isEmpty, $notEven }) =>
      $isEmpty || $notEven ? "#fff !important" : "#f7f6fe"};
  }

  th::before {
    content: none !important;
  }

  .ant-table-cell-scrollbar {
    display: none;
  }

  .ant-table-body {
    overflow-x: hidden !important;
  }

  .ant-table-body::-webkit-scrollbar {
    width: 4px;
  }

  .ant-table-body::-webkit-scrollbar-thumb {
    background-color: #11a7d9;
    border-radius: 50px;
  }

  td {
    svg {
      position: absolute;
      top: 38%;
    }
  }
`;

export const InputSearch = styled(Input.Search)`
  input,
  .ant-input-group-addon {
    color: #6c6c6c;
    background: #f5f5f5;
    font-family: "Open Sans";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    border-radius: 15px;
    border-right: none;

    button {
      background: #f5f5f5;
      border-radius: 0 10px 10px 0 !important;
      height: 29px !important;
      border-left: none;

      :hover {
        border-color: #d9d9d9 !important;
      }

      :focus {
        border-color: #d9d9d9 !important;
      }
    }

    :hover {
      border-color: #d9d9d9 !important;
    }

    :focus {
      border-color: #d9d9d9 !important;
    }
  }
`;

export const Empty = styled.div`
  color: #6c6c6c;
  text-align: center;
  font-family: "Open Sans";
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  height: 338px;
  background: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
`;
