import React, {useMemo} from "react"
import { useIntl } from "react-intl";
import CheckCircle from "../../../newUXAssets/icons/CheckCircle";
import WarningCircle from "../../../newUXAssets/icons/WarningCircle";
import Clock from "../../../newUXAssets/icons/Clock";
import {
  SolvedLabel,
  UnsolvedLabel,
  DelayedLabel
} from './styles'

const Label = ({status})=>{
  const intl = useIntl();

  const LabelWithStatus = useMemo(()=>{
    switch(status) {
      case 'Concluded': return(
        ()=> <SolvedLabel>
              <CheckCircle />
              {intl.formatMessage({ id: "checklist.concluded" })}
            </SolvedLabel>)
      case 'Pending': return(
        ()=> <UnsolvedLabel>
              <WarningCircle />
              {intl.formatMessage({ id: "checklist.pending" })}
            </UnsolvedLabel>)
      case 'Delayed': return(
        ()=> <DelayedLabel>
              <Clock />
              {intl.formatMessage({ id: "checklist.delayed" })}
            </DelayedLabel>)
      default: return <></>
    }
  },[status])

  return <LabelWithStatus/>
}

export default Label