import produce from "immer";

const INITIAL_STATE = {
  ecommerceInfo: {}
};

const ecommerceInfo = (state = INITIAL_STATE, action) => {
  return produce(state, (draft) => {
    switch (action.type) {
      case "SET_ECOMMERCE_INFO": {
        const { ecommerceInfo } = action.payload;

        if (!ecommerceInfo) {
          localStorage.removeItem("InformaMarket:ecommerceInfo")
          break
        }

        draft.ecommerceInfo = ecommerceInfo

        localStorage.setItem(
          "InformaMarket:ecommerceInfo",
          JSON.stringify(ecommerceInfo)
        );

        break;
      }

      case "CLEAR_ECOMMERCE_INFO": {
        draft.ecommerceInfo = [];
        localStorage.removeItem("InformaMarket:ecommerceInfo");

        break;
      }


      default: {
        if(localStorage.getItem('InformaMarket:ecommerceInfo')){
          const ecommerceInfo = JSON.parse(localStorage.getItem('InformaMarket:ecommerceInfo'));
  
          draft.ecommerceInfo = ecommerceInfo
        }
        return draft;
      }
    }
  });
};

export default ecommerceInfo;
