export function getChecklist({
  selectedEvent,
  callbackSuccess,
  callbackError,
  callbackEmpty,
}) {
  return {
    type: "GET_CHECKLIST",
    payload: {
      selectedEvent,
      callbackSuccess,
      callbackError,
      callbackEmpty,
    },
  };
}

export function setChecklistSuccess(checklist) {
  return {
    type: "SET_CHECKLIST",
    payload: {
      checklist,
    },
  };
}

export function setEmptyChecklist(isEmpty) {
  return {
    type: "SET_EMPTY_CHECKLIST",
    payload: {
      isEmpty,
    },
  };
}

export function solveChecklistStep({
  eventChecklistId,
  userChecklistId,
  solved,
  selectedEvent,
  callbackError,
}) {
  return {
    type: "SOLVE_CHECKLIST_STEP",
    payload: {
      eventChecklistId,
      userChecklistId,
      solved,
      selectedEvent,
      callbackError,
    },
  };
}

export function clearChecklist() {
  return {
    type: "CLEAR_CHECKLIST",
  };
}
