import React, {
  useEffect,
  useMemo,
  useState,
  useRef,
  useCallback,
} from "react";
import { useSelector } from "react-redux";
import ConfirmationModal from "../../ConfirmationModal";
import FormButton from "../../FormButton";
import SelectWithLabel from "../../SelectWithLabel";
import {
  FormContainer,
  TitleContainer,
  Title,
  FieldsContainer,
  Horizontal,
  FilterActionsContainer,
  SubmitContainer,
  InformationContainer,
  InformationNotification,
  AdditionalInformationContainer,
  InformationLabel,
  InformationValue,
  BoldInformationLabel,
  BoldInformationValue,
} from "./styles";
import { FormattedMessage, useIntl } from "react-intl";
import api from "../../../service";
import Modal from "../../Modal";
import { useHistory } from "react-router-dom";

import { useLoading } from "../../../hooks/loading";
import { Form } from "@unform/web";
import { validateSingleFieldOnBlur } from "../../../utils/formValidations";
import * as Yup from "yup";
import { useToast } from "../../../hooks/toast";
import getValidationErrors from "../../../utils/getValidationErrors";
import axios from "axios";
import CheckboxInput from "../../CheckboxInput";

const EcommerceModal = ({
  setIsOpen,
  modalIsOpen = false,
}) => {
  const { user, token } = useSelector((state) => state.auth);
  const { isLoading, handleLoading } = useLoading();
  const { addToast } = useToast();
  const intl = useIntl();
  const history = useHistory();

  const formRef = useRef(null);
  const [loading, setLoading] = useState(false);

  const [events, setEvents] = useState([]);
  const [selectedEvent, setSelectedEvent] = useState();
  const [selectedEventId, setSelectedEventId] = useState();
  const [companies, setCompanies] = useState([]);
  const [selectedCompany, setSelectedCompany] = useState();
  const [selectedCompanyEmail, setSelectedCompanyEmail] = useState();
  const [selectedCompanyRoleType, setSelectedCompanyRoleType] = useState();
  const [opportunities, setOpportunities] = useState();
  const [chlebaAccessInformation, setChlebaAccessInformation] = useState();

  const [isConfirmed, setIsConfirmed] = useState(false);

  const [
    serviceProviderInvalidDataModalIsOpen,
    setServiceProviderInvalidDataModalIsOpen,
  ] = useState(false);

  const isAdministrator = user?.role?.type === "ADMINISTRATOR";
  const isStaff = user?.role?.type === "STAFF";
  const isServiceProvider = user?.role?.type === "SERVICE_PROVIDER";

  const checkboxOptions = useMemo(
    () => [
      {
        id: "chleba_authentication_confirmation",
        value: true,
        label: intl.formatMessage({
          id: "chleba_authentication_confirmation",
        }),
      },
    ],
    [intl]
  );

  const schema = Yup.object().shape({
    event: Yup.string()
      .nullable()
      .required(intl.formatMessage({ id: "error.field_required" })),
    company:
      isAdministrator || isStaff
        ? Yup.string()
            .nullable()
            .required(intl.formatMessage({ id: "error.field_required" }))
        : Yup.string().nullable(),
    opportunity: Yup.string()
      .nullable()
      .required(intl.formatMessage({ id: "error.field_required" })),
  });

  useEffect(() => {
    if (modalIsOpen) {
      handleLoading(true);
      setIsConfirmed(false);
      setChlebaAccessInformation();
      setSelectedCompany();
      setSelectedCompanyEmail();
      setSelectedCompanyRoleType();
      const config = {
        headers: {
          "Content-Type": "application/json",
          Accept: "*/*",
          Authorization: `Bearer ${token}`,
        },
      };
      if (isAdministrator || isStaff) {
        api
          .get(`/admin/events`, config)
          .then((result) => {
            const events = result.data.output.map((event) => ({
              label: event.eventEditionName,
              value: event.eventEditionCode,
              code: event.idNumber,
            }));
            let eventsSort = [...events].sort((a, b) =>
              a.label < b.label ? -1 : a.label > b.label ? 1 : 0
            );
            setEvents(eventsSort);
          })
          .catch((err) => {
            addToast({
              type: "error",
              title: intl.formatMessage({
                id: "error",
              }),
              description: intl.formatMessage({
                id: "api.error." + err.response.data.code,
              }),
            });
          })
          .finally(() => {
            handleLoading(false);
          });
      } else {
        axios
          .all([
            api.get(`/exhibitor/events/by-user`, config),
            api.patch(`/users/chleba/access-information/`, {}, config),
          ])
          .then(
            axios.spread((eventResponse, accessInformationResponse) => {
              setEvents(eventResponse.data.output);
              setChlebaAccessInformation(
                accessInformationResponse.data
                  .systemUserChlebaAccessInformationOutput
              );
            })
          )
          .catch((err) =>
            addToast({
              type: "error",
              title: intl.formatMessage({
                id: "error",
              }),
              description: intl.formatMessage({
                id: "api.error." + err.response.data.code,
              }),
            })
          )
          .finally(() => handleLoading(false));
      }
    }
  }, [
    addToast,
    intl,
    token,
    handleLoading,
    isAdministrator,
    isStaff,
    modalIsOpen,
  ]);

  useEffect(() => {
    if (selectedEvent && (isAdministrator || isStaff)) {
      handleLoading(true);
      formRef.current.setFieldValue("company");
      formRef.current.setFieldValue("opportunity");
      setIsConfirmed(false);
      api
        .get(`/admin/account/ecommerce/event/${selectedEvent}`, {
          headers: {
            "Content-Type": "application/json",
            Accept: "*/*",
            Authorization: `Bearer ${token}`,
          },
        })
        .then((result) => {
          const companies = result.data.users.map((user) => ({
            label: user.commercialName,
            value: user.id,
            email: user.email,
            type: user.roleType,
          }));
          setCompanies(companies);
        })
        .catch((err) =>
          addToast({
            type: "error",
            title: intl.formatMessage({
              id: "error",
            }),
            description: intl.formatMessage({
              id: "api.error." + err.response.data.code,
            }),
          })
        )
        .finally(() => handleLoading(false));
    }
  }, [
    selectedEvent,
    addToast,
    intl,
    token,
    handleLoading,
    isAdministrator,
    isStaff,
  ]);

  useEffect(() => {
    if (selectedCompany) {
      api
        .patch(
          `/users/admin/chleba/access-information/`,
          {
            selectedUserId: selectedCompany,
            roleType: selectedCompanyRoleType,
          },
          {
            headers: {
              "Content-Type": "application/json",
              Accept: "*/*",
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((response) =>
          setChlebaAccessInformation(
            response.data.systemUserChlebaAccessInformationOutput
          )
        )
        .catch((err) =>
          addToast({
            type: "error",
            title: intl.formatMessage({
              id: "error",
            }),
            description: intl.formatMessage({
              id: "api.error." + err.response.data.code,
            }),
          })
        )
        .finally(() => handleLoading(false));
    } else {
      setChlebaAccessInformation();
    }
  }, [
    addToast,
    handleLoading,
    intl,
    selectedCompany,
    selectedCompanyRoleType,
    token,
  ]);

  useEffect(() => {
    if (selectedEvent && (!(isAdministrator || isStaff) || selectedCompany)) {
      handleLoading(true);
      formRef.current.setFieldValue("opportunity");

      const requestHeader = {
        headers: {
          "Content-Type": "application/json",
          Accept: "*/*",
          Authorization: `Bearer ${token}`,
        },
      };

      let opportunitiesPromise =
        isAdministrator || isStaff
          ? api.patch(
              "/admin/opportunity/event-and-user",
              {
                eventEditionCode: selectedEvent,
                userId: selectedCompany,
                roleType: selectedCompanyRoleType,
              },
              requestHeader
            )
          : api.get(
              `/exhibitor/opportunity/event/${selectedEvent}`,
              requestHeader
            );

      opportunitiesPromise
        .then((result) => setOpportunities(result.data.output))
        .catch((err) =>
          addToast({
            type: "error",
            title: intl.formatMessage({
              id: "error",
            }),
            description: intl.formatMessage({
              id: "api.error." + err.response.data.code,
            }),
          })
        )
        .finally(() => handleLoading(false));
    }
  }, [
    selectedEvent,
    addToast,
    intl,
    token,
    handleLoading,
    isAdministrator,
    isStaff,
    selectedCompany,
    selectedCompanyRoleType,
  ]);

  const handleInvalidData = () => {
    setIsOpen(false);
    if (isServiceProvider) {
      setServiceProviderInvalidDataModalIsOpen(true);
    } else {
      addToast({
        type: "info",
        title: intl.formatMessage({
          id: "info",
        }),
        description: intl.formatMessage({
          id: "chleba_incorrect_data_confirmation",
        }),
      });
    }
  };

  const handleServiceProviderHandleDataConfirmation = () => {
    setServiceProviderInvalidDataModalIsOpen(false);
    history.push("/update-user");
  };

  const handleEcommerceSubmit = useCallback(
    async (data) => {
      try {
        formRef.current?.setErrors({});

        await schema.validate(data, { abortEarly: false });

        setLoading(true);
        if (loading) return;

        handleLoading(true);

        api
          .post(
            `${
              isAdministrator || isStaff ? "/admin/" : "/"
            }ecommerce/chleba/auth`,
            {
              opportunityId: data.opportunity,
              companyId: data.company,
              eventId: (isAdministrator || isStaff) ? selectedEventId : selectedEvent,
              email: selectedCompanyEmail,
              roleType: selectedCompanyRoleType,
            },
            {
              headers: {
                "Content-Type": "application/json",
                Accept: "*/*",
                Authorization: `Bearer ${token}`,
              },
            }
          )
          .then((result) =>
            window.open(
              result.data.ecommerceChlebaAuthenticationOutput.generatedUrl,
              "_blank"
            )
          )
          .catch((err) => {
            if (err.response.data.code === -18009
                || err.response.data.code === -18020
                || err.response.data.code === -18022
                || err.response.data.code === -18023) {
              addToast({
                type: "info",
                title: intl.formatMessage({
                  id: "info",
                }),
                description: intl.formatMessage({
                  id: "api.error." + err.response.data.code,
                }),
              });
            } else {
              addToast({
                type: "error",
                title: intl.formatMessage({
                  id: "error",
                }),
                description: intl.formatMessage({
                  id: "api.error." + err.response.data.code,
                }),
              });
            }
          })
          .finally(() => {
            setLoading(false);
            handleLoading(false);
          });
      } catch (err) {
        setLoading(false);
        handleLoading(false);
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);
          formRef.current?.setErrors(errors);
          return;
        }
      }
    },
    [
      schema,
      loading,
      handleLoading,
      isAdministrator,
      isStaff,
      token,
      addToast,
      intl,
      selectedEvent,
      selectedEventId,
      selectedCompanyEmail,
      selectedCompanyRoleType,
    ]
  );

  const validationBlur = (name) => {
    let errors = formRef.current.getErrors();
    validateSingleFieldOnBlur(errors, schema, name, formRef);
  };

  return (
    <>
      <ConfirmationModal
        setIsOpen={setServiceProviderInvalidDataModalIsOpen}
        modalIsOpen={serviceProviderInvalidDataModalIsOpen}
        confirmationFunction={handleServiceProviderHandleDataConfirmation}
        description={intl.formatMessage({ id: "correct_your_data" })}
        confirmationText={intl.formatMessage({ id: "yes" })}
        isLoading={isLoading}
      />
      <Modal
        setIsOpen={setIsOpen}
        modalIsOpen={modalIsOpen}
        modalWidth="50%"
        overflowX="auto"
      >
        <FormContainer>
          <Form ref={formRef} onSubmit={handleEcommerceSubmit}>
            <TitleContainer>
              <Title>
                <FormattedMessage id="access_ecommerce" />
              </Title>
            </TitleContainer>
            <FieldsContainer>
              <SelectWithLabel
                name="event"
                validationBlur={validationBlur}
                label={intl.formatMessage({ id: "event_edition" })}
                placeholder={intl.formatMessage({ id: "event_edition" })}
                onChange={(option) => {
                  setSelectedEvent(option?.value);
                  setSelectedEventId(option?.code);
                }}
                labelOrientation="vertical"
                options={events}
              />
              {(isAdministrator || isStaff) && (
                <SelectWithLabel
                  name="company"
                  validationBlur={validationBlur}
                  label={intl.formatMessage({ id: "company" })}
                  placeholder={intl.formatMessage({ id: "company" })}
                  onChange={(option) => {
                    setSelectedCompany(option?.value);
                    setSelectedCompanyEmail(option?.email);
                    setSelectedCompanyRoleType(option?.type);
                  }}
                  labelOrientation="vertical"
                  options={companies}
                />
              )}
              <SelectWithLabel
                name="opportunity"
                validationBlur={validationBlur}
                label={intl.formatMessage({ id: "contract" })}
                placeholder={intl.formatMessage({ id: "contract" })}
                labelOrientation="vertical"
                options={opportunities}
              />

              {chlebaAccessInformation && (
                <>
                  <AdditionalInformationContainer>
                    {!(isAdministrator || isStaff) && (
                      <>
                        <InformationContainer>
                          <InformationLabel>
                            <FormattedMessage id="company_name" />
                          </InformationLabel>
                          <InformationValue>
                            {chlebaAccessInformation.corporateName || "-"}
                          </InformationValue>
                        </InformationContainer>
                      </>
                    )}
                    {selectedCompanyRoleType && (
                      <InformationContainer>
                        <InformationLabel>
                          <FormattedMessage id="type" />
                        </InformationLabel>
                        <InformationValue>
                          {selectedCompanyRoleType === "EXHIBITOR"
                            ? intl.formatMessage({ id: "exhibitor" })
                            : intl.formatMessage({ id: "service_provider" })}
                        </InformationValue>
                      </InformationContainer>
                    )}
                    <InformationContainer>
                      <InformationLabel>
                        <FormattedMessage id="document" />
                      </InformationLabel>
                      <InformationValue>
                        {chlebaAccessInformation.cnpj || "-"}
                      </InformationValue>
                    </InformationContainer>
                    <InformationContainer>
                      <InformationLabel>
                        <FormattedMessage id="telephone" />
                      </InformationLabel>
                      <InformationValue>
                        {chlebaAccessInformation.telephone || "-"}
                      </InformationValue>
                    </InformationContainer>
                    {isAdministrator || isStaff ? (
                      <>
                        <InformationContainer>
                          <BoldInformationLabel>
                            <FormattedMessage id="taxes" />
                          </BoldInformationLabel>
                          <BoldInformationValue>
                            {chlebaAccessInformation.taxType || "-"}
                          </BoldInformationValue>
                        </InformationContainer>
                        <InformationContainer>
                          <BoldInformationLabel>
                            <FormattedMessage id="zip_code" />
                          </BoldInformationLabel>
                          <BoldInformationValue>
                            {chlebaAccessInformation.cep || "-"}
                          </BoldInformationValue>
                        </InformationContainer>
                        <InformationContainer>
                          <BoldInformationLabel>
                            <FormattedMessage id="address" />
                          </BoldInformationLabel>
                          <BoldInformationValue>
                            {chlebaAccessInformation.address || "-"}
                          </BoldInformationValue>
                        </InformationContainer>
                        <InformationContainer>
                          <InformationLabel>
                            <FormattedMessage id="district" />
                          </InformationLabel>
                          <InformationValue>
                            {chlebaAccessInformation.district || "-"}
                          </InformationValue>
                        </InformationContainer>
                        <InformationContainer>
                          <BoldInformationLabel>
                            <FormattedMessage id="city" />
                          </BoldInformationLabel>
                          <BoldInformationValue>
                            {chlebaAccessInformation.city || "-"}
                          </BoldInformationValue>
                        </InformationContainer>
                        <InformationContainer>
                          <BoldInformationLabel>
                            <FormattedMessage id="state" />
                          </BoldInformationLabel>
                          <BoldInformationValue>
                            {chlebaAccessInformation.state || "-"}
                          </BoldInformationValue>
                        </InformationContainer>
                      </>
                    ) : (
                      <>
                        <InformationContainer>
                          <InformationLabel>
                            <FormattedMessage id="taxes" />
                          </InformationLabel>
                          <InformationValue>
                            {chlebaAccessInformation.taxType || "-"}
                          </InformationValue>
                        </InformationContainer>
                        <InformationContainer>
                          <InformationLabel>
                            <FormattedMessage id="zip_code" />
                          </InformationLabel>
                          <InformationValue>
                            {chlebaAccessInformation.cep || "-"}
                          </InformationValue>
                        </InformationContainer>
                        <InformationContainer>
                          <InformationLabel>
                            <FormattedMessage id="address" />
                          </InformationLabel>
                          <InformationValue>
                            {chlebaAccessInformation.address || "-"}
                          </InformationValue>
                        </InformationContainer>
                        <InformationContainer>
                          <InformationLabel>
                            <FormattedMessage id="district" />
                          </InformationLabel>
                          <InformationValue>
                            {chlebaAccessInformation.district || "-"}
                          </InformationValue>
                        </InformationContainer>
                        <InformationContainer>
                          <InformationLabel>
                            <FormattedMessage id="city" />
                          </InformationLabel>
                          <InformationValue>
                            {chlebaAccessInformation.city || "-"}
                          </InformationValue>
                        </InformationContainer>
                        <InformationContainer>
                          <InformationLabel>
                            <FormattedMessage id="state" />
                          </InformationLabel>
                          <InformationValue>
                            {chlebaAccessInformation.state || "-"}
                          </InformationValue>
                        </InformationContainer>
                      </>
                    )}
                    <InformationNotification>
                      <CheckboxInput
                        name="checkbox"
                        onChange={e => setIsConfirmed(e?.target?.checked)}
                        options={checkboxOptions}
                      />
                    </InformationNotification>
                  </AdditionalInformationContainer>
                  <Horizontal>
                    <FilterActionsContainer>
                      <SubmitContainer>
                        <FormButton
                          color="#C9C9C9"
                          type="button"
                          onClick={() => setIsOpen(false)}
                          loading={isLoading}
                        >
                          <FormattedMessage id="close" />
                        </FormButton>
                      </SubmitContainer>
                      <SubmitContainer>
                        <FormButton type="submit" loading={loading} disabled={!isConfirmed}>
                          <FormattedMessage id="yes" />
                        </FormButton>
                      </SubmitContainer>
                      <SubmitContainer>
                        <FormButton
                          loading={loading}
                          onClick={() => handleInvalidData()}
                        >
                          <FormattedMessage id="no" />
                        </FormButton>
                      </SubmitContainer>
                    </FilterActionsContainer>
                  </Horizontal>
                </>
              )}
            </FieldsContainer>
          </Form>
        </FormContainer>
      </Modal>
    </>
  );
};

export default EcommerceModal;
