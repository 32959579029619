import React from "react";

import { useIntl } from "react-intl";
import { useHistory } from "react-router-dom";

import CustomModal from "../../../components/CustomModal";

import Button from "../../Button";

import Logo from "../../../newUXAssets/images/InformaLogo.png";

import {
  Container,
  Image,
  Header,
  ContainerButton,
  Text,
  Description,
} from "./styles";

const WelcomeModal = ({ open, handleClose, id }) => {
  const intl = useIntl();
  const history = useHistory();

  const handleCompleteRegistration = () => {
    handleClose();
    history.push("/user/update-user-service-provider");
  };

  return (
    <CustomModal id={id} isModalVisible={open} handleClose={() => {}}>
      <Container>
        <Header>
          <Image src={Logo} />
        </Header>
        <Text>{intl.formatMessage({ id: "welcome" })}</Text>
        <Description>
          {intl.formatMessage({ id: "welcome_description" })}
        </Description>
        <ContainerButton>
          <Button
            type="primary"
            onClick={handleCompleteRegistration}
            label={intl.formatMessage({ id: "complete_registration" })}
          />
        </ContainerButton>
      </Container>
    </CustomModal>
  );
};

export default WelcomeModal;
