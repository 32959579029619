import React, { useEffect, useRef, useState, useCallback } from "react";
import { useField } from "@unform/core";
import { MdError } from "react-icons/md";

import {
  Container,
  Error,
  RootContainer,
  Label,
  InputRow,
  LabelContainer,
  AsteriskText,
  Tooltip,
  TooltipContainer,
} from "./styles";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useWindowSize } from "../../hooks/dimensions";
import InfoTooltip from "../InfoTooltip";

const DatePickerWithHorizontalLabel = ({
  name,
  containerStyle = {},
  icon: Icon,
  validationBlur,
  validateEnd,
  disabled,
  label,
  labelWidth,
  className,
  maxWidth,
  labelOrientation,
  test,
  required,
  tooltip,
  validateBlurBasedOnOwnValue = false,
  ...rest
}) => {
  const [width] = useWindowSize();
  let language = navigator.language.split(/[-_]/)[0];

  if (language !== "pt") {
    language = "en";
  }

  const datePickerRef = useRef(null);

  const [isFocused, setIsFocused] = useState(false);
  const [isFilled, setIsFilled] = useState(false);
  const [showPortalVisualization, setShowPortalVisualization] = useState(false);

  const { fieldName, defaultValue, error, registerField } = useField(name);

  const [date, setDate] = useState(defaultValue || null);

  useEffect(() => {
    if (width <= 500) {
      setShowPortalVisualization(true);
    } else {
      setShowPortalVisualization(false);
    }
  }, [width]);

  const handleBlur = () => {
    if (validateBlurBasedOnOwnValue && validationBlur) {
      validationBlur(fieldName, datePickerRef.current?.value);
    }

    setIsFocused(false);
  };

  const handleInputBlur = useCallback(async () => {
    let fieldHasValue = date;

    setIsFilled(fieldHasValue);

    if (validationBlur) {
      validationBlur(fieldName);
    }
  }, [date, fieldName, validationBlur]);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: datePickerRef.current,
      path: "props.selected",
      clearValue: (ref) => {
        ref.clear();
      },
    });
  }, [fieldName, registerField]);

  useEffect(() => {
    setDate(defaultValue);
  }, [defaultValue]);

  useEffect(() => {
    if (date) {
      handleInputBlur();
      if (validateEnd && isFocused) {
        validateEnd();
      }
    }
  }, [date, handleInputBlur, isFocused, validateEnd]);

  return (
    <RootContainer className={className} maxWidth={maxWidth}>
      <InputRow labelOrientation={labelOrientation}>
        <LabelContainer labelWidth={labelWidth}>
          <Label labelWidth={labelWidth} labelOrientation={labelOrientation}>
            {label}
            {required && <AsteriskText>*</AsteriskText>}
          </Label>
          {tooltip && (
            <Tooltip>
              <InfoTooltip {...tooltip} />
            </Tooltip>
          )}
        </LabelContainer>
        <Container
          style={containerStyle}
          isFocused={isFocused}
          isFilled={isFilled}
          isErrored={!!error}
          disabled={disabled}
        >
          <DatePicker
            closeOnScroll={(e) => e.target === document}
            onBlur={handleBlur}
            ref={datePickerRef}
            selected={date}
            onFocus={() => setIsFocused(true)}
            onChange={(value) => {
              setDate(value);
            }}
            popperProps={{
              positionFixed: true, // use this to make the popper position: fixed
            }}
            withPortal={showPortalVisualization}
            disabled={disabled}
            locale={language}
            dateFormat="dd/MM/yyyy"
            {...rest}
          />
        </Container>
      </InputRow>

      {error && (
        <Error title={error}>
          <MdError color="#e00000" size={20} />
          {error}
        </Error>
      )}
    </RootContainer>
  );
};

export default DatePickerWithHorizontalLabel;
