import React from "react";
import ReactExport from "react-data-export";

const File = ReactExport.ExcelFile;
const Sheet = ReactExport.ExcelFile.ExcelSheet;
const Column = ReactExport.ExcelFile.ExcelColumn;

const Report = ({
  data,
  filename,
  intl,
  opportunityStatusTypes,
  children
}) =>
 (
  <File element={children} filename={filename} fileExtension="xlsx">
    <Sheet data={data} name="Relatório">
      <Column label="cnpj_passaporte" value="document" />
      <Column label="evento" value="event" />
      <Column label="razao_social" value="accountName" />
      <Column label="nome_fantasia" value="accountAlias" />
      <Column label="contrato" value="opportunity" />
      <Column
        label="status_oportunidade"
        value={ (col) => opportunityStatusTypes.filter((pt) =>
          pt.value === col.opportunityStatus)[0]?.label }
      />
      <Column label="origem" value="orderOrigin" />
      <Column label="estande" value="booth" />
      <Column label="metragem" value="area" />
      <Column label="tipo_pedido" value="orderType" />
      <Column label="numero_pedido" value="orderNumber" />
      <Column label="status_pedido" value="orderStatus" />
      <Column label="metodo_pagamento" value="paymentMethod" />
      <Column label="produto" value="name" />
      <Column
        label="categoria"
        value={ (col) => (col.orderType === 'Obrigatório' ?
          col.productType : col.categoryDescription) }
      />
      <Column label="descricao" value="productDescription" />
      <Column label="condicoes_especiais" value="specialConditions" />
      <Column label="numero_autorizacao" value="authorizationNumber" />
      <Column label="titulo_do_produto" value="productTitle" />
      <Column label="moeda" value="currency" />
      <Column label="valor_unitario" value="unitaryValue" />
      <Column label="quantidade" value="quantity" />
      <Column label="valor_total_por_produto" value="totalValueByProduct" />
      <Column label="valor_total_por_pedido" value="totalValueByOrder" />
      <Column label="desconto_total_no_pedido" value="totalDiscountInOrder" />
      <Column label="tipo_desconto_aplicado" value="appliedDiscountType" />
      <Column label="email_prestador" value="providerEmail" />
      <Column label="responsavel_operacional" value="contact" />
      <Column label="telefone_operacional" value="contactPhone" />
      <Column label="email_operacional" value="contactMail" />
      <Column label="data" value="date" />
      <Column label="data_pagamento" value="paymentDate" />
      <Column label="data_inicial" value="startDateAssembly" />
      <Column label="data_final" value="finalDateAssembly" />
      <Column label="periodo_contratado" value="periodAssembly" />
      <Column label="codigo_venda_OnSite" value="onSiteSaleCode" />
      <Column label="IR" value="ir" />
      <Column label="PIS" value="pis" />
      <Column label="COFINS" value="cofins" />
      <Column label="CSLL" value="csll" />
      <Column label="ISS" value="iss" />
    </Sheet>
  </File>
);
export default Report;