import styled, { css } from "styled-components";

import { Form, Input } from "antd";

export const Container = styled(Form.Item)`
  width: 100%;
  height: 68px;
  font-family: "Open Sans";
  font-size: 14px;
  font-style: normal;

  .ant-form-item-label {
    padding: 0 0 5px !important;
  }

  .ant-form-item-label > label {
    color: #444;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;

    svg {
      cursor: pointer !important;
      margin-left: 5px;
    }
  }

  ${(props) => {
    if (props.$isTelephone) {
      return css`
        margin-bottom: 0 !important;
      `;
    }
  }}
`;

export const CustomInput = styled(Input)`
  color: #6c6c6c;
  font-weight: 400;
  line-height: 20px;
  border-radius: 10px;
  border: 1px solid #c7c9d9;
  background: #fff;
  padding: 10px 32px 8px 24px;
  width: 100%;

  ${(props) => {
    if (props.$isTelephone) {
      return css`
        border-left: none !important;
        border-bottom-left-radius: 0px !important;
        border-top-left-radius: 0px !important;
      `;
    }
  }}
`;

export const InputSearch = styled(Input.Search)`
  color: #6c6c6c;
  border-radius: 15px;
  background: #fff;
  border: 1px solid #c7c9d9;
  width: 100%;

  input,
  .ant-input-group-addon {
    font-family: "Open Sans";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    border: none !important;
    border-right: none;
    height: 40px;
    padding: 0 22px;
    border-radius: 15px;

    button {
      border-radius: 8px !important;
      height: 30px !important;
      border-left: none;
      margin: 0 7.5px;
      padding: 4px 8px;
      background: #11a7d9;
    }
  }

  input.ant-input:focus,
  .ant-input-focused {
    border-color: transparent !important;
    box-shadow: transparent !important;
  }

  input:-webkit-autofill,
  input:-webkit-autofill:focus {
    transition: background-color 0s 600000s, color 0s 600000s !important;
  }
`;
