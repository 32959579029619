import { all, takeLatest, call } from "redux-saga/effects";

import { financialSingleSignOnService } from "../../../service/billing.service";

function* financialSingleSignOn({ payload }) {
  try {
    const response = yield call(financialSingleSignOnService);

    if (response.status === 200) {
      payload.callbackSuccess(response.data.financialGenerateTokenOutput);
    }
  } catch (error) {
    payload.callbackError(error.response.data.code);
  }
}

export default all([
  takeLatest("FINANCIAL_SINGLE_SIGN_ON", financialSingleSignOn),
]);
