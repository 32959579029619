import { useIntl } from "react-intl";

const intl = useIntl;

const provision_types = () => {
  const provision_types_array = [
    { label: intl().formatMessage({ id: "all" }), value: 0 },
    { label: intl().formatMessage({ id: "landscaping" }), value: 1 },
    { label: intl().formatMessage({ id: "safety" }), value: 2 },
    { label: intl().formatMessage({ id: "receptive" }), value: 3 },
    { label: intl().formatMessage({ id: "cleaning" }), value: 4 },
    { label: intl().formatMessage({ id: "custom_broker" }), value: 5 },
    { label: intl().formatMessage({ id: "catering" }), value: 6 },
    {
      label: intl().formatMessage({ id: "furniture_and_decoration" }),
      value: 7,
    },
    { label: intl().formatMessage({ id: "translation" }), value: 8 },
    { label: intl().formatMessage({ id: "compressed_air" }), value: 9 },
    { label: intl().formatMessage({ id: "travel_agency" }), value: 10 },
    { label: intl().formatMessage({ id: "visual_communication" }), value: 11 },
    {
      label: intl().formatMessage({ id: "electrical_and_hydraulic" }),
      value: 12,
    },
    { label: intl().formatMessage({ id: "data_collectors" }), value: 13 },
    {
      label: intl().formatMessage({ id: "transport_import_and_export" }),
      value: 14,
    },
    {
      label: intl().formatMessage({ id: "transportation_equipment_leasing" }),
      value: 15,
    },
    {
      label: intl().formatMessage({ id: "telephone_and_internet" }),
      value: 16,
    },
    { label: intl().formatMessage({ id: "press_office" }), value: 17 },
    { label: intl().formatMessage({ id: "equipment_leasing" }), value: 18 },
    { label: intl().formatMessage({ id: "carmaker" }), value: 19 },
    { label: intl().formatMessage({ id: "carpet" }), value: 20 },
    { label: intl().formatMessage({ id: "cargo_handling" }), value: 21 },
    { label: intl().formatMessage({ id: "reception" }), value: 22 },
    { label: intl().formatMessage({ id: "insurance" }), value: 23 },
    { label: intl().formatMessage({ id: "agency" }), value: 24 },
    { label: intl().formatMessage({ id: "coexhibitor" }), value: 25 },
    { label: intl().formatMessage({ id: "others" }), value: 26 },
  ];

  return provision_types_array;
};

export default provision_types;
