import React from "react";

import { useIntl } from "react-intl";

import CustomModal from "../../../components/CustomModal";

import Button from "../../Button";

import {
  Container,
  Divider,
  Header,
  ContainerButton,
  Description,
} from "./styles";

const LogoutFirstAccessModal = ({ open, handleClose, id, handleLogout }) => {
  const intl = useIntl();

  const handleCompleteRegistration = () => {
    handleClose();
    handleLogout();
  };

  return (
    <CustomModal id={id} isModalVisible={open} handleClose={() => {}}>
      <Container>
        <Header>
          <span>{intl.formatMessage({ id: "logout" })}</span>
        </Header>
        <Divider />
        <Description>
          {intl.formatMessage({ id: "logout_first_access" })}
        </Description>
        <ContainerButton>
          <Button
            type="primary"
            onClick={handleCompleteRegistration}
            label={intl.formatMessage({ id: "logout" })}
          />
          <Button
            type="link"
            onClick={() => handleClose()}
            label={intl.formatMessage({ id: "return_to_form" })}
          />
        </ContainerButton>
      </Container>
    </CustomModal>
  );
};

export default LogoutFirstAccessModal;
