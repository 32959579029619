import { all, takeLatest, call, put } from "redux-saga/effects";

import {
  setAllContractByEvent,
  setContractSuccess,
  setOrderByContractSuccess,
} from "./actions";
import {
  getAllContractByEventService,
  getContractByExhibitorService,
  getOrderByContractService,
  getContractByServiceProviderService,
  changeHeadbandExhibitorService,
} from "../../../service/contract.service";

function* getAllContractByEvent({ payload }) {
  try {
    const response = yield call(getAllContractByEventService, payload.id);

    if (response.status === 200) {
      yield put(
        setAllContractByEvent({
          allContract: response.data.output,
        })
      );
      payload.callbackSuccess()
    }
  } catch (error) {
    payload.callbackError(error.response.data.code);
  }
}

function* getContractByExhibitor({ payload }) {
  try {
    const response = yield call(getContractByExhibitorService, payload.params);

    if (response.status === 200) {
      yield put(setContractSuccess(response.data.output));
    }
  } catch (error) {
    payload.callbackError(error.response.data.code);
  }
}

function* getOrderByContract({ payload }) {
  try {
    const response = yield call(getOrderByContractService, payload.params);

    if (response.status === 200) {
      yield put(
        setOrderByContractSuccess(
          response.data.ecommerceChlebaOrderFilterOutput
        )
      );
    }
  } catch (error) {
    payload.callbackError(error.response.data.code);
  }
}

function* getContractByServiceProvider({ payload }) {
  try {
    const response = yield call(
      getContractByServiceProviderService,
      payload.params
    );

    if (response.status === 200) {
      yield put(setContractSuccess(response.data.output));
    }
  } catch (error) {
    payload.callbackError(error.response.data.code);
  }
}

function* changeHeadbandExhibitor({ payload }) {
  try {
    const response = yield call(changeHeadbandExhibitorService, payload.params);

    if (response.status === 200) {
      payload.callbackSuccess();
    }
  } catch (error) {
    payload.callbackError(error.response.data.code);
  }
}

export default all([
  takeLatest("GET_ALL_CONTRACT_BY_EVENT", getAllContractByEvent),
  takeLatest("GET_CONTRACT_BY_EXHIBITOR", getContractByExhibitor),
  takeLatest("GET_ORDER_BY_CONTRACT", getOrderByContract),
  takeLatest("GET_CONTRACT_BY_SERVICE_PROVIDER", getContractByServiceProvider),
  takeLatest("CHANGE_HEADBAND_EXHIBITOR", changeHeadbandExhibitor),
]);
