import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useSelector } from "react-redux";
import { useHistory } from "react-router";
import AnimatedMainContainer from "../../components/AnimatedMainContainer";
import { useToast } from "../../hooks/toast";
import * as Yup from "yup";
import getValidationErrors from "../../utils/getValidationErrors";
import api from "../../service";
import InputWithLabel from "../../components/InputWithLabel";
import MaskedInputWithLabel from "../../components/MaskedInputWithLabel";
import FormButton from "../../components/FormButton";
import { Form } from "@unform/web";
import { validateSingleFieldOnBlur } from "../../utils/formValidations";
import { format } from "date-fns";

import {
  Container,
  FormContainer,
  TitleContainer,
  Title,
  FieldsContainer,
  Horizontal,
  FilterActionsContainer,
  SubmitContainer,
  LinkButton,
  GoBackContainer,
} from "./styles";
import Table from "../../components/Table";
import Badge from "../../components/Badge";
import { cnpjMask } from "../../utils/mask";

const Accounts = () => {
  const { token } = useSelector((state) => state.auth);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const formRef = useRef(null);
  const intl = useIntl();
  const history = useHistory();
  const { addToast } = useToast();

  const [totalRows, setTotalRows] = useState(0);
  const [perPage, setPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);

  const schema = Yup.object().shape({
    type: Yup.string().nullable(),
    document: Yup.string().nullable(),
    name: Yup.string().nullable(),
    status: Yup.string().nullable(),
  });

  const fetchData = useCallback(
    async (page, size = perPage, requestBody = {}, resetCurrentPage) => {
      setLoading(true);
      if (loading) return;

      if (resetCurrentPage) setCurrentPage(1);

      api
        .patch(`/admin/account?page=${page}&per_page=${size}`, requestBody, {
          headers: {
            "Content-Type": "application/json",
            Accept: "*/*",
            Authorization: `Bearer ${token}`,
          },
        })
        .then((result) => {
          setTotalRows(result.data.paginationInfo.totalElements);
          setData(result.data.output);
        })
        .catch((err) =>
          addToast({
            type: "error",
            title: intl.formatMessage({
              id: "error",
            }),
            description: intl.formatMessage({
              id: "api.error." + err.response.data.code,
            }),
          })
        )
        .finally(() => setLoading(false));
    },
    [addToast, intl, loading, perPage, token]
  );

  const handleSubmit = useCallback(
    async (data) => {
      try {
        formRef.current?.setErrors({});

        await schema.validate(data, { abortEarly: false });

        fetchData(
          0,
          perPage,
          {
            name: data.name === "" ? null : data.name,
            cnpj: data.document === "" ? null : data.document,
          },
          true
        );
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);

          formRef.current?.setErrors(errors);

          return;
        }
      }
    },
    [fetchData, perPage, schema]
  );

  const clearFields = useCallback(() => {
    formRef.current.reset();
    setCurrentPage(1);
    fetchData(currentPage - 1, perPage);
  }, [currentPage, fetchData, perPage]);

  const validationBlur = (name) => {
    let errors = formRef.current.getErrors();
    validateSingleFieldOnBlur(errors, schema, name, formRef);
  };

  useEffect(() => fetchData(0, 10), []);

  const columns = useMemo(
    () => [
      {
        name: intl.formatMessage({ id: "register_date" }),
        selector: (row) => row.createdAt,
        format: (row) => format(new Date(row.createdAt), "dd/MM/yyyy"),
      },
      {
        name: intl.formatMessage({ id: "name" }),
        selector: (row) => row.accountName,
      },
      {
        name: intl.formatMessage({ id: "document_filter" }),
        selector: (row) => row.accountGovCompanyCode,
      },
      {
        name: intl.formatMessage({ id: "active_contracts" }),
        selector: (row) => row.opportunitiesContractNumber,
        format: (row) => {
          if (!row.opportunityContractNumbers) return "";

          const contractNumbers = row.opportunityContractNumbers?.join(", ");
          return <div title={contractNumbers}>{contractNumbers}</div>;
        },
      },
      {
        cell: (row) => (
          <LinkButton to={`/account/${row.accountId}`}>
            <Badge
              color="#002244"
              text={intl.formatMessage({ id: "see_details" })}
            />
          </LinkButton>
        ),
        right: true,
      },
    ],
    [intl]
  );

  const handlePageChange = (page) => {
    fetchData(page - 1);
    setCurrentPage(page);
  };

  const handlePerRowsChange = async (newPerPage, page) => {
    fetchData(page - 1, newPerPage);
    setPerPage(newPerPage);
  };

  return (
    <AnimatedMainContainer
      breadcrumb={intl.formatMessage({ id: "breadcrumb.accounts" })}
    >
      <Container>
        <FormContainer>
          <Form ref={formRef} onSubmit={handleSubmit}>
            <TitleContainer>
              <Title>
                <FormattedMessage id="search_for_accounts" />
              </Title>
              <GoBackContainer>
                <LinkButton onClick={() => history.push("/dashboard")}>
                  <Badge
                    color="#002244"
                    text={intl.formatMessage({ id: "goback" })}
                    fontSize="12px"
                  />
                </LinkButton>
              </GoBackContainer>
            </TitleContainer>
            <FieldsContainer>
              <Horizontal>
                <MaskedInputWithLabel
                  name="document"
                  mask={cnpjMask}
                  validationBlur={validationBlur}
                  label={intl.formatMessage({ id: "document_cnpj" })}
                />
                <InputWithLabel
                  name="name"
                  validationBlur={validationBlur}
                  label={intl.formatMessage({ id: "name" })}
                />
              </Horizontal>
              <Horizontal>
                <FilterActionsContainer>
                  <SubmitContainer>
                    <FormButton
                      color="#C9C9C9"
                      type="button"
                      onClick={clearFields}
                    >
                      <FormattedMessage id="clear_fields" />
                    </FormButton>
                  </SubmitContainer>
                  <SubmitContainer>
                    <FormButton type="submit" loading={loading}>
                      <FormattedMessage id="search" />
                    </FormButton>
                  </SubmitContainer>
                </FilterActionsContainer>
              </Horizontal>
            </FieldsContainer>
          </Form>
        </FormContainer>
        <Table
          data={data}
          columns={columns}
          progressPending={loading}
          paginationServer
          paginationTotalRows={totalRows}
          paginationDefaultPage={currentPage}
          onChangeRowsPerPage={handlePerRowsChange}
          onChangePage={handlePageChange}
        />
      </Container>
    </AnimatedMainContainer>
  );
};

export default Accounts;
