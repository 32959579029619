import React from "react";

const UserSwitch = (props) => {
  const { size = "26", color = "black" } = props;

  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 26 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_295_60748)">
        <path
          d="M13 16.25C15.2437 16.25 17.0625 14.4312 17.0625 12.1875C17.0625 9.94384 15.2437 8.125 13 8.125C10.7563 8.125 8.9375 9.94384 8.9375 12.1875C8.9375 14.4312 10.7563 16.25 13 16.25Z"
          stroke={color}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M6.48047 20.2485C7.09178 19.0444 8.02458 18.0331 9.17547 17.3267C10.3264 16.6203 11.6504 16.2463 13.0008 16.2463C14.3512 16.2463 15.6752 16.6203 16.8261 17.3267C17.977 18.0331 18.9098 19.0444 19.5211 20.2485"
          stroke={color}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M20.3125 13L22.75 15.4375L25.1875 13"
          stroke={color}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M0.8125 13L3.25 10.5625L5.6875 13"
          stroke={color}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M3.25 10.5625V13C3.24923 15.0643 3.90365 17.0755 5.11901 18.7441C6.33438 20.4126 8.04795 21.6524 10.013 22.2848C11.978 22.9172 14.093 22.9096 16.0534 22.2632C18.0138 21.6167 19.7185 20.3647 20.9219 18.6875"
          stroke={color}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M22.75 15.4375V13C22.7508 10.9357 22.0963 8.92449 20.881 7.25594C19.6656 5.58738 17.952 4.34762 15.987 3.7152C14.022 3.08279 11.907 3.09036 9.9466 3.73682C7.98617 4.38329 6.28151 5.63528 5.07812 7.3125"
          stroke={color}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_295_60748">
          <rect width={size} height={size} fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default UserSwitch;
