import React, { useCallback, useRef, useState, useEffect } from "react";
import { useHistory } from "react-router-dom";

import { MdInfo, MdPermContactCalendar } from "react-icons/md";
import { FaFileContract } from "react-icons/fa"

import { Form } from "@unform/web";
import * as Yup from "yup";
import getValidationErrors from "../../../utils/getValidationErrors";

import { useDispatch, useSelector } from "react-redux";

import { FormattedMessage, useIntl } from "react-intl";

import {
  Container,
  HeaderContainer,
  TitleContainer,
  Title,
  SessionDivider,
  Session,
  SessionContainer,
  SessionIconContainer,
  SessionTitle,
  FieldsContainer,
  ActionContainer,
  ActionContainerSave,
  BadgeContainer,
  Horizontal,
  CredentialFieldsContainer,
  ConsultContractTitleContainer,
} from "./styles";

import Input from "../../../components/Input";

import { useToast } from "../../../hooks/toast";
import { validateSingleFieldOnBlur } from "../../../utils/formValidations";
import { updateUser } from "../../../redux/modules/auth/actions";
import api from "../../../service";
import AnimatedMainContainer from "../../../components/AnimatedMainContainer";
import Badge from "../../../components/Badge";
import SelectWithLabel from "../../../components/SelectWithLabel";

const UpdateUser = () => {
  const formRef = useRef(null);
  const intl = useIntl();
  const dispatch = useDispatch();
  const { addToast } = useToast();
  const { user, token } = useSelector((state) => state.auth);
  const [loading, setLoading] = useState(false);
  const [allEvents, setAllEvents] = useState([]);
  const [opportunities, setOpportunities] = useState([]);
  const [event, setEvent] = useState();
  const [data, setData] = useState()

  const history = useHistory();

  const schema = Yup.object().shape({
    event: Yup.string()
      .nullable()
      .required(intl.formatMessage({ id: "error.field_required" })),
    contract: Yup.string()
      .nullable()
      .required(intl.formatMessage({ id: "error.field_required" })),
  });

  const schemaCredential = Yup.object().shape({
    credential_name: Yup.string().required(
      intl.formatMessage({ id: "error.field_required" })
    ),
  });

  const handleSubmit = useCallback(
    async (data) => {
      try {
        formRef.current?.setErrors({});

        setLoading(true);
        if (loading) {
          return;
        }

        await schemaCredential.validate(data, { abortEarly: false });

        api
          .put(
            `/exhibitor/account`,
            {
              companyNameCredential: data.credential_name,
            },
            {
              headers: {
                "Content-Type": "application/json",
                Accept: "*/*",
                Authorization: `Bearer ${token}`,
              },
            }
          )
          .then((result) => {
            setLoading(false);
            dispatch(
              updateUser({
                user: result.data.user,
              })
            );
            addToast({
              type: "success",
              title: intl.formatMessage({
                id: "success",
              }),
              description: intl.formatMessage({
                id: "success.user_update",
              }),
            });
            history.push("/dashboard");
          })
          .catch((err) => {
            setLoading(false);
            addToast({
              type: "error",
              title: intl.formatMessage({
                id: "error",
              }),
              description: intl.formatMessage({
                id: "api.error." + err.response.data.code,
              }),
            });
          });
      } catch (err) {
        setLoading(false);
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);

          formRef.current?.setErrors(errors);

          return;
        }
      }
    },
    [addToast, dispatch, history, intl, schema, token, loading]
  );

  const handleSubmitData = useCallback(
    async () => {
      try {
        formRef.current?.setErrors({});
        await schema.validate(formRef.current?.getData(), { abortEarly: false });

        setLoading(true);
        if (loading) {
          return;
        }

        api
          .patch(
            "/exhibitor",
            {
              eventId: formRef.current?.getFieldValue('event'),
              opportunityId: formRef.current?.getFieldValue('contract'),
            },
            {
              headers: {
                "Content-Type": "application/json",
                Accept: "*/*",
                Authorization: `Bearer ${token}`,
              },
            }
          )
          .then((result) => {
            setData(result.data.output);
          })
          .catch((err) => {
            setData([]);
          })
          .finally(() => {
            setLoading(false);
          });
      } catch (err) {
        setLoading(false);
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);

          formRef.current?.setErrors(errors);

          return;
        }
      }
    },
    [loading, token, schema]
  );

  const validationBlur = (name) => {
    let errors = formRef.current.getErrors();

    validateSingleFieldOnBlur(errors, schema, name, formRef);
  };

  const validationBlurCredentials = (name) => {
    let errors = formRef.current.getErrors();

    validateSingleFieldOnBlur(errors, schemaCredential, name, formRef);
  };

  const pushBack = () => {
    history.push("/dashboard");
  };

  const getEvents = useCallback(() => {
    setLoading(true);
    api
      .get("/provider-association/events", {
        headers: {
          "Content-Type": "application/json",
          Accept: "*/*",
          Authorization: `Bearer ${token}`,
        },
      })
      .then((result) => {
        const events = result.data.output.map((event) => ({
          label: event.eventEditionName,
          value: event.idNumber,
        }));
        let eventsSort = [...events].sort((a, b) =>
          a.label < b.label ? -1 : a.label > b.label ? 1 : 0
        );
        setAllEvents(eventsSort);
      })
      .catch((err) => {
        addToast({
          type: "error",
          title: intl.formatMessage({
            id: "error",
          }),
          description: intl.formatMessage({
            id: "api.error." + err.response.data.code,
          }),
        });
      })
      .finally(() => {
        setLoading(false);
      });
  }, [addToast, intl, token, setLoading]);

  const getOpportunities = useCallback(
    (event) => {
      setLoading(true);
      api
        .get(`/provider-association/opportunities/${event}`, {
          headers: {
            "Content-Type": "application/json",
            Accept: "*/*",
            Authorization: `Bearer ${token}`,
          },
        })
        .then((result) => {
          setOpportunities(
            result.data.output.map((x) => ({ label: x.name, value: x.id }))
          );
        })
        .catch((err) => {
          addToast({
            type: "error",
            title: intl.formatMessage({
              id: "error",
            }),
            description: intl.formatMessage({
              id: "api.error." + err.response.data.code,
            }),
          });
        })
        .finally(() => {
          setLoading(false);
        });
    },
    [addToast, intl, token, setLoading]
  );

  useEffect(() => {
    getEvents();
  }, [getEvents]);

  useEffect(() => {
    if (event) getOpportunities(event);
  }, [event, getOpportunities]);

  const onChangeEventEdition = (e) =>{
    setEvent(e.value)
    formRef.current?.setFieldValue('contract', "")
  } 

  return (
    <AnimatedMainContainer>
        <Form
          ref={formRef}
          onSubmit={handleSubmit}
          initialData={{
            name: data?.name,
            commercial_name: data?.accountAlias,
            cnpj: data?.cnpj,
            classification: data?.nationalClassification,
            responsible_for_the_invoice: data?.billingContact,
            operational_manager: data?.operationsContact,
            fiscal_email: data?.billingContactEmail,
            operational_email: data?.operationsContactEmail,
            address: data?.avenue,
            address_number: data?.number,
            city: data?.city,
            state: data?.state,
            address_complement: data?.zipCode,
            telephone: data?.telephone,
            mobile: data?.mobile,
            credential_name: user.credentialName,
          }}
        >
          <Container>
            <HeaderContainer>
              <TitleContainer>
                <Title>
                  <FormattedMessage id="my_account" />
                </Title>
              </TitleContainer>
              <ActionContainer>
                <BadgeContainer onClick={() => pushBack()}>
                  <Badge
                    color="#C9C9C9"
                    text={intl.formatMessage({ id: "goback" })}
                    fontSize="12px"
                  />
                </BadgeContainer>
              </ActionContainer>
            </HeaderContainer>
            <CredentialFieldsContainer>
              <Input
                name="credential_name"
                placeholder={intl.formatMessage({
                  id: "company_name_credential",
                })}
                hasLabel
                validationBlur={validationBlurCredentials}
                required
              />
              <ActionContainerSave>
                <BadgeContainer onClick={() => formRef.current.submitForm()}>
                  <Badge
                    color="#002244"
                    text={intl.formatMessage({ id: "save" })}
                    fontSize="12px"
                    loading={loading}
                  />
                </BadgeContainer>
              </ActionContainerSave>
            </CredentialFieldsContainer>
          </Container>
          <Container>
            <ConsultContractTitleContainer>
              <SessionIconContainer>
                <FaFileContract size="16px" color="#002244" />
              </SessionIconContainer>
              <SessionTitle>
                <FormattedMessage id="contract_consult" />
              </SessionTitle>
            </ConsultContractTitleContainer>
            <Horizontal>
              <SelectWithLabel
                name="event"
                options={allEvents}
                validationBlur={validationBlur}
                label={intl.formatMessage({ id: "event_edition" })}
                placeholder={intl.formatMessage({ id: "event_edition" })}
                onChange={onChangeEventEdition}
                required
              />
              <SelectWithLabel
                name="contract"
                options={opportunities}
                validationBlur={validationBlur}
                label={intl.formatMessage({ id: "contract" })}
                placeholder={intl.formatMessage({ id: "contract" })}
                required
              />
            </Horizontal>
            <ActionContainerSave>
              <BadgeContainer onClick={() => handleSubmitData()}>
                <Badge
                  color="#002244"
                  text={intl.formatMessage({ id: "search" })}
                  fontSize="12px"
                  loading={loading}
                />
              </BadgeContainer>
            </ActionContainerSave>
            <SessionDivider nr_items="2">
              <Session>
                <SessionContainer>
                  <SessionIconContainer>
                    <MdInfo size="16px" color="#002244" />
                  </SessionIconContainer>
                  <SessionTitle>
                    <FormattedMessage id="informations" />
                  </SessionTitle>
                </SessionContainer>
                <FieldsContainer>
                  <Input
                    name="name"
                    placeholder={intl.formatMessage({
                      id: "company_name",
                    })}
                    hasLabel
                    disabled
                    validationBlur={validationBlur}
                  />
                  <Input
                    name="commercial_name"
                    placeholder={intl.formatMessage({
                      id: "commercial_name",
                    })}
                    hasLabel
                    disabled
                  />
                  <Input
                    name="cnpj"
                    placeholder="CNPJ"
                    hasLabel
                    validationBlur={validationBlur}
                    disabled
                  />
                  <Input
                    name="classification"
                    placeholder={intl.formatMessage({
                      id: "classification",
                    })}
                    hasLabel
                    validationBlur={validationBlur}
                    disabled
                  />
                  <Input
                    name="responsible_for_the_invoice"
                    placeholder={intl.formatMessage({
                      id: "responsible_for_the_invoice",
                    })}
                    hasLabel
                    validationBlur={validationBlur}
                    disabled
                  />
                  <Input
                    name="operational_manager"
                    placeholder={intl.formatMessage({
                      id: "operational_manager",
                    })}
                    hasLabel
                    validationBlur={validationBlur}
                    disabled
                  />
                  <Input
                    name="fiscal_email"
                    placeholder={intl.formatMessage({
                      id: "fiscal_email",
                    })}
                    hasLabel
                    validationBlur={validationBlur}
                    disabled
                  />
                  <Input
                    name="operational_email"
                    placeholder={intl.formatMessage({
                      id: "operational_email",
                    })}
                    hasLabel
                    validationBlur={validationBlur}
                    disabled
                  />
                </FieldsContainer>
              </Session>
              <Session>
                <SessionContainer>
                  <SessionIconContainer>
                    <MdPermContactCalendar size="16px" color="#002244" />
                  </SessionIconContainer>
                  <SessionTitle>
                    <FormattedMessage id="contact_data" />
                  </SessionTitle>
                </SessionContainer>
                <FieldsContainer>
                  <Input
                    name="address"
                    placeholder={intl.formatMessage({
                      id: "address",
                    })}
                    hasLabel
                    disabled
                  />
                  <Input
                    name="address_number"
                    placeholder={intl.formatMessage({
                      id: "address_number",
                    })}
                    hasLabel
                    validationBlur={validationBlur}
                    disabled
                    maxLength="80"
                  />
                  <Input
                    name="city"
                    placeholder={intl.formatMessage({
                      id: "city",
                    })}
                    hasLabel
                    validationBlur={validationBlur}
                    disabled
                  />
                  <Input
                    name="state"
                    placeholder={intl.formatMessage({
                      id: "state",
                    })}
                    hasLabel
                    validationBlur={validationBlur}
                    disabled
                  />
                  <Input
                    name="address_complement"
                    placeholder={intl.formatMessage({
                      id: "address_complement",
                    })}
                    hasLabel
                    validationBlur={validationBlur}
                    disabled
                  />
                  <Input
                    name="telephone"
                    placeholder={intl.formatMessage({
                      id: "telephone",
                    })}
                    hasLabel
                    validationBlur={validationBlur}
                    disabled
                  />
                  <Input
                    name="mobile"
                    placeholder={intl.formatMessage({
                      id: "mobile",
                    })}
                    hasLabel
                    validationBlur={validationBlur}
                    disabled
                  />
                </FieldsContainer> 
              </Session>
            </SessionDivider>
          </Container>
        </Form>
    </AnimatedMainContainer>
  );
};

export default UpdateUser;
