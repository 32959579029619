import React, {useMemo} from 'react'

import { convertToDateOnly } from '../../../utils/date'
import { currencyMask } from '../../../utils/currency'
import logoImg from "../../../assets/images/logo.png";

import {
  Container,
  Header,
  LogoContainer,
  InformaMarketDescriptionContainer,
  InformaMarketDescriptionTitle,
  InformaMarketDescriptionContent,
  InvoiceContainer,
  ContainerHeader,
  HeaderTitle,
  FirstContainer,
  InfoContainer,
  InfoLabelContent,
  InfoLabel,
  InfoContent,
  Label,
  Content,
  DividedHeader,
  DividedBody,
  Divider,
  ProductsContainer,
  TotalAmountContainer,
  FirstPageContainer,
  ResultLabelContent,
  ResultLabel,
  ResultContent,
  ContainerPage2,
  PageLabel,
  Product,
  Observation,
  DividedBodyObservation
} from './styles.js'

const MAX_PRODUCTS_PER_PAGE = 23
const MIN_PRODUCTS_TO_TRIGGER_SECOND_PAGE = 14


const InvoicePDF = ({order})=>{


  const isPortuguese = useMemo(()=>{
    if(order.document === "99999999999999") return false
    return true
  },[order])

  const getLabel = (label)=>{
    if(isPortuguese)
      switch(label) {
        case 'invoice': return 'RECIBO'
        case 'customer': return 'CLIENTE:'
        case 'cnpj': return 'CNPJ:'
        case 'date': return 'DATA:'
        case 'invoiceNumber': return 'PEDIDO:'
        case 'description': return 'DESCRIÇÃO'
        case 'originalAmount': return 'VALOR \u200a BRUTO'
        case 'grossTotal': return 'TOTAL \u200a BRUTO:'
        case 'totalAmount': return 'TOTAL \u0020 LÍQUIDO:'
        case 'page1': return 'PÁGINA \u0020 1/2'
        case 'page2': return 'PÁGINA \u0020 2/2'
        case 'observation': return 'Obs: A Nota Fiscal será emitida conforme a legislação da cidade de São Paulo.'
        case 'observation2': return 'Este recibo só tem validade acompanhado do comprovante de pagamento.'
        default: return 'ERRO'
      }
    else
      switch(label) {
        case 'invoice': return 'INVOICE'
        case 'customer': return 'CUSTOMER:'
        case 'date': return 'DATE:'
        case 'invoiceNumber': return 'INVOICE \u0020 NUMBER:'
        case 'description': return 'DESCRIPTION'
        case 'originalAmount': return 'ORIGINAL \u200a AMOUNT'
        case 'grossTotal': return 'GROSS \u200a TOTAL:'
        case 'totalAmount': return 'TOTAL \u0020 AMOUNT:'
        case 'page1': return 'PAGE \u0020 1/2'
        case 'page2': return 'PAGE \u0020 2/2'
        case 'observation': return 'This receipt is only valid when accompanied by proof of payment.'
        case 'observation2': return ''
        default: return 'ERROR'
    }

  }

  return (
    <>
    <Container>
      <Header>
        <LogoContainer><img src={logoImg} alt='Logo Informa'/></LogoContainer>
        <InformaMarketDescriptionContainer>
          <InformaMarketDescriptionTitle style={{fontFamily: 'Times'}}>Informa Markets LTDA.</InformaMarketDescriptionTitle>
          <InformaMarketDescriptionContent style={{fontFamily: 'Times'}}>CNPJ: 01.914.765/0001-08</InformaMarketDescriptionContent>
          <InformaMarketDescriptionContent style={{fontFamily: 'Times'}}>Av. Dra. Ruth Cardoso, 7221 - 22º andar - Pinheiros</InformaMarketDescriptionContent>
          <InformaMarketDescriptionContent style={{fontFamily: 'Times'}}>CEP: 05425-902- São Paulo - SP</InformaMarketDescriptionContent>
        </InformaMarketDescriptionContainer>
      </Header>
      <InvoiceContainer>
        <ContainerHeader>
          <HeaderTitle>{getLabel('invoice')}</HeaderTitle>
        </ContainerHeader>
        <FirstContainer>
          <InfoContainer>
            <InfoLabelContent>
              <InfoLabel>
                <Label style={{fontFamily: 'Times'}} >{getLabel('customer')}</Label>
              </InfoLabel>
              <InfoContent>
                <Content style={{fontFamily: 'Times'}} >{order.customer}</Content>
              </InfoContent>
            </InfoLabelContent>
            {isPortuguese && 
            <InfoLabelContent>
              <InfoLabel>
                <Label style={{fontFamily: 'Times'}}>{getLabel('cnpj')}</Label>
              </InfoLabel>
              <InfoContent>
                <Content style={{fontFamily: 'Times'}}>{order.document}</Content>
              </InfoContent>
            </InfoLabelContent>}
          </InfoContainer>
          <InfoContainer>
            <InfoLabelContent>
              <InfoLabel>
                <Label style={{fontFamily: 'Times'}}>{getLabel('date')}</Label>
              </InfoLabel>
              <InfoContent>
                <Content style={{fontFamily: 'Times'}}>{convertToDateOnly(order.date)}</Content>
              </InfoContent>
            </InfoLabelContent>
            <InfoLabelContent>
              <InfoLabel>
                <Label style={{fontFamily: 'Times'}}>{getLabel('invoiceNumber')}</Label>
              </InfoLabel>
              <InfoContent>
                <Content style={{fontFamily: 'Times'}}>{order.invoiceNumber}</Content>
              </InfoContent>
            </InfoLabelContent>
          </InfoContainer>
        </FirstContainer>
        <ProductsContainer>
          <DividedHeader>
            <div><HeaderTitle style={{fontFamily: 'Times'}}>{getLabel('description')}</HeaderTitle></div>
            <div><HeaderTitle style={{fontFamily: 'Times'}}>{getLabel('originalAmount')}</HeaderTitle></div>
          </DividedHeader>
          {order.productsList.map((product, idx) => {
            if(idx >= MAX_PRODUCTS_PER_PAGE) return (<></>)
            else return (
              <DividedBody>
                <div className={idx===0 && 'firstCell'}>
                  
                    <Product style={{fontFamily: 'Times'}}>
                      {isPortuguese ? product.descriptionPT:product.descriptionEN}
                    </Product>
                  
                </div>
                <div className={idx===0 && 'firstCell'}>
                  
                    <Product style={{fontFamily: 'Times'}}>
                      {currencyMask(product.originalAmount)}
                    </Product>
                  
                </div>
              </DividedBody>
            )
          }
          )}
          {order.productsList.length <= MIN_PRODUCTS_TO_TRIGGER_SECOND_PAGE &&
          <>
            <DividedBody>
              <div className='taxCell'><Product style={{fontFamily: 'Times'}}>COFINS</Product></div>
              <div><Product style={{fontFamily: 'Times'}}>{currencyMask(order.cofins)}</Product></div>
            </DividedBody>
            <DividedBody>
              <div className='taxCell'><Product style={{fontFamily: 'Times'}}>CSLL</Product></div>
              <div><Product style={{fontFamily: 'Times'}}>{currencyMask(order.csll)}</Product></div>
            </DividedBody>
            <DividedBody>
              <div className='taxCell'><Product style={{fontFamily: 'Times'}}>IR</Product></div>
              <div><Product style={{fontFamily: 'Times'}}>{currencyMask(order.ir)}</Product></div>
            </DividedBody>
            <DividedBody>
              <div className='taxCell'><Product style={{fontFamily: 'Times'}}>ISS</Product></div>
              <div><Product style={{fontFamily: 'Times'}}>{currencyMask(order.iss)}</Product></div>
            </DividedBody>
            <DividedBody>
              <div className='taxCell'><Product style={{fontFamily: 'Times'}}>PIS</Product></div>
              <div><Product style={{fontFamily: 'Times'}}>{currencyMask(order.pis)}</Product></div>
            </DividedBody>
            <Divider/>
            <DividedBody>
              <div className='lastCell'><Product style={{fontFamily: 'Times'}}></Product></div>
              <div className='lastCell'><Product style={{fontFamily: 'Times'}}>{getLabel('grossTotal')}</Product><Product style={{fontFamily: 'Times'}}>{currencyMask(order.grossTotal)}</Product></div>
            </DividedBody>
            <Divider/>
            <DividedBodyObservation>
              <div><Observation style={{fontFamily: 'Times'}}>{getLabel('observation')}</Observation></div>
              <div></div>
            </DividedBodyObservation>
            <DividedBodyObservation>
              <div><Observation style={{fontFamily: 'Times'}}>{getLabel('observation2')}</Observation></div>
              <div></div>
            </DividedBodyObservation>
          </>
          }
        </ProductsContainer>

      </InvoiceContainer>
      {order.productsList.length <= MIN_PRODUCTS_TO_TRIGGER_SECOND_PAGE &&
      <TotalAmountContainer>
        <ResultLabelContent>
          <ResultLabel>
            <Label style={{fontFamily: 'Times'}}>{getLabel('totalAmount')}</Label>
          </ResultLabel>
          <ResultContent>
            <Content style={{fontFamily: 'Times'}}>{currencyMask(order.totalAmount)}</Content>
          </ResultContent>
        </ResultLabelContent>
      </TotalAmountContainer>}
      {order.productsList.length > MIN_PRODUCTS_TO_TRIGGER_SECOND_PAGE &&
      <FirstPageContainer>
        <PageLabel>
          <Label style={{fontFamily: 'Times'}}>{getLabel('page1')}</Label>
        </PageLabel>
      </FirstPageContainer>}
    </Container>
    {order.productsList.length > MIN_PRODUCTS_TO_TRIGGER_SECOND_PAGE &&
      <ContainerPage2>
        <InvoiceContainer>
          <ProductsContainer>
            <DividedHeader>
              <div><HeaderTitle style={{fontFamily: 'Times'}}>{getLabel('description')}</HeaderTitle></div>
              <div><HeaderTitle style={{fontFamily: 'Times'}}>{getLabel('originalAmount')}</HeaderTitle></div>
            </DividedHeader>
            {order.productsList.map((product, idx) => {
              if(idx < MAX_PRODUCTS_PER_PAGE) return (<></>)
              else return (
                <DividedBody>
                  <div className={idx===MAX_PRODUCTS_PER_PAGE && 'firstCell'}>
                    
                      <Product style={{fontFamily: 'Times'}}>
                        {isPortuguese ? product.descriptionPT:product.descriptionEN}
                      </Product> 
                    
                  </div>
                  <div className={idx===MAX_PRODUCTS_PER_PAGE && 'firstCell'}>
                    
                      <Product style={{fontFamily: 'Times'}}>
                        {currencyMask(product.originalAmount)}
                      </Product>
                    
                  </div>
                </DividedBody>
              )
            }
            )}
            <DividedBody>
              <div className={order.productsList.length <= MAX_PRODUCTS_PER_PAGE ? 'taxCell firstCell' : 'taxCell'}>
                <Product style={{fontFamily: 'Times'}}>COFINS</Product>
              </div>
              <div className={order.productsList.length <= MAX_PRODUCTS_PER_PAGE && 'firstCell'}>
                <Product style={{fontFamily: 'Times'}}>{currencyMask(order.cofins)}</Product>
              </div>
            </DividedBody>
            <DividedBody>
              <div className='taxCell'><Product style={{fontFamily: 'Times'}}>CSLL</Product></div>
              <div><Product style={{fontFamily: 'Times'}}>{currencyMask(order.csll)}</Product></div>
            </DividedBody>
            <DividedBody>
              <div className='taxCell'><Product style={{fontFamily: 'Times'}}>IR</Product></div>
              <div><Product style={{fontFamily: 'Times'}}>{currencyMask(order.ir)}</Product></div>
            </DividedBody>
            <DividedBody>
              <div className='taxCell'><Product style={{fontFamily: 'Times'}}>ISS</Product></div>
              <div><Product style={{fontFamily: 'Times'}}>{currencyMask(order.iss)}</Product></div>
            </DividedBody>
            <DividedBody>
              <div className='taxCell'><Product style={{fontFamily: 'Times'}}>PIS</Product></div>
              <div><Product style={{fontFamily: 'Times'}}>{currencyMask(order.pis)}</Product></div>
            </DividedBody>
            <Divider/>
            <DividedBody>
              <div className='lastCell'><Product style={{fontFamily: 'Times'}}></Product></div>
              <div className='lastCell'><Product style={{fontFamily: 'Times'}}>{getLabel('grossTotal')}</Product><Product style={{fontFamily: 'Times'}}>{currencyMask(order.grossTotal)}</Product></div>
            </DividedBody>
            <Divider/>
            <DividedBodyObservation>
              <div><Observation style={{fontFamily: 'Times'}}>{getLabel('observation')}</Observation></div>
              <div></div>
            </DividedBodyObservation>
            <DividedBodyObservation>
              <div><Observation style={{fontFamily: 'Times'}}>{getLabel('observation2')}</Observation></div>
              <div></div>
            </DividedBodyObservation>
          </ProductsContainer>
        </InvoiceContainer>
        <TotalAmountContainer>
          <ResultLabelContent>
            <ResultLabel>
              <Label style={{fontFamily: 'Times'}}>{getLabel('totalAmount')}</Label>
            </ResultLabel>
            <ResultContent>
              <Content style={{fontFamily: 'Times'}}>{currencyMask(order.totalAmount)}</Content>
            </ResultContent>
          </ResultLabelContent>
        </TotalAmountContainer>
        <TotalAmountContainer>
          <PageLabel>
            <Label style={{fontFamily: 'Times'}}>{getLabel('page2')}</Label>
          </PageLabel>
        </TotalAmountContainer>
      </ContainerPage2>
    }
    </>
  )
}

export default InvoicePDF