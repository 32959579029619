import React, { useCallback, useState, useContext } from "react";
import { Form, Switch, Tooltip } from "antd";
import { useIntl } from "react-intl";
import { useHistory } from "react-router-dom";

import useNotification from "../../../hooks/newUXNotification";
import { useLoading } from "../../../hooks/loading";

import { useDispatch, useSelector } from "react-redux";

import { DialogContext } from "../../../hooks/dialog/context";

import {
  getUserDocument,
  createAssociateCompany,
} from "../../../redux/modules/associateCompany/actions";

import ConfirmationAssociateCompany from "../Modal/ConfirmationAssociateCompany";

import Button from "../../../newUXComponents/Button";
import Input from "../../../newUXComponents/Input";
import Select from "../../../newUXComponents/Select";

import Question from "../../../newUXAssets/icons/Question";
import provision_types from "../../../constants/provisionTypes";

import {
  Container,
  Title,
  Hr,
  Content,
  ContainerButton,
  Label,
  SwitchContainer,
} from "./styles";

import { INITIAL_VALUES, validation } from "./validations";

const CreateAssociateCompany = () => {
  const intl = useIntl();
  const [form] = Form.useForm();
  const history = useHistory();
  const dispatch = useDispatch();
  const notification = useNotification();
  const { createDialog } = useContext(DialogContext);
  const optionsProvision = provision_types();
  const { handleLoading } = useLoading();

  const { contract, event } = useSelector((state) => state.initialSettings);

  const [loginAuthorization, setLoginAuthorization] = useState(false);
  const [serviceProviderId, setServiceProviderId] = useState();
  const [services, setServices] = useState([]);

  const removeNonLetterAndNumbers = str => str.replace(/\W/g, "")
  
  const callbackError = useCallback(
    (message) => {
      handleLoading(false);
      notification.displayError(
        intl.formatMessage({
          id: "api.error." + message,
        }),
        "get-document-error"
      );
    },
    [notification, intl, handleLoading]
  );

  const callbackSuccess = useCallback(() => {
    handleLoading(false);
    notification.displaySuccess(
      intl.formatMessage({
        id: "success.create_association",
      }),
      "create_association"
    );
    history.push("/associate-company/view");
  }, [notification, intl, history, handleLoading]);

  const handleModalConfirmation = (formData) => {
    createDialog({
      id: "modal-confirm-create-associate",
      Component: ConfirmationAssociateCompany,
      props: {
        title: intl.formatMessage({
          id: "login_permission",
        }),
        confirmation: () => {
          handleLoading(true);
          dispatch(
            createAssociateCompany(formData, callbackSuccess, callbackError)
          );
        },
      },
    });
  };

  const onFinish = () => {
    const formData = form.getFieldsValue(true);

    if(loginAuthorization)
      handleModalConfirmation({
        eventId: event.value,
        opportunityId: contract.value,
        serviceProviderId: serviceProviderId,
        serviceTypeId: formData.serviceTypeId,
        allowManageAccount: loginAuthorization,
      });
    
    else {
      handleLoading(true);
      dispatch(
        createAssociateCompany(
          {
            eventId: event.value,
            opportunityId: contract.value,
            serviceProviderId: serviceProviderId,
            serviceTypeId: formData.serviceTypeId,
            allowManageAccount: loginAuthorization,
          },
          callbackSuccess,
          callbackError
        )
      );
    }
  };

  const callbackGetInfoDocumentSuccess = useCallback(
    (data) => {
      if (data?.name) {
        form.setFieldsValue({
          nameCompany: data.name,
        });

        setServiceProviderId(data.id);

        let translatedServices = [];

        data.services.forEach((item) => {
          optionsProvision.forEach((s) => {
            if (s.value === item.value) {
              translatedServices.push({
                value: item.id,
                label: s.label,
              });
            }
          });
        });
        translatedServices.sort((a, b) => a.label.localeCompare(b.label));

        setServices(translatedServices);
        handleLoading(false);
      }
    },
    [form, optionsProvision, handleLoading]
  );

  const handleGetUserDocument = (value) => {
    if (value) {
      handleLoading(true);
      dispatch(
        getUserDocument(
          removeNonLetterAndNumbers(value),
          callbackGetInfoDocumentSuccess,
          callbackError
        )
      );
    }
  };

  return (
    <Container>
      <Title>{intl.formatMessage({ id: "service_provider" })}</Title>
      <Hr />
      <Content>
        <Form
          layout="vertical"
          form={form}
          name="associate-company-form"
          onFinish={onFinish}
          initialValues={INITIAL_VALUES}
        >
          <Input
            type="text"
            label={intl.formatMessage({
              id: "cnpj_or_passport",
            })}
            name="document"
            onSearch={handleGetUserDocument}
            placeholder={intl.formatMessage({
              id: "cnpj_or_passport",
            })}
            rules={validation("document", intl)}
            maxLength={20}
            enterButton
          />

          <Input
            type="text"
            name="nameCompany"
            placeholder={intl.formatMessage({
              id: "form.company_name",
            })}
            label={intl.formatMessage({
              id: "form.company_name",
            })}
            rules={validation("nameCompany", intl)}
            disabled
          />

          <Select
            name="serviceTypeId"
            label={intl.formatMessage({ id: "service_type" })}
            allowClear
            placeholder={intl.formatMessage({ id: "service_type" })}
            rules={validation("serviceTypeId", intl)}
            options={services}
            disabled={services.lenght}
          />
          <Label>
            {intl.formatMessage({
              id: "login_authorization",
            })}
            <Tooltip
              title={intl.formatMessage({
                id: "tooltip.create_associate_company",
              })}
            >
              <span>
                <Question size={16} color="#7FD0EB" filled />
              </span>
            </Tooltip>
          </Label>
          <SwitchContainer>
            <Switch
              onClick={() => setLoginAuthorization(!loginAuthorization)}
              checked={loginAuthorization}
            />
            <p>
              {intl.formatMessage({
                id: "form.associated_companies.login_authorization",
              })}
            </p>
          </SwitchContainer>

          <ContainerButton>
            <Button
              type="link"
              label={intl.formatMessage({ id: "cancel" })}
              onClick={() => history.push("/associate-company/view")}
            />
            <Button
              type="primary"
              label={intl.formatMessage({ id: "save" })}
              htmlType="submit"
            />
          </ContainerButton>
        </Form>
      </Content>
    </Container>
  );
};

export default CreateAssociateCompany;
