export const INITIAL_VALUES = {
  address: "",
  nationality: false,
  name: "",
  last_name: "",
  assignment: "",
  credential_name: "",
  email: "",
  document: "",
  telephone: "",
  category: ""
};

export const validation = (key, intl) => {
  switch (key) {
    case "nationality":
      return [
        {
          required: true,
          message: intl.formatMessage({ id: "error.field_required" }),
        },
      ];
    case "name":
      return [
        {
          required: true,
          message: intl.formatMessage({ id: "error.field_required" }),
        },
      ];

    case "last_name":
      return [
        {
          required: true,
          message: intl.formatMessage({ id: "error.field_required" }),
        },
      ];

    case "assignment":
      return [
        {
          required: true,
          message: intl.formatMessage({ id: "error.field_required" }),
        },
      ];

    case "credential_name":
    return [
      {
        required: true,
        message: intl.formatMessage({ id: "error.field_required" }),
      },
       {
        max: 23,
        message: intl.formatMessage({ id: "error.credential_max_length" }),
      },
    ];

    case "email":
    return [
      {
        required: true,
        message: intl.formatMessage({ id: "error.field_required" }),
      },
      {
        type: 'email',
        message: intl.formatMessage({ id: "error.valid_email" }),
      }
    ];

    case "document":
    return [
      {
        required: true,
        message: intl.formatMessage({ id: "error.field_required" }),
      },
    ];

    case "address":
      return [];

    case "telephone":
      return [];

    case "category":
      return [
        {
          required: true,
          message: intl.formatMessage({ id: "error.field_required" }),
        },
      ];

    default:
      return [];
  }
};
