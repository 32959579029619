import React, { useCallback, useEffect, useRef, useState } from "react";
import { useIntl, FormattedMessage } from "react-intl";
import { useSelector } from "react-redux";
import { useHistory, useParams } from "react-router";
import AnimatedMainContainer from "../../../components/AnimatedMainContainer";
import {
  Container,
  TitleContainer,
  Title,
  FieldsContainer,
  Horizontal,
  SessionContainer,
  SessionIconContainer,
  SessionTitle,
  Session,
  SessionDivider,
  ActionContainer,
  BadgeContainer,
  LinkButton,
} from "./styles";
import * as Yup from "yup";
import { validateSingleFieldOnBlur } from "../../../utils/formValidations";
import api from "../../../service";
import { Form } from "@unform/web";
import InputWithLabel from "../../../components/InputWithLabel";
import { MdInfo, MdBook, MdLocationOn } from "react-icons/md";
import { useToast } from "../../../hooks/toast";
import Table from "../../../components/Table";
import Badge from "../../../components/Badge";
import { useMemo } from "react";
import { useLoading } from "../../../hooks/loading";
import getValidationErrors from "../../../utils/getValidationErrors";

const AccountDetail = () => {
  const { token } = useSelector((state) => state.auth);
  const { id } = useParams();

  const formRef = useRef(null);
  const intl = useIntl();
  const history = useHistory();
  const { handleLoading } = useLoading();
  const [loading, setLoading] = useState(false);

  const { addToast } = useToast();

  const [data, setData] = useState({
    opportunities: [],
  });

  const schema = Yup.object().shape({
    credential_name: Yup.string().required(
      intl.formatMessage({ id: "error.field_required" })
    ),
  });

  const handleSubmit = useCallback(
    async (data) => {
      try {
        formRef.current?.setErrors({});

        setLoading(true);
        if (loading) {
          return;
        }

        await schema.validate(data, { abortEarly: false });

        api
          .put(
            `/admin/account`,
            {
              accountId: id,
              companyNameCredential: data.credential_name,
            },
            {
              headers: {
                "Content-Type": "application/json",
                Accept: "*/*",
                Authorization: `Bearer ${token}`,
              },
            }
          )
          .then((result) => {
            addToast({
              type: "success",
              title: intl.formatMessage({
                id: "success",
              }),
              description: intl.formatMessage({
                id: "success.user_update",
              }),
            });
            history.push("/accounts");
          })
          .catch((err) => {
            addToast({
              type: "error",
              title: intl.formatMessage({
                id: "error",
              }),
              description: intl.formatMessage({
                id: "api.error." + err.response.data.code,
              }),
            });
          })
          .finally(() => {
            setLoading(false);
          });
      } catch (err) {
        setLoading(false);
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);

          formRef.current?.setErrors(errors);

          return;
        }
      }
    },
    [addToast, history, intl, schema, token, loading, id]
  );

  useEffect(() => {
    handleLoading(true);
    api
      .get(`/admin/account/${id}`, {
        headers: {
          "Content-Type": "application/json",
          Accept: "*/*",
          Authorization: `Bearer ${token}`,
        },
      })
      .then((result) => {
        setData(result.data.output);
      })
      .catch((err) => {
        addToast({
          type: "error",
          title: intl.formatMessage({
            id: "error",
          }),
          description: intl.formatMessage({
            id: "api.error." + err.response.data.code,
          }),
        });
      })
      .finally(() => {
        handleLoading(false);
      });
  }, [addToast, handleLoading, id, intl, token]);

  const validationBlur = (name) => {
    let errors = formRef.current.getErrors();

    validateSingleFieldOnBlur(errors, schema, name, formRef);
  };

  const columns = useMemo(
    () => [
      {
        name: intl.formatMessage({ id: "event_edition" }),
        selector: (row) => row.eventEditionName,
        allowOverflow: true,
        wrap: true,
        minWidth: "250px",
      },
      {
        name: intl.formatMessage({ id: "contract" }),
        selector: (row) => row.contractNumber,
        allowOverflow: true,
        wrap: true,
        width: "120px",
      },
      {
        name: intl.formatMessage({ id: "contract_id" }),
        selector: (row) => row.opportunityId,
        allowOverflow: true,
        wrap: true,
        minWidth: "250px",
      },
      {
        cell: (row) => (
          <>
            <LinkButton
              to={{
                pathname: `/events/${row.eventEditionCode}`,
                state: { prevPath: history.location },
              }}
            >
              <Badge
                color="#002244"
                text={intl.formatMessage({ id: "see_event_edition" })}
              />
            </LinkButton>
          </>
        ),
        right: true,
        width: "175px"
      },
      {
        cell: (row) => (
          <LinkButton
            to={{
              pathname: `/contract/${row.opportunityId}`,
              state: { prevPath: history.location },
            }}
          >
            <Badge
              color="#002244"
              text={intl.formatMessage({ id: "see_contract" })}
            />
          </LinkButton>
        ),
        right: true,
        width: "175px"
      },
    ],
    [id, intl, history.location]
  );

  return (
    <AnimatedMainContainer
      breadcrumb={intl.formatMessage({ id: "account_informations" })}
    >
      <Container>
        <Form
          ref={formRef}
          onSubmit={handleSubmit}
          initialData={{
            company_name: data.accountName,
            cnpj: data.accountGovCompanyCode,
            classification: data.accountSimplesNacional,
            segment: data.type,
            tax_email: data?.opportunities[0]?.billingContact?.email,
            operational_email: data?.opportunities[0]?.operationsContact?.email,
            city: data?.opportunities[0]?.address?.billing?.city,
            state: data?.opportunities[0]?.address?.billing?.state,
            address: data?.accountBillingAddress?.addressLine1,
            address_complement: data?.accountBillingAddress?.addressLine2,
            telephone: data?.phone,
            credential_name: data?.credentialName,
          }}
        >
          <TitleContainer>
            <Title>
              <FormattedMessage id="account_detail" />
            </Title>
            <ActionContainer>
              <BadgeContainer onClick={() => history.push("/accounts")}>
                <Badge
                  color="#C9C9C9"
                  text={intl.formatMessage({ id: "goback" })}
                  fontSize="12px"
                />
              </BadgeContainer>
              <BadgeContainer onClick={() => formRef.current.submitForm()}>
                <Badge
                  color="#002244"
                  text={intl.formatMessage({ id: "save" })}
                  fontSize="12px"
                />
              </BadgeContainer>
            </ActionContainer>
          </TitleContainer>

          <SessionDivider nr_items="2">
            <Session>
              <SessionContainer>
                <SessionIconContainer>
                  <MdInfo size="16px" color="#002244" />
                </SessionIconContainer>
                <SessionTitle>
                  <FormattedMessage id="informations" />
                </SessionTitle>
              </SessionContainer>
              <FieldsContainer>
                <InputWithLabel
                  name="company_name"
                  validationBlur={validationBlur}
                  disabled
                  label={intl.formatMessage({ id: "company_name" })}
                />

                <Horizontal>
                  <InputWithLabel
                    name="cnpj"
                    validationBlur={validationBlur}
                    disabled
                    label="CNPJ"
                  />

                  <InputWithLabel
                    name="classification"
                    validationBlur={validationBlur}
                    disabled
                    label={intl.formatMessage({ id: "classification" })}
                  />
                </Horizontal>

                <InputWithLabel
                  name="tax_email"
                  validationBlur={validationBlur}
                  disabled
                  label={intl.formatMessage({ id: "tax_email" })}
                />

                <InputWithLabel
                  name="operational_email"
                  validationBlur={validationBlur}
                  disabled
                  label={intl.formatMessage({ id: "operational_email" })}
                />

                <Horizontal>
                  <InputWithLabel
                    name="segment"
                    validationBlur={validationBlur}
                    disabled
                    label={intl.formatMessage({ id: "segment" })}
                  />

                  <InputWithLabel
                    name="credential_name"
                    validationBlur={validationBlur}
                    label={intl.formatMessage({ id: "company_name_credential" })}
                    placeholder={intl.formatMessage({
                      id: "company_name_credential",
                    })}
                    required
                  />
                </Horizontal>
              </FieldsContainer>
            </Session>
            <Session>
              <SessionContainer>
                <SessionIconContainer>
                  <MdLocationOn size="16px" color="#002244" />
                </SessionIconContainer>
                <SessionTitle>
                  <FormattedMessage id="address_informations" />
                </SessionTitle>
              </SessionContainer>
              <FieldsContainer>
                <InputWithLabel
                  name="address"
                  validationBlur={validationBlur}
                  disabled
                  label={intl.formatMessage({ id: "address" })}
                />
                <Horizontal>
                  <InputWithLabel
                    name="address_number"
                    validationBlur={validationBlur}
                    disabled
                    label={intl.formatMessage({ id: "address_number" })}
                  />
                  <InputWithLabel
                    name="address_complement"
                    validationBlur={validationBlur}
                    disabled
                    label={intl.formatMessage({ id: "address_complement" })}
                  />
                </Horizontal>
                <Horizontal>
                  <InputWithLabel
                    name="city"
                    validationBlur={validationBlur}
                    disabled
                    label={intl.formatMessage({ id: "city" })}
                  />
                  <InputWithLabel
                    name="state"
                    validationBlur={validationBlur}
                    disabled
                    label={intl.formatMessage({ id: "state" })}
                  />
                </Horizontal>
                <Horizontal>
                  <InputWithLabel
                    name="telephone"
                    validationBlur={validationBlur}
                    disabled
                    label={intl.formatMessage({ id: "telephone" })}
                  />
                  <InputWithLabel
                    name="mobile"
                    validationBlur={validationBlur}
                    disabled
                    label={intl.formatMessage({ id: "mobile" })}
                  />
                </Horizontal>
              </FieldsContainer>
            </Session>
          </SessionDivider>
          <Session>
            <SessionContainer>
              <SessionIconContainer>
                <MdBook size="16px" color="#002244" />
              </SessionIconContainer>
              <SessionTitle>
                <FormattedMessage id="contracts" />
              </SessionTitle>
            </SessionContainer>
            <FieldsContainer>
              <Table
                data={data.opportunities}
                columns={columns}
                hasPagination={false}
              />
            </FieldsContainer>
          </Session>
        </Form>
      </Container>
    </AnimatedMainContainer>
  );
};

export default AccountDetail;
