import React from "react";
import ReactExport from "react-data-export";
import { format, parseISO } from "date-fns";
import { useIntl } from "react-intl";
import swapcardReturnTypesConstants from "../../constants/swapcardReturnTypes";

const File = ReactExport.ExcelFile;
const Sheet = ReactExport.ExcelFile.ExcelSheet;
const Column = ReactExport.ExcelFile.ExcelColumn;

const Report = ({ data, filename, children }) => {
  const intl = useIntl();
  const swapcardReturnTypes = swapcardReturnTypesConstants();

  return (
    <File element={children} filename={filename} fileExtension="xlsx">
      <Sheet data={data} name="Relatório">
        <Column label="codigo_barras" value="credentialBarcode" />
        <Column
          label="tipo_acao"
          value={(col) =>
            col.actionType === "Add"
              ? intl.formatMessage({ id: "add_type" })
              : intl.formatMessage({ id: "update" })
          }
        />
        <Column
          label="status_retornado"
          value={(col) =>
            swapcardReturnTypes.filter((pt) => pt.value === col.statusReturned)[0]?.label
          }
        />
        <Column
          label="houve_erro"
          value={(col) =>
            col.error
              ? intl.formatMessage({ id: "yes" })
              : intl.formatMessage({ id: "no" })
          }
        />
        <Column
          label="data_emissao"
          value={(col) =>
            format(parseISO(col.createdAt), "dd/MM/yyyy HH:mm")
          }
        />
        <Column label="edicao_evento" value="eventEditionName" />
        <Column label="razao_social" value="companyName" />
        <Column label="documento_usuario" value="userDocument" />
        <Column label="email_expositor" value="userEmail" />
        <Column label="email_colaborador" value="collaboratorEmail" />
        <Column label="nome_empresa_credencial" value="credentialName" />
        <Column label="nome_contato" value="collaboratorName" />
        <Column label="sobrenome_contato" value="collaboratorLastName" />
      </Sheet>
    </File>
  );
};

export default Report;
