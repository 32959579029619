import React, 
{ 
  useCallback,
  useRef,
  useState,
  useEffect,
  useMemo
} from "react";
import { useHistory, useParams } from "react-router-dom";

import { Form } from "@unform/web";
import * as Yup from "yup";
import getValidationErrors from "../../../utils/getValidationErrors";

import { useSelector } from "react-redux";

import { FormattedMessage, useIntl } from "react-intl";
import { validateSingleFieldOnBlur } from "../../../utils/formValidations";

import {
  ActionContainer,
  BadgeContainer,
  Container,
  Session,
  SessionContainer,
  SessionDivider,
  SessionTitle,
  Horizontal,
  Divider,
  FileDropzoneContainer,
  Label,
  LabelContainer,
  UploadAreaWithLabel,
  HorizontalUpload
} from "./styles";

import { parse, format } from "date-fns";

import { useToast } from "../../../hooks/toast";
import { useLoading } from "../../../hooks/loading";
import api from "../../../service";
import AnimatedMainContainer from "../../../components/AnimatedMainContainer";
import Dropzone from "../../../components/Dropzone";
import InputWithLabel from "../../../components/InputWithLabel";
import TextareaWithLabelAndEditor from "../../../newUXComponents/TextareaWithLabelAndEditor";
import TextareaWithLabel from "../../../components/TextareaWithLabel";
import DatePickerWithLabel from "../../../components/DatePickerWithLabel";
import Toggle from "../../../components/Toggle";
import { FieldsContainer, Title, TitleContainer } from "../styles";
import Badge from "../../../components/Badge";


const initialValues = {
  descriptionPt: "",
  additionalInfoPt: "",
  howToDoPt: "",
  linkPt: "",
  descriptionEn: "",
  additionalInfoEn: "",
  howToDoEn: "",
  linkEn: "",
  position: "",
  forExhibitor: true,
  forServiceProvider: true,
  expiresIn: "",
  mediaFileEn: null,
  mediaFilePt: null,
  cardImage: null,
  blockUploadCover: true,
  blockUploadMediaPt: true,
  blockUploadMediaEn: true,
}

const ChecklistItem = () => {
  const formRef = useRef(null);
  const [data, setData] = useState(initialValues);
  const [isUploadDisabledCover, setIsUploadDisabledCover] = useState(false)
  const [isUploadDisabledMediaPt, setIsUploadDisabledMediaPt] = useState(false)
  const [isUploadDisabledMediaEn, setIsUploadDisabledMediaEn] = useState(false)
  const [formKey, setFormKey] = useState();

  const intl = useIntl();
  const { addToast } = useToast();
  const { token } = useSelector((state) => state.auth);
  const { id, itemId } = useParams();
  const { handleLoading } = useLoading();

  const history = useHistory();

  const schema = Yup.object().shape({
    descriptionPt: Yup.string().required(
      intl.formatMessage({ id: "error.field_required" })
    ),
    additionalInfoPt: Yup.string().required(
      intl.formatMessage({ id: "error.field_required" })
    ),
    howToDoPt: Yup.string().required(
      intl.formatMessage({ id: "error.field_required" })
    ),
    linkPt: Yup.string().url(
      intl.formatMessage({ id: "error.valid_url" })
    ),
    descriptionEn: Yup.string().required(
      intl.formatMessage({ id: "error.field_required" })
    ),
    additionalInfoEn: Yup.string().required(
      intl.formatMessage({ id: "error.field_required" })
    ),
    howToDoEn: Yup.string().required(
      intl.formatMessage({ id: "error.field_required" })
    ),
    linkEn: Yup.string().url(
      intl.formatMessage({ id: "error.valid_url" })
    ),
    position: Yup.string().matches(
      /^(^$|([0-9]*))$/g,
      intl.formatMessage({ id: "error.need_to_be_number" })
    ),
    forExhibitor: Yup.bool(),
    forServiceProvider: Yup.bool(),
    expiresIn: Yup.string().nullable().required(
      intl.formatMessage({ id: "error.field_required" })
    )
  });

  useEffect(()=>{
    if(itemId) {
      handleLoading(true);
      api.get(`event-checklist/${itemId}`, 
      {
        headers: {
          "Content-Type": "application/json",
          Accept: "*/*",
          Authorization: `Bearer ${token}`,
        }
      })
      .then(async (result) => {
        if(result.data.output) { 
          setData({
            ...result.data.output,
            expiresIn: parseStringToDate(result.data.output.expiresIn)
          })
        }
        setFormKey(new Date());
        setIsUploadDisabledCover(true)
        setIsUploadDisabledMediaPt(true)
        setIsUploadDisabledMediaEn(true)
      })
      .catch((err) => {
        addToast({
          type: "error",
          title: intl.formatMessage({
            id: "error",
          }),
          description: intl.formatMessage({
            id: "api.error." + err.response?.data?.code,
          }),
        });
      })
      .finally(() => {
        handleLoading(false);
      });
    }
  },[addToast, intl, itemId, token, handleLoading])

  const formatDate = (date, formatString) => {
    if (!date || date === "") {
      return null;
    }

    return format(date, formatString);
  };

  const generateFormData = (data) => {
     let formData = new FormData();

     const formattedDate = formatDate(data.expiresIn, "yyyy-MM-dd")
    if(data.position!== '')  
      formData.set("position", data.position);
    if(data.linkPt!== '')  
      formData.set("linkPt", data.linkPt);
    if(data.linkEn!== '')  
      formData.set("linkEn", data.linkEn);
     formData.set("descriptionPt", data.descriptionPt);
     formData.set("descriptionEn", data.descriptionEn);
     formData.set("additionalInfoPt", data.additionalInfoPt);
     formData.set("additionalInfoEn", data.additionalInfoEn);
     formData.set("howToDoPt", data.howToDoPt);
     formData.set("howToDoEn", data.howToDoEn);
     formData.set("forExhibitor", data.forExhibitor);
     formData.set("forServiceProvider", data.forServiceProvider);
     formData.set("expiresIn", formattedDate);

     if(!!data.cardImage[0] && !isUploadDisabledCover){
      formData.set("cardImage", data.cardImage[0]);
      if(itemId)
        formData.set("hasRemovedOldCardImage", true);
     }

    if(!!data.mediaFileEn[0] && !isUploadDisabledMediaEn){  
      formData.set("mediaFileEn", data.mediaFileEn[0]);
      if(itemId)
        formData.set("hasRemovedOldMediaFileEn", true);
    }

    if(!!data.mediaFilePt[0] && !isUploadDisabledMediaPt){
      formData.set("mediaFilePt", data.mediaFilePt[0]);
      if(itemId)
        formData.set("hasRemovedOldMediaFilePt", true);
    }

    formData.set("eventId", id);

     return formData;
  };

  const postChecklistItem = useCallback(
    async (data) => {
      try {

        handleLoading(true);

        await schema.validate(data, { abortEarly: false });
        const formData = generateFormData(data)

        api
          .post(
            `event-checklist/`, formData,
            {
              headers: {
                "Content-Type": "multipart/form-data",
                Accept: "*/*",
                Authorization: `Bearer ${token}`,
              },
            }
          )
          .then((data) => {
            handleLoading(false)
            addToast({
              type: "success",
              title: intl.formatMessage({
                id: "success",
              }),
              description: intl.formatMessage({
                id: "success.checklist_item_created",
              }),
            });
            history.push(`/event-checklist/${id}`);
          })
          .catch((err) => {
            handleLoading(false)
            addToast({
              type: "error",
              title: intl.formatMessage({
                id: "error",
              }),
              description: intl.formatMessage({
                id: "api.error." + err.response.data.code,
              }),
            });
          });
      } catch (err) {
        handleLoading(false)
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);
          Object.keys(errors).forEach(key => {
            validateSingleFieldOnBlur(errors, schema, key, formRef);
          });
          formRef.current?.setErrors(errors);
          return;
        }
      }
    },
    [addToast, history, intl, schema, token, handleLoading, id]
  );

  const putChecklistItem = useCallback(
    async (data) => {
      try {

        handleLoading(true);

        await schema.validate(data, { abortEarly: false });
        const formData = generateFormData(data)

        api
          .put(
            `event-checklist/${itemId}`, formData,
            {
              headers: {
                "Content-Type": "multipart/form-data",
                Accept: "*/*",
                Authorization: `Bearer ${token}`,
              },
            }
          )
          .then((data) => {
            handleLoading(false)
            addToast({
              type: "success",
              title: intl.formatMessage({
                id: "success",
              }),
              description: intl.formatMessage({
                id: "success.checklist_item_updated",
              }),
            });
            history.push(`/event-checklist/${id}`);
          })
          .catch((err) => {
            handleLoading(false)
            addToast({
              type: "error",
              title: intl.formatMessage({
                id: "error",
              }),
              description: intl.formatMessage({
                id: "api.error." + err.response.data.code,
              }),
            });
          });
      } catch (err) {
        handleLoading(false)
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);
          Object.keys(errors).forEach(key => {
            validateSingleFieldOnBlur(errors, schema, key, formRef);
          });
          formRef.current?.setErrors(errors);
          return;
        }
      }
    },
    [addToast, history, intl, schema, token, handleLoading, id, itemId]
  );

  const handleSubmit = useMemo(
    ()=> itemId ? putChecklistItem : postChecklistItem,
    [itemId, putChecklistItem, postChecklistItem]
  )

  const onChangeProviderToggle = (e) =>{
    if(e.target.checked) return
    formRef.current.setFieldValue('forExhibitor', 'true')
  }

  const onChangeExhibitorToggle = (e) =>{
    if(e.target.checked) return
    formRef.current.setFieldValue('forServiceProvider', 'true')
  }

  const onChangeUploadMediaPtToggle = (e) =>{
    setIsUploadDisabledMediaPt(!e.target.checked)
  }

  const onChangeUploadMediaEnToggle = (e) =>{
    setIsUploadDisabledMediaEn(!e.target.checked)
  }

  const onChangeUploadCoverToggle = (e) =>{
    setIsUploadDisabledCover(!e.target.checked)
  }

  

  const validationBlur = (name) => {
    let errors = formRef.current.getErrors();

    validateSingleFieldOnBlur(errors, schema, name, formRef);
  };

  const pushBack = () => {
    history.push(`/event-checklist/${id}`);
  };

  const parseStringToDate = (dateString) => {
    if (!dateString) {
      return null;
    }
    return parse(dateString, "yyyy-MM-dd", new Date());
  };

  const pageTitleLabel = useMemo(
    ()=> itemId ? 'checklist.edit' : 'checklist.new',[itemId]
  )

  return (
    <AnimatedMainContainer>
      <Container>
        <Form 
          ref={formRef}
          onSubmit={handleSubmit}
          initialData={{
            descriptionPt: data.descriptionPt,
            additionalInfoPt: data.additionalInfoPt.substring(0, 250),
            howToDoPt: data.howToDoPt,
            linkPt: data.linkPt,
            descriptionEn: data.descriptionEn,
            additionalInfoEn: data.additionalInfoEn.substring(0, 250),
            howToDoEn: data.howToDoEn,
            linkEn: data.linkEn,
            position: data.position,
            forExhibitor: data.forExhibitor,
            forServiceProvider: data.forServiceProvider,
            expiresIn: data.expiresIn
          }}
          key={formKey}
        >
          <TitleContainer>
            <Title>
              <FormattedMessage id={pageTitleLabel} />
            </Title>
            <ActionContainer>
              <BadgeContainer onClick={() => pushBack()}>
                <Badge
                  color="#C9C9C9"
                  text={intl.formatMessage({ id: "goback" })}
                  fontSize="12px"
                />
              </BadgeContainer>
              <BadgeContainer onClick={() => formRef.current.submitForm()}>
                <Badge
                  color="#002244"
                  text={intl.formatMessage({ id: "save" })}
                  fontSize="12px"
                />
              </BadgeContainer>
            </ActionContainer>
          </TitleContainer>
          <SessionDivider nr_items="2">
            <Session>
              <SessionContainer>
                <SessionTitle>
                  <FormattedMessage id="portuguese" />
                </SessionTitle>
              </SessionContainer>
              <FieldsContainer>
                <InputWithLabel
                  name="descriptionPt"
                  validationBlur={validationBlur}
                  placeholder={intl.formatMessage({ id: "checklist.item_description_pt" })}
                  label={intl.formatMessage({ id: "checklist.item_description_pt" })}
                  required
                />
                <TextareaWithLabel
                  name="additionalInfoPt"
                  placeholder={intl.formatMessage({ id: "checklist.additional_info_pt" })}
                  label={intl.formatMessage({ id: "checklist.additional_info_pt" })}
                  validationBlur={validationBlur}
                  className={'textArea'}
                  maxlength={250}
                  required
                />
                <TextareaWithLabelAndEditor
                  name="howToDoPt"
                  placeholder={intl.formatMessage({ id: "checklist.howto_pt" })}
                  label={intl.formatMessage({ id: "checklist.howto_pt" })}
                  validationBlur={validationBlur}
                  className={'textArea'}
                  required
                />
                <InputWithLabel
                  name="linkPt"
                  validationBlur={validationBlur}
                  placeholder={intl.formatMessage({ id: "checklist.link_pt" })}
                  label={intl.formatMessage({ id: "checklist.link_pt" })}
                />
              </FieldsContainer>
            </Session>
            <Session>
              <SessionContainer>
                <SessionTitle>
                  <FormattedMessage id="english" />
                </SessionTitle>
              </SessionContainer>
              <FieldsContainer>
                <InputWithLabel
                  name="descriptionEn"
                  validationBlur={validationBlur}
                  placeholder={intl.formatMessage({ id: "checklist.item_description_en" })}
                  label={intl.formatMessage({ id: "checklist.item_description_en" })}
                  required
                />
                <TextareaWithLabel
                  name="additionalInfoEn"
                  placeholder={intl.formatMessage({ id: "checklist.additional_info_en" })}
                  label={intl.formatMessage({ id: "checklist.additional_info_en" })}
                  validationBlur={validationBlur}
                  className={'textArea'}
                  maxlength={250}
                  required
                />
                <TextareaWithLabelAndEditor
                  name="howToDoEn"
                  placeholder={intl.formatMessage({ id: "checklist.howto_en" })}
                  label={intl.formatMessage({ id: "checklist.howto_en" })}
                  validationBlur={validationBlur}
                  className={'textArea'}
                  required
                />
                <InputWithLabel
                  name="linkEn"
                  validationBlur={validationBlur}
                  placeholder={intl.formatMessage({ id: "checklist.link_en" })}
                  label={intl.formatMessage({ id: "checklist.link_en" })}
                />
              </FieldsContainer>
            </Session>
          </SessionDivider>
          <HorizontalUpload>
            <UploadAreaWithLabel>
              {itemId &&
                <Toggle
                  name="blockUploadMediaPt"
                  label={intl.formatMessage({ id: "checklist.reUploadFiles" })}
                  onChange={onChangeUploadMediaPtToggle}
                />
              }
              <LabelContainer>
                <Label>
                  {intl.formatMessage({ id: "checklist.mediaPT" })}
                </Label>
              </LabelContainer>
              <FileDropzoneContainer
                disabled={isUploadDisabledMediaPt}
              >
                <Dropzone
                  name="mediaFilePt"
                  accept={[".jpg", ".jpeg", ".pdf", ".doc", ".txt", ".png", ".mp3"]}
                  type="PDF/JPG/JPEG/PNG/DOC/TXT/MP3"
                  multiple={false}
                  disabled={isUploadDisabledMediaPt}
                  maxSize={10000000}
                />
              </FileDropzoneContainer>
            </UploadAreaWithLabel>
            <UploadAreaWithLabel>
              {itemId &&
                <Toggle
                  name="blockUploadMediaEn"
                  label={intl.formatMessage({ id: "checklist.reUploadFiles" })}
                  onChange={onChangeUploadMediaEnToggle}
                />
              }
              <LabelContainer>
                <Label>
                  {intl.formatMessage({ id: "checklist.mediaEN" })}
                </Label>
              </LabelContainer>
              <FileDropzoneContainer
                disabled={isUploadDisabledMediaEn}
              >
                <Dropzone
                  name="mediaFileEn"
                  accept={[".jpg", ".jpeg", ".pdf", ".doc", ".txt", ".png", ".mp3"]}
                  type="PDF/JPG/JPEG/PNG/DOC/TXT/MP3"
                  multiple={false}
                  disabled={isUploadDisabledMediaEn}
                  maxSize={10000000}
                />
              </FileDropzoneContainer>
            </UploadAreaWithLabel>
          </HorizontalUpload>
          <HorizontalUpload>
            <UploadAreaWithLabel>
              {itemId &&
                <Toggle
                  name="blockUploadMediaCover"
                  label={intl.formatMessage({ id: "checklist.reUploadFiles" })}
                  onChange={onChangeUploadCoverToggle}
                />
              }
              <LabelContainer>
                <Label>
                  {intl.formatMessage({ id: "checklist.image" })}
                </Label>
              </LabelContainer>
              <FileDropzoneContainer
                disabled={isUploadDisabledCover}
              >
                <Dropzone
                  name="cardImage"
                  accept="image/*"
                  type="JPG/JPEG/PNG"
                  multiple={false}
                  disabled={isUploadDisabledCover}
                  maxSize={10000000}
                />
              </FileDropzoneContainer>
            </UploadAreaWithLabel>
          </HorizontalUpload>
          <Divider />
          <Session>
            <FieldsContainer>
              <Horizontal>
                <Horizontal>
                  <DatePickerWithLabel
                    name="expiresIn"
                    validationBlur={validationBlur}
                    label={intl.formatMessage({ id: "checklist.expires" })}
                    labelOrientation="vertical"
                    required
                  />
                  <InputWithLabel
                    name="position"
                    validationBlur={validationBlur}
                    placeholder={intl.formatMessage({ id: "checklist.position" })}
                    label={intl.formatMessage({ id: "checklist.position" })}
                  />
                </Horizontal>
                <Horizontal>
                  <Toggle
                    name="forExhibitor"
                    label={intl.formatMessage({ id: "checklist.exhibitor" })}
                    onChange={onChangeExhibitorToggle}
                  />
                  <Toggle
                    name="forServiceProvider"
                    label={intl.formatMessage({ id: "checklist.provider" })}
                    onChange={onChangeProviderToggle}
                  />
                </Horizontal>
              </Horizontal>
            </FieldsContainer>
          </Session>
        </Form>
      </Container>
    </AnimatedMainContainer>
  );
};

export default ChecklistItem;
