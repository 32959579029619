import { all, takeLatest, call, put } from "redux-saga/effects";

import {
  setAllAccountsUserSuccess,
  setAllAccountsExhibitorSuccess,
} from "./actions";
import { authSuccess } from "../auth/actions";
import { clearInitialSettingsState } from "../initialSettings/actions";
import { clearChecklist } from "../checklist/actions";
import { clearEcommerceInfo } from "../ecommerce/actions";
import { clearEmployee } from "../employee/actions";
import { clearProfileExhibitor } from "../profile/actions";
import { clearSystem } from "../system/actions";
import { clearAllContractByEvent } from "../contract/actions";
import { clearAllEvent } from "../event/actions";

import {
  getAccountsServiceProviderService,
  getAccountsExhibitorService,
  changeAccountsServiceProviderService,
  changeAccountsExhibitorService,
} from "../../../service/accounts.service";

function* getAccountsServiceProvider({ payload }) {
  try {
    const response = yield call(getAccountsServiceProviderService);

    if (
      response?.status === 200 &&
      response?.data?.serviceProviderAccountsAssociated.length
    ) {
      yield put(
        setAllAccountsUserSuccess([
          {
            value: 0,
            label: payload.intl.formatMessage({ id: "my_profile" }),
          },
          ...response?.data?.serviceProviderAccountsAssociated.map(
            (account) => ({
              value: account.idNumber,
              label: account.accountName,
            })
          ),
        ])
      );
    }
  } catch (error) {
    payload.callbackError(error.response.data.code);
  }
}

function* getAccountsExhibitor({ payload }) {
  try {
    const response = yield call(getAccountsExhibitorService);

    if (
      response?.status === 200 &&
      response?.data?.associatedAccountOutputs.length
    ) {
      yield put(
        setAllAccountsExhibitorSuccess(
          response?.data?.associatedAccountOutputs.map((account) => ({
            value: account.accountId,
            label: account.accountName,
          })),
        )
      );
    }
  } catch (error) {
    payload.callbackError(error.response.data.code);
  }
}

function* changeAccount({ payload }) {
  const {
    accountId,
    accountType,
    keepSignedIn,
    callbackSuccess,
    callbackError,
  } = payload;
  let response = {};
  try {
    if (accountType === "EXHIBITOR") {
      response = yield call(changeAccountsExhibitorService, {
        accountIdNumber: accountId,
      });
    }

    if (accountType === "SERVICE_PROVIDER") {
      response = yield call(changeAccountsServiceProviderService, {
        accountIdNumber: accountId,
      });
    }

    if (response?.status === 200) {
      yield put(
        authSuccess({
          user: response?.data.user,
          token: response?.data.token,
          customerCenterToken: response?.data.customerCenterToken,
          accountCreated: response?.data.accountCreated,
          keepSignedIn,
          serviceProviderLoggingAsAnExhibitor:
            response?.data.serviceProviderLoggingAsAnExhibitor,
        })
      );

      yield put(clearInitialSettingsState());
      yield put(clearChecklist());
      yield put(clearEmployee());
      yield put(clearProfileExhibitor())
      yield put(clearEcommerceInfo());
      yield put(clearAllContractByEvent());
      yield put(clearAllEvent());
      yield put(clearSystem());

      callbackSuccess(accountType);
    }
  } catch (error) {
    callbackError(error.response.data.code);
  }
}

export default all([
  takeLatest("GET_ACCOUNTS_SERVICE_PROVIDER", getAccountsServiceProvider),
  takeLatest("GET_ACCOUNTS_EXHIBITOR", getAccountsExhibitor),
  takeLatest("CHANGE_ACCOUNT", changeAccount),
]);
