import React from "react";

import { useIntl } from "react-intl";

import Input from "../../../newUXComponents/Input";
import Select from "../../../newUXComponents/Select";

import { validation } from "./validations";

import { numberMask } from "../../../utils/numberMask";
import { zipCodeMask } from "../../../utils/zipCodeMask";

import TelephoneInput from "../../../newUXComponents/TelephoneInput";

import { ContainerInscription } from "./styles";

const ChangeForm = ({
  optionsProvision,
  handleCep,
  refForm,
  disabledInputs,
  defaultCountry = 'BR',
  defaultCountryMobile = 'BR'
}) => {
  const intl = useIntl();
  return (
    <>
      <Input
        type="text"
        name="companyName"
        label={intl.formatMessage({ id: "corporate_name" })}
        placeholder={intl.formatMessage({ id: "corporate_name" })}
        rules={validation("corporateName", intl)}
        maxLength={255}
      />

      <Select
        name="provisionTypes"
        label={intl.formatMessage({ id: "types_of_service" })}
        mode="multiple"
        allowClear
        placeholder={intl.formatMessage({ id: "types_of_service" })}
        rules={validation("typesOfService", intl)}
        options={optionsProvision}
      />

      <Input
        type="text"
        name="zipCode"
        label={intl.formatMessage({ id: "zip_code" })}
        placeholder={intl.formatMessage({ id: "zip_code" })}
        rules={validation("zipCode", intl)}
        disabled={disabledInputs}
        onChange={({ target }) => {
          handleCep(target.value);
          refForm.setFieldsValue({ zipCode: zipCodeMask(target.value) });
        }}
        maxLength={9}
      />

      <Input
        type="text"
        name="address"
        label={intl.formatMessage({ id: "address" })}
        placeholder={intl.formatMessage({ id: "address" })}
        rules={validation("address", intl)}
        disabled={disabledInputs}
        maxLength={255}
      />

      <ContainerInscription>
        <Input
          type="text"
          name="addressNumber"
          label={intl.formatMessage({ id: "address_number" })}
          placeholder={intl.formatMessage({ id: "address_number" })}
          rules={validation("address_number", intl)}
          disabled={disabledInputs}
          onChange={({ target }) =>
            refForm.setFieldsValue({ addressNumber: numberMask(target.value) })
          }
          maxLength={6}
        />

        <Input
          type="text"
          name="district"
          label={intl.formatMessage({ id: "district" })}
          placeholder={intl.formatMessage({ id: "district" })}
          rules={validation("district", intl)}
          disabled={disabledInputs}
          maxLength={255}
        />
      </ContainerInscription>

      <ContainerInscription>
        <Input
          type="text"
          name="city"
          label={intl.formatMessage({ id: "city" })}
          placeholder={intl.formatMessage({ id: "city" })}
          rules={validation("city", intl)}
          disabled={disabledInputs}
          maxLength={255}
        />

        <Input
          type="text"
          name="state"
          label={intl.formatMessage({ id: "state" })}
          placeholder={intl.formatMessage({ id: "state" })}
          rules={validation("state", intl)}
          disabled={disabledInputs}
          maxLength={255}
        />
      </ContainerInscription>

      <ContainerInscription>
        <TelephoneInput
          name="phone"
          placeholder={intl.formatMessage({
            id: "phone",
          })}
          label={intl.formatMessage({
            id: "phone",
          })}
          rules={validation("phone", intl)}
          disabled={disabledInputs}
          refForm={refForm}
          defaultCountryCode={defaultCountry}
        />
      </ContainerInscription>

      <ContainerInscription>
        <TelephoneInput
          name="mobile"
          placeholder={intl.formatMessage({
            id: "mobile",
          })}
          label={intl.formatMessage({
            id: "mobile",
          })}
          rules={validation("mobile", intl)}
          disabled={disabledInputs}
          refForm={refForm}
          defaultCountryCode={defaultCountryMobile}
        />
      </ContainerInscription>
    </>
  );
};

export default ChangeForm;
