import produce from "immer";

const INITIAL_STATE = {
  faqListOutput: [],
};

const faq = (state = INITIAL_STATE, action) => {
  return produce(state, (draft) => {
    switch (action.type) {
      case "SET_INFO_FAQ": {
        const { faq } = action.payload;

        draft.faqListOutput = faq;

        localStorage.setItem("InformaMarket:faq", JSON.stringify(faq));

        break;
      }

      default: {
        if (localStorage.getItem("InformaMarket:faq")) {
          const faq = JSON.parse(localStorage.getItem("InformaMarket:faq"));

          draft.faqListOutput = faq;
        }

        return draft;
      }
    }
  });
};

export default faq;
