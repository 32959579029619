import styled, { css } from "styled-components";
import { shade } from "polished";
import { MdClose } from "react-icons/md";
import Modal from "react-modal";

export const Horizontal = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  & > div {
    display: flex;
    flex: 1 0 100px;
    margin-left: 16px;
    @media (max-width: 768px) {
      margin-left: 0px;
      flex: 1 1 auto;
      width: 100%;
    }
  }

  & > div:first-child {
    margin-left: 0px;
  }
`;

export const FilterActionsContainer = styled.div`
  display: flex;
  width: 100%;
  font-size: 14px;
  flex-wrap: wrap;

  & > div:first-child {
    margin-left: 0px;
  }

  & > div {
    flex-direction: column;
    margin-left: 16px;
    @media (max-width: 500px) {
      margin-left: 0px;
    }
  }
`;

export const SubmitContainer = styled.div`
  flex: 1;
  display: flex;

  @media (max-width: 500px) {
    margin-left: 0px;
    max-width: none;
    width: 100%;
  }

  button {
    height: 54px;
  }
`;

Modal.setAppElement(document.getElementById("root"));

export const ModalContainer = styled(Modal)`
  position: absolute;
  margin: 0 16px !important;
  padding: 24px;
  background-color: #fff;
  overflow: auto;
  max-height: 100vh;
  display: flex;
  flex-direction: column;

  @media (max-width: 1100px) {
    margin: unset;
    margin-right: -50% !important;
  }
`;

export const CheckboxDescription = styled.p`
  margin-top: 12px;
`;
