import React, {
  useCallback,
  useRef,
  useState,
  useEffect,
  useMemo
} from "react";
import { useHistory } from "react-router-dom";

import { Form } from "@unform/web";
import * as Yup from "yup";
import getValidationErrors from "../../../utils/getValidationErrors";

import { useSelector } from "react-redux";

import { FormattedMessage, useIntl } from "react-intl";

import {
  Container,
  TitleContainer,
  Title,
  Session,
  FieldsContainer,
  ActionContainer,
  BadgeContainer,
} from "./styles";

import { useToast } from "../../../hooks/toast";
import { validateSingleFieldOnBlur } from "../../../utils/formValidations";
import api from "../../../service";
import AnimatedMainContainer from "../../../components/AnimatedMainContainer";
import InputWithHorizontalLabel from "../../../components/InputWithHorizontalLabel";
import SelectWithLabel from "../../../components/SelectWithLabel";
import Badge from "../../../components/Badge";
import { useLoading } from "../../../hooks/loading";
import ConfirmationModal from "../../../components/ConfirmationModal";
import provisionTypesConstants from "../../../constants/provisionTypes";

const CreateCredentialTransfer = () => {
  const formRef = useRef(null);
  const intl = useIntl();
  const { addToast } = useToast();
  const { token } = useSelector((state) => state.auth);

  const [events, setEvents] = useState([]);
  const [selectedEvent, setSelectedEvent] = useState();
  const [opportunities, setOpportunities] = useState([]);
  const [selectedOpportunity, setSelectedOpportunity] = useState();
  const [selectedBenefited, setSelectedBenefited] = useState();
  const [selectedService, setSelectedService] = useState();
  const [isServiceProvider, setIsServiceProvider] = useState(false);
  const [categories, setCategories] = useState([]);
  const [serviceTypes, setServiceTypes] = useState([]);
  const [users, setUsers] = useState([]);
  const [confirmationModalOpen, setConfirmationModalOpen] = useState(false);

  const [loading, setLoading] = useState(false);
  const { handleLoading } = useLoading();
  const provisionTypes = provisionTypesConstants();
  const isPortuguese = useMemo(()=> intl.locale === 'pt', [intl]);

  const history = useHistory();

  const schema = Yup.object().shape({
    event: Yup.string().required(
      intl.formatMessage({ id: "error.field_required" })
    ),
    opportunity: Yup.string().required(
      intl.formatMessage({ id: "error.field_required" })
    ),
    benefited: Yup.string().required(
      intl.formatMessage({ id: "error.field_required" })
    ),
    category: Yup.string().required(
      intl.formatMessage({ id: "error.field_required" })
    ),
    quantity: Yup.string()
      .matches(
        /^(^$|([0-9.]*))$/g,
        intl.formatMessage({ id: "error.need_to_be_number" })
      )
      .required(intl.formatMessage({ id: "error.field_required" })),
  });

  const handleSubmit = useCallback(
    async (data) => {
      try {
        setLoading(true);
        if (loading) {
          return;
        }

        formRef.current?.setErrors({});
        await schema.validate(data, { abortEarly: false });

        api
          .post(
            `/credential-transfer`,
            {
              opportunityId: data.opportunity,
              categoryId: data.category,
              benefitedId: data.benefited,
              quantity: data.quantity,
              serviceProviderAssociationId: data.service,
            },
            {
              headers: {
                "Content-Type": "application/json",
                Accept: "*/*",
                Authorization: `Bearer ${token}`,
              },
            }
          )
          .then(() => {
            addToast({
              type: "success",
              title: intl.formatMessage({
                id: "success",
              }),
              description: intl.formatMessage({
                id: "success.credential_transfer",
              }),
            });
            history.push("/credential-transfer");
          })
          .catch((err) =>
            addToast({
              type: "error",
              title: intl.formatMessage({
                id: "error",
              }),
              description: intl.formatMessage({
                id: "api.error." + err.response.data.code,
              }),
            })
          )
          .finally(() => setLoading(false));
      } catch (err) {
        setLoading(false);
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);
          formRef.current?.setErrors(errors);
          return;
        }
      }
    },
    [addToast, history, intl, loading, schema, token]
  );

  useEffect(() => {
    handleLoading(true);
    api
      .get(`/exhibitor/events/by-user`, {
        headers: {
          "Content-Type": "application/json",
          Accept: "*/*",
          Authorization: `Bearer ${token}`,
        },
      })
      .then((result) => {
        const events = result.data.output;
        let eventsSort = [...events].sort((a, b) =>
          a.label < b.label ? -1 : a.label > b.label ? 1 : 0
        );
        setEvents(eventsSort);
      })
      .catch((err) =>
        addToast({
          type: "error",
          title: intl.formatMessage({
            id: "error",
          }),
          description: intl.formatMessage({
            id: "api.error." + err.response.data.code,
          }),
        })
      )
      .finally(() => handleLoading(false));
  }, [addToast, intl, token, handleLoading]);

  useEffect(() => {
    formRef.current.setFieldValue("opportunity");
    if (selectedEvent) {
      handleLoading(true);
      api
        .get(`/exhibitor/opportunity/event/${selectedEvent}`, {
          headers: {
            "Content-Type": "application/json",
            Accept: "*/*",
            Authorization: `Bearer ${token}`,
          },
        })
        .then((result) => setOpportunities(result.data.output))
        .catch((err) =>
          addToast({
            type: "error",
            title: intl.formatMessage({
              id: "error",
            }),
            description: intl.formatMessage({
              id: "api.error." + err.response.data.code,
            }),
          })
        )
        .finally(() => handleLoading(false));
    }
  }, [selectedEvent, addToast, intl, token, handleLoading]);

  useEffect(() => {
    formRef.current.setFieldValue("benefited");
    if (selectedOpportunity) {
      handleLoading(true);
      const config = {
        headers: {
          "Content-Type": "application/json",
          Accept: "*/*",
          Authorization: `Bearer ${token}`,
        },
      };
      api.get(`/users/transfer/opportunity/${selectedOpportunity}`, config)
      .then((userResponse) => {
        const users = userResponse.data.systemUserByOpportunityOutputs.map(
          (user) => ({
            value: user.id,
            label: user.commercialName,
            isBenefitedServiceProvider: user.serviceProvider,
          })
        );
        setUsers(users);
      })
      .catch((err) =>
        addToast({
          type: "error",
          title: intl.formatMessage({
            id: "error",
          }),
          description: intl.formatMessage({
            id: "api.error." + err.response.data.code,
          }),
        })
      )
      .finally(() => handleLoading(false));
    }
  }, [addToast, handleLoading, intl, selectedOpportunity, token]);

  useEffect(() => {
    formRef.current.setFieldValue("service");
    if (selectedOpportunity && isServiceProvider) {
      handleLoading(true);
      const config = {
        headers: {
          "Content-Type": "application/json",
          Accept: "*/*",
          Authorization: `Bearer ${token}`,
        },
      };
      api.patch(
        `provider-association/find-services`,
        {
          opportunityId: selectedOpportunity,
          systemUserBenefitedId: selectedBenefited,
        },
        config
      )
      .then((serviceResponse) => {
        const serviceTypes =
          serviceResponse.data.output.map(
            (service) => ({
              value: service.serviceProviderAssociationId,
              label: provisionTypes.find((pt) => pt.value === service.serviceType)?.label,
            })
          );
        setServiceTypes(serviceTypes);
      })
      .catch((err) =>
        addToast({
          type: "error",
          title: intl.formatMessage({
            id: "error",
          }),
          description: intl.formatMessage({
            id: "api.error." + err.response.data.code,
          }),
        })
      )
      .finally(() => handleLoading(false));
    }
  }, [addToast, handleLoading, intl, selectedOpportunity, selectedBenefited, isServiceProvider, token]);

  useEffect(() => {
    formRef.current.setFieldValue("category");
    if (selectedOpportunity && selectedBenefited && (!isServiceProvider || selectedService)) {
      handleLoading(true);
      const config = {
        headers: {
          "Content-Type": "application/json",
          Accept: "*/*",
          Authorization: `Bearer ${token}`,
        },
      };
      api.patch(
        `/ecommerce/category/available-transfer/opportunity`,
        {
          opportunityId: selectedOpportunity,
          systemUserBenefitedId: selectedBenefited,
          serviceProviderAssociationId: selectedService,
        },
        config
      )
      .then((categoryResponse) => {
        const credentialCategories =
          categoryResponse.data.ecommerceChlebaCategoryCredentialAvailableTransferOutputs.map(
            (category) => ({
              value: category.id,
              label: isPortuguese ? category.title : category.englishTitle,
            })
          );
        setCategories(credentialCategories);
      })
      .catch((err) =>
        addToast({
          type: "error",
          title: intl.formatMessage({
            id: "error",
          }),
          description: intl.formatMessage({
            id: "api.error." + err.response.data.code,
          }),
        })
      )
      .finally(() => handleLoading(false));
    }
  }, [addToast, handleLoading, intl, selectedOpportunity, selectedBenefited, selectedService, isServiceProvider, token]);

  const validationBlur = (name) => {
    let errors = formRef.current.getErrors();
    validateSingleFieldOnBlur(errors, schema, name, formRef);
  };

  const handleSubmitConditional = useCallback(async () => {
    try {
      const data = formRef.current.getData();
      formRef.current?.setErrors({});
      await schema.validate(data, { abortEarly: false });
      setConfirmationModalOpen(true);
    } catch (err) {
      if (err instanceof Yup.ValidationError) {
        const errors = getValidationErrors(err);
        formRef.current?.setErrors(errors);
        return;
      }
    }
  }, [schema]);

  const confirmModal = () => {
    setConfirmationModalOpen(false);
    formRef.current.submitForm();
  };

  return (
    <AnimatedMainContainer>
      <Container>
        <Form ref={formRef} onSubmit={handleSubmit}>
          <TitleContainer>
            <Title>
              <FormattedMessage id="transfer_credential" />
            </Title>
            <ActionContainer>
              <BadgeContainer
                onClick={() => history.push("/credential-transfer")}
              >
                <Badge
                  color="#C9C9C9"
                  text={intl.formatMessage({ id: "goback" })}
                  fontSize="12px"
                />
              </BadgeContainer>
              <BadgeContainer onClick={() => handleSubmitConditional()}>
                <Badge
                  color="#002244"
                  text={intl.formatMessage({ id: "save" })}
                  fontSize="12px"
                  loading={loading}
                />
              </BadgeContainer>
            </ActionContainer>
          </TitleContainer>
          <Session>
            <FieldsContainer>
              <SelectWithLabel
                name="event"
                label={intl.formatMessage({ id: "event_edition" })}
                placeholder={intl.formatMessage({ id: "event_edition" })}
                validationBlur={validationBlur}
                labelOrientation="horizontal"
                labelWidth="150px"
                options={events}
                onChange={(e) => setSelectedEvent(e?.value)}
                required
              />
              <SelectWithLabel
                name="opportunity"
                placeholder={intl.formatMessage({
                  id: "contract",
                })}
                label={intl.formatMessage({
                  id: "contract",
                })}
                validationBlur={validationBlur}
                labelOrientation="horizontal"
                labelWidth="150px"
                options={opportunities}
                onChange={(e) => setSelectedOpportunity(e?.value)}
                required
              />
              <SelectWithLabel
                name="benefited"
                validationBlur={validationBlur}
                label={intl.formatMessage({ id: "benefited" })}
                placeholder={intl.formatMessage({ id: "benefited" })}
                labelOrientation="horizontal"
                labelWidth="150px"
                options={users}
                onChange={(user) => {
                  setSelectedBenefited(user?.value);
                  setIsServiceProvider(user?.isBenefitedServiceProvider);
                  setSelectedService(null);
                }}
                required
              />
              <SelectWithLabel
                name="service"
                label={intl.formatMessage({ id: "service_type" })}
                placeholder={intl.formatMessage({ id: "service_type" })}
                labelOrientation="horizontal"
                labelWidth="150px"
                options={serviceTypes}
                onChange={(service) => setSelectedService(service?.value)}
                disabled={!isServiceProvider}
              />
              <SelectWithLabel
                name="category"
                validationBlur={validationBlur}
                label={intl.formatMessage({ id: "credential_types" })}
                placeholder={intl.formatMessage({ id: "credential_types" })}
                labelOrientation="horizontal"
                labelWidth="150px"
                options={categories}
                required
              />
              <InputWithHorizontalLabel
                name="quantity"
                placeholder={intl.formatMessage({ id: "quantity" })}
                label={intl.formatMessage({ id: "quantity" })}
                validationBlur={validationBlur}
                labelWidth="150px"
                required
              />
            </FieldsContainer>
          </Session>
        </Form>
      </Container>
      <ConfirmationModal
        setIsOpen={setConfirmationModalOpen}
        modalIsOpen={confirmationModalOpen}
        confirmationFunction={() => confirmModal()}
        description={intl.formatMessage({ id: "credential_transfer_message" })}
        confirmationText={intl.formatMessage({ id: "yes" })}
        cancelText={intl.formatMessage({ id: "no" })}
      />
    </AnimatedMainContainer>
  );
};

export default CreateCredentialTransfer;
