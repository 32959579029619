export function getEcommerceInfo(params, callbackError) {
  return {
    type: "GET_ECOMMERCE_INFO",
    payload: {
      params,
      callbackError,
    },
  };
}

export function setEcommerceInfoSuccess(ecommerceInfo) {
  return {
    type: "SET_ECOMMERCE_INFO",
    payload: {
      ecommerceInfo,
    },
  };
}

export function clearEcommerceInfo() {
  return {
    type: "CLEAR_ECOMMERCE_INFO",
  };
}

export function getEcommerceLink({
  callbackError,
  selectedEvent,
  selectedContract,
}) {
  return {
    type: "GET_ECOMMERCE_LINK",
    payload: {
      selectedEvent,
      selectedContract,
      callbackError,
    },
  };
}
